/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { green } from '@mui/material/colors';
import { Controller } from 'react-hook-form';
import Text from '../../../../../components/Text/Text';
import Grid from '../../../../../components/ui/Layout/Grid';
import { Switcher } from '../../../../../components/inputs/Switcher';

const InventoriedSwitch = ({ control }) => {
  const { t } = useTranslation(['general']);

  return (
    <Controller
      control={control}
      name="inventoried"
      defaultValue={false}
      render={({
        field: {
          onChange, onBlur, value, ref,
        },
      }) => (
        <Grid container alignItems="center" style={{ gap: '1rem' }}>
          <Switcher
            activeHtmlColor={green[600]}
            onBlur={onBlur}
            onChange={onChange}
            checked={value}
            inputRef={ref}
          />
          <Text>
            {t('general:products_section:require_inventory')}
          </Text>
        </Grid>
      )}
    />
  );
};

export default InventoriedSwitch;
