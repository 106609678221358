/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import {
  Button, Grid, Modal, Typography,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import SectionLink from '../../../../../components/ui/SectionLink';

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    maxWidth: 450,
    maxHeight: 500,
    padding: '60px 50px',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #000',
    boxShadow: theme.shadows[5],
    borderRadius: 15,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: 'auto',
    overflowY: 'auto',
  },
  warning: {
    display: 'block',
    minWidth: 40,
    minHeight: 40,
    backgroundImage: `url(${theme.icons.warning})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    cursor: 'pointer',
    margin: 10,
    [theme.breakpoints.down('md')]: {
      minWidth: 35,
      height: 35,
    },
  },
  linkSection: {
    width: 130,
    margin: '15px auto',
  },
}));

export default ({ open, setOpen }) => {
  const classes = useStyles();

  const { t } = useTranslation();
  const handleClose = () => {
    setOpen(false);
  };

  const body = (
    <Grid className={classes.paper}>
      <Grid>
        <Grid container alignItems="center" wrap="nowrap">
          <span className={classes.warning} />
          <Typography variant="h5">
            <b>Uno o más productos no tienen la información de costo</b>
          </Typography>
        </Grid>
        <br />
        <Grid>
          <Typography>
            Para que la información de Ganancias pueda ser precisa es importante que cada producto tenga un costo y un precio al público.
          </Typography>
          <br />
          <Typography>
            Lo puedes configurar en el siguiente botón.
            <br />
            Despues podrás encontrar la misma opción en el módulo de
            {' '}
            <b>inventario&gt;productos</b>
          </Typography>
        </Grid>
        <Grid className={classes.linkSection}>
          <SectionLink
            name={t('general:inventory_page:products:cost')}
            section="cost"
            topIcon="finance"
            link="inventory/costs"
          />
        </Grid>
      </Grid>
      <Grid style={{ width: '100%', textAlign: 'end' }}>
        <Button onClick={handleClose} variant="contained" color="primary" style={{ width: 140, height: 30 }}>
          Ok
        </Button>
      </Grid>
    </Grid>
  );

  return (
    <Modal
      open={open}
      onClose={handleClose}
      disableEnforceFocus
      disableAutoFocus
    >
      {body}
    </Modal>
  );
};
