/* eslint-disable react/prop-types */
import {
  Box, Grid,
} from '@mui/material';
import React from 'react';
import Radio from '@mui/material/Radio';
import { makeStyles } from '@mui/styles';
import Text from '../../Text/Text';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
    marginTop: '15px',
  },
  cardImage: {
    maxWidth: '100%',
    height: '100%',
    display: 'block',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  cardContain: {
    width: '2.5em',
    height: '1em',
    border: '1px solid grey',
    borderRadius: 10,
    padding: '.5em',
    alignItems: 'center',
  },
  containerBody: {

  },
  visa: {
    backgroundImage: `url(${theme.icons.visa})`,
  },
  mastercard: {
    backgroundImage: `url(${theme.icons.masterCard})`,
  },
  americanexpress: {
    backgroundImage: `url(${theme.icons.americanExpress})`,
  },
  fontsize: {
    fontSize: 'small',
  },
}));

export const PaymentMethodSelect = ({
  cardData, checked, onChange, value,
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>

      <div>
        <Radio
          color="secondary"
          checked={checked}
          onChange={onChange}
          value={value}
        />
      </div>
      <div>
        <Grid className={classes.cardContain}>
          <span className={`${classes.cardImage} ${classes[cardData.brand.replace('_', '').toLowerCase()]}`} />
        </Grid>
      </div>
      <div>
        <Text>
          ***
          <b>{cardData.last4}</b>
        </Text>
        <Text className={classes.fontsize}>
          vencimiento
          <b>
            {cardData.exp_month}
            /
            {cardData.exp_year}
          </b>
        </Text>
      </div>

    </Box>
  );
};
