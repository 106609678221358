/* eslint-disable react/prop-types */
import React from 'react';
import { withStyles } from '@mui/styles';
import Button from '../../Button';

const RedButton = withStyles(() => ({
  root: {
    backgroundColor: '#FF1F1F',
    '&:hover': {
      backgroundColor: '#FF1F1F',
    },
  },
}))(Button);

export default (props) => {
  const { text, variant, ...rest } = props;
  return (
    <RedButton {...rest} variant="contained" color="primary" text={text} />
  );
};
