/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Box from '../../../../../components/ui/Layout/Box';
import ConfirmDialog from '../../../../../components/ui/ConfirmDialog';
import Grid from '../../../../../components/ui/Layout/Grid';
import Alert from '../../../../../components/ui/notifications/alert';
import Modal from '../../../../../components/ui/Modals/Modal';
import RawModifierGroupForm from '../RawModifierGroupFrom';
import Actions from '../../../../../components/ui/Modals/Actions/Actions';
import useGroupFormFields from '../RawModifierGroupFrom/fields_definition';

export default ({
  modifierGroup,
  attemptSaveModifierGroup,
  attemptDeleteModifierGroup,
  processing,
  deletingModifierGroup,
  error,
  closeModalModifierGroup,
  handleModifiers,
  handleAssociatedProducts,
  associateError,
}) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const { showModal } = useSelector((state: any) => state.modifier_group);
  const { t } = useTranslation(['general']);

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(closeModalModifierGroup());
  };

  const title = modifierGroup.id == null
    ? t('general:modifiers_groups:create_modifier_group')
    : t('general:modifiers_groups:edit_modifier_group', {
      name: modifierGroup.title,
    });

  const {
    register,
    handleSubmit,
    errors /* , control */,
  } = useGroupFormFields();

  const onSubmit = (data) => {
    data.id = modifierGroup.id ? modifierGroup.id : null;

    attemptSaveModifierGroup(data);
  };

  const actions = (
    <Actions
      handleClose={handleClose}
      handleSubmit={handleSubmit(onSubmit)}
      handleDelete={() => setShowConfirm(true)}
      isNew={modifierGroup.id === null}
      processing={processing}
      deleting={deletingModifierGroup}
    />
  );

  return (
    <Modal
      open={showModal}
      onClose={handleClose}
      title={title}
      actions={actions}
    >
      <Box mb={5}>
        <ConfirmDialog
          open={showConfirm}
          handleCancel={() => {
            setShowConfirm(false);
          }}
          handleConfirm={() => {
            setShowConfirm(false);
            attemptDeleteModifierGroup(modifierGroup);
          }}
          title={t('general:modifiers_groups:delete_modifier_group')}
          content={t('general:modifiers_groups:delete_confirmation_msg')}
        />
        {error && (
          <Grid container>
            <Alert severity="error" message={error} />
          </Grid>
        )}
        {
          associateError?.modifiers && (
          <Alert autoClose={false} severity="error" message={t('modifier_is_required')} />
          )
        }
        {
          associateError?.variants && (
          <Alert autoClose={false} severity="error" message={t('variants_is_required')} />
          )
        }
        <RawModifierGroupForm
          associateError={associateError}
          modifierGroup={modifierGroup}
          errors={errors}
          register={register}
          handleModifiers={handleModifiers}
          handleAssociatedProducts={handleAssociatedProducts}
        />
      </Box>
    </Modal>
  );
};
