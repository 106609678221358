import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Box } from '@mui/material';

interface Props {
  expanded: boolean,
  onClick?(): void
}

export default ({ expanded, onClick } : Props) => (
  <Box onClick={onClick}>
    {expanded ? (

      <ExpandLessIcon />
    ) : (
      <ExpandMoreIcon />
    )}
  </Box>
);
