import { types } from './types';
import AuthorizedApiClient from '../../core/common/api/AuthorizedApiClient';

export const resetMovements = () => ({
  type: types.RESET_MOVEMENTS,
});

export const clearRefresh = () => ({
  type: types.CLEAR_REFRESH,
});

export const setParams = params => ({
  type: types.SET_MOVEMENTS_PARAMS,
  params,
});

export const getMovements = (companyId, params = {}) => async (
  dispatch: any,
) => {
  const sg = new AuthorizedApiClient();
  const path = `companies/${companyId}/movements`;
  dispatch({ type: types.SET_MOVEMENTS_PROCESSING, payload: true });
  try {
    const response = await sg.get(params, path);

    if (response != null) {
      dispatch({ type: types.SET_MOVEMENTS_SUCCESS, response: response.data });
    }
  } catch (error) {
    dispatch({ type: types.SET_MOVEMENTS_FAILURE, error });
  }
};
