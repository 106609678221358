import { Tabs } from '@mui/material';
import { styled } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavTabs } from '../../../../components/navigation/NavTabs/NavTabs';
import ContentHeader from '../../../../components/ui/ContentHeader';
import Box from '../../../../components/ui/Layout/Box';
import Grid from '../../../../components/ui/Layout/Grid';
import { MainPage } from './views/MainPage';
import MapsPage from './views/MapsPage';
import { OrdersPage } from './views/OrdersPage';
import { StatusesPage } from './views/StatusesPage';
import { VehiclesPage } from './views/VehiclesPage';

const CustomeTabs = styled(Tabs)(() => ({
  '& .MuiTabs-indicator': {
    backgroundColor: '#e2b55d',
    color: '#e2b55d',
    top: 0,
  },
  '& .Mui-selected': {
    color: '#e2b55d',
  },
  '& .MuiTab-wrapper': {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  '& .MuiTabs-flexContainer': {
    display: 'flex',
    alignItems: 'center',
  },
}));

export const AnalyticsPage = () => {
  const { t } = useTranslation('general');
  const tabs = [
    {
      id: 0,
      title: t('routes.main'),
      component: MainPage,
      icon: 'routesMain',
      to: 'dashboard',
    },
    {
      id: 1,
      title: t('routes.maps'),
      component: MapsPage,
      icon: 'routesMap',
      to: 'routes',
    },
    {
      id: 2,
      title: t('routes.orders'),
      component: OrdersPage,
      icon: 'routesOrders',
      to: 'orders',
    },
    {
      id: 3,
      title: t('routes.vehicles'),
      component: VehiclesPage,
      icon: 'routesVehicles',
      to: 'vehicles',
    },
    {
      id: 4,
      title: t('routes.statuses'),
      component: StatusesPage,
      icon: 'routesStatuses',
      to: 'statuses',
    },
  ];

  return (
    <main>
      <Grid item xs={12}>
        <ContentHeader
          title={t('routes.routes')}
          section="analytics"
          back
        />
      </Grid>
      <Box mt={3} p={0} pb={12} mx="auto" width="90%" maxWidth={1100}>
        <Grid item xs={12}>
          <NavTabs
            tabs={tabs}
            tabsProps={{
              variant: 'scrollable',
              scrollButtons: 'auto',
              'aria-label': 'scrollable auto tabs example',
            }}
            containerTabsProps={{
              elevation: 0,
              style: {
                borderTop: '1px solid #e2b55d',
              },
            }}
            themeColor="#e2b55d"
            customTabs={CustomeTabs}
          />
        </Grid>
      </Box>
    </main>
  );
};
