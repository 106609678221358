/* eslint-disable prefer-destructuring */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/prop-types */
import { Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import AsyncPaginatedTable, {
  usePaginatedTable,
} from '../../../../../../components/ui/AsyncPaginatedTable';
import FormattedCurrency from '../../../../../../components/ui/FormattedCurrency';
import Box from '../../../../../../components/ui/Layout/Box';
import SaleDate from './SaleDate';

import { getFormattedDate } from '../../../../../../util/dates';
import Grid from '../../../../../../components/ui/Layout/Grid';
import DateFilter from '../../../../../../components/ui/DateFilter';
import { SaleSource } from '../../../../../../constants/common';
import OrderCampaignFilter from '../../../../../../components/ui/OrderCampaignFilter';
import { useSession } from '../../../../../../util/session';

export default ({
  state, getSales, resetSales, downloadSales,
}) => {
  const { company } = useSession();
  const { handleViewColumnsChange, isVisibleColumn } = usePaginatedTable();
  const [visibleColumns, setVisibleColumns] = useState([
    'created_at',
    'source',
    'number',
    'branch',
    'campaigns',
    'employee',
    'customer',
    'items',
    'total',
  ]);
  const { filters } = state.params;
  const { t } = useTranslation(['general']);
  // console.log("filters", filters)
  const startDate = filters && filters.created_at
    ? filters && filters.created_at.$gte
    : null;

  const endDate = filters && filters.created_at
    ? filters && filters.created_at.$lte
    : null;
  const { data } = state.response;
  const [currentCampaign, setCurrentCampaign] = useState({
    value: '',
    label: '',
  });

  const _handleViewColumnsChange = (changedColumn: string, action: string) => {
    handleViewColumnsChange(
      changedColumn,
      action,
      visibleColumns,
      setVisibleColumns,
    );
  };

  const isVisible = useCallback(
    column => isVisibleColumn(visibleColumns, column),
    [visibleColumns, isVisibleColumn],
  );

  const columns = [
    {
      name: 'created_at',
      label: t('general:date'),
      filterName: 'created_at',
      options: {
        filter: true,
        filterType: 'custom',
        display: isVisible('created_at'),
        filterOptions: {
          display: (filterList, onChange, index, column) => (
            <Box mt={2}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <DateFilter
                    label={t('general:filters:from_date')}
                    value={startDate}
                    maxDate={endDate || new Date()}
                    onChange={(date) => {
                      // setStartDate(date);
                      const dates = [];
                      if (date) {
                        dates[0] = getFormattedDate(date);
                      } else {
                        dates[0] = '';
                      }
                      if (endDate) {
                        dates[1] = getFormattedDate(endDate);
                      } else {
                        dates[1] = dates[0];
                        // setEndDate(date);
                      }
                      try {
                        filterList[index][0] = date ? dates.join('|') : '';
                        onChange(filterList[index], index, column);
                      } catch (e) {
                        console.log('error date filter', e.message);
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <DateFilter
                    label={t('general:filters:from_to')}
                    value={endDate}
                    minDate={startDate || null}
                    maxDate={new Date()}
                    onChange={(date) => {
                      // setEndDate(date);

                      const dates = [];
                      if (date) {
                        dates[1] = getFormattedDate(date);
                      } else {
                        dates[1] = '';
                      }
                      if (startDate) {
                        dates[0] = getFormattedDate(startDate);
                      } else {
                        dates[0] = dates[1];
                        // setStartDate(date);
                      }
                      try {
                        filterList[index][0] = date ? dates.join('|') : '';
                        onChange(filterList[index], index, column);
                      } catch (e) {
                        console.log('error date filter', e.message);
                      }
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          ),
        },
        customFilterListOptions: {
          render: (v) => {
            if (Array.isArray(v)) {
              const dates = v[0].split('|');
              if (dates[0] === dates[1]) {
                return dates[0];
              }
              return (
                `${dates[0]} ${t('general:filters:from_to')} ${dates[1]}`
              );
            }
            return [];
          },
        },
        sort: false,
        customBodyRender: (value) => {
          if (value) {
            return <SaleDate value={value} />;
          }
          return '';
        },
      },
      customRenderer: value => <SaleDate value={value} />,
    },

    {
      name: 'source',
      label: t('general:sales_page:source'),

      options: {
        filter: false,
        sort: false,
        display: isVisible('source'),
        customBodyRender: value => (value === SaleSource.MARKET_PLACE
          ? t('general:sales_page:market_place')
          : t('general:sales_page:pos')),
      },
    },

    {
      name: 'number',
      label: t('general:sales_page:ticket'),

      options: {
        filter: false,
        sort: false,
        display: isVisible('number'),
        customBodyRender: (value, tableMeta) => {
          const saleId = data[tableMeta.rowIndex].id;

          return (
            <Link to={`/sales/${saleId}`}>
              #
              {value}
            </Link>
          );
        },
      },
    },
    {
      name: 'branch',
      label: t('general:branch'),

      options: {
        filter: false,
        sort: false,
        display: isVisible('branch'),
        customBodyRender: (branch) => {
          if (branch) {
            return branch.name;
          }
          return '';
        },
      },
    },
    {
      name: 'campaigns',
      label: t('general:shared_campaigns:campaign'),

      options: {
        filter: true,
        sort: false,
        display: isVisible('campaigns'),
        filterType: 'custom',

        filterOptions: {
          display: (filterList, onChange, index, column) => (
            <Grid item xs={12}>
              {company && company.id && (
                <OrderCampaignFilter
                  onChange={(campaign) => {
                    // console.log("campaign", campaign);
                    setCurrentCampaign(campaign);
                    filterList[index][0] = campaign ? campaign.value : '';
                    onChange(filterList[index], index, column);
                  }}
                  current={
                    filters && filters.campaign_id ? currentCampaign : null
                  }
                  companyId={company.id}
                />
              )}
            </Grid>
          ),
        },
        customBodyRender: (campaigns) => {
          if (campaigns && campaigns.length > 0) {
            const names = campaigns.map(c => c.name);
            return names.join(', ');
          }
          return '';
        },
      },
    },
    {
      name: 'employee',
      label: t('general:seller'),

      options: {
        filter: false,
        sort: false,
        display: isVisible('employee'),
        customBodyRender: (employee) => {
          if (employee) {
            return (
              <Typography>
                {`${employee.name} ${employee.last_name}`}
              </Typography>
            );
          }
          return '';
        },
      },
    },
    {
      name: 'customer',
      label: t('general:customer'),

      options: {
        filter: false,
        sort: false,
        display: isVisible('customer'),
        customBodyRender: (customer) => {
          if (customer) {
            return (
              <Typography>
                {`${customer.name} ${customer.last_name}`}
              </Typography>
            );
          }
          return <Typography>{t('general:public')}</Typography>;
        },
      },
    },
    {
      name: 'items',
      label: t('general:items'),

      options: {
        filter: false,
        sort: false,
        display: isVisible('items'),
        customBodyRender: (items) => {
          if (items) {
            return <Typography>{items.length}</Typography>;
          }
          return 0;
        },
      },
    },
    {
      name: 'total',
      label: t('general:total'),

      options: {
        filter: false,
        sort: false,
        display: isVisible('total'),
        customBodyRender: value => (
          <Typography>
            <FormattedCurrency value={value} />
          </Typography>
        ),
      },
    },
  ];
  // console.log("data", data)
  return (
    <Box>
      <Box mt={3}>
        <AsyncPaginatedTable
          hasNextPage={state.response.page < state.response.last_page}
          currentPage={state.response.page}
          perPage={state.response.per_page}
          // page={state.response.page}
          data={data}
          columns={columns}
          loadData={params => getSales(params)}
          resetData={resetSales}
          processing={state.processing}
          total={state.response.total}
          options={{
            filter: true,
            viewColumns: true,
            onViewColumnsChange: _handleViewColumnsChange,
            downloadExcel: downloadSales,
          }}
          filterFields={[{ field: 'created_at', type: 'date_range' }]}
          // onResetFilters={onResetFilters}
        />
      </Box>
    </Box>
  );
};
