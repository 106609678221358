/* eslint-disable no-nested-ternary */
import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Alert from '@mui/material/Alert';
import { Typography } from '@mui/material';
import Box from '../../../../../../components/ui/Layout/Box';
import ActivityIndicator from '../../../../../../components/ui/ActivityIndicator';
import OrderItemView from '../../../../../../components/ui/OrderItemView';
import OrderDetails from '../../../../../../components/ui/OrderDetails';
// import PaymentType from "./PaymentType";
// import SaleItems from "./SaleItems";
// import FormattedCurrency from "../../../../../../components/ui/FormattedCurrency";

export default () => {
  const { t } = useTranslation(['general']);
  const { sale, processing } = useSelector((state: any) => state.sale);
  return (
    <Box>
      {processing ? (
        <ActivityIndicator />
      ) : sale === null ? (
        <Alert severity="error">{t('general:sales_page:sale_not_found')}</Alert>
      ) : (
        <>
          <Box margin="0px 10px">
            <>
              <OrderDetails
                {...sale}
              />
              {
                    sale.items.map(item => (
                      <OrderItemView
                        key={item.id}
                        {...item}
                      />
                    ))
                  }
              <Typography variant="h6" align="right" style={{ marginRight: '13%', fontWeight: 'bold' }}>
                Subtotal: $
                {sale.subtotal}
              </Typography>
              {
                      sale.shipping_method === 'delivery'
                      && (
                      <Typography variant="h6" align="right" style={{ marginRight: '13%', fontWeight: 'bold' }}>
                        Precio de envío: $
                        {sale.delivery_price}
                      </Typography>
                      )
                  }
              <Typography variant="h5" align="right" style={{ marginRight: '13%', fontWeight: 'bold' }}>
                Total: $
                {sale.total}
              </Typography>
            </>
          </Box>
          {/* <Box mt={6} mb={3} display="flex" flexWrap="wrap">
            <Grid container>
              <Grid container item xs={6} alignContent="center">
                <Typography variant="h5">
                  {t("general:sales_page:ticket")} # {sale.number}
                </Typography>
              </Grid>
              <Grid container item xs={6} justify="flex-end">
                <PaymentType type={sale.payment_type} />
              </Grid>
            </Grid>
          </Box>

          <Box mt={3}>
            <SaleItems items={sale.items} />
          </Box>

          <Box mt={2}>
            <Grid container>
              <Grid item xs={10}></Grid>
              <Grid container item xs={2} justify="center">
                <Typography variant="h4">
                  <FormattedCurrency value={sale.total} />
                </Typography>
              </Grid>
            </Grid>
          </Box> */}
        </>
      )}
    </Box>
  );
};
