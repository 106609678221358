import React from 'react';
import { useDispatch } from 'react-redux';
import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { editCustomer } from '../../../../actions/customer';
import CustomersPageView from './CustomersPageView';
import { PERMISSION, RESOURCES } from '../../../../constants/common';
import { useSession } from '../../../../util/session';
import { MenuTabs } from '../../../../components/ui/MenuTabs';
import ContentHeader from '../../../../components/ui/ContentHeader';

export default () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['general']);
  const { can } = useSession();

  const openNewCustomer = () => {
    dispatch(editCustomer());
  };

  const tabs = [
  ];

  if (can(RESOURCES.customers, PERMISSION.create) || can(RESOURCES.customers, PERMISSION.edit)) {
    tabs.push({
      title: 'Clientes',
      icon: 'client',
      render: <CustomersPageView openNewCustomer={openNewCustomer} />,
      index: 0,
    });
  }

  return (
    <main>
      <Grid item xs={12}>
        <ContentHeader title={t('general:clients')} section="client" back />
      </Grid>
      <Box mt={3} p={0} pb={12} mx="auto" width="90%" maxWidth={1100}>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12}>
            <MenuTabs
              data={tabs}
              selectColor="#DA5347"
              colorBar="#DA5347"
            />
          </Grid>
        </Grid>
      </Box>
    </main>
  );
  /* <CustomersPageView openNewCustomer={openNewCustomer}/>; */
};
