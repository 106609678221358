export const types = {
  EDIT_UNITOFMEASURE: 'EDIT_UNITOFMEASURE',
  CLOSE_UNIT_MODAL: 'CLOSE_UNIT_MODAL',
  SAVING_UNITOFMEASURE: 'SAVING_UNITOFMEASURE',
  SAVE_UNITOFMEASURE_SUCCESS: 'SAVE_UNITOFMEASURE_SUCCESS',
  SAVE_UNITOFMEASURE_FAILURE: 'SAVE_UNITOFMEASURE_FAILURE',
  DELETING_UNITOFMEASURE: 'DELETING_UNITOFMEASURE',
  DELETE_UNITOFMEASURE_SUCCESS: 'DELETE_UNITOFMEASURE_SUCCESS',
  DELETE_UNITOFMEASURE_FAILURE: 'DELETE_UNITOFMEASURE_FAILURE',
  CLEAR_REFRESH: 'CLEAR_REFRESH',
};
