import { types } from './types';
import AuthorizedApiClient from '../../core/common/api/AuthorizedApiClient';

export const resetActivitis = () => ({
  type: types.RESET_ACTIVITIS,
});

export const getActivitis = (companyId, params = {}) => async (
  dispatch: any,
) => {
  const sg = new AuthorizedApiClient();
  const path = `companies/${companyId}/pos_events`;
  dispatch({ type: types.LOAD_ACTIVITIS, payload: true });
  try {
    const response = await sg.get(params, path);

    if (response != null) {
      dispatch({ type: types.SUCCESS_ACTIVITIS, response: response.data });
    }
  } catch (error) {
    dispatch({ type: types.FAILURE_ACTIVITIS, error });
  }
};
