/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import SuccessButton from '../ui/Buttons/SuccessButton';
import Grid from '../ui/Layout/Grid';

const Actions = ({ handleSubmit }) => {
  const { t } = useTranslation();
  return (
    <Grid container>
      <Grid container item xs={12} justifyContent="flex-end">
        <SuccessButton
          onClick={handleSubmit}
          color="primary"
          text={t('general:apply')}
        />
      </Grid>
    </Grid>
  );
};

export default Actions;
