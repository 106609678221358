/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { editCategory } from '../../../../../actions/category';
import AutoComplete, {
  AutoCompleteOption,
} from '../../../../../components/ui/FormInput/AutoComplete';
import AuthorizedApiClient from '../../../../../core/common/api/AuthorizedApiClient';
import { useSession } from '../../../../../util/session';
import { PERMISSION, RESOURCES } from '../../../../../constants/common';
import { clearAddedCategory } from '../../../../../actions/product';
import CategoryForm from '../../CategoriesPage/CategoryForm';

export default ({
  handleOnChange, selected, register, error,
}) => {
  const { company } = useSession();
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const dispatch = useDispatch();
  const { t } = useTranslation(['products']);
  const { can } = useSession();
  const { addedCategory } = useSelector((state: any) => state.product_category);

  const _getCategories = useCallback(async (companyId, _params: any = {}) => {
    const sg = new AuthorizedApiClient();
    const path = `companies/${companyId}/categories`;
    const params = {
      per_page: 20,
    };

    if (_params.filter) {
      params['filters'] = { name: _params.filter };
    }
    return sg.get(params, path);
  }, []);

  const _loadCategories = useCallback(
    async (_params = {}) => {
      if (company) {
        setLoading(true);
        try {
          const response = await _getCategories(company.id, _params);

          if (response != null) {
            const categories = response.data.data.map(c => ({
              value: c.id,
              label: c.name,
            } as AutoCompleteOption));

            setCategories(categories);

            setLoading(false);
          }
        } catch (error) {
          setCategories([]);
          setLoading(false);
          //   setError(error.response.data);
        }
      }
    },
    [company, _getCategories],
  );
  const _handleCreateCategory = () => {
    dispatch(editCategory());
  };

  const _handleOnChange = useCallback(
    (value) => {
      handleOnChange('selectedCategory', value);
    },
    [handleOnChange],
  );

  useEffect(() => {
    const a = async () => {
      if (addedCategory !== null && !loading) {
        await _loadCategories({ filter: addedCategory.name });

        _handleOnChange({ value: addedCategory.id, label: addedCategory.name });

        dispatch(clearAddedCategory());
      }
    };
    a();
  }, [
    company,
    categories,
    addedCategory,
    _loadCategories,
    loading,
    dispatch,
    _handleOnChange,
  ]);

  return (
    <>
      <AutoComplete
        getData={_loadCategories}
        error={error}
        defaultValue={selected != null ? selected.value : null}
        value={selected}
        handleOnChange={_handleOnChange}
        options={categories}
        loading={loading}
        label={t('products:category')}
        creatable={can(RESOURCES.categories, PERMISSION.create)}
        handleCreate={_handleCreateCategory}
        createNewLabel={t('products:add_category')}
      />
      <input
        {...register('category_id')}
        type="hidden"
        value={selected ? selected.value : ''}
      />

      {can(RESOURCES.categories, PERMISSION.create) && <CategoryForm source={2} />}
    </>
  );
};
