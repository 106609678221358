/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { CircularProgress, TextField } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Autocomplete from '@mui/material/Autocomplete';
import { SelectOption } from '../FormInput/Select';
import AuthorizedApiClient from '../../../core/common/api/AuthorizedApiClient';
import { AutoCompleteOption } from '../FormInput/AutoComplete';

interface LocationsSelectorProps {
  company_id: string;
  branch_id: string;
  location_id: string;
  setLocation: Function;
}

export default ({
  company_id,
  branch_id,
  location_id,
  setLocation,
}: LocationsSelectorProps) => {
  const { t } = useTranslation('general');

  const [locationsOptions, setLocationsOptions] = useState<SelectOption[]>([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState('');
  const [open, setOpen] = React.useState(false);

  const _handleOnChange = (event, value) => {
    handleOnChange(value);
  };

  const _getLocations = useCallback(async (companyId, branchId, filter) => {
    const sg = new AuthorizedApiClient();
    const path = `companies/${companyId}/locations`;
    setLoading(true);
    try {
      const params = { filters: {} };
      if (branchId) {
        params.filters['branch_id'] = branchId;
      }
      if (filter) {
        params.filters['name'] = filter;
      }

      const response = await sg.get(params, path);

      if (response != null) {
        const locations = response.data.data;
        const _locations = locations.map(b => ({
          value: b.id,
          label: b.name,
        } as AutoCompleteOption));

        setLocationsOptions(_locations);

        setLoading(false);
      }
    } catch (error) {
      setLocationsOptions([]);
      setLoading(false);
    }
  }, []);

  const handleOnChange = (event) => {
    setLocation(event || null);
  };

  useEffect(() => {
    if (open && company_id) {
      _getLocations(company_id, branch_id, filter);
    }
  }, [_getLocations, company_id, branch_id, filter, open]);

  return (
    <Autocomplete
      onChange={_handleOnChange}
      inputValue={filter !== '-1' ? filter : null}
      onInputChange={(event, newInputValue) => {
        setFilter(newInputValue);
      }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) => option.label === value.label}
      getOptionLabel={option => option.label}
      options={locationsOptions}
      loading={loading}
      forcePopupIcon={false}
      fullWidth
      defaultValue={null}
      renderInput={params => (
        <TextField
          variant="standard"
          style={{ marginTop: '5px' }}
          {...params}
          placeholder={t('general:location')}
          fullWidth
          InputProps={{
            ...params.InputProps,

            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};
