import { types } from '../../actions/wizard/types';

export const initialState = {
  step: 1,
  food: null,
  business_type: null,
  categories: null,
  production_volume: null,
  deliveries_ranges: null,
  invoice: null,
  customer_types: null,
  keywords: null,
  branch: null,
  saving: false,
  saved: false,
  error: null,
  finish: false,
};

export default (state = initialState, action: any) => {
  switch (action?.type) {
    case types.SET_WIZARD_STEP:
      return {
        ...state,
        step: action.step,
      };

    case types.SET_FOOD:
      return {
        ...state,
        food: action.food,
        step: 2,
      };

    case types.SET_BUSINESS_TYPE:
      return {
        ...state,
        business_type: action.business_type,
      };

    case types.SET_CATEGORIES:
      return {
        ...state,
        categories: action.categories,
      };

    case types.SET_PRODUCTION_VOLUME:
      return {
        ...state,
        production_volume: action.production_volume,
      };

    case types.SET_CUSTOMER_TYPES:
      // console.log("selectedCus", action.costumer_types)
      return {
        ...state,
        customer_types: action.customer_types,
      };

    case types.SET_DELIVERIES_RANGES:
      return {
        ...state,
        deliveries_ranges: action.deliveries_ranges,
      };

    case types.SET_INVOICE:
      return {
        ...state,
        invoice: action.invoice,
      };

    case types.SET_KEYWORDS:
      return {
        ...state,
        keywords: action.keywords,
      };

    case types.SET_WIZARD_BRANCH:
      return {
        ...state,
        branch: action.branch,
      };

    case types.SAVING_WIZARD:
      return {
        ...state,
        saving: true,
        error: null,
      };

    case types.SET_FINISH:
      return {
        ...state,
        finish: true,
      };

    case types.SAVE_WIZARD_SUCCESS:
      return {
        ...state,
        saving: false,
        saved: true,
        error: null,
        finish: false,
      };

    case types.SAVE_WIZARD_FAILURE:
      return {
        ...state,
        saving: false,
        saved: false,
        error: action.error,
        finish: false,
      };
    case types.CLEAR_WIZARD:
      return initialState;

    default:
      return state;
  }
};
