/* eslint-disable react/prop-types */
import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyle = makeStyles(theme => ({
  point: {
    width: 18,
    height: 18,
    borderRadius: '50%',
    marginLeft: 'auto',
    marginRight: 'auto',
    position: 'relative',
    justifySelf: 'center',
  },
  finance: {
    backgroundColor: theme.colors.finance.main,
  },
  product: {
    backgroundColor: theme.colors.inventory.main,
  },
  movement: {
    backgroundColor: theme.colors.cardPurple.main,
  },
  line: {
    '&::after': {
      content: "''",
      backgroundColor: theme.colors.grey,
      position: 'absolute',
      width: 1,
      height: 90,
      top: '50%',
      left: 'calc(50% - 1.5px)',
      zIndex: -1,
    },
  },
}));

export default ({ line, section }) => {
  const classes = useStyle();

  const colors = {
    sale: classes.finance,
    product: classes.product,
    movement: classes.movement,
  };

  return (
    <div className={`${classes.point} ${colors[section] || colors.movement} ${line ? classes.line : ''}`} />
  );
};
