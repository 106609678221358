import {
  SET_PRODUCTS_LIST_PROCESSING,
  SET_PRODUCTS_LIST_ERROR,
  SET_PRODUCTS_LIST_SUCCESS,
  SET_PRODUCTS_LIST_PARAMS,
  SET_PRODUCTS_RESET_FILTERS,
} from '../constants';

const salesListReducer = (state: any, action: any) => {
  switch (action.type) {
    case SET_PRODUCTS_LIST_PROCESSING:
      return { ...state, processing: action.payload, reset: false };

    case SET_PRODUCTS_LIST_ERROR:
      return { ...state, processing: false, error: action.payload };

    case SET_PRODUCTS_LIST_SUCCESS:

      return {
        ...state,
        processing: false,
        response: action.payload,
      };

    case SET_PRODUCTS_LIST_PARAMS:
      return { ...state, params: action.payload };

    case SET_PRODUCTS_RESET_FILTERS:
      return { ...state, params: { ...state.params, filters: action.payload } };

    default:
      return state;
  }
};

export default salesListReducer;
