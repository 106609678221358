/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import { makeStyles } from '@mui/styles';
import ActivityIndicator from '../../../../../../../../components/ui/ActivityIndicator';

const useStyles = makeStyles(() => ({
  video: {
    maxWidth: '100%',
    height: 'auto',
    width: 'auto',
    maxHeight: '100%',
  },
}));

const VideoPreview = ({ asset }) => {
  const classes = useStyles();
  return (
    <>
      {asset.processing ? (
        <ActivityIndicator />
      ) : (
        <video controls className={classes.video}>
          <source src={asset.url} type={asset.type} />
        </video>
      )}
    </>
  );
};

export default VideoPreview;
