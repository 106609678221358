import { makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: 4,
  },
  colorIdentifier: {
    width: '.8rem',
    height: '.8rem',
    borderRadius: 4,
  },
}));

interface Props {
  label: string;
  color: string;
}

export const Legend = ({ label, color }: Props) => {
  const classes = useStyles();
  return (
    <span className={classes.container} style={{ color }}>
      <div className={classes.colorIdentifier} style={{ backgroundColor: color }} />
      {label}
    </span>
  );
};
