import { types } from '../../actions/unitofmeasures/types';

export const initialState = {
  response: {
    data: [], total: 0, per_page: 0, last_page: 0, page: 0,
  },
  processing: true,
  data: [],
  error: null,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case types.SET_UNITOFMEASURES_PROCESSING:
      return {
        ...state,
        processing: action.payload,
      };

    case types.SET_UNITOFMEASURES_SUCCESS:
      return {
        ...state,
        response: action.response,
        data: [...state.data, ...action.response.data],
        error: null,
        processing: false,
      };
    case types.SET_UNITOFMEASURES_FAILURE:
      return {
        ...state,
        error: action.error,
        processing: false,
      };
    case types.RESET_UNITOFMEASURES:
      return initialState;

    default:
      return state;
  }
};
