import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ActivityIndicator from '../../../../../components/ui/ActivityIndicator';
import Box from '../../../../../components/ui/Layout/Box';
import PaginatedTable from '../../../../../components/ui/PaginatedTable';
import { Tabs } from '../../../../../components/ui/Tabs';

export default () => {
  const { t } = useTranslation(['general']);
  const { validRows, invalidRows, readingExcel } = useSelector(
    (state: any) => state.products_excel,
  );

  const columns = [
    {
      name: 'name',
      label: t('general:products_excel:name'),
      options: {
        filter: false,
        sort: false,
      },
    },

    {
      name: 'description',
      label: t('general:products_excel:description'),

      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'barcode',
      label: t('general:products_excel:barcode'),

      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'cost',
      label: t('general:products_excel:cost'),

      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'price',
      label: t('general:products_excel:price'),

      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'initial_stock',
      label: t('general:products_excel:initial_stock'),

      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'unit_name',
      label: t('general:products_excel:unit'),

      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'published',
      label: t('general:products_excel:published'),

      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          if (value) {
            return 'Si';
          }
          return 'No';
        },
      },
    },
  ];

  const failedColumns = [
    ...columns,
    {
      name: 'error',
      label: t('general:products_excel:cause'),

      options: {
        filter: false,
        sort: false,
      },
    },
  ];
  const validTab = validRows.length > 0 ? (
    () => <PaginatedTable data={validRows} columns={columns} />
  ) : (
    () => <>{t('general:products_excel:no_data')}</>
  );
  const failedTab = invalidRows.length > 0 ? (
    () => <PaginatedTable data={invalidRows} columns={failedColumns} />
  ) : (
    () => <>{t('general:products_excel:no_data')}</>
  );
  const tabs = [
    {
      index: 0,
      title: t('general:products_excel:validated_products', {
        total: validRows.length,
      }),
      content: validTab,
    },
    {
      index: 1,
      title: t('general:products_excel:failed_products', {
        total: invalidRows.length,
      }),
      content: failedTab,
    },
  ];
  return (
    <Box minHeight="50px" width={1}>
      {readingExcel ? <ActivityIndicator /> : <Tabs tabs={tabs} />}
    </Box>
  );
};
