/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ProductsListView from './ProductsListView';
import {
  clearRefresh,
  downloadProducts,
  getProducts,
  resetProducts,
  setParams,
} from '../../../../../../actions/products_report';
import { ViewFormats } from '../../../../../../constants/common';

export default () => {
  const dispatch = useDispatch();
  const state = useSelector((states: any) => states.products_report);
  const { refresh } = state;
  const company = useSelector((states: any) => states.session.company);
  const { params } = state;

  const _getProducts = useCallback(
    async (_params = {}) => {
      if (company) {
        const updatedParams = { ...params, ..._params };
        // console.log("updatedParams", updatedParams)
        dispatch(setParams(updatedParams));

        await dispatch(getProducts(company.id, updatedParams));
      }
    },
    [dispatch, company, params],
  );

  const _downloadProducts = useCallback(
    async () => {
      if (company) {
        const updatedParams = { ...params, format: ViewFormats.EXCEL };

        await dispatch(downloadProducts(company.id, updatedParams));
      }
    },
    [dispatch, company, params],
  );

  useEffect(() => {
    if (refresh && company) {
      dispatch(resetProducts());
      dispatch(clearRefresh());
      _getProducts();
    }
  }, [refresh, company, dispatch, _getProducts]);

  return (
    <ProductsListView
      state={state}
      getProducts={_getProducts}
      resetProducts={resetProducts || refresh}
      downloadProductsExcel={_downloadProducts}
    />
  );
};
