/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/prop-types */
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Modal,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import React, { useState } from 'react';
import Axios from 'axios';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import Box from '../ui/Layout/Box';
import { BASE_URL } from '../../constants/api/ApiConstants';

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: 500,
    height: 'auto',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #878787',
    borderRadius: 5,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  centeredItems: {
    margin: 20,
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  root: {
    width: '100%',
    display: 'felx',
    flexDirection: 'column',
  },
  containerCard: {
    display: 'flex',
    marginTop: 25,
    marginBottom: 25,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const ModalPaymentSelect = ({
  open, setOpen, company, onChangePayment,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('general');
  const [modalStyle] = React.useState(getModalStyle);
  const [paymentMethod, setPaymentMethod] = React.useState(company.payment_methods || []);
  const handleChangeEventPayment = (event: SelectChangeEvent) => {
    setPaymentMethod(event.target.value);
  };
  // const [paymetMethodState, setPaymetMethodState] = useState(paymentMethod.length);

  const [load, setLoad] = useState(false);

  const handleChangePayment = (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    const payment_methods = paymentMethod;
    Axios.put(`${BASE_URL}/api/v1/companies/${company.id}`, { payment_methods })
      .then(() => {
        setOpen(false);
        // setPaymetMethodState(paymentMethod.length);
        onChangePayment({ payment_methods });
      })
      .catch(err => console.error(err))
      .finally(() => {
      });
  };

  const getLinkAcount = async () => {
    setLoad(true);
    Axios.post(`${BASE_URL}/api/v1/companies/${company.id}/payment_accounts`)
      .then(({ data }) => {
        setLoad(false);
        window.open(data.account_link.url, '_blank');
      });
  };

  function getModalStyle() {
    return {
      top: `${50}%`,
      left: `${50}%`,
      transform: `translate(-${50}%, -${50}%)`,
    };
  }

  const handleClose = () => {
    setOpen(false);
  };

  const body = (
    <FormControl variant="standard" fullWidth className={classes.formControl}>
      <InputLabel id="demo-mutiple-checkbox-label">Método de pago aceptado</InputLabel>
      <Select
        variant="standard"
        labelId="demo-mutiple-checkbox-label"
        id="demo-mutiple-checkbox"
        multiple
        value={paymentMethod}
        onChange={handleChangeEventPayment}
        input={<Input />}
        renderValue={selected => (selected as string[]).map(s => t(s)).join(', ')}
        MenuProps={MenuProps}
      >
        <MenuItem
          disabled={!company.billing_info.payment_accounts[0]?.data.charges_enabled}
          value="card"
        >
          <Checkbox checked={paymentMethod.indexOf('card') > -1} />
          <ListItemText primary={company.billing_info.payment_accounts[0]?.data.charges_enabled ? 'tarjeta de débito' : '[tarjeta] - configurar cuenta'} />
        </MenuItem>

        <MenuItem
          disabled={company.billing_info.subscriptions[0]?.plan.code !== 'pro'}
          value="cash"
        >
          <Checkbox checked={paymentMethod.indexOf('cash') > -1} />
          <ListItemText primary={company.billing_info.subscriptions[0]?.plan.code !== 'pro' ? '[efectivo] - en Plan Pro' : 'efectivo'} />
        </MenuItem>
      </Select>
    </FormControl>
  );

  return (
    <Modal
      open={open}
      onClose={handleClose}
      disableEnforceFocus
      disableAutoFocus
    >
      <Box
        className={classes.paper}
        style={modalStyle}
      >
        <form onSubmit={handleChangePayment}>
          {body}

          <Box
            fullWidth
            display="flex"
            justifyContent="flex-end"
            padding="5px"
          >
            <Button
              type="submit"
              variant="contained"
              color="primary"
            >
              Guardar
            </Button>
          </Box>
        </form>
        { company.billing_info.payment_accounts[0]?.data.charges_enabled
          ? null
          : (
            <div style={{ marginTop: 5 }}>
              <Button
                onClick={getLinkAcount}
                color="primary"
              >
                {
                load
                  ? <CircularProgress color="secondary" size={25} />
                  : 'Configure'
              }
              </Button>
              {' '}
              una cuenta para retiro de dinero si desea usar tarjeta
            </div>
          )}
      </Box>
    </Modal>
  );
};
