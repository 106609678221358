/* eslint-disable react/prop-types */
import {
  Checkbox,
  FormControlLabel,
  Typography,
} from '@mui/material';
import React from 'react';
import { makeStyles } from '@mui/styles';
import Box from '../../../../../components/ui/Layout/Box';
import { generateRamdomNumber } from '../../../../../util/keys';

const useStyles = makeStyles(() => ({
  Image: {
    cursor: 'pointer',
    width: '100px',
    height: '60px',
    backgroundSize: '45%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    // backgroundSize: "60%",
    margin: 'auto',
  },
  transfer: {

    backgroundSize: '40%',

  },
  Selected: {
    //   "&:after": {
    transform: 'scale(1)',
    background: '#F4E8FC',
    //   },
  },
  text: {
    fontSize: '0.8rem',
  },
}));

export default ({
  image, checked, label, onChange, selectMenu,
}) => {
  const id = `${'·'.replace(/ /g, '')}_${generateRamdomNumber()}`;
  const classes = useStyles();

  const handleOnChange = (event) => {
    onChange(event.target.checked);
  };

  return (
    <Box
      p={2}
      className={checked ? classes.Selected : ''}
      display="flex"
      flexDirection="column"
      justify="center"
      justifyContent="center"
      alignItems="center"
    >
      <label htmlFor={id}>
        <Box
          style={{
            backgroundImage: `url(${image})`,
          }}
          className={`${classes.Image}`}
        />
        <Box mt={2}>
          <Typography align="center" className={classes.text}>
            {label}
          </Typography>
        </Box>
      </label>
      <FormControlLabel
        disabled={selectMenu === false}
        checked={checked}
        control={(
          <Checkbox
            style={{ display: 'none' }}
            id={id}
            onChange={handleOnChange}
          />
        )}
        label=""
      />
    </Box>
  );
};
