import { types } from '../../actions/search/types';

export const initialState = {
  response: {
    data: [], last_page: 0, page: 0, per_page: 0, total: 0,
  },
  data: [],
  loading: true,
  error: '',
  filters: {
    category_id: [],
    name: '',
  },
};

export default (state = initialState, action: any) => {
  // console.log(state.filters)
  switch (action.type) {
    case types.SEARCHING:
      return {
        ...state,
        loading: true,
      };

    case types.SET_SEARCH_NAME:
      return {
        ...state,
        filters: {
          ...state.filters,
          name: action.name,
        },
      };
    case types.SET_SEARCH_CATEGORY:

      if (!state.filters.category_id) {
        state.filters.category_id = [];
      }

      if (state.filters.category_id.length > 0
        && state.filters.category_id.includes(action.category_id)) {
        return {
          ...state,
          filters: {
            ...state.filters,
            category_id: state.filters.category_id
              .filter(category => category !== action.category_id),
          },
        };
      }

      return {
        ...state,
        filters: {
          ...state.filters,
          category_id: [
            ...state.filters.category_id,
            action.category_id,
          ],
        },
      };

    case types.SEARCH_SUCCESS:

      return {
        ...state,
        loading: false,
        error: false,
        response: action.response,
        data: [...state.data, ...action.response.data],
      };

    case types.SEARCH_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case types.RESET_SEARCH_PRODUCTS:
      return {
        ...state,
        response: initialState.response,
        data: initialState.data,
        loading: initialState.loading,
        error: initialState.error,
      };

    default:
      return state;
  }
};
