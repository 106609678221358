/* eslint-disable react/prop-types */
import { Typography } from '@mui/material';
import React from 'react';
import { makeStyles } from '@mui/styles';
import Box from '../../../../../../../components/ui/Layout/Box';

const styles = makeStyles(() => ({
  limitText: {
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

export default ({ name, description }) => {
  const classes = styles();
  return (
    <Box flexWrap="noWrap">
      <Box width={1}>
        <Typography variant="h6">{name}</Typography>
      </Box>
      <Box width={1}>
        <Typography variant="body2" className={classes.limitText}>{description}</Typography>
      </Box>
    </Box>
  );
};
