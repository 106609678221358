import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { FEATURES_TYPE, PERMISSION } from '../constants/common';
import { can as uCan } from './acl';

export const useSession = () => {
  const { user, company, planning } = useSelector((state: any) => state.session);
  const plan = planning?.subscriptions[0];

  const can = useCallback(
    (
      resource,
      action = PERMISSION.read,
      feature = FEATURES_TYPE.pos_resources,
    ) => uCan(company, user, resource, action, plan, feature),
    [company, plan, user],
  );

  return {
    user, company, planning, can, PERMISSION,
  };
};
