/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import MUIDataTable from 'mui-datatables';
import Box from '../../../../../../components/ui/Layout/Box';
import Grid from '../../../../../../components/ui/Layout/Grid';
import Modal from '../../../../../../components/ui/Modals/Modal';
// import { generateKey } from "../../../../../../util/keys";
// import MovementRow from "./MovementRow";
import Alert from '../../../../../../components/ui/notifications/alert';
import { COMPARED_RECOUNT } from '../../../../../../constants/common';
import Actions from './Actions';

export default ({
  showModal,
  handleClose,
  title,
  type,
  counted,
  error,
  processing,
  handleSubmit,
}) => {
  const { t } = useTranslation(['recounts']);
  const showQuantities = type === COMPARED_RECOUNT;
  const list = counted.map(item => ({
    product: item.product.name,
    location: item.location.name,
    stock: item.stock,
    counted_quantity: item.counted_quantity,
    discrepancy: item.discrepancy,
  }));

  const columns = [
    {
      name: 'product',
      label: t('recounts:product'),
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'location',
      label: t('recounts:location'),
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'stock',
      label: t('recounts:available_quantity'),
      options: {
        filter: false,
        sort: false,
        display: showQuantities,
        setCellProps: () => ({
          align: 'center',
        }),
      },
    },
    {
      name: 'counted_quantity',
      label: t('recounts:counted_quantity'),
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          align: 'center',
        }),
      },
    },
    {
      name: 'discrepancy',
      label: t('recounts:discrepancy'),
      options: {
        filter: false,
        sort: false,
        display: showQuantities,
        setCellProps: () => ({
          align: 'center',
        }),
      },
    },
  ];

  const actions = counted.length > 0 ? (
    <Actions
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      processing={processing}
    />
  ) : null;

  const options = {
    filter: false,
    search: false,
    print: false,
    download: false,
    viewColumns: false,
    filterType: 'dropdown',
    fixedHeader: true,
    selectableRows: 'none',
    pagination: false,
    onRowClick() {
      // console.log(rowNode);
    },
    serverSide: false,

    textLabels: {
      body: {
        noMatch: '',
      },
    },
    responsive: 'standard',
  };

  return (
    <Modal
      open={showModal}
      onClose={handleClose}
      title={title}
      actions={actions}
    >
      <Box minHeight="400px" minWidth="300px">
        {error && (
          <Grid container>
            <Alert severity="error" message={error} />
          </Grid>
        )}

        {counted.length > 0 ? (
          <MUIDataTable data={list} options={options} columns={columns} />
        ) : (
          <Typography>{t('recounts:no_counts')}</Typography>
        )}
      </Box>
    </Modal>
  );
};
