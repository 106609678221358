import React from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import NavigationButton from './NavigationButton';

const NextButton = props => (
  <NavigationButton {...props}>
    <ArrowForwardIcon />
  </NavigationButton>
);

export default NextButton;
