export const types = {
  EDIT_BRAND: 'EDIT_BRAND',
  CLOSE_BRAND_MODAL: 'CLOSE_BRAND_MODAL',
  SAVING_BRAND: 'SAVING_BRAND',
  SAVE_BRAND_SUCCESS: 'SAVE_BRAND_SUCCESS',
  SAVE_BRAND_FAILURE: 'SAVE_BRAND_FAILURE',
  DELETING_BRAND: 'DELETING_BRAND',
  DELETE_BRAND_SUCCESS: 'DELETE_BRAND_SUCCESS',
  DELETE_BRAND_FAILURE: 'DELETE_BRAND_FAILURE',
  CLEAR_REFRESH: 'CLEAR_REFRESH',
};
