/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSession } from '../../util/session';
import ModalModifier from '../../containers/pages/admin/ModifiersGroupsPage/ModalModifier';
import AsyncPaginatedTable from '../ui/AsyncPaginatedTable';
import SuccessButton from '../ui/Buttons/SuccessButton';
import Box from '../ui/Layout/Box';
import Grid from '../ui/Layout/Grid';
import ProductSelector from '../ui/ProductSelector';
import { ListType } from './types';
import FormattedCurrency from '../ui/FormattedCurrency';

export default ({
  state,
  getProducts,
  resetProducts,
  addSelected,
  removeSelected,
  listType,
  handleAddNewClick,
  onSavedModifier,
  onCloseModifierForm,
}) => {
  const { t } = useTranslation(['general']);

  const { data } = state.response;
  const { selected, addNew } = state;
  const { company } = useSession();

  const autoSelected = [];
  data.forEach((p, index) => {
    if (selected.find(_p => p.id === _p.id)) {
      autoSelected.push(index);
    }
  });

  const columns = [
    {
      name: 'name',
      label: t('general:product'),
      options: {
        filter: true,
        filterType: 'custom',
        filterOptions: {
          display: (filterList, onChange, index, column, filterData) => (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ProductSelector
                  company_id={company ? company.id : ''}
                  setProduct={(product) => {
                    if (product) {
                      filterList[index][1] = product ? product.value : '';
                      onChange(filterList[index], index, column, filterData);
                    }
                  }}
                />
              </Grid>
            </Grid>
          ),
        },
        sort: false,
      },
    },

    {
      name: 'variants',
      label: t('general:price'),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (variants) => {
          if (variants && variants[0]) {
            return (
              <FormattedCurrency value={variants[0].price} />
            );
          }
          return '';
        },
      },
    },
  ];

  const _addSelectedProducts = (products) => {
    addSelected(products);
  };

  const _removeSelectedProducts = (products) => {
    removeSelected(products);
  };

  const handleSelected = (currentRowsSelected) => {
    if (currentRowsSelected.length) {
      const toDelete = [];
      const toAdd = [];
      currentRowsSelected.forEach((row) => {
        const product = data[row.dataIndex];

        const selectedProduct = selected.find(p => p.id === product.id);

        if (selectedProduct) {
          toDelete.push(product);
        } else {
          toAdd.push(product);
        }
      });
      if (toAdd.length) {
        _addSelectedProducts(toAdd);
      }
      if (toDelete.length) {
        _removeSelectedProducts(toDelete);
      }
    } else {
      _removeSelectedProducts(data);
    }
  };

  return (
    <Box>
      <Box mt={3}>
        {listType === ListType.MODIFIERS && (
          <Box mb={2} display="flex" justifyContent="flex-end">
            <SuccessButton
              text={t('general:modifiers_groups:add_modifier')}
              onClick={handleAddNewClick}
            />
          </Box>
        )}

        {addNew && <ModalModifier onSavedModifier={onSavedModifier} onClose={onCloseModifierForm} />}

        <AsyncPaginatedTable
          hasNextPage={state.response.page < state.response.last_page}
          currentPage={state.response.page}
          perPage={state.response.per_page}
          data={data}
          columns={columns}
          loadData={params => getProducts(params)}
          resetData={resetProducts}
          processing={state.processing}
          total={state.response.total}
          options={{
            filter: false,
            responsive: 'standard',
            selectableRowsHeader: true,
            selectableRows: 'multiple',
            rowsPerPageOptions: [5, 10, 20],
            rowsSelected: autoSelected,
            onRowSelectionChange: (
              currentRowsSelected,
            ) => {
              handleSelected(currentRowsSelected);
            },
            search: true,
            selectToolbarPlacement: 'none',
          }}
          withDispatch={false}
          searchField="name"
        />
      </Box>
    </Box>
  );
};
