/* eslint-disable no-restricted-globals */
/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/prop-types */
import {
  Grid, TextField, Typography,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles, withStyles } from '@mui/styles';
import { addCostValue } from '../../../../../actions/productsCost';

const useStyles = makeStyles(() => ({
  itemsContainer: {
    margin: '15px 0px',
    display: 'grid',
    gridTemplateColumns: '1fr 2fr 1fr 1fr 1fr 1fr',
  },
  img: {
    display: 'block',
    height: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
  },
  limitText: {
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const CTextField = withStyles(theme => ({
  root: {
    '& .MuiOutlinedInput-input': {
      padding: 5,
      marginLeft: 15,
      [theme.breakpoints.down('lg')]: {
        fontSize: 12,
        marginLeft: 9,
        padding: '4px 2px',
      },
    },
    '& .MuiInputdefaultValue-formControl': {
      position: 'absolute',
    },
    '& .MuiOutlinedInput-root': {
      width: '80%',
      margin: '0px auto',
      position: 'relative',
      '&::before': {
        content: "'$'",
        position: 'absolute',
        left: 7,
      },
      [theme.breakpoints.down('lg')]: {
        '&::before': {
          fontSize: 11,
          left: 2,
        },
      },
    },
  },
}))(TextField);

const CTextFieldPor = withStyles(theme => ({
  root: {
    '& .MuiOutlinedInput-input': {
      padding: 5,
      marginLeft: 15,
      [theme.breakpoints.down('lg')]: {
        fontSize: 12,
        marginLeft: 9,
        padding: '4px 2px',
      },
    },
    '& .MuiInputdefaultValue-formControl': {
      position: 'absolute',
    },
    '& .MuiOutlinedInput-root': {
      width: '80%',
      margin: '0px auto',
      position: 'relative',
      '&::before': {
        content: "'%'",
        position: 'absolute',
        left: 7,
      },
      [theme.breakpoints.down('lg')]: {
        '&::before': {
          fontSize: 11,
          left: 2,
        },
      },
    },
  },
}))(TextField);

export default ({ data }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [cost, setCost] = useState(data.variants[0].cost);
  const [price, setPrice] = useState(data.variants[0].price);

  const _utility = price - cost;
  const _gain = (100 / cost) * (_utility);

  const handleSetCost = (e) => {
    setCost(e.target.value);
  };

  const handleSetPrice = (e) => {
    setPrice(e.target.value);
  };

  const handleSetValues = useCallback((costvalue, pricevalue) => {
    dispatch(addCostValue({ id: data.id, cost: costvalue ? parseInt(costvalue, 10) : data.variants[0].cost, price: pricevalue ? parseInt(pricevalue, 10) : data.variants[0].price }));
  }, [data.variants, data.id, dispatch]);

  useEffect(() => {
    if (data.id && (cost !== data.variants[0].cost || price !== data.variants[0].price)) {
      handleSetValues(cost, price);
    }
  }, [
    data.id,
    cost,
    price,
    data.variants,
    handleSetValues,
  ]);

  return (
    <Grid className={classes.itemsContainer}>
      <Grid>
        <span className={classes.img} style={{ backgroundImage: `url(${data.variants[0].image_url})` }} />
      </Grid>
      <Grid style={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant="h6" style={{ lineHeight: 1 }}>
          { data.name }
        </Typography>
        <Typography variant="caption" className={classes.limitText} style={{ lineHeight: 1 }}>
          { data.description }
        </Typography>
        <Typography variant="caption" style={{ lineHeight: 1 }}>
          { data.variants[0].barcode }
        </Typography>
      </Grid>
      <CTextField
        id="outlined-basic"
        variant="outlined"
        style={{ alignSelf: 'center' }}
        value={cost}
        onChange={handleSetCost}
      />
      <CTextFieldPor
        id="outlined-basic"
        value={isNaN(parseInt(_gain.toFixed(0), 10)) ? 0 : _gain.toFixed(0)}
        variant="outlined"
        style={{ alignSelf: 'center' }}
        disabled
      />
      <CTextField
        id="outlined-basic"
        variant="outlined"
        style={{ alignSelf: 'center' }}
        value={price}
        onChange={handleSetPrice}
      />
      <CTextField
        id="outlined-basic"
        value={_utility || 0}
        variant="outlined"
        style={{ alignSelf: 'center' }}
        disabled
      />
    </Grid>
  );
};
