import { useReducer } from 'react';
import {
  CampaignStatus,
  PAGINATED_TABLE_PER_PAGE,
} from '../../../../../../../constants/common';
import AuthorizedApiClient from '../../../../../../../core/common/api/AuthorizedApiClient';
import { useSession } from '../../../../../../../util/session';
import {
  SET_CAMPAIGNS_LIST_ERROR,
  SET_CAMPAIGNS_LIST_PROCESSING,
  SET_CAMPAIGNS_LIST_SUCCESS,
  SET_CAMPAIGNS_LIST_PARAMS,
  SET_CAMPAIGNS_RESET_FILTERS,
} from '../constants';
import campaignListReducer from '../reducers/campaigns';

const initialState = {
  processing: false,
  response: {
    data: [],
    total: 20,
    per_page: PAGINATED_TABLE_PER_PAGE,
    last_page: 2,
    page: 1,
  },
  params: {
    page: 1,
    per_page: PAGINATED_TABLE_PER_PAGE,
    sort: { created_at: 'desc' },

    filters: {
      status: CampaignStatus.Active,
    },
  },
  refresh: false,
};

const useCampaginListState = () => {
  const [state, dispatch] = useReducer(campaignListReducer, initialState);
  const { company } = useSession();

  const setProcessing = (processing) => {
    dispatch({ type: SET_CAMPAIGNS_LIST_PROCESSING, payload: processing });
  };

  const setCampaignsFailure = (error) => {
    dispatch({ type: SET_CAMPAIGNS_LIST_ERROR, payload: error });
  };

  const setCampaignsResponse = (response) => {
    dispatch({ type: SET_CAMPAIGNS_LIST_SUCCESS, payload: response });
  };

  const getCampaigns = async (params = {}) => {
    if (!company) {
      return;
    }
    const sg = new AuthorizedApiClient();
    const path = `companies/${company.id}/campaigns`;

    setProcessing(true);
    try {
      const response = await sg.get(params, path);

      if (response != null) {
        setCampaignsResponse(response.data);
      }
    } catch (error) {
      setCampaignsFailure(error.response.data);
    }
  };

  const refreshList = () => {
    getCampaigns(initialState.params);
  };

  const setParams = (params) => {
    dispatch({ type: SET_CAMPAIGNS_LIST_PARAMS, payload: params });
  };

  const resetFilters = () => {
    dispatch({
      type: SET_CAMPAIGNS_RESET_FILTERS,
      payload: initialState.params.filters,
    });
  };

  return {
    state,
    getCampaigns,
    refreshList,
    setParams,
    resetFilters,
  };
};

export default useCampaginListState;
