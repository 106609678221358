import { useEffect, useState } from 'react';
import { PERMISSION } from '../constants/common';
import { useSession } from './session';

export const useAuthorization = (section = null) => {
  const { can, user, company } = useSession();
  const [authorized, setAuthorized] = useState(null);

  useEffect(() => {
    if (!section) {
      setAuthorized(true);
    }
    if (user && company) {
      if (section) {
        setAuthorized(can(section, PERMISSION.read));
      } else {
        setAuthorized(true);
      }
    }
  }, [user, company, section, can]);

  return { authorized };
};
