/* eslint-disable no-restricted-syntax */
/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import FormInput from '../../../../../components/ui/FormInput';
import Grid from '../../../../../components/ui/Layout/Grid';

import CategorySelector from './CategorySelector';
import BrandSelector from './BrandSelector';
import UnitSelector from './UnitSelector';
import useSignUploadUrl from '../../../../../components/ui/ImageUploader/useSignUploadUrl';
import { MultiImgUploader } from '../../../../../components/ui/MultiImgUploader';

export default ({
  formData,
  setFormData,
  register,
  errors,
  tempImages,
  setTempImages,
}) => {
  const imgUrl = formData.assets ?? null;
  const { signUrl } = useSignUploadUrl('products');
  const { t } = useTranslation('general');

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <MultiImgUploader
          imgUrl={imgUrl}
          signUrl={signUrl}
          tempImages={tempImages}
          setTempImages={setTempImages}
        />
      </Grid>
      <Grid item xs={12}>
        <FormInput
          register={register('name')}
          error={errors.name}
          label={t('general:product_name')}
          defaultValue={formData.name ? formData.name : ''}
        />
      </Grid>

      <Grid item xs={12}>
        <FormInput
          register={register('barcode')}
          error={errors.barcode}
          label="Código de barras (Opcional)"
          defaultValue={formData.barcode ?? ''}
        />
      </Grid>

      <Grid item xs={12}>
        <FormInput
          register={register('description')}
          type="textarea"
          error={errors.description}
          label={t('general:description_placeholder')}
          defaultValue={formData.description ? formData.description : ''}
          placeholder={t('general:description_placeholder')}
        />
      </Grid>

      <Grid container item spacing={1}>
        <Grid item xs={12} sm={4}>
          <CategorySelector
            selected={formData.selectedCategory}
            handleOnChange={setFormData}
            register={register}
            error={errors.category_id}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <UnitSelector
            selected={formData.selectedUnit}
            handleOnChange={setFormData}
            error={errors.selectedUnit}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <BrandSelector
            selected={formData.selectedBrand}
            handleOnChange={setFormData}
            register={register}
            error={errors.brand_id}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
