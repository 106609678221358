/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import { useReducer } from 'react';
import {
  FILTER_ALL_VALUE,
  PAGINATED_TABLE_PER_PAGE,
} from '../../../../../../constants/common';
import { ProductType } from '../../../../../../core/contracts/data/ProductType';
import ApiProductsGateway from '../../../../../../core/gateways/api/ApiProductsGateway';
import { useSession } from '../../../../../../util/session';

import {
  SET_PRODUCTS_LIST_ERROR,
  SET_PRODUCTS_LIST_PROCESSING,
  SET_PRODUCTS_LIST_SUCCESS,
  SET_PRODUCTS_LIST_PARAMS,
  SET_PRODUCTS_RESET_FILTERS,
} from '../constants';
import productsListReducer from '../reducers/products';

const initialState = {
  processing: false,
  response: {
    data: [],
    total: 20,
    per_page: PAGINATED_TABLE_PER_PAGE,
    last_page: 1,
    page: 1,
  },
  params: {
    page: 1,
    per_page: PAGINATED_TABLE_PER_PAGE,
    sort: { created_at: 'desc' },
    embed: 'category',
    filters: { type: ProductType.REGULAR },
  },
  refresh: false,
};

const parseParams = (params) => {
  const _params = JSON.parse(JSON.stringify(params));

  if (
    _params.filters
    && _params.filters.type
    && _params.filters.type === FILTER_ALL_VALUE
  ) {
    _params.filters.type = {
      $in: [ProductType.REGULAR, ProductType.MODIFIER],
    };
  }

  if (_params.filters && _params.filters.category) {
    _params.filters.category_id = _params.filters.category;

    delete _params.filters.category;
  }

  return _params;
};

const useProductsListState = () => {
  const [state, dispatch] = useReducer(productsListReducer, initialState);
  const { company } = useSession();

  const setProcessing = (processing) => {
    dispatch({ type: SET_PRODUCTS_LIST_PROCESSING, payload: processing });
  };

  const setProductsFailure = (error) => {
    dispatch({ type: SET_PRODUCTS_LIST_ERROR, payload: error });
  };

  const setProductsResponse = (response) => {
    dispatch({ type: SET_PRODUCTS_LIST_SUCCESS, payload: response });
  };

  const getProducts = async (params = {}) => {
    if (!company) {
      return;
    }
    const sg = ApiProductsGateway.getInstance();

    setProcessing(true);
    try {
      const response = await sg.get(
        {
          ...parseParams(params),
        },
        company.id,
      );

      if (response != null) {
        setProductsResponse(response);
      }
    } catch (error) {
      setProductsFailure(error);
    }
  };

  const refreshList = () => {
    getProducts(initialState.params);
  };

  const setParams = (params) => {
    dispatch({ type: SET_PRODUCTS_LIST_PARAMS, payload: params });
  };

  const resetFilters = () => {
    dispatch({
      type: SET_PRODUCTS_RESET_FILTERS,
      payload: initialState.params.filters,
    });
  };

  return {
    state,
    getProducts,
    refreshList,
    setParams,
    resetFilters,
  };
};

export default useProductsListState;
