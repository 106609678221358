/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Alert from '../../../../../../components/ui/notifications/alert';
import { rules as validationRules } from '../../../../../../util/Validations';
import Modal from '../../../../../../components/ui/Modals/Modal';
import Box from '../../../../../../components/ui/Layout/Box';
import Grid from '../../../../../../components/ui/Layout/Grid';
import FormInput from '../../../../../../components/ui/FormInput';
import Actions from './Actions';

export default ({
  user,
  attemptSaveUser,
  processing,
  error,
  handleClose,
  showModal,
}) => {
  const { t } = useTranslation([
    'general',
  ]);

  const schema = Yup.object({
    name: Yup.string()
      .max(validationRules.maxLength)
      .required(
        t('translation:required', {
          input: t('general:settings:name'),
        }),
      ),
    last_name: Yup.string()
      .max(validationRules.maxLength)
      .required(
        t('translation:required', {
          input: t('general:settings:last_name'),
        }),
      ),
    email: Yup.string()
      .email(t('translation:invalid_email'))
      .max(validationRules.maxLength)
      .required(
        t('translation:required', {
          input: t('general:settings:email'),
        }),
      ),
  });
  const { register, handleSubmit, formState: { errors } } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    data.id = user.id ? user.id : null;
    attemptSaveUser(data);
  };

  const actions = (
    <Actions
      handleClose={handleClose}
      handleSubmit={handleSubmit(onSubmit)}
      processing={processing}
    />
  );

  const title = t('general:settings:create_user');

  return (
    <Modal
      open={showModal}
      onClose={handleClose}
      title={title}
      actions={actions}
      maxWidth="xs"
    >
      <Box>
        {error && (
          <Grid container>
            <Alert severity="error" message={t(`general:settings:${error}`, error)} />
          </Grid>
        )}
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormInput
                register={register('name')}
                error={errors.name}
                label={t('general:settings:name')}
                defaultValue={user.name ? user.name : ''}
              />
            </Grid>

            <Grid item xs={12}>
              <FormInput
                register={register('last_name')}
                error={errors.last_name}
                label={t('general:settings:last_name')}
                defaultValue={user.last_name ? user.last_name : ''}
              />
            </Grid>

            <Grid item xs={12}>
              <FormInput
                register={register('email')}
                error={errors.email}
                label={t('general:settings:email')}
                defaultValue={user.email ? user.email : ''}
              />
            </Grid>
          </Grid>
        </form>
      </Box>
    </Modal>
  );
};
