export const types = {
  EDIT_PRODUCT: 'EDIT_PRODUCT',
  CLOSE_PRODUCT_MODAL: 'CLOSE_PRODUCT_MODAL',
  SAVING_PRODUCT: 'SAVING_PRODUCT',
  SAVE_PRODUCT_SUCCESS: 'SAVE_PRODUCT_SUCCESS',
  SAVE_PRODUCT_FAILURE: 'SAVE_PRODUCT_FAILURE',
  DELETING_PRODUCT: 'DELETING_PRODUCT',
  DELETE_PRODUCT_SUCCESS: 'DELETE_PRODUCT_SUCCESS',
  DELETE_PRODUCT_FAILURE: 'DELETE_PRODUCT_FAILURE',
  CLEAR_REFRESH: 'CLEAR_REFRESH',

  SET_CATEGORIES_PRODUCT_PROCESSING: 'SET_CATEGORIES_PRODUCT_PROCESSING',
  SET_CATEGORIES_PRODUCT_SUCCESS: 'SET_CATEGORIES_PRODUCT_SUCCESS',
  SET_CATEGORIES_PRODUCT_FAILURE: 'SET_CATEGORIES_PRODUCT_FAILURE',
  SAVE_CATEGORY_SUCCESS: 'SAVE_CATEGORY_SUCCESS',
  CLEAR_ADDED_CATEGORY: 'CLEAR_ADDED_CATEGORY',

  SET_UNITOFMEASURES_PRODUCT_PROCESSING: 'SET_UNITOFMEASURES_PRODUCT_PROCESSING',
  SET_UNITOFMEASURES_PRODUCT_SUCCESS: 'SET_UNITOFMEASURES_PRODUCT_SUCCESS',
  SET_UNITOFMEASURES_PRODUCT_FAILURE: 'SET_UNITOFMEASURES_PRODUCT_FAILURE',
  SAVE_UNITOFMEASURE_SUCCESS: 'SAVE_UNITOFMEASURE_SUCCESS',
  CLEAR_ADDED_UNITOFMEASURE: 'CLEAR_ADDED_UNITOFMEASURE',

  SET_BRANDS_PRODUCT_PROCESSING: 'SET_BRANDS_PRODUCT_PROCESSING',
  SET_BRANDS_PRODUCT_SUCCESS: 'SET_BRANDS_PRODUCT_SUCCESS',
  SET_BRANDS_PRODUCT_FAILURE: 'SET_BRANDS_PRODUCT_FAILURE',
  SAVE_BRAND_SUCCESS: 'SAVE_BRAND_SUCCESS',
  CLEAR_ADDED_BRAND: 'CLEAR_ADDED_BRAND',

  SET_SUPPLIERS_PRODUCT_PROCESSING: 'SET_SUPPLIERS_PRODUCT_PROCESSING',
  SET_SUPPLIERS_PRODUCT_SUCCESS: 'SET_SUPPLIERS_PRODUCT_SUCCESS',
  SET_SUPPLIERS_PRODUCT_FAILURE: 'SET_SUPPLIERS_PRODUCT_FAILURE',
  SAVE_SUPPLIER_SUCCESS: 'SAVE_SUPPLIER_SUCCESS',
  CLEAR_ADDED_SUPPLIER: 'CLEAR_ADDED_SUPPLIER',

  SAVE_MOVEMENT_SUCCESS: 'SAVE_MOVEMENT_SUCCESS',

  SET_PRODUCT_SUCCESS: 'SET_PRODUCT_SUCCESS',
  SET_PRODUCT_FAILURE: 'SET_PRODUCT_FAILURE',

  SET_PRODUCT_TAB: 'SET_PRODUCT_TAB',
};
