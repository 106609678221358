/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { makeStyles } from '@mui/styles';
import React from 'react';

const styles = makeStyles(() => ({
  openButton: {
    width: 75,
    height: 5,
    borderRadius: 2.5,
    backgroundColor: 'black',
    top: 10,
    position: 'absolute',
    cursor: 'pointer',
    left: 'calc(50% - 37.5px)',
  },
}));

export default (props) => {
  const classes = styles();
  return (
    <span onClick={props.click} className={classes.openButton} />
  );
};
