import React from 'react';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Breadcrumbs, Typography, Paper } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { navigations } from '../../../theme/ECTheme/Navigations';

const useStyles = makeStyles(theme => ({
  root: {
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
    padding: theme.spacing(1),
  },
  Link: {
    color: theme.palette.secondary.main,
  },
}));

type BreadCrumbProps = {
  title?: string;
};

export default ({ title }: BreadCrumbProps) => {
  const classes = useStyles();
  const location = useLocation();
  const { t } = useTranslation(['general']);

  const paths = location.pathname.split('/').filter(el => el.length > 0);

  const current = paths[paths.length - 1];

  const items = [];
  paths.forEach((path) => {
    let item;
    const navigationItem = navigations[path];

    const label = navigationItem !== undefined
      ? t(`general:${navigationItem.label}`)
      : path;
    if (path !== current) {
      item = (
        <Link key={path} to={`${navigationItem.path}`} className={classes.Link}>
          {label}
        </Link>
      );
    } else if (title) {
      item = (
        <Typography key={path} color="textSecondary">
          {title}
        </Typography>
      );
    } else {
      item = (
        <Typography key={path} color="textSecondary">
          {label}
        </Typography>
      );
    }

    items.push(item);
  });

  return (
    <Paper className={classes.root}>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        {items}
      </Breadcrumbs>
    </Paper>
  );
};
