/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SalesListView from './SalesListView';
import {
  clearRefresh,
  downloadSales,
  getSales,
  resetSales,
  setParams,
} from '../../../../../../actions/sales';
import { ViewFormats } from '../../../../../../constants/common';

export default () => {
  const dispatch = useDispatch();
  const state = useSelector((states: any) => states.sales);
  const { refresh } = state;
  const company = useSelector((states: any) => states.session.company);
  const { params } = state;

  const _getSales = useCallback(
    async (_params = {}) => {
      if (company) {
        const updatedParams = { ...params, ..._params };
        // console.log("updatedParams", updatedParams)
        if (updatedParams.filters && updatedParams.filters.campaigns) {
          updatedParams.filters.campaign_id = updatedParams.filters.campaigns;
          delete updatedParams.filters.campaigns;
        }
        dispatch(setParams(updatedParams));

        await dispatch(getSales(company.id, updatedParams));
      }
    },
    [dispatch, company, params],
  );

  const _downloadSales = useCallback(
    async () => {
      if (company) {
        const updatedParams = { ...params, format: ViewFormats.EXCEL };

        await dispatch(downloadSales(company.id, updatedParams));
      }
    },
    [dispatch, company, params],
  );

  useEffect(() => {
    if (refresh && company) {
      dispatch(resetSales());
      dispatch(clearRefresh());
      _getSales();
    }
  }, [refresh, company, dispatch, _getSales]);

  return (
    <SalesListView
      state={state}
      getSales={_getSales}
      resetSales={resetSales || refresh}
      downloadSales={_downloadSales}
    />
  );
};
