export const types = {
  SET_SALES_PROCESSING: 'SET_SALES_PROCESSING',
  SET_SALES_SUCCESS: 'SET_SALES_SUCCESS',
  SET_SALES_FAILURE: 'SET_SALES_FAILURE',
  RESET_SALES: 'RESET_SALES',
  SET_SALES_PARAMS: 'SET_SALES_PARAMS',

  CLEAR_SALES_REFRESH: 'CLEAR_SALES_REFRESH',

};
