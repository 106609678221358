export const types = {
  SET_WIZARD_STEP: 'SET_WIZARD_STEP',
  SET_FOOD: 'SET_FOOD',
  SET_CATEGORIES: 'SET_CATEGORIES',
  SET_PRODUCTION_VOLUME: 'SET_PRODUCTION_VOLUME',
  SET_CUSTOMER_TYPES: 'SET_CUSTOMER_TYPES',
  SET_DELIVERIES_RANGES: 'SET_DELIVERIES_RANGES',
  SET_BUSINESS_TYPE: 'SET_BUSINESS_TYPE',
  SET_INVOICE: 'SET_INVOICE',
  SET_KEYWORDS: 'SET_KEYWORDS',
  CLEAR_WIZARD: 'CLEAR_WIZARD',
  SAVING_WIZARD: 'SAVING_WIZARD',
  SAVE_WIZARD_SUCCESS: 'SAVE_WIZARD_SUCCESS',
  SAVE_WIZARD_FAILURE: 'SAVE_WIZARD_FAILURE',
  SET_WIZARD_BRANCH: 'SET_WIZARD_BRANCH',
  SET_FINISH: 'SET_FINISH',
  SAVE_STORE_PROFILE_SUCCESS: 'SAVE_STORE_PROFILE_SUCCESS',
};
