/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import Box from '../../../../../components/ui/Layout/Box';
import ConfirmDialog from '../../../../../components/ui/ConfirmDialog';
import Grid from '../../../../../components/ui/Layout/Grid';
import Alert from '../../../../../components/ui/notifications/alert';
import FormInput from '../../../../../components/ui/FormInput';
import { rules as validationRules } from '../../../../../util/Validations';
import Modal from '../../../../../components/ui/Modals/Modal';
import Actions from '../../../../../components/ui/Modals/Actions/Actions';

export default ({
  unitOfMeasure,
  attemptSaveUnitOfMeasure,
  attemptDeleteUnitOfMeasure,
  processing,
  deletingUnitOfMeasure,
  error,
  closeModalUnitOfMeasure,
}) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const { showModal } = useSelector((state: any) => state.unitofmeasure);
  const { t } = useTranslation(['units', 'translation', 'general']);

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(closeModalUnitOfMeasure());
  };

  const title = unitOfMeasure.id == null
    ? t('units:create_unit')
    : t('units:edit_unit', { name: unitOfMeasure.code });

  const schema = Yup.object({
    code: Yup.string()
      .max(validationRules.maxLength)
      .required(
        t('translation:required', {
          input: t('units:unit'),
        }),
      ),
    description: Yup.string()
      .max(validationRules.maxLength),
  });
  const { register, handleSubmit, formState: { errors } } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    data.id = unitOfMeasure.id ? unitOfMeasure.id : null;

    attemptSaveUnitOfMeasure(data);
  };

  const actions = (
    <Actions
      handleClose={handleClose}
      handleSubmit={handleSubmit(onSubmit)}
      handleDelete={() => setShowConfirm(true)}
      isNew={unitOfMeasure.id === null}
      processing={processing}
      deleting={deletingUnitOfMeasure}
    />
  );

  return (
    <Modal
      open={showModal}
      onClose={handleClose}
      title={title}
      actions={actions}
    >
      <Box>
        <ConfirmDialog
          open={showConfirm}
          handleCancel={() => {
            setShowConfirm(false);
          }}
          handleConfirm={() => {
            setShowConfirm(false);
            attemptDeleteUnitOfMeasure(unitOfMeasure);
          }}
          title={t('units:delete_unit')}
          content={t('units:delete_confirmation_msg')}
        />
        {error && (
          <Grid container>
            <Alert severity="error" message={error} />
          </Grid>
        )}
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <Box mt={2}>
                    <FormInput
                      register={register('code')}
                      error={errors.code}
                      label={t('units:unit')}
                      defaultValue={
                        unitOfMeasure.code ? unitOfMeasure.code : ''
                      }
                    />
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <Box mt={3}>
                    <FormInput
                      register={register('description')}
                      error={errors.description}
                      type="textarea"
                      label={t('general:descriptionOpc')}
                      defaultValue={
                        unitOfMeasure.description
                          ? unitOfMeasure.description
                          : ''
                      }
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Modal>
  );
};
