/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import NotchedOutline from '@mui/material/OutlinedInput/NotchedOutline';
import { makeStyles } from '@mui/styles';
import Box from '../../components/ui/Layout/Box';

const useStyle = makeStyles(() => ({
  root: {
    position: 'relative',
    borderRadius: '30px',
    '& fieldset': {
      borderRadius: '7px',
    },
  },
  content: {
    padding: '18.5px 14px',
  },
  inputLabel: {
    fontSize: '1.2rem',
    pointerEvents: 'initial',
  },
}));

export default (props) => {
  const classes = useStyle();
  // const [labelWidth, setLabelWidth] = React.useState(0);
  // const labelRef = React.useRef(null);
  const { label, children, id } = props;
  // React.useEffect(() => {
  //   // TODO: check this logic.
  //   // setLabelWidth(
  //   //   labelRef != null
  //   //     ? labelRef.current.offsetWidth
  //   //         + (props.customWidth ? props.customWidth : 10)
  //   //     : 0,
  //   // );
  // }, [props.label, props.customWidth]);

  return (
    <Box style={{ position: 'relative', width: '100%' }}>
      <InputLabel
        // ref={labelRef}
        htmlFor={id}
        variant="outlined"
        className={classes.inputLabel}
        shrink
      >
        {label}
      </InputLabel>
      <Box className={classes.root}>
        <Box id={id} className={classes.content}>
          {children}
          <NotchedOutline notched label={label} />
        </Box>
      </Box>
    </Box>
  );
};
