/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { generateRamdomNumber } from '../../../../../util/keys';
import styles from './CategoryImage.module.css';

export default (props) => {
  const url = props.url
    ? props.url
    : `${process.env.PUBLIC_URL}/images/groceries.png`;
  return (
    <div className={`text-center ${styles.CategoryImageContainer}`}>
      <span
        style={{ backgroundImage: `url(${url}?v=${generateRamdomNumber()})` }}
        className={styles.CategoryImage}
      />
    </div>
  );
};
