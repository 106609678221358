/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import Alert from '@mui/material/Alert';
import Box from '../../Layout/Box';

export default (props) => {
  const [visible, setVisible] = useState(true);
  const {
    message, clearAlert, autoClose = true, ...rest
  } = props;

  useEffect(() => {
    let s;
    if (autoClose) {
      s = setTimeout(() => {
        setVisible(false);
        if (clearAlert) {
          clearAlert();
        }
      }, 10000);
    }
    return () => {
      if (s) {
        clearTimeout(s);
      }
    };
  }, [visible, clearAlert]);

  return (
    <Box mb={2}>
      {visible && (
      <Alert {...rest} onClose={() => { setVisible(false); }}>
        {message}
      </Alert>
      )}
    </Box>
  );
};
