/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/prop-types */
import React, { useCallback, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { FormControlLabel, Switch, Typography } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import Axios from 'axios';
import { useSelector } from 'react-redux';
import Box from '../../../../../../../components/ui/Layout/Box';
import Toggle from './Toggle';
import Circle from './Circle';
import { isAdminUser } from '../../../../../../../util/acl';

import PermissionsManager from './PermissionsManager';
import { BASE_URL } from '../../../../../../../constants/api/ApiConstants';

const styles = makeStyles(() => ({
  root: {
    border: '0.5px solid #9A9494',
    borderRadius: '3px',
  },
  title: {
    cursor: 'pointer',
  },
}));

export default ({ user, resetUsers, getUsers }) => {
  const classes = styles();
  const [expanded, setExpanded] = useState(false);
  const company = useSelector((state: any) => state.session.company);
  const [status, setStatus] = React.useState(user.status === 'active');

  const _resetUsers = useCallback(() => {
    resetUsers();
    getUsers();
  }, [resetUsers, getUsers]);

  const isAdmin = isAdminUser(user);

  const handleTogleClick = () => {
    if (!isAdmin) {
      setExpanded(!expanded);
    }
  };

  const changeStatusUser = (event: React.ChangeEvent<HTMLInputElement>) => {
    const statusUser = {
      status: event.target.checked ? 'active' : 'disabled',
    };

    setStatus(event.target.checked);

    Axios.put(`${BASE_URL}/api/v1/companies/${company.id}/users/${user.id}`, statusUser)
      .then(() => {
      });
  };
  return (
    <Box className={classes.root} mb={1} p={1}>
      <Box
        width={1}
        className={classes.title}
        display="flex"
        flexDirection="row"
      >
        <Box>
          <Circle isAdmin={isAdmin} />
        </Box>
        <Box display="flex" flexGrow={1} pl={1} pr={1} alignItems="center">
          <Typography>
            {user.name}
            {' '}
            {user.last_name}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <div style={{ marginRight: 5 }}>
            {isAdmin
              ? null
              : (
                <FormControlLabel
                  value="start"
                  control={<Switch checked={status} onChange={changeStatusUser} color="primary" />}
                  label={status ? 'Acceso habilitado' : 'Acceso denegado'}
                  labelPlacement="start"
                />
              )}
          </div>
          {isAdmin ? <LockIcon /> : <Toggle onClick={handleTogleClick} expanded={expanded} />}
        </Box>
      </Box>
      {!isAdmin && expanded && (
        <Box m={1}>
          <PermissionsManager resetUsers={_resetUsers} user={user} />
        </Box>
      )}
    </Box>
  );
};
