/* eslint-disable no-restricted-syntax */
import { types } from './types';
import Log from '../../util/Log';
import AuthorizedApiClient from '../../core/common/api/AuthorizedApiClient';

export const editBrand = (brand = null) => ({
  type: types.EDIT_BRAND,
  payload: brand,
});

export const savingBrand = () => ({
  type: types.SAVING_BRAND,
});

export const saveBrandSucceed = response => ({
  type: types.SAVE_BRAND_SUCCESS,
  response,
});

export const saveBrandFailed = error => ({
  type: types.SAVE_BRAND_FAILURE,
  error,
});

export const deletingBrand = () => ({
  type: types.DELETING_BRAND,
});

export const deleteBrandSucceed = response => ({
  type: types.DELETE_BRAND_SUCCESS,
  response,
});

export const deleteBrandFailed = error => ({
  type: types.DELETE_BRAND_FAILURE,
  error,
});

export const closeModalBrand = () => ({
  type: types.CLOSE_BRAND_MODAL,
});

export const saveBrand = (_data, companyId) => async (dispatch: any) => {
  dispatch(savingBrand());
  const sg = new AuthorizedApiClient();
  let path = `companies/${companyId}/brands`;

  try {
    let response;
    const { id } = _data;
    const data = _data;

    if (id != null) {
      path = `${path}/${id}`;
      delete data.id;
      response = await sg.put(data, path);
    } else {
      delete data.id;
      response = await sg.post(data, path);
      //   {
      //   'Content-Type': 'multipart/form-data',
      // }
    }

    if (response != null) {
      Log.debug('saved Brand', response);
      dispatch(saveBrandSucceed(response));
      dispatch(closeModalBrand());
    }
  } catch (error) {
    Log.error(error.message);
    dispatch(saveBrandFailed(error.message));
  }
};

export const deleteBrand = (brand, companyId) => async (dispatch: any) => {
  dispatch(deletingBrand());
  const sg = new AuthorizedApiClient();
  let path = `companies/${companyId}/brands`;
  try {
    if (!brand.id) {
      throw new Error('The id is required');
    }

    path = `${path}/${brand.id}`;
    const response = await sg.delete(path);

    if (response != null) {
      Log.debug('deleted brand', response);
      dispatch(deleteBrandSucceed(response));
      dispatch(closeModalBrand());
    }
  } catch (error) {
    Log.error(error.message);
    dispatch(deleteBrandFailed(error.message));
  }
};
