/* eslint-disable max-len */
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Alert from '@mui/material/Alert';
import {
  Button,
} from '@mui/material';
import { ShepherdTourContext } from 'react-shepherd';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { makeStyles } from '@mui/styles';
import Grid from '../../../../components/ui/Layout/Grid';
import PageContainer from '../../../../hoc/PageContainer';
import Box from '../../../../components/ui/Layout/Box';
import AddButton from '../../../../components/ui/Buttons/BlueButton/AddButton';
import SectionListContainer from '../../../../hoc/SectionListContainer';
import ProductForm, { ProductFormType } from './ProductForm';
import AdminCost from './ProductForm/AdminCost';
import { useSession } from '../../../../util/session';
import { PERMISSION, RESOURCES } from '../../../../constants/common';
import ExcelButton from '../../../../components/ui/Buttons/ExcelButton';
import ProductsExcel from './ProductsExcel';
import QuantityForm from './QuantityForm';
import ProductsList from './ProductsList';
import { ListOfProducts } from './ListOfProducts';
import SwitchList from '../../../../components/inputs/SwitchList';
import { ProductInterface } from '../../../../helpers/interfaces/product';
import useProducts from '../../../../hooks/useProducts';
import DownloadExcelButton from '../../../../components/ui/Buttons/DownloadExcelButton';

const useStyles = makeStyles(() => ({
  buttonHelp: {
    padding: 3,
    margin: '0px 3px',
    minWidth: 0,
    width: 42,
    height: 42,
    borderRadius: 21,
  },
}));

const FILE_NAME = 'productos.xlsx';

interface ProductsPageProps {
  openProductsExcel(): void;
}

export default ({ openProductsExcel }: ProductsPageProps) => {
  const classes = useStyles();
  const { t } = useTranslation('general');
  const tour = useContext(ShepherdTourContext);
  const [productModalState, setProductModalState] = useState<{
    product: ProductFormType,
    isOpen: boolean,
    inventories: any[]
  }>({
    product: null,
    isOpen: false,
    inventories: [],
  });
  const [modalCost, setModalCost] = useState(false);
  const [modalQuantity, setModalQuantity] = useState(false);
  const [list, setList] = useState(true);
  const { can, company } = useSession();
  const prodctsControllers = useProducts(company.id);

  const finishedSteps = JSON.parse(window.localStorage.getItem('finished-tour-steps'));

  useEffect(() => {
    prodctsControllers.getProducts();
  }, [prodctsControllers.getProducts]);

  const canCreate = can(RESOURCES.products, PERMISSION.create);
  const canEdit = can(RESOURCES.products, PERMISSION.edit);

  // const handleOpenCosts = () => {
  //   setModalCost(!modalCost);
  // };
  const handleOpenQuantity = () => {
    setModalQuantity(!modalQuantity);
  };

  const openProductModal = (product: ProductInterface = null) => {
    const data = product ? {
      product: {
        variant_id: product.variants[0].id,
        id: product.id,
        name: product.name,
        description: product.description,
        barcode: product.variants[0].barcode,
        brand_id: product.brand_id || undefined,
        category_id: product.category_id || undefined,
        unitofmeasure_id: product.variants[0].unitofmeasure_id,
        selectedCategory: product.category ? {
          value: product.category.id,
          label: product.category.name,
        } : undefined,
        selectedBrand: product.brand ? {
          value: product.brand.id,
          label: product.brand.name,
        } : undefined,
        selectedUnit: {
          value: product.variants[0].unitofmeasure.id,
          label: product.variants[0].unitofmeasure.code,
        },
        // online store
        published: product.published,
        // inventory
        available: product.available,
        inventoried: product.inventoried,
        adequate_stock: product.variants[0].adequate_stock?.toString(),
        stock_alert: product.variants[0].stock_alert?.toString(),
        low_stock: product.variants[0].low_stock?.toString(),
        selectedSuppliers: product.suppliers.length > 0
          ? product.suppliers.map(supplier => ({ value: supplier.id, label: supplier.name }))
          : [],
        // finance
        cost: product.variants[0].cost,
        price: product.variants[0].price,
        image_url: product.variants[0].image_url,
        assets: product.variants[0].assets.map(images => ({
          type: 'image',
          preview: images.url,
          url: images.url,
          key: images.key,
        })),
      },
      inventories: product.variants[0].inventories.map(({
        id, stock, location_id, variant_id, location,
      }) => ({
        id, stock, quantity: stock, location_id, variant_id, name: location.name,
      })),
      isOpen: true,
    } : {
      product: null,
      inventories: null,
      isOpen: true,
    };

    setProductModalState(data);
  };

  const closeProductModal = () => {
    setProductModalState({ product: null, inventories: [], isOpen: false });
  };

  const startProductTour = () => {
    tour.show('products-tour');
  };

  const onPressAddProduct = () => {
    openProductModal();
    const stepTour = tour.getCurrentStep();
    if (tour.isActive() && stepTour.id === 'products-tour-last-step') {
      const actualStepsFinished = JSON.parse(window.localStorage.getItem('finished-tour-steps'));

      window.localStorage.setItem(
        'finished-tour-steps',
        JSON.stringify({
          ...actualStepsFinished,
          productsDone: true,
        }),
      );
      tour.hide();
    }
  };

  return (
    <>
      {
        (canCreate || canEdit)
        && (
          <ProductForm
            onClose={closeProductModal}
            open={productModalState.isOpen}
            initialFormState={productModalState.product}
            initialInventories={productModalState.inventories}
            onDone={prodctsControllers.getProducts}
          />
        )
      }
      {(canCreate || canEdit) && <ProductsExcel />}
      <AdminCost open={modalCost} setOpen={setModalCost} />
      <QuantityForm handleClose={handleOpenQuantity} open={modalQuantity} />

      <Grid container>
        <PageContainer>
          { (!finishedSteps || !finishedSteps.productsDone) && (
            <Alert
              severity="info"
              action={(
                <Button onClick={startProductTour} color="inherit" size="small">
                  ¡Empezar!
                </Button>
              )}
            >
              Crea productos y administralos desde un solo lugar
            </Alert>
          )}
          <Grid container spacing={1}>
            <Grid item xs={10}>
              <Box
                mt={3}
                display="flex"
                flexDirection="row"
                width={1}
                flexWrap="wrap"
              >
                {canCreate && (
                  <Box className="create-first-product">
                    <AddButton onClick={onPressAddProduct}>
                      {t('general:create_product')}
                    </AddButton>
                  </Box>
                )}
                {(canCreate || canEdit) && (
                  <Box className="excel-table-upload">
                    <ExcelButton
                      text={t('general:products_excel:upload_excel')}
                      onClick={openProductsExcel}
                    />
                  </Box>
                )}
              </Box>
            </Grid>

            <Grid item xs={2}>
              <Box
                mt={3}
                display="flex"
                flexDirection="row"
                width={1}
                flexWrap="wrap"
                align="right"
                justifyContent="flex-end"
              >

                <Box>
                  <Button onClick={startProductTour} className={classes.buttonHelp}>
                    <HelpOutlineIcon />
                  </Button>
                </Box>
                <Box className="excel-table-download" padding={1} style={{ marginRight: '16px' }}>
                  <DownloadExcelButton
                    downloadData={{
                      url: prodctsControllers.productsPath,
                      params: prodctsControllers.buildDownloadParams(),
                      fileName: FILE_NAME,
                    }}
                    imageHeight={26}
                  />
                </Box>
                <Box>
                  <SwitchList
                    onChange={() => setList(!list)}
                    value={list}
                  />
                </Box>
              </Box>

            </Grid>
          </Grid>

          <SectionListContainer>
            {
              list
                ? <ListOfProducts onClickProduct={openProductModal} {...prodctsControllers} />
                : <ProductsList onClickProduct={openProductModal} />
            }
          </SectionListContainer>
        </PageContainer>
      </Grid>
    </>
  );
};
