import { Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: '.5rem 3rem',
    color: theme.colors.black,
  },
  value: {
    padding: '1.5rem',
    fontWeight: 500,
  },
}));

interface Props {
  title: string;
  value: string | number
}

export const WidgetStat = ({ title, value }: Props) => {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <Typography variant="subtitle2">
        {title}
      </Typography>
      <Typography className={classes.value} variant="h4">
        {value}
      </Typography>
    </Paper>
  );
};
