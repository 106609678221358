import React from 'react';
import { useDispatch } from 'react-redux';
import { editBranch } from '../../../../actions/branch';
import BranchesPageView from './BranchesPageView';

export default () => {
  const dispatch = useDispatch();
  const openNewBranch = () => {
    dispatch(editBranch());
  };

  return <BranchesPageView openNewBranch={openNewBranch} />;
};
