/* eslint-disable react/prop-types */
import React from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const styles = makeStyles(() => ({
  ItemDescription: {
    color: '#AAAAAA',
  },
}));

export default ({ text }) => {
  const classes = styles();
  return (
    <Typography
      align="center"
      variant="inherit"
      className={classes.ItemDescription}
    >
      {text}
    </Typography>
  );
};
