/* eslint-disable @typescript-eslint/no-shadow */
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Chip,
  FormControl, MenuItem, Select, SelectChangeEvent,
} from '@mui/material';
import { useSelector } from 'react-redux';
import Axios from 'axios';
import Box from '../../../../../../../components/ui/Layout/Box';
import SectionTitle from '../../SectionTitle';
import SectionText from '../../SectionText';
import { useCampaignWizardContext } from '../context/CampaignWizardContext';
import { CampaignWizardStep } from '../constants/campaign_wizard';
import { BASE_URL } from '../../../../../../../constants/api/ApiConstants';

const ZonesSection = () => {
  const { t } = useTranslation(['general']);

  const {
    state, setSelectedZone, setStepToSave, removeZone,
  } = useCampaignWizardContext();
  const { companyId } = useSelector((state: any) => state.session);
  const [deliveryInfo, setDeliveryInfo] = useState('' || []);

  const { stepToSave, campaign } = state;

  const handleDelete = (id: string) => {
    removeZone(id);
  };

  const handleChange = (event: SelectChangeEvent) => {
    const jsonData = JSON.parse(event.target.value as string);
    setSelectedZone(jsonData);
    // setRenderZone(event.target.value as string);
  };

  useEffect(() => {
    if (stepToSave === CampaignWizardStep.ZONES) {
      setStepToSave(null);
    }
  }, [stepToSave, setStepToSave]);

  const getDeliveryData = useCallback(
    async () => {
      Axios.get(`${BASE_URL}/api/v1/companies/${companyId}/deliveryzones`)
        .then((res) => {
          setDeliveryInfo(res.data.data.data.map(({ name, id }) => ({ name, id })));
        });
    },
    [companyId],
  );

  useEffect(() => {
    getDeliveryData();
  }, [getDeliveryData]);

  return (
    <Box mt={3} pb={5}>
      <SectionTitle title={t('general:shared_campaigns:zones_title')} />
      <Box width={1} mt={2}>
        <FormControl variant="standard" fullWidth>
          <Select
            variant="standard"
            labelId="simple-select-label"
            id="simple-select"
            onChange={handleChange}
            value={null}
          >
            {
            deliveryInfo
              .filter(zone => !campaign.zones.some(z => z.id === zone.id))
              .map(({ name, id }) => (
                <MenuItem key={id} value={JSON.stringify({ name, id })}>
                  {name}
                </MenuItem>
              ))
            }
          </Select>
        </FormControl>
      </Box>

      <Box marginTop="15" marginRight="15" flexDirection="row" flexWrap="wrap" display="flex">
        {
          campaign.zones.map(zone => (
            <div style={{ margin: 10 }} key={zone.id}>
              <Chip style={{ display: 'flex' }} onDelete={() => handleDelete(zone.id)} label={zone.name} color="primary" />
            </div>
          ))
        }
      </Box>
      <Box mt={1}>
        <SectionText text={t('general:shared_campaigns:zones_msg')} />
      </Box>
    </Box>
  );
};

export default ZonesSection;
