/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/prop-types */
import React from 'react';
import { CircularProgress } from '@mui/material';
import { FailedPayment } from './FailedPayment';
import { SuccessfulPayment } from './SuccessfulPayment';

export const PaymentInfo = ({ err, processing }) => (
  <>
    { processing
      ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
          <div>
            Esta operación puede tardar unos segundos...
            {' '}
            <CircularProgress />
          </div>
        </div>
      )
      : (
        <>
          {err !== null
            ? <FailedPayment msg={err} />
            : <SuccessfulPayment />}
        </>
      )}
  </>
);
