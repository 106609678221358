/* eslint-disable react/prop-types */
import { Grid } from '@mui/material';
import React from 'react';
import { makeStyles } from '@mui/styles';

const styles = makeStyles(theme => ({
  container: {
    minWidth: 30,
    height: 20,
    borderRadius: 5,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '2px 6px',
  },
  alertStock: {
    border: `solid 1px ${theme.colors.trafficLight.alert.main}`,
    backgroundColor: theme.colors.trafficLight.alert.light,
  },
  lowStock: {
    border: `solid 1px ${theme.colors.trafficLight.low.main}`,
    backgroundColor: theme.colors.trafficLight.low.light,
  },
  goodStock: {
    border: `solid 1px ${theme.colors.trafficLight.adequate.main}`,
    backgroundColor: theme.colors.trafficLight.adequate.light,
  },
}));

export default ({ variants }) => {
  const classes = styles();
  const alert = (stock: any) => {
    if (stock.stock <= stock.low_stock) {
      return classes.lowStock;
    } if (stock.stock <= stock.stock_alert) {
      return classes.alertStock;
    }
    return classes.goodStock;
  };
  return (
    <Grid className={`${classes.container} ${alert(variants)}`}>
      <span style={{ fontSize: 15 }}>{variants.stock}</span>
    </Grid>
  );
};
