import { types } from '../../actions/supplier/types';

export const initialState = {
  supplier: {
    id: null, name: '', business_name: '', image: '',
  },
  error: null,
  showModal: false,
  processing: false,
  deletingSupplier: false,
  refresh: false,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case types.EDIT_SUPPLIER:
      return {
        ...state,
        showModal: true,
        supplier:
          action.payload !== null ? action.payload : initialState.supplier,
      };
    case types.CLOSE_SUPPLIER_MODAL:
      return initialState;
    case types.SAVE_SUPPLIER_SUCCESS:
      return {
        ...state,
        response: action.response,
        error: null,
        processing: false,
        refresh: true,
      };
    case types.SAVE_SUPPLIER_FAILURE:
      return {
        ...state,
        error: action.error,
        processing: false,
      };
    case types.SAVING_SUPPLIER:
      return {
        ...state,
        error: null,
        processing: true,
      };

    case types.DELETING_SUPPLIER:
      return {
        ...state,
        error: null,
        deletingSupplier: true,
      };

    case types.DELETE_SUPPLIER_SUCCESS:
      return {
        ...state,
        error: null,
        deletingSupplier: false,
        refresh: true,
      };
    case types.DELETE_SUPPLIER_FAILURE:
      return {
        ...state,
        error: action.error,
        deletingSupplier: false,
      };
    case types.CLEAR_REFRESH:
      return {
        ...state,
        refresh: false,
      };
    default:
      return state;
  }
};
