/* eslint-disable no-restricted-syntax */
import FormData from 'form-data';
import AuthorizedApiClient from '../../core/common/api/AuthorizedApiClient';
import { types } from './types';

export const setWizardStep = step => ({
  type: types.SET_WIZARD_STEP,
  step,
});

export const setFood = food => ({
  type: types.SET_FOOD,
  food,
});

export const setBusinessType = business_type => ({
  type: types.SET_BUSINESS_TYPE,
  business_type,
});

export const setCategories = categories => ({
  type: types.SET_CATEGORIES,
  categories,
});

export const setProductionVolume = production_volume => ({
  type: types.SET_PRODUCTION_VOLUME,
  production_volume,
});

export const setCostumerTypes = customer_types => ({
  type: types.SET_CUSTOMER_TYPES,
  customer_types,
});

export const setDeliveriesRanges = deliveries_ranges => ({
  type: types.SET_DELIVERIES_RANGES,
  deliveries_ranges,
});

export const setInvoice = invoice => ({
  type: types.SET_INVOICE,
  invoice,
});

export const setKeywords = keywords => ({
  type: types.SET_KEYWORDS,
  keywords,
});

export const setWizardBranch = branch => ({
  type: types.SET_WIZARD_BRANCH,
  branch,
});

export const setFinish = finish => ({
  type: types.SET_FINISH,
  finish,
});

export const clearWizard = () => ({
  type: types.CLEAR_WIZARD,
});

export const savingWizard = () => ({
  type: types.SAVING_WIZARD,
});

export const saveCompanySucceed = response => ({
  type: types.SAVE_STORE_PROFILE_SUCCESS,
  response,
});

export const saveWizard = (companyID, data, branch) => async (dispatch: any) => {
  dispatch(savingWizard());
  const sg = new AuthorizedApiClient();
  const path = `companies/${companyID}`;
  const branchPatch = `${path}/branches/${branch.id}`;
  let response;
  let branchFormData;

  try {
    if (branch.file !== null) {
      branchFormData = new FormData();
      for (const key in branch) {
        if (key !== 'id' && key !== 'file') {
          branchFormData.append(key, branch[key]);
        }
      }
      branchFormData.append('image', branch.file);
    } else {
      delete branch.file;
      delete branch.id;
      branchFormData = branch;
    }

    response = await sg.put(branchFormData, branchPatch);

    response = await sg.put(data, path);

    if (response != null) {
      dispatch({ type: types.SAVE_WIZARD_SUCCESS });
      dispatch(saveCompanySucceed(response));
    }
  } catch (error) {
    dispatch({ type: types.SAVE_WIZARD_FAILURE, error: error.response.data });
  }
};
