import { Box, TextField } from '@mui/material';
import React from 'react';
import PersonIcon from '@mui/icons-material/Person';

const CardHolderInput = ({
  label,
  error,
  defaultValue = '',
  register,
}: any) => (
  <Box width="100%">
    <TextField
      variant="standard"
      {...register}
      label={label}
      InputLabelProps={{
        shrink: true,
      }}
      defaultValue={defaultValue}
      error={!!error}
      helperText={error !== undefined ? error.message : null}
      fullWidth
      InputProps={{

        startAdornment: (
          <PersonIcon />
        ),
      }}
    />
  </Box>
);

export default CardHolderInput;
