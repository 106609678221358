/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyle = makeStyles(theme => ({
  container: {
    display: 'flex',
    textAlign: 'center',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    boxSizing: 'border-box',

  },
  bgData: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors.cardPurple.light,
    borderRadius: 15,
    padding: '30px 0px',
    [theme.breakpoints.down('md')]: {
      padding: '15px 0px',
    },
  },
  tittle: {
    color: theme.colors.cardPurple.main,
    fontSize: 18,
    fontWeight: 'bold',
    margin: 0,
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      fontSize: 13,
    },
  },
  data: {
    color: theme.colors.cardPurple.main,
    fontSize: 46,
    fontWeight: 'bold',
    [theme.breakpoints.down('md')]: {
      fontSize: 30,
    },
  },
  money: {
    '&:before': {
      content: "'$'",
    },
  },
  warning: {
    display: 'block',
    position: 'absolute',
    top: -20,
    right: -15,
    width: 45,
    height: 45,
    backgroundImage: `url(${theme.icons.warning})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      width: 30,
      height: 30,
    },
  },
}));

export default (props) => {
  const classes = useStyle();
  return (
    <div className={classes.container}>
      <p className={classes.tittle}>
        {props.tittle}
      </p>
      <div className={classes.bgData}>
        { props.alert && <span className={classes.warning} onClick={props.warningClick} /> }

        {props.load
          ? <CircularProgress />
          : (
            <p className={`${classes.data} ${props.type === 'money' ? classes.money : null}`}>
              {props.data}
            </p>
          )}
      </div>
    </div>
  );
};
