import React, { useState } from 'react';
import {
  Box, Button, Chip, Popover, Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface Option {
  identifier: string | number;
  label: string;
  color?: string;
}

interface Props {
  value: Option['identifier'];
  options: Option[];
  onChange?(selected: Option): void;
  fullWidth?: boolean;
  label?: string;
}

export const Dropdown = ({
  value, options, onChange, fullWidth, label,
}: Props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const isOpen = Boolean(anchorEl);
  const selectedOption = options.find(o => o.identifier === value);

  const handleClick = (e) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const onClickOption = (selecting: Option) => {
    onChange?.(selecting);
    setAnchorEl(null);
  };

  return (
    <div>
      {label && <Typography color="gray" ml={1.5} variant="caption">{label}</Typography>}
      <Chip
        clickable
        icon={<ExpandMoreIcon htmlColor="black" />}
        label={selectedOption?.label || 'Seleccionar'}
        onClick={handleClick}
        style={{
          backgroundColor: selectedOption?.color || 'whitesmoke',
          width: fullWidth ? '100%' : 'auto',
          display: 'inline-flex',
        }}
      />
      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        style={{ marginTop: 4 }}
      >
        <Box display="flex" flexDirection="column">
          {
            options.map(o => (
              <Button onClick={(e) => {
                e.stopPropagation();
                onClickOption(o);
              }}
              >
                <Typography color="ButtonText">
                  {o.label}
                </Typography>
              </Button>
            ))
          }
        </Box>
      </Popover>
    </div>
  );
};
