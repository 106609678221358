/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '../../../../../../../components/ui/Layout/Grid';
import { useCampaignWizardContext } from '../context/CampaignWizardContext';
import useDatesForm from './form_definition';
import { CampaignWizardStep } from '../constants/campaign_wizard';
import Box from '../../../../../../../components/ui/Layout/Box';
import SectionTitle from '../../SectionTitle';
import DateInput from './DateInput';
import HourInput from './HourInput';

const DatesSection = () => {
  const { t } = useTranslation(['general']);
  const { state, setDates, setStepToSave } = useCampaignWizardContext();

  const { stepToSave } = state;
  const {
    handleSubmit,
    errors,
    getValues,
    setValue,
    clearError,
    register,
  } = useDatesForm(state.campaign);

  useEffect(() => {
    register('start_date');
    register('end_date');
    register('delivery_date');
  }, [register]);

  const _saveData = useCallback(
    (data) => {
      setDates(data);
    },
    [setDates],
  );

  useEffect(() => {
    if (stepToSave === CampaignWizardStep.DATES) {
      setStepToSave(null);
      handleSubmit(_saveData)();
    }
  }, [stepToSave, setStepToSave, _saveData, handleSubmit]);

  const handleDateChange = useCallback(
    (name, date) => {
      setValue(name, date);
      clearError(name);
    },
    [setValue, clearError],
  );

  const handleStartDateChange = useCallback(
    (date) => {
      handleDateChange('start_date', date);
    },
    [handleDateChange],
  );

  const handleEndDateChange = useCallback(
    (date) => {
      handleDateChange('end_date', date);
    },
    [handleDateChange],
  );

  const handleDeliveryDateChange = useCallback(
    (date:Date | null) => {
      handleDateChange('delivery_date', date ? new Date(date.setHours(23, 59, 59)) : null);
    },
    [handleDateChange],
  );

  const handleStartTimeChange = useCallback(
    (date: Date) => {
      // handleDateChange("start_time", date);
      const currentStartDate = getValues('start_date');
      if (currentStartDate) {
        handleDateChange(
          'start_date',
          new Date(
            currentStartDate.setHours(date.getHours(), date.getMinutes()),
          ),
        );
      }
    },
    [handleDateChange, getValues],
  );

  const handleEndTimeChange = useCallback(
    (date: Date) => {
      // handleDateChange("end_time", date);
      const currentEndDate = getValues('end_date');
      if (currentEndDate) {
        handleDateChange(
          'end_date',
          new Date(currentEndDate.setHours(date.getHours(), date.getMinutes())),
        );
      }
    },
    [handleDateChange, getValues],
  );

  // console.log("errors", errors);

  return (
    <Box mt={5} pb={5}>
      <form autoComplete="off">
        <SectionTitle title={t('general:shared_campaigns:date_title')} />
        <Box mt={2}>
          <SectionTitle
            title={t('general:shared_campaigns:campaign_duration')}
          />
        </Box>

        <Box mt={3}>
          <Grid container spacing={2}>
            <Grid container item xs={12} spacing={1}>
              <Grid item xs={7}>
                <DateInput
                  label={t('general:shared_campaigns:start_date')}
                  onChange={handleStartDateChange}
                  defaultValue={state.campaign.start_date}
                  error={errors.start_date}
                />
              </Grid>
              <Grid item xs={5}>
                <HourInput
                  onChange={handleStartTimeChange}
                  defaultValue={state.campaign.start_date}
                  error={null}
                />
              </Grid>
            </Grid>

            <Grid container item xs={12} spacing={1}>
              <Grid item xs={7}>
                <DateInput
                  label={t('general:shared_campaigns:end_date')}
                  onChange={handleEndDateChange}
                  defaultValue={state.campaign.end_date}
                  error={errors.end_date}
                />
              </Grid>
              <Grid item xs={5}>
                <HourInput
                  onChange={handleEndTimeChange}
                  defaultValue={state.campaign.end_date}
                  error={null}
                />
              </Grid>
            </Grid>

            <Grid container item xs={12} spacing={1}>
              <Grid item xs={12}>
                <DateInput
                  label={t('general:shared_campaigns:delivery_date')}
                  onChange={handleDeliveryDateChange}
                  defaultValue={state.campaign.delivery_date}
                  error={errors.delivery_date}
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </form>
    </Box>
  );
};

export default DatesSection;
