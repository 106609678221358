/* eslint-disable react/prop-types */
import React from 'react';
import { useDispatch } from 'react-redux';
import { editModifierGroup } from '../../../../../actions/modifier_group';
import Box from '../../../../../components/ui/Layout/Box';
import PaginatedScroll from '../../../../../hoc/PaginatedScroll';
import { generateKey } from '../../../../../util/keys';
import ModifierGroupRow from '../ModifierGroupRow';

export default ({ state, getModifierGroups, resetModifierGroups }) => {
  const dispatch = useDispatch();
  const showDetails = (modifierGroup) => {
    dispatch(editModifierGroup(modifierGroup));
  };

  const list = state.data.map(modifierGroup => (
    <Box
      key={generateKey(modifierGroup.id)}
      width={1}
      onClick={() => showDetails(modifierGroup)}
    >
      <ModifierGroupRow modifierGroup={modifierGroup} />
    </Box>
  ));

  return (
    <PaginatedScroll
      hasNextPage={state.response.page < state.response.last_page}
      loadData={params => getModifierGroups(params)}
      currentPage={state.response.page}
      resetData={resetModifierGroups}
      processing={state.processing}
    >
      <Box>{list}</Box>
    </PaginatedScroll>
  );
};
