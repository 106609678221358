/* eslint-disable react/prop-types */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import { makeStyles } from '@mui/styles';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '../../../../../components/ui/Layout/Grid';
import PaginatedScroll from '../../../../../hoc/PaginatedScroll';
import ProductItemInList from '../../AllProductsPage/ProductItemInList';
import ProductItemInMo from '../../AllProductsPage/ProductItemInMo';
import { getSearchProducts, resetSearchProducts } from '../../../../../actions/search';
import { SCROLL_PER_PAGE } from '../../../../../constants/common';
import ProductModal from '../../../../../components/Forms/ProductModal';
import { ProductModalInterface } from '../../../../../helpers/interfaces/handlers';

const styles = makeStyles(theme => ({
  container: {
    display: 'grid',
    gap: '22px',
    justifyItems: 'center',
    alignItems: 'center',
  },
  inMosaic: {
    gridTemplateColumns: 'repeat(4, 1fr)',
    [theme.breakpoints.down('lg')]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
  },
  inList: {
    gridTemplateColumns: 'repeat(1, 1fr)',
    gap: 0,
  },
}));

export default ({ listView, switchView, setPriceAlert }) => {
  const classes = styles();
  const dispatch = useDispatch();
  const search = useSelector((state: any) => state.search);
  const { company } = useSelector((state: any) => state.session);
  const cart = useSelector((state: any) => state.cart);
  const [productModal, setProductModal] = useState<ProductModalInterface>({
    isOpen: false,
  });

  const _getProducts = useCallback(async (params: any = {}) => {
    params.per_page = SCROLL_PER_PAGE;
    params.embed = 'category,brand,suppliers,modifiers_groups';
    await dispatch(getSearchProducts(company.id, params));
  }, [company.id, dispatch]);

  useEffect(() => {
    if (cart.completed === true) {
      dispatch(resetSearchProducts());
      _getProducts();
    }
  }, [dispatch, cart, _getProducts]);

  const _resetProducts = useCallback(() => {
    dispatch(resetSearchProducts());
  }, [dispatch]);

  const addItem = (product: any) => {
    setProductModal({ isOpen: true, product });
  };

  const searchList = search.data.map((product: any) => {
    if (listView === true) {
      return <ProductItemInList key={product.id} item={product} />;
    }
    return (
      <ProductItemInMo
        key={product.id}
        product={product}
        setPriceAlert={setPriceAlert}
        handleAddItem={addItem}
      />
    );
  });

  return (
    <>
      {
      useMemo(() => (
        <ProductModal
          handlerOpen={setProductModal}
          isOpen={productModal.isOpen}
          productData={productModal.product}
        />
      ), [productModal])
    }
      <PaginatedScroll
        hasNextPage={
        search.response.page < search.response.last_page
      }
        currentPage={search.response.page}
        processing={search.loading}
        resetData={_resetProducts}
        loadData={_getProducts}
        filters={search.filters}
      >
        <Grid className={`${classes.container} ${switchView ? classes.inList : classes.inMosaic}`}>
          {searchList}
        </Grid>
      </PaginatedScroll>
    </>
  );
};
