/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '../../../../components/ui/Layout/Grid';
import PaginatedScroll from '../../../../hoc/PaginatedScroll';
import { getSearchProducts, resetSearchProducts } from '../../../../actions/search';
import { SCROLL_PER_PAGE } from '../../../../constants/common';
import AdminCostItem from '../AllProductsPage/ProductForm/AdminCostItem';
import ActivityIndicator from '../../../../components/ui/ActivityIndicator';

export default () => {
  const search = useSelector((state: any) => state.search);
  const { company } = useSelector((state: any) => state.session);
  const productsCost = useSelector((state: any) => state.productsCost);

  const dispatch = useDispatch();

  const _getProducts = useCallback(async (params: any = {}) => {
    if (company) {
      params.per_page = SCROLL_PER_PAGE;
      params.embed = 'category,brand,suppliers';
      await dispatch(getSearchProducts(company.id, params));
    }
  }, [company, dispatch]);

  const _resetProducts = useCallback(() => {
    dispatch(resetSearchProducts());
  }, [dispatch]);

  const searchList = search.data.map((product: any) => (
    <AdminCostItem data={product} key={product.id} />
  ));

  return (
    <Grid style={{
      height: '58%', width: '100%', maxHeight: 500, padding: '5px 0px 5px 0px',
    }}
    >
      <PaginatedScroll
        hasNextPage={
            search.response.page < search.response.last_page
        }
        currentPage={search.response.page}
        processing={search.loading}
        resetData={_resetProducts}
        loadData={_getProducts}
        filters={search.filters}
      >
        { productsCost.load ? <ActivityIndicator /> : searchList}
      </PaginatedScroll>
    </Grid>
  );
};
