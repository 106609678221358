import React from 'react';
import {
  ButtonProps as MuiButtonProps, Button,
} from '@mui/material';
import { withStyles } from '@mui/styles';

const ECButton = withStyles(theme => ({
  root: {
    // marginTop: "8px",
    // marginBottom: "8px",
    margin: '5px',
    [theme.breakpoints.down('md')]: {
      padding: '8px 18px',
    },
  },

}))(Button);

export default (props:MuiButtonProps) => <ECButton {...props} color="primary" variant="contained" />;
