import React from 'react';

import Grid from '../../components/ui/Layout/Grid';
import Box from '../../components/ui/Layout/Box';

interface Props {
  children: React.ReactNode
}

const SectionListContainer = ({ children }: Props) => (
  <Box mt={3} width={1} pb={12}>
    <Grid container spacing={1}>
      <Grid item xs={12}>
        {children}
      </Grid>
    </Grid>
  </Box>
);

export default SectionListContainer;
