/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useState } from 'react';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import {
  Button, Link, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import LinkButton from '../../ui/Buttons/LinkButton';
import LinkDangerButton from '../../ui/Buttons/LinkDangerButton';
import Box from '../../ui/Layout/Box';

interface Props {
  zone: any
  handleEdit: (id: string) => void;
  handleDelete: (id: string) => void;
}

const DeliveryZoneCard = ({ zone, handleEdit, handleDelete }: Props) => {
  const classes = useStyles();
  const [toggle, setToggle] = useState(false);
  const { t } = useTranslation([]);

  const onDelete = () => {
    handleDelete(zone.id);
  };

  const onEdit = () => {
    handleEdit(zone.id);
  };

  return (
    <Box className={classes.zonesInfo}>

      <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h6" style={{ color: 'black', marginBlock: 10 }}>
          {' '}
          {zone.name}
        </Typography>
        <Button
          style={{ justifySelf: 'flex-end', backgroundColor: zone.color || '#fff' }}
          className={classes.squareButton}
        />
      </Box>

      <Typography variant="body2">
        Tiempo de envío:
        {' '}
        {(zone.courier_providers[0].delivery_time_settings.max_time)}
        {' '}
        min
      </Typography>
      <Typography variant="body2">
        {' '}
        Geolocalización:
        {' '}
        <Link
          style={{ cursor: 'pointer' }}
          onClick={() => setToggle(!toggle)}
          underline="hover"
        >
          Ver detalle
        </Link>
      </Typography>

      {toggle
              && (
              <>
                <p style={{ fontSize: '0.9em' }}> Infomación de coordenadas</p>
                {
                      zone.geo_information.type === 'sphere' ? (
                        <span>
                          {(zone.geo_information.sphere.location.coordinates).map(info => (
                            <li>
                              [
                              {info}
                              ],
                            </li>
                          ))}
                        </span>
                      )

                        : (
                          <span>
                            {(zone.geo_information.polygon.coordinates).map(info => (
                              <li>
                                {info}
                                ,
                              </li>
                            ))}
                          </span>
                        )

                  }
              </>
              )}

      <Typography variant="body2">
        Costo: $
        {zone.courier_providers[0].delivery_cost_settings.regular_cost}
      </Typography>
      <Typography variant="body2">
        Conectado a:
        {' '}
        {(zone.courier_providers[0].type) && (t('general:orders:self_managed'))}
      </Typography>

      {
            zone.discount_settings?.free_deliveries_from && zone.discount_settings?.free_deliveries_from !== 0
              ? (
                <Box style={{ display: 'flex', alignContent: 'center', marginTop: 5 }}>
                  <CheckBoxOutlinedIcon />
                  <Typography variant="body2" style={{ marginTop: 2 }}>
                    En la compra de $
                    {zone.discount_settings.free_deliveries_from}
                    , o más el envío es gratis
                  </Typography>

                </Box>
              )
              : null
          }

      <Box className={classes.finalButtons}>

        <LinkDangerButton onClick={onDelete}>
          Eliminar
        </LinkDangerButton>
        <LinkButton onClick={onEdit}>
          Editar
        </LinkButton>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  zonesInfo: {
    width: '100%',
    padding: 5,
    boxSizing: 'border-box',
  },
  squareButton: {
    margin: 10,
    backgroundColor: '#F8ACAA',
  },
  infoDelivery: {
    margin: 2,
  },
  finalButtons: {
    marginRight: 15,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
}));

export default DeliveryZoneCard;
