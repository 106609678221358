/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { makeStyles } from '@mui/styles';
import React from 'react';

const styles = makeStyles(theme => ({
  arrow: {
    display: 'block',
    position: 'relative',
    height: 26,
    cursor: 'pointer',
    transition: 'width 0.3s',
    '&::after': {
      content: "''",
      display: 'block',
      backgroundColor: theme.colors.black,
      width: '85%',
      height: 5,
      position: 'absolute',
      top: 'calc(25% - 2px)',
      transform: 'rotate(-45deg)',
    },
    '&::before': {
      content: "''",
      display: 'block',
      backgroundColor: theme.colors.black,
      width: '85%',
      height: 5,
      position: 'absolute',
      top: 'calc(75% - 3px)',
      transform: 'rotate(45deg)',
    },
  },
  containerHidden: {
    visibility: 'hidden',
    display: 'none',
    width: 0,
  },
  containerVisible: {
    visibility: 'visible',
    width: 26,
  },
}));

export default (props) => {
  const classes = styles();

  return (
    <span
      className={`${classes.arrow} ${
        props.displayed && props.step === 2
          ? classes.containerVisible
          : classes.containerHidden
      }`}
      onClick={() => props.click()}
    />
  );
};
