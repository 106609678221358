import { Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import Box from '../ui/Layout/Box';
import { BusinessType } from '../../constants/common';
import CheckOption from './CheckOption';
import { setBusinessType, setWizardStep } from '../../actions/wizard';
import { WizardSteps } from './steps';
import SuccessButton from '../ui/Buttons/SuccessButton';

const useStyle = makeStyles(() => ({
  questionsContainer: {
    width: '70%',
    marginLeft: 'auto',
    marginRight: 'auto',
    minWidth: '220px',
  },
}));

const BusinessTypeSection = () => {
  const classes = useStyle();
  const { t } = useTranslation(['general']);
  const dispatch = useDispatch();
  const [selectedBT, setSelectedBT] = useState(null);
  const handleBusinessTypeSelected = (value) => {
    setSelectedBT(value);
  };

  const handleSubmitBT = () => {
    dispatch(setBusinessType(selectedBT));
    dispatch(setWizardStep(WizardSteps.CATEGORIES));
  };

  const options = Object.values(BusinessType).map(key => (
    <Box key={key} mb={1} p={0}>
      <CheckOption
        label={t(`general:business_type:${key}`)}
        handleOnCLick={() => handleBusinessTypeSelected(key)}
        selected={selectedBT === key}
      />
    </Box>
  ));

  return (
    <>
      <Box>
        <Typography variant="h6" align="center" color="textSecondary">
          {t('general:wizard_setup:business_type_question')}
        </Typography>
      </Box>

      <Box mt={2} className={classes.questionsContainer} p={0} pt={5}>
        {options}
      </Box>

      <Box mt={2} display="flex" justifyContent="center">
        <SuccessButton
          text={t('general:next')}
          onClick={handleSubmitBT}
          disabled={selectedBT === null}
        />
      </Box>
    </>
  );
};

export default BusinessTypeSection;
