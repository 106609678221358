/* eslint-disable consistent-return */
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import AuthorizedApiClient from '../../../../../core/common/api/AuthorizedApiClient';
import { rules as validationRules } from '../../../../../util/Validations';

export default function useModifierForm() {
  const { t } = useTranslation(['general']);
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState(null);

  const schema = Yup.object({
    name: Yup.string()
      .max(validationRules.maxLength)
      .required(
        t('translation:required', {
          input: t('general:name'),
        }),
      ),
    price: Yup.number()
      .required(
        t('translation:required', {
          input: t('general:price'),
        }),
      )
      .typeError(
        t('translation:required', {
          input: t('general:price'),
        }),
      ),
    unitofmeasure_id: Yup.string()
      .required()

      .required(t('general:unit_no_set')),
    inventoried: Yup.boolean().default(false),
    available: Yup.boolean().default(true),
    published: Yup.boolean().default(false),
  });

  const { register, handleSubmit, formState: { errors } /* , control */ } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const saveModifier = async (companyId, data) => {
    setProcessing(true);
    const sg = new AuthorizedApiClient();
    const path = `companies/${companyId}/products`;

    try {
      const response = await sg.post(data, path);

      if (response != null) {
        // console.log("saved product", response);
        return response.data.product;
      }
    } catch (err) {
      console.log(err.message);

      setError(err.response.data);
    } finally {
      setProcessing(false);
    }
  };

  return {
    register,
    handleSubmit,
    saveModifier,
    errors,
    processing,
    error,
  };
}
