/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/prop-types */
import { Hidden } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Box from '../../../../../../../components/ui/Layout/Box';
import Grid from '../../../../../../../components/ui/Layout/Grid';
import Cell from './Cell';
import ProductCell from './ProductCell';
import Title from './Title';
import InputQty from './InputQty';
import DisabledQuantity from './DisabledQuantity';

const styles = makeStyles(() => ({
  image: {
    width: '50px',
    height: '50px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  editBox: {
    width: '30px',
    position: 'absolute',
    right: '-5px',
    top: '0',
  },
  root: {
    position: 'relative',
  },
}));

export default ({
  product,
  showQuantities,
  view,
  imageUrl,
  unit,
  location,
  stock,
  defaultVariant,
  showLocations,
  handleEditClick,
  handleCounted,
}) => {
  const { counted } = useSelector((state: any) => state.count_movements);
  const classes = styles();
  const { t } = useTranslation(['recounts', 'general']);

  const countedItem = counted
    .find(c => c.variant_id === defaultVariant.id && c.location_id === location.id);

  const handleCountedChange = (value) => {
    // setCountedQuantity(value);

    let _dis;
    if (!value && value !== 0) {
      _dis = null;
    } else {
      _dis = value - stock;
    }
    handleCounted(defaultVariant.id, location, stock, value, _dis);
  };

  const locationContent = view === 2 ? (
    <Title title={t('recounts:location')} />
  ) : (
    <Cell
      title={t('recounts:location')}
      content={location ? location.name : ''}
    />
  );

  const stockContent = (
    <>
      <Box width={1} display="flex" justifyContent="center">
        <Title title={t('recounts:available_quantity')} />
      </Box>
      {view === 1 && (
        <Box width={1} display="flex" justifyContent="center">
          <DisabledQuantity quantity={stock} />
        </Box>
      )}
    </>
  );

  const countedContent = (
    <>
      <Box width={1} display="flex" justifyContent="center">
        <Title title={t('recounts:counted_quantity')} />
      </Box>
      {view === 1 && (
        <Box width={1} display="flex" justifyContent="center">
          <InputQty
            value={countedItem ? countedItem.counted_quantity : null}
            handleOnChange={handleCountedChange}
          />
        </Box>
      )}
    </>
  );

  const discrepancyContent = (
    <>
      <Box width={1} display="flex" justifyContent="center">
        <Title title={t('recounts:discrepancy')} />
      </Box>
      {view === 1 && (
        <Box width={1} display="flex" justifyContent="center">
          <DisabledQuantity
            quantity={countedItem ? countedItem.discrepancy : null}
          />
        </Box>
      )}
    </>
  );

  return (
    <Grid container className={classes.root}>
      <Grid item xs={2} md={1}>
        <Box
          className={classes.image}
          style={{ backgroundImage: `url(${imageUrl})` }}
        />
      </Grid>
      <Grid item xs={5} md={showQuantities ? 2 : 6}>
        <ProductCell name={product.name} description={product.description} />
      </Grid>

      <Grid item xs={5} md={1}>
        <Cell title={t('recounts:unit')} content={unit.code} />
      </Grid>

      <Grid
        container
        item
        xs={showQuantities ? 3 : 7}
        md={2}
        alignContent={view === 1 ? 'flex-start' : 'center'}
        justifyContent="center"
      >
        {view === 2 ? (
          <Hidden lgDown>{locationContent}</Hidden>
        ) : (
          locationContent
        )}
      </Grid>

      {showQuantities && (
        <Grid
          container
          item
          xs={3}
          md={2}
          alignContent={view === 1 ? 'flex-start' : 'center'}
        >
          {view === 2 ? <Hidden lgDown>{stockContent}</Hidden> : stockContent}
        </Grid>
      )}

      <Grid
        container
        item
        xs={showQuantities ? 3 : 5}
        md={2}
        alignContent={view === 1 ? 'flex-start' : 'center'}
      >
        {view === 2 ? <Hidden lgDown>{countedContent}</Hidden> : countedContent}
      </Grid>

      {showQuantities && (
        <Grid
          container
          item
          xs={2}
          md={1}
          alignContent={view === 1 ? 'flex-start' : 'center'}
        >
          {view === 2 ? (
            <Hidden lgDown>{discrepancyContent}</Hidden>
          ) : (
            discrepancyContent
          )}
        </Grid>
      )}
      {view === 2 && (
        <Box className={classes.editBox} justify="center" alignContent="center">
          {showLocations ? (
            <ArrowUpwardIcon
              style={{ cursor: 'pointer' }}
              onClick={handleEditClick}
            />
          ) : (
            <EditIcon style={{ cursor: 'pointer' }} onClick={handleEditClick} />
          )}
        </Box>
      )}
    </Grid>
  );
};
