/* eslint-disable react/prop-types */
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../../components/ui/Modals/Modal';
import RawModifierForm from '../RawModifierForm';
import useModifierForm from '../RawModifierForm/fields_definition';
import Actions from './Actions';
import { useSession } from '../../../../../util/session';

const ModalModifier = ({ show = true, onSavedModifier, onClose }) => {
  const [showModal, setShowModal] = useState(show);
  const { t } = useTranslation(['general']);
  const title = t('general:modifiers_groups:new_modifier');
  const { company } = useSession();

  const {
    register, handleSubmit, errors, saveModifier, processing,
  } = useModifierForm();

  const onSubmit = useCallback(async (data) => {
    // console.log("data to save", data);
    if (company) {
      const product = await saveModifier(company.id, data);
      onSavedModifier(product);
    }
  }, [company, saveModifier, onSavedModifier]);

  const handleClose = () => {
    setShowModal(false);
    onClose();
  };

  const actions = (
    <Actions
      handleSubmit={handleSubmit(onSubmit)}
      processing={processing}
    />
  );

  return (
    <Modal
      open={showModal}
      title={title}
      onClose={handleClose}
      actions={actions}
      maxWidth="xs"
    >
      <RawModifierForm register={register} errors={errors} />
    </Modal>
  );
};

export default ModalModifier;
