import AuthorizedApiClient from '../../core/common/api/AuthorizedApiClient';
import { types } from './types';

// from market place

export const addItemToCart = (product: object) => ({
  type: types.add_item_to_cart,
  product,
});

export const removeProduct = (product: any) => ({
  type: types.remove_one_in_product,
  product,
});

//--------------------------
export const setTemporalPrice = (item_id, newPrice) => ({
  type: types.EDIT_PRICE_ITEM,
  item_id,
  newPrice,
});

export const clearCartError = () => ({
  type: types.CLEAR_CART_ERROR,
});

export const requestingTicket = () => ({
  type: types.GET_TICKET,
  payload: true,
});

export const clearAddedCustomer = () => ({
  type: types.CLEAR_ADDED_CUSTOMER,
});

export const requestingCustomers = () => ({
  type: types.REQUESTING_CUSTOMERS,
});

export const setCustomer = customer => ({
  type: types.SET_CUSTOMER,
  customer,
});

const stockWarningTrue = item => ({
  type: types.OVER_STOCK_TRUE,
  item,
});
const stockWarningFalse = item => ({
  type: types.OVER_STOCK_FALSE,
  item,
});
export const stockWarning = item => async (dispatch) => {
  dispatch(stockWarningTrue(item));
  const finishAlert = () => setTimeout(() => {
    dispatch(stockWarningFalse(item));
  }, 3000);
  await finishAlert();
};

export const setSellDrawer = () => ({
  type: types.SET_SELL_DRAWER,
});

export const savingCart = () => ({
  type: types.SAVING_CART,
});

export const clearCart = () => ({
  type: types.CLEAR_CART,
});

export const setStep = step => ({
  type: types.SET_STEP,
  step,
});

export const addItem = item => ({
  type: types.ADD_CART_ITEM,
  item,
});

export const removeOnlyOne = item => ({
  type: types.REMOVE_ONLY_ONE,
  item,
});

export const removeProductFromCart = item => ({
  type: types.DELETE_PRODUCT_FROM_CART,
  item,
});
export const removeCart = () => ({
  type: types.REMOVE_CART,
});

export const addPaymentType = paymentType => ({
  type: types.ADD_PAYMENT_TYPE,
  paymentType,
});

export const getTicket = (companyID, params = {}) => async (dispatch: any) => {
  dispatch(requestingTicket());

  const sg = new AuthorizedApiClient();
  const path = `companies/${companyID}/tickets`;
  // dispatch({ type: types.GET_TICKET, payload: true });
  try {
    const response = await sg.post(params, path);
    if (response != null) {
      dispatch({ type: types.GET_TICKET_SUCCESS, response: response.data });
    }
  } catch (error) {
    dispatch({ type: types.GET_TICKET_FAILURE, error });
  }
};

export const saveCart = (companyID, params) => async (dispatch: any) => {
  dispatch(savingCart());

  const sg = new AuthorizedApiClient();
  const path = `companies/${companyID}/sales`;
  try {
    const response = await sg.post(params, path);
    if (response != null) {
      dispatch({ type: types.SAVE_CART_SUCCESS, response: response.data });
    }
  } catch (error) {
    dispatch({ type: types.SAVE_CART_FAILURE, error: error.response.data });
  }
};

export const calculateCartAmounts = () => ({
  type: types.UPDATE_CART_AMOUNTS,
});

export const getCustomersForCart = (companyId, params = {}) => async (
  dispatch: any,
) => {
  dispatch(requestingCustomers());
  const sg = new AuthorizedApiClient();
  const path = `companies/${companyId}/customers`;

  try {
    const response = await sg.get(params, path);

    if (response != null) {
      dispatch({
        type: types.SET_CART_CUSTOMERS_SUCCESS,
        response: response.data,
      });
    }
  } catch (error) {
    dispatch({ type: types.SET_CART_CUSTOMERS_FAILURE, error });
  }
};
