import { types } from '../../actions/store_profile/types';

export const initialState = {
  error: null,
  processing: false,
  saved: false,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case types.SAVE_STORE_PROFILE_SUCCESS:
      return {
        ...state,
        response: action.response,
        error: null,
        processing: false,
        saved: true,
      };
    case types.SAVE_STORE_PROFILE_FAILURE:
      return {
        ...state,
        error: action.error,
        processing: false,
        saved: false,
      };
    case types.SAVING_STORE_PROFILE:
      return {
        ...state,
        error: null,
        processing: true,
        saved: false,
      };
    case types.CLEAR_STORE_SAVED:
      return {
        ...state,
        saved: false,
      };

    default:
      return state;
  }
};
