import { makeStyles } from '@mui/styles';
import React from 'react';
import { Redirect } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  container: {
    display: 'grid',
    placeContent: 'center',
    height: '80vh',
  },
}));

export const ReturnPaymentAcount = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Redirect to="/store/finances" />
    </div>
  );
};
