import Axios from 'axios';
import { useCallback, useState } from 'react';
import { BASE_URL } from '../constants/api/ApiConstants';

const useDeliveryZones = (companyId: string) => {
  const [deliveryZones, setDeliveryZones] = useState([]);
  const [loadingZones, setLoadingZones] = useState(false);
  const [error, setError] = useState(null);
  const [pagination, setPagination] = useState({
    last_page: 1,
    page: 1,
    per_page: 10,
    total: 4,
  });

  const getDeliveryZones = useCallback(() => {
    setLoadingZones(true);
    Axios
      .get(`${BASE_URL}/api/v1/companies/${companyId}/deliveryzones`)
      .then((res) => {
        const { data, ...pag } = res.data.data;
        setDeliveryZones(data);
        setPagination(pag);
      })
      .catch(setError)
      .finally(() => setLoadingZones(false));
  }, [companyId]);

  const createZone = useCallback((data) => {
    setLoadingZones(true);
    return Axios
      .post(`${BASE_URL}/api/v1/companies/${companyId}/deliveryzones`, data)
      .then(console.log)
      .catch(setError)
      .finally(() => setLoadingZones(false));
  }, [companyId]);

  const updateZone = useCallback((id, data) => {
    setLoadingZones(true);
    return Axios
      .put(`${BASE_URL}/api/v1/companies/${companyId}/deliveryzones/${id}`, data)
      .then(console.log)
      .catch(setError)
      .finally(() => setLoadingZones(false));
  }, [companyId]);

  return {
    getDeliveryZones,
    pagination,
    error,
    loadingZones,
    deliveryZones,
    createZone,
    updateZone,
  };
};

export default useDeliveryZones;
