/* eslint-disable no-restricted-syntax */
import { types } from './types';
import Log from '../../util/Log';
import AuthorizedApiClient from '../../core/common/api/AuthorizedApiClient';

export const editCategory = (category = null) => ({
  type: types.EDIT_CATEGORY,
  payload: category,
});

export const savingCategory = () => ({
  type: types.SAVING_CATEGORY,
});

export const saveCategorySucceed = response => ({
  type: types.SAVE_CATEGORY_SUCCESS,
  response,
});

export const saveCategoryFailed = error => ({
  type: types.SAVE_CATEGORY_FAILURE,
  error,
});

export const deletingCategory = () => ({
  type: types.DELETING_CATEGORY,
});

export const deleteCategorySucceed = response => ({
  type: types.DELETE_CATEGORY_SUCCESS,
  response,
});

export const deleteCategoryFailed = error => ({
  type: types.DELETE_CATEGORY_FAILURE,
  error,
});

export const closeModalCategory = () => ({
  type: types.CLOSE_CATEGORY_MODAL,
});

export const saveCategory = (_data, companyId) => async (dispatch: any) => {
  dispatch(savingCategory());
  const sg = new AuthorizedApiClient();
  let path = `companies/${companyId}/categories`;

  try {
    let response;
    const { id } = _data;
    const data = _data;

    if (id != null) {
      path = `${path}/${id}`;
      delete data.id;
      response = await sg.put(data, path);
    } else {
      delete data.id;
      response = await sg.post(data, path);
    }

    if (response != null) {
      Log.debug('saved category', response);
      dispatch(saveCategorySucceed(response));
      dispatch(closeModalCategory());
    }
  } catch (error) {
    Log.error(error.message);
    dispatch(saveCategoryFailed(error.message));
  }
};

export const deleteCategory = (category, companyId) => async (dispatch: any) => {
  dispatch(deletingCategory());
  const sg = new AuthorizedApiClient();
  let path = `companies/${companyId}/categories`;
  try {
    if (!category.id) {
      throw new Error('The id is required');
    }

    path = `${path}/${category.id}`;
    const response = await sg.delete(path);

    if (response != null) {
      Log.debug('deleted category', response);
      dispatch(deleteCategorySucceed(response));
      dispatch(closeModalCategory());
    }
  } catch (error) {
    Log.error(error.message);
    dispatch(deleteCategoryFailed(error.response?.data || error.message));
  }
};
