/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/prop-types */
import React, { Fragment } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import MUIDataTable from 'mui-datatables';
import { Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import Box from '../../../../../../../components/ui/Layout/Box';
import SectionText from '../../SectionText';
import ProductListInput from './ProductListInput';
import FormattedCurrency from '../../../../../../../components/ui/FormattedCurrency';

const useStyle = makeStyles(theme => ({
  root: {
    position: 'relative',
  },
  '@global': {
    '.MuiTableCell-head': {
      color: theme.palette.secondary.dark,
      fontSize: '0.8rem',
    },
    '.MuiTableCell-body': {
      color: theme.palette.secondary.dark,
      fontWeight: 'normal',
      fontSize: '0.8rem',
    },
    '.MuiTablePagination-actions': {
      marginLeft: '0',
    },
    '.MuiChip-root': {
      display: 'none',
    },
    '.MuiTableCell-root': {
      padding: '5px 5px',
    },
  },
  deleteIcon: {
    cursor: 'pointer',
  },
}));

const defaultOptions = {
  filter: false,
  search: false,
  print: false,
  download: false,
  viewColumns: false,
  filterType: 'dropdown',
  fixedHeader: true,
  selectableRows: 'none',
  pagination: false,
  onRowClick() {
  },
  serverSide: false,

  textLabels: {
    body: {
      noMatch: '',
    },
    pagination: {},
  },
  responsive: 'standard',
};

const ProductsList = ({
  products,
  handleDeleteProduct,
  handleProductPrice,
  handleProductMaxQuantity,
}) => {
  const { t } = useTranslation(['general']);
  const classes = useStyle();

  const _deleteProduct = (id) => {
    if (handleDeleteProduct) {
      handleDeleteProduct(id);
    }
  };

  defaultOptions.textLabels.pagination = {
    next: t('general:pagination:next'),
    previous: t('general:pagination:previous'),
    rowsPerPage: t('general:pagination:per_page'),
    displayRows: t('general:pagination:of'),
  };

  const options = { ...defaultOptions };

  const _handleProductPrice = (id, price) => {
    handleProductPrice(id, price);
  };

  const _handleProductMaxQuantity = (id, quantity) => {
    handleProductMaxQuantity(id, quantity);
  };

  const idIndex = 5;

  const columns = [
    {
      name: 'name',
      label: t('general:product'),
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'price',
      label: t('general:price'),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const index = tableMeta.rowIndex;
          let visiblePrice = 0;
          if (products[index] && products[index].variants[0]) {
            visiblePrice = products[index].variants[0].price;
          }
          return <FormattedCurrency value={visiblePrice} />;
        },
      },
    },
    {
      name: 'campaign_price',
      label: t('general:shared_campaigns:shared_price'),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const row = tableMeta.rowData;
          const id = row[idIndex];
          const index = tableMeta.rowIndex;

          return (
            <ProductListInput
              onChange={(event) => {
                _handleProductPrice(id, event.target.value);
              }}
              defaultValue={
                products[index] ? products[index].campaign_price : null
              }
            />
          );
        },
      },
    },
    {
      name: 'max_quantity',
      label: t('general:shared_campaigns:max_quantity'),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const row = tableMeta.rowData;
          const id = row[idIndex];
          const index = tableMeta.rowIndex;
          return (
            <ProductListInput
              onChange={(event) => {
                _handleProductMaxQuantity(id, event.target.value);
              }}
              defaultValue={
                products[index] ? products[index].max_quantity : null
              }
            />
          );
        },
      },
    },

    {
      name: '',
      label: '',
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({ style: { maxWidth: '30px' } }),
        customBodyRender: (value, tableMeta) => {
          const row = tableMeta.rowData;
          const id = row[idIndex];
          return (
            <Box display="flex" justifyContent="flex-end" width="30px">
              <DeleteIcon
                onClick={() => _deleteProduct(id)}
                className={classes.deleteIcon}
              />
            </Box>
          );
        },
      },
    },
    {
      name: 'id',
      label: '',

      options: {
        display: false,
        filter: false,
        sort: false,
      },
    },
  ];

  return (
    <>
      {products.length > 0 ? (
        <Paper className={classes.root}>
          <MUIDataTable data={products} columns={columns} options={options} />
        </Paper>
      ) : (
        <SectionText
          text={t('general:shared_campaigns:no_associated_products')}
        />
      )}
    </>
  );
};

export default ProductsList;
