/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-unused-prop-types */
import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import Box from '../Layout/Box';

const useStyle = makeStyles(theme => ({
  wrapper: {
    backgroundColor: 'rgba(154, 148, 148, 0.7)',
    width: '100%',
    height: '100%',
    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
    borderRadius: '14px',

    cursor: 'pointer',
  },

  noImageIcon: {
    color: theme.colors.white,
    width: '20%',
    height: '20%',
  },

  img: {
    borderRadius: '14px',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    height: '100%',
    cursor: 'pointer',
    backgroundSize: 'cover',
  },
}));

type ImageUploaderProps = {
  defaultPreview?: string;
  imgUrl: string;
  setImage?: Function;
  alt?: string;
  selectMenu: any
};
export default ({ selectMenu, imgUrl, setImage }: ImageUploaderProps) => {
  const classes = useStyle();

  const [localImage, setLocalImage] = useState({
    raw: '',
    preview: imgUrl ? `${imgUrl}?v=${Math.random()}` : null,
  });

  const handleChange = (e) => {
    if (e.target.files.length) {
      setLocalImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
      setImage(e.target.files[0]);
    }
  };

  const id = `upload-button${Math.floor(Math.random() * 1000)}${1}`;

  return (
    <Box width={1} height="100%" className={classes.wrapper}>
      <Box
        component="label"
        htmlFor={id}
        width={1}
        height={1}
        style={{ cursor: 'pointer' }}
      >
        <Box width={1} height="100%" display="flex" alignItems="center">
          {localImage.preview != null ? (
            <div
              style={{
                width: '100%',
                height: '100%',
                backgroundImage: `url(${localImage.preview})`,
              }}
              className={classes.img}
            />
          ) : (
            <Box
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              width="100%"
              height="100%"
              display="flex"
            >
              <AddAPhotoIcon className={classes.noImageIcon} />
            </Box>
          )}
        </Box>
      </Box>
      <input
        // disabled={ selectMenu === false ? true : false }
        type="file"
        id={id}
        onChange={handleChange}
        style={{ display: 'none' }}
      />
    </Box>
  );
};
