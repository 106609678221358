/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import LocationsListView from './LocationsListView';

export default ({ branches }) => {
  const content = branches.map(branch => <LocationsListView key={branch.id} branch={branch} />);

  return <>{content}</>;
};
