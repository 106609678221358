import { types } from '../../actions/customer/types';

export const initialState = {
  customer: {
    id: null,
    name: '',
    last_name: '',
    email: '',
    phone: '',
  },
  error: null,
  showModal: false,
  processing: false,
  deletingCustomer: false,
  tab: 0,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case types.EDIT_CUSTOMER:
      return {
        ...state,
        showModal: true,
        customer:
          action.payload !== null ? action.payload : initialState.customer,
      };
    case types.CLOSE_CUSTOMER_MODAL:
      return initialState;
    case types.SAVE_CUSTOMER_SUCCESS:
      return {
        ...state,
        response: action.response,
        error: null,
        processing: false,
      };
    case types.SAVE_CUSTOMER_FAILURE:
      return {
        ...state,
        error: action.error,
        processing: false,
      };
    case types.SAVING_CUSTOMER:
      return {
        ...state,
        error: null,
        processing: true,
      };
    case types.DELETING_CUSTOMER:
      return {
        ...state,
        error: null,
        deletingCustomer: true,
      };
    case types.DELETE_CUSTOMER_SUCCESS:
      return {
        ...state,
        error: null,
        deletingCustomer: false,
      };
    case types.DELETE_CUSTOMER_FAILURE:
      return {
        ...state,
        error: action.error,
        deletingCustomer: false,
      };

    case types.SET_CUSTOMER_SUCCESS:
      return {
        ...state,
        customer: action.customer,
      };
    case types.SET_CUSTOMER_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case types.SET_CUSTOMER_TAB:
      return {
        ...state,
        tab: action.tab,
      };

    default:
      return state;
  }
};
