/* eslint-disable react/prop-types */
import { Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '../../../../../components/ui/Layout/Box';
import LocationsByBranch from './LocationsByBranch';

export default ({ branch }) => {
  const { t } = useTranslation(['general']);

  return (
    <Box mb={3}>
      <Typography variant="h6">
        {t('locations:locations_in')}
        {' '}
        {branch.name}
      </Typography>
      <Box mt={2}>
        <LocationsByBranch branch={branch} />
      </Box>
    </Box>
  );
};
