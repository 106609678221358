/* eslint-disable react/button-has-type */
/* eslint-disable no-nested-ternary */
import { CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
// import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux';
import Text from '../../../../../components/Text/Text';
import { BalancePaymentCard } from '../../../../../components/ui/BalancePaymentCard';
import TransferPaymentCard from '../../../../../components/ui/TransferPaymentCard';
import { WithdrawalsCard } from '../../../../../components/ui/WithdrawalsCard';
import {
  BASE_URL,
  ENVIRONMENT,
} from '../../../../../constants/api/ApiConstants';

const useStyles = makeStyles(() => ({
  container: {
    maxWidth: 900,
    margin: '0px auto',
    padding: '15px 10px',

  },
  bodyText: {
    color: 'black',
    marginTop: '20px',

  },
  creditCard: {
    margin: '20px 0px',
    display: 'flex',
    width: '100%',
    gap: '10px',
    justifyContent: 'space-between',
  },
  balanceCard: {
    display: 'flex',
    flexDirection: 'column',
    gap: 15,
  },
}));

export const StoreFinancesPages = () => {
  const classes = useStyles();
  const { companyId } = useSelector((state: any) => state.session);
  const [load, setLoad] = useState(true);
  const [paymentAccounts, setPaymentAccounts] = useState<any>([]);

  const deleteAcount = async (id) => {
    Axios.delete(`${BASE_URL}/api/v1/companies/${companyId}/payment_accounts/${id}`);
  };

  const getLinkAcount = async () => {
    Axios.post(`${BASE_URL}/api/v1/companies/${companyId}/payment_accounts`)
      .then(({ data }) => {
        window.open(data.account_link.url, '_blank');
      });
  };

  const getPaymentAcount = useCallback(
    async () => {
      Axios.get(`${BASE_URL}/api/v1/companies/${companyId}/payment_accounts`)
        .then(({ data }) => {
          if ((data.data[0].status === 'new' || 'validated' || 'verified') && data.data[0].data.bank_name) {
            setPaymentAccounts(data.data);
          }
        })
        .catch(() => {
        })
        .finally(() => {
          setLoad(false);
        });
    },
    [companyId],
  );

  useEffect(() => {
    getPaymentAcount();
  }, [getPaymentAcount]);

  return (
    <main className={classes.container}>
      {
        load
          ? (
            <div className={classes.container}>
              <CircularProgress />
            </div>
          )
          : paymentAccounts.length > 0
            ? (
              <div>
                <div>
                  <Text variant="h5" className={classes.bodyText}>
                    {' '}
                    <b> Cuenta para retiro de dinero</b>
                  </Text>
                  <Text>Esta información es derivada de la plataforma STRIPE  </Text>
                </div>

                <div className={classes.creditCard}>
                  {
                    paymentAccounts.map(tranasferInfo => (
                      <div key={tranasferInfo.id} style={{ alignSelf: 'start' }}>
                        <TransferPaymentCard
                          key={tranasferInfo.id}
                          transferInfo={tranasferInfo}
                        />
                      </div>
                    ))
                  }
                  <div className={classes.balanceCard}>
                    <BalancePaymentCard balanceType="available" balanceInfo={paymentAccounts[0].balance.available} />
                    <BalancePaymentCard balanceType="pending" balanceInfo={paymentAccounts[0].balance.pending} />
                  </div>
                </div>
                {
                  ENVIRONMENT === 'QA_ENVIRONMENT'
                    ? (
                      <button onClick={() => deleteAcount(paymentAccounts[0].id)}>
                        eliminar cuenta
                      </button>
                    )
                    : null
                }
              </div>
            )
            : (
              <WithdrawalsCard onClick={getLinkAcount} />
            )
      }
    </main>
  );
};
