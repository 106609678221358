/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/prop-types */
import { CircularProgress } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AuthorizedApiClient from '../../../core/common/api/AuthorizedApiClient';
import { useSession } from '../../../util/session';
import { AutoCompleteOption } from '../FormInput/AutoComplete';
import { SelectOption } from '../FormInput/Select';
import TextField from '../TextField';

const CompanyFilter = ({ onChange, current = null }) => {
  const { t } = useTranslation(['general']);
  const { company } = useSession();
  const companyId = company && company.id ? company.id : null;
  const [companiesOptions, setCompanysOptions] = useState<SelectOption[]>([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState(current ? current.label : '');
  const [open, setOpen] = React.useState(false);

  const _handleOnChange = (event, value) => {
    handleOnChange(value);
  };

  const _getCompanys = useCallback(async (companyId, filter) => {
    const sg = new AuthorizedApiClient();
    const path = 'companies';
    setLoading(true);
    try {
      const params = { filters: {}, sort: { created_at: 'desc' } };

      if (filter) {
        params.filters['name'] = filter;
      }

      const response = await sg.get(params, path);

      if (response != null) {
        const companies = response.data.data;
        const _companies = companies.map(b => ({
          value: b.id,
          label: b.name,
        } as AutoCompleteOption));

        setCompanysOptions(_companies);

        setLoading(false);
      }
    } catch (error) {
      setCompanysOptions([]);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (current) {
      setFilter(current.label);
    } else {
      setFilter('');
    }
  }, [current]);

  const handleOnChange = (event) => {
    onChange(event || null);
  };

  useEffect(() => {
    if (open && companyId) {
      _getCompanys(companyId, filter);
    }
  }, [_getCompanys, companyId, filter, open]);

  return (
    <Autocomplete
      onChange={_handleOnChange}
      inputValue={filter !== '-1' ? filter : null}
      onInputChange={(event, newInputValue) => {
        setFilter(newInputValue);
      }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) => option.label === value.label}
      getOptionLabel={option => option.label}
      options={companiesOptions}
      loading={loading}
      forcePopupIcon={false}
      fullWidth
      defaultValue={null}
      // value={value ? value : null}
      renderInput={params => (
        <TextField
          {...params}
          placeholder={t('general:shop')}
          fullWidth
          InputProps={{
            ...params.InputProps,

            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default CompanyFilter;
