/* eslint-disable react/prop-types */
import React from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Box from '../../../../../../components/ui/Layout/Box';

const useStyle = makeStyles(theme => ({
  income: {
    background: `url(${theme.icons.income}) no-repeat right`,
  },
  outcome: {
    background: `url(${theme.icons.outcome}) no-repeat right`,
  },
  adjustement: {
    background: `url(${theme.icons.adjustment}) no-repeat right`,
  },
  button: {
    border: '1px solid #707070',
    borderRadius: '13px',
    padding: theme.spacing(1),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingRight: theme.spacing(4),
    cursor: 'pointer',
    backgroundPositionX: '94%',
    backgroundSize: '25%',
  },
}));

export default ({ text, type, onClick }) => {
  const classes = useStyle();
  let customClass;

  switch (type) {
    case 3:
      customClass = classes.adjustement;
      break;
    case 2:
      customClass = classes.outcome;
      break;

    case 1:
      customClass = classes.income;
      break;
    default:
      customClass = classes.income;
  }
  return (
    <Box
      flexGrow={1}
      className={`${classes.button} ${customClass}`}
      onClick={() => onClick(type)}
    >
      <Typography>{text}</Typography>
    </Box>
  );
};
