import React from 'react';
import { makeStyles } from '@mui/styles';
import { Typography, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Box from '../../../../../components/ui/Layout/Box';
import FormattedCurrency from '../../../../../components/ui/FormattedCurrency';

const styles = makeStyles(() => ({
  title: {
    fontSize: '0.7rem',
  },
}));

interface PriceProps {
  variants : Array<any>
}

export default ({ variants }: PriceProps) => {
  const { t } = useTranslation(['products']);
  const classes = styles();

  const price = variants.length > 0 && variants[0].price != null ? variants[0].price : '0';
  return (
    <Grid container>
      <Grid container item xs={12} justifyContent="center">
        <Typography className={classes.title}>
          {t('products:price')}
        </Typography>
      </Grid>

      <Grid
        container
        item
        xs={12}
        justifyContent="center"
        style={{ borderLeft: '1px solid #CCC' }}
      >
        <Box p={0} m={0}>
          <Typography style={{ fontSize: '1.4rem' }}>
            <FormattedCurrency value={price} />
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};
