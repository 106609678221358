import { Box } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import React from 'react';

export const RowTableSkeleton = () => (
  <Box flexDirection="column">
    {
        [...Array(10).keys() as any]
          .map(i => (
            <Skeleton
              key={i}
              variant="rectangular"
              width="100%"
              height={55}
              style={{ margin: '10px 0px' }}
            />
          ))
      }
  </Box>
);
