import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../../components/ui/Button';
import SuccessButton from '../../../../../../components/ui/Buttons/SuccessButton';
import Grid from '../../../../../../components/ui/Layout/Grid';

interface ActionsProps {
  handleClose: Function;
  handleSubmit: Function;
  processing: boolean;
}

export default ({
  handleClose,
  handleSubmit,
  processing,
}: ActionsProps) => {
  const { t } = useTranslation();
  return (
    <Grid container>
      <Grid container item xs={6} justifyContent="center">

        <Button
          text={t('general:cancel')}
          onClick={handleClose}
        />

      </Grid>
      <Grid container item xs={6} justifyContent="center">
        <SuccessButton
          processing={processing ? 1 : 0}
          onClick={handleSubmit}
          color="primary"
          text={t('general:save')}
        />
      </Grid>
    </Grid>
  );
};
