import { types } from './types';
import ApiRegisterGateway from '../../core/gateways/api/ApiRegisterGateway';
import Log from '../../util/Log';
import { login } from '../auth';

export const setRequestRegister = () => ({
  type: types.REQUEST_REGISTER,

});

export const clearRegister = () => ({
  type: types.CLEAR_REGISTER,

});

export const setRegisterUser = (user:any) => ({
  type: types.REGISTER_USER,
  payload: user,
});

// export const setStep = (step:number) => {
//     return {
//         type: types.SET_STEP,
//         payload: step
//     }
// }

export const setRegisterFailure = error => ({
  type: types.REGISTER_FAILURE,
  payload: error,
});

export const register = (data: any) => async (dispatch) => {
  const arg = ApiRegisterGateway.getInstance();
  dispatch(setRequestRegister());
  try {
    const response = await arg.register(data);
    dispatch(setRegisterUser(response.data.user));
    await dispatch(login(data.email, data.password));
    // const {user} = response.data;
    // if(user.register_step > 1){
    //     dispatch(setStep(user.register_step+1));
    // }else{
    //     dispatch(setStep(2));
    // }
  } catch (error) {
    Log.debug('Error register', error.message);
    dispatch(setRegisterFailure(error.response.data));
  }
};

// export const saveCompany = (data: any) => async (dispatch)=> {
//     let arg = ApiRegisterGateway.getInstance();
//     dispatch(setRequestRegister());

//     try{
//         let response = await arg.saveCompany(data);
//         Log.debug("response company", response);
//         if(response.data.user != null){
//             Log.debug("response password user", response.data.user);
//             dispatch(setRegisterUser(response.data.user));
//             dispatch(setStep(3));
//         }

//     }catch(error){
//         Log.debug("Error saving company", error);

//         dispatch(setRegisterFailure(errorParser(error)));
//     }

// }

// export const savePassword = (data: any) => async (dispatch)=> {
//     let arg = ApiRegisterGateway.getInstance();
//     dispatch(setRequestRegister());

//     try{
//         let response = await arg.savePassword(data);
//         Log.debug("response password", response);
//         if(response.data.user != null){
//             Log.debug("response password user", response.data.user);
//             dispatch(setRegisterUser(response.data.user));

//             return true;
//         }

//     }catch(error){
//         Log.debug("Error saving password", error);
//         dispatch(setRegisterFailure(errorParser(error)));

//     }

// }
