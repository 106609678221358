/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/prop-types */
import {
  Typography,
} from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import OrderPaymentStatus from '../../../../containers/pages/admin/StorePage/OrdersPage/OrderPaymentStatus';
import OrderStatus from '../../../../containers/pages/admin/StorePage/OrdersPage/OrderStatus';
import OrderStatusFilter from '../../../../containers/pages/admin/StorePage/OrdersPage/OrderStatusFilter';
import { getFormattedDate } from '../../../../util/dates';
import AsyncPaginatedTable, {
  usePaginatedTable,
} from '../../../ui/AsyncPaginatedTable';
import DateFilter from '../../../ui/DateFilter';
import FormattedCurrency from '../../../ui/FormattedCurrency';
import Box from '../../../ui/Layout/Box';
import Grid from '../../../ui/Layout/Grid';
import OrderCampaignFilter from '../../../ui/OrderCampaignFilter';
import OrderListDeliveryMethod from '../../../ui/OrderListDeliveryMethod';
import OrderListPaymentMethod from '../../../ui/OrderListPaymentMethod';
import OrderDate from './OrderDate';
import Alert from '../../../ui/notifications/alert';
import { useOrdersContext } from './context/OrdersContext';
import CompanyFilter from '../../../ui/CompanyFilter';
import ShippingAddress from './ShippingAddress';
import Articles from './Articles';

const useStyles = makeStyles(() => ({
  cellContainer: {
    minWidth: '200px',
  },
}));

export default ({
  setError,
  error = null,
  message = null,
  setMessage,
  title,
}) => {
  const { t } = useTranslation(['general']);
  const classes = useStyles();
  const {
    state, getOrders, setParams, resetOrders,
  } = useOrdersContext();

  const { filters } = state.params;
  const startDate = filters && filters.created_at
    ? filters && filters.created_at.$gte
    : null;

  const endDate = filters && filters.created_at
    ? filters && filters.created_at.$lte
    : null;

  const data = state.response.data.map((r) => {
    let userPhone = r.user && r.user.phone ? r.user.phone : '';

    if (!userPhone && r.shipping_address && r.shipping_address.phone) {
      userPhone = r.shipping_address.phone;
    }
    return {
      ...r,
      company: r.company && r.company.name ? r.company.name : '',
      user: r.user ? `${r.user.name} ${r.user.last_name}` : '',
      company_phone: r.company && r.company.phone ? r.company.phone : '',
      company_in_charge:
        r.company && r.company.users && r.company.users[0].name
          ? `${r.company.users[0].name} ${r.company.users[0].last_name}`
          : '',
      user_phone: userPhone,
    };
  });

  const [currentCampaign, setCurrentCampaign] = useState({
    value: '',
    label: '',
  });

  const [currentCompany, setCurrentCompany] = useState({
    value: '',
    label: '',
  });

  const { params } = state;
  const _getOrders = useCallback(
    async (_params = {}) => {
      const updatedParams = { ...params, ..._params };
      if (updatedParams.filters && updatedParams.filters.campaigns) {
        updatedParams.filters.campaign_id = updatedParams.filters.campaigns;
        delete updatedParams.filters.campaigns;
      }
      setParams(updatedParams);

      await getOrders(updatedParams);
    },
    [params, getOrders, setParams],
  );

  const [visibleColumns, setVisibleColumns] = useState([
    'created_at',
    'number',
    'company',
    'company_phone',
    'company_in_charge',
    'campaigns',
    'user',
    'user_phone',
    'shipping_method',
    'shipping_address',
    'items',
    'total',
    'status',
  ]);

  const { handleViewColumnsChange, isVisibleColumn } = usePaginatedTable();

  const onChangedStatus = useCallback(
    (order) => {
      setMessage(
        t('general:orders_page:changed_status_success_msg', {
          order_number: order.number,
        }),
      );
      _getOrders();
    },

    [t, setMessage, _getOrders],
  );

  const onStartToChangeStatus = useCallback(
    () => {
      setMessage(null);
    },
    [setMessage],
  );

  const isVisible = useCallback(
    column => isVisibleColumn(visibleColumns, column),
    [visibleColumns, isVisibleColumn],
  );

  const columns = [
    {
      name: 'id',
      label: '',

      options: {
        display: 'excluded',
        filter: false,
        sort: false,
      },
    },
    {
      name: 'company_id',
      label: '',

      options: {
        display: 'excluded',
        filter: false,
        sort: false,
      },
    },
    {
      name: 'created_at',
      label: t('general:date'),
      filterName: 'created_at',
      options: {
        filter: true,
        filterType: 'custom',
        display: isVisible('created_at'),
        filterOptions: {
          display: (filterList, onChange, index, column) => (
            <Box mt={2}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <DateFilter
                    label={t('general:filters:from_date')}
                    value={
                      state.params.filters && state.params.filters.created_at
                        ? state.params.filters
                          && state.params.filters.created_at.$gte
                        : null
                    }
                    maxDate={endDate || new Date()}
                    onChange={(date) => {
                      const dates = [];
                      if (date) {
                        dates[0] = getFormattedDate(date);
                      } else {
                        dates[0] = '';
                      }
                      if (endDate) {
                        dates[1] = getFormattedDate(endDate);
                      } else {
                        dates[1] = dates[0];
                      }
                      try {
                        filterList[index][0] = date ? dates.join('|') : '';
                        onChange(filterList[index], index, column);
                      } catch (e) {
                        console.log('error date filter', e.message);
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <DateFilter
                    label={t('general:filters:from_to')}
                    value={endDate}
                    minDate={startDate || null}
                    maxDate={new Date()}
                    onChange={(date) => {
                      const dates = [];
                      if (date) {
                        dates[1] = getFormattedDate(date);
                      } else {
                        dates[1] = '';
                      }
                      if (startDate) {
                        dates[0] = getFormattedDate(startDate);
                      } else {
                        dates[0] = dates[1];
                      }
                      try {
                        filterList[index][0] = date ? dates.join('|') : '';
                        onChange(filterList[index], index, column);
                      } catch (e) {
                        console.log('error date filter', e.message);
                      }
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          ),
        },
        customFilterListOptions: {
          render: (v) => {
            if (Array.isArray(v)) {
              const dates = v[0].split('|');
              if (dates[0] === dates[1]) {
                return dates[0];
              }
              return (
                `${dates[0]} ${t('general:filters:from_to')} ${dates[1]}`
              );
            }
            return [];
          },
        },
        sort: false,
        customBodyRender: (value) => {
          if (value) {
            return <OrderDate value={value} />;
          }
          return '';
        },
      },
      customRenderer: value => <OrderDate value={value} />,
    },

    {
      name: 'number',
      label: t('general:order_number'),

      options: {
        filter: false,
        sort: false,
        display: isVisible('number'),
        customBodyRender: value => (
          <>
            #
            {value}
          </>
        ),
      },
    },

    {
      name: 'company',
      label: t('general:shop'),

      options: {
        filter: true,
        sort: false,
        display: isVisible('company'),
        filterType: 'custom',

        filterOptions: {
          display: (filterList, onChange, index, column) => (
            <Grid item xs={12}>
              <CompanyFilter
                onChange={(company) => {
                  setCurrentCompany(company);
                  filterList[index][0] = company ? company.value : '';
                  onChange(filterList[index], index, column);
                }}
                current={filters && filters.company ? currentCompany : null}
              />
            </Grid>
          ),
        },
        customBodyRender: value => <Box className={classes.cellContainer}>{value}</Box>,
      },
    },

    {
      name: 'company_phone',
      label: t('general:phone'),

      options: {
        filter: false,
        sort: false,
        display: isVisible('company_phone'),

        customBodyRender: (company_phone) => {
          if (company_phone) {
            return company_phone;
          }
          return '';
        },
      },
    },

    {
      name: 'company_in_charge',
      label: t('general:in_charge'),

      options: {
        filter: false,
        sort: false,
        display: isVisible('company_in_charge'),

        customBodyRender: (company_in_charge) => {
          if (company_in_charge) {
            return company_in_charge;
          }
          return '';
        },
      },
    },

    {
      name: 'campaigns',
      label: t('general:shared_campaigns:campaign'),

      options: {
        filter: true,
        sort: false,
        display: isVisible('campaigns'),
        filterType: 'custom',

        filterOptions: {
          display: (filterList, onChange, index, column) => (
            <Grid item xs={12}>
              <OrderCampaignFilter
                onChange={(campaign) => {
                  setCurrentCampaign(campaign);
                  filterList[index][0] = campaign ? campaign.value : '';
                  onChange(filterList[index], index, column);
                }}
                current={
                  filters && filters.campaign_id ? currentCampaign : null
                }
                companyId={filters.company ? filters.company : null}
              />
            </Grid>
          ),
        },
        customBodyRender: (campaigns) => {
          if (campaigns && campaigns.length > 0) {
            const names = campaigns.map(c => c.name);
            return (
              <Box className={classes.cellContainer}>{names.join(', ')}</Box>
            );
          }
          return t('general:regular');
        },
      },
    },

    {
      name: 'user',
      label: t('general:customer'),

      options: {
        filter: false,
        sort: false,
        display: isVisible('user'),
        customBodyRender: value => <Box className={classes.cellContainer}>{value}</Box>,
      },
    },

    {
      name: 'user_phone',
      label: t('general:phone'),

      options: {
        filter: false,
        sort: false,
        display: isVisible('user_phone'),
      },
    },

    {
      name: 'shipping_method',
      label: t('general:shipping_method'),

      options: {
        filter: false,
        sort: false,
        display: isVisible('shipping_method'),
        customBodyRender: value => <OrderListDeliveryMethod value={value} />,
      },
    },

    {
      name: 'shipping_address',
      label: t('general:shipping_address'),

      options: {
        filter: false,
        sort: false,
        display: isVisible('shipping_address'),
        customBodyRender: shipping_address => (
          <Box className={classes.cellContainer}>
            <ShippingAddress shipping_address={shipping_address} />
          </Box>
        ),
      },
    },

    {
      name: 'items',
      label: t('general:items'),

      options: {
        filter: false,
        sort: false,
        display: isVisible('items'),
        customBodyRender: items => (
          <Box className={classes.cellContainer}>
            <Articles items={items} />
          </Box>
        ),
      },
    },

    {
      name: 'subtotal',
      label: t('general:subtotal'),

      options: {
        filter: false,
        sort: false,
        display: isVisible('subtotal'),
        customBodyRender: value => (
          <Typography>
            <FormattedCurrency value={value} />
          </Typography>
        ),
      },
    },
    {
      name: 'delivery_price',
      label: t('general:store_page:delivery_price'),

      options: {
        filter: false,
        sort: false,
        display: isVisible('delivery_price'),
        customBodyRender: value => (
          <Typography>
            <FormattedCurrency value={value} />
          </Typography>
        ),
      },
    },
    {
      name: 'total',
      label: t('general:total'),

      options: {
        filter: false,
        sort: false,
        display: isVisible('total'),
        customBodyRender: value => (
          <Typography>
            <FormattedCurrency value={value} />
          </Typography>
        ),
      },
    },
    {
      name: 'status',
      label: t('general:status'),

      options: {
        filter: true,
        sort: false,
        filterType: 'custom',
        display: isVisible('status'),
        customFilterListOptions: {
          render: v => v.map(status => (status ? t(`general:orders_page:${status}`) : null)),
          update: (filterList, filterPos, index) => {
            filterList[index].splice(filterPos, 1);
            return filterList;
          },
        },
        filterOptions: {
          logic: (location, filters) => {
            if (filters.length) return !filters.includes(location);
            return false;
          },
          display: (filterList, onChange, index, column) => (
            <Grid item xs={12}>
              <OrderStatusFilter
                onChange={(value) => {
                  filterList[index][0] = value;
                  onChange(filterList[index], index, column);
                }}
                value={filters && filters.status ? filters.status : ''}
              />
            </Grid>
          ),
        },
        customBodyRender: (value, tableMeta) => {
          const order = data[tableMeta.rowIndex];
          return (
            <OrderStatus
              order={order}
              setError={setError}
              onChangedStatus={onChangedStatus}
              onStartToChangeStatus={onStartToChangeStatus}
            />
          );
        },
      },
    },
    {
      name: 'paid',
      label: t('general:paid'),

      options: {
        filter: false,
        sort: false,
        display: isVisible('paid'),
        customBodyRender: value => <OrderPaymentStatus paid={value} />,
      },
    },
    {
      name: 'payment_type',
      label: t('general:payment_method'),

      options: {
        filter: false,
        sort: false,
        display: isVisible('payment_type'),
        customBodyRender: value => <OrderListPaymentMethod value={value} />,
      },
    },
  ];

  const _handleViewColumnsChange = (changedColumn: string, action: string) => {
    handleViewColumnsChange(
      changedColumn,
      action,
      visibleColumns,
      setVisibleColumns,
    );
  };

  return (
    <Box>
      <Box mt={3}>
        {error && (
          <Box mb={2}>
            <Alert
              severity="error"
              message={t(`general:orders_page:${error}`, error)}
            />
          </Box>
        )}
        {message && (
          <Box mb={2}>
            <Alert severity="success" message={message} />
          </Box>
        )}
        <AsyncPaginatedTable
          hasNextPage={state.response.page < state.response.last_page}
          currentPage={state.response.page}
          perPage={state.response.per_page}
          data={data}
          columns={columns}
          loadData={params => _getOrders(params)}
          resetData={resetOrders}
          processing={state.processing}
          total={state.response.total}
          options={{
            filter: true,
            search: true,
            viewColumns: true,
            onViewColumnsChange: _handleViewColumnsChange,
          }}
          title={title}
          filterFields={[{ field: 'created_at', type: 'date_range' }]}
          searchField="number"
        />
      </Box>
    </Box>
  );
};
