import React, { useContext } from 'react';
import { Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { ShepherdTourContext } from 'react-shepherd';
import Grid from '../../../../../components/ui/Layout/Grid';
import { useSession } from '../../../../../util/session';

const useStyles = makeStyles(theme => ({
  header: {
    padding: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 14,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  img: {
    width: 80,
    height: 80,
    borderRadius: 40,
    boxShadow: theme.shadow.main,
    objectFit: 'cover',
    display: 'grid',
    placeContent: 'center',
    backgroundColor: '#737373',
    color: 'white',
  },
  buttonHelp: {
    padding: 3,
    margin: '0px 3px',
    minWidth: 0,
    width: 42,
    height: 42,
    borderRadius: 21,
  },
  title: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
}));

export const Header = () => {
  const classes = useStyles();
  const { company } = useSession();
  const tour = useContext(ShepherdTourContext);

  const handleOpenHelp = () => {
    tour.show('intro');
  };

  return (
    <Grid className={classes.header}>
      {
        company.image_url ? (
          <img className={classes.img} src={company.image_url} alt={company.name} />
        )
          : (
            <span className={classes.img}>
              <h2>{company.name.charAt(0)}</h2>
            </span>
          )
      }
      <Grid className={classes.title}>
        <Typography variant="h4">
          {company.name}
        </Typography>
        <Button onClick={handleOpenHelp} className={classes.buttonHelp}>
          <HelpOutlineIcon />
        </Button>
      </Grid>
    </Grid>
  );
};
