/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Box from '../Layout/Box';
import Check from './Check';
import FormInput from '../FormInput';

const useStyle = makeStyles(theme => ({
  text: {
    [theme.breakpoints.down('md')]: {
      fontSize: '0.8rem',
    },
  },

  '@global': {
    '.inputLight': {
      width: '70px',
    },
    '.inputLight input': {
      padding: '5px 10px',
    },
  },
}));

export default (props) => {
  const {
    handleOnChange,
    register,
    text,
    title,
    defaultValue,
    error,
  } = props;

  const classes = useStyle();

  return (
    <Box display="flex">
      <Box display="flex" width={1}>
        <Box>
          <Check alert={props.alert} />
        </Box>
        <Box flexGrow={1}>
          <Box width={1} pl={1}>
            <Typography variant="subtitle1" color="secondary">
              {title}
            </Typography>
            <Box display="flex" alignItems="flex-start">
              <Box>
                <Typography
                  variant="subtitle2"
                  color="secondary"
                  className={classes.text}
                >
                  {text}
                  {' '}
&nbsp;
                </Typography>
              </Box>
              <Box>
                <FormInput
                  className="inputLight"
                  register={register}
                  type="number"
                  onChange={handleOnChange}
                  defaultValue={defaultValue}
                  error={error}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
