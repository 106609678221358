/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/prop-types */
import React, { useCallback, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Grid, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CartButton from '../CartButton';
import OpenPointSell from '../OpenPointSell';
import CartItem from '../CartItem';
import {
  getTicket, setStep, clearCartError, removeCart,
} from '../../../../../../actions/cart';
import CartImageItem from '../CartImageItem';
import PayCard from '../PayCard';
import { hasBeenPassed } from '../../../../../../util/dates';
import { TICKET_EXPIRATION_SECONDS } from '../../../../../../constants/common';
import BackButton from './BackButton';
import CustomerSelector from '../../CustomerSelector';
import Box from '../../../../../../components/ui/Layout/Box';
import Alert from '../../../../../../components/ui/notifications/alert';
import DiscartCart from './DiscartCart';

const styles = makeStyles(theme => ({
  drawerPaper: {
    position: 'fixed',
    width: '80%',
    margin: '0px auto',
    maxWidth: 790,
    zIndex: 90,
    border: '1px solid black',
    bottom: 0,
    borderBottom: 'none',
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
    padding: 25,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    left: 0,
    right: 0,
    backgroundColor: 'white',
    height: '25%',
    transition: 'height 0.5s',
  },
  open: {
    height: '73%',
  },

  notProducts: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  interactions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '85%',
    margin: '3px 0px',
    height: '10%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },

  containerItemsClose: {
    width: '100%',
    heigth: 80,
    margin: 10,
  },
  containerItemsOpen: {
    flexGrow: 1,
    width: '100%',
    height: '70%',
  },
  cardItems: {
    width: '100%',
    overflow: 'auto',
    whiteSpace: 'nowrap',
  },
  cardItemsClose: {

  },
  cardItemsOpen: {
    height: '100%',
  },

  ticket: {
    position: 'absolute',
    backgroundColor: theme.colors.home.light,
    width: 200,
    height: 45,
    top: -46,
    left: 25,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 7,
    borderTopRightRadius: 5,
    borderTopLeftRadius: 5,
  },
}));

export default ({
  openDrawer, displayed, nextStep, setClientAlert,
}) => {
  const { t } = useTranslation(['general']);
  const classes = styles();
  const dispatch = useDispatch();
  const company = useSelector((state: any) => state.session.company);
  const companyId = useSelector((state: any) => state.session.companyId);
  const cart = useSelector((state: any) => state.cart);
  const pos = useSelector((state: any) => state.pos);
  const { error } = cart;

  const listItems = cart.items.map((item: any) => (
    <CartItem
      key={item.id}
      item={item}
    />
  ));
  const listItemsImages = cart.items
    .map((item: any) => <CartImageItem key={item.id} item={item} />);

  const itemsCart = () => {
    if (cart.items.length > 0) {
      if (cart.displayed) {
        return listItems;
      }
      return listItemsImages;
    }
    return (
      <div className={classes.notProducts}>No hay objetos en el carrito</div>
    );
  };

  const _getTicket = useCallback(
    async (params = {}) => {
      if (company) {
        await dispatch(getTicket(company.id, params));
      }
    },
    [dispatch, company],
  );

  const shouldRequestTicket = useCallback(() => {
    const ticketIsExpired = () => (
      cart.ticket !== null
        && cart.ticketExpiresAt !== null
        && hasBeenPassed(cart.ticketExpiresAt, TICKET_EXPIRATION_SECONDS) // 30 minutes
    );
    return (
      companyId != null
      && !cart.requestingTicket
      && (cart.ticket === null || ticketIsExpired())
    );
  }, [companyId, cart]);

  useEffect(() => {
    const t = async () => {
      if (shouldRequestTicket()) {
        await _getTicket();
        // console.log("request ticket");
      }
    };
    t();
  }, [_getTicket, shouldRequestTicket]);

  const back = () => {
    dispatch(setStep(1));
  };

  const _removeCart = () => {
    dispatch(removeCart());
  };

  const _clearAlert = useCallback(() => {
    if (error) {
      dispatch(clearCartError());
    }
  }, [error, dispatch]);

  return (
    <>
      <Grid className={classes.interactions}>
        <OpenPointSell click={openDrawer} />
        <DiscartCart
          step={cart.step}
          displayed={cart.displayed}
          click={() => _removeCart()}
        />
        <BackButton
          step={cart.step}
          displayed={cart.displayed}
          click={() => back()}
        />
        <Grid className={classes.ticket}>
          <Typography variant="h5">
            {t('general:point_of_sell:ticket')}
            {' '}
            {cart.ticket ? cart.ticket.number : ' ...'}
          </Typography>
        </Grid>

        {/* <AddSellDrawer displayed={cart.displayed}/> */}
        {cart.displayed && <CustomerSelector />}
      </Grid>
      <Box>
        {error && (
          <Alert
            severity="error"
            message={t(`general:point_of_sell:${error}`, error)}
            clearAlert={_clearAlert}
          />

        )}
      </Box>
      <Grid
        className={
          cart.displayed
            ? classes.containerItemsOpen
            : classes.containerItemsClose
        }
      >
        <Grid
          className={`
            ${cart.displayed || classes.cardItems} 
            ${cart.displayed ? classes.cardItemsOpen : classes.cardItemsClose}
          `}
        >
          {cart.step === 2 ? (
            <PayCard drawerOpen={displayed} total={cart.subtotal} setClientAlert={setClientAlert} />
          ) : (
            itemsCart()
          )}
        </Grid>
      </Grid>

      <CartButton
        quantity={cart.quantity}
        total={cart.subtotal}
        click={() => nextStep()}
        step={cart.step}
        processing={cart.processing}
        branch_id={pos.branch_id}
      />
    </>
  );
};
