import React from 'react';
import { useDispatch } from 'react-redux';
import { editSupplier } from '../../../../actions/supplier';
import SuppliersPageView from './SuppliersPageView';

export default () => {
  const dispatch = useDispatch();
  const openNewSupplier = () => {
    dispatch(editSupplier());
  };

  return <SuppliersPageView openNewSupplier={openNewSupplier} />;
};
