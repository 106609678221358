/* eslint-disable no-constant-condition */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/prop-types */
import {
  Box, Tooltip,
} from '@mui/material';
import React from 'react';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  container: {
    borderRadius: 5,
    border: `1px solid ${theme.colors.grey}`,
    padding: '.5em 1em',
    minWidth: '15rem',
    backgroundColor: '#F8F9FD',
    display: 'flex',
    maxWidth: '24rem',
  },
  bankIcon: {
    backgroundColor: theme.colors.grey,
    alignSelf: 'self-start',
    padding: 4,
    marginRight: 15,
    borderRadius: 3,
  },
  bodyInfo: {
    flex: 1,
    padding: '0px 5px',
  },
  pColor: {
    color: 'black',
  },
  currencyCheck: {
    display: 'flex',
    alignSelf: 'self-start',
  },
  currency: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.colors.grey,
    padding: '2px 4px',
    marginRight: '10px',
    borderRadius: 3,
  },
  marginB: {
    marginBottom: 10,
  },

}));
const TransferPaymentCard = ({ transferInfo }) => {
  const classes = useStyles();
  const {
    bank_name, account_holder_name, country, account_holder_type, last4,
  } = transferInfo.data;
  return (
    <Box className={classes.container}>
      <div className={classes.bankIcon}>
        <AccountBalanceIcon htmlColor="black" />
      </div>
      <div className={classes.bodyInfo}>
        <p className={`${classes.pColor} ${classes.marginB}`}><b>{bank_name}</b></p>
        <p>{account_holder_name}</p>
        <p className={`${classes.pColor} ${classes.marginB}`}>
          ***
          <b>{last4}</b>
        </p>
        <p>{country}</p>
        <p>{account_holder_type}</p>
      </div>
      <div className={classes.currencyCheck}>

        <Tooltip disableInteractive title="currency" placement="top">
          <p className={`${classes.currency} ${classes.marginB}`}>{transferInfo.balance.available.currency.toUpperCase()}</p>
        </Tooltip>

        <Tooltip disableInteractive title="status" placement="top">
          <CheckCircleIcon htmlColor={transferInfo.status === 'new' || 'validated' || 'verified' ? 'green' : 'red'} />
        </Tooltip>
      </div>
    </Box>
  );
};

export default TransferPaymentCard;
