import { useCallback, useState } from 'react';
import AuthorizedApiClient from '../../../../../core/common/api/AuthorizedApiClient';
import useCompany from '../../../../../hooks/useCompany';
import { DEFAULT_START_DATE, DEFAULT_END_DATE } from '../constants';

const INITIAL_DATA = {
  data: null,
  total: 0,
  per_page: 12,
  last_page: 1,
  page: 1,
};

const INITIAL_PARAMS = {
  page: 1,
  per_page: 12,
  sort: { created_at: 'desc' },
  filters: {
    updated_at: {
      $gte: DEFAULT_START_DATE,
      $lte: DEFAULT_END_DATE,
    },
  },
};

const METRIC_ENDPOINT = {
  brand: 'bestseller_brands',
  category: 'bestseller_categories',
};

const API = new AuthorizedApiClient();

type MetricsOptions =
  | 'category'
  | 'brand';

interface Props {
  initialMetric: MetricsOptions,
}

export const useSalesPerMetric = ({
  initialMetric,
}: Props = {
  initialMetric: 'category',
}) => {
  const { company } = useCompany();
  const [metric, setMetric] = useState<MetricsOptions>(initialMetric);
  const [data, setData] = useState(INITIAL_DATA);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const getSales = useCallback(async (params) => {
    const parameters = {
      ...INITIAL_PARAMS,
      ...params,
      filters: {
        ...INITIAL_PARAMS.filters,
        ...params.filters,
        updated_at: {
          ...INITIAL_PARAMS.filters.updated_at,
          ...params.filters.updated_at,
        },
      },
    };
    try {
      setLoading(true);
      const path = `sales/reports/${METRIC_ENDPOINT[metric]}`;
      const response = await API.get(parameters, path);
      setData(response.data);
    } catch (e) {
      setError(e.message);
    } finally {
      setLoading(false);
    }
  }, [company, metric]);

  const changeMetric = (otherMetric: MetricsOptions) => setMetric(otherMetric);

  return {
    getSales,
    isLoading: loading,
    sales: data,
    error,
    changeMetric,
    metric,
  };
};
