/* eslint-disable @typescript-eslint/naming-convention */
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Line,
  ResponsiveContainer,
} from 'recharts';
import Box from '../../../../../components/ui/Layout/Box';
import { useSalesPerMonthContext } from './context/SalesPerMonthContext';
import LabelledOutline from '../../../../../hoc/LabelledOutline';
import ActivityIndicator from '../../../../../components/ui/ActivityIndicator';
import { dateToUtc, getFormattedDate } from '../../../../../util/dates';
import { useDashboardContext } from '../context/DashboardContext';
import Log from '../../../../../util/Log';
import { capitalize } from '../../../../../util/strings';

const formatDate = (date: string) => {
  let formatted = '';

  const arr = date.split('_');
  if (arr.length === 2) {
    const year = arr[0];
    const month = (parseInt(arr[1], 10)).toString().padStart(2, '0');
    try {
      const dateObject = new Date(`${year}/${month}/01`);
      if (dateObject instanceof Date) {
        formatted = capitalize(getFormattedDate(dateObject, 'MMM YY'));
      }
    } catch (e) {
      Log.debug('error parsing date', e);
    }
  }

  return formatted;
};

const SalesPerMonth = () => {
  const { t } = useTranslation(['general']);
  const { getSales, state } = useSalesPerMonthContext();
  const { params, processing, response } = state;

  const { state: dashboardState } = useDashboardContext();
  const { start_date, end_date } = dashboardState;

  useEffect(() => {
    getSales({
      ...params,
      filters: {
        ...params.filters,
        updated_at: {
          $gte: dateToUtc(start_date),
          $lte: dateToUtc(end_date),
        },
      },
    });
  }, [params, start_date, end_date, getSales]);

  const data = useMemo(() => response.data.reverse().map(s => ({
    ...s,
    date: formatDate(s.date),
  })), [response]);

  return (
    <LabelledOutline label={t('general:admin_dashboard:sales_per_month')}>
      <Box mt={1} width="100%" height="250px">
        {processing ? (
          <ActivityIndicator />
        ) : (
          <ResponsiveContainer width="100%" height="100%">
            <ComposedChart width={720} height={250} data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Legend wrapperStyle={{ position: 'relative' }} />
              <Bar
                dataKey="total_campaigns"
                name="Compras colaborativas"
                fill="#8884d8"
              />
              <Bar dataKey="total_regular" name="Normal" fill="#82ca9d" />
              <Line
                type="monotone"
                dataKey="average"
                name="Promedio"
                stroke="#ff7300"
              />
            </ComposedChart>
          </ResponsiveContainer>
        )}
      </Box>
    </LabelledOutline>
  );
};

export default SalesPerMonth;
