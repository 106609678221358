import React from 'react';
import useOrdersState from '../hooks/useOrdersState';

interface IContextProps {
  state: any;
  getOrders: Function;
  setParams: Function;
  resetFilters: Function;
  resetOrders: Function;
}

export const OrdersContenxt = React.createContext({} as IContextProps);

interface Props {
  children: React.ReactNode
}
export const OrdersProvider = ({ children }: Props) => {
  const initialState = useOrdersState();
  return (
    <OrdersContenxt.Provider value={initialState}>
      {children}
    </OrdersContenxt.Provider>
  );
};

export const useOrdersContext = () => {
  const context = React.useContext(OrdersContenxt);
  if (context === undefined) {
    throw new Error(
      'OrdersState must be used within a OrdersProvider',
    );
  }
  return context;
};
