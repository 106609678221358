import { types } from '../../actions/register/types';

export const initialState = {
  // step: 1,
  user: null,
  processing: false,
  error: null,
};

export default (state = initialState, action : any) => {
  switch (action.type) {
    case types.REQUEST_REGISTER:
      return {
        ...state,
        processing: true,
        error: null,
      };
    case types.REGISTER_USER:
      return {
        ...state,
        processing: false,
        error: null,
        user: action.payload,
        // step: 2
      };
    case types.REGISTER_COMPANY:
      return {
        ...state,
        processing: false,
        error: null,
        user: action.payload,
        // step:3
      };
    case types.REGISTER_PASSWORD:
      return {
        ...state,
        processing: false,
        error: null,
        user: action.payload,
        // step:4
      };
    case types.REGISTER_FAILURE:
      return {
        ...state,
        processing: false,
        error: action.payload,
      };
      // case types.SET_STEP:
      //     return {
      //         ...state,
      //         step: action.payload
      //     }
    case types.CLEAR_REGISTER:
      return initialState;
    default:
      return state;
  }
};
