import React from 'react';
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Box from '../../../../../components/ui/Layout/Box';
import Grid from '../../../../../components/ui/Layout/Grid';
import PageContainer from '../../../../../hoc/PageContainer';
import SectionListContainer from '../../../../../hoc/SectionListContainer';
import OrdersList from './OrdersList';
import Text from '../../../../../components/Text/Text';
import { ModalGetPremium } from '../../../../../components/ModalGetPremium';
import { useSession } from '../../../../../util/session';

const useStyle = makeStyles(() => ({
  callToAction: {
    marginTop: '20px',
    backgroundColor: '#FEF6E1',
    borderRadius: 10,
  },
  textNbutton: {
    padding: 15,
  },
}));

export default () => {
  const classes = useStyle();

  const [open, setOpen] = React.useState(false);
  const { planning } = useSession();
  const typeOfPlan = planning.subscriptions[0]?.plan.code;

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <Box>
      <Grid container>
        <PageContainer>
          {/* <BreadCrumbs /> */}
          { typeOfPlan === 'free'
            ? (
              <div className={classes.callToAction}>
                <div className={classes.textNbutton}>
                  <Text>
                    Recuerda que tus ventas tienen comisión.
                    Suscríbete a uno de nuestros planes para disfrutar de muchos beneficios,
                    ¡como no pagar comisión!
                  </Text>
                  <Button onClick={handleOpen}>
                    <Text>¡Adquiere un Plan!</Text>
                  </Button>
                  <ModalGetPremium open={open} setOpen={setOpen} />
                </div>
              </div>
            )
            : null}

          <SectionListContainer>
            <OrdersList />
          </SectionListContainer>
        </PageContainer>
      </Grid>
    </Box>
  );
};
