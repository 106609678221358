import React from 'react';

import { makeStyles } from '@mui/styles';
import { List, ListItemText } from '@mui/material';

import { useTranslation } from 'react-i18next';
import MenuItem from '../MenuItem';
import ShopSuscription from '../ShopSuscription';
import { useSession } from '../../../../util/session';
import { RESOURCES, SECTIONS } from '../../../../constants/common';

const useStyles = makeStyles(() => ({
  SubMenu: {
    paddingLeft: 20,
  },
}));

const NavBar = () => {
  const { t } = useTranslation(['general']);
  const classes = useStyles();

  const { company, can } = useSession();
  const shopName = company ? company.name : '';

  return (
    <List component="nav">
      <ShopSuscription shopName={shopName} typeAccount="Free" />
      <MenuItem
        link="/"
        tittle={t('general:home_page')}
        section="home"
        shadow={false}
        background={false}
      />
      {can(RESOURCES.pos) && (
        <MenuItem
          link="/pos"
          tittle={t('general:point_sale')}
          section="pointSell"
        />
      )}
      <MenuItem
        link="/dashboard"
        tittle={t('general:dashboard')}
        section="settings"
        shadow={false}
      />

      <ListItemText primary={t('general:moduls')} className={classes.SubMenu} />

      <MenuItem
        link="/products"
        tittle={t('general:products')}
        section="products"
      />

      <MenuItem
        link="/analytics"
        tittle={t('general:analytics')}
        section="analytics"
      />

      {can(SECTIONS.inventory.name) && (
        <MenuItem
          link="/inventory"
          tittle={t('general:inventory')}
          section="inventory"
        />
      )}

      {can(RESOURCES.customers) && (
        <MenuItem
          link="/customers"
          tittle={t('general:clients')}
          section="client"
        />
      )}

      {can(SECTIONS.store) && (
        <MenuItem
          link="/store"
          tittle={t('general:store_page:my_store')}
          section="store"
        />
      )}

      {can(SECTIONS.reports) && (
        <MenuItem
          link="/reports"
          tittle={t('general:reports')}
          section="reports"
        />
      )}
      {can(SECTIONS.reports) && (
        <MenuItem
          link="/logistic"
          tittle={t('general:store_page:logistic')}
          section="pickerZones"
        />
      )}

      <ListItemText primary={t('general:more')} className={classes.SubMenu} />

      <MenuItem
        link="/settings/users-permissions"
        tittle={t('general:permission')}
        section="settings"
        shadow={false}
      />

      {can(SECTIONS.admin) && (
        <>
          <ListItemText
            primary={t('general:admin')}
            className={classes.SubMenu}
          />

          {can(RESOURCES.ecubi_dashboard) && (
            <MenuItem
              link="/global-dashboard"
              tittle={t('general:ecubi_dashboard')}
              section="settings"
              shadow={false}
            />
          )}

          {can(RESOURCES.ecubi_orders) && (
            <MenuItem
              link="/orders"
              tittle={t('general:orders:orders')}
              // section="orders"
              shadow={false}
            />
          )}
        </>
      )}
    </List>
  );
};

export default NavBar;
