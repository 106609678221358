import { types } from './types';
import AuthorizedApiClient from '../../core/common/api/AuthorizedApiClient';
import fileDownloader from '../../helpers/file-downloader';

const sg = new AuthorizedApiClient();

const EXCEL_FILE_NAME = 'ventas.xlsx';

export const resetSales = () => ({
  type: types.RESET_SALES,
});

export const clearRefresh = () => ({
  type: types.CLEAR_SALES_REFRESH,
});

export const setParams = params => ({
  type: types.SET_SALES_PARAMS,
  params,
});

const getPath = (companyId: string) => `companies/${companyId}/sales`;

export const getSales = (companyId, params = {}) => async (
  dispatch: any,
) => {
  const path = getPath(companyId);
  dispatch({ type: types.SET_SALES_PROCESSING, payload: true });
  try {
    const response = await sg.get(params, path);

    if (response != null) {
      dispatch({ type: types.SET_SALES_SUCCESS, response: response.data });
    }
  } catch (error) {
    dispatch({ type: types.SET_SALES_FAILURE, error });
  }
};

export const downloadSales = (companyId, params = {}) => async (
  dispatch: any,
) => {
  const path = getPath(companyId);

  try {
    const fileName = EXCEL_FILE_NAME;
    const response = await sg.downloadFile(params, path);

    fileDownloader.downloadFileInBrowser(response, fileName);
  } catch (error) {
    dispatch({ type: types.SET_SALES_FAILURE, error });
  }
};
