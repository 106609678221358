/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable no-restricted-syntax */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Link, Typography } from '@mui/material';
import Alert from '@mui/material/Alert';
import { useDispatch, useSelector } from 'react-redux';
import Box from '../../../../../components/ui/Layout/Box';
import { EXCEl_LIMIT_ROWS } from '../../../../../constants/common';
import { useExcelReader } from '../../../../../util/excel';
import { cleanKey, rules as validationRules } from '../../../../../util/Validations';
import ExcelUploader from '../../../../../components/ui/ExcelUploader';
import { generateRamdomNumber } from '../../../../../util/keys';
import {
  getUnitsForProductExcel,
  setError,
  setFile,
  setInvalidRows,
  setReadingExcel,
  setValidRows,
} from '../../../../../actions/products_excel';
import { useSession } from '../../../../../util/session';
import ActivityIndicator from '../../../../../components/ui/ActivityIndicator';
import DownoloadButton from '../../../../../components/ui/Buttons/DownoloadButton';

const isBusinessValidRow = async (object, schema) => {
  try {
    await schema.validate(object);
    return true;
  } catch (e) {
    return e.errors;
  }
};

export default () => {
  const { t } = useTranslation(['general']);
  const dispatch = useDispatch();
  const { readExcel } = useExcelReader();
  const { units } = useSelector((state: any) => state.products_excel);
  let unitsCodes: Array<string> = [];

  if (units !== null) {
    unitsCodes = units.map(u => cleanKey(u.code));
  }

  const { company } = useSession();

  useEffect(() => {
    if (company) {
      const params = {};
      params['per_page'] = 100;
      dispatch(getUnitsForProductExcel(company.id, params));
    }
  }, [dispatch, company]);

  const rowBuilder = row => ({
    name: row[0] ? row[0].toString() : '',
    description: row[1] ? row[1].toString() : '',
    barcode: row[2] ? row[2].toString() : '',
    cost: row[3],
    price: row[4],
    initial_stock: row[5] ? row[5] : 0,
    unit_name: row[6] ? row[6].toString() : units[0].code,
    published: !!(row[7] && row[7].toString() === '1'),
  });

  const schema = Yup.object({
    name: Yup.string()
      .max(
        validationRules.maxLength,
        t('general:products_excel:max_name', {
          limit: validationRules.maxLength,
        }),
      )
      .required(
        t('general:products_excel:required_name', {
          input: t('products:product_name'),
        }),
      ),

    barcode: Yup.string().max(
      validationRules.maxLength,
      t('general:products_excel:max_barcode', {
        limit: validationRules.maxLength,
      }),
    ),
    description: Yup.string().max(
      validationRules.maxLength,
      t('general:products_excel:max_description', {
        limit: validationRules.maxLength,
      }),
    ),
    cost: Yup.number()
      .nullable()
      .min(0, t('general:products_excel:cost_error'))
      .typeError(t('general:products_excel:cost_error')),
    price: Yup.number()
      .nullable()
      .min(0, t('general:products_excel:cost_error'))
      .typeError(t('general:products_excel:price_error')),
    initial_stock: Yup.number()
      .nullable()
      .min(0, t('general:products_excel:cost_error'))
      .typeError(t('general:products_excel:initial_stock_error')),
    unit_name: Yup.string()
      .nullable()
      .transform(cleanKey)
      .oneOf(unitsCodes, t('general:products_excel:error_unit')),
    published: Yup.boolean()
      .nullable()
      .typeError(t('general:products_excel:published_error')),
  });

  const fileHandler = async (event) => {
    const fileObj = event.target.files[0];
    dispatch(setReadingExcel(true));
    dispatch(setFile(fileObj));
    try {
      const result = await readExcel(fileObj);

      if (Array.isArray(result)) {
        if (result.length > EXCEl_LIMIT_ROWS) {
          dispatch(
            setError(
              t('general:products_excel:error_limit_reached', {
                limit: EXCEl_LIMIT_ROWS,
              }),
            ),
          );
          dispatch(setReadingExcel(false));
          return;
        }
        const valid = [];
        const invalid = [];
        for await (const row of result) {
          const object = rowBuilder(row);
          const validationResult = await isBusinessValidRow(object, schema);
          if (validationResult === true) {
            valid.push(object);
          } else {
            object['error'] = validationResult.join(', ');
            invalid.push(object);
          }
        }

        dispatch(setValidRows(valid));
        dispatch(setInvalidRows(invalid));
        dispatch(setReadingExcel(false));
      }
    } catch (e) {
      dispatch(setReadingExcel(false));
      dispatch(setError(e));
    }
  };
  return (
    <Box>
      {units === null ? (
        <ActivityIndicator />
      ) : units.length === 0 ? (
        <Alert severity="error">
          {t('general:products_excel:msg_no_units', {
            limit: EXCEl_LIMIT_ROWS,
          })}
        </Alert>
      ) : (
        <>
          <Box>
            <Box width={1}>
              <Typography>
                {t('general:products_excel:msg_select_file')}
                :
              </Typography>
            </Box>
            <Box display="flex" justifyContent="center" width={1}>
              <ExcelUploader fileHandler={fileHandler} />
            </Box>
          </Box>

          <Box mt={4}>
            <Box width={1}>
              <Typography>
                {t('general:products_excel:msg_download_template')}
                :
              </Typography>
            </Box>
            <Box display="flex" justifyContent="center" width={1}>
              <Link
                href={
                  `${process.env.PUBLIC_URL
                  }/media/excel/ec-products-template.xlsx?v?=${generateRamdomNumber()}`
                }
                underline="hover"
              >
                <DownoloadButton
                  component="span"
                  text={t('general:products_excel:download_template')}
                />
              </Link>
            </Box>
          </Box>

          <Box justifyContent="center" mt={5}>
            <Alert severity="warning">
              {t('general:products_excel:msg_limit_excels', {
                limit: EXCEl_LIMIT_ROWS,
              })}
            </Alert>
          </Box>
        </>
      )}
    </Box>
  );
};
