/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { makeStyles } from '@mui/styles';
import Icon from '../../Icon';

const styles = makeStyles(() => ({

  topIconContainer: {
    position: 'absolute',
    right: '-10%',
    top: '-10%',
    width: '20%',
    height: '20%',

    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default (props) => {
  const classes = styles();
  return (
    <div className={`${classes.topIconContainer}`}>
      <Icon section={props.section} />
    </div>
  );
};
