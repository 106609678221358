import Axios from 'axios';
import AuthorizedApiClient from '../core/common/api/AuthorizedApiClient';
import filesHelper from '../helpers/files';

interface SingningResponse {
  url: string,
  key: string,
  http_method: 'PUT' | 'POST';
}
const sg = new AuthorizedApiClient();

const getSigninUploadData = file => ({
  name: filesHelper.getName(file),
  mime_type: filesHelper.getType(file),
  size: filesHelper.getSize(file),
});

const signUpload = async (file, url: string): Promise<SingningResponse> => {
  const data = getSigninUploadData(file);
  const response = await sg.post(data, url);

  return {
    url: response.data.url,
    key: response.data.key,
    http_method: response.data.http_method,
  } as SingningResponse;
};

const uploadFileToProvider = async (file: File, data: SingningResponse) => {
  const simpleAxios = Axios.create();

  return simpleAxios({
    method: data.http_method,
    url: data.url,
    data: file,
  });
};

export const uploadTmpImage = async (file: File, url: string): Promise<string> => {
  const signResponse = await signUpload(file, url);

  await uploadFileToProvider(file, signResponse);

  return signResponse.key;
};
