/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable react/prop-types */
import {
  CircularProgress,
  InputAdornment,
  TextField,
} from '@mui/material';
import Autocomplete, {
  createFilterOptions,
} from '@mui/material/Autocomplete';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, withStyles } from '@mui/styles';
import Box from '../Layout/Box';
import { AutoCompleteOption } from './AutoComplete';

const styles = makeStyles(theme => ({
  circle: {
    width: 22,
    height: 22,
    padding: 5,
    margin: '3px 0px 3px 3px',
    borderRadius: '50%',
    backgroundColor: theme.colors.cardPurple.main,
    top: 'calc(25% - 4px)',
  },
  button: {
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'block',
    '&::after': {
      content: "''",
      position: 'absolute',
      backgroundColor: theme.colors.white,
      width: '100%',
      height: '4px',
      top: 'calc(50% - 2px)',
    },
    '&::before': {
      content: "''",
      backgroundColor: theme.colors.white,
      position: 'absolute',
      width: '100%',
      height: '4px',
      top: 'calc(50% - 2px)',
      transform: 'rotate(90deg)',
    },
  },
}));

const CTextField = withStyles(theme => ({
  root: {
    '& label.Mui-focused': {
      borderColor: theme.colors.cardPurple.main,
    },
    '& .MuiOutlinedInput-root': {
      padding: 0,
      backgroundColor: '#F0F5F8',
      borderRadius: 20,
      borderWidth: 1,
      fontSize: 14,
      '& fieldset': {
        borderColor: theme.colors.cardPurple.main,
      },
      '&:hover fieldset': {
        borderColor: theme.colors.cardPurple.main,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.colors.cardPurple.main,
        borderWidth: 1,
      },
    },
  },
}))(TextField);

export default ({
  handleOnChange,
  handleCreate,
  defaultValue = null,
  getData,
  options = [],
  loading = false,
  label = '',
  value = { value: '', label: '' },
  addStartLabel = 'Crear',
}) => {
  const [filter, setFilter] = useState('');
  const [open, setOpen] = React.useState(false);
  const filtered = createFilterOptions<AutoCompleteOption>();
  const { t } = useTranslation(['general']);
  React.useEffect(() => {
    if (open) {
      const params = {};
      if (filter) {
        params['filter'] = filter;
      }
      getData(params);
    }
  }, [getData, filter, open]);

  const classes = styles();

  const _handleOnChange = (event, value) => {
    if (value && value.value === '-1') {
      const cleanValue = value.label
        .replace(`${addStartLabel} `, '')
        .replace('"', '')
        .replace('"', '')
        .replace(t('general:new'), '');
      handleCreate(cleanValue);
      setFilter('');
    } else {
      handleOnChange(value);
    }
  };

  return (
    <Autocomplete
      onChange={_handleOnChange}
      inputValue={filter !== '-1' ? filter : null}
      onInputChange={(event, newInputValue) => {
        setFilter(newInputValue);
      }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) => option.label === value.label}
      getOptionLabel={option => option.label}
      options={options}
      loading={loading}
      forcePopupIcon={false}
      fullWidth
      defaultValue={defaultValue}
      value={(value || null) as any}
      renderInput={params => (
        <CTextField
          {...params}
          variant="outlined"
          fullWidth
          placeholder={label}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <Box className={classes.circle}>
                  <Box component="span" className={classes.button} />
                </Box>
              </InputAdornment>
            ),
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      filterOptions={(options, params) => {
        const _filtered = filtered(options, params);

        // Suggest the creation of a new value
        // if (params.inputValue !== "") {
        _filtered.push({
          value: '-1',
          label: `${addStartLabel} ${
            params.inputValue ? `"${params.inputValue}"` : t('general:new')
          }`,
        });
        // }

        return _filtered;
      }}
    />
  );
};
