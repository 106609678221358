/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect } from 'react';

import { makeStyles } from '@mui/styles';

import { useSelector, useDispatch } from 'react-redux';
import AppBar from '../components/AppBar';
import MainMenu from '../components/Drawer';
import PerfileMenu from '../components/PerfilDrawer';
import { setOpen, setOpenPerfile } from '../../../actions/theme';
import { useSession } from '../../../util/session';
import Box from '../../../components/ui/Layout/Box';
import Toaster from '../../../components/ui/Toaster';
// import Modal from "../../../components/ui/Modals/Modal";
// import Wizard from "../../../components/Wizard";

const drawerWidth = 300;
const minWidth = 960;
const styles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  toolbar2: theme.mixins.toolbar,
  content: {
    width: '100%',
    padding: '0, 35, 35, 35',
    transitionProperty: 'marginLeft, width',
    display: 'flex',
    flexDirection: 'column',
  },
  layout: {
    width: '100%',
    padding: '0, 35, 35, 35',
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    display: 'flex',
    flexDirection: 'column',
  },
}));

export default (props) => {
  const classes = styles();
  // eslint-disable-next-line react/prop-types
  const { children } = props;
  const dispatch = useDispatch();

  const { open, perfile } = useSelector((state: any) => state.theme);
  const [sizeContent, setSizeContent] = React.useState(null);

  // const [showWizard, setShowWizard] = useState(false);
  const { company } = useSession();

  useEffect(() => {
    if (open === true) {
      setSizeContent(classes.layout);
    } else {
      setSizeContent(classes.content);
    }
  }, [open, classes]);

  function MenuToogle() {
    dispatch(setOpen(!open));

    if (window.innerWidth <= minWidth && perfile === true) {
      dispatch(setOpenPerfile(!perfile));
    }
  }
  function PerfileToogle() {
    dispatch(setOpenPerfile(!perfile));

    if (window.innerWidth <= minWidth && open === true) {
      dispatch(setOpen(!open));
    }
  }
  function closePerfile() {
    dispatch(setOpenPerfile(false));
    dispatch(setOpen(false));
  }

  return (
    <div className={classes.root}>
      {company && (
        <>
          <AppBar variant="dense" toogleClick={MenuToogle} perfileClik={PerfileToogle} />
          <MainMenu
            variant="persistent" // permanent default
            open={open}
          />
          <PerfileMenu
            variant="persistent" // permanent default
            open={perfile}
          />
          <div
            className={sizeContent || classes.content}
            onClick={closePerfile}
          >
            <div className={classes.toolbar2} />
            <Box width={1} style={{ flex: 1 }}>
              {children}
              <Toaster />
            </Box>
          </div>
        </>
      )}
    </div>
  );
};
