import { useCallback, useReducer } from 'react';
import AuthorizedApiClient from '../../../../../../core/common/api/AuthorizedApiClient';
import useCompany from '../../../../../../hooks/useCompany';
import { DEFAULT_END_DATE, DEFAULT_START_DATE } from '../../constants';

import {
  SET_PRODUCTS_LIST_ERROR,
  SET_PRODUCTS_LIST_PROCESSING,
  SET_PRODUCTS_LIST_SUCCESS,
  SET_PRODUCTS_LIST_PARAMS,
  SET_PRODUCTS_RESET_FILTERS,
} from '../constants';
import productsListReducer from '../reducers/products';

const initialState = {
  processing: false,
  response: {
    data: [],
    total: 0,
    per_page: 10,
    last_page: 1,
    page: 1,
  },
  params: {
    page: 1,
    per_page: 10,
    sort: { created_at: 'desc' },

    filters: {
      updated_at: {
        $gte: DEFAULT_START_DATE,
        $lte: DEFAULT_END_DATE,
      },
    },
  },
};

const useSalesListState = () => {
  const { company } = useCompany();
  const [state, dispatch] = useReducer(productsListReducer, initialState);

  const setProcessing = useCallback((processing) => {
    dispatch({ type: SET_PRODUCTS_LIST_PROCESSING, payload: processing });
  }, [dispatch]);

  const setSalesFailure = useCallback((error) => {
    dispatch({ type: SET_PRODUCTS_LIST_ERROR, payload: error });
  }, [dispatch]);

  const setSalesResponse = useCallback((response) => {
    dispatch({ type: SET_PRODUCTS_LIST_SUCCESS, payload: response });
  }, [dispatch]);

  const getProducts = useCallback(
    async (params = {}) => {
      // const sg = ApiBestSellerGateway.getInstance();
      const path = `companies/${company.id}/sales/reports/bestseller`;
      const sg = new AuthorizedApiClient();

      setProcessing(true);
      try {
        const response = await sg.get(params, path);
        // const response = await sg.get(params);

        if (response != null) {
          setSalesResponse(response.data);
        }
      } catch (error) {
        setSalesFailure(error);
      }
    },
    [setProcessing, setSalesResponse, setSalesFailure],
  );

  const refreshList = () => {
    getProducts(initialState.params);
  };

  const setParams = (params) => {
    dispatch({ type: SET_PRODUCTS_LIST_PARAMS, payload: params });
  };

  const resetFilters = () => {
    dispatch({
      type: SET_PRODUCTS_RESET_FILTERS,
      payload: initialState.params.filters,
    });
  };

  return {
    state,
    getProducts,
    refreshList,
    setParams,
    resetFilters,
  };
};

export default useSalesListState;
