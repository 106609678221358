import { types } from '../../actions/movement/types';

export const initialState = {
  error: null,
  showModal: false,
  processing: false,
  loadingProducts: false,
  loadingLocations: false,
  products: [],
  locations: [],
  product: null,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case types.CLOSE_MOVEMENT_MODAL:
      return initialState;

    case types.SHOW_MOVEMENT_MODAL:
      return {
        ...state,
        showModal: true,
      };
    case types.SAVE_MOVEMENT_SUCCESS:
      return {
        ...state,
        response: action.response,
        error: null,
        processing: false,
      };
    case types.SAVE_MOVEMENT_FAILURE:
      return {
        ...state,
        error: action.error,
        processing: false,
      };
    case types.SAVING_MOVEMENT:
      return {
        ...state,
        error: null,
        processing: true,
      };

    case types.SET_PRODUCTS_MOVEMENT_PROCESSING:
      return {
        ...state,
        loadingProducts: action.payload,
      };

    case types.SET_PRODUCTS_MOVEMENT_SUCCESS:
      return {
        ...state,
        loadingProducts: false,
        products: action.response.data,
      };
    case types.SET_PRODUCTS_MOVEMENT_FAILURE:
      return {
        ...state,
        loadingProducts: false,
        error: action.error,
      };

    case types.SET_LOCATIONS_MOVEMENT_PROCESSING:
      return {
        ...state,
        loadingLocations: action.payload,
      };

    case types.SET_LOCATIONS_MOVEMENT_SUCCESS:
      return {
        ...state,
        loadingLocations: false,
        locations: action.response.data,
      };
    case types.SET_LOCATIONS_MOVEMENT_FAILURE:
      return {
        ...state,
        loadingLocations: false,
        error: action.error,
      };

    case types.SET_PRODUCT_MOVEMENT:
      return {
        ...state,
        product: action.product,
      };

    default:
      return state;
  }
};
