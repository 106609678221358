/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable prefer-const */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-promise-executor-return */
import { useState } from 'react';
import AuthorizedApiClient from '../../../../../../../core/common/api/AuthorizedApiClient';
import Log from '../../../../../../../util/Log';
import { useSession } from '../../../../../../../util/session';
import { CampaignWizardStep, FIRST_STEP } from '../constants/campaign_wizard';

const date = new Date();
date.setHours(0, 0, 0);
const endDate = new Date();
endDate.setHours(23, 59, 59);
const initialState = {
  step: FIRST_STEP,
  stepToSave: null,
  processing: false,
  error: null,
  campaign: {
    id: null,
    name: '',
    description: '',
    start_date: date,
    end_date: endDate,
    delivery_date: '',
    goal_amount: '',
    min_percentage: '',
    min_amount: '',
    min_sale: '',
    products: [],
    zones: [],
    images: [],
    video: null,
  },
  refresh: false,
};

export const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

const useCampaginWizardState = () => {
  const [state, setState] = useState(initialState);
  const { company } = useSession();

  const setStep = (step) => {
    setState({ ...state, step });
  };

  const setStepToSave = (step) => {
    setState({ ...state, stepToSave: step });
  };

  const setBasicInfo = (data) => {
    setState({
      ...state,

      campaign: {
        ...state.campaign,
        name: data.name,
        description: data.description,
      },
      stepToSave: null,
    });
  };

  const setDates = (data) => {
    setState({
      ...state,

      campaign: {
        ...state.campaign,
        start_date: data.start_date,
        end_date: data.end_date,
        delivery_date: data.delivery_date,
      },
      stepToSave: null,
      step: CampaignWizardStep.GOALS,
    });
  };

  const setGoals = (data) => {
    setState({
      ...state,

      campaign: {
        ...state.campaign,
        min_amount: data.min_amount,
        min_percentage: data.min_percentage,
        goal_amount: data.goal_amount,
        min_sale: data.min_sale,
      },
      stepToSave: null,
      step: CampaignWizardStep.PRODUCTS,
    });
  };

  const setSelectedProducts = (products) => {
    setState({
      ...state,

      campaign: {
        ...state.campaign,
        products,
      },
      stepToSave: null,
      step: CampaignWizardStep.ZONES,
    });
  };

  const setSelectedZone = (zone) => {
    setState({
      ...state,

      campaign: {
        ...state.campaign,
        zones: [...state.campaign.zones, zone], // TODO: Add instead of replacing
      },
      stepToSave: null,
      // step: CampaignWizardStep.GALLERY,
    });
  };

  const removeZone = (zoneId) => {
    setState({
      ...state,

      campaign: {
        ...state.campaign,
        zones: state.campaign.zones.filter(z => z.id !== zoneId),
      },
      stepToSave: null,
    });
  };

  const setGallery = (video, images) => {
    setState({
      ...state,
      campaign: {
        ...state.campaign,
        video,
        images,
      },
      stepToSave: null,
      step: CampaignWizardStep.BASIC_INFO,
    });
  };

  const clearCampaignForm = () => {
    setState(initialState);
  };

  const setProcessing = (payload) => {
    setState({
      ...state,
      processing: payload,
    });
  };

  const saveCampaignSucceed = () => {
    setState({ ...state, refresh: true, processing: false });
  };

  const saveCampaignFailed = (error) => {
    setState({
      ...state, refresh: false, error, processing: false,
    });
  };

  const saveCampaign = async (data) => {
    setProcessing(true);
    const sg = new AuthorizedApiClient();
    let path = `companies/${company.id}/campaigns`;
    const _data = data;
    try {
      let response;
      let data;
      const { id } = _data;

      data = _data;

      if (id != null) {
        path = `${path}/${id}`;
        delete data.id;
        response = await sg.put(data, path);
      } else {
        delete data.id;
        response = await sg.post(data, path);
      }

      if (response != null) {
        Log.debug('saved campaign', response);
        saveCampaignSucceed();
      }
    } catch (error) {
      Log.error(error.response.data);
      saveCampaignFailed(error.response.data);
    }
  };

  return {
    setStep,
    setStepToSave,
    clearCampaignForm,
    setDates,
    setSelectedProducts,
    setBasicInfo,
    setGoals,
    saveCampaign,
    setSelectedZone,
    setGallery,
    removeZone,
    state,
  };
};

export default useCampaginWizardState;
