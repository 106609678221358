/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/prop-types */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  saveCustomer,
  closeModalCustomer,
  setCustomerTab,
  deleteCustomer,
} from '../../../../../actions/customer';

import CustomerFormView from './CustomerFormView';

export default ({ source = 1 }) => {
  const dispatch = useDispatch();
  const { company } = useSelector((state: any) => state.session);

  const {
    customer,
    error,
    processing,
    deletingCustomer,
    showModal,
    tab,
  } = useSelector((state: any) => state.customer);

  const _setTab = (value) => {
    dispatch(setCustomerTab(value));
  };

  const handleClose = () => {
    // setFormData({});
    dispatch(closeModalCustomer());
  };

  const attemptSaveCustomer = async (data) => {
    await dispatch(saveCustomer(data, company.id, source));
    handleClose();
  };

  const attemptDeleteCustomer = async (customer) => {
    await dispatch(deleteCustomer(customer, company.id));
    handleClose();
  };

  return (
    <CustomerFormView
      formData={customer}
      attemptSaveCustomer={data => attemptSaveCustomer(data)}
      attemptDeleteCustomer={customer => attemptDeleteCustomer(customer)}
      processing={processing}
      deletingCustomer={deletingCustomer}
      error={error}
      handleClose={handleClose}
      showModal={showModal}
      tab={tab}
      setTab={_setTab}
    />
  );
};
