import React from 'react';
import { useTranslation } from 'react-i18next';
import BreadCrumbs from '../../../../../components/ui/BreadCrumbs';
import ContentHeader from '../../../../../components/ui/ContentHeader';
import Box from '../../../../../components/ui/Layout/Box';
import Grid from '../../../../../components/ui/Layout/Grid';
import PageContainer from '../../../../../hoc/PageContainer';
import SectionListContainer from '../../../../../hoc/SectionListContainer';
import MovementsList from './MovementsList';

export default () => {
  const { t } = useTranslation(['general']);

  return (
    <Box>
      <Grid container>
        <Grid item xs={12}>
          <ContentHeader title={t('general:reports')} section="reports" back />
        </Grid>
        <PageContainer>
          <BreadCrumbs />

          <SectionListContainer>
            <MovementsList />
          </SectionListContainer>
        </PageContainer>
      </Grid>
    </Box>
  );
};
