/* eslint-disable react/prop-types */
import {
  Box, Typography,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';

const styles = makeStyles(theme => ({
  imgContainer: {
    height: 65,
    width: 65,
    margin: '0px 2px',
    display: 'inline-block',
  },
  Image: {
    backgroundColor: theme.colors.inventory.light,
    backgroundRepeat: 'no-repeat',
    height: '64%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  alertClose: {
    visibility: 'hidden',
    height: 0,
  },
  alertOpen: {
    color: 'red',
    fontSize: 11,
    lineHeight: '1rem',
    whiteSpace: 'normal',
    visibility: 'visible',
    height: 'auto',
  },
}));

export default ({ item }) => {
  const classes = styles();
  const { t } = useTranslation(['general']);

  return (
    <Box className={classes.imgContainer}>
      <div style={{ backgroundImage: `url(${item.variants[0].image_url})` }} className={classes.Image} />
      <Typography align="center" className={item.stock_warning ? classes.alertOpen : classes.alertClose}>
        {t('general:point_of_sell:product_limit')}
      </Typography>
      <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
        x
        {item.quantity}
      </div>
    </Box>
  );
};
