import { types } from '../../actions/session/types';

export const initialState = {
  user: {},
  loggedIn: process.env.REACT_APP_DISABLE_AUTH === 'true',
  token: {
    access_token: null,
    refresh_token: null,
    expires_in: null,
  },
  planning: null,
  company: null,
  companyId: null,
  roles: [],
  permissions: {},
};
export default (state = initialState, action: any) => {
  switch (action.type) {
    case types.SET_SESSION:
      return {
        ...state,
        loggedIn: true,
        user: action.data.user,
        planning: action.data.planning,
        token: action.data.token,
        companyId: action.data.user
          ? action.data.user.companies[0].id
          : state.companyId, // TODO: check it.
        company: action.data.user
          ? action.data.user.companies[0]
          : state.company, // TODO: check it.
        permissions: action.data.permissions,
        roles: action.data.roles,
      };

    case types.SET_USER_SESSION:
      return {
        ...state,

        user: action.user,

      };

    case types.CLEAR_SESSION:
      return initialState;

    case types.SAVE_STORE_PROFILE_SUCCESS:
      return {
        ...state,
        company: action.response.data.company,
      };
    case types.DISABLE_USER_STATUS:
      return {
        ...state,
        user: {
          ...state.user,
          status: 'disabled',
        },
      };
    case types.ENABLE_USER_STATUS:
      return {
        ...state,
        user: {
          ...state.user,
          status: 'active',
        },
      };
    default:
      return state;
  }
};
