import React from 'react';

import { makeStyles } from '@mui/styles';
import Icon from '../../../../components/ui/Icon';

const useStyles = makeStyles(() => ({
  IconBackground: {
    width: 32,
    height: 32,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const MenuIcon = (props) => {
  const classes = useStyles();
  return (
    <div className={`${classes.IconBackground}`}>
      <Icon {...props} />
    </div>
  );
};

export default MenuIcon;
