/* eslint-disable react/prop-types */
import React from 'react';
import { useDispatch } from 'react-redux';
import { editBranch } from '../../../../../actions/branch';
import Grid from '../../../../../components/ui/Layout/Grid';
import PaginatedScroll from '../../../../../hoc/PaginatedScroll';
import BranchCard from '../BranchCard';

export default ({ state, getBranches, resetBranches }) => {
  const dispatch = useDispatch();
  const showDetails = (branch) => {
    dispatch(editBranch(branch));
  };

  const list = state.data.map(branch => (
    <Grid item xs={12} sm={6} key={branch.id} onClick={() => showDetails(branch)}>
      <BranchCard branch={branch} />
    </Grid>

  ));

  return (
    <PaginatedScroll
      hasNextPage={state.response.page < state.response.last_page}
      loadData={params => getBranches(params)}
      currentPage={state.response.page}
      resetData={resetBranches}
      processing={state.processing}
    >
      <Grid container spacing={3}>
        {list}
      </Grid>
    </PaginatedScroll>
  );
};
