import axios from 'axios';
import ApiClient from './ApiClient';

interface Props { apiVerison?: 'v1' | 'v1.1' }

export default class AuthorizedApiClient extends ApiClient {
  protected path: string | null;

  getPath(companyId: string | null = null): string {
    return companyId ? `companies/${companyId}/${this.path}` : this.path;
  }

  private static token: string = '';

  constructor(props: Props = { apiVerison: 'v1' }) {
    super();
    const version = props.apiVerison;
    this.setUrl(`${this.getBaseUrl()}/api/${version}`);
  }

  /*
    override
    */
  public interceptor() {
    axios.interceptors.request.use((config) => {
      // console.log("el api token", AuthorizedApiClient.token)
      config.headers.common.Accept = 'application/json';
      if (AuthorizedApiClient.token) {
        config.headers.common.Authorization = `Bearer ${AuthorizedApiClient.token}`;
      }

      return config;
    });
  }

  static setToken(token: string) {
    AuthorizedApiClient.token = token;
  }
}
