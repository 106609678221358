/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/prop-types */
import React, { useCallback, useEffect } from 'react';
import ProductsListView from './ProductsListView';
import { useSession } from '../../util/session';
import useStore from './store';
import { ListType } from './types';
import { ProductType } from '../../core/contracts/data/ProductType';

const ProductsListSelector = ({ handleSelected, listType }) => {
  const {
    state,
    getProducts,
    clearRefresh,
    resetProducts,
    addSelected,
    removeSelected,
    handleAddNewClick,
    closeModalModifier,
  } = useStore();

  const { refresh, selected } = state;
  const { company } = useSession();

  const _getProducts = useCallback(
    (_params: any = {}) => {
      if (listType === ListType.MODIFIERS) {
        if (!_params.filters) {
          _params.filters = {};
        }
        _params.filters.type = {
          $in: [ProductType.REGULAR, ProductType.MODIFIER],
        };

        _params.sort = { created_at: 'desc' };
      }
      getProducts(_params);
    },
    [listType, getProducts],
  );

  useEffect(() => {
    handleSelected(selected);
  }, [selected, handleSelected]);

  useEffect(() => {
    if (refresh && company) {
      resetProducts();
      clearRefresh();
      _getProducts();
    }
  }, [refresh, company, _getProducts, resetProducts, clearRefresh]);

  const onSavedModifier = useCallback(
    () => {
      closeModalModifier();
      _getProducts();
    },
    [_getProducts, closeModalModifier],
  );

  const onCloseModifierForm = useCallback(() => {
    closeModalModifier();
  }, [closeModalModifier]);

  return (
    <ProductsListView
      state={state}
      getProducts={params => _getProducts(params)}
      resetProducts={resetProducts || refresh}
      listType={listType}
      addSelected={addSelected}
      removeSelected={removeSelected}
      handleAddNewClick={handleAddNewClick}
      onSavedModifier={onSavedModifier}
      onCloseModifierForm={onCloseModifierForm}
    />
  );
};

export default ProductsListSelector;
