export const types = {
  EDIT_USER: 'EDIT_USER',
  CLOSE_USER_MODAL: 'CLOSE_USER_MODAL',
  SAVING_USER: 'SAVING_USER',
  SAVE_USER_SUCCESS: 'SAVE_USER_SUCCESS',
  SAVE_USER_FAILURE: 'SAVE_USER_FAILURE',
  // DELETING_USER: "DELETING_USER",
  // DELETE_USER_SUCCESS: "DELETE_USER_SUCCESS",
  // DELETE_USER_FAILURE: "DELETE_USER_FAILURE",
  SET_USER_SUCCESS: 'SET_USER_SUCCESS',
  SET_USER_FAILURE: 'SET_USER_FAILURE',
};
