import { types } from '../../actions/unitofmeasure/types';

export const initialState = {
  unitOfMeasure: { id: null, name: '', description: '' },
  error: null,
  showModal: false,
  processing: false,
  deletingUnitOfMeasure: false,
  refresh: false,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case types.EDIT_UNITOFMEASURE:
      return {
        ...state,
        showModal: true,
        unitOfMeasure:
          action.payload !== null ? action.payload : initialState.unitOfMeasure,
      };
    case types.CLOSE_UNIT_MODAL:
      return initialState;
    case types.SAVE_UNITOFMEASURE_SUCCESS:
      return {
        ...state,
        response: action.response,
        error: null,
        processing: false,
        refresh: true,
      };
    case types.SAVE_UNITOFMEASURE_FAILURE:
      return {
        ...state,
        error: action.error,
        processing: false,
      };
    case types.SAVING_UNITOFMEASURE:
      return {
        ...state,
        error: null,
        processing: true,
      };

    case types.DELETING_UNITOFMEASURE:
      return {
        ...state,
        error: null,
        deletingUnitOfMeasure: true,
      };

    case types.DELETE_UNITOFMEASURE_SUCCESS:
      return {
        ...state,
        error: null,
        deletingUnitOfMeasure: false,
        refresh: true,
      };
    case types.DELETE_UNITOFMEASURE_FAILURE:
      return {
        ...state,
        error: action.error,
        deletingUnitOfMeasure: false,
      };
    case types.CLEAR_REFRESH:
      return {
        ...state,
        refresh: false,
      };
    default:
      return state;
  }
};
