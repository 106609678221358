import React from 'react';
import Box from '../../components/ui/Layout/Box';
import Grid from '../../components/ui/Layout/Grid';

type PageContainerProps = {
  children: any;
};

export default (props: PageContainerProps) => {
  const { children } = props;

  return (
    <Box mt={3} p={0} pb={12} mx="auto" width="90%" maxWidth={800}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          {children}
        </Grid>
      </Grid>
    </Box>
  );
};
