import React from 'react';
import { makeStyles } from '@mui/styles';
import Icon from '../../Icon';

const styles = makeStyles(() => ({

  topIconContainer: {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default (props) => {
  const classes = styles();
  return (
    <div className={classes.topIconContainer}>
      <Icon {...props} />
    </div>

  );
};
