/* eslint-disable react/prop-types */
import {
  Box, Button, CircularProgress, Divider, Grid, Typography,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import useRequest from '../../../../hooks/useRequest';

const useStyles = makeStyles(theme => ({
  container: {
    borderRadius: 5,
    border: `2px solid ${theme.colors.grey}`,
    padding: '.5em 1em',
    minWidth: '17.5rem',
    backgroundColor: 'white',
    maxWidth: '100%',
    boxSizing: 'border-box',
  },
  card: {
    maxWidth: '100%',
    height: '100%',
    display: 'block',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  cardContain: {
    justifySelf: 'center',
    width: '5.5em',
    height: '3em',
    border: '1px solid grey',
    borderRadius: 10,
    padding: '.5em',
    margin: '.7em .5em',
  },
  visa: {
    backgroundImage: `url(${theme.icons.visa})`,
  },
  mastercard: {
    backgroundImage: `url(${theme.icons.masterCard})`,
  },
  americanexpress: {
    backgroundImage: `url(${theme.icons.americanExpress})`,
  },
}));
const PaymentMethodItem = ({
  pm, getPaymentMethods, setModalUpCard, setDataUpCard,
}) => {
  const classes = useStyles();
  const { t } = useTranslation(['general']);
  const { destroy, processing } = useRequest({});
  // console.log(response)

  const handleDelete = async () => {
    await destroy(`/user/payment_methods/${pm.id}`);
    await getPaymentMethods();
  };

  const handleUpdate = async () => {
    await setDataUpCard(pm);
    await setModalUpCard(true);
  };

  return (
    <Box className={classes.container}>
      {/* <Box>{pm.data?.name}</Box> */}
      <Box display="grid" gridTemplateColumns="4fr 3fr" alignItems="center">
        <Grid className={classes.cardContain}>
          <span className={`${classes.card} ${classes[pm.data?.brand.replace('_', '').toLowerCase()]}`} />
        </Grid>
        <Box justifySelf="start">
          <Typography variant="body1">
            {pm.data?.brand.replace('_', ' ').toUpperCase()}
            {' '}
            &#8226;&#8226;&#8226;
            {pm.data.last4}
          </Typography>
          <Typography variant="caption">
            { t('general:expire_in')}
            {' '}
            {`${pm.data.exp_month}/${pm.data.exp_year}`}
          </Typography>
        </Box>
      </Box>

      <Divider />
      <Box display="flex" justifyContent="space-between">
        <Box>
          <Button onClick={() => {}} color="primary">
            {processing ? <CircularProgress /> : 'Volver primaria'}
          </Button>
        </Box>
        <Box>
          <Button onClick={handleDelete} style={{ color: 'red' }}>
            {processing ? <CircularProgress /> : 'Eliminar'}
          </Button>
          <Button onClick={handleUpdate} color="primary">
            { t('general:edit') }
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default PaymentMethodItem;
