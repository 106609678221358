/* eslint-disable prefer-const */
import { types } from './types';
import Log from '../../util/Log';
import AuthorizedApiClient from '../../core/common/api/AuthorizedApiClient';

export const editCustomer = (customer = null) => ({
  type: types.EDIT_CUSTOMER,
  payload: customer,
});

export const savingCustomer = () => ({
  type: types.SAVING_CUSTOMER,
});

export const saveCustomerSucceed = (response, source) => ({
  type: types.SAVE_CUSTOMER_SUCCESS,
  response,
  refresh: source === 1,
});

export const saveCustomerFailed = error => ({
  type: types.SAVE_CUSTOMER_FAILURE,
  error,
});

export const closeModalCustomer = () => ({
  type: types.CLOSE_CUSTOMER_MODAL,
});

export const deletingCustomer = () => ({
  type: types.DELETING_CUSTOMER,
});

export const deleteCustomerSucceed = response => ({
  type: types.DELETE_CUSTOMER_SUCCESS,
  response,
});

export const deleteCustomerFailed = error => ({
  type: types.DELETE_CUSTOMER_FAILURE,
  error,
});

export const setCustomerTab = tab => ({
  type: types.SET_CUSTOMER_TAB,
  tab,
});
export const getCustomer = (id, companyId) => async (
  dispatch: any,
) => {
  const sg = new AuthorizedApiClient();
  const path = `companies/${companyId}/customers/${id}`;

  try {
    const response = await sg.get({}, path);

    if (response != null) {
      dispatch({ type: types.SET_CUSTOMER_SUCCESS, customer: response.data.customer });
    }
  } catch (error) {
    dispatch({ type: types.SET_CUSTOMER_FAILURE, erro: error });
  }
};

export const deleteCustomer = (customer, companyId) => async (dispatch: any) => {
  dispatch(deletingCustomer());
  const sg = new AuthorizedApiClient();
  let path = `companies/${companyId}/customers`;
  try {
    if (!customer.id) {
      throw new Error('The id is required');
    }

    path = `${path}/${customer.id}`;
    const response = await sg.delete(path);

    if (response != null) {
      Log.debug('deleted customer', response);
      dispatch(deleteCustomerSucceed(response));
      dispatch(closeModalCustomer());
    }
  } catch (error) {
    Log.error(error.message);
    dispatch(deleteCustomerFailed(error.message));
  }
};

export const saveCustomer = (_data, companyId, source) => async (dispatch: any) => {
  dispatch(savingCustomer());
  const sg = new AuthorizedApiClient();
  let path = `companies/${companyId}/customers`;

  try {
    let response;
    let data;
    const { id } = _data;
    data = _data;

    if (id != null) {
      path = `${path}/${id}`;
      delete data.id;
      response = await sg.put(data, path);
    } else {
      delete data.id;
      response = await sg.post(data, path);
    }

    if (response != null) {
      Log.debug('saved customer', response);
      dispatch(saveCustomerSucceed(response, source));
      dispatch(closeModalCustomer());
    }
  } catch (error) {
    Log.error(error.message);
    dispatch(saveCustomerFailed(error.message));
  }
};
