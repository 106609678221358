import { types } from '../../actions/branch/types';

export const initialState = {
  branch: {
    id: null, name: '', address: '', phone: '', email: '', image: '',
  },
  error: null,
  showModal: false,
  processing: false,
  deletingBranch: false,
  refresh: false,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case types.EDIT_BRANCH:
      return {
        ...state,
        showModal: true,
        branch: action.payload !== null ? action.payload : initialState.branch,
      };
    case types.CLOSE_BRANCH_MODAL:
      return initialState;
    case types.SAVE_BRANCH_SUCCESS:
      return {
        ...state,
        response: action.response,
        error: null,
        processing: false,
        refresh: true,
      };
    case types.SAVE_BRANCH_FAILURE:
      return {
        ...state,
        error: action.error,
        processing: false,
      };
    case types.SAVING_BRANCH:
      return {
        ...state,
        error: null,
        processing: true,
      };

    case types.DELETING_BRANCH:
      return {
        ...state,
        error: null,
        deletingBranch: true,

      };

    case types.DELETE_BRANCH_SUCCESS:
      return {
        ...state,
        error: null,
        deletingBranch: false,
        refresh: true,
      };
    case types.DELETE_BRANCH_FAILURE:
      return {
        ...state,
        error: action.error,
        deletingBranch: false,
      };

    case types.CLEAR_REFRESH:
      return {
        ...state,
        refresh: false,
      };

    default:
      return state;
  }
};
