/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import DangerButton from '../../Buttons/DangerButton';
import SuccessButton from '../../Buttons/SuccessButton';
import Grid from '../../Layout/Grid';

interface ActionsProps {
  handleClose: Function;
  handleSubmit: Function;
  handleDelete: Function;
  processing: boolean;
  deleting: boolean;
  isNew: boolean;
}

export default ({

  handleSubmit,
  processing,
  handleDelete,
  deleting,
  isNew,
}: ActionsProps) => {
  const { t } = useTranslation();
  return (
    <Grid container>
      <Grid container item xs={6} justifyContent="flex-start">
        {!isNew && (
          <DangerButton
            text={t('general:delete')}
            processing={deleting ? 1 : 0}
            onClick={handleDelete}

          />
        )}
      </Grid>
      <Grid container item xs={6} justifyContent="flex-end">
        <SuccessButton
          processing={processing ? 1 : 0}
          onClick={handleSubmit}
          color="primary"
          text={t('general:save')}
        />
      </Grid>
    </Grid>
  );
};
