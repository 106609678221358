/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useContext } from 'react';
import Lottie from 'react-lottie';
import { makeStyles } from '@mui/styles';
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Grid,
  Button,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ShepherdTourContext } from 'react-shepherd';
import PerfileButton from '../PerfileButton';
import Icons from '../../assets/images';
import giftBox from '../../assets/Lotties/giftBox.json';
import { useSession } from '../../../../util/session';
import { openTrialModal } from '../../../../actions/notification';
// import { MobileNotification } from "../MobileNotification";

const useStyle = makeStyles(theme => ({
  menuButton: {
    marginRight: theme.spacing(0),
  },
  toolbarStyle: {},
  tittle: {

    flexGrow: 1,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  appBar: {
    zIndex: 200,
    boxShadow: theme.shadow.main,
  },
  containerLogoAppbar: {
    [theme.breakpoints.down('md')]: {
      flexGrow: 1,
      textAlign: 'center',
    },
  },
  logoAppbar: {
    height: 43,
    [theme.breakpoints.down('md')]: {
      height: 35,
    },
  },
  imageContainer: {
    marginTop: 5,
    margin: 5,
    marginBottom: 5,
    cursor: 'pointer',
  },
}));

const NavBar = (props) => {
  const dispatch = useDispatch();
  const classes = useStyle();
  const tour = useContext(ShepherdTourContext);
  const { t } = useTranslation(['general']);
  const { planning } = useSession();
  const plan = planning?.subscriptions[0]?.plan_id || '';

  const { user } = useSelector((state: any) => state.session);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    rendererSettings: {
      preserverAspectRatio: 'xMidYMid slice',
    },
  };

  const openPremiumTrial = () => {
    dispatch(openTrialModal());
  };

  const handleClickMenuButton = () => {
    props.toogleClick();
    const stepTour = tour.getCurrentStep();
    if (tour.isActive() && stepTour.id === 'intro-9') {
      const actualStepsFinished = JSON.parse(window.localStorage.getItem('finished-tour-steps'));

      window.localStorage.setItem(
        'finished-tour-steps',
        JSON.stringify({
          ...actualStepsFinished,
          dashboardDone: true,
        }),
      );
      tour.hide();
    }
  };

  return (
    <AppBar position="fixed" color="white" className={classes.appBar}>
      {/* <MobileNotification/> */}
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="menu"
          className={`${classes.menuButton} hamburger-menu-icon`}
          onClick={handleClickMenuButton}
          size="large"
        >
          <MenuIcon />
        </IconButton>
        <Grid className={classes.containerLogoAppbar}>
          <Link to="/">
            <img src={Icons.Logo} alt="Logo de ecubi" className={classes.logoAppbar} />
          </Link>
        </Grid>
        <Typography variant="h6" className={classes.tittle}>
          {t('general:my_business')}
        </Typography>
        { planning
          && planning.trial_redeemed === false
          && planning.offer_free_pro === false
          && plan !== '614a50847893331348600551'
          ? (
            <Button
              className={classes.imageContainer}
              style={{ width: 50, height: 50 }}
              onClick={openPremiumTrial}
            >
              <Lottie options={{ animationData: giftBox, ...defaultOptions }} />
            </Button>

          ) : null}

        {user ? (
          <PerfileButton user={user} click={() => props.perfileClik()} />
        ) : null}
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
