/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '../../../../components/ui/Layout/Grid';
import ContentHeader from '../../../../components/ui/ContentHeader';
import PageContainer from '../../../../hoc/PageContainer';
import ProductsSell from './ProductsSell';
import {
  clearCart,
} from '../../../../actions/cart';
import {
  setBranch,
} from '../../../../actions/pos';
import BranchSelector from '../../../../components/ui/BranchSelector';
import CustomerForm from '../CustomersPage/CustomerForm';

const PointSalePageView = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['general']);
  const cart = useSelector((state: any) => state.cart);
  const pos = useSelector((state: any) => state.pos);

  const company = useSelector((state: any) => state.session.company);

  useEffect(() => () => {
    if (cart.completed === true) {
      dispatch(clearCart());
    }
  }, [dispatch, cart]);

  const _setBranch = (value) => {
    dispatch(setBranch(value));
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <ContentHeader
          title={t('general:pointSell')}
          section="pointSell"
          icon
        />
      </Grid>

      <PageContainer>
        <BranchSelector
          // branches={branchesOptions}
          company_id={company ? company.id : null}
          branch_id={pos.branch_id}
          setBranch={value => _setBranch(value)}
        />

        <ProductsSell />
        <CustomerForm source={2} />
      </PageContainer>
    </Grid>
  );
};

export default PointSalePageView;
