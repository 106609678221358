import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField } from '@mui/material';

interface ControlledDateFieldProdps {
  errors: any;
  control: any;
  defaultValue: Date | null;
  name?: string;
  label?: string;
}
export default (props: ControlledDateFieldProdps) => {
  const {
    // errors,
    label,
    control,
    defaultValue,
    name,
  } = props;

  const [value, setValue] = useState<Date | null>(
    defaultValue || null,
  );
  const onChange = (date: Date | null) => {
    setValue(date);
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Controller
        render={() => (
          <DatePicker
            showToolbar={false}
            // variant="inline"
            inputFormat="yyyy/MM/dd"
            // autoOk
            value={value}
            onChange={onChange}
            // KeyboardButtonProps={{
            //   'aria-label': 'change date',
            // }}
            // inputVariant="outlined"
            renderInput={params => (
              <TextField {...params} label={label} sx={{ width: '100%' }} />
            )}
          />
        )}
        name={name}
        defaultValue={defaultValue}
        control={control}
      />
    </LocalizationProvider>
  );
};
