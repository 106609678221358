/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CategoriesListView from './CategoriesListView';
import {
  getCategories,
  resetCategories,
} from '../../../../../actions/categories';
import { SCROLL_PER_PAGE } from '../../../../../constants/common';

export default () => {
  const dispatch = useDispatch();
  const state = useSelector((states: any) => states.categories);
  const company = useSelector((states: any) => states.session.company);

  const _getCategories = useCallback(
    async (params: any = {}) => {
      if (company) {
        params.per_page = SCROLL_PER_PAGE;
        await dispatch(getCategories(company.id, params));
      }
    },
    [dispatch, company],
  );

  const _resetCategories = useCallback(() => {
    dispatch(resetCategories());
  }, [dispatch]);

  return (
    <CategoriesListView
      state={state}
      getCategories={params => _getCategories(params)}
      resetCategories={_resetCategories}
    />
  );
};
