const getName = (file: File): string => file.name;

const getExtension = (file: File): string => {
  const fileName = getName(file);

  const extension = fileName.split('.').pop();

  return extension;
};

const getType = (file: File): string => file.type;

const getSize = (file: File): number => file.size;

const filesHelper = {
  getExtension,
  getName,
  getType,
  getSize,
};

export default filesHelper;
