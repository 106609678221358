import React from 'react';
import { makeStyles } from '@mui/styles';
import { Typography, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Box from '../../../../../components/ui/Layout/Box';
import FormattedNumber from '../../../../../components/ui/FormattedNumber';

const styles = makeStyles(theme => ({
  RoundedContainer: {
    borderRadius: '6px',
    width: '80%',

    border: '2px solid',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontSize: '0.7rem',
  },
  Price: {
    fontSize: '0.7rem',
    textAlign: 'center',
  },
  adequateStock: {
    borderColor: theme.colors.trafficLight.adequate.main,
    backgroundColor: theme.colors.trafficLight.adequate.light,
  },
  stockAlert: {
    borderColor: theme.colors.trafficLight.alert.main,
    backgroundColor: theme.colors.trafficLight.alert.light,
  },
  lowStock: {
    borderColor: theme.colors.trafficLight.low.main,
    backgroundColor: theme.colors.trafficLight.low.light,
  },
  availability: {
    display: 'flex',
    height: '30px',
    flexDirection: 'column',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

interface StockProps {
  product: any;
}

export default ({ product }: StockProps) => {
  const { variants } = product;
  const classes = styles();
  const { t } = useTranslation(['products']);
  const variant = variants.length > 0 ? variants[0] : null;
  const stock = variant !== null ? variant.stock : 0;
  const uom = variant !== null && variant.unitofmeasure !== null
    ? variant.unitofmeasure.code
    : 'Pieza';
  const stockAlert = variant !== null ? variant.stock_alert : 0;
  const adequateStock = variant !== null ? variant.adequate_stock : 0;

  let trafficLigthClass = classes.lowStock;
  if (product.inventoried) {
    if (stock >= adequateStock) {
      trafficLigthClass = classes.adequateStock;
    } else if (stock >= stockAlert) {
      trafficLigthClass = classes.stockAlert;
    }
  } else if (product.available) {
    trafficLigthClass = classes.adequateStock;
  }

  return (
    <Grid container>
      <Grid container item xs={12} justifyContent="center">
        <Typography className={classes.title}>{t('products:stock')}</Typography>
      </Grid>

      <Grid container item xs={12} justifyContent="center">
        <Box
          p={0}
          m={0}
          className={`${classes.RoundedContainer} ${trafficLigthClass}`}
        >
          <Typography className={classes.Price}>
            {product.inventoried ? (
              <>
                <FormattedNumber value={stock} />
                <br />
                {uom}
              </>
            ) : (
              <span className={classes.availability}>
                {product.available ? t('products:available') : t('products:unavailable')}
                <br />

              </span>
            )}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};
