/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import React from 'react';
import { makeStyles } from '@mui/styles';
import { useDispatch } from 'react-redux';
import { editSupplier } from '../../../../../actions/supplier';
import { generateRamdomNumber } from '../../../../../util/keys';

const styles = makeStyles(theme => ({
  container: {
    width: '100%',
    height: 150,
    margin: '10px 0px',
    boxShadow: theme.shadow.main,
    borderRadius: 5,
    display: 'grid',
    gridTemplateColumns: '1fr 2fr',
    cursor: 'pointer',
  },
  img: {
    width: 91,
    height: 91,
    borderRadius: '50%',
    backgroundColor: '#F8F5F5',
    boxShadow: theme.shadow.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    justifySelf: 'center',
    alignSelf: 'center',
  },
  textImg: {
    fontSize: 30,
    color: theme.colors.black,
  },
  title: {
    fontSize: 22,
    color: theme.colors.black,
    fontWeight: 'bold',
    margin: '5px 0px',
  },
  textData: {
    fontSize: 15,
    color: theme.colors.black,
    overflow: 'hidden',
  },
  numbers: {
    display: 'flex',
  },
  overFlow: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  image: {
    backgroundColor: 'transparent',
    backgroundRepeat: 'no-repeat',
    width: '80%',
    height: '80%',
    backgroundSize: '100%',
    borderRadius: '50%',
  },
}));

export default ({ supplier }) => {
  const dispatch = useDispatch();
  const showDetails = (supplierSet) => {
    dispatch(editSupplier(supplierSet));
  };

  const classes = styles();
  return (
    <div className={classes.container} onClick={() => showDetails(supplier)}>
      <span className={classes.img}>
        <div
          style={{ backgroundImage: `url(${supplier.image_url}?v=${generateRamdomNumber()})` }}
          className={classes.image}
        />
      </span>
      <div className={classes.textData}>
        <p className={`${classes.title} ${classes.overFlow}`}>
          {supplier.alias}
        </p>
        <p className={`${classes.overFlow}`}>{supplier.name}</p>
        <p className={`${classes.overFlow}`}>{supplier.manager}</p>
        <div className={classes.numbers}>
          <p>{supplier.phone}</p>
          &nbsp;/&nbsp;
          <p>{supplier.cellphone}</p>
        </div>
        <p className={`${classes.overFlow}`}>{supplier.email}</p>
        <p className={`${classes.overFlow}`}>{supplier.address}</p>
      </div>
    </div>
  );
};
