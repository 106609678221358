import React from 'react';
import useKPIDashboardState from '../hooks/useKPIDashboardState';

interface IContextProps {
  state: any;
  getSales: Function;
  setParams: Function;
  resetFilters: Function;
}

export const KPIDashboardContext = React.createContext({} as IContextProps);

interface Props {
  children: React.ReactNode;
}

export const KPIDashboardProvider = ({ children }: Props) => {
  const initialState = useKPIDashboardState();
  return (
    <KPIDashboardContext.Provider value={initialState}>
      {children}
    </KPIDashboardContext.Provider>
  );
};

export const useKPIDashboardContext = () => {
  const context = React.useContext(KPIDashboardContext);
  if (context === undefined) {
    throw new Error(
      'KPIDashboardState must be used within a KPIDashboardProvider',
    );
  }
  return context;
};
