import { types } from '../../actions/productsCost/types';

export const initialState = {
  data: [],
  load: false,
  error: '',
};

export default (state = initialState, action: any) => {
  // console.log(state);
  switch (action.type) {
    case types.ADD_PRODUCT_VALUE: {
      const exists = state.data.find(product => product.id === action.data.id);
      if (exists) {
        return {

          ...state,
          data: state.data.map((product) => {
            if (product.id === action.data.id) {
              product.price = action.data.price;
              product.cost = action.data.cost;
              return product;
            }

            return product;
          }),

        };
      }
      return {
        ...state,
        data: [
          ...state.data,
          action.data,
        ],
      };
    }

    case types.SET_ALL_PRODUCTS:
      return {
        ...state,
      };

    case types.LOAD_MASIVE_PRODUCTS:
      return {
        ...state,
        load: true,
      };

    case types.LOAD_MASIVE_SUCCES:
      return {
        ...state,
        load: false,
      };

    case types.LOAD_MASIVE_FAILED:
      return {
        ...state,
        error: action.error,
      };

    case types.RESET_MASIVE_STATE:
      return initialState;

    default:
      return state;
  }
};
