/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  useCallback, useEffect, useState,
} from 'react';
import {
  Box, Button, CircularProgress, Hidden, Modal, Typography,
} from '@mui/material';
import Axios from 'axios';
import { useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { useSession } from '../../../util/session';
import GoodHappyFace from '../../../theme/ECTheme/assets/images/GoodHappyFace.svg';
import images from '../../../theme/ECTheme/assets/images';
import { BASE_URL } from '../../../constants/api/ApiConstants';
import { ModalCongrats } from './ModalCongrats';
// import PlanSubsContext from '../../Forms/PlansForm/context/PlanSubsContext';

const useStyles = makeStyles(theme => ({

  paper: {
    position: 'absolute',
    width: '100%',
    maxWidth: 520,
    height: 650,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #878787',
    borderRadius: 5,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  centeredItems: {
    margin: 20,
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  mainText: {
    color: 'black',
    padding: 11,
  },
  icon: {
    width: 35,
    marginBlock: 10,
  },
  root: {
    width: '100%',
  },
  selectButton: {
    backgroundColor: '#8821EA',
    margin: 5,
    width: '40%',
    color: '#FFF',
    padding: 5,
    fontSize: 18,
    border: 'none',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: 2,
    '&:hover': {
      backgroundColor: '#6A1AB8',
    },
  },
  imageContainer: {
    margin: 40,

  },
}));

export const PilotPremium = () => {
  const classes = useStyles();
  const { planning } = useSession();
  const [open, setOpen] = useState(false);
  const [openPayment, setOpenPayment] = useState(false);
  const [modalStyle] = React.useState(getModalStyle);
  const plan = planning?.subscriptions[0]?.plan_id || '';
  const { companyId } = useSelector((state: any) => state.session);
  const [load, setLoad] = useState(false);
  const [congratsAlert, setCongratsAlert] = useState(false);

  // custom hook de planes

  const getLinkAcount = async () => {
    setLoad(true);
    Axios.post(`${BASE_URL}/api/v1/companies/${companyId}/subscriptions/pilot_subscription`)
      .then((res) => {
        setLoad(false);
        setCongratsAlert(true);
      });
  };

  const showPilotPremium = useCallback(() => {
    if (
      planning
      && planning.offer_free_pro === true
      && plan !== '614a50847893331348600551'
    ) {
      setOpen(true);
    }
  }, [planning]);

  useEffect(() => {
    showPilotPremium();
  }, [showPilotPremium]);

  function getModalStyle() {
    return {
      top: `${50}%`,
      left: `${50}%`,
      transform: `translate(-${50}%, -${50}%)`,
    };
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleEnableProAccount = () => {
    setOpenPayment(true);
  };

  const body = (

    <Box
      style={modalStyle}
      className={classes.paper}
    >
      { !congratsAlert
        ? (
          <div className={classes.root}>
            <Typography variant="h5" className={classes.centeredItems} style={{ color: 'black', fontWeight: 'bold', marginTop: 20 }}>
              ¡Gracias por participar en los pilotos de Ecubi!
            </Typography>

            <Typography variant="h6" className={classes.centeredItems} style={{ color: 'black', fontWeight: 'lighter', marginTop: 20 }}>
              Tu cuenta ha participado en alguno de nuestros pilotos con el Gobierno de la Ciudad de México
              y/o Banco Mundial. En agradecimiento te queremos otorgar una cuenta Pro para que seas parte
              de nuestra comunidad semilla, seas parte de este gran esfuerzo y tengas más beneficios.
            </Typography>

            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Hidden mdDown>
                <img src={images.Icon_EcubiMiNegocio} alt="mi-negocio" className={classes.imageContainer} style={{ width: 140 }} />
                <img src={GoodHappyFace} alt="discount" style={{ width: 180 }} />
              </Hidden>
            </div>

            <div style={{
              display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
            }}
            >

              {/* <Link to="/settings" className={classes.selectButton}>
                          Activar mi cuenta Pro
                      </Link> */}
              {load
                ? <CircularProgress color="secondary" size={25} />
                : (
                  <Button className={classes.selectButton} onClick={() => getLinkAcount()}>
                    Activar mi cuenta pro
                  </Button>
                )}

              <Button color="primary" onClick={handleClose}>Ahora no</Button>
            </div>
          </div>
        )
        : (
          <div style={{ alignItems: 'center' }}>
            <ModalCongrats closeModal={handleClose} />
          </div>
        )}

    </Box>
  );

  return (
    <Modal
      open={open}
      onClose={handleClose}
      disableEnforceFocus
      disableAutoFocus
    >
      {body}
    </Modal>
  );
};
