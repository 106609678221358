import { types } from '../../actions/activitis/types';

export const initialState = {
  response: { data: [] },
  processing: true,
  data: [],
  error: null,
  params: {
    per_page: 5,
    sort: { updated_at: 'desc' },
  },
};
// updated_at: {
//     $gte: getUTCTodayStart(),
//     $lte: getUTCTodayEnd(),
//   },

export default (state = initialState, action: any) => {
  switch (action.type) {
    case types.LOAD_ACTIVITIS:
      return {
        ...state,
        processing: action.payload,
      };

    case types.SUCCESS_ACTIVITIS:
      return {
        ...state,
        response: action.response,
        data: [...state.data, ...action.response.data],
        error: null,
        processing: false,
      };

    case types.FAILURE_ACTIVITIS:
      return {
        ...state,
        error: action.error,
        processing: false,
      };

    case types.SET_ACTIVITIS_PARAMS:
      return {
        ...state,
        params: action.params,
      };

    case types.RESET_ACTIVITIS:
      return initialState;

    default:
      return state;
  }
};
