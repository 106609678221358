import React from 'react';
import { useDispatch } from 'react-redux';
import ProductsPageView from './ProductsPageView';
import { showProductsExcelModal } from '../../../../actions/products_excel';
import {
  ProductsListProvider,
} from './ProductsTable/context/ProductsListContext';

const AllProductsPage = () => {
  const dispatch = useDispatch();
  const openProductsExcel = () => {
    dispatch(showProductsExcelModal());
  };

  return (
    <ProductsListProvider>
      <ProductsPageView
        openProductsExcel={openProductsExcel}
      />
    </ProductsListProvider>
  );
};

export default AllProductsPage;
