/* eslint-disable react/jsx-props-no-multi-spaces */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Controller } from 'react-hook-form';

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export interface SelectOption {
  value: string;
  label: string;
}

export default ({
  handleOnChange = null,
  variant = 'standard',
  error = null,
  control = null,
  defaultValue = null,
  name,
  options = [],
  label = '',
  startAdornment = null,
}) => {
  const classes = useStyles();
  const optionsList = options.map(option => (
    <MenuItem key={option.value} value={option.value}>
      <Typography>{option.label}</Typography>
    </MenuItem>
  ));

  return (
    <FormControl
      variant="outlined"
      className={classes.formControl}
      error={!!error}
    >
      <InputLabel id={`location-${name}-select-outlined-label`}>{label}</InputLabel>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue || ''}
        render={({ field }) => (
          <Select
            variant={variant as any}
            startAdornment={startAdornment && startAdornment}
            labelId={`location-${name}-select-outlined-label`}
            id={`location-${name}-select-outlined`}
            {...field}
            style={{ width: '100%' }}
            error={!!error}
            label={label}
          >
            <MenuItem value="">
              <em />
            </MenuItem>
            {optionsList}
          </Select>
        )}
      />
      {error && <FormHelperText>{error.message}</FormHelperText>}
    </FormControl>
  );
};
