/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import SectionListContainer from '../../../../hoc/SectionListContainer';
import Grid from '../../../../components/ui/Layout/Grid';
import PageContainer from '../../../../hoc/PageContainer';
import BranchesList from './BranchesList';
import BranchForm from './BranchForm';
import { useSession } from '../../../../util/session';
import { PERMISSION, RESOURCES } from '../../../../constants/common';

type BranchProps = {
  openNewBranch: any;
};
export default ({ openNewBranch }: BranchProps) => {
  const { can } = useSession();
  return (
    <>
      {(can(RESOURCES.branches, PERMISSION.create)
        || can(RESOURCES.branches, PERMISSION.create)) && <BranchForm />}

      <Grid container>
        <PageContainer>
          <SectionListContainer>
            <BranchesList />
          </SectionListContainer>
        </PageContainer>
      </Grid>
    </>
  );
};
