/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable import/no-extraneous-dependencies */
import { Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import Box from '../ui/Layout/Box';
import { setKeywords, setWizardStep } from '../../actions/wizard';
import SuccessButton from '../ui/Buttons/SuccessButton';
import TextField from '../ui/TextField';
import KeyWordTag from '../ui/KeyWordTag';

import { WizardSteps } from './steps';

const KeywordsSection = () => {
  const { t } = useTranslation(['general']);
  const dispatch = useDispatch();
  const [words, setWords] = useState([]);
  const [word, setWord] = useState(null);

  const addWord = (words, value) => {
    if (!value) {
      return;
    }
    const current = words.find(
      c => c.toString().toLowerCase() === value.toString().toLowerCase(),
    );

    if (!current) {
      setWords([...words, value]);
    }
    setWord(null);
  };

  const removeWord = (value) => {
    setWords(
      words.filter(
        c => c.toString().toLowerCase() !== value.toString().toLowerCase(),
      ),
    );
  };

  const delayedSetState = useCallback(
    _.debounce((words, q) => addWord(words, q), 1000),
    [],
  );
  const _onChange = (e: any) => {
    setWord(e.target.value);
    delayedSetState(words, e.target.value ? e.target.value : '');
  };

  const handleSubmit = () => {
    dispatch(setKeywords(words));
    dispatch(setWizardStep(WizardSteps.BRANCH));
  };

  return (
    <>
      <Box>
        <Typography variant="h6" align="center" color="textSecondary">
          {t('general:wizard_setup:keywords_question')}
        </Typography>
      </Box>

      <Box mt={5} ml={4}>
        <Typography variant="body2" color="textSecondary">
          {t('general:wizard_setup:keywords_example')}
        </Typography>
      </Box>

      <Box mt={2} ml={2} mr={2} pt={5}>
        <TextField
          onChange={_onChange}
          value={word || ''}
          InputProps={{ inputProps: { maxLength: 20 } }}
        />
      </Box>

      {words && words.length > 0 && (
        <Box mt={3} display="flex" flexWrap="wrap">
          {words.map(word => (
            <KeyWordTag
              key={word}
              label={word}
              handleDelete={() => removeWord(word)}
            />
          ))}
        </Box>
      )}

      <Box mt={2} display="flex" justifyContent="center">
        <SuccessButton
          text={t('general:next')}
          onClick={handleSubmit}
        />
      </Box>
    </>
  );
};

export default KeywordsSection;
