/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';
import Geocode from 'react-geocode';

import Box from '../../../../../../../components/ui/Layout/Box';

const Map = ({
  zoom = 14,
  zone = null,
  height = '40vh',
  onLocationChanged,
  onLoadLocation = null,
  disableDefaultUI = false,
  draggableMap = true,
  clickableMarker = true,
  draggableMarker = true,
  clickableIcons = true,
}) => {
  const containerStyle = {
    width: '100%',
    height,
  };

  const [lat, setLat] = useState(zone ? zone.lat : null);
  const [lng, setLng] = useState(zone ? zone.lng : null);
  const isLoaded = true;
  useEffect(() => {
    if (zone) {
      setLat(zone.lat);
      setLng(zone.lng);
    } else {
      setLat(null);
      setLng(null);
    }
  }, [zone]);

  const getAddress = useCallback(async (lat, lng) => {
    if (lat && lng) {
      try {
        const response = await Geocode.fromLatLng(lat, lng);
        // debugger
        return response.results[0].formatted_address;
      } catch (error) {
        console.error(error);
        return null;
      }
    }
  }, []);

  const onMarkerChanged = async (e) => {
    const fullAddress = await getAddress(e.latLng.lat(), e.latLng.lng());
    if (fullAddress) {
      const lat = e.latLng.lat();
      const lng = e.latLng.lng();
      setLat(lat);
      setLng(lng);
      onLocationChanged(fullAddress, lat, lng);
    }
  };

  const onLoadMarker = async (e) => {
    const lat = e.position.lat();
    const lng = e.position.lng();
    const fullAddress = await getAddress(lat, lng);
    if (fullAddress) {
      setLat(lat);
      setLng(lng);
      onLoadLocation(fullAddress, lat, lng);
    }
  };

  return (
    <Box>
      <Box>
        {isLoaded && (
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={lat && lng ? { lat, lng } : null}
            zoom={zoom}
            options={{
              disableDefaultUI,
              draggable: draggableMap,
              clickableIcons,
            }}
          >
            {lat && lng && (
              <Marker
                position={{ lat, lng }}
                draggable
                onDragEnd={onMarkerChanged}
                onLoad={onLoadMarker}
                options={{
                  clickable: clickableMarker,
                  draggable: draggableMarker,
                }}
              />
            )}
          </GoogleMap>
        )}
      </Box>
    </Box>
  );
};

export default React.memo(Map);
