import { Typography } from '@mui/material';
import React from 'react';
import { makeStyles } from '@mui/styles';
import FormattedCurrency from '../../../../../../components/ui/FormattedCurrency';
import FormattedNumber from '../../../../../../components/ui/FormattedNumber';
import Box from '../../../../../../components/ui/Layout/Box';

const useKPIDataStyles = makeStyles(() => ({
  data: {
    color: 'green',
  },
}));

export enum KPIDataType {
  CURRENCY = 1,
  QUANTITY = 2,
}

interface Props {
  value: number;
  type: KPIDataType;
}

const KPIData = ({ value, type }: Props) => {
  const classes = useKPIDataStyles();
  return (
    <Box mt={3}>
      <Typography variant="h5" className={classes.data} align="center">
        {type === KPIDataType.QUANTITY ? (
          <FormattedNumber value={value} />
        ) : (
          <FormattedCurrency value={value} />
        )}
      </Typography>
    </Box>
  );
};

export default KPIData;
