import Axios from 'axios';
import { useCallback, useState } from 'react';
import { BASE_URL } from '../constants/api/ApiConstants';
import { ViewFormats } from '../constants/common';

export interface ProductParams {
  per_page?: number;
  page?: number;
  embed?: string;
  sort?: { [key: string]: string };
  filters?: {
    name?: string;
    inventoried?: boolean;
  };
}

const useProducts = (companyId: string, params: ProductParams = {}) => {
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [products, setProduct] = useState({
    data: [],
    page: undefined,
    last_page: undefined,
    per_page: undefined,
    total: undefined,
  });

  const [loadingProducts, setLoad] = useState(false);
  const productsPath = `companies/${companyId}/products`;

  const getProducts = useCallback(() => {
    setLoad(true);
    Axios.get(`${BASE_URL}/api/v1/${productsPath}`, {
      params: {
        per_page: perPage,
        page,
        embed: 'suppliers,category,brand',
        sort: { created_at: 'desc' },
        ...params,
      },
    })
      .then((res) => {
        setProduct(res.data);
      })
      .finally(() => setLoad(false));
  }, [page, perPage, productsPath]);

  const updateProduct = useCallback((productId, data) => {
    setLoad(true);
    return Axios.put(`${BASE_URL}/api/v1/${productsPath}/${productId}`, data)
      .then(res => res)
      .finally(() => setLoad(false));
  }, [productsPath]);

  const buildParams = () => ({
    per_page: perPage,
    page,
    embed: 'suppliers,category,brand',
    sort: { created_at: 'desc' },
    ...params,
  });

  const buildDownloadParams = (format: ViewFormats = ViewFormats.EXCEL) => ({
    ...buildParams(),
    ...params,
    format,
  });

  return {
    getProducts,
    products,
    loadingProducts,
    updateProduct,
    setPerPage,
    setPage,
    buildDownloadParams,
    productsPath,
  };
};

export default useProducts;
