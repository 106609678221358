// import AuthService from '../../contracts/services/AuthService';
import ApiClient from '../../common/api/ApiClient';

export default class ApiRegisterService extends ApiClient {
  getPath(): string {
    return 'api/v1/register';
  }

  async register(data: any) {
    return this.post(data);
  }

  async saveCompany(data: any) {
    return this.post(data, 'api/v1/register/company');
  }

  async savePassword(data: any) {
    return this.post(data, 'api/v1/register/password');
  }
}
