/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-extraneous-dependencies */
import {
  Box, CircularProgress, FormControl, FormControlProps, MenuItem, Select,
} from '@mui/material';
import { DefaultTheme, styled } from '@mui/styles';
import React, { ChangeEvent, useEffect, useState } from 'react';
import useCategories from '../../../hooks/useCategories';
import useCompany from '../../../hooks/useCompany';

interface CategoryFormControlProps {
  htmlColor: string;
}

const CategoryFormControl = styled(FormControl)(({ htmlColor = '#ccc' }: CategoryFormControlProps) => ({
  '& .MuiSelect-select': {
    backgroundColor: htmlColor,
    color: 'white',
    borderRadius: 70,
    padding: '4px 20px 4px 10px',
  },
  '& .MuiInput-underline': {
    '&::after': {
      display: 'none',
    },
    '&::before': {
      display: 'none',
    },
  },
}));

const CategorySelecter = ({ defaultValue, onChange }) => {
  const [option, setOption] = useState(defaultValue);
  const { company } = useCompany();
  const { getCategories, loadingCat, categories } = useCategories(company.id);

  useEffect(() => {
    getCategories();
  }, [getCategories]);

  const onChangeSelect = (e: ChangeEvent<HTMLInputElement>) => {
    setOption(e.target.value);
    if (onChange) onChange(e);
  };

  if (loadingCat) return <Box width={100}><CircularProgress size={16} /></Box>;

  return (
    <CategoryFormControl
      htmlColor={categories.find(category => category.id === option)?.color}
      style={{ width: 100, boxSizing: 'border-box' }}
    >
      <Select
        variant="standard"
        maxRows={5}
        value={categories.length < 1 ? '' : option}
        onChange={onChangeSelect}
      >
        {
          categories.map(unit => <MenuItem key={unit.id} value={unit.id}>{unit.name}</MenuItem>)
        }
      </Select>
    </CategoryFormControl>
  );
};

export default CategorySelecter;
