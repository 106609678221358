import React from 'react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import images from '../../../theme/ECTheme/assets/images';

const useStyle = makeStyles(() => ({
  perfileImg: {
    width: 50,
    height: 50,
    borderRadius: '50%',
    border: '1px solid #7A4CE2',
    cursor: 'pointer',

    '&:hover': {
      opacity: 0.5,
    },
  },
}));

interface ButtonProps {
  image: any;
  width?: number;
  height?: number;
}

const ProfilePicture = ({ image, width, height }: ButtonProps) => {
  const classes = useStyle();

  return (
    <Box className={classes.perfileImg} style={{ width, height }}>
      <span
        style={{
          backgroundImage: `url(${image || images.DefaultPerfile})`,
          width: '100%',
          height: '100%',
          borderRadius: '50%',
          backgroundSize: 'cover',
          display: 'inline-block',
        }}
      />
    </Box>
  );
};

export default ProfilePicture;
