/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import { PersistGate } from 'redux-persist/integration/react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Axios from 'axios';
import { useJsApiLoader } from '@react-google-maps/api';
import { ShepherdTour } from 'react-shepherd';
import { persistor } from './CongigureStore';
import Routes from './router/Routes';
import { checkStoredUser, disableUserStatus, enableUserStatus } from './actions/session';
import ThemeProvider from './theme/ECTheme/layout/materialUIThemeProvider/ThemeProvider';
import { GEO_API_KEY, STRIPE_KEY } from './constants/api/ApiConstants';
import useSockets from './hooks/useSockets';
import { useSession } from './util/session';
import { allSteps } from './constants/stepsBySteps/allSteps';

const { PUBLIC_URL } = process.env;

const tourOptions = {
  defaultStepOptions: {
    cancelIcon: {
      enabled: true,
    },
  },
  useModalOverlay: true,
};

const AppContainer = () => {
  const dispatch = useDispatch();
  const { subscribeToBinding, unsubscribeFromBinding } = useSockets();
  const { user: userSession } = useSession();
  useJsApiLoader({ googleMapsApiKey: GEO_API_KEY });

  useEffect(() => {
    subscribeToBinding('SubscriptionCreated', () => {
      dispatch(checkStoredUser());
    });
    subscribeToBinding('SubscriptionChanged', () => {
      dispatch(checkStoredUser());
    });
    subscribeToBinding('SubscriptionCanceled', () => {
      dispatch(checkStoredUser());
    });
    subscribeToBinding('PosUserActivated', ({ user }) => {
      if (user.user_id === userSession.id) {
        dispatch(enableUserStatus());
      }
    });
    subscribeToBinding('PosUserDisabled', ({ user }) => {
      if (user.user_id === userSession.id) {
        dispatch(disableUserStatus());
      }
    });
    return () => {
      unsubscribeFromBinding('SubscriptionCreated');
      unsubscribeFromBinding('SubscriptionChanged');
      unsubscribeFromBinding('SubscriptionCanceled');
      unsubscribeFromBinding('PosUserActivated');
      unsubscribeFromBinding('PosUserDisabled');
    };
  }, [dispatch, subscribeToBinding, unsubscribeFromBinding, userSession]);

  useEffect(
    () => {
      const _checkSession = async () => {
        await dispatch(checkStoredUser());
      };
      _checkSession();
    },
    [dispatch],
  );

  useEffect(() => { // TODO: corregir
    Axios.interceptors.response.use(response => response, (error) => {
      // dispatch(enableUserStatus());
      const unAuthStatus = [401, 403, 498];
      if (unAuthStatus.includes(error.response?.status)) {
        dispatch(disableUserStatus());
      }
      return Promise.reject(error);
    });
  }, []);

  const stripePromise = loadStripe(STRIPE_KEY);

  return (
    <Elements stripe={stripePromise}>
      <PersistGate persistor={persistor}>
        <React.Suspense fallback={null}>
          <Router basename={PUBLIC_URL}>
            <LastLocationProvider>
              <ThemeProvider>
                <ShepherdTour
                  steps={allSteps}
                  tourOptions={tourOptions}
                >
                  <Routes />
                </ShepherdTour>
              </ThemeProvider>
            </LastLocationProvider>
          </Router>
        </React.Suspense>
      </PersistGate>
    </Elements>
  );
};

export default AppContainer;
