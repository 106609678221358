import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Tooltip } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import InfoIcon from '@mui/icons-material/Info';
import LabelledOutline from '../../../../../hoc/LabelledOutline';
import Box from '../../../../../components/ui/Layout/Box';
import Grid from '../../../../../components/ui/Layout/Grid';
import { getUTCDayEnd, getUTCDayStart } from '../../../../../util/dates';
import { useDashboardContext } from '../context/DashboardContext';
import { useSalesPerMetric } from '../hooks/useSalesPerMetric';
import { CustomPieChart } from '../PieChart';
import { randomColor } from '../../../../../helpers/colors';
import { Legend } from '../Legend';

export const SalesPerMetric = () => {
  const { t } = useTranslation(['general']);
  const { state: dashboardState } = useDashboardContext();
  const {
    getSales, isLoading, sales, changeMetric, metric,
  } = useSalesPerMetric();

  useEffect(() => {
    getSales({
      filters: {
        updated_at: {
          $gte: getUTCDayStart(dashboardState.start_date),
          $lte: getUTCDayEnd(dashboardState.end_date),
        },
      },
    });
  }, [dashboardState.start_date, dashboardState.end_date, getSales]);

  const dataWithColors = useMemo(() => sales.data?.map(sale => ({
    ...sale,
    color: sale.color || randomColor(),
  })), [sales]);

  return (
    <>
      <Box display="flex" width="100%" marginBottom={3}>
        <Button
          style={{
            backgroundColor: metric === 'category' ? '#4154b2' : '#5c78fe',
            width: '50%',
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            color: 'white',
          }}
          onClick={() => changeMetric('category')}
        >
          Categoría
        </Button>
        <Button
          style={{
            backgroundColor: metric === 'brand' ? '#4154b2' : '#5c78fe',
            width: '50%',
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            color: 'white',
          }}
          onClick={() => changeMetric('brand')}
        >
          Marca
        </Button>
      </Box>
      <Grid container sx={12} spacing={1} mt={1}>
        <Grid item xs={12} sm={6}>
          <LabelledOutline
            label={(
              <>
                {t('general:total_sales')}
                <Tooltip disableInteractive title={t('sales_info')}>
                  <Button style={{ borderRadius: 20, minWidth: 'auto' }}><InfoIcon /></Button>
                </Tooltip>
              </>
          )}
          >
            <Box width="100%" height="240px" display="flex" justifyContent="center" alignItems="center">
              {isLoading ? <Skeleton animation="wave" variant="circular" width={170} height={170} /> : <CustomPieChart data={dataWithColors} dataKey="total_sales" />}
            </Box>
          </LabelledOutline>
        </Grid>
        <Grid item xs={12} sm={6}>
          <LabelledOutline
            label={(
              <>
                {t('general:total_cost')}
                <Tooltip disableInteractive title={t('cost_info')}>
                  <Button style={{ borderRadius: 20, minWidth: 'auto' }}><InfoIcon /></Button>
                </Tooltip>
              </>
          )}
          >
            <Box width="100%" height="240px" display="flex" justifyContent="center" alignItems="center">
              {isLoading ? <Skeleton animation="wave" variant="circular" width={170} height={170} /> : <CustomPieChart data={dataWithColors} dataKey="total_cost" />}
            </Box>
          </LabelledOutline>
        </Grid>
      </Grid>
      <Grid container sx={12} spacing={1}>
        <Grid item xs={12} sm={6}>
          <LabelledOutline
            label={(
              <>
                {t('general:total_profit')}
                <Tooltip disableInteractive title={t('profit_info')}>
                  <Button style={{ borderRadius: 20, minWidth: 'auto' }}><InfoIcon /></Button>
                </Tooltip>
              </>
          )}
          >
            <Box width="100%" height="240px" display="flex" justifyContent="center" alignItems="center">
              {isLoading ? <Skeleton animation="wave" variant="circular" width={170} height={170} /> : <CustomPieChart data={dataWithColors} dataKey="total_profit" />}
            </Box>
          </LabelledOutline>
        </Grid>
        <Grid item xs={12} sm={6}>
          <LabelledOutline
            label={(
              <>
                {t('general:total_revenue')}
                <Tooltip disableInteractive title={t('revenue_info')}>
                  <Button style={{ borderRadius: 20, minWidth: 'auto' }}><InfoIcon /></Button>
                </Tooltip>
              </>
          )}
          >
            <Box width="100%" height="240px" display="flex" justifyContent="center" alignItems="center">
              {isLoading ? <Skeleton animation="wave" variant="circular" width={170} height={170} /> : <CustomPieChart data={dataWithColors} dataKey="total_revenue" />}
            </Box>
          </LabelledOutline>
        </Grid>
      </Grid>
      <Box
        display="flex"
        style={{
          gap: 8, margin: 16, flexWrap: 'wrap', justifyContent: 'center',
        }}
      >
        {isLoading ? <Skeleton variant="text" width={200} /> : dataWithColors?.map(sale => <Legend label={sale.name} color={sale.color} />)}
      </Box>
    </>
  );
};
