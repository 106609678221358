/* eslint-disable react/prop-types */
import React from 'react';
import Box from '../../../../../../../components/ui/Layout/Box';
import CellContent from './CellContent';
import Title from './Title';

export default ({ title, content }) => (
  <Box
    display="flex"
    justifyContent="center"
    flexDirection="col"
    flexWrap="wrap"
  >
    <Box width={1} display="flex" justifyContent="center">
      <Title title={title} />
    </Box>
    <Box width={1} display="flex" justifyContent="center">
      <CellContent content={content} />
    </Box>
  </Box>
);
