/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-unused-prop-types */
import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import axios from 'axios';
import Box from '../Layout/Box';
import filesHelper from '../../../helpers/files';
import AuthorizedApiClient from '../../../core/common/api/AuthorizedApiClient';

const sg = new AuthorizedApiClient();

const getSigninUploadData = file => ({
  name: filesHelper.getName(file),
  mime_type: filesHelper.getType(file),
  size: filesHelper.getSize(file),
});

const uploadFileToProvider = async (file: File, data: SingningResponse) => {
  const simpleAxios = axios.create();

  return simpleAxios({
    method: data.http_method,
    url: data.url,
    data: file,
  });
};

const signUpload = async (file, url: string): Promise<SingningResponse> => {
  const data = getSigninUploadData(file);
  const response = await sg.post(data, url);

  return {
    url: response.data.url,
    key: response.data.key,
    http_method: response.data.http_method,
  } as SingningResponse;
};
const uploadTmpImage = async (file: File, url: string): Promise<string> => {
  const signResponse = await signUpload(file, url);

  await uploadFileToProvider(file, signResponse);

  return signResponse.key;
};

interface SingningResponse {
  url: string,
  key: string,
  http_method: 'PUT' | 'POST';
}

const useStyle = makeStyles(theme => ({
  wrapper: {
    margin: theme.spacing(1),
    boxSizing: 'border-box',
    borderRadius: '4px',
    border: '1px solid #AAA8A8',
    cursor: 'pointer',
    padding: '2px',
  },
  noImageIcon: {
    color: theme.colors.pointSale.main,

    width: '26%',
    height: '26%',
    marginBottom: '8%',
  },
  text: {
    textAlign: 'center',
    fontSize: 10,
    color: theme.colors.pointSale.main,
    fontWeight: 'bold',
  },
  img: {
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    height: '100%',
    cursor: 'pointer',
    backgroundSize: '100%',
  },
}));

type ImageUploaderProps = {
  defaultPreview?: string;
  imgUrl: string;
  setImage?: Function;
  alt?: string;
  name?: string;
  text?: string;
  size?: number;
  signUrl?: string;
  setImageKey?: Function;
};

const ImageUploader = ({
  imgUrl,
  setImage,
  alt = '',
  name = null,
  text = '',
  size,
  signUrl, setImageKey,
}: ImageUploaderProps) => {
  const classes = useStyle();

  const [localImage, setLocalImage] = useState({
    raw: '',
    preview: imgUrl || null,
  });

  const handleChange = async (e) => {
    if (e.target.files.length) {
      const file = e.target.files[0];
      if (signUrl && setImageKey) {
        const responseKey = await uploadTmpImage(file, signUrl);

        setImageKey(responseKey);
      }

      setLocalImage({
        preview: URL.createObjectURL(file),
        raw: file,
      });
      setImage(file);
    }
  };

  const id = `upload-button${Math.floor(Math.random() * 1000)}${1}`;

  return (
    <Box width={size || '100%'} height={size || '100%'} className={classes.wrapper}>
      <Box component="label" htmlFor={id} width={1} height={1} style={{ cursor: 'pointer' }}>
        <Box width={1} height="100%" display="flex" alignItems="center">
          {localImage.preview != null ? (
            <div
              style={{
                width: '100%',
                height: '100%',
                backgroundImage: `url(${localImage.preview})`,
              }}
              className={classes.img}
            />
          ) : (
            // <img src={localImage.preview} alt={alt} className={classes.img} />
            <Box
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              width="100%"
              height="100%"
              display="flex"
            >
              <AddAPhotoIcon className={classes.noImageIcon} />
              <span className={classes.text}>{text}</span>
            </Box>
          )}
        </Box>
      </Box>
      <input
        type="file"
        name={name}
        id={id}
        onChange={handleChange}
        style={{ display: 'none' }}
        accept=".jpeg,.jpg,.png"
      />
    </Box>
  );
};

export default ImageUploader;
