/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '../../../../../../components/ui/Layout/Box';
import ConfirmDialog from '../../../../../../components/ui/ConfirmDialog';
import Grid from '../../../../../../components/ui/Layout/Grid';
import Alert from '../../../../../../components/ui/notifications/alert';
import Modal from '../../../../../../components/ui/Modals/Modal';
import CampaignWizard from '../CampaignWizard';
import CampaignWizardNavigation from '../CampaignWizard/CampaignWizardNavigation';
import { CampaignWizardStateContext } from '../CampaignWizard/context/CampaignWizardContext';

export default ({
  campaign,
  closeCampaign,
  error,
  showModal,
  refreshList,
}) => {
  const [showConfirm, setShowConfirm] = useState(false);

  const { t } = useTranslation(['general']);

  const { clearCampaignForm, state } = useContext(CampaignWizardStateContext);
  const { refresh } = state;
  const handleClose = () => {
    clearCampaignForm();
    closeCampaign();
  };

  useEffect(() => {
    if (refresh && refreshList) {
      clearCampaignForm();
      refreshList();
    }
  }, [refresh, refreshList, clearCampaignForm]);

  const title = campaign && campaign.id !== null
    ? t('general:shared_campaigns:edit_campaign', {
      name: campaign.name,
    })
    : t('general:shared_campaigns:create_campaign');

  const actions = <CampaignWizardNavigation />;

  return (
    <Modal
      open={showModal}
      onClose={handleClose}
      title={title}
      actions={actions}
      maxWidth="xs"
    >
      <Box mb={5}>
        <ConfirmDialog
          open={showConfirm}
          handleCancel={() => {
            setShowConfirm(false);
          }}
          handleConfirm={() => {
            setShowConfirm(false);
            // attemptDeleteCampaign(campaign);
          }}
          title={t('general:modifiers_groups:delete_modifier_group')}
          content={t('general:modifiers_groups:delete_confirmation_msg')}
        />
        {error && (
          <Grid container>
            <Alert severity="error" message={error} />
          </Grid>
        )}

        <CampaignWizard />
      </Box>
    </Modal>
  );
};
