/* eslint-disable react/prop-types */
import React from 'react';
import { withStyles } from '@mui/styles';
import TextField from '../../../../../components/ui/TextField';

const CTextField = withStyles(() => ({
  root: {
    '& .MuiOutlinedInput-root': {
      padding: 0,
      fontSize: 12,
      width: '60px',
    },
    '& .MuiOutlinedInput-input': {
      padding: '5px 0',
      textAlign: 'center',
    },
    padding: 0,
  },
}))(TextField);

const ModifierListPriceInput = ({ defaultValue = '0' }) => (
  <CTextField
    type="text"
    value={`$ ${defaultValue}`}
    disabled
  />
);

export default ModifierListPriceInput;
