import { makeStyles } from '@mui/styles';
import React from 'react';
import Box from '../../../../../../../components/ui/Layout/Box';

const styles = makeStyles(theme => ({
  root: {
    backgroundColor: '#E5E6E5',
    textAlign: 'center',
    color: '#5F5353',
    minWidth: '50px',
    padding: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      width: '40px',
      padding: theme.spacing(0.5),
      fontSize: '0.875rem',
    },
  },
}));

interface DQProps {
  quantity: number;
}
export default ({ quantity }: DQProps) => {
  const classes = styles();
  return <Box component="span" className={classes.root}>{quantity !== null ? quantity : '-'}</Box>;
};
