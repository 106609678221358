/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import Lottie from 'react-lottie';
import { makeStyles } from '@mui/styles';
import { Box, Button } from '@mui/material';
import success from '../../../theme/ECTheme/assets/Lotties/successful.json';
import Ecubi from '../../../theme/ECTheme/assets/images/Ecubi.svg';

const useStyle = makeStyles(() => ({
  imageContainer: {
    marginTop: 10,
    margin: 40,
    marginBottom: 70,
  },
  selectButton: {
    marginTop: '15px',
    backgroundColor: '#8821EA',
    margin: 5,
    width: '40%',
    color: '#FFF',
    padding: 5,
    fontSize: 18,
    border: 'none',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: 2,
    '&:hover': {
      backgroundColor: '#6A1AB8',
    },
  },
}));

export const ModalCongrats = ({ closeModal }: any) => {
  const classes = useStyle();
  const defaultOptions = {
    loop: false,
    autoplay: true,
    rendererSettings: {
      preserverAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <h2 style={{ color: 'black' }}>
        ¡FELICIDADES!
      </h2>
      <p style={{ width: 500, fontSize: '22px', textAlign: 'center' }}>
        Ahora tu negocio contará con las mejores herramientas
        para llegar al éxito.
      </p>
      <div style={{ display: 'flex', justifyContent: 'space-between', width: 450 }}>
        <img src={Ecubi} alt="ecubi_logo" className={classes.imageContainer} style={{ width: '65%', marginTop: 80 }} />
        <div className={classes.imageContainer} style={{ width: '100%' }}>
          <Lottie options={{ animationData: success, ...defaultOptions }} />
        </div>
      </div>
      <div style={{
        width: 500, fontSize: '22px', textAlign: 'center', color: 'black', alignItems: 'center',
      }}
      >
        <div>
          Este cupón es gracias al
          {' '}
          <b>Banco Mundial</b>
          {' '}
          y/o
          {' '}
          <b>Gobierno de la Ciudad de México,</b>
          se renovará cada 6 meses, de utilizar tu cuenta se renovará automáticamente,
          pero si tu cuenta en este lapso de tiempo se muestra
          inactiva perderás los beneficios permanentemente.
        </div>
      </div>
      <Button className={classes.selectButton} color="primary" onClick={closeModal} href="/">Finalizar</Button>
    </Box>
  );
};
