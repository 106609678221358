/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import {
  FormControl,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '../Layout/Box';
import { SelectOption } from '../FormInput/Select';
import AuthorizedApiClient from '../../../core/common/api/AuthorizedApiClient';

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '100%',
    maxWidth: '300px',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

interface BranchesSelectorProps {
  company_id: string;
  branch_id: string;
  setBranch: Function;
}

export default ({
  company_id,
  branch_id,
  setBranch,
}: BranchesSelectorProps) => {
  const classes = useStyles();
  const { t } = useTranslation(['branches']);
  const [branchesOptions, setBranchesOptions] = useState<SelectOption[]>([]);

  const _getBranches = useCallback(
    async (companyId) => {
      const sg = new AuthorizedApiClient();
      const path = `companies/${companyId}/branches`;

      try {
        const response = await sg.get({}, path);

        if (response != null) {
          const branches = response.data.data;
          const _branches = branches.map(b => ({
            value: b.id,
            label: b.name,
          } as SelectOption));

          setBranchesOptions(_branches);
          if (branches.length && !branch_id) {
            setBranch(response.data.data[0].id);
          }
        }
      } catch (error) {
        setBranchesOptions([]);
      }
    },
    [setBranch, branch_id],
  );
  useEffect(() => {
    if (company_id && branchesOptions.length === 0) {
      _getBranches(company_id);
    }
  }, [_getBranches, company_id, branchesOptions]);

  const optionsList = branchesOptions.map(option => (
    <MenuItem key={option.value} value={option.value}>
      <Typography>{option.label}</Typography>
    </MenuItem>
  ));

  const handleOnChange = (event) => {
    setBranch(event.target.value);
  };

  return (
    <Box mb={2} display="flex" justifyContent="center" width={1}>
      <FormControl
        variant="standard"
        //   error={error ? true : false}
        className={classes.formControl}
      >
        {/* <InputLabel>{t("branches:branch")}</InputLabel> */}

        <Select
          variant="standard"
          style={{ width: '100%' }}
          onChange={handleOnChange}
          value={branchesOptions.length > 0 && branch_id ? branch_id : ''}
          defaultValue=""
          label={t('branches:branch')}
        >
          {optionsList}
        </Select>
      </FormControl>
    </Box>
  );
};
