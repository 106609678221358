/* eslint-disable react/no-unused-prop-types */
import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import Box from '../Layout/Box';
import images from '../../../theme/ECTheme/assets/images';

const useStyle = makeStyles(() => ({
  wrapper: {
    borderRadius: '50%',
    width: '100%',
    height: '100%',
    border: '3px solid #7A4CE2',
    cursor: 'pointer',
  },

  img: {
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    height: '100%',
    cursor: 'pointer',
    backgroundSize: 'cover',
    borderRadius: '50%',
  },
}));

type ImageUploaderProps = {
  defaultPreview?: string;
  imgUrl: string;
  setImage?: Function;
  alt?: string;
};
export default ({
  imgUrl,
  setImage,
}: ImageUploaderProps) => {
  const classes = useStyle();

  const [localImage, setLocalImage] = useState({
    raw: '',
    preview: imgUrl ? `${imgUrl}?v=${Math.random()}` : null,
  });

  const handleChange = (e) => {
    if (e.target.files.length) {
      setLocalImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
      setImage(e.target.files[0]);
    }
  };

  const id = `upload-button${Math.floor(Math.random() * 1000)}${1}`;

  return (
    <Box width={1} height="100%" className={classes.wrapper}>
      <Box component="label" htmlFor={id} width={1} height={1} style={{ cursor: 'pointer' }}>
        <Box width={1} height="100%" display="flex" alignItems="center">
          {localImage.preview != null ? (
            <div
              style={{
                width: '100%',
                height: '100%',
                backgroundImage: `url(${localImage.preview})`,
              }}
              className={classes.img}
            />
          ) : (

            <div
              style={{
                backgroundImage: `url(${images.DefaultPerfile})`,
              }}
              className={classes.img}
            />
          )}
        </Box>
      </Box>
      <input
        type="file"
        id={id}
        onChange={handleChange}
        style={{ display: 'none' }}
        accept=".jpeg,.jpg,.png"
      />
    </Box>
  );
};
