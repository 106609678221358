import {
  ECUBIROL, PERMISSION, ROLES, SECTIONS,
} from '../constants/common';

export const hasRole = (user, roleCode) => {
  let r = null;
  if (user.roles) {
    r = user.roles.find(rol => rol.code.toString() === roleCode);
  }
  if (r) {
    return true;
  }
  return false;
};

export const hasEcubiRol = (user, roleCode) => {
  let r = null;
  if (user.ec_roles) {
    r = user.ec_roles.find(rol => rol.code.toString() === roleCode);
  }
  if (r) {
    return true;
  }
  return false;
};

export const isAdminUser = user => hasRole(user, ROLES.owner);

export const companyCan = (company, planning, featureType, resource) => {
  if (!planning?.plan) {
    return false;
  }

  const { features } = planning.plan;

  const limitsFeatureValidation = {
    pos_users: !features.limits || company.numberOfUsers < features.limits.pos_users,
  };

  const featureOptions = {
    custom_subdomain: Boolean(features.custom_subdomain),
    pos_resources: Boolean(features.pos_resources?.[resource]),
    // orders_fee: features.orders_fee,
    limits: Boolean(limitsFeatureValidation[resource]),
  };

  return featureOptions[featureType];
};

export const userHasPermission = (user, resource, action) => {
  if (!user || !user.permissions || !user.permissions[resource]) {
    return false;
  }

  return user.permissions[resource].includes(action);
};

export const userHasPermissionToSection = (user, section, action) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const r of SECTIONS[section].resources) {
    if (userHasPermission(user, r, action)) {
      return true;
    }
  }
  return false;
};

export const isEcubiSection = resource => (
  resource.name === SECTIONS.admin.name
    || SECTIONS.admin.resources.includes(resource.toString())
);

export const userHasEcubiPermission = (user, resource, action) => {
  if (!user || !user.ec_roles || user.ec_roles.length === 0) {
    return false;
  }

  if (SECTIONS[resource.name] && action === PERMISSION.read) {
    // eslint-disable-next-line no-restricted-syntax
    for (const r of SECTIONS[resource.name].resources) {
      if (userHasEcubiPermission(user, r, action)) {
        return true;
      }
    }
    return false;
  }
  return user.ec_roles
    .find(rol => rol.permissions[resource] && rol.permissions[resource].includes(action));
};

export const userCan = (user, resource, action) => {
  if (isEcubiSection(resource)) {
    return userHasEcubiPermission(user, resource, action);
  }
  if (isAdminUser(user)) {
    return true;
  }

  if (SECTIONS[resource] && action === PERMISSION.read) {
    return userHasPermissionToSection(user, resource, action);
  }

  return userHasPermission(user, resource, action);
};

export const isEcubiAdminUser = user => hasEcubiRol(user, ECUBIROL.administrator);

export const can = (company, user, resource, action, planning, feature) => {
  if (!user) {
    return false;
  }

  if (isEcubiSection(resource)) {
    return userCan(user, resource, action);
  }

  if (action === 'access') {
    return companyCan(company, planning, feature, resource);
  }

  return userCan(user, resource, action);
};
