import { types } from '../../actions/user/types';

export const initialState = {
  user: {
    id: null,
    name: '',
    last_name: '',
    email: '',
    phone: '',
    // message: ""
  },
  error: null,
  showModal: false,
  processing: false,
  // deletingUser: false,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case types.EDIT_USER:
      return {
        ...state,
        showModal: true,
        user:
          action.payload !== null ? action.payload : initialState.user,
      };
    case types.CLOSE_USER_MODAL:
      return initialState;
    case types.SAVE_USER_SUCCESS:
      return {
        ...state,
        response: action.response,
        error: null,
        processing: false,
      };
    case types.SAVE_USER_FAILURE:
      return {
        ...state,
        error: action.error,
        processing: false,
      };
    case types.SAVING_USER:
      return {
        ...state,
        error: null,
        processing: true,
      };

      // case types.DELETING_USER:
      //   return {
      //     ...state,
      //     error: null,
      //     deletingUser: true,
      //   };

      // case types.DELETE_USER_SUCCESS:
      //   return {
      //     ...state,
      //     error: null,
      //     deletingUser: false
      //   };
      // case types.DELETE_USER_FAILURE:
      //   return {
      //     ...state,
      //     error: action.error,
      //     deletingUser: false,
      //   };

    default:
      return state;
  }
};
