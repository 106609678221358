/* eslint-disable import/no-named-as-default */
import React from 'react';
import { Provider } from 'react-redux';
import ReactGa from 'react-ga';
import { useClearCache } from 'react-clear-cache';
import store from './CongigureStore';
import AppContainer from './AppContainer';
import { ECUBI_GA } from './constants/api/ApiConstants';
import SocketsProvider from './contexts/SocketsContext';
import 'shepherd.js/dist/css/shepherd.css';

// ReactGa.initialize("UA-184733903-1")
ReactGa.initialize(ECUBI_GA);

const App = () => {
  useClearCache({ auto: true });
  return (
    <Provider store={store}>
      <SocketsProvider>
        <AppContainer />
      </SocketsProvider>
    </Provider>

  );
};

export default App;
