import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setShowAllLocations } from '../../../../../actions/count';
import GreenSwitch from '../../../../../components/ui/Switches/GreenSwitch';

export default () => {
  const { t } = useTranslation(['recounts', 'general']);
  const dispatch = useDispatch();
  const {
    showAllLocations, filters, loadingProducts, loadingLocations,
  } = useSelector((state: any) => state.count);
  const enabled = (filters.branch_id && !loadingProducts && !loadingLocations);

  const handleSwitchChange = (event) => {
    dispatch(setShowAllLocations(event.target.checked));
  };

  return (
    <GreenSwitch
      checked={showAllLocations}
      handleChange={handleSwitchChange}
      label={t('recounts:show_all_locations')}
      disabled={!enabled}
    />
  );
};
