import AuthorizedApiClient from '../../core/common/api/AuthorizedApiClient';
import { types } from './types';

export const setBranch = branch_id => ({
  type: types.SET_BRANCH,
  branch_id,
});

export const openWarning = warning_message => ({
  type: types.OPEN_WARNING_MESSAGE,
  warning_message,
});

export const resetSales = () => ({
  type: types.RESET_SALES,
});

export const getSales = (companyId, params = {}) => async (
  dispatch: any,
) => {
  const sg = new AuthorizedApiClient();
  const path = `companies/${companyId}/sales`;
  dispatch({ type: types.SET_SALES_PROCESSING, payload: true });
  try {
    const response = await sg.get(params, path);

    if (response != null) {
      dispatch({ type: types.SET_SALES_SUCCESS, response: response.data });
    }
  } catch (error) {
    dispatch({ type: types.SET_SALES_FAILURE, error });
  }
};

// export const getBranchesForPos = (companyId, params = {}) => async (
//   dispatch: any
// ) => {
//   let sg = new AuthorizedApiClient();
//   let path = `companies/${companyId}/branches`;

//   try {
//     let response = await sg.get(params, path);

//     if (response != null) {
//       dispatch({
//         type: types.SET_BRANCHES_POS_SUCCESS,
//         response: response.data,
//       });
//     }
//   } catch (error) {
//     dispatch({ type: types.SET_BRANCHES_POS_FAILURE, error: error });
//   }
// };
