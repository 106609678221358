/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import RegisterForm from './RegisterForm';
import TopBar from '../../../../components/sections/Register/TopBar';
import RegisterBanner from '../../../../components/sections/Register/RegisterBanner';
import ShapeLeft from '../../../../components/sections/Register/ShapeLeft';
import ShapeRight from '../../../../components/sections/Register/ShapeRight';
import Box from '../../../../components/ui/Layout/Box';
import Grid from '../../../../components/ui/Layout/Grid';

const Register = () => {
  const { loggedIn } = useSelector((state: any) => state.session);

  return (
    <>
      {loggedIn ? (
        <Redirect to="/" />
      ) : (
        <Box className="root-register">
          <TopBar />
          <RegisterBanner />
          <div className="wrapper-register ">
            <ShapeLeft className="shape1" />
            <ShapeLeft className="shape2" color="#FFB901" />
            <ShapeRight className="shape3" color="#E94436" />
            <ShapeRight className="shape4" color="#7DBB02" />
            <Box
              mt={12}
              mx="auto"
              maxWidth="80%"
              width={600}
              className="register-forms"
              pt={3}
              pb={3}
              style={{ zIndex: 111, position: 'relative' }}
            >
              <Grid
                container
                justifyContent="center"
                direction="row"
                alignItems="center"
              >
                <RegisterForm />
              </Grid>
            </Box>
          </div>
        </Box>
      )}
    </>
  );
};

export default Register;
