/* eslint-disable prefer-const */
/* eslint-disable max-len */
import { types } from './types';
import Log from '../../util/Log';
import AuthorizedApiClient from '../../core/common/api/AuthorizedApiClient';

export const editModifierGroup = (modififierGroup = null) => ({
  type: types.EDIT_MODIFIER_GROUP,
  payload: modififierGroup,
});

export const savingModifierGroup = () => ({
  type: types.SAVING_MODIFIER_GROUP,
});

export const saveModifierGroupSucceed = response => ({
  type: types.SAVE_MODIFIER_GROUP_SUCCESS,
  response,
});

export const saveModifierGroupFailed = error => ({
  type: types.SAVE_MODIFIER_GROUP_FAILURE,
  error,
});

export const setModifiers = modifiers => ({
  type: types.SET_MODIFIERS,
  modifiers,
});

export const setAssociatedProducts = associated_products => ({
  type: types.SET_ASSOCIATED_PRODUCTS,
  associated_products,
});

export const deletingModifierGroup = () => ({
  type: types.DELETING_MODIFIER_GROUP,
});

export const deleteModifierGroupSucceed = response => ({
  type: types.DELETE_MODIFIER_GROUP_SUCCESS,
  response,
});

export const deleteModifierGroupFailed = error => ({
  type: types.DELETE_MODIFIER_GROUP_FAILURE,
  error,
});

export const closeModalModifierGroup = () => ({
  type: types.CLOSE_MODIFIER_GROUP_MODAL,
});

export const getModifierGroup = (id, companyId) => async (
  dispatch: any,
) => {
  const sg = new AuthorizedApiClient();
  const path = `companies/${companyId}/modifiers_groups/${id}`;
  try {
    const response = await sg.get({ embed: 'variants,modifiers' }, path);

    if (response != null) {
      dispatch({ type: types.SET_MODIFIER_GROUP_SUCCESS, modifierGroup: response.data.modifier_group });
    }
  } catch (error) {
    dispatch({ type: types.SET_MODIFIER_GROUP_FAILURE, erro: error });
  }
};

export const saveModifierGroup = (_data, companyId) => async (dispatch: any) => {
  dispatch(savingModifierGroup());
  const sg = new AuthorizedApiClient();
  let path = `companies/${companyId}/modifiers_groups`;

  try {
    let response;
    let data;
    const { id } = _data;

    data = _data;

    if (id != null) {
      path = `${path}/${id}`;
      delete data.id;
      response = await sg.put(data, path);
    } else {
      delete data.id;
      response = await sg.post(data, path);
    }

    if (response != null) {
      Log.debug('saved modifierGroup', response);
      dispatch(saveModifierGroupSucceed(response));
      dispatch(closeModalModifierGroup());
    }
  } catch (error) {
    Log.error(error.message);
    dispatch(saveModifierGroupFailed(error.response.data));
  }
};

export const deleteModifierGroup = (branch, companyId) => async (dispatch: any) => {
  dispatch(deletingModifierGroup());
  const sg = new AuthorizedApiClient();
  let path = `companies/${companyId}/modifiers_groups`;
  try {
    if (!branch.id) {
      throw new Error('The id is required');
    }

    path = `${path}/${branch.id}`;
    const response = await sg.delete(path);

    if (response != null) {
      Log.debug('deleted modifier group', response);
      dispatch(deleteModifierGroupSucceed(response));
      dispatch(closeModalModifierGroup());
    }
  } catch (error) {
    Log.error(error.message);
    dispatch(deleteModifierGroupFailed(error.response.data));
  }
};
