import React from 'react';
import { Paper, Typography, Box } from '@mui/material';
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Line,
  ResponsiveContainer,
} from 'recharts';
import { useTranslation } from 'react-i18next';

export const OrdersBarChart = () => {
  const { t } = useTranslation('general');
  return (
    <Paper style={{ padding: '2rem' }}>
      <Typography variant="h6" style={{ marginBottom: '1.5rem' }}>
        {t('routes.main_graphic_description')}
      </Typography>
      <Box height={400}>
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart
            width={720}
            height={381} // 400 - 19 from labels
            data={[
              {
                in_time: 576,
                date: 'Sep 22',
                out_time: 200,
                no_delivery: 40,
              },
              {
                in_time: 224,
                date: 'Sep 22',
                out_time: 120,
                no_delivery: 20,
              },
              {
                in_time: 632,
                date: 'Sep 22',
                out_time: 180,
                no_delivery: 32,
              },
            ]}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend wrapperStyle={{ position: 'relative' }} />
            <Bar dataKey="in_time" name={t('routes.finals_orders_statuses.in_time')} fill="#82ca9d" />
            <Bar dataKey="out_time" name={t('routes.finals_orders_statuses.out_time')} fill="#8884d8" />
            <Line
              type="monotone"
              dataKey="no_delivery"
              name={t('routes.finals_orders_statuses.no_delivery')}
              stroke="#C47D7D"
            />
          </ComposedChart>
        </ResponsiveContainer>
      </Box>
    </Paper>
  );
};
