/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-empty */
/* eslint-disable consistent-return */
// import Log from '../../util/Log';
import { types } from './types';

import LocalKeyGateway from '../../core/gateways/local/LocalKeyGateway';
import ApiUserGateway from '../../core/gateways/api/ApiUserGateway';
import AuthorizedApiClient from '../../core/common/api/AuthorizedApiClient';

export const setSession = (data: any) => ({
  type: types.SET_SESSION,
  data,
});

export const setUserSession = (user: any) => ({
  type: types.SET_USER_SESSION,
  user,
});

export const getUser = () => async (dispatch: any) => {
  const upg = ApiUserGateway.getInstance();
  try {
    const userData = await upg.getUser();

    if (userData !== null) {
      let permissions = {};
      let roles = [];

      const selectedCompany = userData.companies[0].id;

      const sg = new AuthorizedApiClient();
      const path = `companies/${selectedCompany}`;

      const response = await sg.get({}, path);

      if (response) {
        userData.companies[0] = response.data.company;
      }

      const usersPath = `companies/${selectedCompany}/users`;
      const { data: usersInfoData } = await sg.get({}, usersPath);

      if (usersInfoData) {
        userData.companies[0].numberOfUsers = usersInfoData.total;
      }

      if (
        userData.permissions
        && userData.permissions.pos
        && userData.permissions.pos[selectedCompany]
      ) {
        permissions = userData.permissions.pos[selectedCompany];
      }

      if (userData.pos_roles && userData.pos_roles[selectedCompany]) {
        roles = userData.pos_roles[selectedCompany];
      }
      userData.roles = roles;
      userData.permissions = permissions;

      dispatch(setUserSession(userData));
      return true;
    }
    dispatch(clearSession());
  } catch (e) {}
};

export const clearSession = () => ({
  type: types.CLEAR_SESSION,
});

export const checkStoredUser = () => async (dispatch: any) => {
  const klg = LocalKeyGateway.getInstance();
  const upg = ApiUserGateway.getInstance();

  try {
    const data = klg.getToken();

    if (data != null) {
      dispatch(setSession(data));
      AuthorizedApiClient.setToken(data.access_token);

      const userData = await upg.getUser();

      if (userData !== null) {
        let permissions = {};
        let roles = [];

        const selectedCompany = userData.companies[0].id;

        const sg = new AuthorizedApiClient();

        const posUserPath = `companies/${selectedCompany}/users`;
        const { data } = await sg.get({ filters: { user_id: userData.id } }, posUserPath);

        if (data) {
          userData.status = data.data[0].status;
        }

        const path = `companies/${selectedCompany}`;
        const response = await sg.get({ embed: 'billing_info,categories' }, path);

        const planningData = response.data.company.billing_info;
        if (response) {
          userData.companies[0] = response.data.company;
        }

        const usersPath = `companies/${selectedCompany}/users`;
        const { data: usersInfoData } = await sg.get({}, usersPath);

        if (usersInfoData) {
          userData.companies[0].numberOfUsers = usersInfoData.total;
        }

        if (
          userData.permissions
          && userData.permissions.pos
          && userData.permissions.pos[selectedCompany]
        ) {
          permissions = userData.permissions.pos[selectedCompany];
        }

        if (userData.pos_roles && userData.pos_roles[selectedCompany]) {
          roles = userData.pos_roles[selectedCompany];
        }
        userData.roles = roles;
        userData.permissions = permissions;
        const session = {
          token: data,
          user: userData,
          planning: planningData,
        };

        dispatch(setSession(session));
        return true;
      }
      dispatch(clearSession());
    }
  } catch (error) {
    dispatch(clearSession());
  }
};

export const disableUserStatus = () => ({
  type: types.DISABLE_USER_STATUS,
});

export const enableUserStatus = () => ({
  type: types.ENABLE_USER_STATUS,
});
