/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { ChangeEvent, useState } from 'react';
import {
  Grid, TextField,
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';

const styles = makeStyles(() => ({
  container: {
    width: '100%',
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  spanBtn: {
    fontSize: '1.5em',
    fontWeight: 'bold',
    color: 'grey',
    cursor: 'pointer',
  },
}));

const CTextField = withStyles(() => ({
  root: {
    '& .MuiOutlinedInput-input': {
      padding: '.33em',
      textAlign: 'center',
    },
    '& .MuiInputdefaultValue-formControl': {
      position: 'absolute',
    },
    '& .MuiOutlinedInput-root': {
      width: '80%',
      fontSize: '1em',
      margin: 'auto',
      position: 'relative',
      boxSizing: 'border-box',
    },
  },
}))(TextField);

interface AddRemoveProps {
  value: number;
  add: Function;
  remove: Function;
  min?: number;
  max?: number;
  onValueChange?: Function;
  fontSize?: number
}

const AddRemove = ({
  value, min = 0, max = 99, add, remove, onValueChange, fontSize,
}: AddRemoveProps) => {
  const classes = styles();
  const [canAdd, setCanAdd] = useState<boolean>(() => true);
  const [canRemove, setCanRemove] = useState<boolean>(() => false);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const regex = /^([a-z0-9]){0,10}$/i;
    if (Number.parseInt(e.target.value, 10) > max) {
      return alert(`El valor maximo para este campo es ${max}`);
    }
    if (Number.parseInt(e.target.value, 10) < min) {
      return alert(`El valor minimo para este campo es ${min}`);
    }
    if (e.target.value === '' || regex.test(e.target.value)) {
      if (e.target.value === '') {
        onValueChange(min);
      } else {
        onValueChange(Number.parseInt(e.target.value, 10));
      }
    }
  };

  const handleAdd = () => {
    if (value >= max) {
      alert('¡Vaya!, parece que te haz acabado el stock de la tienda.');
      return setCanAdd(false);
    }
    setCanRemove(true);
    return add();
  };

  const handleRemove = () => {
    if (value <= min) {
      alert('¡Vaya!, parece que deberías agregar algo más.');
      return setCanRemove(false);
    }
    setCanAdd(true);
    return remove();
  };

  return (
    <Grid className={classes.container} style={{ fontSize: fontSize && fontSize }}>
      {
                canRemove
                && (
                <span className={classes.spanBtn} onClick={handleRemove}>
                  -
                </span>
                )
            }
      <CTextField
        id="outlined-basic"
        variant="outlined"
        style={{ alignSelf: 'center' }}
        value={value}
        onChange={handleChange}
        disabled={!onValueChange}
      />
      {
                canAdd
                && (
                <span className={classes.spanBtn} onClick={handleAdd}>
                  +
                </span>
                )
            }
    </Grid>
  );
};

export default AddRemove;
