/* eslint-disable react/prop-types */
import React from 'react';
import {
  FormControlLabel,
  Switch,
} from '@mui/material';
import { withStyles } from '@mui/styles';

const CSwitch = withStyles({
  switchBase: {
    // color: "#009688",
    '&$checked': {
      color: '#009688',
    },
    // "&$checked + $track": {
    //   backgroundColor: "#009688",
    // },
  },
  checked: {},
  // track: {
  //   backgroundColor: "#009688",
  // },
})(Switch);

export default ({
  label, checked, handleChange, disabled = false, labelPlacement = null,
}) => {
  const pl = labelPlacement || 'start';
  return (
    <FormControlLabel
      control={<CSwitch checked={checked} onChange={handleChange} />}
      label={label}
      labelPlacement={pl}
      disabled={disabled}
    />
  );
};
