/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { withStyles } from '@mui/styles';
import TextField from '../../../../../../../components/ui/TextField';

const CTextField = withStyles(() => ({
  root: {
    '& .MuiOutlinedInput-root': {
      padding: 0,
      fontSize: 12,
      width: '60px',
    },
    '& .MuiOutlinedInput-input': {
      padding: '5px 0',
      textAlign: 'center',
    },
    padding: 0,
  },
}))(TextField);

const ModifierListInput = ({
  onChange,
  defaultValue = '',
  disabled = false,
}) => {
  const [val, setVal] = useState(defaultValue);
  useEffect(() => {
    setVal(defaultValue);
  }, [defaultValue]);
  const _onChange = (event) => {
    setVal(event.target.value);
    onChange(event);
  };
  return (
    <CTextField
      type="number"
      onChange={_onChange}
      value={val || ''}
      disabled={disabled}
      InputProps={{ inputProps: { min: 0 } }}
    />
  );
};

export default ModifierListInput;
