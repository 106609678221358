/* eslint-disable import/no-cycle */
import React from 'react';
import TextField from '../TextField';
import PasswordField from '../PasswordField';
import PhoneField from '../PhoneField';
import { rules as validationRules } from '../../../util/Validations';
import { FormInputProps } from '.';
import Box from '../Layout/Box';

interface FormInputPropsRegister extends FormInputProps {
  register;
}

export default (props: FormInputPropsRegister) => {
  const {
    register,
    defaultValue,
    error,
    name,
    type,
    label,
    className,
    maxLength,
    labelWidth,
    rows,
    onChange,
    // selectOptions,
    // labelId,
    min,
    ...rest
  } = props;

  // const classes = useStyles();

  let inputType;
  const maxlength = maxLength || validationRules.maxLength;

  switch (type) {
    case 'textarea':
      inputType = (
        <TextField
          maxLength={maxlength}
          label={label}
          multiline
          minRows={rows != null ? rows : 4}
          register={register}
          defaultValue={defaultValue || ''}
          error={!!error}
          helperText={error !== undefined ? error.message : null}
          onChange={onChange}
        />
      );
      break;
    case 'password':
      inputType = (
        <PasswordField
          register={register}
          // name={name}
          maxLength={maxlength}
          label={label}
          labelWidth={labelWidth}
          defaultValue={defaultValue || ''}
          error={error}
          onChange={onChange}
        />
      );
      break;

    case 'phone':
      inputType = (
        <PhoneField
          label={label}
          error={error}
          maxLength={maxlength}
          register={register}
          defaultValue={defaultValue || ''}
          onChange={onChange}
        />
      );
      break;

    case 'number':
      inputType = (
        <TextField
          maxLength={maxlength}
          label={label}
          register={register}
          defaultValue={defaultValue !== null && defaultValue !== undefined ? defaultValue : ''}
          error={!!error}
          helperText={error !== undefined ? error.message : null}
          onChange={onChange}
          type={type}
          className={className}
          min={min}
          InputProps={{ inputProps: { min: min || 0 } }}
        />
      );
      break;

    default:
      inputType = (
        <TextField
          {...rest}
          register={register}
          maxLength={maxlength}
          label={label}
          inputRef={register}
          // name={name}
          defaultValue={defaultValue || ''}
          error={!!error}
          helperText={error !== undefined ? error.message : null}
          onChange={onChange}
          type={type}
          className={className}
          InputProps={{ inputProps: { maxLength: maxlength } }}
        />
      );
  }

  return <Box width="100%">{inputType}</Box>;
};
