/* eslint-disable consistent-return */
/* eslint-disable no-restricted-syntax */
import '@formatjs/intl-locale/polyfill';
import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/locale-data/es'; // locale-data for es
import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/locale-data/es'; //

const DATE_UNITS: Array<[string, number]> = [
  ['day', 86400],
  ['hour', 3600],
  ['minute', 60],
  ['second', 1],
];

const datetypes: Intl.RelativeTimeFormatUnit[] = [
  'day',
  'hour',
  'minute',
  'second',
];

const getDateDiff = (timestampDate: number, comparedDate = null) => {
  const now = comparedDate || Date.now();
  const elapsed = (timestampDate - now) / 1000;

  for (const [unit, secondsInUnit] of DATE_UNITS) {
    if (Math.abs(elapsed) > secondsInUnit || unit === 'second') {
      const value = Math.round(elapsed / secondsInUnit);
      return { value, unit };
    }
  }
};

export const useTimeAgo = (timestampDate: any, comparedDate = null) => {
  if (!timestampDate || comparedDate) {
    return [''];
  }
  const { value, unit } = comparedDate
    ? getDateDiff(timestampDate, comparedDate)
    : getDateDiff(timestampDate);
  const unitFound = datetypes.find(typeUnit => typeUnit === unit);

  const rtf = new Intl.RelativeTimeFormat('es', {
    style: 'long',
  });
  const agoLower = value ? rtf.format(value, unitFound).toString() : 0;
  const ago = agoLower;

  return [ago];
};
