import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem, Select,
  InputAdornment,
  FormHelperText,
} from '@mui/material';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import React, { useEffect, useState } from 'react';
// import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import useStoreLocations from '../../../hooks/useStoreLocations';
import Text from '../../Text/Text';

interface Props {
  onChange: ({ id, name }: { id: string, name: string }) => void;
  filterIds?: string[];
  error?: boolean;
  helperText?: string;
}

const useStyles = makeStyles(() => ({
  formControl: {
    minWidth: 220,
    width: '100%',
  },
}));

const LocationSelect = ({
  onChange, error, helperText, filterIds = [],
}: Props) => {
  const classes = useStyles();
  // const { t } = useTranslation('general');
  const { data, getLocations, loading } = useStoreLocations({ initialLoad: true });
  const [value, setValue] = useState('');

  const handleChange = (event) => {
    const { id, name } = JSON.parse(event.target.value);
    setValue(event.target.value);
    onChange({ id, name });
  };

  useEffect(() => {
    getLocations();
  }, [getLocations]);

  if (loading) {
    return (
      <FormControl variant="outlined" className={classes.formControl}>
        <CircularProgress size={22} />
      </FormControl>
    );
  }

  const locations = filterIds.length > 0 ? data.filter(({ id }) => !filterIds.includes(id)) : data;

  return locations.length === 0
    ? <Text htmlColor="gray">No hay locaciones para asignar</Text>
    : (

      <FormControl variant="outlined" className={classes.formControl} error={error}>
        <InputLabel id="location-store-select-outlined-label">Sucursal</InputLabel>

        <Select
          variant="standard"
          error={error}
          startAdornment={(
            <InputAdornment position="start">
              <RoomOutlinedIcon htmlColor="gray" />
            </InputAdornment>
          )}
          labelId="location-store-select-outlined-label"
          id="location-store-select-outlined"
          value={value}
          onChange={handleChange}
          label="Sucursal"
        >
          {
            locations.map(({ id, name }) => (
              <MenuItem key={id} value={JSON.stringify({ id, name })}>{name}</MenuItem>
            ))
          }
        </Select>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    );
};

export default LocationSelect;
