/* eslint-disable react/prop-types */
import React from 'react';
import Grid from '../../../../../components/ui/Layout/Grid';
import PaginatedScroll from '../../../../../hoc/PaginatedScroll';
import SuppliersCard from '../SuppliersCard';

export default ({ state, getSuppliers, resetSuppliers }) => {
  const list = state.data.map(supplier => (
    <Grid item xs={12} sm={6} key={supplier.id}>
      <SuppliersCard supplier={supplier} />
    </Grid>
  ));

  return (
    <PaginatedScroll
      hasNextPage={state.response.page < state.response.last_page}
      loadData={params => getSuppliers(params)}
      currentPage={state.response.page}
      resetData={resetSuppliers}
      processing={state.processing}
    >
      <Grid container spacing={3}>
        {list}
      </Grid>
    </PaginatedScroll>
  );
};
