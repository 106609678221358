import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '../../../../components/ui/Layout/Grid';
import ContentHeader from '../../../../components/ui/ContentHeader';
import PageContainer from '../../../../hoc/PageContainer';
import Products from './ProductsFinances';
import Inventorys from './InventaryFinancePage';
import Orders from './OrdersFinancesPage';
import { Tabs } from '../../../../components/ui/Tabs';

const FinancePageView = () => {
  const { t } = useTranslation(['general']);

  const tabs = [
    { index: 0, title: t('general:products'), content: Products },
    { index: 1, title: t('general:inventory'), content: Inventorys },
    { index: 2, title: t('general:orders'), content: Orders },
  ];

  return (
    <Grid container>
      <Grid item xs={12}>
        <ContentHeader
          title={t('general:finances')}
          section="finance"
        />
      </Grid>

      <PageContainer>
        <Tabs tabs={tabs} />
      </PageContainer>
    </Grid>
  );
};

export default FinancePageView;
