/* eslint-disable react/destructuring-assignment */
import React, { FC } from 'react';
import { useForm } from 'react-hook-form';

import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { makeStyles } from '@mui/styles';
import Alert from '../../../../components/ui/notifications/alert';
import Grid from '../../../../components/ui/Layout/Grid';
import { rules as validationRules } from '../../../../util/Validations';
import FormInput from '../../../../components/ui/FormInput';
import Log from '../../../../util/Log';
import SuccessButton from '../../../../components/ui/Buttons/SuccessButton';
import Box from '../../../../components/ui/Layout/Box';

const useStyle = makeStyles(theme => ({
  container: {
    background: '#ffffff 0% 0% no-repeat padding-box',
    boxShadow: theme.shadow.main,
    border: '1px solid #C0C3CA',
    borderRadius: 5,
  },
  mainButton: {
    width: '50%',
    padding: 5,
    margin: '5px auto',
    fontWeight: 'bold',
  },
  link: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  logo: {
    display: 'block',
    height: 50,
    width: '100%',
    backgroundImage: `url(${theme.icons.Logo})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    margin: '30px 0px',
  },
}));

interface ILoginForm {
  onLogin: Function;
  processing: boolean;
  error: string;
}
const LoginForm: FC<ILoginForm> = (props) => {
  // const { handleSubmit,control, errors } = useForm();
  const classes = useStyle();
  const { t } = useTranslation(['validation', 'register', 'general', 'login']);

  const schema = Yup.object({
    email: Yup.string()
      .email(t('validation:invalid_email'))
      .max(validationRules.maxLength)
      .required(
        t('validation:required', {
          input: t('register:email'),
        }),
      ),
    password: Yup.string()
      .max(validationRules.maxLength)
      .required(
        t('validation:required', {
          input: t('register:password'),
        }),
      ),
  });

  const { register, handleSubmit, formState: { errors } } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  // const [validated,setValidated] = useState(false)
  const onSubmit = (data) => {
    // setValidated(true);
    props.onLogin(data);
  };
  Log.debug('errors ', errors);
  return (
    <Grid
      container
      justifyContent="center"
      direction="row"
      alignItems="center"
      style={{ minHeight: '100vh' }}
    >
      <Grid item xs={10} md={5} lg={3} className={classes.container}>
        <Box p={4}>
          <span className={classes.logo} />

          <Typography
            variant="h5"
            align="center"
            style={{ margin: '25px 0px' }}
          >
            {t('general:welcome')}
          </Typography>

          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            {props.error && (
              <div className="">
                <Alert severity="error" message={props.error} />
              </div>
            )}

            <Grid container direction="column" spacing={4}>
              <Grid item>
                <FormInput
                  label={t('register:email')}
                  register={register('email')}
                  error={errors.email}
                  placeholder={t('register:placeholder_email')}
                />
              </Grid>
            </Grid>

            <Grid container direction="column" spacing={4}>
              <Grid item>
                <FormInput
                  label={t('register:password')}
                  register={register('password')}
                  error={errors.password}
                  placeholder={t('register:placeholder_password')}
                  type="password"
                  autoComplete="off"
                />
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="center"
              spacing={4}
              direction="column"
              style={{ margin: '25px 0px', width: '100%' }}
            >
              <SuccessButton
                text={t('general:login')}
                processing={props.processing ? 1 : 0}
                type="submit"
                size="large"
                className={classes.mainButton}
              />
              <Link to="/register" className={classes.mainButton}>
                <Typography align="center" className={classes.link}>
                  {t('general:create_acount')}
                </Typography>
              </Link>
            </Grid>
            <Grid
              container
              justifyContent="center"
              spacing={4}
              width="100%"
              direction="column"
            >
              <Link to="/" className={classes.mainButton}>
                <Typography align="center" className={classes.link}>
                  {t('general:forgot_password')}
                </Typography>
              </Link>

            </Grid>
          </form>
        </Box>
      </Grid>
    </Grid>
  );
};

export default LoginForm;
