import Box from '@mui/material/Box/Box';
import React from 'react';
import PaginatedScroll from '../../../../../../hoc/PaginatedScroll';
import { generateKey } from '../../../../../../util/keys';
import UserListItem from './UserListItem';

interface UsersListProps {
  state: any;
  getUsers: any;
  resetUsers: any;
}
export default ({ state, getUsers, resetUsers }: UsersListProps) => {
  const usersList = state.data.map(user => (
    <UserListItem
      key={generateKey(user.id)}
      user={user}
      resetUsers={resetUsers}
      getUsers={getUsers}
    />
  ));

  return (
    <PaginatedScroll
      hasNextPage={state.response.page < state.response.last_page}
      loadData={params => getUsers(params)}
      currentPage={state.response.page}
      resetData={resetUsers}
      processing={state.processing}
    >
      <Box>{usersList}</Box>
    </PaginatedScroll>
  );
};
