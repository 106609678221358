/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';
import { Dialog, useTheme } from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import { withStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

export interface DialogTitleProps {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default (props) => {
  const theme = useTheme();
  const {
    children, actions, title, open, classes, onClose, ...other
  } = props;
  const fullScreen = props.fullScreen
    ? props.fullScreen
    : useMediaQuery(theme.breakpoints.down('md'));
  const descriptionElementRef = React.useRef<HTMLDivElement>(null);
  return (
    <Dialog
      {...other}
      onClose={onClose}
      open={open}
      fullScreen={fullScreen}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      {title && (
        // <DialogTitle id="scroll-dialog-title" onClose={onClose}>
        //   {title}
        // </DialogTitle>
        <MuiDialogTitle className={classes?.root}>
          {/* id="scroll-dialog-title" onClose={onClose} */}
          <Typography variant="h6" component="span">{title}</Typography>
          {onClose ? (
            <IconButton
              aria-label="close"
              className={classes?.closeButton}
              onClick={onClose}
              size="large"
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </MuiDialogTitle>
      )}

      <DialogContent dividers ref={descriptionElementRef} tabIndex={-1}>
        {children}
      </DialogContent>
      {actions != null && <DialogActions>{actions}</DialogActions>}
    </Dialog>
  );
};
