/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/prop-types */
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import Box from '../../../../../components/ui/Layout/Box';
import Alert from '../../../../../components/ui/notifications/alert';
import Modal from '../../../../../components/ui/Modals/Modal';
import Actions from './Actions';

import { useSession } from '../../../../../util/session';
import {
  resetProcess,
  saveProductsExcel,
  setValidRows,
} from '../../../../../actions/products_excel';
import Preview from './Preview';
import Files from './Files';
import Results from './Results';
import { cleanKey } from '../../../../../util/Validations';

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up('md')]: {
      width: '750px',
      maxWidth: '100%',
    },
  },
}));

export default ({ showModal, handleClose }) => {
  const { t } = useTranslation(['general']);
  const dispatch = useDispatch();

  const classes = useStyles();

  const {
    processing,
    savedProducts,
    file,
    error,
    validRows,
    units,
  } = useSelector((state: any) => state.products_excel);

  const enabled = validRows.length > 0;

  const { company } = useSession();
  const _saveProducts = useCallback(
    async (companyId, data) => {
      dispatch(saveProductsExcel(data, companyId));
    },
    [dispatch],
  );

  const parseData = useCallback(
    data => data.map((item) => {
      const unitname = item.unit_name;
      const unit = units.find(u => cleanKey(u.code.toString()) === cleanKey(unitname.toString()));
      if (unit) {
        item.unitofmeasure_id = unit.id;
      }
      delete item.unit_name;
      return item;
    }),
    [units],
  );

  const handleSubmit = useCallback(() => {
    const data = parseData(validRows);

    _saveProducts(company.id, data);
  }, [company, _saveProducts, validRows, parseData]);

  const step = savedProducts ? 3 : file !== null ? 2 : 1;

  const _resetProcess = () => {
    dispatch(resetProcess());
  };

  const actions = (
    <Actions
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      step={step}
      enabled={enabled}
      processing={processing}
      resetProcess={_resetProcess}
    />
  );

  useEffect(() => {
    if (error || savedProducts) {
      dispatch(setValidRows([]));
    }
  }, [dispatch, error, savedProducts]);
  return (
    <Modal
      open={showModal}
      onClose={handleClose}
      title={t('general:products_excel:title')}
      actions={actions}
      maxWidth="md"
    >
      <Box p={2} minHeight="300px" className={classes.root}>
        {error && (
          <Box>
            <Alert severity="error" message={error} />
          </Box>
        )}

        <Box id="scroll-dialog-description">
          {step === 3 ? <Results /> : step === 2 ? <Preview /> : <Files />}
        </Box>
      </Box>
    </Modal>
  );
};
