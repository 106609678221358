/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable prefer-destructuring */
import { Hidden } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { HtmlHTMLAttributes, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Box from '../../../../../../../components/ui/Layout/Box';
import { COMPARED_RECOUNT } from '../../../../../../../constants/common';
import { generateKey } from '../../../../../../../util/keys';
import ProductLocationRow from './ProductLocationRow';
import { AutoCompleteOption } from '../../../../../../../components/ui/FormInput/AutoComplete';
import ProductRow from './ProductRow';
import {
  addCountItem,
  removeCountItem,
} from '../../../../../../../actions/count';
import Grid from '../../../../../../../components/ui/Layout/Grid';
import Title from './Title';

interface ProductRowProps extends HtmlHTMLAttributes<any> {
  product: any;
  type: number;
  selectedLocation: AutoCompleteOption | null;
}

const styles = makeStyles(() => ({
  root: {
    border: 'solid 0.5px #639298',
    marginBottom: '1px',
    paddingBottom: '5px',
  },
}));

export default ({ product, type, selectedLocation }: ProductRowProps) => {
  const classes = styles();
  const { t } = useTranslation(['recounts']);
  const [showLocations, setShowLocations] = useState(false);
  const { showAllLocations, branchLocations } = useSelector(
    (state: any) => state.count,
  );
  const list = [];
  const dispatch = useDispatch();
  let stock = null;
  let location = branchLocations[0];
  const defaultVariant = product.variants && product.variants.length > 0
    ? product.variants[0]
    : null;

  const showQuantities = type === COMPARED_RECOUNT;
  const imageUrl = defaultVariant ? defaultVariant.image_url : '';
  let view = !selectedLocation
    && (defaultVariant.inventories.length > 1 || showAllLocations)
    ? 2
    : 1;
  const unit = defaultVariant.unitofmeasure;
  if (defaultVariant.inventories.length) {
    if (selectedLocation) {
      location = {
        id: selectedLocation.value,
        name: selectedLocation.label,
      };
    } else {
      location = branchLocations[0];
    }
    const il = defaultVariant.inventories.find(_i => _i.location_id === location.id);

    if (il) {
      stock = il.stock;
    }
  }

  const handleEditClick = () => {
    setShowLocations(!showLocations);
  };

  const handleCounted = (variantId, location, stock, counted) => {
    if (!counted && counted !== 0) {
      dispatch(
        removeCountItem({
          variant_id: variantId,
          location_id: location.id,
        }),
      );
    } else {
      const discrepancy = counted - stock;

      if (!discrepancy && type === COMPARED_RECOUNT) {
        dispatch(
          removeCountItem({
            variant_id: variantId,
            location_id: location.id,
          }),
        );
      } else {
        dispatch(
          addCountItem({
            variant_id: variantId,
            location_id: location.id,
            location,
            counted_quantity: counted,
            discrepancy,
            stock,
            product,
          }),
        );
      }
    }
  };
  if (showAllLocations) {
    branchLocations.forEach((_location) => {
      const _key = `${product.id}_${_location.id}`;
      let _stock = null;
      const _inventory = defaultVariant.inventories.find(_i => _i.location_id === _location.id);

      if (_inventory) {
        _stock = _inventory.stock;
      }
      list.push(
        <ProductLocationRow
          key={generateKey(_key)}
          product={product}
          variant_id={defaultVariant.id}
          location={_location}
          type={type}
          stock={_stock}
          handleCounted={handleCounted}
        />,
      );
    });
  } else if (defaultVariant !== null && !selectedLocation) {
    defaultVariant.inventories.forEach((inventory) => {
      const _key = `${product.id}_${inventory.id}`;
      const _bl = branchLocations.find(_ib => _ib.id === inventory.location_id);
      if (_bl) {
        list.push(
          <ProductLocationRow
            key={generateKey(_key)}
            product={inventory.product}
            variant_id={defaultVariant.id}
            location={inventory.location}
            type={type}
            stock={inventory.stock}
            handleCounted={handleCounted}
          />,
        );
      }
    });
  }

  if (!list.length) {
    view = 1;
  }

  return (
    <Box className={classes.root}>
      <ProductRow
        product={product}
        imageUrl={imageUrl}
        unit={unit}
        showQuantities={showQuantities}
        handleEditClick={handleEditClick}
        showLocations={showLocations}
        view={view}
        location={location}
        stock={stock}
        defaultVariant={defaultVariant}
        handleCounted={handleCounted}
      />
      {showLocations && (
        <Box pb={2}>
          <hr />
          <Hidden lgUp>
            <Grid container>
              <Grid item xs={12} md={showQuantities ? 4 : 7} />
              <Grid item xs={showQuantities ? 3 : 7} md={2}>
                <Box width={1} display="flex" justifyContent="center">
                  <Title title={t('recounts:location')} />
                </Box>
              </Grid>

              {showQuantities && (
                <Grid item xs={3} md={2}>
                  <Box width={1} display="flex" justifyContent="center">
                    <Title title={t('recounts:available_quantity')} />
                  </Box>
                </Grid>
              )}
              <Grid item xs={showQuantities ? 3 : 5} md={2}>
                <Box width={1} display="flex" justifyContent="center">
                  <Title title={t('recounts:counted_quantity')} />
                </Box>
              </Grid>
              {showQuantities && (
                <Grid item xs={3} md={1}>
                  <Box width={1} display="flex" justifyContent="center">
                    <Title title={t('recounts:discrepancy')} />
                  </Box>
                </Grid>
              )}
            </Grid>
          </Hidden>
          {list}
        </Box>
      )}
    </Box>
  );
};
