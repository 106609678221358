/* eslint-disable react/button-has-type */
/* eslint-disable react/prop-types */
import { makeStyles } from '@mui/styles';
import React from 'react';
import images from '../../../theme/ECTheme/assets/images';

const styles = makeStyles(() => ({
  btnSwitch: {
    backgroundColor: 'transparent',
    border: 'none',
    padding: 0,
    height: '100%',
    margin: 'auto',
    cursor: 'pointer',
  },
  imgBtn: {
    display: 'block',
    width: 25,
    height: 25,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  imgMo: {
    backgroundImage: `url(${images.Icon_Mosaic})`,
  },
  imList: {
    backgroundImage: `url(${images.Icon_List})`,
  },
}));

export default ({ value, onChange }) => {
  const classes = styles();

  return (
    <button onClick={onChange} className={classes.btnSwitch}>
      <span className={`${classes.imgBtn} ${value ? classes.imgMo : classes.imList}`} />
    </button>
  );
};
