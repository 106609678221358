export const BASE_URL = process.env.REACT_APP_URL_API ? process.env.REACT_APP_URL_API : 'https://apiqa.ecubi.net';

export const ECUBI_GA = process.env.ECUBI_GA ? process.env.ECUBI_GA : '';

export const MARKETPLACE_URL = process.env.MARKETPLACE_URL ? process.env.MARKETPLACE_URL : 'marketplaceqa.ecubi.net';

export const GEO_API_KEY = 'AIzaSyAUren7WKQ6-36epIpz85Bcb1uFLHvzMwU';
export const DEFAULT_COUNTRY = 'Mexico';
export const DEFAULT_CITY = 'CDMX';

export const REQUIRE_INVITATION_CODE = true;

export const GOOGLE_PLACES_API_URL = `https://maps.googleapis.com/maps/api/js?key=${GEO_API_KEY}&libraries=places,maps`;

export const STRIPE_KEY = process.env.REACT_STRIPE_KEY ? process.env.REACT_STRIPE_KEY : 'pk_test_5K6M0HkETG1it5yg7zU6EGVP00ll5X4lpH';

export const ENVIRONMENT = process.env.ENVIRONMENT ? process.env.ENVIRONMENT : 'QA_ENVIRONMENT';

export const PUSHER_KEY = 'fb5f360979a1c2d6582d';
