/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/prop-types */
import React from 'react';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';

const useStyle = makeStyles(theme => ({
  container: {
    width: 310,
    height: 130,
    boxShadow: theme.shadow.main,
    margin: '10px 15px',
    flex: '0 0 auto',
    padding: '10px 10px',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      width: 270,
      height: 100,
    },
  },
  tittle: {
    maxWidth: '70%',
    color: theme.colors.home.main,
    margin: 0,
    fontSize: '1.5em',
  },

  sales: {
    background: `url(${theme.icons.money})`,
  },
  moviment: {
    background: `url(${theme.icons.circleArrow})`,
  },
  existence: {
    background: `url(${theme.icons.inventoryReport})`,
  },
  span: {
    display: 'block',
    width: '30%',
    height: '100%',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundOrigin: 'border-box',
    backgroundPosition: 'center',
    margin: 5,
  },
  disable: {
    opacity: 0.2,
  },
}));

const BaseReport = ({
  title, section, link = '', disable = false,
}) => {
  const classes = useStyle();

  return (
    <Link to={disable ? '' : link}>
      <div className={`${classes.container} ${disable ? classes.disable : null}`}>

        <span className={`${classes.span} ${classes[section]}`} />
        <p className={classes.tittle}>
          Reporte de
          {' '}
          <strong>{title}</strong>

        </p>
      </div>
    </Link>
  );
};

export default BaseReport;
