import ApiProductsService from '../../services/api/ApiProductsService';
import ProductsGatewayContract from '../../contracts/gateways/ProductsGatewayContract';
import BaseGateway from './BaseGateway';
import { Product } from '../../contracts/data/Product';

export default class ApiProductsGateway
  extends BaseGateway<Product>
  implements ProductsGatewayContract {
  parseEntity(row: any): Product {
    return row as Product;
  }

  private static instance: ApiProductsGateway = null;

  private constructor() {
    super();
    this.service = new ApiProductsService();
  }

  public static getInstance(): ApiProductsGateway {
    if (ApiProductsGateway.instance === null) {
      ApiProductsGateway.instance = new ApiProductsGateway();
    }
    return ApiProductsGateway.instance;
  }
}
