/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import { makeStyles } from '@mui/styles';
import Box from '../Layout/Box';

const useStyles = makeStyles(theme => ({
  TagLabel: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '2px solid #CCC',
    borderRadius: 20,
    minHeight: 30,
    padding: '0px 5px',
    margin: '5px 10px',
    minWidth: 70,
    cursor: 'pointer',
  },
  tagText: {
    color: theme.colors.grey,
    fontSize: 16,
  },
  deleteIcon: {
    cursor: 'pointer',
  },
  hover: { backgroundColor: '#1A73E8' },
  textHover: { color: '#FFF' },
}));
export default (props) => {
  const classes = useStyles();
  const [selected, setSelected] = useState(false);
  return (
    <Box
      className={`${classes.TagLabel} ${selected ? classes.hover : ''}`}
      display="flex"
      flexDirection="row"
      onMouseEnter={() => {
        setSelected(true);
      }}
      onMouseLeave={() => {
        setSelected(false);
      }}
    >
      <Box
        className={`${classes.tagText} ${selected ? classes.textHover : ''}`}
      >
        {props.label}
      </Box>

      <Box
        display="flex"
        flexDirection="col"
        align="center"
        alignItems="center"
      >
        <ClearIcon
          onClick={props.handleDelete}
          className={`${classes.deleteIcon} ${classes.tagText} ${selected ? classes.textHover : ''}`}
        />
      </Box>
    </Box>
  );
};
