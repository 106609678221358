/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/prop-types */
import React from 'react';
import { makeStyles } from '@mui/styles';
import { useDispatch } from 'react-redux';
import Grid from '../../../../../components/ui/Layout/Grid';
import Box from '../../../../../components/ui/Layout/Box';
import Stock from './Stock';
import Price from './Price';
import ProductName from './ProductName';
import { editProduct } from '../../../../../actions/product';
import { useSession } from '../../../../../util/session';
import { RESOURCES } from '../../../../../constants/common';
import { generateRamdomNumber } from '../../../../../util/keys';

const styles = makeStyles(theme => ({
  Card: {
    width: '100%',
    paddingTop: '100%',
    position: 'relative',
    cursor: 'pointer',
  },
  innerCard: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  Image: {
    backgroundColor: theme.colors.inventory.light,
    backgroundRepeat: 'no-repeat',
    width: '100%',
    height: '100%',
    backgroundSize: '100%',
  },
}));

const ProductImage = ({ url }) => {
  const classes = styles();
  let style = null;
  if (url) {
    style = { backgroundImage: `url(${url}?v=${generateRamdomNumber()})` };
  }
  return (
    <div style={style} className={classes.Image} />
  );
};

export default ({ product }) => {
  const classes = styles();
  const { can } = useSession();
  const dispatch = useDispatch();
  const showDetails = (product) => {
    dispatch(editProduct(product));
  };

  const defaultVariant = product.variants != null && product.variants[0] ? product.variants[0] : null;

  return (
    <Box
      className={classes.Card}
      borderRadius="16px"
      boxShadow={3}
      onClick={() => showDetails(product)}
    >
      <Box display="flex" className={classes.innerCard} flexDirection="column">
        <Box flexGrow={8} p={0} m={0}>
          <ProductImage url={defaultVariant != null ? defaultVariant.image_url : ''} />
        </Box>

        <Box
          p={0}
          m={0}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          alignSelf="flex-center"
          flexGrow={0.5}
        >
          <ProductName name={product.name} />
        </Box>

        <Box p={0} m={0} flexGrow={0.5}>
          <Grid container alignItems="flex-end">
            <Grid container item xs={6} alignItems="flex-end">
              {can(RESOURCES.stock) && <Stock product={product} />}

            </Grid>
            <Grid container item xs={6} alignItems="flex-end">
              <Price variants={product.variants} />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};
