import { types } from '../../actions/notification/types';

const initialState = {
  trialModal: false,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case types.OPEN_TRIAL_MODAL:
      return {
        ...state,
        trialModal: true,
      };
    case types.CLOSE_TRIAL_MODAL:
      return {
        ...state,
        trialModal: false,
      };

    default:
      return state;
  }
};
