/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import moment from 'moment';
import 'moment/locale/es';

moment.locale('es');

export const dateToUtc = date => moment.utc(date).format();

export const getUTCDayStart = (_date) => {
  const date = moment(_date).startOf('day').toDate();
  return dateToUtc(date);
};

export const getUTCDayEnd = (_date) => {
  const date = moment(_date).endOf('day').toDate();
  return dateToUtc(date);
};

export const getUTCTodayStart = () => {
  const date = moment().startOf('day').toDate();
  return dateToUtc(date);
};

export const getUTCTodayEnd = () => {
  const date = moment().endOf('day').toDate();
  return dateToUtc(date);
};

export const formatUTC = (date:Date) => moment(date).utcOffset(0).add(moment().utcOffset(), 'minutes').format();

export const getFormattedToday = () => moment().utc().format('YYYY-MM-DD');

export const getFormattedDate = (date, format = 'YYYY-MM-DD') => {
  const _date = moment(date);
  moment.locale('es');
  return _date.format(format);
};
export const getFormattedHour = (date, format = 'h:mm') => moment(date).format(format);

export const getTicketExpirationTime = () => moment().add(30, 'minutes');

export const hasBeenPassed = (date, seconds = 1800) => {
  const currentTime = moment();
  return currentTime.diff(date, 'seconds') > seconds;
};

export const isValidDate = d => d instanceof Date && !Number.isNaN(d.getTime());
