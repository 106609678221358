/* eslint-disable react/prop-types */
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearWizard, saveWizard } from '../../actions/wizard';
import { useSession } from '../../util/session';
import WizardView from './WizardView';

const Wizard = ({ onFinish }) => {
  const state = useSelector((states: any) => states.wizard);
  const { finish, saving, saved } = state;
  const { company } = useSession();
  const dispatch = useDispatch();
  const attemptSaveWizard = useCallback(async () => {
    if (company) {
      const data = {
        categories: state.categories ? state.categories : null,
        business_type: state.business_type ? state.business_type : null,
        keywords: state.keywords ? state.keywords : null,
        can_bill: state.invoice ? state.invoice : null,
        production_volume: state.production_volume
          ? state.production_volume
          : null,
        deliveries_ranges: state.deliveries_ranges
          ? state.deliveries_ranges
          : null,
        customer_types: state.customer_types ? state.customer_types : null,
        setup: true,
      };
      const { branch } = state;
      await dispatch(saveWizard(company.id, data, branch));
    }
  }, [state, company, dispatch]);

  useEffect(() => {
    if (!saving && finish === true) {
      attemptSaveWizard();
    }
  }, [finish, attemptSaveWizard, saving]);

  useEffect(() => {
    if (saved === true && onFinish) {
      dispatch(clearWizard());
      onFinish();
    }
  }, [saved, onFinish, dispatch]);
  return <WizardView />;
};

export default Wizard;
