/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/dot-notation */
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Text from '../../Text/Text';

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: props => (props['balanceType'] === 'available' ? theme.colors.finance.light : theme.colors.inventory.light),
    border: `1px solid ${theme.colors.grey}`,
    borderRadius: 5,
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '18rem',
    boxSizing: 'border-box',
  },
  textColor: {
    color: props => (props['balanceType'] === 'available' ? theme.colors.finance.main : theme.colors.inventory.main),

  },
}));

export const BalancePaymentCard = ({ balanceInfo, balanceType }) => {
  const classes = useStyles({ balanceType });
  const { t } = useTranslation(['general']);

  return (
    <div className={classes.container}>
      <div className={classes.textColor}>
        {' '}
        <Text>
          {' '}
          {t(balanceType)}
          {' '}
        </Text>
        {' '}
      </div>
      <div className={classes.textColor}>
        {' '}
        <Text variant="h4">
          {' '}
          $
          {balanceInfo.formatted_amount}
        </Text>
        {' '}
      </div>
    </div>
  );
};
