/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/prop-types */
import {
  Chip,
} from '@mui/material';
import React, { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import ReceiptIcon from '@mui/icons-material/Receipt';
import Axios from 'axios';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import Box from '../Layout/Box';
import Text from '../../Text/Text';
import { ModalCategories } from '../../ModalCategories';
import { BASE_URL } from '../../../constants/api/ApiConstants';
import { ModalCanBill } from '../../ModalCanBill';
import images from '../../../theme/ECTheme/assets/images';

const useStyles = makeStyles(() => ({
  containerSection: {
    minWidth: '15rem',
  },
  dividerButton: {
    height: 20,
    borderRadius: 10,
    marginLeft: 10,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',

  },
  containerCards: {
    display: 'flex',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
    marginBottom: 15,
  },
  chipSpace: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    marginBottom: 10,
  },
}));

const productionVolume = {
  small: 'Pequeño',
};
const customerType = {
  final_consumer: 'Consumidor final',
  retail: 'Minorista',
};

export const StoreOptionscard = ({ company }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [openModalBilling, setOpenModalBilling] = React.useState(false);
  const [billing, setBilling] = useState(company.can_bill ? 'true' : 'false');
  const [billingRender, setBillingRender] = useState(company.can_bill ? 'true' : 'false');

  const [categoriesSelect, setCategoriesSelect] = React.useState<string[]>(
    company.categories
      ?.map(category => JSON.stringify({ name: category.name, id: category.id })) || [],
  );
  const [categoriesRender, setCategoriesRender] = React.useState<string[]>(
    company.categories
      ?.map(category => JSON.stringify({ name: category.name, id: category.id })) || [],
  );

  const handleChangeEventBill = (event: React.ChangeEvent<{ value: unknown }>) => {
    setBilling(event.target.value as string);
  };
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setCategoriesSelect(event.target.value as string[]);
  };

  const handleOpen = () => setOpen(true);

  const handleOpenModalBilling = () => setOpenModalBilling(true);

  const handleClick = () => {
    // console.info('You clicked the Chip.');
  };

  const handleChangeCat = (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    const statusCategories = {
      categories: (categoriesSelect as any).map(cat => JSON.parse(cat).id),
    };
    Axios.put(`${BASE_URL}/api/v1/companies/${company.id}`, statusCategories)
      .then(() => {
        setCategoriesRender(categoriesSelect);
        setOpen(false);
      })
      .catch(err => console.error(err))
      .finally(() => {
      });
  };

  const handleChangeBilling = (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    const can_bill = billing === 'true';
    Axios.put(`${BASE_URL}/api/v1/companies/${company.id}`, { can_bill })
      .then(() => {
        setBillingRender(billing);
        setOpenModalBilling(false);
      })
      .catch(err => console.error(err))
      .finally(() => {

      });
  };

  return (
    <Box mt={4}>
      <Box className={classes.containerSection}>
        <div className={classes.container}>
          <div style={{ margin: '10px' }}>
            <Text><b>Opciones generales</b></Text>
          </div>
          <div style={{ display: 'flex', marginBottom: '10px' }}>
            <div style={{ marginRight: '5px' }}>
              <Chip avatar={<ReceiptIcon fontSize="small" />} label={billingRender === 'true' ? 'sí' : 'No'} onClick={handleOpenModalBilling} variant="outlined" />
            </div>
            <div style={{ marginRight: '5px' }}>
              <Chip avatar={<img src={images.Icon_storeProfile} alt="Icon_storeProfile" />} label={productionVolume[company.production_volume]} onClick={handleClick} variant="outlined" />
            </div>
          </div>
          <div style={{ margin: '10px' }}>
            <Text><b>Categoría general</b></Text>
          </div>
          <div style={{ display: 'flex', marginBottom: '10px' }}>
            <div>
              <Chip label={t(`general:${company.categories[0]?.business_type}`)} onClick={handleClick} variant="outlined" />
            </div>
          </div>
          <div style={{ margin: '10px' }}>
            <Text><b>Subcategoría</b></Text>
          </div>
          <div className={classes.chipSpace}>
            {categoriesRender.map(subCategory => (
              <Chip style={{ marginBottom: 10 }} key={JSON.parse(subCategory).id} label={JSON.parse(subCategory).name} onClick={handleClick} variant="outlined" />
            ))}
          </div>
          <div style={{ marginTop: '5px', marginBottom: '10px' }}>
            <Chip avatar={<AddIcon fontSize="small" />} label="Añadir" onClick={handleOpen} variant="outlined" />
          </div>

          <div style={{ margin: '10px' }}>
            <Text><b>Tipo de cliente buscado</b></Text>
          </div>
          <div style={{ display: 'flex', marginBottom: '10px' }}>
            {company.customer_types.map(customer => (
              <div style={{ marginRight: '5px' }}>
                <Chip key={customer} label={customerType[customer]} onClick={handleClick} variant="outlined" />
              </div>
            ))}
          </div>
        </div>
      </Box>
      <ModalCategories
        onSubmit={handleChangeCat}
        open={open}
        setOpen={setOpen}
        company={company}
        defaultValue={categoriesSelect}
        onChange={handleChange}
      />
      <ModalCanBill
        value={billing}
        onSubmit={handleChangeBilling}
        open={openModalBilling}
        setOpen={setOpenModalBilling}
        company={company}
        onChange={handleChangeEventBill}
      />
    </Box>
  );
};
