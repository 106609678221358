/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Box from '../../../../../components/ui/Layout/Box';
// import ConfirmDialog from "../../../../../components/ui/ConfirmDialog";
import Grid from '../../../../../components/ui/Layout/Grid';
import Alert from '../../../../../components/ui/notifications/alert';
import { rules as validationRules } from '../../../../../util/Validations';
import Actions from '../../../../../components/ui/Modals/Actions/Actions';
import Modal from '../../../../../components/ui/Modals/Modal';
import Form from './Form';
import { Tabs } from '../../../../../components/ui/Tabs';
import ConfirmDialog from '../../../../../components/ui/ConfirmDialog';

export default ({
  formData,
  attemptSaveCustomer,
  attemptDeleteCustomer,
  processing,
  deletingCustomer,
  error,
  handleClose,
  showModal,
  // setFormData,
  tab,
  setTab,
}) => {
  const [showConfirm, setShowConfirm] = useState(false);

  const { t } = useTranslation(['customers', 'translation', 'general']);

  const isNew = !formData.id;

  const schema = Yup.object({
    name: Yup.string()
      .max(validationRules.maxLength)
      .required(
        t('translation:required', {
          input: t('customers:name'),
        }),
      ),
    last_name: Yup.string()
      .max(validationRules.maxLength)
      .required(
        t('translation:required', {
          input: t('customers:last_name'),
        }),
      ),

    phone: Yup.string().max(validationRules.maxLength),
    cellphone: Yup.string().max(validationRules.maxLength).min(validationRules.ladaKey, 'requiere clave LADA. ejem: 52'),
    address: Yup.string().max(validationRules.maxLength),
    comments: Yup.string().max(validationRules.maxLength),
    business_name: Yup.string().max(validationRules.maxLength),
    rfc: Yup.string().max(validationRules.maxLength),
    curp: Yup.string().max(validationRules.maxLength),
    billing_address: Yup.string().max(validationRules.maxLength),
    no_ext: Yup.string().max(validationRules.maxLength),
    no_int: Yup.string().max(validationRules.maxLength),
    billing_phone: Yup.string().max(validationRules.maxLength),
    email: Yup.string()
      .email(t('validation:invalid_email'))
      .max(validationRules.maxLength),
    billing_email: Yup.string()
      .email(t('validation:invalid_email'))
      .max(validationRules.maxLength),
  });
  const methods = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    methods.reset(formData);
  }, [formData]);

  const onSubmit = (data) => {
    data.id = formData.id ? formData.id : null;
    attemptSaveCustomer(data);
  };

  const actions = (
    <Actions
      handleClose={handleClose}
      handleSubmit={methods.handleSubmit(onSubmit)}
      handleDelete={() => setShowConfirm(true)}
      isNew={isNew}
      processing={processing}
      deleting={deletingCustomer}
    />
  );

  const title = formData.id == null
    ? t('customers:create_customer')
    : t('general:edit_customer', { name: formData.name });

  const basicTab = (
    <Form tab={tab} />
  );
  const billingTab = (
    <Form tab={tab} />
  );

  const tabs = [
    {
      index: 0,
      title: t('general:basic'),
      content: basicTab,
    },
    { index: 1, title: t('general:billing'), content: billingTab },
  ];

  const handleTabChanged = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <Modal
      open={showModal}
      onClose={handleClose}
      title={title}
      actions={actions}
      maxWidth="xs"
    >
      <Box p={2} minHeight="600px">
        <ConfirmDialog
          open={showConfirm}
          handleCancel={() => {
            setShowConfirm(false);
          }}
          handleConfirm={() => {
            setShowConfirm(false);
            attemptDeleteCustomer(formData);
          }}
          title={t('customers:delete_customer')}
          content={t('customers:delete_confirmation_msg')}
        />
        {error && (
          <Grid container>
            <Alert severity="error" message={error} />
          </Grid>
        )}
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)} autoComplete="off">
            <Box id="scroll-dialog-description">
              <Tabs tabs={tabs} onTabChanged={handleTabChanged} />
            </Box>
          </form>
        </FormProvider>
      </Box>
    </Modal>
  );
};
