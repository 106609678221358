import { types } from './types';

export const setOpen = (open: boolean = false) => ({
  type: types.SET_OPEN,
  payload: open,
});

export const setOpenPerfile = (open: boolean = false) => ({
  type: types.SET_PERFILE_OPEN,
  payload: open,
});
