/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl, FormHelperText } from '@mui/material';
import Box from '../../../../../../../components/ui/Layout/Box';
import SectionTitle from '../../SectionTitle';
import SectionText from '../../SectionText';
import ProductsList from './ProductsList';
import LinkButton from '../../../../../../../components/ui/Buttons/LinkButton';
import ModalProducts from '../../../../../../../components/ModalProducts';
import { useCampaignWizardContext } from '../context/CampaignWizardContext';
import { CampaignWizardStep } from '../constants/campaign_wizard';

enum ProductsListError {
  NO_PRODUCTS = 'no_products',
  THERE_ARE_INVALID_PRODUCTS = 'invalid_products',
}

const ProductsSection = () => {
  const { state, setSelectedProducts, setStepToSave } = useCampaignWizardContext();

  // const { dispatch } = useCampaignWizardDispatch();
  const { stepToSave } = state;
  const [products, setProducts] = useState(state.campaign.products);
  const [error, setError] = useState(null);
  const [showProductsList, setShowProductsList] = useState(false);
  const { t } = useTranslation(['general']);

  // console.log("products", products)

  const handleSelected = useCallback(
    (selected) => {
      const newSelected = [];
      selected.forEach((product) => {
        const current = products.find(p => p.id === product.id);

        if (!current) {
          newSelected.push(product);
        }
      });
      setError(null);
      setProducts([...products, ...newSelected]);
      setShowProductsList(false);
    },
    [products],
  );

  const handleDeleteProduct = useCallback(
    (id) => {
      setProducts(
        products.filter(p => p.id !== id),
      );
    },
    [products],
  );

  const handleProductPrice = useCallback(
    (id, price) => {
      setProducts(
        products.map((product) => {
          if (product.id === id) {
            product.campaign_price = price;
          }

          return product;
        }),
      );
    },
    [products],
  );

  const handleProductMaxQuantity = useCallback(
    (id, quantity) => {
      setProducts(
        products.map((modifier) => {
          if (modifier.id === id) {
            modifier.max_quantity = quantity;
          }

          return modifier;
        }),
      );
    },
    [products],
  );

  const _validateProduct = useCallback(product => (
    !product.campaign_price
      || product.campaign_price >= product.variants[0].price
      || !product.max_quantity
  ), []);

  const _setProducts = useCallback(() => {
    if (products.length) {
      const invalids = products.filter(product => _validateProduct(product));

      if (invalids.length > 0) {
        setError(ProductsListError.THERE_ARE_INVALID_PRODUCTS);
      } else {
        setSelectedProducts(products);
      }
    } else {
      setError(ProductsListError.NO_PRODUCTS);
    }
  }, [products, _validateProduct, setSelectedProducts]);

  useEffect(() => {
    if (stepToSave === CampaignWizardStep.PRODUCTS) {
      setStepToSave(null);
      _setProducts();
    }
  }, [stepToSave, _setProducts, setStepToSave]);

  return (
    <Box mt={5} pb={5}>
      <SectionTitle title={t('general:shared_campaigns:products_title')} />

      <Box width={1} mt={2} minHeight="200px">
        <Box width={1}>
          <ProductsList
            products={products}
            handleDeleteProduct={handleDeleteProduct}
            handleProductPrice={handleProductPrice}
            handleProductMaxQuantity={handleProductMaxQuantity}
          />
          {error && (
            <FormControl fullWidth variant="filled" error>
              <FormHelperText>
                {t(`general:shared_campaigns:products_errors:${error}`)}
              </FormHelperText>
            </FormControl>
          )}

          <Box width={1} display="flex" justifyContent="flex-end">
            <LinkButton
              onClick={() => {
                setShowProductsList(!showProductsList);
              }}
            >
              {t('general:shared_campaigns:add_products')}
            </LinkButton>
          </Box>
        </Box>
      </Box>

      <Box mt={3}>
        <SectionText text={t('general:shared_campaigns:products_msg1')} />
        <SectionText text={t('general:shared_campaigns:products_msg2')} />
      </Box>

      {showProductsList && (
        <ModalProducts handleSelected={handleSelected} listType={1} />
      )}
    </Box>
  );
};

export default ProductsSection;
