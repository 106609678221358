import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

export default function useDatesForm(defaultValues = {
  start_date: undefined,
  end_date: undefined,
  delivery_date: undefined,
}) {
  const { t } = useTranslation(['general']);

  const schema = Yup.object({
    start_date: Yup.date()
      .required(t('general:shared_campaigns:start_date_error'))
      .typeError(t('general:shared_campaigns:start_date_error')),

    // start_time: Yup.date().required("").typeError(""),

    end_date: Yup.date()
      .required(t('general:shared_campaigns:end_date_error'))
      .typeError(t('general:shared_campaigns:end_date_error'))
      .min(
        Yup.ref('start_date'),
        t('general:shared_campaigns:end_date_min_error'),
      ),

    // end_time: Yup.date().required("").typeError(""),
    delivery_date: Yup.date()
      .required(t('general:shared_campaigns:delivery_date_error'))
      .typeError(t('general:shared_campaigns:delivery_date_error'))
      .min(
        Yup.ref('end_date'),
        t('general:shared_campaigns:delivery_date_min_error'),
      ),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    clearErrors,
    getValues,
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues,
  });

  return {
    register,
    handleSubmit,
    errors,
    control,
    setValue,
    clearError: clearErrors,
    getValues,
  };
}
