/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { editBrand } from '../../../../../actions/brand';
import AutoComplete, {
  AutoCompleteOption,
} from '../../../../../components/ui/FormInput/AutoComplete';
import AuthorizedApiClient from '../../../../../core/common/api/AuthorizedApiClient';
import { useSession } from '../../../../../util/session';
import { PERMISSION, RESOURCES } from '../../../../../constants/common';
import { clearAddedBrand } from '../../../../../actions/product';
import BrandForm from '../../BrandsPage/BrandForm';

export default ({
  handleOnChange, selected, register, error,
}) => {
  const { company } = useSession();
  const [loading, setLoading] = useState(false);
  const [brands, setBrands] = useState([]);
  const dispatch = useDispatch();
  const { t } = useTranslation(['products', 'general']);
  const { can } = useSession();
  const { addedBrand } = useSelector((state: any) => state.product_brand);

  const _getBrands = useCallback(async (companyId, _params: any = {}) => {
    const sg = new AuthorizedApiClient();
    const path = `companies/${companyId}/brands`;
    const params = {
      per_page: 20,
    };

    if (_params.filter) {
      params['filters'] = { name: _params.filter };
    }
    return sg.get(params, path);
  }, []);

  const _loadBrands = useCallback(
    async (_params = {}) => {
      if (company) {
        setLoading(true);
        try {
          const response = await _getBrands(company.id, _params);

          if (response != null) {
            const brands = response.data.data.map(c => ({
              value: c.id,
              label: c.name,
            } as AutoCompleteOption));

            setBrands(brands);

            setLoading(false);
          }
        } catch (error) {
          setBrands([]);
          setLoading(false);
          //   setError(error.response.data);
        }
      }
    },
    [company, _getBrands],
  );
  const _handleCreateBrand = () => {
    dispatch(editBrand());
  };

  const _handleOnChange = useCallback((value) => {
    handleOnChange('selectedBrand', value);
  }, [handleOnChange]);

  useEffect(() => {
    const a = async () => {
      if (addedBrand !== null && !loading) {
        await _loadBrands({ filter: addedBrand.name });
        _handleOnChange({ value: addedBrand.id, label: addedBrand.name });

        dispatch(clearAddedBrand());
      }
    };
    a();
  }, [
    company,
    brands,
    addedBrand,
    _loadBrands,
    loading,
    dispatch,
    _handleOnChange,
  ]);

  return (
    <>
      <AutoComplete
        getData={_loadBrands}
        defaultValue={selected != null ? selected.value : null}
        error={error}
        value={selected}
        handleOnChange={_handleOnChange}
        options={brands}
        loading={loading}
        label={t('general:brandOpc')}
        creatable={can(RESOURCES.brands, PERMISSION.create)}
        handleCreate={_handleCreateBrand}
        createNewLabel={t('products:add_brand')}
      />
      <input
        {...register('brand_id')}
        type="hidden"
        value={selected ? selected.value : ''}
      />

      {can(RESOURCES.brands, PERMISSION.create) && <BrandForm source={2} />}
    </>
  );
};
