/* eslint-disable @typescript-eslint/return-await */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable consistent-return */
import Log from '../../util/Log';
import { types } from './types';
import ApiLoginGateway from '../../core/gateways/api/ApiLoginGateway';
import LocalKeyGateway from '../../core/gateways/local/LocalKeyGateway';
import { setSession, clearSession } from '../session';
import AuthorizedApiClient from '../../core/common/api/AuthorizedApiClient';
import ApiUserGateway from '../../core/gateways/api/ApiUserGateway';

export const setProcessing = (processing: boolean = false) => ({
  type: types.SET_LOGIN_PROCESSING,
  payload: processing,
});

export const login = (username: string, password: string) => async (
  dispatch,
) => {
  const apg = ApiLoginGateway.getInstance();
  const klg = LocalKeyGateway.getInstance();
  const upg = ApiUserGateway.getInstance();

  try {
    const response = await apg.getToken(username, password);

    AuthorizedApiClient.setToken(response.data.access_token);

    klg.saveToken(response.data);
    Log.debug('token data ', response.data);

    const userData = await upg.getUser();

    if (userData !== null) {
      if (!userData.companies) {
        throw new Error('Invalid company');
      }

      let permissions = {};
      let roles = [];

      const selectedCompany = userData.companies[0].id;
      const sg = new AuthorizedApiClient();

      const posUserPath = `companies/${selectedCompany}/users`;
      const { data } = await sg.get({ filters: { user_id: userData.id } }, posUserPath);

      if (data) {
        userData.status = data.status;
      }

      const path = `companies/${selectedCompany}`;
      const response = await sg.get({ embed: 'billing_info,categories' }, path);

      const planningData = response.data.company.billing_info;

      if (response) {
        userData.companies[0] = response.data.company;
      }

      // get number of users of companie
      const usersPath = `companies/${selectedCompany}/users`;
      const { data: usersInfoData } = await sg.get({}, usersPath);

      if (usersInfoData) {
        userData.companies[0].numberOfUsers = usersInfoData.total;
      }

      if (
        userData.permissions
        && userData.permissions.pos
        && userData.permissions.pos[selectedCompany]
      ) {
        permissions = userData.permissions.pos[selectedCompany];
      }

      if (userData.pos_roles && userData.pos_roles[selectedCompany]) {
        roles = userData.pos_roles[selectedCompany];
      }

      userData.roles = roles;
      userData.permissions = permissions;

      const session = {
        token: response.data,
        user: userData,
        planning: planningData,
      };
      dispatch(setSession(session));
      return true;
    }
    dispatch(clearSession());
  } catch (error) {
    Log.debug('Error getting token', error);
    klg.clear();
    dispatch(clearSession());
    throw error;
  }
};

export const logout = () => async (dispatch) => {
  const klg = LocalKeyGateway.getInstance();

  klg.clear();
  return await dispatch(clearSession());
};
