/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import { makeStyles, withStyles } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../components/ui/Button';

const styles = makeStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    color: theme.colors.white,
    borderRadius: 15,
    fontSize: 30,
    width: '100%',
    position: 'relative',
    cursor: 'pointer',
    minWidth: 500,
    [theme.breakpoints.down('md')]: {
      fontSize: 19,
      minWidth: 250,
    },
  },
  price: {
    '&::before': {
      content: "'$'",
    },
  },
  arrow: {
    position: 'absolute',
    display: 'block',
    width: 26,
    height: 26,
    right: 20,
    top: 'calc(50% - 13px)',
    [theme.breakpoints.down('md')]: {
      width: 19,
      height: 19,
      right: 15,
      top: 'calc(50% - 10.5px)',
    },
    '&::after': {
      content: "''",
      display: 'block',
      backgroundColor: theme.colors.white,
      width: '85%',
      height: 5,
      position: 'absolute',
      top: 'calc(25% - 2px)',
      transform: 'rotate(45deg)',
      [theme.breakpoints.down('md')]: {},
    },
    '&::before': {
      content: "''",
      display: 'block',
      backgroundColor: theme.colors.white,
      width: '85%',
      height: 5,
      position: 'absolute',
      top: 'calc(75% - 3px)',
      transform: 'rotate(-45deg)',
      [theme.breakpoints.down('md')]: {},
    },
  },
}));

const PurpleButton = withStyles(theme => ({
  root: {
    backgroundColor: theme.colors.cardPurple.main,
    '&:hover': {
      backgroundColor: theme.colors.cardPurple.main,
    },
  },
}))(Button);

export default (props) => {
  const { step } = props;
  const classes = styles();
  const { t } = useTranslation(['general']);
  const content = () => {
    if (step === 1) {
      return (
        <span style={{ marginRight: 20 }}>
          <span>{props.quantity}</span>
&nbsp;
          {t('general:point_of_sell:products')}
&nbsp;
          <span className={classes.price}>{props.total}</span>
          <span className={classes.arrow} />
        </span>
      );
    }
    return <span>{t('general:point_of_sell:confirm')}</span>;
  };

  return (
    <PurpleButton
      text={content()}
      disabled={props.quantity < 1 || !props.branch_id}
      className={classes.container}
      onClick={() => props.click()}
      processing={props.processing === true ? 1 : 0}
    />
  );
};
