/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import OrderDetailsView from './OrderDetailsView';
import { getOrder } from '../../../../../../actions/order';

export default ({ id }) => {
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const company = useSelector((state: any) => state.session.company);
  const { t } = useTranslation(['general']);

  const _getOrder = useCallback(
    async (id) => {
      if (company) {
        // console.log("get order", id)
        await dispatch(getOrder(id, company.id, { embed: 'user,company' }));
      }
    },
    [dispatch, company],
  );

  const onChangedStatus = useCallback(
    (order) => {
      setMessage(
        t('general:orders_page:changed_status_success_msg', {
          order_number: order.number,
        }),
      );
    },
    [t, setMessage],
  );

  const onStartToChangeStatus = useCallback(
    () => {
      setMessage(null);
    },
    [setMessage],
  );

  useEffect(() => {
    if (company) {
      _getOrder(id);
    }
  }, [id, company, _getOrder]);

  return (
    <OrderDetailsView
      error={error}
      setError={setError}
      message={message}
      onChangedStatus={onChangedStatus}
      onStartToChangeStatus={onStartToChangeStatus}
    />
  );
};
