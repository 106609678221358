/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable react/prop-types */
import Axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { BASE_URL } from '../../../constants/api/ApiConstants';
import PlanSubsContext from './context/PlanSubsContext';
import PlansFormView from './PlansFormView';
import { PlansInfo } from './PlansInfo';

export default ({
  open, setOpen, plan, step,
}) => {
  const [planSub, setPlanSub] = useState<any>({});
  const { companyId } = useSelector((state: any) => state.session);

  const [value, setValue] = useState(null);
  const [planId, setPlanId] = useState(null);
  const [pricePlan, setPricePlan] = useState(null);
  const setId = useState();

  if (plan) {
    setPlanId(plan);
  }

  const getSuscription = useCallback(
    async () => {
      Axios.get(`${BASE_URL}/api/v1/companies/${companyId}/subscriptions/?embed=plan,payment_method`)
        .then((res) => {
          if (res.data.data.data.length > 0) {
            setPlanSub(res.data.data.data[0].plan);
          }
        });
    },
    [companyId],
  );

  useEffect(() => {
    getSuscription();
  }, [getSuscription]);

  return (
    <PlanSubsContext.Provider value={{
      value, setValue, setId, planId, setPlanId, pricePlan, setPricePlan,
    }}
    >
      { planSub.code === 'free' && (
        <PlansInfo />
      )}
      <PlansFormView open={open} setOpen={setOpen} stepSelector={step} />
    </PlanSubsContext.Provider>
  );
};
