/* eslint-disable prefer-const */
/* eslint-disable no-restricted-syntax */
import FormData from 'form-data';
import { types } from './types';
import Log from '../../util/Log';
import AuthorizedApiClient from '../../core/common/api/AuthorizedApiClient';
import { getUser } from '../session';

export const savingProfile = () => ({
  type: types.SAVING_PROFILE,
});

export const clearSaved = () => ({
  type: types.CLEAR_SAVED,
});

export const saveProfileSucceed = response => ({
  type: types.SAVE_PROFILE_SUCCESS,
  response,
});

export const saveProfileFailed = error => ({
  type: types.SAVE_PROFILE_FAILURE,
  error,
});

export const saveProfile = (_data, file) => async (dispatch: any) => {
  dispatch(savingProfile());
  const sg = new AuthorizedApiClient();
  const path = 'user';

  try {
    let response;
    let data;

    if (file !== null) {
      data = new FormData();
      for (const key in _data) {
        if (key !== 'id') {
          data.append(key, _data[key]);
        }
      }
      data.append('image', file);
    } else {
      data = _data;
    }

    delete data.id;
    response = await sg.put(data, path);

    if (response != null) {
      Log.debug('saved profile', response);
      await dispatch(getUser());
      dispatch(saveProfileSucceed(response));
    }
  } catch (error) {
    Log.error(error.message);
    dispatch(saveProfileFailed(error.message));
  }
};
