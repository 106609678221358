/* eslint-disable react/prop-types */
/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import Lottie from 'react-lottie';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import Fail from '../../../../theme/ECTheme/assets/Lotties/paymentFailedError.json';
import Box from '../../../ui/Layout/Box';

const useStyle = makeStyles(() => ({
  imageContainer: {
    marginBottom: 20,

  },
}));

export const FailedPayment = ({ msg }) => {
  const classes = useStyle();
  const defaultOptions = {
    loop: false,
    autoplay: true,
    rendererSettings: {
      preserverAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <h2 style={{ color: 'black' }}>
        PAGO FALLIDO
      </h2>
      <Typography variant="body2" style={{ width: 600, fontSize: '22px', textAlign: 'center' }}>
        ¡Vaya! Parece que algo ha salido mal, vuelve a intentarlo.
      </Typography>

      <Typography
        variant="subtitle1"
        style={{
          width: 500, fontSize: '14px', color: 'red', textAlign: 'center',
        }}
      >
        ERROR:
        {' '}
        {msg}
      </Typography>
      <div style={{ display: 'flex', justifyContent: 'center', width: 600 }}>
        <div className={classes.imageContainer} style={{ width: '55%' }}>
          <Lottie options={{ animationData: Fail, ...defaultOptions }} />
        </div>
      </div>
    </Box>
  );
};
