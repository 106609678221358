/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import {
  Box,
  Grid,
  Typography,
  Button,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import Axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { clearCart } from '../../../../../../actions/cart';
import { BASE_URL } from '../../../../../../constants/api/ApiConstants';
import images from '../../../../../../theme/ECTheme/assets/images';
import { useSession } from '../../../../../../util/session';
import PurpleButton from './PurpleButton';
import { rules as validationRules } from '../../../../../../util/Validations';
import FormInput from '../../../../../../components/ui/FormInput';

const styles = makeStyles(theme => ({
  ticketFormContainer: {
    display: 'flex',
    alignSelf: 'center',
    gap: '15px',
    marginTop: '10px',
  },
  icons: {
    height: '66px',
    width: '66px',
    margin: '15px',
    cursor: 'pointer',
  },
  containerSectionIcon: {
    alignSelf: 'center',
    justifyContent: 'center',
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',

  },
  container: {
    width: '90%',
    height: '90%',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  imgSize: {
    height: '30%',
    width: '100%',
  },
  Image: {
    backgroundRepeat: 'no-repeat',
    height: '100%',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
  },
  imageSms: {
    mask: `url(${theme.icons.mailIcon})`,
    maskSize: '50% auto !important',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  imgSpace: {
    maxWidth: '100%',
    color: 'red',
    maxHeight: '100%',
    borderWidth: 1,
    borderRadius: 5,
  },
  imgContainer: {
    padding: 10,
    alignSelf: 'center',
    border: '3px solid #7A4CE2',
    borderRadius: '50%',
    minWidth: '45px',
    minHeight: '45px',
  },
  customerData: {
    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '300px',
    margin: 15,
    borderRadius: 5,
    height: '0px',
    transition: 'height .2s ease',
    overflow: 'hidden',
  },
  customerDataTransition: {
    padding: 20,
    border: '1px solid #949494',
    height: 500,
  },
  SentButton: {
    display: 'flex',
    backgroundColor: '#E6F4E8',
    border: '1px solid #289B52',
    color: '#289B52',
    borderWidth: 1,
    borderRadius: 5,
    padding: 5,
  },
}));

export default (props) => {
  const classes = styles();
  const dispatch = useDispatch();
  const { company } = useSession();
  const { t } = useTranslation('general');
  const cart = useSelector((state: any) => state.cart);
  const [saleId, setSaleId] = useState('');
  const [customerPhone, setCustomerPhone] = useState('');
  const [smsSend, setSmsSend] = useState(false);
  const [emailSend, setEmailSend] = useState(false);
  const [selectMenu, setSelectMenu] = useState(false);

  const schema = Yup.object({
    email: Yup.string()
      .email(t('validation:invalid_email'))
      .max(validationRules.maxLength),
  });
  const {
    reset, register, handleSubmit, formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
    },
  });

  const MenuSelector = () => {
    setSelectMenu(!selectMenu);
    const userFilter = props.cart.customer?.value !== undefined
      ? props.cart.customers.filter(customer => customer.id === props.cart.customer.value)
      : null;
    reset({
      email: userFilter !== null ? userFilter[0].email : null,
    });
    setCustomerPhone(userFilter !== null ? userFilter[0].cellphone : null);
  };

  const getTicketInfo = useCallback(() => {
    Axios.get(`${BASE_URL}/api/v1/sales`, {
      params: {
        sort: { updated_at: 'desc' },
      },
    })
      .then((res) => {
        setSaleId(res.data.data[0].id);
      });
  }, []);

  useEffect(() => {
    getTicketInfo();
  }, [getTicketInfo]);

  const sendMail = async (data?) => {
    setEmailSend(true);
    try {
      const emailFilter = props.cart.customer?.value !== undefined
        ? props.cart.customers.filter(customer => customer.id === props.cart.customer.value)
        : null;
      const customerEmail = emailFilter !== null ? emailFilter[0].email : data.email;
      Axios.post(`${BASE_URL}/api/v1/companies/${company.id}/sales/${saleId}/send_email`, {
        email: customerEmail,
      });
    } catch (e) {
      console.log(e);
    }
  };

  const sendSms = async () => {
    setSmsSend(true);
    try {
      const phoneFilter = props.cart.customer?.value !== undefined
        ? props.cart.customers.filter(customer => customer.id === props.cart.customer.value)
        : null;
      const customerSMS = phoneFilter !== null ? phoneFilter[0].cellphone : customerPhone;
      Axios.post(`${BASE_URL}/api/v1/companies/${company.id}/sales/${saleId}/send_sms`, {
        cellphone: customerSMS,
      });
    } catch (e) {
      console.log(e);
    }
  };

  const handleNewSaleClick = () => {
    dispatch(clearCart());
  };

  return (
    <Grid className={classes.container}>
      <Box className={classes.imgSize}>
        <div
          style={{ backgroundImage: `url(${images.Icon_Checker})` }}
          className={classes.Image}
        />
      </Box>
      <Typography variant="h3" align="center">
        $
        {props.price}
      </Typography>
      <Typography variant="h6" align="center">
        {t('general:point_of_sell:voucher_success')}
      </Typography>
      <Typography variant="h6" align="center">
        {t('general:point_of_sell:ticket')}
        {cart.ticket !== null ? cart.ticket.number : ''}
      </Typography>
      <Button onClick={MenuSelector}>
        <Typography variant="h6" align="center" style={{ color: '#7A4CE2' }}>Enviar ticket</Typography>
      </Button>
      <div className={`${classes.customerData} ${selectMenu ? classes.customerDataTransition : null}`}>

        Agrega los datos de tu comprador para que pueda recibir su ticket
        <div className={classes.ticketFormContainer}>
          <div style={{
            display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '25px',
          }}
          >
            <div>
              <PhoneInput
                inputProps={{ name: 'Celular' }}
                inputStyle={{ width: '100%' }}
                country="mx"
                placeholder="Celular"
                defaultMask="..........."
                value={customerPhone}
                enableSearch
                onChange={phone => setCustomerPhone(phone)}
              />
            </div>
            <div style={{ width: '100%' }}>
              <FormInput
                register={register('email')}
                error={errors.email}
                label={t('branches:email')}
              />
            </div>
          </div>
          <div style={{
            display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '40px', paddingTop: '12px',
          }}
          >

            <div>
              {
                smsSend
                  ? (
                    <div className={classes.SentButton}>
                      <Typography>
                        enviado
                      </Typography>
                      <CheckIcon />
                    </div>
                  )
                  : (
                    <Button
                      onClick={sendSms}
                      variant="contained"
                      color="primary"
                    >
                      Enviar
                    </Button>
                  )
              }

            </div>
            <div>
              { emailSend
                ? (
                  <div className={classes.SentButton}>
                    <Typography>
                      Enviado
                    </Typography>
                    <CheckIcon />
                  </div>
                )
                : (
                  <Button
                    disabled={emailSend}
                    onClick={handleSubmit(sendMail)}
                    variant="contained"
                    color="primary"
                  >
                    Enviar
                  </Button>
                )}
            </div>
          </div>
        </div>
        {
          smsSend
            ? (
              <Button onClick={() => { setSmsSend(false); }}> Reenviar SMS </Button>
            )
            : null
        }
        {
          emailSend
            ? (
              <Button onClick={() => { setEmailSend(false); }}> Reenviar correo </Button>
            )
            : null
        }
      </div>

      <Grid className={classes.buttons}>
        <PurpleButton title="Nueva venta" handleOnClick={handleNewSaleClick} />
      </Grid>
    </Grid>
  );
};
