import { Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import Box from '../ui/Layout/Box';
import { CustomerType } from '../../constants/common';
import CheckOption from './CheckOption';
import { setCostumerTypes, setWizardStep } from '../../actions/wizard';
import { WizardSteps } from './steps';
import SuccessButton from '../ui/Buttons/SuccessButton';

const useStyle = makeStyles(() => ({
  questionsContainer: {
    width: '70%',
    marginLeft: 'auto',
    marginRight: 'auto',
    minWidth: '220px',
  },
}));

const CustomerTypeSection = () => {
  const classes = useStyle();
  const { t } = useTranslation(['general']);
  const dispatch = useDispatch();
  const [selectedCus, setSelectedCus] = useState([]);

  const handleSelected = (value) => {
    const current = selectedCus.find(c => c.toString() === value.toString());

    if (current) {
      setSelectedCus(selectedCus.filter(c => c.toString() !== value));
    } else {
      setSelectedCus([...selectedCus, value]);
    }
  };

  const handleSubmit = () => {
    dispatch(setCostumerTypes(selectedCus));
    dispatch(setWizardStep(WizardSteps.LOGISTIC));
  };

  const options = Object.values(CustomerType).map(key => (
    <Box key={key} mb={1}>
      <CheckOption
        label={t(`general:costumer_type:name:${key}`)}
        handleOnCLick={() => handleSelected(key)}
        selected={selectedCus.includes(key)}
        complement={t(`general:costumer_type:desc:${key}`)}
      />
    </Box>
  ));

  return (
    <>

      <Box>
        <Typography variant="h6" align="center" color="textSecondary">
          {t('general:wizard_setup:customer_type_question')}
        </Typography>
      </Box>

      <Box mt={2} className={classes.questionsContainer} pt={5}>
        {options}
      </Box>

      <Box mt={2} display="flex" justifyContent="center">
        <SuccessButton
          text={t('general:next')}
          onClick={handleSubmit}
          disabled={!(selectedCus.length > 0)}
        />
      </Box>
    </>
  );
};

export default CustomerTypeSection;
