/* eslint-disable @typescript-eslint/dot-notation */
import { Typography, TypographyProps } from '@mui/material';
import React from 'react';
import { makeStyles } from '@mui/styles';

const styles = makeStyles({
  limitText: {
    lineHeight: 1.1,
    margin: 3,
    display: '-webkit-box',
    WebkitLineClamp: TypoProps => TypoProps['textTruncate'],
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  color: {
    color: TypoProps => TypoProps['htmlColor'],
  },
});

interface TextComponent extends TypographyProps {
  textTruncate?: number
  htmlColor?: string
}

const Text = ({ ...TypoProps }:TextComponent) => {
  const { textTruncate, htmlColor, ...restProps } = TypoProps;
  const classes = styles({ textTruncate, htmlColor });

  return (
    <Typography
      className={`
        ${textTruncate && classes.limitText} 
        ${htmlColor && classes.color}
      `}
      {...restProps}
    />
  );
};

export default Text;
