/* eslint-disable react/prop-types */
import { makeStyles } from '@mui/styles';
import React from 'react';
import Box from '../../../../components/ui/Layout/Box';
// import { useTranslation } from "react-i18next";
import Grid from '../../../../components/ui/Layout/Grid';

const styles = makeStyles(theme => ({
  root: {
    border: 'solid 0.5px #9A9494',
    marginBottom: '1px',
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
    cursor: 'pointer',
  },
}));

export default ({ modifierGroup }) => {
  const classes = styles();
  // const { t } = useTranslation(["general"]);

  return (
    <Grid item xs={12} className={classes.root}>
      <Box p={1}>{modifierGroup.title}</Box>
    </Grid>
  );
};
