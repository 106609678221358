import {
  Chip,
} from '@mui/material';
import Axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { BASE_URL } from '../../../constants/api/ApiConstants';
import images from '../../../theme/ECTheme/assets/images';
import Text from '../../Text/Text';

const useStyles = makeStyles(() => ({
  containerSection: {
    minWidth: '15rem',
  },
  Image: {
    cursor: 'pointer',
    width: '30px',
    height: '20px',
    backgroundSize: '45%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    // backgroundSize: "60%",
    margin: 'auto',
  },
  headerNIcon: {
    display: 'flex',
    marginBottom: 10,
  },
  chipSpace: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    marginBottom: 15,
  },
}));

export const DeliveryZoneConfig = () => {
  const classes = useStyles();
  const [deliveryInfo, setDeliveryInfo] = useState([]);
  const { companyId } = useSelector((state: any) => state.session);

  const getDeliveryData = useCallback(
    async () => {
      Axios.get(`${BASE_URL}/api/v1/companies/${companyId}/deliveryzones`)
        .then((res) => {
          setDeliveryInfo(res.data.data.data);
        });
    },
    [companyId],
  );

  useEffect(() => {
    getDeliveryData();
  }, [getDeliveryData]);

  return (
    <div className={classes.containerSection}>
      <div className={classes.headerNIcon}>
        <div>
          <img className={classes.Image} src={images.Icon_Delivery} alt="Icon_Delivery" />
        </div>
        <div>
          <Text><b>Configura tus zonas de reparto</b></Text>
        </div>
      </div>
      <div className={classes.chipSpace}>
        {
            deliveryInfo.map(deliveryzone => (
              <Link to="/logistic" key={deliveryzone.id} style={{ marginRight: '5px' }}>
                <Chip style={{ cursor: 'pointer', marginBottom: 10 }} label={deliveryzone.name} variant="outlined" />
              </Link>
            ))
          }
      </div>
      <div style={{ marginTop: '5px' }}>
        <Link to="/logistic" style={{ marginRight: '5px' }}>
          <Chip style={{ cursor: 'pointer' }} label="Editar zonas de reparto" variant="outlined" />
        </Link>
      </div>
    </div>
  );
};
