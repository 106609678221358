/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/prop-types */
import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import UnitFormView from './UnitFormView';

import {
  getUnitOfMeasures,
  resetUnitOfMeasures,
} from '../../../../../actions/unitofmeasures';
import { saveUnitOfMeasure, deleteUnitOfMeasure, closeModalUnitOfMeasure } from '../../../../../actions/unitofmeasure';
import { SCROLL_PER_PAGE } from '../../../../../constants/common';
import { types } from '../../../../../actions/unitofmeasure/types';

export default ({ source = 1 }) => {
  const dispatch = useDispatch();
  const { company } = useSelector((state: any) => state.session);
  const {
    unitOfMeasure, error, processing, refresh, deletingUnitOfMeasure,
  } = useSelector((state: any) => state.unitofmeasure);

  const _getUnitOfMeasures = useCallback(() => {
    dispatch(resetUnitOfMeasures());
    dispatch(getUnitOfMeasures(company.id, { page: 1, per_page: SCROLL_PER_PAGE }));
    dispatch({
      type: types.CLEAR_REFRESH,
    });
  }, [dispatch, company]);

  const attemptSaveBrand = async (data) => {
    await dispatch(saveUnitOfMeasure(data, company.id));
  };

  const attemptDeleteBrand = async (category) => {
    await dispatch(deleteUnitOfMeasure(category, company.id));
  };

  useEffect(() => {
    if (refresh && source === 1) {
      _getUnitOfMeasures();
    }
  }, [refresh, _getUnitOfMeasures, source]);

  return (
    <UnitFormView
      unitOfMeasure={unitOfMeasure}
      attemptSaveUnitOfMeasure={data => attemptSaveBrand(data)}
      attemptDeleteUnitOfMeasure={brand => attemptDeleteBrand(brand)}
      processing={processing}
      deletingUnitOfMeasure={deletingUnitOfMeasure}
      error={error}
      closeModalUnitOfMeasure={closeModalUnitOfMeasure}
    />
  );
};
