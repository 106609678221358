/* eslint-disable import/no-cycle */
import React from 'react';
import { Controller } from 'react-hook-form';
import TextField from '../TextField';
import PasswordField from '../PasswordField';
import PhoneField from '../PhoneField';
import { rules as validationRules } from '../../../util/Validations';
import { FormInputProps } from '.';
import Box from '../Layout/Box';

interface FormInputPropsControl extends FormInputProps {
  control;
}

export default (props: FormInputPropsControl) => {
  let inputType;

  const {
    control,
    defaultValue,
    error,
    name,
    type,
    label,
    placeholder,
    autoComplete,
    maxLength,
    labelWidth,
    rows,
    // selectOptions,
    // labelId,
    // onChange,
    ...rest
  } = props;

  const maxlength = maxLength || validationRules.maxLength;
  // const classes = useStyles();

  switch (type) {
    case 'textarea':
      inputType = (
        <TextField
          placeholder={placeholder}
          maxLength={maxlength}
          label={label}
          multiline
          minRows={rows != null ? rows : 4}
          autoComplete={autoComplete}
          type={type}
        />
      );
      break;
    case 'password':
      inputType = (
        <PasswordField
          placeholder={placeholder}
          maxLength={maxlength}
          label={label}
          labelWidth={labelWidth}
          autoComplete={autoComplete}
          type={type}
        />
      );
      break;
    case 'phone':
      inputType = (
        <PhoneField
          placeholder={placeholder}
          label={label}
          defaultValue={defaultValue}
          error={error}
          autoComplete={autoComplete}
          type={type}
        />
      );
      break;
    default:
      inputType = (
        <TextField
          {...rest}
          placeholder={placeholder}
          maxLength={maxlength}
          label={label}
          error={error}
          autoComplete={autoComplete}
          type={type}
        />
      );
  }

  return (
    <Box width="100%">
      <Controller
        render={() => inputType}
        name={name}
        control={control}
        defaultValue={defaultValue || ''}

      />
    </Box>
  );
};
