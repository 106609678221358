/* eslint-disable react/prop-types */
import { Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '../../../../../../../../components/ui/Layout/Box';
import { generateKey } from '../../../../../../../../util/keys';
import Resource from './Resource';

export default ({
  user, code, resources, setPermission,
}) => {
  const { t } = useTranslation(['general']);
  const components = resources.map(resource => (
    <Box key={generateKey(resource.code)} width={1}>
      <Resource
        user={user}
        resource={resource}
        setPermission={setPermission}
      />
    </Box>
  ));
  return (
    <Box ml={3} mr={3}>
      <Typography variant="subtitle2" color="textPrimary">
        {t(`general:${code}`, code)}
      </Typography>
      <Box mb={2} mt={2}>
        {components}
      </Box>
    </Box>
  );
};
