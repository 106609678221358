import { useSession } from '../../../util/session';

const useSignUploadUrl = (section: string) => {
  const { company } = useSession();

  const signUrl = `companies/${company.id}/${section}/upload`;

  return {
    signUrl,
  };
};

export default useSignUploadUrl;
