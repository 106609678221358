import React from 'react';
import { useTranslation } from 'react-i18next';
import SectionListContainer from '../../../../hoc/SectionListContainer';
import Grid from '../../../../components/ui/Layout/Grid';
import PageContainer from '../../../../hoc/PageContainer';
import Box from '../../../../components/ui/Layout/Box';
import AddButton from '../../../../components/ui/Buttons/BlueButton/AddButton';
import SupplierForm from './SupplierForm';
import SuppliersList from './SuppliersList';
import { PERMISSION, RESOURCES } from '../../../../constants/common';
import { useSession } from '../../../../util/session';

type SuppliersProps = {
  openNewSupplier: any;
};
export default ({ openNewSupplier }: SuppliersProps) => {
  const { t } = useTranslation('general');
  const { can } = useSession();
  return (
    <>
      {(can(RESOURCES.suppliers, PERMISSION.create)
        || can(RESOURCES.suppliers, PERMISSION.create)) && <SupplierForm />}

      <Grid container>
        {/* <Grid item xs={12}>
        <ContentHeader
            title={t("general:products")}
            section="products"
            back
          />
        </Grid> */}

        <PageContainer>
          {/* <BreadCrumbs /> */}
          {can(RESOURCES.suppliers, PERMISSION.create) && (
            <Box mt={3}>
              <AddButton onClick={openNewSupplier}>
                {t('general:create_supplier')}
              </AddButton>
            </Box>
          )}

          <SectionListContainer>
            <SuppliersList />
          </SectionListContainer>
        </PageContainer>
      </Grid>
    </>
  );
};
