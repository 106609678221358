/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/prop-types */
import React, { useCallback } from 'react';
import {
  Box,
  InputAdornment,
  TextField,
} from '@mui/material';
import _ from 'lodash';
import { withStyles } from '@mui/styles';

const CTextField = withStyles(() => ({
  root: {
    '& .MuiOutlinedInput-root': {
      padding: 0,
      maxWidth: '150px',
      width: '100%',
      backgroundColor: '#CCC',
    },
    '& .MuiOutlinedInput-input': {
      padding: '10px 0',
      textAlign: 'center',
      marginRight: '20px',
    },
    padding: 0,
  },
}))(TextField);

const GoalsCurrencyInput = ({
  register,
  name,
  error,
  onChange = null,
  defaultValue = '',
  disabled = false,
}) => {
  const delayedOnChange = useCallback(
    _.debounce(q => onChange(q), 500),
    [],
  );
  const _handleOnChange = (e: any) => {
    if (onChange) {
      delayedOnChange(e.target.value);
    }
  };
  return (
    <Box maxWidth="150px">
      <CTextField
        {...register(name)}
        variant="outlined"
        type="number"
        onChange={_handleOnChange}
        defaultValue={defaultValue || ''}
        disabled={disabled}
        InputProps={{
          inputProps: { min: 0 },
          startAdornment: (
            <Box pl={1}>
              <InputAdornment position="start">$</InputAdornment>
            </Box>
          ),
        }}
        error={!!error}
        helperText={error ? error.message : null}
      />
    </Box>
  );
};

export default GoalsCurrencyInput;
