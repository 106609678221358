/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { editUnitOfMeasure } from '../../../../../actions/unitofmeasure';
import AutoComplete, {
  AutoCompleteOption,
} from '../../../../../components/ui/FormInput/AutoComplete';
import AuthorizedApiClient from '../../../../../core/common/api/AuthorizedApiClient';
import { useSession } from '../../../../../util/session';
import { PERMISSION, RESOURCES } from '../../../../../constants/common';
import { clearAddedUnit } from '../../../../../actions/product';
import UnitForm from '../../UnitsPage/UnitForm';

export default ({
  handleOnChange, selected, error,
}) => {
  const { company } = useSession();
  const [loading, setLoading] = useState(false);
  const [units, setUnits] = useState([]);
  const dispatch = useDispatch();
  const { t } = useTranslation(['products']);
  const { can } = useSession();
  const { addedUnit } = useSelector((state: any) => state.product_unit);

  const _getUnits = useCallback(async (companyId, _params: any = {}) => {
    const sg = new AuthorizedApiClient();
    const path = `companies/${companyId}/unitofmeasures`;
    const params = {
      per_page: 20,
    };

    if (_params.filter) {
      params['filters'] = { name: _params.filter };
    }
    return sg.get(params, path);
  }, []);

  const _loadUnits = useCallback(
    async (_params = {}) => {
      if (company) {
        setLoading(true);
        try {
          const response = await _getUnits(company.id, _params);

          if (response != null) {
            const units = response.data.data.map(c => ({
              value: c.id,
              label: c.code,
            } as AutoCompleteOption));

            setUnits(units);

            setLoading(false);
          }
        } catch (error) {
          setUnits([]);
          setLoading(false);
        }
      }
    },
    [company, _getUnits],
  );
  const _handleCreateUnit = () => {
    dispatch(editUnitOfMeasure());
  };

  const _handleOnChange = useCallback((value) => {
    handleOnChange('selectedUnit', value, { shouldValidate: true });
  }, [handleOnChange]);

  useEffect(() => {
    const a = async () => {
      if (addedUnit !== null && !loading) {
        await _loadUnits({ filter: addedUnit.code });

        _handleOnChange({ value: addedUnit.id, label: addedUnit.code });

        dispatch(clearAddedUnit());
      }
    };
    a();
  }, [
    company,
    units,
    addedUnit,
    _loadUnits,
    loading,
    dispatch,
    _handleOnChange,
  ]);

  return (
    <>
      <AutoComplete
        getData={_loadUnits}
        defaultValue={selected != null ? selected.value : null}
        value={selected}
        error={error}
        handleOnChange={_handleOnChange}
        options={units}
        loading={loading}
        label={t('products:unit')}
        creatable={can(RESOURCES.units, PERMISSION.create)}
        handleCreate={_handleCreateUnit}
        createNewLabel={t('products:add_unit')}
      />
      {/* <input
        type="hidden"
        value={selected ? selected.value : ''}
      /> */}

      {can(RESOURCES.units, PERMISSION.create) && <UnitForm source={2} />}
    </>
  );
};
