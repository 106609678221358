import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box, Grid, Tabs,
} from '@mui/material';
import { styled } from '@mui/styles';
import { PERMISSION, RESOURCES } from '../../../../constants/common';
import { useSession } from '../../../../util/session';
import StoreProfilePage from './StoreProfilePage';
import { StoreFinancesPages } from './StoreFinancesPages';
import ContentHeader from '../../../../components/ui/ContentHeader';
import StoreCommentsPage from './StoreCommentsPage';
import CampingsWhitoutBar from './SharedCampaignsPage/CampaignWizard/CampingsWhitoutBar';
import OrderPagesWhitoutBars from './OrdersPage/OrderPagesWhitoutBars';
import { NavTabs } from '../../../../components/navigation/NavTabs/NavTabs';

const CustomeTabs = styled(Tabs)(() => ({
  '& .MuiTabs-indicator': {
    backgroundColor: '#00A0F3',
    color: '#00A0F3',
    top: 0,
  },
  '& .Mui-selected': {
    color: '#00A0F3',
  },
  '& .MuiTab-wrapper': {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  '& .MuiTabs-flexContainer': {
    display: 'flex',
    alignItems: 'center',
  },
}));

export default () => {
  const { t } = useTranslation('general');
  // const classes = useStyles()
  const { can } = useSession();

  const tabs = [];

  if (can(RESOURCES.company, PERMISSION.edit)) {
    tabs.push({
      id: 0,
      title: t('general:store_page:general_info'),
      component: StoreProfilePage,
      icon: 'marketInformation',
      to: 'profile',
    });
  }

  if (can(RESOURCES.sales, PERMISSION.read)) {
    tabs.push({
      id: 1,
      title: t('general:store_page:orders'),
      component: OrderPagesWhitoutBars,
      icon: 'marketSales',
      to: 'orders',
    });
  }

  if (can(RESOURCES.sales, PERMISSION.read)) {
    tabs.push({
      id: 2,
      title: t('general:shared_campaigns:campaigns'),
      component: CampingsWhitoutBar,
      icon: 'sharedCampaigns',
      to: 'campaigns',
    });
  }

  if (can(RESOURCES.comments, PERMISSION.read)) {
    tabs.push({
      id: 3,
      title: t('general:store_page:store_comments'),
      component: StoreCommentsPage,
      icon: 'marketOpinions',
      to: 'store-comments',
    });
  }

  tabs.push({
    id: 4,
    title: t('general:store_page:finances'),
    component: StoreFinancesPages,
    icon: 'marketFinances',
    to: 'finances',
  });

  return (
    <main>
      <Grid item xs={12}>
        <ContentHeader
          title={t('general:store_page:my_store')}
          section="store"
          back
        />
      </Grid>
      <Box mt={3} p={0} pb={12} mx="auto" width="100%" maxWidth={1100}>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12}>
            {/* <MenuTabs
              data={tabs}
              selectColor="#00A0F3"
              colorBar="#00A0F3"
            /> */}
            <NavTabs
              tabs={tabs}
              tabsProps={{
                variant: 'scrollable',
                scrollButtons: 'auto',
                'aria-label': 'scrollable auto tabs example',
              }}
              containerTabsProps={{
                elevation: 0,
                style: {
                  borderTop: '1px solid #00A0F3',
                },
              }}
              themeColor="#00A0F3"
              customTabs={CustomeTabs}
            />
          </Grid>
        </Grid>
      </Box>
    </main>
  );
};
