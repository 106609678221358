/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '../../../../../../../components/ui/Layout/Box';
import SectionTitle from '../../SectionTitle';
import GoalsCurrencyInput from './GoalsCurrencyInput';
import SectionText from '../../SectionText';
import GoalsPercentageInput from './GoalsPercentageInput';
import useGoalsForm from './form_definition';
import { useCampaignWizardContext } from '../context/CampaignWizardContext';
import { CampaignWizardStep } from '../constants/campaign_wizard';

const GoalsSection = () => {
  const { t } = useTranslation(['general']);
  const { state, setGoals, setStepToSave } = useCampaignWizardContext();
  const { stepToSave } = state;
  const {
    register,
    handleSubmit,
    errors,
    getValues,
    setValue,
    clearError,
  } = useGoalsForm(state.campaign);

  const handleGoalAmount = (value) => {
    let minAmount = getValues('min_amount');
    const minPercentage = getValues('min_percentage');
    if (value !== '' && minPercentage !== '') {
      minAmount = (minPercentage * 100) / value;
      setValue('min_amount', minAmount || 0);
      clearError('min_amount');
    }
  };

  const handleminPercentage = (value) => {
    let minAmount = getValues('min_amount');
    const currentGoal = getValues('goal_amount');
    if (value !== '' && currentGoal !== '') {
      minAmount = (currentGoal * value) / 100;
      setValue('min_amount', minAmount || 0);
      clearError('min_amount');
    }
  };

  const handleMinAmount = (value) => {
    let minPercentage = getValues('min_percentage');
    const currentGoal = getValues('goal_amount');
    if (value !== '' && currentGoal !== '') {
      minPercentage = (value * 100) / currentGoal;
      setValue('min_percentage', minPercentage || 0);
      clearError('min_percentage');
    }
  };

  const _saveData = useCallback(
    (data) => {
      setGoals(data);
    },
    [setGoals],
  );

  useEffect(() => {
    if (stepToSave === CampaignWizardStep.GOALS) {
      setStepToSave(null);
      handleSubmit(_saveData)();
    }
  }, [stepToSave, setStepToSave, _saveData, handleSubmit]);

  return (
    <Box mt={5} pb={5}>
      <form autoComplete="off">
        <SectionTitle title={t('general:shared_campaigns:goals_title')} />
        <Box mt={2}>
          <Box mt={2} display="flex" justifyContent="center">
            <GoalsCurrencyInput
              onChange={handleGoalAmount}
              register={register}
              name="goal_amount"
              error={errors.goal_amount}
            />
          </Box>
          <Box mt={3} width="100%">
            <Box width="100%">
              <SectionText
                text={t('general:shared_campaigns:min_percentage_msg')}
                align="center"
              />
            </Box>
            <Box mt={1} width="100%" display="flex" flexDirection="row">
              <Box
                width="50%"
                display="flex"
                justifyContent="flex-end"
                pr={1}
              >
                <GoalsPercentageInput
                  onChange={handleminPercentage}
                  register={register}
                  name="min_percentage"
                  error={errors.min_percentage}
                  defaultValue={state.campaign.min_percentage}
                />
              </Box>
              <Box
                width="50%"
                display="flex"
                justifyContent="flex-start"
                pl={1}
              >
                <GoalsCurrencyInput
                  onChange={handleMinAmount}
                  register={register}
                  name="min_amount"
                  error={errors.min_amount}
                  defaultValue={state.campaign.min_amount}
                />
              </Box>
            </Box>
          </Box>

          <Box mt={4}>
            <SectionText
              text={t('general:shared_campaigns:min_purchase_msg')}
              align="center"
            />
            <Box mt={1} display="flex" justifyContent="center">
              <GoalsCurrencyInput
                register={register}
                name="min_sale"
                error={errors.min_sale}
              />
            </Box>
          </Box>

          <Box mt={4}>
            <SectionText text={t('general:shared_campaigns:goals_msg')} />
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default GoalsSection;
