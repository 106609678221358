import { types } from './types';
import AuthorizedApiClient from '../../core/common/api/AuthorizedApiClient';

export const getSale = (id, companyId) => async (
  dispatch: any,
) => {
  const sg = new AuthorizedApiClient();
  const path = `companies/${companyId}/sales/${id}`;
  dispatch({ type: types.GETTING_SALE });
  try {
    const response = await sg.get({ embed: 'company' }, path);

    if (response != null) {
      dispatch({ type: types.SET_SALE_SUCCESS, sale: response.data.sale });
    }
  } catch (error) {
    dispatch({ type: types.SET_SALE_FAILURE, erro: error });
  }
};
