/* eslint-disable @typescript-eslint/naming-convention */
import { Paper, Typography } from '@mui/material';
import React from 'react';
import Box from '../../../../../components/ui/Layout/Box';
import Grid from '../../../../../components/ui/Layout/Grid';
import { useDashboardContext } from '../context/DashboardContext';
import DateFilter from './DateFilter';

const TitleBar = () => {
  const { state, setStartDate, setEndDate } = useDashboardContext();
  const { start_date } = state;
  const { end_date } = state;
  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  return (
    <Paper>
      <Box p={1}>
        <Grid container>
          <Grid
            container
            item
            xs={12}
            sm={7}
            justifyContent="flex-start"
            alignItems="center"
          >
            <Typography variant="h6">Ecubi Dashboard</Typography>
          </Grid>
          <Grid container item xs={12} sm={5} justifyContent="flex-end">
            <Grid container>
              <Grid item xs={6}>
                <DateFilter
                  date={start_date}
                  label="Desde"
                  handleChange={handleStartDateChange}
                />
              </Grid>

              <Grid item xs={6}>
                <DateFilter
                  date={end_date}
                  label="Hasta"
                  handleChange={handleEndDateChange}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default TitleBar;
