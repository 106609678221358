/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/prop-types */
import { CircularProgress, TextField } from '@mui/material';
import React, { useState } from 'react';
import Autocomplete, {
  createFilterOptions,
} from '@mui/material/Autocomplete';
import { useTranslation } from 'react-i18next';
import Box from '../Layout/Box';

export interface AutoCompleteOption {
  value: string;
  label: string;
}

export default ({
  handleOnChange,
  error = null,
  defaultValue = null,
  value = null,
  getData,
  options = [],
  loading = false,
  label = '',
  addStartLabel = 'Crear',
  creatable = false,
  handleCreate = null,
  createNewLabel = '',
}) => {
  const [filter, setFilter] = useState('');
  const [open, setOpen] = React.useState(false);
  const filtered = createFilterOptions<AutoCompleteOption>();
  const { t } = useTranslation(['general']);
  React.useEffect(() => {
    if (open) {
      const params = {};
      if (filter) {
        params['filter'] = filter;
      }
      getData(params);
    }
  }, [getData, filter, open]);

  const _handleOnChange = (event, value) => {
    if (value && value.value === '-1') {
      const cleanValue = value.label
        .replace(`${addStartLabel} `, '')
        .replace('"', '')
        .replace('"', '')
        .replace(t('general:new'), '');
      handleCreate(cleanValue);
    } else {
      handleOnChange(value);
    }
  };

  return (
    <Box>
      <Autocomplete
        onChange={_handleOnChange}
        inputValue={filter !== '-1' ? filter : null}
        defaultValue={defaultValue || null}
        value={value}
        onInputChange={(event, newInputValue) => {
          setFilter(newInputValue);
        }}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        isOptionEqualToValue={(option, value) => option.label === value.label}
        getOptionLabel={option => option.label || ''}
        options={options}
        loading={loading}
        renderInput={params => (
          <TextField
            error={!!error}
            helperText={error ? error.message : null}
            {...params}
            label={label}
            variant="outlined"
            fullWidth
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        filterOptions={(options, params) => {
          const _filtered = filtered(options, params);
          if (creatable && !loading) {
            _filtered.push({
              value: '-1',
              label: params.inputValue
                ? `${addStartLabel} "${params.inputValue}"`
                : createNewLabel || `${addStartLabel} ${t('general:new')}`,
            });
          }

          return _filtered;
        }}
      />
    </Box>
  );
};
