import React from 'react';
import { makeStyles } from '@mui/styles';
import Drawer from '@mui/material/Drawer';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Icon from '../../../../../components/ui/Icon';

const styles = makeStyles(theme => ({
  drawerPaper: {
    height: 80,
    zIndex: 98,
    overflow: 'visible',
    boxShadow: theme.shadow.main,
    MozBoxShadow: theme.shadow.main,
    WebkitBoxShadow: theme.shadow.main,
  },
  containerAccess: {
    width: '100%',
    height: '100%',
    paddingBottom: 5,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  button: {
    boxShadow: theme.shadow.main,
    border: '5px solid #fff',
    borderRadius: '50%',
    position: 'absolute',
    top: -40,
    width: 70,
    height: 70,
  },
  quickAccessSpace: {
    height: 115, // height that drawerPaper (80)+ icon height/2 (35)
  },
}));

const Navigator = () => {
  const classes = styles();
  const { open } = useSelector((state: any) => state.theme);
  const { t } = useTranslation(['general']);

  return (
    <div>
      <Drawer
        classes={{ paper: `${classes.drawerPaper}` }}
        anchor="bottom"
        elevation={100}
        variant="persistent"
        open={!open}
      >
        <div className={classes.containerAccess}>
          <div className={`${classes.button} point-sale-icon`}>
            <Link to="/pos">
              <Icon
                section="pointSell"
                shadow={false}
              />
            </Link>
          </div>
          <div>
            {t('general:point_of_sell:point_of_sell')}
          </div>
        </div>
      </Drawer>
      <div className={classes.quickAccessSpace} />
    </div>
  );
};

export default Navigator;
