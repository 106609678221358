import { types } from './types';
import AuthorizedApiClient from '../../core/common/api/AuthorizedApiClient';
import fileDownloader from '../../helpers/file-downloader';

const sg = new AuthorizedApiClient();

const EXCEL_FILE_NAME = 'clientes.xlsx';

export const setParams = params => ({
  type: types.SET_CUSTOMERS_PARAMS,
  params,
});

export const resetCustomers = () => ({
  type: types.RESET_CUSTOMERS,
});

const getPath = (companyId: string) => `companies/${companyId}/customers`;

export const getCustomers = (companyId, params = {}) => async (
  dispatch: any,
) => {
  const path = getPath(companyId);

  dispatch({ type: types.SET_CUSTOMERS_PROCESSING, payload: true });
  try {
    const response = await sg.get(params, path);

    if (response != null) {
      dispatch({ type: types.SET_CUSTOMERS_SUCCESS, response: response.data });
    }
  } catch (error) {
    dispatch({ type: types.SET_CUSTOMERS_FAILURE, error });
  }
};

export const downloadCustomers = (companyId, params = {}) => async (
  dispatch: any,
) => {
  const path = getPath(companyId);

  try {
    const fileName = EXCEL_FILE_NAME;

    const response = await sg.downloadFile(params, path);

    fileDownloader.downloadFileInBrowser(response, fileName);
  } catch (error) {
    dispatch({ type: types.SET_CUSTOMERS_FAILURE, erro: error });
  }
};
