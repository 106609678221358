/* eslint-disable react/prop-types */
import React from 'react';
import Moment from 'react-moment';
import Box from '../../../../ui/Layout/Box';

export default ({ value }) => (
  <Box minWidth="100px">
    <Moment format="YYYY-MM-DD HH:mm">{value}</Moment>
  </Box>
);
