import React from 'react';
import useProductsListState from '../hooks/useProductsListState';

interface IContextProps {
  state: any;
  getProducts: Function;
  refreshList: Function;
  setParams: Function;
  resetFilters: Function;
}

export const ProductsListStateContext = React.createContext(
  {} as IContextProps,
);

interface Props {
  children: React.ReactNode;
}

export const ProductsListProvider = ({ children }: Props) => {
  const initialState = useProductsListState();
  return (
    <ProductsListStateContext.Provider value={initialState}>
      {children}
    </ProductsListStateContext.Provider>
  );
};

export const useProductsListContext = () => {
  const context = React.useContext(ProductsListStateContext);
  if (context === undefined) {
    throw new Error(
      'useProductsListState must be used within a ProductsListProvider',
    );
  }
  return context;
};
