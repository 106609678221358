/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Grid from '../../../../../components/ui/Layout/Grid';
import FormInput from '../../../../../components/ui/FormInput';
import ModifierGroupRules from './ModifierGroupRules';
import Box from '../../../../../components/ui/Layout/Box';
import ModifiersList from './ModifiersList';
import AssociatedProducts from './AssociatedProducts';
import ModalProducts from '../../../../../components/ModalProducts';
import LinkButton from '../../../../../components/ui/Buttons/LinkButton';

const RawModifierGroupForm = ({
  modifierGroup,
  errors,
  register,
  handleModifiers = null,
  handleAssociatedProducts = null,
  associateError,
}) => {
  const { t } = useTranslation(['general']);
  const [showProductsList, setShowProductsList] = useState(false);
  const [showModifiersList, setShowModifiersList] = useState(false);

  const currentVariants = modifierGroup.variants;

  const currentModifiers = modifierGroup.modifiers;

  const [products, setProducts] = useState([]);
  const [modifiers, setModifiers] = useState([]);

  const handleSelected = useCallback(
    (selected) => {
      const newSelected = [];
      selected.forEach((product) => {
        const current = products.find(p => p.id === product.id);
        if (!current) {
          newSelected.push(product);
        }
      });
      setProducts([...products, ...newSelected]);
      setShowProductsList(false);
    },
    [products],
  );

  const handleModifiersSelected = useCallback(
    (selected) => {
      const newSelected = [];
      selected.forEach((modifier) => {
        const current = modifiers.find(m => m.id === modifier.id);
        if (!current) {
          newSelected.push(modifier);
        }
      });
      setModifiers([...modifiers, ...newSelected]);
      setShowModifiersList(false);
    },
    [modifiers],
  );

  const handleDeleteProduct = useCallback(
    (id) => {
      setProducts(
        products.filter(p => p.id !== id),
      );
    },
    [products],
  );

  const handleDeleteModifier = useCallback(
    (id) => {
      setModifiers(
        modifiers.filter(p => p.id !== id),
      );
    },
    [modifiers],
  );

  const handleModifierQuantity = useCallback(
    (id, quantity) => {
      setModifiers(
        modifiers.map((modifier) => {
          if (modifier.id === id) {
            modifier.max_permitted = quantity;
          }

          return modifier;
        }),
      );
    },
    [modifiers],
  );

  useEffect(() => {
    if (handleModifiers) {
      handleModifiers(modifiers);
    }
  }, [modifiers, handleModifiers]);

  useEffect(() => {
    if (handleAssociatedProducts) {
      handleAssociatedProducts(products);
    }
  }, [products, handleAssociatedProducts]);

  useEffect(() => {
    if (
      currentModifiers !== undefined
      && currentModifiers.length > 0
      && currentModifiers[0].variant
    ) {
      setModifiers(
        currentModifiers.map((pm) => {
          const item = pm.variant.product;
          if (item) {
            item.custom_price = pm.price ? pm.price : null;
            item.max_permitted = pm.max_permitted ? pm.max_permitted : null;
          }

          return item;
        }),
      );
    } else {
      setModifiers([]);
    }
  }, [currentModifiers]);

  useEffect(() => {
    if (
      currentVariants !== undefined
      && currentVariants.length
      && currentVariants[0].product !== undefined
    ) {
      setProducts(currentVariants.map(variant => variant.product));
    } else {
      setProducts([]);
    }
  }, [currentVariants]);
  return (
    <form autoComplete="off">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormInput
            register={register('title')}
            error={errors.title}
            label={t('general:modifiers_groups:title')}
            defaultValue={modifierGroup.title ? modifierGroup.title : ''}
          />
        </Grid>
        <Grid item xs={12}>
          <Accordion expanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography color="textPrimary" variant="subtitle2">
                {t('general:modifiers_groups:rules')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ModifierGroupRules
                register={register}
                modifierGroup={modifierGroup}
              />
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid item xs={12}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              {associateError?.modifiers && <ErrorOutlineIcon fontSize="small" htmlColor="red" />}
              <Typography style={associateError?.modifiers ? { color: 'red', fontWeight: '400' } : null} color="textPrimary" variant="subtitle2">
                {t('general:modifiers_groups:associated_modifiers')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box width={1}>
                <Box width={1}>
                  <ModifiersList
                    modifiers={modifiers}
                    handleDeleteProduct={handleDeleteModifier}
                    handleModifierQuantity={handleModifierQuantity}
                  />
                </Box>

                <Box width={1} display="flex" justifyContent="flex-end">
                  <LinkButton
                    onClick={() => {
                      setShowModifiersList(!showModifiersList);
                    }}
                  >
                    {t('general:modifiers_groups:add_modifiers')}
                  </LinkButton>
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid item xs={12}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              {associateError?.variants && <ErrorOutlineIcon fontSize="small" htmlColor="red" />}
              <Typography style={associateError?.variants ? { color: 'red', fontWeight: '400' } : null} color="textPrimary" variant="subtitle2">
                {t('general:modifiers_groups:associated_products')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box width={1}>
                <AssociatedProducts
                  products={products}
                  handleDeleteProduct={handleDeleteProduct}
                />
                <Box width={1} display="flex" justifyContent="flex-end">
                  <LinkButton
                    onClick={() => {
                      setShowProductsList(!showProductsList);
                    }}
                  >
                    {t('general:modifiers_groups:add_products')}
                  </LinkButton>
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        </Grid>

        {showProductsList && (
        <ModalProducts handleSelected={handleSelected} listType={1} />
        )}

        {showModifiersList && (
        <ModalProducts
          handleSelected={handleModifiersSelected}
          title={t('general:modifiers_groups:modifiers_list')}
          listType={2}
        />
        )}
      </Grid>
    </form>
  );
};

export default RawModifierGroupForm;
