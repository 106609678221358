/* eslint-disable no-case-declarations */
import { types } from './types';

const PAGINATED_TABLE_PER_PAGE = 5;
export const initialState = {
  response: {
    data: [],
    total: 0,
    per_page: PAGINATED_TABLE_PER_PAGE,
    last_page: 0,
    page: 0,
  },
  processing: true,
  data: [],
  error: null,
  params: {
    page: 1,
    per_page: PAGINATED_TABLE_PER_PAGE,
    filters: {},
  },
  refresh: false,
  selected: [],
  showModal: true,
  addNew: false,
};

export function reducer(state, action) {
  switch (action.type) {
    case types.SET_PRODUCTS_LIST_COMPONENT_PROCESSING:
      return {
        ...state,
        processing: action.payload,
      };

    case types.SET_PRODUCTS_LIST_COMPONENT_SUCCESS:
      return {
        ...state,
        response: action.response,
        data: [...state.data, ...action.response.data],
        error: null,
        processing: false,
      };
    case types.SET_PRODUCTS_LIST_COMPONENT_FAILURE:
      return {
        ...state,
        error: action.error,
        processing: false,
      };

    case types.CLEAR_PRODUCTS_LIST_COMPONENT_REFRESH:
      return {
        ...state,
        refresh: false,
      };

    case types.SET_PRODUCTS_LIST_COMPONENT_PARAMS:
      return {
        ...state,
        params: action.params,
      };

    case types.PRODUCTS_LIST_COMPONENT_ADD_SELECTED_PRODUCTS:
      return {
        ...state,
        selected: [...state.selected, ...action.products],
      };

    case types.PRODUCTS_LIST_COMPONENT_REMOVE_SELECTED_PRODUCTS:
      const productsIds = action.products.map(p => p.id);
      return {
        ...state,
        selected: state.selected.filter(p => !productsIds.includes(p.id)),
      };

    case types.PRODUCTS_LIST_COMPONENT_ADD_NEW:
      return {
        ...state,
        addNew: true,
      };

    case types.PRODUCTS_LIST_COMPONENT_CLOSE_MODALMODIFER:
      return {
        ...state,
        addNew: false,
      };

    case types.RESET_PRODUCTS_LIST_COMPONENT:
      return initialState;

    default:
      return state;
  }
}
