/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/dot-notation */
import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  IconBackground: {
    width: props => (props['size'] ? props['size'] : '100%'),
    height: props => (props['size'] ? props['size'] : '100%'),
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    opacity: '1',
  },
  shadow: {
    WebkitBoxShadow: theme.shadow.main,
    MozBoxShadow: theme.shadow.main,
    boxShadow: theme.shadow.main,
  },

  homeBackground: {
    backgroundColor: theme.colors.home.light,
  },
  homeIcon: {
    backgroundColor: theme.colors.home.main,
    WebkitMask: `url(${theme.icons.home}) no-repeat center`,
    mask: `url(${theme.icons.home}) no-repeat center`,
  },

  pointSaleBackground: {
    backgroundColor: theme.colors.pointSale.light,
  },
  pointSaleIcon: {
    backgroundColor: theme.colors.pointSale.main,
    WebkitMask: `url(${theme.icons.pointSale}) no-repeat center`,
    mask: `url(${theme.icons.pointSale}) no-repeat center`,
  },

  reportsBackground: {
    backgroundColor: theme.colors.reports.light,
  },
  reportsIcon: {
    backgroundColor: theme.colors.reports.main,
    WebkitMask: `url(${theme.icons.reports}) no-repeat center`,
    mask: `url(${theme.icons.reports}) no-repeat center`,
  },

  financeBackground: {
    backgroundColor: theme.colors.finance.light,
  },
  financeIcon: {
    backgroundColor: theme.colors.finance.main,
    WebkitMask: `url(${theme.icons.finance}) no-repeat center`,
    mask: `url(${theme.icons.finance}) no-repeat center`,
  },

  inventoryBackground: {
    backgroundColor: theme.colors.orange.light,
  },
  inventoryIcon: {
    backgroundColor: theme.colors.orange.main,
    WebkitMask: `url(${theme.icons.inventory}) no-repeat center`,
    mask: `url(${theme.icons.inventory}) no-repeat center`,
  },
  storeBackground: {
    backgroundColor: theme.colors.store.light,
  },
  storeIcon: {
    backgroundColor: theme.colors.store.main,
    WebkitMask: `url(${theme.icons.store}) no-repeat center`,
    mask: `url(${theme.icons.store}) no-repeat center`,
  },

  clientBackground: {
    backgroundColor: theme.colors.clients.light,
  },
  clientIcon: {
    backgroundColor: theme.colors.clients.main,
    WebkitMask: `url(${theme.icons.clients}) no-repeat center`,
    mask: `url(${theme.icons.clients}) no-repeat center`,
  },

  permissionsIcon: {
    backgroundColor: theme.colors.grey,
    WebkitMask: `url(${theme.icons.key}) no-repeat center`,
    mask: `url(${theme.icons.key}) no-repeat center`,
  },

  settingsIcon: {
    backgroundColor: '#737373',
    WebkitMask: `url(${theme.icons.configuration}) no-repeat center`,
    mask: `url(${theme.icons.configuration}) no-repeat center`,
  },

  helpIcon: {
    backgroundColor: theme.colors.home.main,
    WebkitMask: `url(${theme.icons.questionMark}) no-repeat center`,
    mask: `url(${theme.icons.questionMark}) no-repeat center`,
  },
  ordersIcon: {
    backgroundColor: theme.colors.grey,
    WebkitMask: `url(${theme.icons.opinions}) no-repeat center`,
    mask: `url(${theme.icons.opinions}) no-repeat center`,
  },

  productsIcon: {
    WebkitMask: `url(${theme.icons.productsIcon}) no-repeat center`,
    mask: `url(${theme.icons.productsIcon}) no-repeat center`,
    backgroundColor: theme.colors.inventory.main,
  },
  productsBackground: {
    backgroundColor: theme.colors.inventory.light,
  },
  campingIcon: {
    backgroundColor: theme.colors.inventory.main,
    WebkitMask: `url(${theme.icons.campingIcon}) no-repeat center`,
    mask: `url(${theme.icons.campingIcon}) no-repeat center`,
  },
  sharedCampaigns: {
    mask: `url(${theme.icons.sharedCampaigns})`,
    backgroundColor: '#1F7CFF',
    maskSize: '60% auto !important',
  },
  campingsBackground: {
    backgroundColor: '#d4e3f4',
  },
  pickerZones: {
    mask: `url(${theme.icons.pickerZones})`,
    backgroundColor: '#1F7CFF',
    maskSize: '60% auto !important',
  },
  zonesBackground: {
    backgroundColor: '#d4e3f4',
  },
  tikcetBackground: {
    backgroundColor: '#7A4CE2',
  },
  mailIcon: {
    mask: `url(${theme.icons.mailIcon})`,
    backgroundColor: '#7A4CE2',
    maskSize: '60% auto !important',
  },
  smsIcon: {
    mask: `url(${theme.icons.smsIcon})`,
    backgroundColor: '#7A4CE2',
    maskSize: '60% auto !important',
  },
  mailIconReverse: {
    mask: `url(${theme.icons.mailIcon})`,
    backgroundColor: 'white',
    maskSize: '60% auto !important',
  },
  smsIconReverse: {
    mask: `url(${theme.icons.smsIcon})`,
    backgroundColor: 'white',
    maskSize: '60% auto !important',
  },
  smsWRadius: {
    mask: `url(${theme.icons.smsWRadius})`,
    backgroundColor: 'white',
    maskSize: '60% auto !important',
  },
  sizeIcon: {
    width: '100%',
    height: '100%',
    maskSize: '70% auto',
    maskRepeat: 'no-repeat',
    maskOrigin: 'border-box',
    maskPosition: 'center',
  },

}));

interface IconProps {
  section?: string;
  shadow?: boolean;
  background?: boolean;
  size?: number;
}
export default ({
  section, shadow = true, background = true, size,
}: IconProps) => {
  const classes = useStyles({ size });
  const sections = {
    home: {
      background: classes.homeBackground,
      icon: classes.homeIcon,
    },
    pointSell: {
      background: classes.pointSaleBackground,
      icon: classes.pointSaleIcon,
    },
    reports: {
      background: classes.reportsBackground,
      icon: classes.reportsIcon,
    },
    finance: {
      background: classes.financeBackground,
      icon: classes.financeIcon,
    },
    inventory: {
      background: classes.inventoryBackground,
      icon: classes.inventoryIcon,
    },
    store: {
      background: classes.storeBackground,
      icon: classes.storeIcon,
    },
    client: {
      background: classes.clientBackground,
      icon: classes.clientIcon,
    },
    permissions: {
      icon: classes.permissionsIcon,
    },
    settings: {
      icon: classes.settingsIcon,
    },
    help: {
      icon: classes.helpIcon,
    },
    orders: {
      icon: classes.ordersIcon,
    },
    products: {
      icon: classes.productsIcon,
      background: classes.productsBackground,
    },
    storeCamping: {
      icon: classes.sharedCampaigns,
      background: classes.campingsBackground,
    },
    pickerZones: {
      icon: classes.pickerZones,
      background: classes.zonesBackground,
    },
    mailIconReverse: {
      icon: classes.mailIconReverse,
      background: classes.tikcetBackground,
    },
    mailIcon: {
      icon: classes.mailIcon,
      background: 'white',
    },
    smsIcon: {
      icon: classes.smsIcon,
      background: 'white',
    },
    smsIconReverse: {
      icon: classes.smsIconReverse,
      background: classes.tikcetBackground,
    },
    smsWRadius: {
      icon: classes.smsWRadius,
      background: classes.tikcetBackground,
    },
    analytics: {
      icon: classes.smsWRadius,
      background: classes.tikcetBackground,
    },
  };

  const _icon = sections[section] !== undefined ? sections[section].icon : '';
  const _background = background && sections[section] !== undefined
    ? sections[section].background
    : null;
  const _shadow = shadow ? classes.shadow : null;

  return (
    <div className={`${classes.IconBackground} ${_background} ${_shadow}`}>
      <div className={`${classes.sizeIcon} ${_icon}`} />
    </div>
  );
};
