import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '../../../../components/ui/Layout/Grid';
import PageContainer from '../../../../hoc/PageContainer';
import Box from '../../../../components/ui/Layout/Box';
import AddButton from '../../../../components/ui/Buttons/BlueButton/AddButton';
import SectionListContainer from '../../../../hoc/SectionListContainer';
import CustomerForm from './CustomerForm';
import { useSession } from '../../../../util/session';
import { PERMISSION, RESOURCES } from '../../../../constants/common';
import CustomersList from './CustomersList';

interface CustomersPageProps {
  openNewCustomer;
}

export default ({ openNewCustomer }: CustomersPageProps) => {
  const { t } = useTranslation('general');
  const { can } = useSession();

  return (
    <>
      {(can(RESOURCES.customers, PERMISSION.create)
        || can(RESOURCES.customers, PERMISSION.edit)) && <CustomerForm />}

      <Grid container>
        {/* <Grid item xs={12}>
          <ContentHeader title={t("general:clients")} section="client" back />
        </Grid> */}

        <PageContainer>
          {/* <BreadCrumbs /> */}

          <Box mt={3}>
            {can(RESOURCES.customers, PERMISSION.create) && (
              <AddButton onClick={openNewCustomer}>
                {t('general:create_customer')}
              </AddButton>
            )}
          </Box>
          <SectionListContainer>
            <CustomersList />
          </SectionListContainer>
        </PageContainer>
      </Grid>
    </>
  );
};
