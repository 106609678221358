import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { editLocation } from '../../../../actions/location';
import LocationsPageView from './LocationsPageView';

export default () => {
  const dispatch = useDispatch();

  const { refresh } = useSelector((state: any) => state.locations);

  const openNewLocation = () => {
    dispatch(editLocation());
  };

  return (
    <LocationsPageView openNewLocation={openNewLocation} refresh={refresh} />
  );
};
