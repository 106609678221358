/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/prop-types */
import { ListItemText, MenuItem, Select } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CampaignStatus } from '../../../../../../constants/common';

const StatusFilter = ({ onChange, defaultValue = null }) => {
  // console.log("defaultValue", defaultValue)
  const { t } = useTranslation(['general']);
  const allValue = 'both';
  const [value, setValue] = useState(defaultValue || allValue);
  useEffect(() => {
    setValue(defaultValue || allValue);
  }, [defaultValue]);
  return (
    <Select
      variant="standard"
      value={value}
      onChange={(event) => {
        setValue(event.target.value);
        const _value = event.target.value !== allValue ? event.target.value : '';
        onChange(_value);
      }}
    >
      <MenuItem value={allValue}>
        <ListItemText primary={t('general:shared_campaigns:both')} />
      </MenuItem>

      <MenuItem value={CampaignStatus.Active}>
        <ListItemText primary={t('general:shared_campaigns:active')} />
      </MenuItem>

      <MenuItem value={CampaignStatus.Inactive}>
        <ListItemText primary={t('general:shared_campaigns:inactive')} />
      </MenuItem>
    </Select>
  );
};

export default StatusFilter;
