import React from 'react';
import { useTranslation } from 'react-i18next';
import BrandsList from './BrandsList';
import SectionListContainer from '../../../../hoc/SectionListContainer';
import Grid from '../../../../components/ui/Layout/Grid';
import PageContainer from '../../../../hoc/PageContainer';
import Box from '../../../../components/ui/Layout/Box';
import AddButton from '../../../../components/ui/Buttons/BlueButton/AddButton';
import BrandForm from './BrandForm';
import { useSession } from '../../../../util/session';
import { PERMISSION, RESOURCES } from '../../../../constants/common';

type BrandProps = {
  openNewBrand: any;
};
export default ({ openNewBrand }: BrandProps) => {
  const { t } = useTranslation('general');
  const { can } = useSession();
  return (
    <>
      {(can(RESOURCES.brands, PERMISSION.create)
        || can(RESOURCES.brands, PERMISSION.create)) && <BrandForm />}

      <Grid container>
        {/* <Grid item xs={12}>
          <ContentHeader
            title={t("general:products")}
            section="products"
            back
          />
        </Grid> */}

        <PageContainer>
          {/* <BreadCrumbs /> */}
          {can(RESOURCES.brands, PERMISSION.create) && (
            <Box mt={3}>
              <AddButton onClick={openNewBrand}>
                {t('general:create_brand')}
              </AddButton>
            </Box>
          )}

          <SectionListContainer>
            <BrandsList />
          </SectionListContainer>
        </PageContainer>
      </Grid>
    </>
  );
};
