import { ShepherdOptionsWithType } from 'react-shepherd';
import { ECUBI_MARKET_URL } from '../common';

export const dashSteps: ShepherdOptionsWithType[] = [
  {
    id: 'intro',
    buttons: [
      {
        text: 'Saltar recorrido',
        secondary: true,
        action() {
          const actualStepsFinished = JSON.parse(window.localStorage.getItem('finished-tour-steps'));

          window.localStorage.setItem(
            'finished-tour-steps',
            JSON.stringify({
              ...actualStepsFinished,
              dashboardDone: true,
            }),
          );
          this.hide();
        },
      },
      {
        text: 'Siguiente',
        type: 'next',
      },
    ],
    title: '¡Bienvenido a Ecubi mi negocio!',
    text: ['<p>La plataforma que te ayudará con la administración, control y crecimiento de tu negocio.</p> <p>Nos gustaría que conocieras un poco más de nuestra plataforma.</p>'],
  },
  {
    id: 'intro-2',
    attachTo: { element: '.all-modules', on: 'bottom' },
    modalOverlayOpeningRadius: 10,
    buttons: [
      {
        classes: 'shepherd-button-secondary',
        text: 'Atras',
        type: 'back',
      },
      {
        text: 'Siguiente',
        type: 'next',
      },
    ],
    title: 'Módulos',
    text: ['<p>Los módulos son secciones con diferentes funciones para administrar tu negocio</p>'],
    canClickTarget: false,
  },
  {
    id: 'intro-3',
    attachTo: { element: '.first-element', on: 'bottom' },
    modalOverlayOpeningRadius: 40,
    modalOverlayOpeningPadding: 10,
    buttons: [
      {
        classes: 'shepherd-button-secondary',
        text: 'Atras',
        type: 'back',
      },
      {
        text: 'Siguiente',
        type: 'next',
      },
    ],
    title: 'Módulo productos',
    text: ['<p>En esta sección podrás crear, gestionar y ver todos tus productos.</p>'],
    canClickTarget: false,
  },
  {
    id: 'intro-4',
    attachTo: { element: '.second-element', on: 'bottom' },
    modalOverlayOpeningRadius: 40,
    modalOverlayOpeningPadding: 10,
    buttons: [
      {
        classes: 'shepherd-button-secondary',
        text: 'Atras',
        type: 'back',
      },
      {
        text: 'Siguiente',
        type: 'next',
      },
    ],
    title: 'Módulo inventario',
    text: ['<p>Aquí puedes administrar la cantidad de productos que entran y salen de tu negocio.</p>'],
    canClickTarget: false,
  },
  {
    id: 'intro-5',
    attachTo: { element: '.third-element', on: 'bottom' },
    modalOverlayOpeningRadius: 40,
    modalOverlayOpeningPadding: 10,
    buttons: [
      {
        classes: 'shepherd-button-secondary',
        text: 'Atras',
        type: 'back',
      },
      {
        text: 'Siguiente',
        type: 'next',
      },
    ],
    title: 'Módulo Clientes',
    text: ['<p>Registra a tus clientes y guarda sus datos de facturación de ser necesario.</p>'],
    canClickTarget: false,
  },
  {
    id: 'intro-6',
    attachTo: { element: '.fourth-element', on: 'bottom' },
    modalOverlayOpeningRadius: 40,
    modalOverlayOpeningPadding: 10,
    buttons: [
      {
        classes: 'shepherd-button-secondary',
        text: 'Atras',
        type: 'back',
      },
      {
        text: 'Siguiente',
        type: 'next',
      },
    ],
    title: 'Módulo Mi tienda online',
    text: [`<p>Desde aqui podrás administrar toda la informacion de tu tienda publicada en <a target="_blank" href="${ECUBI_MARKET_URL}">Ecubi Marketplace</a>.</p>`],
    canClickTarget: false,
  },
  {
    id: 'intro-7',
    attachTo: { element: '.fifth-element', on: 'bottom' },
    modalOverlayOpeningRadius: 40,
    modalOverlayOpeningPadding: 10,
    buttons: [
      {
        classes: 'shepherd-button-secondary',
        text: 'Atras',
        type: 'back',
      },
      {
        text: 'Siguiente',
        type: 'next',
      },
    ],
    title: 'Módulo Campañas',
    text: ['<p>Administra compras colaborativas para tus clientes.</p>'],
    canClickTarget: false,
  },
  {
    id: 'intro-8',
    attachTo: { element: '.sixth-element', on: 'bottom' },
    modalOverlayOpeningRadius: 40,
    modalOverlayOpeningPadding: 10,
    buttons: [
      {
        classes: 'shepherd-button-secondary',
        text: 'Atras',
        type: 'back',
      },
      {
        text: 'Siguiente',
        type: 'next',
      },
    ],
    title: 'Módulo Logística',
    text: ['<p>Delimita el área donde podrías entregar tu producto.</p>'],
    canClickTarget: false,
  },
  {
    id: 'intro-9',
    attachTo: { element: '.point-sale-icon', on: 'top' },
    modalOverlayOpeningRadius: 42,
    modalOverlayOpeningPadding: 10,
    buttons: [
      {
        classes: 'shepherd-button-secondary',
        text: 'Atras',
        type: 'back',
      },
      {
        text: 'Siguiente',
        type: 'next',
      },
    ],
    title: 'Módulo Punto de venta',
    text: ['<p>Donde podrás registrar todas las ventas que tengas en tu tienda física.</p>'],
    canClickTarget: false,
  },
  {
    id: 'intro-9',
    attachTo: { element: '.hamburger-menu-icon', on: 'right-start' },
    // advanceOn: { selector: '.hamburger-menu-icon', event: 'click' },
    modalOverlayOpeningRadius: 29,
    modalOverlayOpeningPadding: 10,
    title: 'Menú',
    text: ['<p>Puedes acceder a todos los módulos a traves del menu lateral.</p>'],
  },
];
