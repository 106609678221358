/* eslint-disable react/prop-types */
import React from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TextField } from '@mui/material';

export default ({
  label,
  value,
  onChange,
  minDate = null,
  maxDate = null,
  format = 'yyyy/MM/dd',
  reset = false,
}) => (
  <LocalizationProvider dateAdapter={AdapterDateFns}>
    <DatePicker
      showToolbar={false}
      // variant="inline"
      inputFormat={format}
      // autoOk
      value={value && !reset ? value : null}
      maxDate={maxDate}
      minDate={minDate}
      onChange={onChange}
      // KeyboardButtonProps={{
      //   'aria-label': 'change date',
      // }}
      // inputVariant="outlined"
      // style={{ width: '100%' }}
      renderInput={params => (
        <TextField {...params} label={label} />
      )}
    />
  </LocalizationProvider>
);
