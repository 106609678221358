import React from 'react';
import {
  Paper, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';

const useStyle = makeStyles(theme => ({
  title: {
    paddingLeft: theme.spacing(2),
  },
}));

export default () => {
  const { t } = useTranslation(['general']);
  const classes = useStyle();
  return (
    <Paper className={classes.title}>
      <Typography color="secondary">{t('general:today')}</Typography>
    </Paper>
  );
};
