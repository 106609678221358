export const types = {
  SET_USERS_PROCESSING: 'SET_USERS_PROCESSING',
  SET_USERS_SUCCESS: 'SET_USERS_SUCCESS',
  SET_USERS_FAILURE: 'SET_USERS_FAILURE',
  RESET_USERS: 'RESET_USERS',
  SAVE_USER_SUCCESS: 'SAVE_USER_SUCCESS',

  SET_RESOURCES_PROCESSING: 'SET_RESOURCES_PROCESSING',
  SET_RESOURCES_SUCCESS: 'SET_RESOURCES_SUCCESS',
  SET_RESOURCES_FAILURE: 'SET_RESOURCES_FAILURE',
};
