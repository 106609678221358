import React from 'react';

const NotFound = () => (
  <div className="kt-grid kt-grid--ver kt-grid--root">
    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-error-v1">
      <div className="kt-error-v1__container">
        <h1 className="kt-error-v1__number">404</h1>
        <p className="kt-error-v1__desc">Page Not found.</p>
      </div>
    </div>
  </div>
);

export default NotFound;
