/* eslint-disable react/prop-types */
import React from 'react';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { generateRamdomNumber } from '../../../../../util/keys';

const styles = makeStyles(theme => ({
  container: {

    width: '100%',
    height: 98,
    display: 'grid',
    gridAutoFlow: 'row',
    gridTemplateColumns: 'repeat(3,1fr)',
    gridTemplateRows: '35% auto',
    borderRadius: 5,
    boxShadow: theme.shadow.main,
    backgroundColor: theme.colors.white,
    textAlign: 'center',
    cursor: 'pointer',
  },
  img: {
    gridColumn: 3,
    gridRow: '1/3',
    justifySelf: 'center',
    alignSelf: 'center',
    width: 74,
    height: 74,
    borderRadius: '50%',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  tittle: {
    alignSelf: 'end',
    color: theme.colors.black,
    fontsize: 15,
    marginBottom: 6,
  },
  brand: {
    color: theme.colors.black,
    fontsize: 17,
    height: '2.5rem',
    fontWeight: 'bold',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    padding: '0px 6px',
  },
  description: {
    height: '2.5rem',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    padding: '0px 2px',
  },
}));

export default ({ brand }) => {
  const classes = styles();
  const { t } = useTranslation(['general']);

  return (
    <div className={classes.container}>
      <div className={classes.tittle}>{t('general:brand')}</div>
      <div className={classes.tittle}>{t('general:description')}</div>

      <div className={classes.brand}>{brand.name}</div>
      <div className={classes.description}>{brand.description}</div>
      <span
        className={classes.img}
        style={{ backgroundImage: `url(${brand.image_url}?v=${generateRamdomNumber()})` }}
      />
    </div>
  );
};
