export const types = {
  EDIT_BRANCH: 'EDIT_BRANCH',
  CLOSE_BRANCH_MODAL: 'CLOSE_BRANCH_MODAL',
  SAVING_BRANCH: 'SAVING_BRANCH',
  SAVE_BRANCH_SUCCESS: 'SAVE_BRANCH_SUCCESS',
  SAVE_BRANCH_FAILURE: 'SAVE_BRANCH_FAILURE',
  DELETING_BRANCH: 'DELETING_BRANCH',
  DELETE_BRANCH_SUCCESS: 'DELETE_BRANCH_SUCCESS',
  DELETE_BRANCH_FAILURE: 'DELETE_BRANCH_FAILURE',
  CLEAR_REFRESH: 'CLEAR_REFRESH',
};
