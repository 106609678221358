import { types } from '../../actions/order/types';

export const initialState = {
  order: null,
  error: null,

  processing: true,
  deletingProduct: false,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case types.GETTING_ORDER:
      return {
        ...state,
        processing: true,
      };

    case types.SET_ORDER_SUCCESS:
      return {
        ...state,
        order: action.order,
        processing: false,
      };
    case types.SET_ORDER_FAILURE:
      return {
        ...state,
        error: action.error,
        processing: false,
      };

    default:
      return state;
  }
};
