/* eslint-disable react/prop-types */
import {
  Typography,
} from '@mui/material';
import React from 'react';
import { makeStyles, withStyles } from '@mui/styles';
import Box from '../ui/Layout/Box';
import TextField from '../ui/TextField';

const CTextField = withStyles(() => ({
  root: {
    '& .MuiOutlinedInput-root': {
      padding: 0,
      backgroundColor: '#FFF',

      '& fieldset': {
        borderColor: '#A174CB',
      },
      '&:hover fieldset': {
        borderColor: '#A174CB',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#A174CB',
        borderWidth: 1,
      },
    },
  },
}))(TextField);

const useStyle = makeStyles(() => ({
  container: {
    border: '1px solid #C0C3CA',
    borderRadius: '6px',
    cursor: 'pointer',
    // width: "100%",
  },
  text: { width: '100%', fontSize: '1rem' },
  selected: {
    backgroundColor: '#F4E8FC',
    borderColor: '#A174CB',
  },
}));

const CheckOption = ({
  label,
  handleOnCLick,
  selected = false,
  complement = null,
  editable = false,
  handleInputChange = null,
}) => {
  const classes = useStyle();
  return (
    <Box
      className={`${classes.container} ${selected ? classes.selected : ''}`}
      onClick={handleOnCLick}
      p={1}
    >
      <Typography
        variant="body2"
        className={classes.text}
        color="textSecondary"
      >
        {label}
        {complement && (
          <Typography component="span" variant="body2">
            {' '}
            (
            {complement}
            )
          </Typography>
        )}

      </Typography>
      {editable && selected && (
      <CTextField placeholder="Cuál" onChange={handleInputChange} />
      )}
    </Box>
  );
};

export default CheckOption;
