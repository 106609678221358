export const errorParser = (error, str = false) => {
  if (error.response.status !== 500) {
    if (
      typeof error.response.data === 'string'
      || error.response.data instanceof String
    ) {
      return error.response.data;
    } if (error.response.data !== null) {
      return error.response.data;
    }
  }

  return str ? 'server_error' : { unknow: ['Unknow error'] };
};
