/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '../../../../../components/ui/Layout/Box';
import ConfirmDialog from '../../../../../components/ui/ConfirmDialog';
import Grid from '../../../../../components/ui/Layout/Grid';
import Alert from '../../../../../components/ui/notifications/alert';

import Form from './Form';
import { Tabs } from '../../../../../components/ui/Tabs';

export default ({
  supplier,
  attemptDeleteSupplier,
  setShowConfirm,
  showConfirm,
  error,
  setImageKey,
  setImage,
  setData,
}) => {
  const [tab, setTab] = useState(0);

  const { t } = useTranslation(['suppliers', 'translation', 'general']);

  const basicTab = () => (
    <Form
      setData={setData}
      supplier={supplier}
      setImage={setImage}
      setImageKey={setImageKey}
      tab={tab}
    />
  );
  const billingTab = () => (
    <Form
      setData={setData}
      supplier={supplier}
      setImage={setImage}
      setImageKey={setImageKey}
      tab={tab}
    />
  );

  const tabs = [
    {
      index: 0,
      title: t('general:basic'),
      content: basicTab,
    },
    { index: 1, title: t('general:billing'), content: billingTab },
  ];

  const handleTabChanged = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <Box>
      <ConfirmDialog
        open={showConfirm}
        handleCancel={() => {
          setShowConfirm(false);
        }}
        handleConfirm={() => {
          setShowConfirm(false);
          attemptDeleteSupplier(supplier);
        }}
        title={t('suppliers:delete_supplier')}
        content={t('suppliers:delete_confirmation_msg')}
      />
      {error && (
        <Grid container>
          <Alert severity="error" message={error} />
        </Grid>
      )}
      <form autoComplete="off">
        <Box id="scroll-dialog-description">
          <Tabs tabs={tabs} onTabChanged={handleTabChanged} />
        </Box>
      </form>
    </Box>
  );
};
