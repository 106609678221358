/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-mixed-operators */
/* eslint-disable react/prop-types */
import React from 'react';
import {
  Grid, TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles, withStyles } from '@mui/styles';
import ModalBanner from '../../../../../components/ui/ModalBanner';
import Text from '../../../../../components/Text/Text';

const CTextField = withStyles(theme => ({
  root: {
    '& .MuiOutlinedInput-input': {
      padding: 5,
      marginLeft: 15,
      [theme.breakpoints.down('md')]: {
        fontSize: 12,
        marginLeft: 9,
        padding: '4px 2px',
      },
    },
    '& .MuiInputdefaultValue-formControl': {
      position: 'absolute',
    },
    '& .MuiOutlinedInput-root': {
      width: '80%',
      margin: '0px auto',
      position: 'relative',
      '&::before': {
        content: "'$'",
        position: 'absolute',
        left: 7,
      },
      [theme.breakpoints.down('md')]: {
        '&::before': {
          fontSize: 11,
          left: 2,
        },
      },
    },
  },
}))(TextField);

const CTextFieldPor = withStyles(theme => ({
  root: {
    '& .MuiOutlinedInput-input': {
      padding: 5,
      marginLeft: 15,
      [theme.breakpoints.down('md')]: {
        fontSize: 12,
        marginLeft: 9,
        padding: '4px 2px',
      },
    },
    '& .MuiInputdefaultValue-formControl': {
      position: 'absolute',
    },
    '& .MuiOutlinedInput-root': {
      width: '80%',
      margin: '0px auto',
      position: 'relative',
      '&::before': {
        content: "'%'",
        position: 'absolute',
        left: 7,
      },
      [theme.breakpoints.down('md')]: {
        '&::before': {
          fontSize: 11,
          left: 2,
        },
      },
    },
  },
}))(TextField);

const useStyle = makeStyles(() => ({
  itemsContainer: {
    margin: '15px 0px',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    width: '100%',
  },
}));

export default ({
  formData,
  register,
  errors,
}) => {
  const { t } = useTranslation(['products']);
  const classes = useStyle();

  const earnings = 100 / formData.cost * (formData.price - formData.cost);

  return (
    <Grid>
      <ModalBanner section="finance" />
      <Grid className={classes.itemsContainer}>
        <Text variant="caption" align="center" style={{ alignSelf: 'center', lineHeight: 1 }}>
          { t('general:cost') }
        </Text>
        <Text variant="caption" align="center" style={{ alignSelf: 'center', lineHeight: 1 }}>
          { t('general:price_public') }
        </Text>
        <Text variant="caption" align="center" style={{ alignSelf: 'center', lineHeight: 1 }}>
          { t('general:earnings') }
        </Text>
        <Text variant="caption" align="center" style={{ alignSelf: 'center', lineHeight: 1 }}>
          { t('general:utility') }
        </Text>
      </Grid>
      <Grid className={classes.itemsContainer}>
        <CTextField
          {...register('cost')}
          variant="outlined"
          style={{ alignSelf: 'center' }}
        />
        <CTextField
          {...register('price')}
          variant="outlined"
          style={{ alignSelf: 'center' }}
        />
        <CTextFieldPor
          variant="outlined"
          value={Number.isFinite(earnings) ? (earnings).toFixed(2) : 0}
          style={{ alignSelf: 'center' }}
          disabled
        />
        <CTextField
          variant="outlined"
          value={(formData.price - formData.cost || 0).toFixed()}
          style={{ alignSelf: 'center' }}
          disabled
        />
      </Grid>
    </Grid>
  );
};
