/* eslint-disable react/prop-types */
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import CampaignFormView from './CampaignFormView';
import { CampaignWizardProvider } from '../CampaignWizard/context/CampaignWizardContext';

export default ({
  showModal, handleClose, refreshList, campaign = null,
}) => {
  const { error } = useSelector((state: any) => state.modifier_group);

  const closeCampaign = useCallback(() => {
    handleClose();
  }, [handleClose]);

  return (
    <CampaignWizardProvider>
      <CampaignFormView
        campaign={campaign}
        error={error}
        closeCampaign={closeCampaign}
        showModal={showModal}
        refreshList={refreshList}
      />
    </CampaignWizardProvider>
  );
};
