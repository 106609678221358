/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import Lottie from 'react-lottie';
import { makeStyles } from '@mui/styles';
import success from '../../../../theme/ECTheme/assets/Lotties/successful.json';
import Ecubi from '../../../../theme/ECTheme/assets/images/Ecubi.svg';
import Box from '../../../ui/Layout/Box';

const useStyle = makeStyles(() => ({
  imageContainer: {
    marginTop: 10,
    margin: 40,
    marginBottom: 70,

  },
}));

export const SuccessfulPayment = () => {
  const classes = useStyle();
  const defaultOptions = {
    loop: false,
    autoplay: true,
    rendererSettings: {
      preserverAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <h2 style={{ color: 'black' }}>
        ¡FELICIDADES!
      </h2>
      <p style={{ width: 500, fontSize: '22px', textAlign: 'center' }}>
        Ahora tu negocio contará con las mejores herramientas
        para llegar al éxito.
      </p>
      <div style={{ display: 'flex', justifyContent: 'space-between', width: 600 }}>
        <img src={Ecubi} alt="ecubi_logo" className={classes.imageContainer} style={{ width: '65%', marginTop: 80 }} />
        <div className={classes.imageContainer} style={{ width: '100%' }}>
          <Lottie options={{ animationData: success, ...defaultOptions }} />
        </div>
      </div>
    </Box>
  );
};
