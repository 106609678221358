/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-useless-fragment */
import { makeStyles } from '@mui/styles';
import React from 'react';
import ActivityIndicator from '../../../../../../../../components/ui/ActivityIndicator';

const useStyles = makeStyles(() => ({
  img: {
    maxWidth: '100%',
    height: 'auto',
    width: 'auto',
    maxHeight: '100%',
  },
}));

const ImagePreview = ({ asset }) => {
  const classes = useStyles();
  return (
    <>
      {asset.processing ? (
        <ActivityIndicator />
      ) : (
        <img
          src={asset.url}
          alt={asset.name ? asset.name : ''}
          className={classes.img}
        />
      )}
    </>
  );
};

export default ImagePreview;
