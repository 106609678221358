import React from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import NavigationButton from './NavigationButton';

const PreviousButton = props => (
  <NavigationButton {...props}>
    <ArrowBackIcon />
  </NavigationButton>
);

export default PreviousButton;
