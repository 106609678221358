/* eslint-disable react/prop-types */
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSearchCategory } from '../../../../../actions/search';
import Grid from '../../../../../components/ui/Layout/Grid';
import PaginatedScroll from '../../../../../hoc/PaginatedScroll';
import CategoryButton from '../../CategoriesPage/CategoryListItem/CategoryButton';

const styles = makeStyles(() => ({
  container: {
    width: '100%',
    height: 80,
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    overflowX: 'auto',
    marginBottom: 20,
  },
}));

export default ({ stateCategories, getCategories, resetCategories }) => {
  const classes = styles();
  const dispatch = useDispatch();

  const search = useSelector((state: any) => state.search);

  const HandleSetSearch = (value) => {
    dispatch(setSearchCategory(value));
  };

  return (
    <PaginatedScroll
      hasNextPage={
        stateCategories.response.page < stateCategories.response.last_page
      }
      loadData={(params: any) => getCategories(params)}
      currentPage={stateCategories.response.page}
      resetData={resetCategories}
      processing={stateCategories.processing}
    >
      <Grid className={classes.container}>
        {
          stateCategories.data
            .map((category: any) => (
              <CategoryButton
                key={category.id}
                category={category}
                click={HandleSetSearch}
                opacity={search.filters.category_id
                  ? search.filters.category_id.includes(category.id) : false}
              />
            ))
        }
      </Grid>
    </PaginatedScroll>
  );
};
