import React from 'react';
import { useTranslation } from 'react-i18next';
import SuccessButton from '../../../../../components/ui/Buttons/SuccessButton';
import Grid from '../../../../../components/ui/Layout/Grid';

interface ActionsProps {
  handleSubmit: Function;
}

export default ({ handleSubmit }: ActionsProps) => {
  const { t } = useTranslation();
  return (
    <Grid container>
      <Grid container item xs={12} justifyContent="flex-end">
        <SuccessButton
          onClick={handleSubmit}
          color="primary"
          text={t('general:apply')}
        />
      </Grid>
    </Grid>
  );
};
