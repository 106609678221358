export const types = {
  SET_LOCATIONS_PROCESSING: 'SET_LOCATIONS_PROCESSING',
  SET_LOCATIONS_SUCCESS: 'SET_LOCATIONS_SUCCESS',
  SET_LOCATIONS_FAILURE: 'SET_LOCATIONS_FAILURE',
  RESET_LOCATIONS: 'RESET_LOCATIONS',

  DELETE_LOCATION_SUCCESS: 'DELETE_LOCATION_SUCCESS',
  SAVE_LOCATION_SUCCESS: 'SAVE_LOCATION_SUCCESS',

  SET_BRANCHES_LOCATIONS_SUCCESS: 'SET_BRANCHES_LOCATIONS_SUCCESS',
  SET_BRANCHES_LOCATIONS_FAILURE: 'SET_BRANCHES_LOCATIONS_FAILURE',

};
