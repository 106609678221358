import { types } from './types';
import AuthorizedApiClient from '../../core/common/api/AuthorizedApiClient';

export const savingOrder = () => ({
  type: types.SAVING_ORDER,
});

export const getOrder = (id, companyId, params = {}) => async (
  dispatch: any,
) => {
  const sg = new AuthorizedApiClient();
  const path = `companies/${companyId}/orders/${id}`;
  dispatch({ type: types.GETTING_ORDER });
  try {
    const response = await sg.get(params, path);

    if (response != null) {
      dispatch({ type: types.SET_ORDER_SUCCESS, order: response.data.order });
    }
  } catch (error) {
    dispatch({ type: types.SET_ORDER_FAILURE, erro: error });
  }
};

export const updateOrder = (data, companyID) => async (dispatch: any) => {
  dispatch(savingOrder());
  const { id } = data;
  delete data.id;
  const sg = new AuthorizedApiClient();
  const path = `companies/${companyID}/orders/${id}`;
  try {
    const response = await sg.put(data, path);
    if (response != null) {
      dispatch({ type: types.SAVE_ORDER_SUCCESS, response: response.data });
    }
  } catch (error) {
    dispatch({ type: types.SAVE_ORDER_FAILURE, error: error.response.data });
  }
};
