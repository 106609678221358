/* eslint-disable react/prop-types */
import React from 'react';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';

const styles = makeStyles(theme => ({
  container: {
    height: 98,
    width: '100%',
    boxShadow: theme.shadow.main,
    borderRadius: 5,
    display: 'grid',
    gridTemplateColumns: '2fr 3fr',
    gridTemplateRows: '25% auto',
    padding: 10,
    boxSizing: 'border-box',
    overflow: 'hidden',
    cursor: 'pointer',
  },
  columnTittle: {
    color: theme.colors.black,
    fontSize: 15,
    alignSelf: 'end',
  },
  units: {
    color: theme.colors.black,
    justifySelf: 'center',
  },
  unit: {
    fontWeight: 'bold',
    alignSelf: 'center',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    padding: '0px 3px',
  },
  description: {
    alignSelf: 'center',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical',
  },
  descriptionTittle: {
    paddingLeft: 20,
  },
}));

export default ({ unitOfMeasure }) => {
  const { t } = useTranslation(['units']);
  const classes = styles();
  return (
    <div className={classes.container}>
      <div className={`${classes.columnTittle} ${classes.units}`}>
        {t('units:units')}
      </div>
      <div className={`${classes.columnTittle} ${classes.descriptionTittle}`}>
        {t('units:description')}
      </div>
      <div className={`${classes.units} ${classes.unit}`}>
        {unitOfMeasure.code}
      </div>
      <div className={classes.description}>{unitOfMeasure.description}</div>
    </div>
  );
};
