import ApiClient from '../../common/api/ApiClient';
import ApiUserService from '../../services/api/ApiUserService';
import Log from '../../../util/Log';
import UserGatewayContract from '../../contracts/gateways/UserGatewayContract';
import UserService from '../../contracts/services/UserService';

export default class ApiUserGateway implements UserGatewayContract {
  private static instance: ApiUserGateway = null;

  private service: UserService = null;

  private constructor() {
    this.service = new ApiUserService();
  }

  public static getInstance(): ApiUserGateway {
    if (ApiUserGateway.instance === null) {
      ApiUserGateway.instance = new ApiUserGateway();
    }
    return ApiUserGateway.instance;
  }

  async getUser() {
    try {
      const response = await this.service.getUser();
      Log.debug('ApiUserGateway ok getting user', response);
      if (response.data.user !== undefined) {
        return response.data.user;
      }
      throw new Error('Error getting user');
    } catch (error) {
      Log.debug('ApiUserGateway error getting user', error.response);
      throw new Error(ApiClient.parseError(error.response));
    }
  }
}
