import { types } from '../../actions/movements/types';
import { PAGINATED_TABLE_PER_PAGE } from '../../constants/common';
import { getUTCTodayEnd, getUTCTodayStart } from '../../util/dates';

export const initialState = {
  response: {
    data: [], total: 0, per_page: PAGINATED_TABLE_PER_PAGE, last_page: 0, page: 0,
  },
  processing: true,
  data: [],
  error: null,
  params: {
    page: 1,
    per_page: PAGINATED_TABLE_PER_PAGE,
    sort: { updated_at: 'desc' },
    embed: 'product,location,user',
    filters: {
      updated_at: {
        $gte: getUTCTodayStart(),
        $lte: getUTCTodayEnd(),
      },
    },
  },
  refresh: false,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case types.SET_MOVEMENTS_PROCESSING:
      return {
        ...state,
        processing: action.payload,
      };

    case types.SET_MOVEMENTS_SUCCESS:
      return {
        ...state,
        response: action.response,
        data: [...state.data, ...action.response.data],
        error: null,
        processing: false,
      };
    case types.SET_MOVEMENTS_FAILURE:
      return {
        ...state,
        error: action.error,
        processing: false,
      };

    case types.SET_MOVEMENTS_PARAMS:
      return {
        ...state,
        params: action.params,
      };
    case types.RESET_MOVEMENTS:
      return initialState;

    case types.SAVE_MOVEMENT_SUCCESS:
      return {
        ...state,
        refresh: true,
      };

    case types.CLEAR_REFRESH:
      return {
        ...state,
        refresh: false,
      };
    default:
      return state;
  }
};
