/* eslint-disable default-case */
import React from 'react';
import Grid from '../../../../../../../components/ui/Layout/Grid';
import {
  CampaignWizardStep,
  FIRST_STEP,
  LAST_STEP,
} from '../constants/campaign_wizard';
import { useCampaignWizardContext } from '../context/CampaignWizardContext';
import NextButton from './NextButton';
import PreviousButton from './PreviousButton';

const CampaignWizardNavigation = () => {
  const { state, setStep, setStepToSave } = useCampaignWizardContext();

  const { step, campaign } = state;
  const isLast = step === LAST_STEP;
  const isFirst = step === FIRST_STEP;

  const handleNextClick = (currentStep) => {
    if (currentStep === CampaignWizardStep.ZONES && campaign.zones.length > 0) {
      return setStep(CampaignWizardStep.GALLERY);
    }
    return setStepToSave(currentStep);
  };

  const handlePreviousClick = (currentStep) => {
    let previousStep = FIRST_STEP;
    // To be sure about the order, We make a swith instead of incrementing the step
    switch (currentStep) {
      case CampaignWizardStep.DATES:
        previousStep = FIRST_STEP;

        break;
      case CampaignWizardStep.GOALS:
        previousStep = CampaignWizardStep.DATES;
        break;

      case CampaignWizardStep.PRODUCTS:
        previousStep = CampaignWizardStep.GOALS;
        break;

      case CampaignWizardStep.ZONES:
        previousStep = CampaignWizardStep.PRODUCTS;
        break;

      case CampaignWizardStep.GALLERY:
        previousStep = CampaignWizardStep.ZONES;
        break;

      case CampaignWizardStep.BASIC_INFO:
        previousStep = CampaignWizardStep.GALLERY;
        break;
    }

    setStep(previousStep);
  };

  return (
    <Grid container>
      <Grid container item xs={6} justifyContent="flex-end">
        <PreviousButton
          onClick={() => {
            handlePreviousClick(step);
          }}
          disabled={isFirst}
        />
      </Grid>
      <Grid container item xs={6}>
        <Grid container item xs={6} justifyContent="flex-start">
          <NextButton
            onClick={() => {
              handleNextClick(step);
            }}
            disabled={isLast}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CampaignWizardNavigation;
