/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
import React from 'react';
import { makeStyles } from '@mui/styles';
import { Grid, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Box from '../../../../../components/ui/Layout/Box';
import { addItem, stockWarning } from '../../../../../actions/cart';
import ItemStock from '../ItemStock';
import { openWarning } from '../../../../../actions/pos';
import { useSession } from '../../../../../util/session';
import { PERMISSION, RESOURCES } from '../../../../../constants/common';

const styles = makeStyles(theme => ({
  container: {
    width: '95%',
    margin: '0px auto',
    height: 65,
    display: 'grid',
    gridTemplateColumns: '1fr 2fr 1fr 2fr 1fr',
    alignContent: 'center',
    border: '1px solid grey',
    cursor: 'pointer',
    backgroundColor: 'white',
  },
  imgSize: {
    height: '100%',
    width: '100%',
  },
  Image: {
    backgroundRepeat: 'no-repeat',
    height: 'calc(100% - 8px)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    marginTop: 4,
  },
  price: {
    fontWeight: 'bold',
    fontSize: '1rem',
    '&:before': {
      content: "'$'",
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    lineHeight: 1,
    [theme.breakpoints.down('md')]: {
      fontSize: 13,
      fontWeight: 'bold',
    },
  },
  subtitle: {
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
    },
  },
  Limittext: {
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  available: {
    color: theme.colors.trafficLight.adequate.main,
  },
  unavailable: {
    color: theme.colors.trafficLight.low.main,
  },
}));

export default ({ item }) => {
  const classes = styles();
  const { t } = useTranslation(['general']);
  const { can } = useSession();
  const dispatch = useDispatch();
  const cart = useSelector((state: any) => state.cart);
  const itemUpdate = cart.items.find(i => i.id === item.id);

  const openPriceWarning = () => {
    dispatch(openWarning(true));
  };
  const openStockWarning = () => {
    dispatch(stockWarning(item));
  };

  const additem = (itemItem: any) => {
    if (item.variants[0].price <= 0) {
      if (itemItem.inventoried) {
        if (!item.variants[0].stock) {
          return;
        }
        openPriceWarning();
        if (item.quantity >= item.variants[0].stock) {
          openStockWarning();
        } else {
          return dispatch(addItem(itemItem));
        }
      } else if (itemItem.available === true) {
        openPriceWarning();
        return dispatch(addItem(itemItem));
      }
    } else if (itemItem.inventoried) {
      if (!item.variants[0].stock) {
        return;
      }
      if (item.quantity >= item.variants[0].stock) {
        openStockWarning();
      } else {
        return dispatch(addItem(itemItem));
      }
    } else if (itemItem.available === true) {
      return dispatch(addItem(itemItem));
    }
  };

  const canStock = can(RESOURCES.stock, PERMISSION.edit);

  return (
    <Box
      className={classes.container}
      onClick={() => additem(itemUpdate || item)}
    >
      <Box className={classes.imgSize}>
        <div
          style={{ backgroundImage: `url(${item.variants[0].image_url})` }}
          className={classes.Image}
        />
      </Box>

      <Grid style={{ marginLeft: 5 }}>
        <Typography variant="h6" align="inherit" className={classes.title}>
          {item.name}
        </Typography>
        <Typography
          variant="body2"
          align="inherit"
          className={`${classes.subtitle} ${classes.Limittext}`}
        >
          {item.description}
        </Typography>
      </Grid>

      <Grid className={classes.content}>
        <Typography
          variant="caption"
          align="center"
          className={classes.subtitle}
        >
          {t('general:point_of_sell:unit')}
        </Typography>
        <Typography variant="body2" align="center" className={classes.subtitle}>
          {item.variants[0].unitofmeasure.code}
        </Typography>
      </Grid>

      <Grid className={classes.content}>
        {canStock ? (
          <>
            <Typography
              variant="caption"
              align="center"
              className={classes.subtitle}
            >
              {item.inventoried
                ? t('general:point_of_sell:quantity_available')
                : t('general:point_of_sell:availability')}
            </Typography>
            {item.inventoried ? (
              <ItemStock variants={item.variants[0]} />
            ) : (
              <Typography
                variant="caption"
                align="center"
                className={
                  item.available ? classes.available : classes.unavailable
                }
              >
                {item.available
                  ? t('general:point_of_sell:available')
                  : t('general:point_of_sell:unavailable')}
              </Typography>
            )}
          </>
        ) : (
          <>
            <Typography
              variant="caption"
              align="center"
              className={classes.subtitle}
            >
              {t('general:point_of_sell:availability')}
            </Typography>
            <Typography
              variant="caption"
              align="center"
              className={
                item.available ? classes.available : classes.unavailable
              }
            >
              {item.available
                ? t('general:point_of_sell:available')
                : t('general:point_of_sell:unavailable')}
            </Typography>
          </>
        )}
      </Grid>

      <Grid className={classes.content}>
        <Typography
          variant="caption"
          align="center"
          className={classes.subtitle}
        >
          {t('general:point_of_sell:price')}
        </Typography>
        <Typography variant="body2" align="center" className={classes.price}>
          {item.variants[0].price}
        </Typography>
      </Grid>
    </Box>
  );
};
