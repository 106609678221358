import { types } from './types';
import AuthorizedApiClient from '../../core/common/api/AuthorizedApiClient';

export const resetBranches = () => ({
  type: types.RESET_BRANCHES,
});

export const getBranches = (companyId, params = {}) => async (
  dispatch: any,
) => {
  const sg = new AuthorizedApiClient();
  const path = `companies/${companyId}/branches`;
  dispatch({ type: types.SET_BRANCHES_PROCESSING, payload: true });
  try {
    const response = await sg.get(params, path);

    if (response != null) {
      dispatch({ type: types.SET_BRANCHES_SUCCESS, response: response.data });
    }
  } catch (error) {
    dispatch({ type: types.SET_BRANCHES_FAILURE, error });
  }
};
