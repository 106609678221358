/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { OrdersProvider } from './context/OrdersContext';
import OrdersListView from './OrdersListView';

export default ({ title }) => {
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);

  return (
    <OrdersProvider>
      <OrdersListView
        error={error}
        setError={setError}
        message={message}
        setMessage={setMessage}
        title={title}
      />
    </OrdersProvider>
  );
};
