import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SectionListContainer from '../../../../../hoc/SectionListContainer';
import Grid from '../../../../../components/ui/Layout/Grid';
import PageContainer from '../../../../../hoc/PageContainer';
import Box from '../../../../../components/ui/Layout/Box';
import AddButton from '../../../../../components/ui/Buttons/BlueButton/AddButton';
import CampaignForm from './CampaignForm';
import { PERMISSION, RESOURCES } from '../../../../../constants/common';
import { useSession } from '../../../../../util/session';
import CampaignsList from './CampaignsList';
import { useCampaignListContext } from './CampaignsList/context/CampaignsListContext';
// import ContentHeader from "../../../../../components/ui/ContentHeader";
// import BreadCrumbs from "../../../../../components/ui/BreadCrumbs";

export default () => {
  const [showModal, setShowModal] = useState(false);

  const openNewCampaign = () => {
    setShowModal(true);
  };

  const handleClose = useCallback(() => {
    setShowModal(false);
  }, []);
  const { t } = useTranslation(['general']);
  const { can } = useSession();
  const { refreshList } = useCampaignListContext();

  const handleRefresh = useCallback(() => {
    handleClose();
    refreshList();
  }, [refreshList, handleClose]);

  return (
    <>
      {(can(RESOURCES, PERMISSION.create)
        || can(RESOURCES.sales, PERMISSION.edit)) && (
        <CampaignForm
          handleClose={handleClose}
          showModal={showModal}
          refreshList={handleRefresh}
          campaign={null}
        />
      )}

      <Grid container>
        {/* <Grid item xs={12}>
          <ContentHeader
            title={t('general:store_page:my_store')}
            section="store"
          />
        </Grid> */}

        <PageContainer>
          {/* <BreadCrumbs /> */}
          {can(RESOURCES.products, PERMISSION.create) && (
            <Box mt={3}>
              <AddButton onClick={openNewCampaign}>
                {t('general:shared_campaigns:create_campaign')}
              </AddButton>
            </Box>
          )}

          <SectionListContainer>
            <CampaignsList />
          </SectionListContainer>
        </PageContainer>
      </Grid>
    </>
  );
};
