/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import { useTranslation } from 'react-i18next';
import ActivityIndicator from '../../../../../../components/ui/ActivityIndicator';
import { useKPIDashboardContext } from '../context/KPIDashboardContext';
import KPIData, { KPIDataType } from '../KPIData';
import KPITitle from '../KPITitle';
import KPIWrapper from '../KPIWrapper';

const SalesRevenue = () => {
  const { t } = useTranslation(['general']);
  const { state } = useKPIDashboardContext();
  const { total_revenue, processing } = state;
  return (
    <KPIWrapper>
      <KPITitle title={t('general:admin_dashboard:revenue')} />

      {processing ? (
        <ActivityIndicator />
      ) : (
        <KPIData value={total_revenue} type={KPIDataType.CURRENCY} />
      )}
    </KPIWrapper>
  );
};

export default SalesRevenue;
