/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useEffect } from 'react';
import {
  PieChart,
  Pie,
  Tooltip,
  Cell,
  ResponsiveContainer,
  Legend,
} from 'recharts';
import { useTranslation } from 'react-i18next';
import LabelledOutline from '../../../../../hoc/LabelledOutline';
import Box from '../../../../../components/ui/Layout/Box';
import { useSalesPaymentMethodsContext } from './context/SalesPaymentMethodsContext';
import ActivityIndicator from '../../../../../components/ui/ActivityIndicator';
import { useDashboardContext } from '../context/DashboardContext';
import { dateToUtc } from '../../../../../util/dates';

const SalesPaymentMethods = () => {
  const { t } = useTranslation(['general']);
  const { getPaymentMethods, state } = useSalesPaymentMethodsContext();
  const { params, processing, response } = state;

  const { state: dashboardState } = useDashboardContext();
  const { start_date, end_date } = dashboardState;

  useEffect(() => {
    getPaymentMethods({
      ...params,
      filters: {
        ...params.filters,
        updated_at: {
          $gte: dateToUtc(start_date),
          $lte: dateToUtc(end_date),
        },
      },
    });
  }, [params, start_date, end_date, getPaymentMethods]);

  const data = response.data.map(pm => ({
    payment_type: t(`general:payment_type:${pm.payment_type.toUpperCase()}`),
    percentage: pm.percentage,
  }));

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28'];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    // index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  return (
    <LabelledOutline
      label={t('general:admin_dashboard:payment_methods_title')}
      customWidth={150}
    >
      <Box width="100%" height="250px">
        {processing ? (
          <ActivityIndicator />
        ) : (
          <ResponsiveContainer width="100%" height="100%">
            <PieChart width={320} height={260}>
              <Pie
                data={data}
                dataKey="percentage"
                nameKey="payment_type"
                fill="#8884d8"
                cx="50%"
                cy="50%"
                label={renderCustomizedLabel}
                labelLine={false}
              >
                {data.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Tooltip />
              <Legend wrapperStyle={{ position: 'relative' }} />
            </PieChart>
          </ResponsiveContainer>
        )}
      </Box>
    </LabelledOutline>
  );
};

export default SalesPaymentMethods;
