/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import {
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import HeaderIcon from '../../../../../../../../components/ui/ContentHeader/HeaderIcon';
import Box from '../../../../../../../../components/ui/Layout/Box';
import Grid from '../../../../../../../../components/ui/Layout/Grid';
import { generateKey } from '../../../../../../../../util/keys';
import Toggle from '../Toggle';
import SubSection from './SubSection';

const styles = makeStyles(theme => ({
  section: {
    border: '0.5px solid #9A9494',
    borderRadius: '3px',
  },
  title: {
    cursor: 'pointer',
  },
  titleText: {
    paddingLeft: theme.spacing(2),
  },
}));

export default ({
  user, code, subsections, setPermission,
}) => {
  const classes = styles();
  const { t } = useTranslation(['general']);
  const [expanded, setExpanded] = useState(false);
  // const [checked, setChecked] = useState(false);
  const list = [];
  Object.entries(subsections).forEach(([subsection]) => {
    list.push(
      <Grid item xs={12} sm={6} key={generateKey(subsection)}>
        <SubSection
          code={subsection}
          resources={subsections[subsection]}
          user={user}
          setPermission={setPermission}
        />
      </Grid>,
    );
  });

  const handleTogleClick = () => {
    setExpanded(!expanded);
  };

  // const handleChecked = (event) => {
  //   setChecked(event.target.checked);
  // };

  return (
    <Box className={classes.section} p={1}>
      <Box
        width={1}
        className={classes.title}
        display="flex"
        flexDirection="row"
        onClick={handleTogleClick}
      >
        {/* <Box>
          <Checkbox checked={checked} onChange={handleChecked} />
        </Box> */}
        <Box display="flex" flexGrow={1} pl={1} pr={1} alignItems="center">
          <HeaderIcon section={code === 'clients' ? 'client' : (code === 'pos' ? 'pointSell' : code)} />
          {' '}
          {/* TODO: check this */}
          <Typography className={classes.titleText}>{t(`general:${code}`, code)}</Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Toggle expanded={expanded} />
        </Box>
      </Box>
      {expanded && (
        <Box mt={2}>
          <Grid container>{list}</Grid>
        </Box>
      )}
    </Box>
  );
};
