export const types = {
  EDIT_CUSTOMER: 'EDIT_CUSTOMER',
  CLOSE_CUSTOMER_MODAL: 'CLOSE_CUSTOMER_MODAL',
  SAVING_CUSTOMER: 'SAVING_CUSTOMER',
  SAVE_CUSTOMER_SUCCESS: 'SAVE_CUSTOMER_SUCCESS',
  SAVE_CUSTOMER_FAILURE: 'SAVE_CUSTOMER_FAILURE',
  DELETING_CUSTOMER: 'DELETING_CUSTOMER',
  DELETE_CUSTOMER_SUCCESS: 'DELETE_CUSTOMER_SUCCESS',
  DELETE_CUSTOMER_FAILURE: 'DELETE_CUSTOMER_FAILURE',

  SET_CUSTOMER_SUCCESS: 'SET_CUSTOMER_SUCCESS',
  SET_CUSTOMER_FAILURE: 'SET_CUSTOMER_FAILURE',

  SET_CUSTOMER_TAB: 'SET_CUSTOMER_TAB',
};
