/* eslint-disable consistent-return */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import { useReducer } from 'react';
import axios from 'axios';
import AuthorizedApiClient from '../../../../../../../core/common/api/AuthorizedApiClient';
import Log from '../../../../../../../util/Log';
import { useSession } from '../../../../../../../util/session';
import {
  SET_IMAGE_UPLOAD_SUCCESS,
  START_IMAGE_UPLOAD,
  SET_IMAGE_UPLOAD_ERROR,
  DELETE_IMAGE,
  AssetType,
  START_VIDEO_UPLOAD,
  SET_VIDEO_UPLOAD_SUCCESS,
  SET_VIDEO_UPLOAD_ERROR,
  DELETE_VIDEO,
  SET_ERROR,
} from './constants';
import { initialState, reducer } from './reducer';
import {
  MAX_IMAGE_SIZE,
  MAX_VIDEO_SIZE,
} from '../../../../../../../constants/common';

const simpleAxions = axios.create();

export enum GalleryError {
  REQUIRE_IMAGE_OR_VIDEO = 'require_image_or_video',
  PROCESSING_ASSET = 'processing_gallery_error',
  IMAGE_SIZE = 'image_size',
  VIDEO_SIZE = 'video_size',
  SIGN_POST_REQUEST = 'sign_post_request',
  UPLOAD_VENDOR_REQUEST = 'upload_vendor_request',
}

const VIDEO_MP4_TYPE = 'video/mp4';

const useFileUpload = (defaultImages = [], defaultVideo = null) => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,

    images: defaultImages,
    video: defaultVideo,
  });

  const { company } = useSession();
  const upload = async (_data, file) => {
    if (company) {
      const { url, fields } = _data.data;
      const data = new FormData();
      for (const key in fields) {
        data.append(key, fields[key]);
      }
      data.append('file', file);
      try {
        const response = await simpleAxions.post(url, data);

        return response;
      } catch (e) {
        Log.debug('error s3', e);
        throw new Error(
          GalleryError.UPLOAD_VENDOR_REQUEST,
        );
      }
    }
  };

  const signPost = async (data = {}) => {
    if (company) {
      const sg = new AuthorizedApiClient();
      const path = `companies/${company.id}/campaigns/upload`;
      try {
        const response = await sg.post(data, path);
        if (response) {
          return response.data;
        }
      } catch (e) {
        Log.error('Error signin post', e);
        throw new Error(
          GalleryError.SIGN_POST_REQUEST,
        );
      }
    }
  };

  const startUpload = (asset) => {
    if (asset.resource.toString() === AssetType.VIDEO) {
      dispatch({
        type: START_VIDEO_UPLOAD,
        payload: asset,
      });
    } else {
      dispatch({
        type: START_IMAGE_UPLOAD,
        payload: asset,
      });
    }
  };

  const onUploadSuccess = (asset) => {
    if (asset.resource.toString() === AssetType.VIDEO) {
      dispatch({
        type: SET_VIDEO_UPLOAD_SUCCESS,
        payload: asset,
      });
    } else {
      dispatch({
        type: SET_IMAGE_UPLOAD_SUCCESS,
        payload: asset,
      });
    }
  };

  const onUploadFailed = (asset) => {
    if (asset.resource.toString() === AssetType.VIDEO) {
      dispatch({
        type: SET_VIDEO_UPLOAD_ERROR,
        payload: asset,
      });
    } else {
      dispatch({
        type: SET_IMAGE_UPLOAD_ERROR,
        payload: asset,
      });
    }
  };

  const deleteImage = (image) => {
    dispatch({
      type: DELETE_IMAGE,
      payload: image,
    });
  };

  const deleteVideo = (video) => {
    dispatch({
      type: DELETE_VIDEO,
      payload: video,
    });
  };

  const uploadViaPresignedPost = async (file) => {
    const asset = {
      success: false,
      error: null,
      processing: true,
      resource: file.type === VIDEO_MP4_TYPE ? AssetType.VIDEO : AssetType.IMAGE,
      type: file.type,
      name: file.name,
      uid: file.uid,
      size: file.size,
    };

    startUpload(asset);

    try {
      const signedPostData = await signPost({
        size: file.size,
        name: file.name,
        type: file.type,
        resource: file.type === VIDEO_MP4_TYPE
          ? AssetType.VIDEO : AssetType.IMAGE,
      });

      if (signedPostData && signedPostData.data.url) {
        Log.debug('signedPostData', signedPostData);
        const uploadResponse = await upload(signedPostData, file);
        Log.debug('uploadResponse', uploadResponse);
        onUploadSuccess({
          ...asset,
          id: signedPostData.id,
          name: signedPostData.name,
          url: `${signedPostData.data.url}/${signedPostData.data.fields.Key}`,
          processing: false,
          success: true,
          Key: signedPostData.data.fields.Key,
        });
      }
    } catch (error) {
      Log.debug('error uplopading via sign post', error);
      onUploadFailed({ ...asset, error: error.message });
    }
  };

  const setError = (error) => {
    dispatch({
      type: SET_ERROR,
      payload: error,
    });
  };

  const uploaderProps = {
    accept: '.png, .jpeg, .mp4, .jpg',
    beforeUpload: (file) => {
      if (file.type === VIDEO_MP4_TYPE && file.size > MAX_VIDEO_SIZE) {
        setError(GalleryError.VIDEO_SIZE);
        return false;
      } if (file.type !== VIDEO_MP4_TYPE && file.size > MAX_IMAGE_SIZE) {
        setError(GalleryError.IMAGE_SIZE);
        return false;
      }
    },
    customRequest: async options => uploadViaPresignedPost(options.file),
  };

  return {
    uploaderProps, state, deleteImage, deleteVideo, setError,
  };
};

export default useFileUpload;
