/* eslint-disable react/prop-types */
import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '../../../../../components/ui/Layout/Box';

const ModifierGroupRules = ({ register, modifierGroup }) => {
  const { t } = useTranslation(['general']);
  const [required, setRequired] = useState(!!modifierGroup.required);
  const [several, setSeveral] = useState(!!modifierGroup.allow_several);
  return (
    <Box ml={2}>

      <Box mt={1}>
        <Box>
          <FormControlLabel
            control={(
              <Checkbox
                {...register('required')}
                color="primary"
                checked={required}
                onChange={(event) => {
                  setRequired(event.target.checked);
                }}
              />
            )}
            label={<Typography variant="body2">{t('general:modifiers_groups:rules_option_1')}</Typography>}
          />
        </Box>
        <Box>
          <FormControlLabel
            control={(
              <Checkbox
                {...register('allow_several')}
                color="primary"
                checked={several}
                onChange={(event) => {
                  setSeveral(event.target.checked);
                }}
              />
            )}
            label={<Typography variant="body2">{t('general:modifiers_groups:rules_option_2')}</Typography>}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ModifierGroupRules;
