/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-empty */
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';

const useStyle = makeStyles(theme => ({
  reportsContainer: {
    position: 'relative',
  },
  reportsGradientR: {
    position: 'absolute',
    width: '17%',
    height: '84%',
    top: '8%',
    bottom: '8%',
    right: 0,
    zIndex: 50,
    background:
        'linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,0.29) 19%, rgba(255,255,255,0.53) 35%, rgba(255,255,255,0.8) 53%)',
    cursor: 'pointer',
    '&::after': {
      content: "''",
      background: theme.colors.cardPurple.main,
      width: 20,
      height: 5,
      position: 'absolute',
      top: 'calc(50% - 5.5px)',
      left: '50%',
      transform: 'rotate(45deg)',
    },
    '&::before': {
      content: "''",
      background: theme.colors.cardPurple.main,
      width: 20,
      height: 5,
      position: 'absolute',
      top: 'calc(50% - -5.5px)',
      left: '50%',
      transform: 'rotate(-45deg)',
    },
  },
  reportsGradientL: {
    position: 'absolute',
    width: '17%',
    height: '84%',
    top: '8%',
    bottom: '8%',
    left: 0,
    zIndex: 50,
    background:
        'linear-gradient(to left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.29) 19%, rgba(255,255,255,0.53) 35%, rgba(255,255,255,0.8) 53%)',
    cursor: 'pointer',
    '&::after': {
      content: "''",
      background: theme.colors.cardPurple.main,
      width: 20,
      height: 5,
      position: 'absolute',
      top: 'calc(50% - 5.5px)',
      left: '50%',
      transform: 'rotate(-45deg)',
    },
    '&::before': {
      content: "''",
      background: theme.colors.cardPurple.main,
      width: 20,
      height: 5,
      position: 'absolute',
      top: 'calc(50% - -5.5px)',
      left: '50%',
      transform: 'rotate(45deg)',
    },
  },
  reports: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    overflowX: 'auto',
    height: 200,
    scrollbarWidth: 'none',
    scrollBehavior: 'smooth',
    transition: 'all 0.5s',
  },

}));

const ReportCard = (props) => {
  const classes = useStyle();

  const [scrollRight, setScrollRight] = useState(true);
  const [scrollLeft, setScrollLeft] = useState(false);
  const reports = document.getElementById('dashboard-reports');
  let scroll = 0;
  const scrollR = () => {
    try {
      const MaxScroll = reports.scrollWidth - reports.offsetWidth;
      scroll = reports.scrollLeft + reports.offsetWidth / 2;
      reports.scrollTo(scroll, 0);
      setScrollLeft(true);
      if (scroll > MaxScroll) {
        setScrollRight(false);
      }
    } catch {}
  };
  const scrollL = () => {
    try {
      scroll = reports.scrollLeft - reports.offsetWidth / 2;
      reports.scrollTo(scroll, 0);
      setScrollRight(true);
      if (scroll <= 0) {
        setScrollLeft(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className={classes.reportsContainer}>
      <div
        className={scrollRight ? classes.reportsGradientR : null}
        onClick={scrollR}
      />
      <div
        className={scrollLeft ? classes.reportsGradientL : null}
        onClick={scrollL}
      />
      <div className={classes.reports} id="dashboard-reports">
        { props.children }
      </div>
    </div>
  );
};

export default ReportCard;
