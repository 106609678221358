import { useCallback, useState } from 'react';
import AuthorizedApiClient from '../core/common/api/AuthorizedApiClient';
import useCompany from './useCompany';

const sg = new AuthorizedApiClient();

const useStoreLocations = ({ initialLoad = false }) => {
  const { company: { id } } = useCompany();
  const [movements, setMovements] = useState({
    data: [],
    loading: initialLoad,
    error: null,
  });

  const getLocations = useCallback(async (params = {}) => {
    const path = `companies/${id}/locations`;

    try {
      const response = await sg.get(params, path);
      setMovements({
        data: response.data.data,
        loading: false,
        error: null,
      });
    } catch (error) {
      setMovements({
        data: [],
        loading: false,
        error,
      });
    }
  }, [id]);

  return {
    ...movements,
    getLocations,
  };
};

export default useStoreLocations;
