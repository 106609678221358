/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { editLocation } from '../../../../../actions/location';
import Grid from '../../../../../components/ui/Layout/Grid';
import LocationCard from '../LocationCard';
import Box from '../../../../../components/ui/Layout/Box';
import AuthorizedApiClient from '../../../../../core/common/api/AuthorizedApiClient';
import ActivityIndicator from '../../../../../components/ui/ActivityIndicator';

function getLocations(companyId, params): Promise<any> {
  const sg = new AuthorizedApiClient();
  const path = `companies/${companyId}/locations`;

  return sg.get(params, path);
}

export default ({ branch }) => {
  const company = useSelector((state: any) => state.session.company);
  const [locations, setLocations] = useState([]);
  const [processing, setProcessing] = useState(true);

  const dispatch = useDispatch();
  const showDetails = (location) => {
    dispatch(editLocation(location));
  };

  const _getLocations = useCallback(
    async (params: any = {}) => {
      if (company && branch) {
        params.per_page = 1000; // TODO: check it.
        params.filters = { branch_id: branch.id };
        setProcessing(true);
        try {
          const response = await getLocations(company.id, params);
          setProcessing(false);
          setLocations(response.data.data);
        } catch (e) {
          setProcessing(false);
        }
      }
    },
    [company, branch],
  );

  useEffect(() => {
    if (company) {
      _getLocations();
    }
  }, [company, _getLocations]);

  const list = locations.map(location => (
    <Grid
      item
      xs={12}
      sm={6}
      key={location.id}
      onClick={() => showDetails(location)}
    >
      <LocationCard location={location} />
    </Grid>
  ));

  return (
    <Box mb={3}>
      {processing ? (
        <ActivityIndicator />
      ) : (
        <Grid container spacing={3}>
          {list}
        </Grid>
      )}
    </Box>
  );
};
