/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import { Grid } from '@mui/material';
import React from 'react';
import { makeStyles } from '@mui/styles';

const styles = makeStyles(theme => ({
  container: {
    width: '50%',
    height: 24,
    borderRadius: 12,
    padding: 2,
    backgroundColor: theme.colors.cardPurple.main,
    bottom: -12,
    left: '25%',
    position: 'absolute',
  },
  font: {
    color: 'white',
    fontSize: 18,
    fontWeight: 'bold',
    position: 'relative',
    display: 'block',
    width: '100%',
    height: '100%',
    textAlign: 'center',
    '&:before': {
      content: "'$'",
      position: 'absolute',
      left: 6,
    },
  },
}));

export default (props) => {
  const classes = styles();

  return (
    <Grid className={`${classes.container}`}>
      <span className={classes.font}>{props.price}</span>
    </Grid>
  );
};
