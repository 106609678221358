/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { makeStyles } from '@mui/styles';

const styles = makeStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    backgroundColor: theme.colors.cardPurple.main,
    color: theme.colors.white,
    borderRadius: 15,
    fontSize: 30,
    padding: 15,
    width: '100%',
    maxWidth: 500,
    position: 'relative',
    cursor: 'pointer',
    margin: 5,
    height: 'max-content',
    [theme.breakpoints.down('md')]: {
      fontSize: 21,
    },
  },
  containerSecondary: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    backgroundColor: theme.colors.white,
    color: theme.colors.cardPurple.main,
    border: `solid 1px ${theme.colors.cardPurple.main}`,
    borderRadius: 15,
    fontSize: 30,
    padding: 15,
    width: '100%',
    maxWidth: 500,
    position: 'relative',
    cursor: 'pointer',
    margin: 5,
    height: 'max-content',
    [theme.breakpoints.down('md')]: {
      fontSize: 21,
    },
  },
  price: {
    '&::before': {
      content: "'$'",
    },
  },
  arrow: {
    position: 'absolute',
    display: 'block',
    width: 26,
    height: 26,
    right: 20,
    [theme.breakpoints.down('md')]: {
      width: 21,
      height: 21,
      right: 15,
    },
    '&::after': {
      content: "''",
      display: 'block',
      backgroundColor: theme.colors.white,
      width: '85%',
      height: 5,
      position: 'absolute',
      top: 'calc(25% - 2px)',
      transform: 'rotate(45deg)',
      [theme.breakpoints.down('md')]: {},
    },
    '&::before': {
      content: "''",
      display: 'block',
      backgroundColor: theme.colors.white,
      width: '85%',
      height: 5,
      position: 'absolute',
      top: 'calc(75% - 3px)',
      transform: 'rotate(-45deg)',
      [theme.breakpoints.down('md')]: {},
    },
  },
}));

interface BProps {
  handleOnClick: any;
  title: string;
  variant?: string;
}
export default (props: BProps) => {
  const classes = styles();
  const variant = () => {
    if (props.variant === 'secondary') {
      return classes.containerSecondary;
    }
    return classes.container;
  };
  return (
    <div className={variant()} onClick={() => props.handleOnClick()}>
      {props.title}
      <span className={classes.arrow} />
    </div>
  );
};
