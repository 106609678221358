/* eslint-disable default-case */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/prop-types */
import React, { useCallback } from 'react';
import { makeStyles } from '@mui/styles';
import { Grid } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import Cart from './Cart';
import Voucher from './Voucher';
import { saveCart, setSellDrawer, setStep } from '../../../../../actions/cart';
import {
  PAYMENT_TYPE,
  SALE_SOURCE,
  SALE_STATUS,
} from '../../../../../constants/common';

const styles = makeStyles(theme => ({
  drawerPaper: {
    position: 'fixed',
    width: '80%',
    maxWidth: 800,
    zIndex: 90,
    border: '1px solid black',
    bottom: 0,
    borderBottom: 'none',
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
    padding: 25,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    transition: 'height 0.5s',
    boxSizing: 'border-box',
    height: 203,
    [theme.breakpoints.down('lg')]: {
      width: '90%',
    },
  },
  open: {
    height: '80%',
  },
}));

export default ({ setClientAlert }) => {
  const classes = styles();
  const dispatch = useDispatch();

  const cart = useSelector((state: any) => state.cart);
  const pos = useSelector((state: any) => state.pos);
  const company = useSelector((state: any) => state.session.company);

  const { step, displayed } = cart;

  const openDrawer = () => {
    dispatch(setSellDrawer());
  };

  const parseData = useCallback(() => ({
    branch_id: pos.branch_id,
    number: cart.ticket.number,
    source: cart.source ? cart.source : SALE_SOURCE.POS,
    payment_type: cart.payment_type ? cart.payment_type : PAYMENT_TYPE.CASH,
    status: cart.status ? cart.status : SALE_STATUS.Complete,
    customer_id: cart.customer ? cart.customer.value : '',
    date: cart.date,
    subtotal: cart.subtotal ? cart.subtotal : 0,
    discount: cart.discount ? cart.discount : 0,
    tax: cart.tax ? cart.tax : 0,
    items: cart.items.map(product => ({
      line_id: product.line_id,
      quantity: product.quantity,
      variant_id: product.variants[0].id,
      modifiers_groups: product.selected_modifiers.map(modifier => ({
        modifier_group_id: modifier.modifierId,
        items: modifier.products.map(productVariant => ({
          variant_id: productVariant.id,
          quantity: productVariant.quantity * product.quantity,
        })),
      })),
    })),
  }), [cart, pos]);

  const _saveCart = useCallback(async () => {
    if (company) {
      const data = parseData();
      // console.log("data", data);
      await dispatch(saveCart(company.id, data));
    }
  }, [dispatch, company, parseData]);

  const nextStep = async () => {
    switch (step) {
      case 1:
        if (cart.items.length > 0) {
          if (!cart.displayed) {
            dispatch(setSellDrawer());
          } else {
            dispatch(setStep(step + 1));
          }
        }

        break;

      case 2:
        await _saveCart();
        break;
    }
  };

  return (
    <Grid
      className={`${classes.drawerPaper} ${displayed ? classes.open : ''}`}
    >
      {cart.completed ? (
        <Voucher price={cart.subtotal} cart={cart} />
      ) : (
        <Cart
          openDrawer={() => openDrawer()}
          displayed={displayed}
          nextStep={nextStep}
          setClientAlert={setClientAlert}
        />
      )}
    </Grid>
  );
};
