/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { logout } from '../../../actions/auth';
import images from '../../../theme/ECTheme/assets/images';
import Text from '../../Text/Text';
import AddButton from '../../ui/Buttons/BlueButton/AddButton';
import Grid from '../../ui/Layout/Grid';
import Modal from '../../ui/Modals/Modal';

interface Props {
  open: boolean
}

const PermissionAccount = ({ open }: Props) => {
  const { t } = useTranslation('general');
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();

  const handleLogout = async () => {
    await dispatch(logout());
    history.push('/login');
  };

  const actions = (
    <AddButton onClick={handleLogout} fullWidth>
      <Text>
        Entiendo
      </Text>
    </AddButton>
  );

  return (
    <Modal style={{ backgroundColor: '#000000BB' }} open={open} actions={actions}>
      <Grid className={classes.container}>
        <Text variant="h5" align="center">
          {t('permissionAccount.title')}
        </Text>
        <Text variant="subtitle1" htmlColor="gray">
          {t('permissionAccount.description')}
        </Text>
        <img className={classes.img} src={images.Icon_blockUser} alt="you don't have permissions" />
      </Grid>
    </Modal>
  );
};

const useStyles = makeStyles({
  img: {
    width: 'auto',
    height: 120,
    objectFit: 'contain',
  },
  container: {
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '20px',
  },
});
export default PermissionAccount;
