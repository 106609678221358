import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import UserFormView from './UserFormView';

import {
  saveUser,
  closeModalUser,
} from '../../../../../../actions/user';

export default () => {
  const dispatch = useDispatch();
  const { company } = useSelector((state: any) => state.session);
  const {
    user,
    error,
    processing,
    showModal,
  } = useSelector((state: any) => state.user);

  const handleClose = () => {
    dispatch(closeModalUser());
  };

  const attemptSaveUser = async (data) => {
    await dispatch(saveUser(data, company.id));
  };

  return (
    <UserFormView
      user={user}
      attemptSaveUser={data => attemptSaveUser(data)}
      processing={processing}
      error={error}
      handleClose={handleClose}
      showModal={showModal}
    />
  );
};
