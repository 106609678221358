import React from 'react';
import { useTranslation } from 'react-i18next';
import CategoriesList from './CategoriesList';
import SectionListContainer from '../../../../hoc/SectionListContainer';
import Grid from '../../../../components/ui/Layout/Grid';
import PageContainer from '../../../../hoc/PageContainer';
import Box from '../../../../components/ui/Layout/Box';
import AddButton from '../../../../components/ui/Buttons/BlueButton/AddButton';
import CategoryForm from './CategoryForm';
import { useSession } from '../../../../util/session';
import { PERMISSION, RESOURCES } from '../../../../constants/common';

type CategoryProps = {
  openNewCategory: any;
};
export default ({ openNewCategory }: CategoryProps) => {
  const { t } = useTranslation('general');
  const { can } = useSession();
  return (
    <>
      {(can(RESOURCES.categories, PERMISSION.create)
        || can(RESOURCES.categories, PERMISSION.create)) && <CategoryForm />}

      <Grid container>

        <PageContainer>
          {can(RESOURCES.categories, PERMISSION.create) && (
            <Box mt={3}>
              <AddButton onClick={openNewCategory}>
                {t('create_category')}
              </AddButton>
            </Box>
          )}

          <SectionListContainer>
            <CategoriesList />
          </SectionListContainer>
        </PageContainer>
      </Grid>
    </>
  );
};
