export const ENVIRONMENT = process.env.NODE_ENV;
export const DEFAULT_FROM_CODE = 'EUR';
export const DEFAULT_TO_CODE = 'USD';
export const REQUEST_FREQUENCY = 60;
export const DEBUG = process.env.NODE_ENV;
export const SCROLL_PER_PAGE = 50;
export const AUTOCOMPLETE_PER_PAGE = 20;
export const TABLE_PER_PAGE = 20;
export const PAGINATED_TABLE_PER_PAGE = 10;
export const DEFAULT_CATEGORY_COLOR = '#EB4A4F';
export const TICKET_EXPIRATION_SECONDS = 1800;
export const PAYMENT_METHODS_URL = '/user/payment_methods';
export const ECUBI_MARKET_URL = 'https://market.ecubi.mx';

export const DEFAULT_COORDINATES = {
  lat: 19.4212808,
  lng: -99.2091955,
};

export enum PaymentVendors {
  CONEKTA = 'conekta',
  STRIPE = 'stripe',
}

export enum CustomerPaymentMethods {
  CARD = 'card',
}

export const BLANK_RECOUNT = 1;
export const COMPARED_RECOUNT = 2;

export const EMPTY_QUANTITY = '-';

export const MOVEMENT_TYPE = {
  INCOME: 1,
  OUTCOME: 2,
  ADJUSTMENT: 3,
};

export const MOVEMENT_PARENT_TYPE = {
  Sale: 1,
  Purchase: 2,
  Count: 3,
  Order: 4,
};

export const PAYMENT_TYPE = {
  CASH: 'cash',
  CREDIT_CARD: 'credit-card',
  CARD: 'card',
  CODI: 'codi',
  CREDIT: 'credit',
  VOUCHER: 'voucher',
  TRANSFE: 'transfer',
};

export const SALE_STATUS = {
  Open: 'open',

  Cancelled: 'cancelled',

  Complete: 'complete',
};

export const SALE_SOURCE = {
  POS: 'pos',
  MARKET_PLACE: 'market-place',
};

export const GENRES = {
  MALE: 'M',
  FEMALE: 'F',
  OTHER: 'O',
};

export const ROLES = {
  super: 'super',
  owner: 'owner',
  seller: 'seller',
};

export const ECUBIROL = {
  administrator: 'administrator',
};

export const RESOURCES = {
  store_profile: 'store_profile',
  products: 'products',
  categories: 'categories',
  brands: 'brands',
  suppliers: 'suppliers',
  units: 'units',
  products_reports: 'products_reports',
  stock: 'stock',
  movements: 'movements',
  blank_count: 'blank_count',
  compared_count: 'compared_count',
  locations: 'locations',
  branches: 'branches',
  sales: 'sales',
  pos: 'pos',
  users: 'users',
  subscriptions: 'subscriptions',
  customers: 'customers',
  company: 'company',
  comments: 'comments',
  dashboard: 'dashboard',
  ecubi_dashboard: 'ecubi_dashboard',
  ecubi_orders: 'ecubi_orders',
  finances: 'finances',
  aprove_acount: 'aprove_acount',
  pending_acount: 'pending_acount',
  sales_reports: 'sales_reports',
  stock_reports: 'stock_reports',
  movements_reports: 'movements_reports',
  counts: 'counts',
  pos_users: 'pos_users',
};

export const SECTIONS = {
  inventory: {
    name: 'inventory',
    resources: [
      RESOURCES.products,
      RESOURCES.categories,
      RESOURCES.brands,
      RESOURCES.suppliers,
      RESOURCES.units,
      RESOURCES.products_reports,
    ],
  },
  reports: {
    name: 'reports',
    resources: [
      RESOURCES.products_reports,
      RESOURCES.sales,
      RESOURCES.movements,
    ],
  },
  store: {
    name: 'store',
    resources: [RESOURCES.company, RESOURCES.sales, RESOURCES.comments],
  },
  admin: {
    name: 'admin',
    resources: [
      RESOURCES.ecubi_dashboard,
      RESOURCES.ecubi_orders,
    ],
  },
};

export const FEATURES_TYPE = {
  custom_subdomain: 'custom_subdomain',
  orders_fee: 'orders_fee',
  limits: 'limits',
  pos_resources: 'pos_resources',
};

export const PERMISSION = {
  access: 'access',
  read: 'read',
  create: 'create',
  edit: 'edit',
  delete: 'delete',
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export const EXCEl_LIMIT_ROWS = 500;

export enum ShopThemes {
  CATEGORIES = 'categories',
  BRANDS = 'brands',
  SUPPLIERS = 'suppliers',
}

export enum OrderStatuses {
  Pending = 'pending',
  Accepted = 'accepted',
  Preparing = 'preparing',
  Rejected = 'rejected',
  Cancelled = 'cancelled',
  Sent = 'sent',
  Shipped = 'shipped',
  Complete = 'complete',
  Returned = 'returned',
}

export const orderStatusesArr = [
  OrderStatuses.Pending,
  OrderStatuses.Accepted,
  OrderStatuses.Rejected,
  OrderStatuses.Preparing,
  OrderStatuses.Sent,
  OrderStatuses.Returned,
  OrderStatuses.Shipped,
  OrderStatuses.Cancelled,
];

export const allowedOrderStatuses = {};
allowedOrderStatuses[OrderStatuses.Pending] = [
  OrderStatuses.Accepted,
  OrderStatuses.Rejected,
  OrderStatuses.Cancelled,
];

allowedOrderStatuses[OrderStatuses.Accepted] = [
  OrderStatuses.Preparing,
  OrderStatuses.Sent,
  OrderStatuses.Shipped,
  OrderStatuses.Cancelled,
];

allowedOrderStatuses[OrderStatuses.Preparing] = [
  OrderStatuses.Sent,
  OrderStatuses.Shipped,
  OrderStatuses.Cancelled,
];

allowedOrderStatuses[OrderStatuses.Sent] = [OrderStatuses.Shipped];

export enum ShipmentMethods {
  BUY_IN_SHOP = 'buy_in_shop',
  DELIVERY = 'delivery',
  SHOPPICKUP = 'shop_pickup',
}

export enum WeekDays {
  SUNDAY = 0,
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
}

export interface Schedule {
  start_hour: string;
  end_hour: string;
}

export enum BusinessType {
  FARMER = 'farmer',
  PROCESOR = 'procesor',
  COMMERCE = 'commerce',
  RESTAURANT = 'restaurant',
  OTHER = 'other',
}

export enum ProductionVolume {
  BIG = 'big',
  MEDIUM = 'medium',
  SMALL = 'small',
  MICRO = 'micro',
}

export enum CustomerType {
  FINAL_CONSUMER = 'final_consumer',
  WHOLESALE = 'wholesale',
  RETAIL = 'retail',
  CORPORATIVE = 'corporative',
  INTERNATIONAL = 'international',
}

export enum DeliveryRange {
  CLOSE = 'close',
  CITY = 'city',
  NATIONAL = 'national',
  NONE = 'none',
  OTHER = 'other',
}

export enum ProductsStock {
  STOCK = 'stock',
  AVAILABILITY = 'availability',
}

export enum SaleSource {
  POS = 'pos',
  MARKET_PLACE = 'market-place',
}

export const MINUTES_IN_DAY = 1440;
export const MINUTES_IN_HOUR = 60;

export enum CampaignStatus {
  Active = 'active',
  Inactive = 'inactive',
}

export const MAX_VIDEO_SIZE = 10485760;
export const MAX_IMAGE_SIZE = 2048000;

export const FILTER_ALL_VALUE = '-1';

export enum ViewFormats {
  EXCEL = 'excel',
  PDF = 'pdf',
}
