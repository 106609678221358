/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable consistent-return */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/prop-types */
import { Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import AsyncPaginatedTable, {
  usePaginatedTable,
} from '../../../../../../components/ui/AsyncPaginatedTable';
import FormattedCurrency from '../../../../../../components/ui/FormattedCurrency';
import Box from '../../../../../../components/ui/Layout/Box';
import OrderDate from './OrderDate';

import { getFormattedDate } from '../../../../../../util/dates';
import Grid from '../../../../../../components/ui/Layout/Grid';
import DateFilter from '../../../../../../components/ui/DateFilter';
import OrderStatus from '../OrderStatus';
import OrderStatusFilter from '../OrderStatusFilter';
import OrderPaymentStatus from '../OrderPaymentStatus';
import Alert from '../../../../../../components/ui/notifications/alert';
import OrderListPaymentMethod from '../../../../../../components/ui/OrderListPaymentMethod';
import OrderListDeliveryMethod from '../../../../../../components/ui/OrderListDeliveryMethod';
import OrderCampaignFilter from '../../../../../../components/ui/OrderCampaignFilter';
import { useSession } from '../../../../../../util/session';

export default ({
  state,
  getOrders,
  resetOrders,
  setError,
  error = null,
  message = null,
  setMessage,
  redirectButton,
}) => {
  const { company } = useSession();
  const { t } = useTranslation(['general']);
  const { filters } = state.params;
  const startDate = filters && filters.created_at
    ? filters && filters.created_at.$gte
    : null;

  const endDate = filters && filters.created_at
    ? filters && filters.created_at.$lte
    : null;

  const { data } = state.response;

  const [currentCampaign, setCurrentCampaign] = useState({
    value: '',
    label: '',
  });

  const [visibleColumns, setVisibleColumns] = useState([
    'created_at',
    'number',
    // "branch",
    'campaigns',
    'user',
    'items',
    'total',
    'status',

    'paid',
  ]);

  const { handleViewColumnsChange, isVisibleColumn } = usePaginatedTable();

  const onChangedStatus = useCallback(
    (order) => {
      setMessage(
        t('general:orders_page:changed_status_success_msg', {
          order_number: order.number,
        }),
      );
      getOrders();
    },

    [t, setMessage, getOrders],
  );

  const onStartToChangeStatus = useCallback(
    () => {
      setMessage(null);
    },
    [setMessage],
  );

  const isVisible = useCallback(
    column => isVisibleColumn(visibleColumns, column),
    [visibleColumns, isVisibleColumn],
  );

  const columns = [
    {
      name: 'created_at',
      label: t('general:date'),
      filterName: 'created_at',
      options: {
        filter: true,
        filterType: 'custom',
        display: isVisible('created_at'),
        filterOptions: {
          display: (filterList, onChange, index, column) => (
            <Box mt={2}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <DateFilter
                    label={t('general:filters:from_date')}
                    value={
                      state.params.filters && state.params.filters.created_at
                        ? state.params.filters
                          && state.params.filters.created_at.$gte
                        : null
                    }
                    maxDate={endDate || new Date()}
                    onChange={(date) => {
                      const dates = [];
                      if (date) {
                        dates[0] = getFormattedDate(date);
                      } else {
                        dates[0] = '';
                      }
                      if (endDate) {
                        dates[1] = getFormattedDate(endDate);
                      } else {
                        dates[1] = dates[0];
                      }
                      try {
                        filterList[index][0] = date ? dates.join('|') : '';
                        onChange(filterList[index], index, column);
                      } catch (e) {
                        console.log('error date filter', e.message);
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <DateFilter
                    label={t('general:filters:from_to')}
                    value={endDate}
                    minDate={startDate || null}
                    maxDate={new Date()}
                    onChange={(date) => {
                      const dates = [];
                      if (date) {
                        dates[1] = getFormattedDate(date);
                      } else {
                        dates[1] = '';
                      }
                      if (startDate) {
                        dates[0] = getFormattedDate(startDate);
                      } else {
                        dates[0] = dates[1];
                      }
                      try {
                        filterList[index][0] = date ? dates.join('|') : '';
                        onChange(filterList[index], index, column);
                      } catch (e) {
                        console.log('error date filter', e.message);
                      }
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          ),
        },
        customFilterListOptions: {
          render: (v) => {
            if (Array.isArray(v)) {
              const dates = v[0].split('|');
              if (dates[0] === dates[1]) {
                return dates[0];
              }
              return (
                `${dates[0]} ${t('general:filters:from_to')} ${dates[1]}`
              );
            }
            return [];
          },
        },
        sort: false,
        customBodyRender: (value) => {
          if (value) {
            return <OrderDate value={value} />;
          }
          return '';
        },
      },
      customRenderer: value => <OrderDate value={value} />,
    },

    {
      name: 'number',
      label: t('general:order_number'),

      options: {
        filter: false,
        sort: false,
        display: isVisible('number'),
        customBodyRender: (value, tableMeta) => {
          const orderId = data[tableMeta.rowIndex].id;

          return (
            <Link to={`/orders/${orderId}`}>
              #
              {value}
            </Link>
          );
        },
      },
    },

    {
      name: 'branch',
      label: t('general:branch'),

      options: {
        filter: false,
        sort: false,
        display: isVisible('branch'),
        customBodyRender: (branch) => {
          if (branch) {
            return branch.name;
          }
          return '';
        },
      },
    },

    {
      name: 'campaigns',
      label: t('general:shared_campaigns:campaign'),

      options: {
        filter: true,
        sort: false,
        display: isVisible('campaigns'),
        filterType: 'custom',

        filterOptions: {
          display: (filterList, onChange, index, column) => (
            <Grid item xs={12}>
              {company && company.id && (
                <OrderCampaignFilter
                  onChange={(campaign) => {
                    setCurrentCampaign(campaign);
                    filterList[index][0] = campaign ? campaign.value : '';
                    onChange(filterList[index], index, column);
                  }}
                  current={
                    filters && filters.campaign_id ? currentCampaign : null
                  }
                  companyId={company.id}
                />
              )}
            </Grid>
          ),
        },
        customBodyRender: (campaigns) => {
          if (campaigns && campaigns.length > 0) {
            const names = campaigns.map(c => c.name);
            return names.join(', ');
          }
          return '';
        },
      },
    },

    {
      name: 'user',
      label: t('general:customer'),

      options: {
        filter: false,
        sort: false,
        display: isVisible('user'),
        customBodyRender: (user) => {
          if (user) {
            return <Typography>{`${user.name} ${user.last_name}`}</Typography>;
          }
        },
      },
    },
    {
      name: 'items',
      label: t('general:items'),

      options: {
        filter: false,
        sort: false,
        display: isVisible('items'),
        customBodyRender: (items) => {
          if (items) {
            return <Typography>{items.length}</Typography>;
          }
          return 0;
        },
      },
    },
    {
      name: 'total',
      label: t('general:total'),

      options: {
        filter: false,
        sort: false,
        display: isVisible('total'),
        customBodyRender: value => (
          <Typography>
            <FormattedCurrency value={value} />
          </Typography>
        ),
      },
    },
    {
      name: 'status',
      label: t('general:status'),

      options: {
        filter: true,
        sort: false,
        filterType: 'custom',
        display: isVisible('status'),
        customFilterListOptions: {
          render: v => v.map(status => (status ? t(`general:orders_page:${status}`) : null)),
          update: (filterList, filterPos, index) => {
            filterList[index].splice(filterPos, 1);
            return filterList;
          },
        },
        filterOptions: {
          logic: (location, filters) => {
            if (filters.length) return !filters.includes(location);
            return false;
          },
          display: (filterList, onChange, index, column) => (
            <Grid item xs={12}>
              <OrderStatusFilter
                onChange={(value) => {
                  filterList[index][0] = value;
                  onChange(filterList[index], index, column);
                }}
                value={filters && filters.status ? filters.status : ''}
              />
            </Grid>
          ),
        },
        customBodyRender: (value, tableMeta) => {
          const order = data[tableMeta.rowIndex];
          return (
            <OrderStatus
              order={order}
              setError={setError}
              onChangedStatus={onChangedStatus}
              onStartToChangeStatus={onStartToChangeStatus}
            />
          );
        },
      },
    },
    {
      name: 'paid',
      label: t('general:paid'),

      options: {
        filter: false,
        sort: false,
        display: isVisible('paid'),
        customBodyRender: value => <OrderPaymentStatus paid={value} />,
      },
    },
    {
      name: 'payment_type',
      label: t('general:payment_method'),

      options: {
        filter: false,
        sort: false,
        display: isVisible('payment_type'),
        customBodyRender: value => <OrderListPaymentMethod value={value} />,
      },
    },
    {
      name: 'shipping_method',
      label: t('general:shipping_method'),

      options: {
        filter: false,
        sort: false,
        display: isVisible('shipping_method'),
        customBodyRender: value => <OrderListDeliveryMethod value={value} />,
      },
    },
  ];

  const _handleViewColumnsChange = (changedColumn: string, action: string) => {
    handleViewColumnsChange(
      changedColumn,
      action,
      visibleColumns,
      setVisibleColumns,
    );
  };

  return (
    <Box>
      <Box mt={3}>
        {error && (
          <Box mb={2}>
            <Alert
              severity="error"
              message={t(`general:orders_page:${error}`, error)}
            />
          </Box>
        )}
        {message && (
          <Box mb={2}>
            <Alert severity="success" message={message} />
          </Box>
        )}
        <AsyncPaginatedTable
          redirectButton={redirectButton}
          hasNextPage={state.response.page < state.response.last_page}
          currentPage={state.response.page}
          perPage={state.response.per_page}
          data={data}
          columns={columns}
          loadData={params => getOrders(params)}
          resetData={resetOrders}
          processing={state.processing}
          total={state.response.total}
          options={{
            filter: true,
            search: true,
            viewColumns: true,
            onViewColumnsChange: _handleViewColumnsChange,
          }}
          title={t('general:store_page:orders')}
          filterFields={[{ field: 'created_at', type: 'date_range' }]}
          searchField="number"
        />
      </Box>
    </Box>
  );
};
