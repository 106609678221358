/* eslint-disable react/no-unstable-nested-components */
import React, { useState } from 'react';
import {
  Box,
  Grid, Paper, Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { OrdersBarChart } from '../components/OrdersBarChart';
import { DatePicker } from '../../../../../components/DatePicker/DatePicker';
import { OrdersTable } from '../components/OrdersTable';
import { WidgetStat } from '../../../../../components/WidgetStat/WidgetStat';

const today = new Date();
const daysAgo = new Date().setDate(today.getDate() - 30);
const DEFAULT_START_DATE = new Date(daysAgo);
const DEFAULT_END_DATE = today;

const useStyles = makeStyles(() => ({
  mainContainer: { gap: '1rem' },
  dividerTitle: { padding: '1rem 2rem', display: 'inline-block' },
}));

export const MainPage = () => {
  const classes = useStyles();
  const { t } = useTranslation('general');
  const [startDate, setStartDate] = useState(DEFAULT_START_DATE);
  const [endDate, setEndDate] = useState(DEFAULT_END_DATE);

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  return (
    <Grid className={classes.mainContainer} container direction="column">
      <Grid>
        <Paper className={classes.dividerTitle}>
          <Typography variant="body2">
            CEDIS 1
          </Typography>
        </Paper>
      </Grid>

      <Grid>
        <Paper>
          <Box padding="1rem 2rem" display="flex">
            <Typography variant="h4" style={{ flex: 1 }}>
              {t('routes.delivery_and_orders')}
            </Typography>
            <Box display="flex" flexBasis="30%" gap={6}>
              <DatePicker
                label="Desde"
                date={startDate}
                handleChange={handleStartDateChange}
              />
              <DatePicker
                label="Hasta"
                date={endDate}
                handleChange={handleEndDateChange}
              />
            </Box>
          </Box>
        </Paper>
      </Grid>

      <Grid>
        <OrdersBarChart />
      </Grid>

      <Grid>
        <Paper>
          <Box padding="1rem 2rem">
            <Typography variant="h6">
              {t('routes.general_info_message')}
            </Typography>
          </Box>
        </Paper>
      </Grid>

      <Grid
        container
        direction="row"
        alignItems="stretch"
        justifyContent="space-between"
      >
        <WidgetStat
          title={t('routes.total_delivery')}
          value="432"
        />
        <WidgetStat
          title={t('routes.main_statuses.waiting_for_route')}
          value="123"
        />
        <WidgetStat
          title={t('routes.main_statuses.in_trouble')}
          value="16"
        />
        <WidgetStat
          title={t('routes.main_statuses.in_progress')}
          value="231"
        />
        <WidgetStat
          title={t('routes.main_statuses.shipped')}
          value="178"
        />
      </Grid>

      <OrdersTable />

    </Grid>
  );
};
