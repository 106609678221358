/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useContext, useEffect, useState } from 'react';
import {
  Grid,
  Typography,
  Button,
  Divider,
  IconButton,
  Collapse,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MessengerCustomerChat from 'react-messenger-customer-chat';
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import { ShepherdTourContext } from 'react-shepherd';
import { makeStyles } from '@mui/styles';
import PointSaleQuickAccess from './PointSaleDrawer';
import Icon from '../../../../components/ui/Icon';
import ReportsView from './ReportCard/ReportsView';
import QuickAccess from './QuickAccess';
import History from './History';
import { useSession } from '../../../../util/session';
import {
  FEATURES_TYPE, PERMISSION, RESOURCES, SECTIONS,
} from '../../../../constants/common';

import ProductWarning from './ProductWarning';
import DayInformation from './dayInformation';
import BaseReport from './ReportCard/BaseReport';

import images from '../../../../theme/ECTheme/assets/images';
import { ModalGetPremium } from '../../../../components/ModalGetPremium';
import { PilotPremium } from '../../../../components/Modals/PilotPremium';
import { PremiumTrial } from '../../../../components/Modals/PremiumTrial';
import SectionListContainer from '../../../../hoc/SectionListContainer';
import OrdersList from '../StorePage/OrdersPage/OrdersList';
import { Header } from './Header/Header';

const useStyle = makeStyles(theme => ({
  marketSales: {
    mask: `url(${theme.icons.ecommerceBag})`,
    maskSize: '60% auto !important',
  },
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  containerSectionIcon: {
    boxSizing: 'border-box',
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    margin: 40,
    maxWidth: 600,
    [theme.breakpoints.down('md')]: {
      width: '80%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  icons: {
    height: 66,
    width: 66,
    margin: '15px',
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      height: 52,
      width: 52,
    },
  },
  camping: {
    height: 70,
    width: 70,
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      height: 52,
      width: 52,
    },
  },
  iconsText: {
    marginTop: 11,
    width: '100%',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
    },
  },
  iconsTextCamping: {
    marginTop: -7,
    width: '100%',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
    },
  },

  divider: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    padding: '0px 10px',
  },
  dividerButton: {
    height: 20,
    borderRadius: 10,
    marginLeft: 10,
  },
  reportText: {
    fontSize: 20,
    color: theme.colors.black,
    margin: 0,
  },

  containerSection2: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    margin: 20,
    maxWidth: 840,
  },

  containerSection3: {
    width: '100%',
    display: 'flex',
    maxWidth: 840,
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
    },
  },
  subSection: {
    width: '100%',
  },

  QuickAccess: {
    display: 'grid',
    justifyItems: 'center',
    gridTemplateColumns: 'repeat(2, 1fr)',
    margin: '20px 0px 40px 0px',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'nowrap',
      overflowX: 'auto',
    },
  },

  premiumIcon: {
    position: 'absolute',
    top: -5,
    right: -10,
    width: 25,
    height: 25,
  },
}));

export const HomePage = () => {
  const classes = useStyle();
  const { t } = useTranslation(['general']);
  const tour = useContext(ShepherdTourContext);
  const { can, company } = useSession();
  // const shopName = company ? company.name : '';
  const [openAlert, setOpenAlert] = React.useState(true);

  const canStockReports = can(
    RESOURCES.stock_reports,
    PERMISSION.access,
    FEATURES_TYPE.pos_resources,
  );
  const canMovementsReports = can(
    RESOURCES.movements_reports,
    PERMISSION.access,
    FEATURES_TYPE.pos_resources,
  );
  const canLocations = can(
    RESOURCES.locations,
    PERMISSION.access,
    FEATURES_TYPE.pos_resources,
  );

  // console.log({ canStockReports, panning: planning.plan })

  // ----warning handler
  const [warning, setWarning] = useState(false);
  const handleOpenWarning = () => {
    setWarning(true);
  };
  // ----warning handler
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const _quickAccess = [
    { name: t('general:inventory_page:products:products_services'), section: 'products', link: 'products/all' },
    { name: t('general:clients'), section: 'clients', link: 'customers' },
    { name: t('general:inventory_page:products:cost'), section: 'cost', link: 'products/costs' },
    { name: t('general:inventory_recounts'), section: 'recounts', link: 'inventory/recounts' },
  ];
  const accessList = _quickAccess.map(access => (
    <QuickAccess
      key={access.name}
      title={access.name}
      link={access.link}
      section={access.section}
    />
  ));

  const _favReports = [
    { title: 'ventas', section: 'sales', link: '/reports/sales_report' },
    { title: 'Movimientos de inventraio', section: 'moviment', link: '/reports/movements_report' },
    { title: 'Existencia', section: 'existence', link: '/reports/products_report' },
  ];
  const reportsList = _favReports.map((reports) => {
    const validation = {
      sales: true,
      moviment: canMovementsReports,
      existence: canStockReports,
    };
    return (
      validation[reports.section]
        ? <BaseReport key={reports.section} {...reports} />
        : (
          <div onClick={handleOpen}>
            <BaseReport disable key={reports.section} {...reports} />
          </div>
        )
    );
  });

  useEffect(() => {
    const finishedSteps = JSON.parse(window.localStorage.getItem('finished-tour-steps'));
    if ((!finishedSteps || !finishedSteps.dashboardDone) && company.setup) {
      tour?.start();
    }
  }, [tour]);

  return (
    <div>
      <MessengerCustomerChat // TODO: Cambiar a apiconstants
        pageId="746430042198863"
        appId="330998347352516"
        language="es_LA"
      />
      <ModalGetPremium open={open} setOpen={setOpen} />
      <PilotPremium />
      <PremiumTrial />
      <Header />
      <div className={classes.container}>
        <div className={`${classes.containerSectionIcon} all-modules`}>

          {can(SECTIONS.store) && (
          <Grid className={`${classes.icons} first-element`}>
            <Link to="/products">
              <Icon section="products" />
              <Typography className={classes.iconsText}>Productos</Typography>
            </Link>
          </Grid>
          )}

          {can(SECTIONS.inventory.name) && (
            <Grid className={`${classes.icons} second-element`}>
              { canLocations
                ? (
                  <Link to="/inventory">
                    <Icon section="inventory" />
                    <Typography className={classes.iconsText}>
                      Inventario
                    </Typography>
                  </Link>
                )
                : (
                  <div style={{ width: '100%', height: '100%', position: 'relative' }} onClick={handleOpen}>
                    <Link to="">
                      <img className={classes.premiumIcon} src={images.Icon_PlanProCrown} alt="" />
                      <Icon section="inventory" />
                      <Typography className={classes.iconsText}>
                        Inventario
                      </Typography>
                    </Link>
                  </div>
                )}
            </Grid>
          )}

          {can(RESOURCES.customers) && (
            <Grid className={`${classes.icons} third-element`}>
              <Link to="/customers">
                <Icon section="client" />
                <Typography className={classes.iconsText}>Clientes</Typography>
              </Link>
            </Grid>
          )}
          {can(SECTIONS.store) && (
            <Grid className={`${classes.icons} fourth-element`}>
              <Link to="/store">
                <Icon section="store" />
                <Typography className={classes.iconsText}>{t('general:store_page:my_store')}</Typography>
              </Link>
            </Grid>
          )}
          <div className={`${classes.icons} fifth-element`}>
            <Link to="/store/campaigns">
              <Icon section="storeCamping" />
              <Typography className={classes.iconsText}>{t('general:store_page:Store_campings')}</Typography>
            </Link>
          </div>
          <div className={`${classes.icons} sixth-element`}>
            <Link to="/logistic">
              <Icon section="pickerZones" />
              <Typography className={classes.iconsText}>{t('general:store_page:logistic')}</Typography>
            </Link>
          </div>
        </div>

        <DayInformation handleOpenWarning={handleOpenWarning} />
      </div>

      <div style={{
        width: '100%', marginLeft: 'auto', marginRight: 'auto', maxWidth: 800,
      }}
      >
        <SectionListContainer>
          <Collapse in={openAlert}>
            <Alert
              severity="info"
              icon={
                <img style={{ maxWidth: 40, backgroundColor: '#EDF7ED' }} src={images.Icon_ecommerceBag} alt="Icon_ecommerceBag" />
                }
              action={(
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpenAlert(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
            )}
            >
              {t('general:ordersDisclaimer')}
            </Alert>
          </Collapse>
          <OrdersList redirectButton defaultFilters={{ status: { $in: ['accepted', 'pending', 'sent'] } }} />
        </SectionListContainer>
      </div>

      <div className={classes.container}>
        <div className={classes.containerSection2}>
          <div className={classes.divider}>
            <p className={classes.reportText}>Mis reportes favoritos</p>
            <Button
              variant="contained"
              color="primary"
              className={classes.dividerButton}
            >
              Ver más
            </Button>
          </div>
          <Divider variant="middle" />
          <ReportsView>
            { reportsList }
          </ReportsView>
        </div>

        <div className={classes.containerSection3}>
          <div className={classes.subSection}>
            <div className={classes.divider}>
              <p className={classes.reportText}>Accesos rápidos</p>
              <Button
                variant="contained"
                color="primary"
                className={classes.dividerButton}
              >
                Ver más
              </Button>
            </div>
            <Divider variant="middle" />
            <div className={classes.QuickAccess}>
              { accessList }
            </div>
          </div>
          <div className={classes.subSection}>
            <div className={classes.divider}>
              <p className={classes.reportText}>Actividades</p>
              <Button
                variant="contained"
                color="primary"
                className={classes.dividerButton}
              >
                Ver más
              </Button>
            </div>
            <Divider variant="middle" />
            <History />
          </div>
        </div>
      </div>
      {can(RESOURCES.pos) && <PointSaleQuickAccess />}
      <ProductWarning open={warning} setOpen={setWarning} />
    </div>
  );
};
