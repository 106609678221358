/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SuppliersListView from './SuppliersListView';
import {
  getSuppliers,
  resetSuppliers,
} from '../../../../../actions/suppliers';
import { SCROLL_PER_PAGE } from '../../../../../constants/common';

export default () => {
  const dispatch = useDispatch();
  const state = useSelector((states: any) => states.suppliers);
  const company = useSelector((states: any) => states.session.company);

  const _getSuppliers = useCallback(
    async (params: any = {}) => {
      if (company) {
        params.per_page = SCROLL_PER_PAGE;
        await dispatch(getSuppliers(company.id, params));
      }
    },
    [dispatch, company],
  );

  const _resetSuppliers = useCallback(() => {
    dispatch(resetSuppliers());
  }, [dispatch]);

  return (
    <SuppliersListView
      state={state}
      getSuppliers={params => _getSuppliers(params)}
      resetSuppliers={_resetSuppliers}
    />
  );
};
