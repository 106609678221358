/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import { useTranslation } from 'react-i18next';
import FormattedCurrency from '../../../../ui/FormattedCurrency';
import FormattedNumber from '../../../../ui/FormattedNumber';
import LinkButton from '../../../../ui/Buttons/LinkButton';

const Articles = ({ items }) => {
  const { t } = useTranslation(['general']);
  const totalItems = items.length;
  const [showDetails, setShowDetails] = useState(false);
  const list = items.map((item, index) => (
    <TableRow key={index}>
      <TableCell>{item.product.name}</TableCell>
      <TableCell align="center">
        <FormattedNumber value={item.quantity} />
      </TableCell>
      <TableCell align="center">
        <FormattedCurrency value={item.price} />
      </TableCell>
    </TableRow>
  ));
  return (
    <>
      {totalItems}
      {showDetails && (
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Producto</TableCell>
                <TableCell>Cantidad</TableCell>
                <TableCell>Precio</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{list}</TableBody>
          </Table>
        </TableContainer>
      )}
      <LinkButton
        onClick={() => {
          setShowDetails(!showDetails);
        }}
      >
        {showDetails ? t('general:see_less') : t('general:see_more')}
      </LinkButton>
    </>
  );
};

export default Articles;
