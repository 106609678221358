import AuthorizedApiClient from '../../common/api/AuthorizedApiClient';
import ProductsServiceContract from '../../contracts/services/ProductsServiceContract';

export default class ApiProductsService
  extends AuthorizedApiClient
  implements ProductsServiceContract {
  protected path = 'products';

  create(data: any) {
    return this.post(data);
  }
}
