/* eslint-disable react/prop-types */
import { Box, TableCell, TableRow } from '@mui/material';
import { blue, green } from '@mui/material/colors';
import React, { ChangeEvent, useState } from 'react';
import CategorySelecter from '../../../../../components/inputs/CategorySelect';
import { Switcher } from '../../../../../components/inputs/Switcher';
import UnitsSelecter from '../../../../../components/inputs/UnitSelect';
import Text from '../../../../../components/Text/Text';
import useCompany from '../../../../../hooks/useCompany';
import useProducts from '../../../../../hooks/useProducts';
import images from '../../../../../theme/ECTheme/assets/images';

export const ProductTableRow = ({ productData, onClick }) => {
  const [available, setAvailable] = useState(productData.available);
  const [published, setPublished] = useState(productData.published);
  const { company } = useCompany();
  const { updateProduct } = useProducts(company.id);

  const availableChange = (e: ChangeEvent<HTMLInputElement>) => {
    setAvailable(e.target.checked);
    // POST
    updateProduct(productData.id, {
      available: e.target.checked,
    });
  };

  const publishedChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPublished(e.target.checked);
    // POST
    updateProduct(productData.id, {
      published: e.target.checked,
    });
  };

  const categoryChange = (e: ChangeEvent<HTMLInputElement>) => {
    // POST
    updateProduct(productData.id, {
      category_id: e.target.value,
    });
  };

  const unitChange = (e: ChangeEvent<HTMLInputElement>) => {
    // POST
    updateProduct(productData.id, {
      unitofmeasure_id: e.target.value,
    });
  };

  return (
    <TableRow>
      <TableCell style={{ border: 'none', padding: 4, borderRight: '2px solid #ABABAB80' }} component="th" scope="row">
        <Box onClick={onClick} style={{ cursor: 'pointer' }} flexDirection="row" alignItems="center" display="flex">
          <img
            width={40}
            height={40}
            style={{ objectFit: 'cover', borderRadius: 5 }}
            src={productData.variants[0].image_url || images.noProductImage}
            alt={productData.name}
          />
          <Text textTruncate={2} htmlColor="gray" variant="body2" style={{ marginLeft: 5 }}>
            {productData.name}
          </Text>
        </Box>
      </TableCell>
      <TableCell style={{ border: 'none' }} align="center">
        <UnitsSelecter
          onChange={unitChange}
          defaultValue={productData.variants[0].unitofmeasure.id}
        />
      </TableCell>
      <TableCell style={{ border: 'none' }} align="center">
        {
          productData.inventoried
            ? productData.variants[0].stock
            : 'N/A'
        }
      </TableCell>
      <TableCell style={{ border: 'none' }} align="center">
        {
          !productData.inventoried
            ? (
              <Switcher
                activeHtmlColor={green[600]}
                checked={available}
                onChange={availableChange}
              />
            )
            : 'N/A'
        }
      </TableCell>
      <TableCell style={{ border: 'none' }} align="center">
        <CategorySelecter
          onChange={categoryChange}
          defaultValue={productData.category_id}
        />
      </TableCell>
      <TableCell style={{ border: 'none' }} align="center">
        $
        {productData.variants[0].cost}
      </TableCell>
      <TableCell style={{ border: 'none' }} align="center">
        $
        {productData.variants[0].price}
      </TableCell>
      <TableCell style={{ border: 'none' }} align="center">
        <Switcher
          activeHtmlColor={blue[600]}
          checked={published}
          onChange={publishedChange}
        />
      </TableCell>
    </TableRow>
  );
};
