/* eslint-disable react/button-has-type */
/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import images from '../../../../theme/ECTheme/assets/images';
import Box from '../../Layout/Box';
import AuthorizedApiClient from '../../../../core/common/api/AuthorizedApiClient';
import fileDownloader from '../../../../helpers/file-downloader';

const sg = new AuthorizedApiClient();

interface DownloadExcelParams {
  downloadExcel?: Function,
  imageHeight?: number,
  downloadData?: {
    url: string,
    params: any | null,
    fileName: string
  }

}

export default ({ downloadExcel, imageHeight = 23, downloadData }: DownloadExcelParams) => {
  const { t } = useTranslation('general');
  const [loading, setLoading] = useState(false);

  const handleDownloadExcel = useCallback(
    async () => {
      const response = await sg.downloadFile(downloadData.params, downloadData.url);

      fileDownloader.downloadFileInBrowser(response, downloadData.fileName);
    },
    [downloadData],
  );

  const startProcess = () => {
    setLoading(true);
  };

  const endProcess = () => {
    setLoading(false);
  };

  const click = async () => {
    try {
      startProcess();

      if (downloadData) {
        await handleDownloadExcel();
      } else {
        await downloadExcel();
      }
    } catch (error) {
      console.log(error);
    } finally {
      endProcess();
      setLoading(false);
    }
  };

  useEffect(() => {
    if (loading) {
      toast.loading(t('general:products_excel:downloading_file'));
    } else {
      toast.dismiss();
    }
  }, [loading]);

  const image = <img alt="download excel" style={{ height: imageHeight, width: 'auto' }} src={images.Icon_DownloadExcel} />;

  return (

    <Box
      onClick={click}
      style={{ cursor: 'pointer' }}
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="1vh"
    >
      <Tooltip
        disableInteractive
        title={t('general:products_excel:download_excel')}
      >
        {image}
      </Tooltip>
    </Box>

  );
};
