import ApiClient from '../../common/api/ApiClient';

export default class ApiAuthService extends ApiClient {
  getPath(): string {
    return 'api/v1/signin';
  }

  async login(username: string, password: string) {
    const data = {
      username,
      password,
    };
    // Log.debug("data", data);
    return this.post(data);
  }
}
