import React from 'react';
import {
  Tab,
  Paper,
  Tabs,
  TabsProps as MTabsProps,
  PaperProps,
  TabTypeMap,
  ExtendButtonBase,
} from '@mui/material';
import {
  Link,
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import { makeStyles } from '@mui/styles';

const useStyle = makeStyles(theme => ({
  productos: {
    mask: `url(${theme.icons.products})`,
  },
  categories: {
    mask: `url(${theme.icons.categories})`,
  },
  cost: {
    mask: `url(${theme.icons.cost})`,
  },
  brands: {
    mask: `url(${theme.icons.brands})`,
  },
  suppliers: {
    mask: `url(${theme.icons.providers})`,
  },
  units: {
    mask: `url(${theme.icons.units})`,
  },
  movements: {
    mask: `url(${theme.icons.movements})`,
  },
  recounts: {
    mask: `url(${theme.icons.recounts})`,
  },
  locations: {
    mask: `url(${theme.icons.locations})`,
  },
  branches: {
    mask: `url(${theme.icons.branches})`,
  },
  clients: {
    mask: `url(${theme.icons.people})`,
  },
  routesMain: {
    mask: `url(${theme.icons.routesMain})`,
    maskSize: '66% auto !important',
  },
  routesMap: {
    mask: `url(${theme.icons.routesMap})`,
    maskSize: '66% auto !important',
  },
  routesOrders: {
    mask: `url(${theme.icons.routesOrders})`,
    maskSize: '66% auto !important',
  },
  routesVehicles: {
    mask: `url(${theme.icons.routesVehicles})`,
    maskSize: '66% auto !important',
  },
  routesStatuses: {
    mask: `url(${theme.icons.routesTatuses})`,
    maskSize: '66% auto !important',
  },
  modifiers_groups: {
    mask: `url(${theme.icons.modifiers_groups})`,
    maskSize: '60% auto !important',
  },
  existenceReports: {
    mask: `url(${theme.icons.stocksReport})`,
    maskSize: '66% auto !important',
  },
  movimentReports: {
    mask: `url(${theme.icons.moventsReport})`,
    maskSize: '66% auto !important',

  },
  salesReports: {
    mask: `url(${theme.icons.salesReports})`,
    maskSize: '66% auto !important',

  },
  marketInformation: {
    mask: `url(${theme.icons.shoppingOnline})`,
    maskSize: '60% auto !important',
  },
  marketOpinions: {
    mask: `url(${theme.icons.opinions})`,
    maskSize: '60% auto !important',
  },
  marketSales: {
    mask: `url(${theme.icons.ecommerceBag})`,
    maskSize: '60% auto !important',
  },
  sharedCampaigns: {
    mask: `url(${theme.icons.sharedCampaigns})`,
    maskSize: '60% auto !important',
  },
  client: {
    mask: `url(${theme.icons.clients})`,
    maskSize: '60% auto !important',
  },
  marketFinances: {
    mask: `url(${theme.icons.financesStore})`,
    maskSize: '60% auto !important',
  },
  pickerZones: {
    mask: `url(${theme.icons.pickerZones})`,
    maskSize: '50% auto !important',
  },
  span: {
    display: 'block',
    width: 50,
    height: 50,
    maskSize: 'auto',
    maskRepeat: 'no-repeat',
    maskOrigin: 'border-box',
    maskPosition: 'center',
  },
}));

interface TabProps {
  id: number;
  title: string;
  component: () => JSX.Element;
  to: string;
  icon?: string;
}

interface NavTabsProps {
  tabs: Array<TabProps>;
  tabsProps?: MTabsProps;
  eachTabsProps?: ExtendButtonBase<TabTypeMap>;
  customTabs?: React.ElementType;
  containerTabsProps?: PaperProps;
  themeColor?: string;
}

export const NavTabs = ({
  tabs,
  tabsProps,
  customTabs,
  containerTabsProps,
  eachTabsProps,
  themeColor,
}: NavTabsProps) => {
  const classes = useStyle();
  const match = useRouteMatch();
  const location = useLocation();
  const TabsComponent = customTabs || Tabs;

  const renderTabs = tabs.map((tab) => {
    const keyValue = `${match.path}/${tab.to}`;
    const isActive = keyValue === location.pathname;
    return (
      <Tab
        label={tab.title}
        component={Link}
        to={`${match.path}/${tab.to}`}
        value={keyValue}
        style={{ textTransform: 'capitalize' }}
        key={tab.id}
        icon={(
          tab.icon && (
            <span
              style={{ backgroundColor: isActive ? themeColor : 'grey' }}
              className={`${classes[tab.icon]} ${classes.span}`}
            />
          )
        )}
        {...eachTabsProps}
      />
    );
  });

  const tabPanels = tabs.map(tab => (
    <Route path={`${match.path}/${tab.to}`} component={tab.component} />
  ));

  return (
    <>
      <Paper {...containerTabsProps}>
        <TabsComponent
          value={location.pathname}
          indicatorColor="primary"
          textColor="primary"
          {...tabsProps}
        >
          {renderTabs}
        </TabsComponent>
      </Paper>
      <Switch>
        {tabPanels}
        <Route exact path={match.path}>
          <Redirect to={`${match.path}/${tabs[0].to}`} />
        </Route>
      </Switch>
    </>
  );
};
