/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import SuccessButton from '../../../../../components/ui/Buttons/SuccessButton';
import Grid from '../../../../../components/ui/Layout/Grid';

const Actions = ({ handleSubmit, processing }) => {
  const { t } = useTranslation();
  return (
    <Grid container>
      <Grid container item xs={12} justifyContent="flex-end">
        <SuccessButton
          onClick={handleSubmit}
          color="primary"
          text={t('general:save')}
          processing={processing ? 1 : 0}
        />
      </Grid>
    </Grid>
  );
};

export default Actions;
