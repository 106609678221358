/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Upload from 'rc-upload';
import { Grid } from '@mui/material';
import Box from '../../../../../../../components/ui/Layout/Box';
import SectionTitle from '../../SectionTitle';
import SectionText from '../../SectionText';
import GalleryButton from './GalleryButton';
import useFileUpload, { GalleryError } from './useFileUpload';
import Thumb from './Thumb';
import { AssetType } from './constants';
import { useCampaignWizardContext } from '../context/CampaignWizardContext';
import { CampaignWizardStep } from '../constants/campaign_wizard';
import Alert from '../../../../../../../components/ui/notifications/alert';

const GallerySection = () => {
  const { t } = useTranslation(['general']);
  const context = useCampaignWizardContext();
  const [error2, setError2] = useState(null);
  const { setGallery, setStepToSave } = context;
  const gState = context.state;
  const { stepToSave } = gState;

  const {
    uploaderProps, state, deleteImage, deleteVideo,
  } = useFileUpload(
    gState.campaign.images,
    gState.campaign.video,
  );
  const { images, video, error } = state;

  const _handleDedleteImage = useCallback(
    (image) => {
      deleteImage(image);
    },
    [deleteImage],
  );

  const _handleDeleteVideo = useCallback(
    (video) => {
      deleteVideo(video);
    },
    [deleteVideo],
  );

  useEffect(() => {
    if (stepToSave === CampaignWizardStep.GALLERY) {
      setStepToSave(null);

      if (error) {
        return;
      }

      if (!images.length && !video) {
        setError2(GalleryError.REQUIRE_IMAGE_OR_VIDEO);
        return;
      }

      if (
        (video && video.processing)
        || images.find(image => image.processing)
      ) {
        setError2(GalleryError.PROCESSING_ASSET);
        return;
      }

      setGallery(video, images);
    }
  }, [stepToSave, video, images, setStepToSave, setGallery, setError2, error]);

  return (
    <Box mt={3} pb={5}>
      <SectionTitle title={t('general:shared_campaigns:gallery_title')} />
      {error && (
        <Grid container>
          <Alert
            severity="error"
            message={t(`general:shared_campaigns:${error}`, error)}
          />
        </Grid>
      )}

      {error2 && (
        <Grid container>
          <Alert
            severity="error"
            message={t(`general:shared_campaigns:${error2}`, error2)}
          />
        </Grid>
      )}
      <Box
        mt={3}
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100px"
      >
        <Upload {...uploaderProps}>
          <GalleryButton />
        </Upload>
      </Box>

      <Box mt={2}>
        <Grid container spacing={1}>
          {images.map(image => (
            <Grid
              item
              key={image.uid}
              xs={image.resource.toString() === AssetType.VIDEO ? 12 : 4}
            >
              <Thumb asset={image} handleDelete={_handleDedleteImage} />
            </Grid>
          ))}
        </Grid>
      </Box>

      <Box mt={2}>
        {video && <Thumb asset={video} handleDelete={_handleDeleteVideo} />}
      </Box>

      <Box mt={3}>
        <SectionText text={t('general:shared_campaigns:gallery_msg')} />
      </Box>
    </Box>
  );
};

export default GallerySection;
