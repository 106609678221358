/* eslint-disable react/prop-types */
import { Typography } from '@mui/material';
import React from 'react';
import { makeStyles } from '@mui/styles';
import Box from '../ui/Layout/Box';

const useStyle = makeStyles(() => ({
  container: {
    border: '1px solid #C0C3CA',
    borderRadius: '10px',
    cursor: 'pointer',
    width: '100px',
    height: '100px',
  },
  text: { width: '100%' },
  selected: {
    backgroundColor: '#F4E8FC',
    borderColor: '#A174CB',
  },
}));

const BoxAnswer = ({ label, handleOnCLick, selected = false }) => {
  const classes = useStyle();
  return (
    <Box
      className={`${classes.container} ${selected ? classes.selected : ''}`}
      onClick={handleOnCLick}
      display="flex"
      alignItems="center"
    >
      <Box display="flex" align="center" width={1}>
        <Typography variant="h6" align="center" className={classes.text} color="textSecondary">
          {label}
        </Typography>
      </Box>
    </Box>
  );
};

export default BoxAnswer;
