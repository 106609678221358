/* eslint-disable @typescript-eslint/dot-notation */
import { REQUIRE_INVITATION_CODE } from '../../../constants/api/ApiConstants';
import Log from '../../../util/Log';

import RegisterGatewayContract from '../../contracts/gateways/RegisterGatewayContract';
import ApiRegisterService from '../../services/api/ApiRegisterService';

export default class ApiRegisterGateway implements RegisterGatewayContract {
  private static instance: ApiRegisterGateway = null;

  private service: ApiRegisterService = null;

  private constructor() {
    this.service = new ApiRegisterService();
  }

  public static getInstance(): ApiRegisterGateway {
    if (ApiRegisterGateway.instance === null) {
      ApiRegisterGateway.instance = new ApiRegisterGateway();
    }
    return ApiRegisterGateway.instance;
  }

  async register(_data: any) {
    try {
      const data = {
        email: _data.email,
        company_name: _data.company_name,
        name: _data.name !== undefined ? _data.name : '',
        last_name: _data.last_name !== undefined ? _data.last_name : '',
        nickname: _data.nickname !== undefined ? _data.nickname : '',
        password: _data.password !== undefined ? _data.password : '',
        phone: _data.phone !== undefined ? _data.phone : '',
        // business_types: _data.business_types  !== undefined ? _data.business_types : [],
      };

      if (REQUIRE_INVITATION_CODE) {
        data['invitation_code'] = _data.invitation_code ? _data.invitation_code : '';
      }

      return await this.service.register(data);
    } catch (error) {
      Log.debug('error gateway', error.response);
      throw error;
    }
  }

  async saveCompany(data: any) {
    try {
      return await this.service.saveCompany(data);
    } catch (error) {
      Log.debug('error gateway', error);
      throw error;
    }
  }

  async savePassword(data: any) {
    try {
      return await this.service.savePassword(data);
    } catch (error) {
      Log.debug('error gateway', error);
      throw error;
    }
  }
}
