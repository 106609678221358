/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import { Typography } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCategories,
  resetCategories,
} from '../../../../../actions/categories';
import { SCROLL_PER_PAGE } from '../../../../../constants/common';
import SearchTools from '../SearchTools';
import CategoryView from './CategoryView';
import ProductsView from './ProductsView';
import SellDrawer from '../SellDrawer';
import ContinueAlert from '../../../../../components/Alerts/ContinueAlert';

export default () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['general']);
  const company = useSelector((state: any) => state.session.company);
  const stateCategories = useSelector((state: any) => state.categories);
  const [priceAlert, setPriceAlert] = useState(false);
  const [clientAlert, setClientAlert] = useState(false);

  const [inList, setInList] = useState(false);
  const switchList = () => {
    setInList(!inList);
  };

  const _getCategories = useCallback(
    async (params: any = {}) => {
      if (company) {
        params.per_page = SCROLL_PER_PAGE;
        await dispatch(getCategories(company.id, params));
      }
    },
    [dispatch, company],
  );

  const _resetCategories = useCallback(() => {
    dispatch(resetCategories());
  }, [dispatch]);

  return (
    <>
      <SearchTools
        switchViewClick={switchList}
        switchView={inList}
      />
      <Typography>
        {t('general:categories')}
        :
      </Typography>
      {
        useMemo(() => (
          <CategoryView
            stateCategories={stateCategories}
            resetCategories={_resetCategories}
            getCategories={(params: any) => _getCategories(params)}
          />
        ), [stateCategories, _resetCategories, _getCategories])
      }
      <ProductsView
        setPriceAlert={setPriceAlert}
        listView={inList}
        switchView={inList}
      />
      <div style={{ height: '260px' }} />
      <SellDrawer
        setClientAlert={setClientAlert}
      />
      <ContinueAlert
        open={priceAlert}
        setOpen={setPriceAlert}
        title={t('general:payment_alert:price:title')}
        description={t('general:payment_alert:price:description')}
      />
      <ContinueAlert
        open={clientAlert}
        setOpen={setClientAlert}
        title={t('general:payment_alert:client:title')}
        description={t('general:payment_alert:client:description')}
      />
    </>
  );
};
