/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import Grid from '../../../../../components/ui/Layout/Grid';
import FormInput from '../../../../../components/ui/FormInput';
import ImageUploader from '../../../../../components/ui/ImageUploader';
import Box from '../../../../../components/ui/Layout/Box';
import useSignUploadUrl from '../../../../../components/ui/ImageUploader/useSignUploadUrl';

export default ({
  supplier, setImage, setData, tab, setImageKey,
}) => {
  const imgUrl = supplier.image_url ? supplier.image_url : null;
  const { signUrl } = useSignUploadUrl('suppliers');

  const setImage2 = (image) => {
    setData({ ...supplier, image_url: URL.createObjectURL(image) });
    setImage(image);
  };

  const { t } = useTranslation(['suppliers']);
  const { register, formState: { errors } } = useFormContext();

  const onHandle = (event) => {
    setData({ ...supplier, [event.target.name]: event.target.value });
  };

  return (
    <>
      <Grid
        container
        spacing={2}
        style={{ display: tab === 0 ? 'block' : 'none' }}
      >
        {/* Basic Tab */}
        <Grid container item spacing={2}>
          <Grid item xs={5} sm={4}>
            <Box height="100%">
              <ImageUploader
                imgUrl={imgUrl}
                setImage={setImage2}
                text={t('suppliers:add_image')}
                signUrl={signUrl}
                setImageKey={setImageKey}
              />

            </Box>
          </Grid>
          <Grid container item xs={7} sm={8}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <FormInput
                  register={register('name')}
                  error={errors.name}
                  label={t('suppliers:supplier_name')}
                  defaultValue={supplier.name ? supplier.name : ''}
                  onChange={onHandle}
                />
              </Grid>

              <Grid item xs={12}>
                <FormInput
                  register={register('alias')}
                  error={errors.alias}
                  label={t('suppliers:alias')}
                  defaultValue={supplier.alias ? supplier.alias : ''}
                  onChange={onHandle}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} mt={0}>
          <FormInput
            register={register('manager')}
            error={errors.manager}
            label={t('suppliers:manager')}
            defaultValue={supplier.manager ? supplier.manager : ''}
          />
        </Grid>

        <Grid item xs={12}>
          <FormInput
            register={register('phone')}
            error={errors.phone}
            label={t('suppliers:phone')}
            defaultValue={supplier.phone ? supplier.phone : ''}
          />
        </Grid>

        <Grid item xs={12}>
          <FormInput
            register={register('cellphone')}
            error={errors.cellphone}
            label={t('suppliers:cellphone')}
            defaultValue={supplier.cellphone ? supplier.cellphone : ''}
          />
        </Grid>

        <Grid item xs={12}>
          <FormInput
            register={register('email')}
            error={errors.email}
            label={t('suppliers:email')}
            defaultValue={supplier.email ? supplier.email : ''}
          />
        </Grid>

        <Grid item xs={12}>
          <FormInput
            register={register('address')}
            error={errors.address}
            label={t('suppliers:address')}
            defaultValue={supplier.address ? supplier.address : ''}
          />
        </Grid>

        <Grid item xs={12}>
          <FormInput
            register={register('comments')}
            error={errors.comments}
            label={t('suppliers:comments')}
            defaultValue={supplier.comments ? supplier.comments : ''}
          />
        </Grid>
      </Grid>

      {/* Billint tab */}
      <Grid
        container
        spacing={2}
        style={{ display: tab === 1 ? 'block' : 'none' }}
      >
        <Grid item xs={12}>
          <FormInput
            register={register('business_name')}
            error={errors.business_name}
            label={t('suppliers:business_name')}
            defaultValue={supplier.business_name ? supplier.business_name : ''}
            onChange={onHandle}
          />
        </Grid>

        <Grid container item spacing={2}>
          <Grid item xs={6}>
            <FormInput
              register={register('rfc')}
              error={errors.rfc}
              label={t('suppliers:rfc')}
              defaultValue={supplier.rfc ? supplier.rfc : ''}
            />
          </Grid>

          <Grid item xs={6}>
            <FormInput
              register={register('curp')}
              error={errors.curp}
              label={t('suppliers:curp')}
              defaultValue={supplier.curp ? supplier.curp : ''}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <FormInput
            register={register('bill_address')}
            error={errors.bill_address}
            label={t('suppliers:bill_address')}
            defaultValue={supplier.bill_address ? supplier.bill_address : ''}
          />
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs={6}>
            <FormInput
              register={register('no_ext')}
              error={errors.no_ext}
              label={t('suppliers:no_ext')}
              defaultValue={supplier.no_ext ? supplier.no_ext : ''}
            />
          </Grid>

          <Grid item xs={6}>
            <FormInput
              register={register('no_int')}
              error={errors.no_int}
              label={t('suppliers:no_int')}
              defaultValue={supplier.no_int ? supplier.no_int : ''}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <FormInput
            register={register('bill_email')}
            error={errors.bill_email}
            label={t('suppliers:email')}
            defaultValue={supplier.bill_email ? supplier.bill_email : ''}
          />
        </Grid>

        <Grid item xs={12}>
          <FormInput
            register={register('bill_phone')}
            error={errors.bill_phone}
            label={t('suppliers:phone')}
            defaultValue={supplier.bill_phone ? supplier.bill_phone : ''}
          />
        </Grid>
      </Grid>
    </>
  );
};
