/* eslint-disable react/destructuring-assignment */
import React from 'react';
import styles from './Shape1.module.css';

interface ShapeProps extends React.HTMLAttributes<HTMLElement> {
  color?: string;
}

const ShapeLeft: React.ElementType = (props: ShapeProps) => {
  const color = props.color ? props.color : '#00A0F3';
  return (
    <div className={`${props.className}`}>
      <div className={`${styles.Wrapper}`}>
        <div
          className={`${styles.Shape1}`}
          style={{ backgroundColor: color }}
        />
      </div>
    </div>
  );
};

export default ShapeLeft;
