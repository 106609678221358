import {
  Box,
} from '@mui/material';
import React from 'react';
import { makeStyles } from '@mui/styles';
import ActivityIndicator from '../../../components/ui/ActivityIndicator';

const useStyles = makeStyles(() => ({
  root: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    verticalAlign: 'center',
    justifyContent: 'center',
  },
}));

export default () => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <ActivityIndicator />
    </Box>
  );
};
