export const types = {
  SET_COUNT_BRANCH: 'SET_COUNT_BRANCH',
  SET_COUNT_LOCATION: 'SET_COUNT_LOCATION',
  SET_SHOW_ALL_LOCATIONS: 'SET_SHOW_ALL_LOCATIONS',
  SET_COUNT_PRODUCTS_SUCCESS: 'SET_COUNT_PRODUCTS_SUCCESS',
  SET_COUNT_PRODUCTS_FAILURE: 'SET_COUNT_PRODUCTS_FAILURE',
  SET_COUNT_PRODUCTS_PROCESSING: 'SET_COUNT_PRODUCTS_PROCESSING',
  SET_COUNT_PRODUCTS_SEARCH: 'SET_COUNT_PRODUCTS_SEARCH',
  RESET_COUNT_PRODUCTS: 'RESET_COUNT_PRODUCTS',
  SHOW_COUNT_MOVEMENTS_SUMMARY: 'SHOW_COUNT_MOVEMENTS_SUMMARY',
  CLOSE_COUNT_MOVEMENTS_SUMMARY: 'CLOSE_COUNT_MOVEMENTS_SUMMARY',
  ADD_COUNT_ITEM: 'ADD_COUNT_ITEM',
  REMOVE_COUNT_ITEM: 'REMOVE_COUNT_ITEM',
  SAVING_COUNT_MOVEMENTS: 'SAVING_COUNT_MOVEMENTS',
  SET_COUNT_MOVEMENTS_SUCCESS: 'SET_COUNT_MOVEMENTS_SUCCESS',
  SET_COUNT_MOVEMENTS_FAILURE: 'SET_COUNT_MOVEMENTS_FAILURE',
  SET_COUNT_LOCATIONS_PROCESSING: 'SET_COUNT_LOCATIONS_PROCESSING',
  SET_COUNT_LOCATIONS_SUCCESS: 'SET_COUNT_LOCATIONS_SUCCESS',
  SET_COUNT_LOCATIONS_FAILURE: 'SET_COUNT_LOCATIONS_FAILURE',
  CLEAR_COUNT_LOCATIONS: 'CLEAR_COUNT_LOCATIONS',
  CLEAR_COUNT: 'CLEAR_COUNT',

};
