/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Axios from 'axios';
import GreenSwitch from '../../../../../components/ui/Switches/GreenSwitch';
import { BASE_URL } from '../../../../../constants/api/ApiConstants';

const PublishedSwitch = ({
  register, company, paymentState, setPublished,
}) => {
  const { t } = useTranslation(['general']);
  const [checked, setChecked] = useState(
    company.published === true,
  );

  const changeStatusStore = (event: React.ChangeEvent<HTMLInputElement>) => {
    const statusStore = {
      published: !!event.target.checked,
    };
    setChecked(event.target.checked);
    setPublished(event.target.checked);

    Axios.put(`${BASE_URL}/api/v1/companies/${company.id}`, statusStore)
      .then((res) => {
      });
  };

  return (
    <>
      <GreenSwitch
        label={paymentState === 0 ? 'antes de publicar tu tienda, debes seleccionar un método de pago' : t('general:store_page:published')}
        disabled={paymentState === 0}
        checked={checked}
        handleChange={changeStatusStore}
        labelPlacement="end"
      />
      <input type="hidden" value={checked} {...register('published')} />
    </>
  );
};

export default PublishedSwitch;
