/* eslint-disable consistent-return */
import {
  Box, Grid, Typography,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { stockWarning } from '../../../../../actions/cart';
import Text from '../../../../../components/Text/Text';
import { PERMISSION, RESOURCES } from '../../../../../constants/common';
import { useSession } from '../../../../../util/session';
import ItemStock from '../ItemStock';
import ItemPrice from './ItemPrice';

const styles = makeStyles(theme => ({
  container: {
    width: '100%',
    height: 210,
    boxShadow: theme.shadow.main,
    borderRadius: 20,
    cursor: 'pointer',
  },
  quantityContainer: {
    width: '100%',
    height: '20%',
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    boxShadow: theme.shadow.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dataContainer: {
    width: '100%',
    height: '80%',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  imgSize: {
    height: '35%',
    width: '50%',
    margin: 5,
  },
  Image: {
    backgroundColor: theme.colors.inventory.light,
    backgroundRepeat: 'no-repeat',
    height: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },

  available: {
    color: theme.colors.trafficLight.adequate.main,
  },
  unavailable: {
    color: theme.colors.trafficLight.low.main,
  },
}));

interface ProductProps {

  product: any;
  setPriceAlert: (x: boolean)=>void;
  handleAddItem?: (x: any)=>void;
  // any = product interface
}

export default ({ product, setPriceAlert, handleAddItem }: ProductProps) => {
  const classes = styles();
  const dispatch = useDispatch();
  const { can } = useSession();
  const { t } = useTranslation(['general']);
  // const cart = useSelector((state: any) => state.cart);
  // const itemUpdate = cart.items.find((item) => item.id === product.id);

  const openPriceWarning = () => {
    setPriceAlert(true);
  };
  const openStockWarning = () => {
    dispatch(stockWarning(product));
  };

  const addProduct = () => {
    if ((product.inventoried && product.variants[0].stock > 1) || product.available) {
      if (product.variants[0].price <= 0) {
        openPriceWarning();
        if (product.quantity >= product.variants[0].stock) {
          openStockWarning();
        } else {
          return handleAddItem;
        }
      } else if (product.quantity >= product.variants[0].stock) {
        openStockWarning();
      } else {
        return handleAddItem;
      }
    } else {
      openStockWarning();
    }
  };

  return (
    <Box
      className={classes.container}
      onClick={() => addProduct()(product)}
    >
      {can(RESOURCES.stock, PERMISSION.edit) && (
        <Grid className={classes.quantityContainer}>
          {product.inventoried ? (
            <>
              <ItemStock variants={product.variants[0]} />
              <Typography variant="caption" style={{ marginLeft: 4 }}>
                {product.variants[0].unitofmeasure.code}
                {' '}
                {t('general:point_of_sell:available')}
              </Typography>
            </>
          ) : (
            <Typography variant="caption" className={product.available ? classes.available : classes.unavailable} style={{ marginLeft: 4 }}>

              {product.available ? t('general:point_of_sell:available') : t('general:point_of_sell:unavailable')}
            </Typography>
          )}
        </Grid>
      )}

      <Grid className={classes.dataContainer}>
        <Box className={classes.imgSize}>
          <div
            style={{ backgroundImage: `url(${product.variants[0].image_url})` }}
            className={classes.Image}
          />
        </Box>
        <Box style={{ width: '65%' }}>
          <Text variant="subtitle1" align="inherit" textTruncate={3} style={{ fontWeight: 550 }}>
            {product.name}
          </Text>
          <Text
            variant="body2"
            align="inherit"
            textTruncate={3}
          >
            {product.description}
          </Text>
        </Box>
        <ItemPrice price={product.variants[0].price} />
      </Grid>
    </Box>
  );
};
