/* eslint-disable react/prop-types */
import React from 'react';
import INumberFormat from 'react-number-format';

export default ({ value }) => (
  <INumberFormat
    value={value}
    displayType="text"
    decimalScale={2}
    thousandSeparator
  />
);
