/* eslint-disable default-case */
/* eslint-disable react/prop-types */
import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyle = makeStyles(() => ({
  light: {
    display: 'inline-block',
    height: 16,
    width: 16,
    borderRadius: '50%',
    border: '1px solid',
    marginTop: '4px',
    // [theme.breakpoints.down("xs")]: {
    //   height: 11,
    //   width: 11,
    // },
  },
  greenLight: {
    borderColor: '#01CC38',
    backgroundColor: '#E9FAE8',
  },
  yellowLight: {
    borderColor: '#FDFF09',
    backgroundColor: '#FFFED6',
  },
  redLight: {
    borderColor: '#FF0000',
    backgroundColor: '#FFD0D0',
  },
}));

export default ({ alert }) => {
  const classes = useStyle();
  let light;
  switch (alert) {
    case 'green':
      light = classes.greenLight;
      break;
    case 'yellow':
      light = classes.yellowLight;
      break;
    case 'red':
      light = classes.redLight;
      break;
  }

  return <span className={`${classes.light} ${light}`} />;
};
