/* eslint-disable consistent-return */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useEffect, useState } from 'react';
import {
  Button, CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
} from '@mui/material';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import Alert from '@mui/material/Alert';
import Modal from '../../../../../components/ui/Modals/Modal';
import Features from './Features';
import Inventory from './Inventory';
import Finance from './Finance';
import OnlineStore from './OnlineStore';
import { useSession } from '../../../../../util/session';
import { deleteProduct, saveProduct } from '../../../../../actions/product';
import { saveCountMovements } from '../../../../../actions/count';
import Box from '../../../../../components/ui/Layout/Box';
import { rules as validationRules } from '../../../../../util/Validations';

interface AutocompleteOption {
  label: string;
  value: string;
}

export type ProductFormType = {
  image_url: string; // solo para mostrar al editar
  // general
  id: string;
  name: string;
  description: string;
  barcode: string;
  selectedBrand: AutocompleteOption,
  selectedCategory: AutocompleteOption,
  selectedUnit: AutocompleteOption,
  // online store
  published: boolean,
  // inventory
  available: boolean;
  inventoried: boolean;
  adequate_stock: string;
  stock_alert: string;
  low_stock: string;
  selectedSuppliers: any[]
  // finance
  cost: number;
  price: number;
  assets: any[],
};

const defaultFormState: ProductFormType = {
  image_url: '',
  // genral
  id: '',
  name: '',
  description: '',
  barcode: '',
  selectedBrand: {} as AutocompleteOption,
  selectedCategory: {} as AutocompleteOption,
  selectedUnit: null,
  // online store
  published: false,
  // inventory
  available: false,
  inventoried: false,
  adequate_stock: '0',
  stock_alert: '0',
  low_stock: '0',
  selectedSuppliers: [],
  // finance
  cost: 0,
  price: 0,
  assets: [],
};

interface Props {
  open: boolean;
  onClose: () => void;
  initialFormState?: ProductFormType;
  initialInventories?: any[];
  onDone?: () => void;
}

export default ({
  open,
  initialFormState,
  onClose,
  initialInventories,
  onDone,
}: Props) => {
  const { t } = useTranslation('general');
  const schema = Yup.object({
    name: Yup.string()
      .max(validationRules.maxLength)
      .required(
        t('translation:required', {
          input: t('general:product_name'),
        }),
      ),
    barcode: Yup.string().max(validationRules.maxLength),
    description: Yup.string().max(validationRules.maxLength),
    selectedUnit: Yup.string()
      .nullable()
      .max(validationRules.maxLength)
      .required(t('products:error_required_unit')),
    // category_id: Yup.string().nullable().max(validationRules.maxLength),
    // brand_id: Yup.string().nullable().max(validationRules.maxLength),
    adequate_stock: Yup.number()
      .transform((curr, orig) => (!orig ? 0 : curr))
      .min(
        initialFormState?.stock_alert ? initialFormState?.stock_alert : 0,
        t('products:error_msg_quantity2', { value: initialFormState?.stock_alert }),
      )
      .typeError(t('products:error_msg_quantity')),
    stock_alert: Yup.number()
      .transform((curr, orig) => (!orig ? 0 : curr))
      .nullable()
      .min(
        initialFormState?.low_stock ? initialFormState?.low_stock : 0,
        t('products:error_msg_quantity2', { value: initialFormState?.low_stock }),
      )
      .typeError(t('products:error_msg_quantity')),
    low_stock: Yup.number()
      .transform((curr, orig) => (!orig ? 0 : curr))
      .nullable()
      .min(0, t('products:error_msg_quantity'))
      .typeError(t('products:error_msg_quantity')),
    cost: Yup.number()
      .transform((curr, orig) => (!orig ? 0 : curr))
      .nullable()
      .min(0, t('products:error_msg_quantity'))
      .typeError(t('products:error_msg_quantity')),
    price: Yup.number()
      .transform((curr, orig) => (!orig ? 0 : curr))
      .nullable()
      .min(0, t('products:error_msg_quantity'))
      .typeError(t('products:error_msg_quantity')),
    published: Yup.boolean(),
    inventoried: Yup.boolean(),
    available: Yup.boolean(),
  });

  const {
    reset,
    watch,
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<ProductFormType>({
    defaultValues: defaultFormState,
    resolver: yupResolver(schema),
  });

  const dispatch = useDispatch();
  const { company } = useSession();
  const [tempImages, setTempImages] = useState([]);
  const [inventories, setInventories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [productError, setProductError] = useState(null);
  const [movementsSummaryForm, setMovementsSummaryForm] = useState({
    movements: [],
    product: {},
    isOpen: false,
  });

  const onSubmit = async (data) => {
    const {
      selectedSuppliers,
      unitofmeasure_id,
      brand_id,
      category_id,
      id,
      image_url,
      variant_id: variantId,
      selectedBrand,
      selectedCategory,
      selectedUnit,
      assets,
      ...restData
    } = data;
    const suppliers = selectedSuppliers.map(supplier => supplier.value);
    const parsedData = {
      ...restData,
      id: id || undefined,
      unitofmeasure_id: getValues('selectedUnit.value') || undefined,
      brand_id: selectedBrand?.value || undefined,
      category_id: selectedCategory?.value || undefined,
      suppliers,
      assets: tempImages.map(i => ({
        type: i.type,
        key: i.key,
      })),
    };

    if (inventories.length > 0) {
      const movements = inventories
        .map(({
          location_id, variant_id, quantity, stock, name,
        }) => ({
          location_id,
          variant_id: variant_id ?? variantId,
          quantity: quantity - stock,
          type: quantity - stock > 0 ? 1 : 2,

          name,
          difQuantity: quantity,
          lastStock: stock,
        }))
        .filter(({ quantity }) => quantity !== 0);

      if (movements.length > 0) {
        return setMovementsSummaryForm({
          movements,
          product: parsedData,
          isOpen: true,
        });
      }
    }

    try {
      setLoading(true);
      await dispatch(saveProduct(parsedData, company.id));
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      handleClose();
      if (onDone) {
        onDone();
      }
    }
  };

  const onSubmitWithMovements = async (productData, movements) => {
    try {
      const movementsData = movements.map(({
        difQuantity, lastStock, name, ...rest
      }) => rest);
      setLoading(true);
      await dispatch(saveCountMovements(company.id, movementsData));
      await dispatch(saveProduct(productData, company.id));
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      handleCloseSummary();
      handleClose();
      if (onDone) {
        onDone();
      }
    }
  };

  const handleDeleteProduct = async () => {
    try {
      setLoading(true);
      await dispatch(deleteProduct(initialFormState, company.id));
      handleCloseSummary();
      handleClose();
      if (onDone) {
        onDone();
      }
    } catch (err) {
      setProductError(err.response.data);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (initialFormState) {
      reset(initialFormState);
      setTempImages([]);
    }
  }, [initialFormState, reset]);

  useEffect(() => {
    if (initialInventories) {
      setInventories(initialInventories);
    }
  }, [initialInventories]);

  const handleClose = () => {
    onClose();
    reset(defaultFormState);
  };

  const handleCloseSummary = () => {
    setMovementsSummaryForm({
      movements: [],
      product: {},
      isOpen: false,
    });
  };

  return (
    <>
      <Modal
        open={movementsSummaryForm.isOpen}
        onClose={handleCloseSummary}
        title={t('general:movement_summary')}
        actions={(
          <div style={{ display: 'flex' }}>
            <div style={{ marginRight: '10px' }}>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleCloseSummary}
              >
                Cancelar
              </Button>
            </div>
            <div>
              <Button
                variant="contained"
                color="primary"
                disabled={loading}
                onClick={() => onSubmitWithMovements(movementsSummaryForm.product, movementsSummaryForm.movements)}
              >
                {
                loading ? <CircularProgress size={24} /> : t('general:confirm')
              }
              </Button>
            </div>
          </div>
      )}
      >
        <Box minHeight="400px" minWidth="300px">
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Ubicación</TableCell>
                  <TableCell align="center">Hay</TableCell>
                  <TableCell align="center">Contado</TableCell>
                  <TableCell align="center">Diferencia</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {movementsSummaryForm.movements.map(row => (
                  <TableRow key={row.variant_id}>
                    <TableCell align="center">{row.name}</TableCell>
                    <TableCell align="center">{row.lastStock}</TableCell>
                    <TableCell align="center">{row.difQuantity}</TableCell>
                    <TableCell align="center">{row.quantity}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Modal>
      <Modal
        open={open}
        actions={(
          <Box width="100%" justifyContent="space-between" display="flex">
            <Button
              variant="contained"
              color="secondary"
              disabled={loading}
              onClick={handleDeleteProduct}
            >
              {
                loading ? <CircularProgress size={24} /> : t('general:delete')
              }
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={loading}
              onClick={handleSubmit(onSubmit)}
            >
              {
                loading ? <CircularProgress size={24} /> : t('general:confirm')
              }
            </Button>
          </Box>
      )}
        title="Nuevo Producto"
        onClose={handleClose}
      >
        {productError && (
          <Alert severity="error">
            {
            productError === 'product already has movements' && t('has_movements')
          }
          </Alert>
        )}
        <Features
          tempImages={tempImages}
          setTempImages={setTempImages}
          formData={watch()} // watch() es una funcion que devuelve el valor de un campo, obesevable
          setFormData={setValue}
          register={register}
          errors={errors}
        />
        <OnlineStore
          formData={watch()}
          control={control}
        />
        <Inventory
          formState={watch()}
          setFormData={setValue}
          register={register}
          control={control}
          inventories={inventories}
          setInventories={setInventories}
          errors={{}}
        />
        <Finance
          formData={watch()}
          register={register}
          errors={{}}
        />
      </Modal>
    </>
  );
};
