import Icon_Client from './Clients.svg';
import Icon_Configuration from './Configuration.svg';
import Icon_Finance from './Finance.svg';
import Icon_Home from './Home.svg';
import Icon_Inventory from './Inventory.svg';
import Icon_Key from './Key.svg';
import Icon_PointSale from './PointSale.svg';
import Icon_QuestionMark from './QuestionMark.svg';
import Icon_Reports from './Reports.svg';
import Icon_Selection from './Selection.svg';
import Logo from './Logo.svg';
import Icon_Lens from './lupa.svg';
import Icon_List from './List.svg';
import Icon_Mosaic from './mosaic.svg';
import Icon_Code from './code.svg';
import Icon_Checker from './comprobar.svg';
import Icon_CASH from './CASH.svg';
import Icon_CREDIT_CARD from './CREDIT_CARD.svg';
import Icon_CODI from './CODI.svg';
import Icon_VOUCHER from './VOUCHER.svg';
import Icon_TRANSFE from './TRANSFE.svg';
import Icon_CREDIT from './CREDIT.svg';
import DefaultPerfile from './perfile.png';

import Icon_Categories_Theme from './CategoriesTheme.svg';
import Icon_Brands_Theme from './BrandsTheme.svg';
import Icon_Suppliers_Theme from './SuppliersTheme.svg';

import Icon_FoodStore from './food-store.svg';
import Icon_Delivery from './delivery.svg';
import Icon_Marker from './marker.svg';

import ProductsStockViewQuantity from './product_stock_view_quantity.svg';
import ProductsStockViewAvailability from './product_stock_view_availability.svg';

import Icon_Withdrawals from './withdrawals.svg';
import Icon_Products from './Products.svg';
import Icon_EcubiMiNegocio from './EcubiMiNegocioLogo.svg';

import EcubiMarket from './EcubMarket.svg';

import Icon_FileDowload from './FileDowload.svg';
import Icon_PlanProCrown from './PlanProCrown.svg';

import noProductImage from './noProductImage.png';
import Icon_storeProfile from './storeProfile.svg';

import Icon_blockUser from './blockUser.svg';

import Icon_moventsReport from './moventsReport.svg';
import Icon_salesReports from './salesReports.svg';
import Icon_stocksReport from './stocksReport.svg';

import Icon_storeCamping from './storeCampings.svg';

import Icon_comunity from './annualPlanComunity.svg';
import Icon_hands from './annualPlanHands.svg';
import Icon_tips from './annualPlanTips.svg';

import Icon_zone_delimiter from './pickerZones.svg';

import Icon_ecommerceBag from './ecommerceBag.svg';

import Icon_DownloadExcel from './DownloadExcel.svg';

import Icon_Mail from './Mail.svg';
import Icon_Sms from './Sms.svg';
import Icon_SmsWRadius from './SmsWRadius.svg';

import routesMain from './routes-main.svg';
import routesMap from './routes-map.svg';
import routesOrders from './routes-orders.svg';
import routesTatuses from './routes-tatuses.svg';
import routesVehicles from './routes-vehicles.svg';

export default {
  Icon_ecommerceBag,
  Icon_storeCamping,
  Icon_hands,
  Icon_tips,
  Icon_comunity,
  Icon_stocksReport,
  Icon_salesReports,
  Icon_moventsReport,
  EcubiMarket,
  Logo,
  DefaultPerfile,
  Icon_Client,
  Icon_Configuration,
  Icon_Finance,
  Icon_Home,
  Icon_Inventory,
  Icon_Key,
  Icon_PointSale,
  Icon_QuestionMark,
  Icon_Reports,
  Icon_Selection,
  Icon_Lens,
  Icon_List,
  Icon_Mosaic,
  Icon_Code,
  Icon_Checker,
  Icon_CASH,
  Icon_CREDIT_CARD,
  Icon_CODI,
  Icon_VOUCHER,
  Icon_TRANSFE,
  Icon_CREDIT,
  Icon_Categories_Theme,
  Icon_Brands_Theme,
  Icon_Suppliers_Theme,
  Icon_FoodStore,
  Icon_Delivery,
  Icon_Marker,
  ProductsStockViewQuantity,
  ProductsStockViewAvailability,
  Icon_Withdrawals,
  Icon_Products,
  Icon_EcubiMiNegocio,
  Icon_FileDowload,
  Icon_PlanProCrown,
  noProductImage,
  Icon_storeProfile,
  Icon_blockUser,
  Icon_zone_delimiter,
  Icon_DownloadExcel,
  Icon_Mail,
  Icon_Sms,
  Icon_SmsWRadius,
  routesMain,
  routesMap,
  routesOrders,
  routesTatuses,
  routesVehicles,
};
