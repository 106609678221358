/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/prop-types */
import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BrandFormView from './BrandFormView';

import {
  getBrands,
  resetBrands,
} from '../../../../../actions/brands';
import { saveBrand, deleteBrand, closeModalBrand } from '../../../../../actions/brand';
import { SCROLL_PER_PAGE } from '../../../../../constants/common';
import { types } from '../../../../../actions/brand/types';

export default ({ source = 1 }) => {
  const dispatch = useDispatch();
  const { company } = useSelector((state: any) => state.session);
  const {
    brand, error, processing, refresh, deletingBrand,
  } = useSelector((state: any) => state.brand);

  const _getBrands = useCallback(() => {
    dispatch(resetBrands());
    dispatch(getBrands(company.id, { page: 1, per_page: SCROLL_PER_PAGE }));
    dispatch({
      type: types.CLEAR_REFRESH,
    });
  }, [dispatch, company]);

  const attemptSaveBrand = async (data) => {
    await dispatch(saveBrand(data, company.id));
  };

  const attemptDeleteBrand = async (category) => {
    await dispatch(deleteBrand(category, company.id));
  };

  useEffect(() => {
    if (refresh && source === 1) {
      _getBrands();
    }
  }, [refresh, _getBrands, source]);

  return (
    <BrandFormView
      brand={brand}
      attemptSaveBrand={data => attemptSaveBrand(data)}
      attemptDeleteBrand={brand => attemptDeleteBrand(brand)}
      processing={processing}
      deletingBrand={deletingBrand}
      error={error}
      closeModalBrand={closeModalBrand}
    />
  );
};
