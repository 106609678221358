import { useSelector } from 'react-redux';

const useCompany = () => {
  const { company, user, ...rest } = useSelector((state: any) => state.session);

  return {
    company,
    user,
    rest,
  };
};

export default useCompany;
