import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { getModifiersGroups, resetModifiersGroups } from '../../../../actions/modifiers_groups';
import { editModifierGroup } from '../../../../actions/modifier_group';
import { useSession } from '../../../../util/session';
import ModifiersGroupsPageView from './ModifiersGroupsPageView';

export default () => {
  const dispatch = useDispatch();
  const { company } = useSession();
  const openNewModifierGroup = () => {
    dispatch(editModifierGroup());
  };

  const refreshList = useCallback(() => {
    if (company) {
      dispatch(resetModifiersGroups());
      dispatch(getModifiersGroups(company.id));
    }
  }, [dispatch, company]);

  return (
    <ModifiersGroupsPageView
      openNewModifierGroup={openNewModifierGroup}
      refreshList={refreshList}
    />
  );
};
