/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-return-assign */
import {
  Box, Divider, Grid, Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { makeStyles } from '@mui/styles';
import { ProductItemInterface } from '../../../helpers/interfaces/order';
import images from '../../../theme/ECTheme/assets/images';

const styles = makeStyles(theme => ({
  container: {
    border: `1px solid ${theme.colors.grey}`,
    borderRadius: 8,
    margin: '.5em',
  },
  mainItem: {
    display: 'grid',
    gridTemplateColumns: '85px 2fr 1fr 1fr 30px',
    gap: 5,
  },
  img: {
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    boxSizing: 'border-box',
    width: '100%',
    height: '100%',
    borderRadius: 8,
  },
  center: {
    justifySelf: 'center',
    textAlign: 'center',
  },
  limitText: {
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
  },
  deploy: {
    display: 'block',
  },
  close: {
    display: 'none',
  },
  buttonDeploy: {
    cursor: 'pointer',
    width: 'max-content',
    transition: 'transform 0.3s ease',
  },
  buttonClose: {
    transform: 'rotate(90deg)',
  },

}));

const OrderItemView = ({
  product, variant, quantity, price, modifiers_groups, ...rest
}: ProductItemInterface) => {
  const classes = styles();
  const { t } = useTranslation();
  const [isDeploy, setIsDeploy] = useState<boolean>(false);

  let allItemsModifiers = [];
  let subtotalModifiers = 0;

  modifiers_groups.forEach((modifier) => {
    modifier.items.forEach((item) => {
      allItemsModifiers = [
        ...allItemsModifiers,
        item,
      ];
    });
  });

  allItemsModifiers.forEach(item => subtotalModifiers += (item.price * item.quantity));

  return (
    <Box className={classes.container}>
      <Box className={classes.mainItem}>
        <Box width="100%" height="100%" padding="2px" boxSizing="border-box">
          <Grid
            className={classes.img}
            style={{
              backgroundImage: `url(${variant.image_url ? variant.image_url : images.DefaultPerfile})`,
            }}
          />
        </Box>
        <Grid>
          <Typography variant="body1" style={{ fontWeight: 550 }}>
            {product.name}
          </Typography>
          <Typography variant="body2" className={classes.limitText}>
            {product.description}
          </Typography>
        </Grid>
        <Grid className={`${classes.center}`}>
          <Typography variant="body1" style={{ fontWeight: 550 }}>
            {t('general:quantity')}
          </Typography>
          <Typography variant="body2" style={{ lineHeight: 1 }}>
            {quantity}
          </Typography>
          <Typography variant="caption">
            {variant.unitofmeasure.code}
          </Typography>
        </Grid>
        <Grid className={`${classes.center}`}>
          <Typography variant="body1" style={{ fontWeight: 550 }}>
            {t('general:price')}
          </Typography>
          <Typography variant="body1" style={{ fontWeight: 550 }}>
            $
            { price }
          </Typography>
        </Grid>
        <Box
          alignSelf="center"
          className={`${classes.buttonDeploy} ${isDeploy && classes.buttonClose}`}
          onClick={() => setIsDeploy(!isDeploy)}
        >
          <ArrowForwardIosIcon fontSize="small" />
        </Box>
      </Box>
      <Box className={`${classes.deploy} ${isDeploy || classes.close}`}>
        <Box padding="15px 30px 0px 30px">
          <Typography variant="subtitle2" style={{ lineHeight: 1 }}>
            Detalles del producto
          </Typography>
        </Box>
        {
                    allItemsModifiers.map((item, index, arr) => (
                      <>
                        <Box display="flex" padding="0px 20px" alignItems="center">
                          <Typography variant="caption" style={{ flex: 1 }}>
                            {`${item.quantity}x ${item.variant.product.name}`}
                          </Typography>
                          <Typography align="center" variant="caption" style={{ minWidth: 45 }}>
                            {`$${item.price}`}
                          </Typography>
                          <Box width="30px" height="30px" margin="2px 8px" borderRadius="32px" overflow="hidden">
                            <Grid
                              className={classes.img}
                              style={{
                                backgroundImage: `url(${item.variant.image_url || null})`,
                              }}
                            />
                          </Box>
                        </Box>
                        {
                                index + 1 !== arr.length
                                && <Divider variant="middle" />
                            }
                      </>
                    ))
                }
        <Box display="flex" padding="0px 65px" justifyContent="flex-end">
          <Typography variant="body1" style={{ display: 'block', borderTop: '1px solid #ccc' }}>
            $
            { subtotalModifiers + (price * quantity) }
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default OrderItemView;
