import { Channel } from 'pusher-js';
import { useContext, useEffect, useRef } from 'react';
import { SocketContext } from '../contexts/SocketsContext';
import { useSession } from '../util/session';

const useSockets = () => {
  const { socket } = useContext(SocketContext);
  const { company } = useSession();
  const socketRef = useRef<Channel>(null);

  useEffect(() => {
    if (company) {
      socketRef.current = socket.subscribe(company.id);
    }

    return () => {
      if (company) {
        socket.unsubscribe(company.id);
      }
    };
  }, [company, socket]);

  const subscribeToBinding = (eventName: string, callback: (payload: any)=>void) => {
    if (socketRef.current) {
      socketRef.current.bind(eventName, callback);
    }
  };

  const unsubscribeFromBinding = (eventName: string, callback?: (payload: any)=>void) => {
    if (socketRef.current) {
      socketRef.current.unbind(eventName, callback);
    }
  };

  return {
    socket,
    subscribeToBinding,
    unsubscribeFromBinding,
  };
};

export default useSockets;
