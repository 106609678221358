export const types = {
  // from market place

  add_item_to_cart: 'add_item_to_cart',
  remove_one_in_product: 'remove_one_in_product',

  //--------------------------

  ADD_CART_ITEM: 'ADD_CART_ITEM',
  REMOVE_ONLY_ONE: 'REMOVE_ONLY_ONE',
  REMOVE_ALL: 'REMOVE_ALL',
  GET_TICKET: 'GET_TICKET',
  GET_TICKET_SUCCESS: 'GET_TICKET_SUCCESS',
  GET_TICKET_FAILURE: 'GET_TICKET_FAILURE',
  DELETE_PRODUCT_FROM_CART: 'DELETE_PRODUCT_FROM_CART',
  ADD_PAYMENT_TYPE: 'ADD_PAYMENT_TYPE',

  CREATE_VOUCHER_SUCCESS: 'CREATE_VOUCHER_SUCCESS',
  CREATE_VOUCHER_FAILURE: 'CREATE_VOUCHER_FAILURE',
  CREATE_VOUCHER: 'CREATE_VOUCHER',

  CLEAR_SESSION: 'CLEAR_SESSION',

  REQUESTING_TICKET: 'REQUESTING_TICKET',

  SAVE_CART_SUCCESS: 'SAVE_CART_SUCCESS',
  SAVE_CART_FAILURE: 'SAVE_CART_FAILURE',

  CLEAR_CART: 'CLEAR_CART',
  SET_STEP: 'SET_STEP',
  SAVING_CART: 'SAVING_CART',
  SET_SELL_DRAWER: 'SET_SELL_DRAWER',

  UPDATE_CART_AMOUNTS: 'UPDATE_CART_AMOUNTS',

  REQUESTING_CUSTOMERS: 'REQUESTING_CUSTOMERS',
  SET_CART_CUSTOMERS_SUCCESS: 'SET_CART_CUSTOMERS_SUCCESS',
  SET_CART_CUSTOMERS_FAILURE: 'SET_CART_CUSTOMERS_FAILURE',

  SET_CUSTOMER: 'SET_CUSTOMER',
  SAVE_CUSTOMER_SUCCESS: 'SAVE_CUSTOMER_SUCCESS',
  CLEAR_ADDED_CUSTOMER: 'CLEAR_ADDED_CUSTOMER',

  OVER_STOCK_TRUE: 'OVER_STOCK_TRUE',
  OVER_STOCK_FALSE: 'OVER_STOCK_FALSE',
  CLEAR_CART_ERROR: 'CLEAR_CART_ERROR',

  REMOVE_CART: 'REMOVE_CART',

  EDIT_PRICE_ITEM: 'EDIT_PRICE_ITEM',
};
