import Axios from 'axios';
import ApiClient from './ApiClient';

interface Props { apiVerison?: 'v1' | 'v1.1' }

export default class PublicApiClient extends ApiClient {
  protected axiosInstance;

  getPath(): string {
    return '';
  }
  // private static token: string = "";

  constructor(props: Props = { apiVerison: 'v1' }) {
    super();
    const version = props.apiVerison;
    this.setUrl(`${this.getBaseUrl()}/${version}`);
    this.axiosInstance = Axios.create();
  }

  /*
    override
    */
  public interceptor() {
    this.axiosInstance.interceptors.request.use((config) => {
      // console.log("el api token", AuthorizedApiClient.token)
      config.headers.common.Accept = 'application/json';
      // config.headers.common["Authorization"] = ""
      return config;
    });
  }
}
