export const types = {
  SET_ORDERS_PROCESSING: 'SET_ORDERS_PROCESSING',
  SET_ORDERS_SUCCESS: 'SET_ORDERS_SUCCESS',
  SET_ORDERS_FAILURE: 'SET_ORDERS_FAILURE',
  RESET_ORDERS: 'RESET_ORDERS',
  SET_ORDERS_PARAMS: 'SET_ORDERS_PARAMS',

  CLEAR_ORDERS_REFRESH: 'CLEAR_ORDERS_REFRESH',

};
