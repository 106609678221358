/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Modal,
  Typography,
} from '@mui/material';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { PaymentInfo } from '../Forms/PlansForm/PaymentInfo';
import { BASE_URL } from '../../constants/api/ApiConstants';
import PaymentMethod from '../../containers/pages/admin/PaymentMethod';

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    maxWidth: 850,
    maxHeight: 620,
    padding: '40px 50px',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #878787',
    boxShadow: theme.shadows[5],
    borderRadius: 5,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: 'auto',
    overflowY: 'auto',
  },
  warning: {
    display: 'block',
    minWidth: 40,
    minHeight: 40,
    backgroundImage: `url(${theme.icons.warning})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    cursor: 'pointer',
    margin: 10,
    [theme.breakpoints.down('md')]: {
      minWidth: 35,
      height: 35,
    },
  },
  linkSection: {
    width: 130,
    margin: '15px auto',
  },
  root: {
    width: '100%',
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),

  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    justifyItems: 'flex-end',
    marginTop: 15,
  },
  nextButton: {

    justifyContent: 'center',
    fontSize: 18,
    color: '#FFF',
    position: 'relative',
    bottom: 10,
    backgroundColor: '#8821EA',
    width: 250,
    '&:hover': {
      backgroundColor: '#6A1AB8',
    },
  },
  previousButton: {
    border: 'none',
    backgroundColor: '#fff',
    borderRadius: 50,
    '&:hover': {
      backgroundColor: '#DEDEDE',
    },
  },
}));

export default ({ open, setOpen }) => {
  const classes = useStyles();
  const steps = getSteps();
  const [activeStep, setActiveStep] = React.useState(0);
  const [subscriptionId, setSubscriptionId] = useState('');
  const [error, setError] = useState(null);
  const [idCard, setIdCard] = useState('');
  const [processing, setProcessing] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };
  const planId = '614a50847893331348600551';

  const { companyId } = useSelector((state: any) => state.session);

  const getSuscription = useCallback(async () => {
    axios
      .get(`${BASE_URL}/api/v1/companies/${companyId}/subscriptions`)
      .then((res) => {
        if (res.data.data.data.length > 0) {
          setSubscriptionId(res.data.data.data[0].id);
        }
      });
  }, [companyId]);

  useEffect(() => {
    getSuscription();
  }, [getSuscription]);

  const updatePlan = useCallback(() => {
    setProcessing(true);
    axios
      .put(
        `${BASE_URL}/api/v1/companies/${companyId}/subscriptions/${subscriptionId}`,
        {
          plan_id: planId,
          default_payment_method_id: idCard,
        },
      )
      .then(res => console.log(res))
      .catch((err) => {
        console.log(err);
        setError(err.response.data);
      })
      .finally(() => { setProcessing(false); });
  }, [companyId, planId, subscriptionId, idCard]);

  const handleNextandUpdate = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
    updatePlan();
  };

  function getSteps() {
    return ['0', '1'];
  }

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <PaymentMethod card={idCard} setCard={setIdCard} />;
      case 1:
        return <PaymentInfo err={error} processing={processing} />;
      default:
        return 'Unknown step';
    }
  }

  const body = (
    <Box className={classes.paper}>
      <div className={classes.root}>

        {
          activeStep <= steps.length
            && (
            <>
              <Typography className={classes.instructions}>
                {' '}
                {getStepContent(activeStep)}
                {' '}
              </Typography>
              <Box className={classes.buttons}>

                {
                  activeStep === steps.length - 1
                    ? (
                      error !== null

                        ? (
                          <Button
                            onClick={() => { setActiveStep(0); }}
                            className={classes.nextButton}
                          >
                            Regresar al método de pago
                          </Button>
                        )
                        : (
                          <Button
                            className={classes.nextButton}
                            href="/settings"
                          >
                            Finalizar
                          </Button>
                        )
                    )
                    : (
                      <div className={classes.buttons}>

                        {
                          activeStep === steps.length - 2
                          && (
                            <Button
                              onClick={handleNextandUpdate}
                              className={classes.nextButton}
                            >
                              Confirmar
                            </Button>
                          )
                        }

                      </div>
                    )
                }

              </Box>
            </>
            )
        }

      </div>

    </Box>
  );

  return (
    <Modal
      open={open}
      onClose={handleClose}
      disableEnforceFocus
      disableAutoFocus
    >
      {body}
    </Modal>
  );
};
