/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import SuccessButton from '../../../../../../../components/ui/Buttons/SuccessButton';
import FormInput from '../../../../../../../components/ui/FormInput';
import Box from '../../../../../../../components/ui/Layout/Box';
import Grid from '../../../../../../../components/ui/Layout/Grid';
import SectionTitle from '../../SectionTitle';
import { useCampaignWizardContext } from '../context/CampaignWizardContext';
import useBasicInfoForm from './form_definition';
import Alert from '../../../../../../../components/ui/notifications/alert';
import { dateToUtc } from '../../../../../../../util/dates';

const BasicInfoSection = () => {
  const { t } = useTranslation(['general']);
  const { state, setBasicInfo, saveCampaign } = useCampaignWizardContext();
  const { campaign, error, processing } = state;
  const { register, handleSubmit, errors } = useBasicInfoForm(state.campaign);

  const _saveCampaign = useCallback(
    (data) => {
      setBasicInfo(data);
      const dataToSend = {
        ...campaign,
        delivery_zones: data.zones.map(z => z.id),
        name: data.name,
        description: data.description,
        start_date: dateToUtc(campaign.start_date),
        end_date: dateToUtc(campaign.end_date),
        delivery_date: dateToUtc(campaign.delivery_date),
      };

      dataToSend.variants = campaign.products.map(p => ({
        variant_id: p.variants[0].id,

        campaign_price: p.campaign_price,
        max_quantity: p.max_quantity ? parseFloat(p.max_quantity) : null,
      }));
      delete dataToSend.products;

      dataToSend.assets = campaign.images.map(image => ({
        name: image.name,
        mime_type: image.type,
        Key: image.Key,
        size: image.size,
        resource: image.resource,
      }));

      if (campaign.video) {
        dataToSend.assets.push({
          name: campaign.video.name,
          mime_type: campaign.video.type,
          Key: campaign.video.Key,
          size: campaign.video.size,
          resource: campaign.video.resource,
        });
      }
      delete dataToSend.images;
      delete dataToSend.video;
      delete dataToSend.zones;
      saveCampaign(dataToSend);
    },
    [setBasicInfo, saveCampaign, campaign],
  );
  return (
    <Box mt={5} pb={5}>
      <form autoComplete="off">
        <SectionTitle title={t('general:shared_campaigns:basic_title')} />
        <Box mt={3}>
          {error && (
            <Grid container>
              <Alert
                severity="error"
                message={t(`movements:${error}`, error)}
              />
            </Grid>
          )}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormInput
                register={register('name')}
                error={errors.name}
                label={t('general:name')}
              />
            </Grid>

            <Grid item xs={12}>
              <FormInput
                register={register('description')}
                error={errors.description}
                label={t('general:description')}
                type="textarea"
              />
            </Grid>
          </Grid>
          <Box mt={3} width="100%" display="flex" justifyContent="center">
            <SuccessButton
              text={t('general:shared_campaigns:publish_campaign')}
              onClick={handleSubmit(_saveCampaign)}
              processing={processing ? 1 : 0}
            />
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default BasicInfoSection;
