/* eslint-disable react/prop-types */
import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  Box, CircularProgress, FormControl, InputLabel, MenuItem, Select,
} from '@mui/material';
import useUnits from '../../../hooks/useUnit';
import useCompany from '../../../hooks/useCompany';

const UnitsSelecter = ({ defaultValue, onChange }) => {
  const [option, setOption] = useState(defaultValue);
  const { company } = useCompany();
  const { getUnits, loadingUnits, units } = useUnits(company.id);

  useEffect(() => {
    getUnits();
  }, [getUnits]);

  const onChangeSelect = (e: ChangeEvent<HTMLInputElement>) => {
    setOption(e.target.value);
    if (onChange) onChange(e);
  };

  if (loadingUnits) return <Box width={100}><CircularProgress size={16} /></Box>;

  return (
    <FormControl variant="standard" style={{ width: 90 }}>
      <InputLabel id="unit-select-from-db">Unidad</InputLabel>
      <Select
        variant="standard"
        labelId="unit-select-from-db"
        value={units.length < 1 ? '' : option}
        onChange={onChangeSelect}
      >
        {
          units.map(unit => <MenuItem key={unit.id} value={unit.id}>{unit.code}</MenuItem>)
        }
      </Select>
    </FormControl>
  );
};

export default UnitsSelecter;
