export const START_IMAGE_UPLOAD = 'START_IMAGE_UPLOAD';
export const SET_IMAGE_UPLOAD_ERROR = 'SET_IMAGE_UPLOAD_ERROR';
export const SET_IMAGE_UPLOAD_SUCCESS = 'SET_IMAGE_UPLOAD_SUCCESS';
export const DELETE_IMAGE = 'DELETE_IMAGE';
export const SET_ERROR = 'SET_ERROR';

export const START_VIDEO_UPLOAD = 'START_VIDEO_UPLOAD';
export const SET_VIDEO_UPLOAD_SUCCESS = 'SET_VIDEO_UPLOAD_SUCCESS';
export const SET_VIDEO_UPLOAD_ERROR = 'SET_VIDEO_UPLOAD_ERROR';
export const DELETE_VIDEO = 'DELETE_VIDEO';
export enum AssetType {
  IMAGE = 'image',
  VIDEO = 'video',
}
