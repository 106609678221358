/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Box from '../Layout/Box';
import TopIcon from './TopIcon';

interface SectionLinkProps {
  name: string;
  link?: string;
  section?: string;
  topIcon?: string;
}

const styles = makeStyles(theme => ({
  sectionLink: {
    width: '100%',
    paddingTop: '100%',
    position: 'relative',
  },
  innerCard: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },

  products: {
    backgroundColor: theme.colors.inventory.main,
    mask: `url(${theme.icons.products})`,
  },
  categories: {
    backgroundColor: theme.colors.inventory.main,
    mask: `url(${theme.icons.categories})`,
  },
  cost: {
    backgroundColor: theme.colors.inventory.main,
    mask: `url(${theme.icons.cost})`,
  },
  brands: {
    backgroundColor: theme.colors.inventory.main,
    mask: `url(${theme.icons.brands})`,
  },
  suppliers: {
    backgroundColor: theme.colors.inventory.main,
    mask: `url(${theme.icons.providers})`,
  },

  modifiers_groups: {
    backgroundColor: theme.colors.inventory.main,
    mask: `url(${theme.icons.modifiers_groups})`,
    maskSize: '48% auto !important',
  },
  units: {
    backgroundColor: theme.colors.inventory.main,
    mask: `url(${theme.icons.units})`,
  },
  movements: {
    backgroundColor: theme.colors.orange.main,
    mask: `url(${theme.icons.movements})`,
  },
  recounts: {
    backgroundColor: theme.colors.orange.main,
    mask: `url(${theme.icons.recounts})`,
  },
  locations: {
    backgroundColor: theme.colors.orange.main,
    mask: `url(${theme.icons.locations})`,
  },
  branches: {
    backgroundColor: theme.colors.orange.main,
    mask: `url(${theme.icons.branches})`,
  },

  marketOpinions: {
    backgroundColor: theme.colors.store.main,
    mask: `url(${theme.icons.opinions})`,
  },
  marketSales: {
    backgroundColor: theme.colors.store.main,
    mask: `url(${theme.icons.ecommerceBag})`,
  },
  marketInformation: {
    backgroundColor: theme.colors.store.main,
    mask: `url(${theme.icons.shoppingOnline})`,
  },
  sharedCampaigns: {
    backgroundColor: theme.colors.store.main,
    mask: `url(${theme.icons.sharedCampaigns})`,
  },
  existenceReports: {
    background: `url(${theme.icons.money})`,
    backgroundSize: '66% auto',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    margin: 5,
  },
  movimentReports: {
    background: `url(${theme.icons.circleArrow})`,
    backgroundSize: '66% auto',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    margin: 5,
  },
  salesReports: {
    background: `url(${theme.icons.inventoryReport})`,
    backgroundSize: '66% auto',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    margin: 5,
  },

  card: {
    maskSize: '66% auto',
    maskRepeat: 'no-repeat',
    maskOrigin: 'border-box',
    maskPosition: 'center',
  },
}));

const card = (section = 'card') => {
  const classes = styles();
  return (
    <Box
      className={`${classes.card} ${classes[section]} ${classes.innerCard}`}
    />
  );
};

export default (props: SectionLinkProps) => {
  const { name } = props;
  const classes = styles();
  const _card = card(props.section);
  return (
    <Box className="text-center">
      <Box
        width={1}
        mb={2}
        className={classes.sectionLink}
        boxShadow={3}
        borderRadius="16px"
      >
        {props.topIcon && <TopIcon section={props.topIcon} />}
        {props.link ? <Link to={props.link}>{_card}</Link> : _card}
      </Box>
      <span>{name}</span>
    </Box>
  );
};
