import AuthorizedApiClient from '../../common/api/AuthorizedApiClient';
import OrdersServiceContract from '../../contracts/services/OrdersServiceContract';

export default class ApiOrdersService
  extends AuthorizedApiClient
  implements OrdersServiceContract {
  protected path = 'orders';

  create(data: any) {
    return this.post(data);
  }
}
