import { Box, TextField } from '@mui/material';
import React, { ChangeEvent } from 'react';

const CardYearInput = ({
  label,
  register,
  error,
  defaultValue = '',
  onChange,
  min,
  ...rest
}: any) => {
  let value = defaultValue ? defaultValue.toString() : '';
  value = value.length > 2 ? value.substr(2, 2) : defaultValue;

  return (
    <Box width="100%">
      <TextField
        variant="standard"
        {...rest}
        {...register}
        label={label}
        InputLabelProps={{
          shrink: true,
        }}
        onInput={(e: ChangeEvent<HTMLInputElement>) => {
          e.target.value = e.target.value.replace(/[^0-9]/g, '');
        }}
        inputProps={{ maxLength: 2 }}
        defaultValue={value}
        error={!!error}
        helperText={error !== undefined ? error.message : null}
        onChange={onChange}
      />
    </Box>
  );
};

export default CardYearInput;
