export const navigations = {
  inventory: {
    path: '/inventory',
    label: 'inventory',

  },
  store: {
    path: '/store',
    label: 'store_page:my_store',

  },
  profile: {
    path: '/store/profile',
    label: 'store_page:general_info',
  },
  orders: {
    path: '/store/orders',
    label: 'store_page:orders',
  },
  store_comments: {
    path: '/store/store_comments',
    label: 'store_page:store_comments',
  },
  products: {
    path: '/products',
    label: 'products',
  },
  sales: {
    // path: "/sales",
    label: 'sales',
  },
  categories: {
    path: '/inventory/categories',
    label: 'categories',
  },
  suppliers: {
    path: '/inventory/suppliers',
    label: 'suppliers',
  },
  brands: {
    path: '/inventory/brands',
    label: 'brands',
  },
  branches: {
    path: '/inventory/branches',
    label: 'branches',
  },
  locations: {
    path: '/inventory/locations',
    label: 'locations',
  },
  units: {
    path: '/inventory/units',
    label: 'units',
  },
  movements: {
    path: '/inventory/movements',
    label: 'movements',
  },
  reports: {
    path: '/reports',
    label: 'reports',
  },
  movements_report: {
    path: '/reports/movements_report',
    label: 'movements_reports',
  },
  products_report: {
    path: '/reports/products_report',
    label: 'products_reports',
  },
  sales_report: {
    path: '/reports/sales_report',
    label: 'sales_reports',
  },
  recounts: {
    path: '/inventory/recounts',
    label: 'recounts',
  },
  blank: {
    path: '/inventory/recounts/blank',
    label: 'blank',
  },
  compared: {
    path: '/inventory/recounts/compared',
    label: 'compared',
  },
  customers: {
    path: '/customers',
    label: 'customers',
  },
  modifiers_groups: {
    path: '/inventory/modifiers_groups',
    label: 'modifiers_groups:modifiers_groups',
  },
  campaigns: {
    path: '/store/campaigns',
    label: 'shared_campaigns:campaigns',
  },
};
