/* eslint-disable @typescript-eslint/no-shadow */
import React, { useContext } from 'react';
import Box from '../../../../../../components/ui/Layout/Box';
import BasicInfoSection from './BasicInfoSection';
import { CampaignWizardStep } from './constants/campaign_wizard';
import { CampaignWizardStateContext } from './context/CampaignWizardContext';

import DatesSection from './DatesSection';
import GallerySection from './GallerySection';
import GoalsSection from './GoalsSection';
import ProductsSection from './ProductsSection';
import ZonesSection from './ZonesSection';

const CampaignWizardView = () => {
  // const { state } = useCampaignWizardState();
  const { state } = useContext(CampaignWizardStateContext);
  const { step } = state;

  const renderStep = (step) => {
    switch (step) {
      case CampaignWizardStep.DATES:
        return <DatesSection />;

      case CampaignWizardStep.GOALS:
        return <GoalsSection />;

      case CampaignWizardStep.PRODUCTS:
        return <ProductsSection />;

      case CampaignWizardStep.ZONES:
        return <ZonesSection />;

      case CampaignWizardStep.GALLERY:
        return <GallerySection />;

      case CampaignWizardStep.BASIC_INFO:
        return <BasicInfoSection />;

      default:
        return <div />;
    }
  };
  return <Box>{renderStep(step)}</Box>;
};

export default CampaignWizardView;
