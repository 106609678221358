/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '../../../../ui/Layout/Box';
import LinkButton from '../../../../ui/Buttons/LinkButton';

const ShippingAddress = ({ shipping_address }) => {
  const { t } = useTranslation(['general']);
  const [showDetails, setShowDetails] = useState(false);
  if (shipping_address) {
    return (
      <>
        <Box>{shipping_address.address}</Box>
        {showDetails && (
          <>
            {shipping_address.complement && (
              <Box>{shipping_address.complement}</Box>
            )}
            {shipping_address.city && <Box>{shipping_address.city}</Box>}
            {shipping_address.country && <Box>{shipping_address.country}</Box>}
            {shipping_address.phone && (
              <Box>
                {t('general:phone')}
                :
                {shipping_address.phone}
              </Box>
            )}
          </>
        )}

        <LinkButton
          onClick={() => {
            setShowDetails(!showDetails);
          }}
        >
          {showDetails ? t('general:see_less') : t('general:see_more')}
        </LinkButton>
      </>
    );
  }
  return <></>;
};

export default ShippingAddress;
