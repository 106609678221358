import React from 'react';
import { withStyles } from '@mui/styles';
import Button from '../../../../../../../components/ui/Button';

const CustomButton = withStyles(theme => ({
  root: {
    color: '#FFF',
    backgroundColor: theme.colors.home.main,
    '&:hover': {
      backgroundColor: theme.colors.home.main,
    },
  },
}))(Button);

const NavigationButton = props => (
  <CustomButton {...props} />
);

export default NavigationButton;
