import { PAGINATED_TABLE_PER_PAGE } from '../../../../../constants/common';
import {
  SET_ORDERS_LIST_PROCESSING,
  SET_ORDERS_LIST_ERROR,
  SET_ORDERS_LIST_SUCCESS,
  SET_ORDERS_LIST_PARAMS,
  SET_ORDERS_RESET_FILTERS,
  SET_ORDERS_RESET_ORDERS,
} from '../constants';

export const initialState = {
  processing: false,
  response: {
    data: [],
    total: 0,
    per_page: PAGINATED_TABLE_PER_PAGE,
    last_page: 1,
    page: 1,
  },
  params: {
    page: 1,
    per_page: PAGINATED_TABLE_PER_PAGE,
    sort: { created_at: 'desc' },

    filters: {},
    embed: 'campaigns,user,company,company_users',
  },
};

const salesListReducer = (state: any, action: any) => {
  switch (action.type) {
    case SET_ORDERS_LIST_PROCESSING:
      return { ...state, processing: action.payload, reset: false };

    case SET_ORDERS_LIST_ERROR:
      return { ...state, processing: false, error: action.payload };

    case SET_ORDERS_LIST_SUCCESS:
      return {
        ...state,
        processing: false,
        response: action.payload,
      };

    case SET_ORDERS_LIST_PARAMS:
      return { ...state, params: action.payload };

    case SET_ORDERS_RESET_FILTERS:
      return { ...state, params: { ...state.params, filters: action.payload } };

    case SET_ORDERS_RESET_ORDERS:
      return initialState;

    default:
      return state;
  }
};

export default salesListReducer;
