/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@mui/styles';

const styles = makeStyles(theme => ({
  ReturnIcon: {
    cursor: 'pointer',
    background: `url(${theme.icons.arrowLeft}) no-repeat center`,
    backgroundPosition: 'center',
    backgroundSize: '100%',
    width: '25px',
    height: '25px',
  },
}));

const BackButton = withRouter(({ history }) => {
  const classes = styles();

  const goBack = () => {
    history.goBack();
  };
  return <div className={classes.ReturnIcon} onClick={goBack} />;
});

export default BackButton;
