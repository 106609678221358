/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import { useTranslation } from 'react-i18next';

const OrderListPaymentMethod = ({ value }) => {
  const { t } = useTranslation(['general']);

  return (
    <>
      {value ? t(`general:payment_type:${value.toString().toUpperCase()}`) : ''}
    </>
  );
};

export default OrderListPaymentMethod;
