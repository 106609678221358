import React from 'react';
import SectionListContainer from '../../../../hoc/SectionListContainer';
import Grid from '../../../../components/ui/Layout/Grid';
import PageContainer from '../../../../hoc/PageContainer';
import Box from '../../../../components/ui/Layout/Box';
import RecountCard from './RecountCard';
import { useSession } from '../../../../util/session';
import { PERMISSION, RESOURCES } from '../../../../constants/common';

export default () => {
  const { can } = useSession();
  return (
    <Grid container>
      {/* <Grid item xs={12}>
          <ContentHeader
            title={t("general:inventory")}
            section="inventory"
            back
          />
        </Grid> */}

      <PageContainer>
        {/* <BreadCrumbs /> */}

        <SectionListContainer>
          <Box mt={4}>
            <Grid container spacing={2}>
              {can(RESOURCES.blank_count, PERMISSION.create) && (
              <Grid item xs={12} sm={6}>
                <RecountCard type={1} />
              </Grid>
              )}

              {can(RESOURCES.compared_count, PERMISSION.create) && (
              <Grid item xs={12} sm={6}>
                <RecountCard type={2} />
              </Grid>
              )}
            </Grid>
          </Box>
        </SectionListContainer>
      </PageContainer>
    </Grid>
  );
};
