import React from 'react';
import useDashboardState from '../hooks/useDashboardState';

interface IContextProps {
  state: any;
  setStartDate: Function;
  setEndDate: Function;
}

export const DashboardContext = React.createContext({} as IContextProps);

interface Props {
  children: React.ReactNode;
}

export const DashboardProvider = ({ children }: Props) => {
  const initialState = useDashboardState();
  return (
    <DashboardContext.Provider value={initialState}>
      {children}
    </DashboardContext.Provider>
  );
};

export const useDashboardContext = () => {
  const context = React.useContext(DashboardContext);
  if (context === undefined) {
    throw new Error('DashboardState must be used within a DashboardProvider');
  }
  return context;
};
