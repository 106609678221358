/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import ActivityIndicator from '../../../../../../components/ui/ActivityIndicator';
import Box from '../../../../../../components/ui/Layout/Box';
import Alert from '../../../../../../components/ui/notifications/alert';
import OrderStatus from '../OrderStatus';
import OrderItemView from '../../../../../../components/ui/OrderItemView';
import OrderDetails from '../../../../../../components/ui/OrderDetails';

export default ({
  setError,
  error = null,
  message = null,
  onChangedStatus,
  onStartToChangeStatus,
}) => {
  const { t } = useTranslation(['general']);
  const { order, processing } = useSelector((state: any) => state.order);

  return (
    <Box width={1}>
      {processing ? (
        <ActivityIndicator />
      ) : order === null ? (
        <Alert severity="error">
          {t('general:orders_page:order_not_found')}
        </Alert>
      ) : (
        <Box margin="0px 10px">
          <>
            <OrderDetails
              {...order}
              orderStatusComponent={(
                <>
                  {error && (
                  <Alert severity="error" message={t(`general:${error}`, error)} />
                  )}
                  {message && <Alert severity="success" message={message} />}
                  <OrderStatus
                    order={order}
                    setError={setError}
                    onChangedStatus={onChangedStatus}
                    onStartToChangeStatus={onStartToChangeStatus}
                  />
                </>
                  )}
            />
            {
                  order.items.map(item => (
                    <OrderItemView
                      key={item.id}
                      {...item}
                    />
                  ))
                }
            <Typography variant="h6" align="right" style={{ marginRight: '13%', fontWeight: 'bold' }}>
              Subtotal: $
              {order.subtotal}
            </Typography>
            {
                    order.shipping_method === 'delivery'
                    && (
                    <Typography variant="h6" align="right" style={{ marginRight: '13%', fontWeight: 'bold' }}>
                      Precio de envío: $
                      {order.delivery_price}
                    </Typography>
                    )
                }
            <Typography variant="h5" align="right" style={{ marginRight: '13%', fontWeight: 'bold' }}>
              Total: $
              {order.total}
            </Typography>
          </>
        </Box>
        // <Box width={1}>
        //   <Box mt={6} mb={3} display="flex" flexWrap="wrap" width={1}>
        //     {error && (
        //       <Alert severity="error" message={t(`general:${error}`, error)} />
        //     )}
        //     {message && <Alert severity="success" message={message} />}
        //     <Grid container>
        //       <Grid container item xs={8} sm={8} alignContent="center">
        //         <Box width={1}>
        //           <Typography variant="h5">
        //             {t("general:number")} # {order.number}
        //           </Typography>
        //         </Box>

      //         <Box width={1}>
      //           <Typography variant="body2">
      //             <strong>{t("general:internal_number")} #</strong> {order.company_number}
      //           </Typography>
      //         </Box>

      //         {order.user && <OrderCustomer customer={order.user} />}
      //         {order.shipping_address && (
      //           <OrderShippingAddress
      //             shipping_address={order.shipping_address}
      //           />
      //         )}
      //       </Grid>
      //       <Grid container item xs={4} sm={4} justify="flex-end">
      //         <PaymentType type={order.payment_type} />
      //         <Box width={1} display="flex" justifyContent="flex-end">
      //           {/* <Box mt={2}>
      //             <Typography align="center" variant="body2">
      //               {t("general:status")}
      //             </Typography>
      //             <OrderStatus
      //               order={order}
      //               setError={setError}
      //               onChangedStatus={onChangedStatus}
      //               onStartToChangeStatus={onStartToChangeStatus}
      //             />
      //           </Box> */}
      //         </Box>
      //       </Grid>
      //     </Grid>
      //   </Box>

      //   <Box mt={3}>
      //     <OrderItems items={order.items} />
      //   </Box>

      //   <Box mt={2}>
      //     <Grid container>
      //       <Grid item xs={10}></Grid>
      //       <Grid container item xs={2} justify="center">
      //         <Typography variant="h4">
      //           <FormattedCurrency value={order.total} />
      //         </Typography>
      //       </Grid>
      //     </Grid>
      //   </Box>
      // </Box>
      )}
    </Box>
  );
};
