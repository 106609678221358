/* eslint-disable react/prop-types */
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { DEFAULT_CATEGORY_COLOR } from '../../../../../constants/common';

const styles = makeStyles(() => ({
  tagCategory: {
    display: 'block',
    padding: '7px 5px',
    width: 85,
    borderRadius: 30,
    cursor: 'pointer',
    margin: '3px 6px',
  },
  categoryText: {
    color: '#FFFFFF',
    display: 'block',
    width: '100%',
    textAlign: 'center',
    fontSize: '1rem',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
}));

export default ({ category, click, opacity = true }) => {
  const classes = styles();

  return (
    <Box
      component="span"
      className={classes.tagCategory}
      style={{
        backgroundColor: category.color ? category.color : DEFAULT_CATEGORY_COLOR,
        opacity: opacity ? '100%' : '20%',
      }}
      onClick={() => click(category.id)}
    >

      <Box
        component="span"
        className={classes.categoryText}
      >
        {category.name}
      </Box>

    </Box>
  );
};
