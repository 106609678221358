import { DEBUG } from '../constants/common';

const DEBUG_KEY = 'Debug';
const WARNING_KEY = 'Warning';
const ERROR_KEY = 'Error';
export default class Log {
  public static message(message = '', data: any = null, type = 0): void {
    if (DEBUG) {
      switch (type) {
        case 1:
          console.warn(`${WARNING_KEY}: ${message}`, data);
          break;
        case 2:
          console.error(`${ERROR_KEY}: ${message}`, data);
          break;
        default:
          console.log(`${DEBUG_KEY}: ${message}`, data);
      }
    }
  }

  public static debug(message = '', data: any = null): void {
    Log.message(message, data);
  }

  public static warning(message = '', data: any = null): void {
    Log.message(message, data, 1);
  }

  public static error(message = '', data: any = null): void {
    Log.message(message, data, 2);
  }
}
