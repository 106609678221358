/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { makeStyles } from '@mui/styles';
import PaginatedTable from '../../../../../components/ui/PaginatedTable';

const useStyle = makeStyles(() => ({
  deleteIcon: {
    cursor: 'pointer',
  },
}));

const AssociatedProducts = ({ products, handleDeleteProduct }) => {
  const { t } = useTranslation(['general']);
  const classes = useStyle();

  const _deleteProduct = (id) => {
    if (handleDeleteProduct) {
      handleDeleteProduct(id);
    }
  };

  const columns = [
    {
      name: 'name',
      label: t('general:product_name'),
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: '',
      label: '',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const row = tableMeta.rowData;
          const id = row[2];
          // console.log("product id", id )
          return (
            <Box display="flex" justifyContent="flex-end">
              <DeleteIcon
                onClick={() => _deleteProduct(id)}
                className={classes.deleteIcon}
              />
            </Box>
          );
        },
      },
    },
    {
      name: 'id',
      label: '',

      options: {
        display: false,
        filter: false,
        sort: false,
      },
    },
  ];

  return (
    <>
      {products.length > 0 ? (
        <PaginatedTable
          data={products}
          columns={columns}
          options={{ responsive: 'standard' }}
        />
      ) : (
        <Typography variant="body2">
          {t('general:modifiers_groups:no_associated_products')}
        </Typography>
      )}
    </>
  );
};

export default AssociatedProducts;
