import React from 'react';
import { makeStyles } from '@mui/styles';
import {
  List, ListItem, ListItemText, Divider,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PerfileButton from '../PerfileButton';
import { setOpenPerfile } from '../../../../actions/theme';

const useStyles = makeStyles(theme => ({
  img: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 15,
  },
  textOpcion: {
    color: theme.colors.black,
    textAlign: 'center',
  },
}));

const NavBar = () => {
  const classes = useStyles();
  const { t } = useTranslation(['general']);
  const dispatch = useDispatch();
  const { user } = useSelector((state: any) => state.session);

  return (
    <div>
      <List component="nav">
        <div className={classes.img}>
          {user ? (
            <PerfileButton user={user} width={100} height={100} />
          ) : null}
        </div>

        <Divider />
        <Link to="/settings" onClick={() => dispatch(setOpenPerfile(false))}>
          <ListItem button>
            <ListItemText
              primary={t('general:settings:settings')}
              className={classes.textOpcion}
            />
          </ListItem>
        </Link>
        <Link to="/logout">
          <ListItem button>
            <ListItemText
              primary={t('general:logout')}
              className={classes.textOpcion}
            />
          </ListItem>
        </Link>
      </List>
    </div>
  );
};

export default NavBar;
