import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Tabs } from '@mui/material';
import { styled } from '@mui/styles';
import Grid from '../../../../components/ui/Layout/Grid';
import ContentHeader from '../../../../components/ui/ContentHeader';
import { useSession } from '../../../../util/session';
import { PERMISSION, RESOURCES } from '../../../../constants/common';
import MovementsPage from '../MovementsPage';
import RecountsPage from '../RecountsPage';
import LocationsPage from '../LocationsPage';
import BranchesPage from '../BranchesPage';
import { NavTabs } from '../../../../components/navigation/NavTabs/NavTabs';

const CustomeTabs = styled(Tabs)(() => ({
  '& .MuiTabs-indicator': {
    backgroundColor: '#DE9042',
    color: '#DE9042',
    top: 0,
  },
  '& .Mui-selected': {
    color: '#DE9042',
  },
  '& .MuiTab-wrapper': {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  '& .MuiTabs-flexContainer': {
    display: 'flex',
    alignItems: 'center',
  },
}));

const InventoryPageView = () => {
  // const classes = useStyles()
  const { t } = useTranslation('general');
  const { can } = useSession();

  const tabs = [];

  if (can(RESOURCES.movements, PERMISSION.read)) {
    tabs.push({
      id: 0,
      title: t('general:inventory_movements'),
      component: MovementsPage,
      icon: 'movements',
      to: 'movements',
    });
  }

  if (can(RESOURCES.blank_count, PERMISSION.create)
  || can(RESOURCES.compared_count, PERMISSION.create)) {
    tabs.push({
      id: 1,
      title: t('general:inventory_recounts'),
      component: RecountsPage,
      icon: 'recounts',
      to: 'recounts',
    });
  }

  if (can(RESOURCES.locations, PERMISSION.read)) {
    tabs.push({
      id: 2,
      title: t('general:locations'),
      component: LocationsPage,
      icon: 'locations',
      to: 'locations',
    });
  }

  if (can(RESOURCES.branches, PERMISSION.read)) {
    tabs.push({
      id: 3,
      title: t('general:branch_offices'),
      component: BranchesPage,
      icon: 'branches',
      to: 'branches',
    });
  }

  return (
    <main>
      <Grid item xs={12}>
        <ContentHeader
          title={t('general:inventory')}
          section="inventory"
          back
        />
      </Grid>
      <Box mt={3} p={0} pb={12} mx="auto" width="90%" maxWidth={1100}>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12}>
            {/* <MenuTabs
              data={tabs}
              selectColor="#DE9042"
              colorBar="#DE9042"
            /> */}
            <NavTabs
              tabs={tabs}
              tabsProps={{
                variant: 'scrollable',
                scrollButtons: 'auto',
                'aria-label': 'scrollable auto tabs example',
              }}
              containerTabsProps={{
                elevation: 0,
                style: {
                  borderTop: '1px solid #DE9042',
                },
              }}
              themeColor="#DE9042"
              customTabs={CustomeTabs}
            />
          </Grid>
        </Grid>
      </Box>
    </main>
  );
};

export default InventoryPageView;
