/* eslint-disable react/prop-types */
import React from 'react';
import { useDispatch } from 'react-redux';
import { editBrand } from '../../../../../actions/brand';
import Grid from '../../../../../components/ui/Layout/Grid';
import PaginatedScroll from '../../../../../hoc/PaginatedScroll';
import BrandCard from '../BrandCard';

export default ({ state, getBrands, resetBrands }) => {
  const dispatch = useDispatch();
  const showDetails = (brand) => {
    dispatch(editBrand(brand));
  };

  const list = state.data.map(brand => (
    <Grid item xs={12} sm={6} key={brand.id} onClick={() => showDetails(brand)}>
      <BrandCard brand={brand} />
    </Grid>

  ));

  return (
    <PaginatedScroll
      hasNextPage={state.response.page < state.response.last_page}
      loadData={params => getBrands(params)}
      currentPage={state.response.page}
      resetData={resetBrands}
      processing={state.processing}
    >
      <Grid container spacing={3}>
        {list}
      </Grid>
    </PaginatedScroll>
  );
};
