import ApiOrdersService from '../../services/api/ApiOrdersService';
import OrdersGatewayContract from '../../contracts/gateways/OrdersGatewayContract';
import BaseGateway from './BaseGateway';
import { Order } from '../../contracts/data/Order';

export default class ApiOrdersGateway
  extends BaseGateway<Order>
  implements OrdersGatewayContract {
  parseEntity(row: any): Order {
    return row as Order;
  }

  private static instance: ApiOrdersGateway = null;

  private constructor() {
    super();
    this.service = new ApiOrdersService();
  }

  public static getInstance(): ApiOrdersGateway {
    if (ApiOrdersGateway.instance === null) {
      ApiOrdersGateway.instance = new ApiOrdersGateway();
    }
    return ApiOrdersGateway.instance;
  }
}
