/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import GreenSwitch from '../../../../../components/ui/Switches/GreenSwitch';
import { ProductType } from '../../../../../core/contracts/data/ProductType';

const TypeSwitch = ({ register, defaultChecked = false }) => {
  const { t } = useTranslation(['general']);
  const [checked, setChecked] = useState(
    defaultChecked || false,
  );
  const _handleChange = (event) => {
    setChecked(event.target.checked);
  };
  return (
    <>
      <GreenSwitch
        label={t('general:modifiers_groups:product_type_msg')}
        checked={checked}
        handleChange={_handleChange}
        labelPlacement="end"
      />
      <input
        type="hidden"
        value={checked ? ProductType.REGULAR : ProductType.MODIFIER}
        {...register('type')}
      />

      <input
        type="hidden"
        value={checked ? 'true' : 'false'}
        {...register('published')}
        style={{ display: 'none' }}
      />
    </>
  );
};

export default TypeSwitch;
