/* eslint-disable react/no-unstable-nested-components */
import {
  Button, CircularProgress, Grid, Typography,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { updateProducts } from '../../../../actions/productsCost';
import { setSearchName } from '../../../../actions/search';
import InputSearch from '../../../../components/ui/InputSearch';
import images from '../../../../theme/ECTheme/assets/images';
import ProductsView from './ProductsView';

const useStyles = makeStyles(theme => ({
  containerSearch: {
    display: 'flex',
    alignItems: 'center',
    margin: '15px 5px',
  },
  barcode: {
    display: 'block',
    width: 25,
    height: 25,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundImage: `url(${images.Icon_Code})`,
  },

  itemsTitle: {
    display: 'grid',
    gridTemplateColumns: '1fr 2fr 1fr 1fr 1fr 1fr',
    alignContent: 'center',
    [theme.breakpoints.down('lg')]: {
      '& span': {
        fontSize: 10,
      },
    },
  },
}));

export default () => {
  const { t } = useTranslation('general');
  const classes = useStyles();
  const dispatch = useDispatch();
  const { company } = useSelector((state: any) => state.session);
  const productsCost = useSelector((state: any) => state.productsCost);

  const updateData = async () => {
    const dataupdate = {
      data: productsCost.data,
    };
    await dispatch(updateProducts(company.id, dataupdate));
  };

  const HandleSetSearch = (value) => {
    dispatch(setSearchName(value));
  };

  const titles = (
    <Grid className={classes.itemsTitle}>
      <Grid />
      <Grid />
      <Typography variant="caption" align="center" style={{ alignSelf: 'center', lineHeight: 1 }}>
        { t('general:cost') }
      </Typography>
      <Typography variant="caption" align="center" style={{ alignSelf: 'center', lineHeight: 1 }}>
        { t('general:earnings') }
      </Typography>
      <Typography variant="caption" align="center" style={{ alignSelf: 'center', lineHeight: 1 }}>
        { t('general:price_public') }
      </Typography>
      <Typography variant="caption" align="center" style={{ alignSelf: 'center', lineHeight: 1 }}>
        { t('general:utility') }
      </Typography>
    </Grid>
  );

  const Action = () => (
    <Grid style={{ width: '100%', margin: '15px 0px', textAlign: 'end' }}>
      <Button onClick={updateData} variant="contained" color={productsCost.load ? 'inherit' : 'primary'} style={{ width: 140, height: 30 }}>
        {productsCost.load ? <CircularProgress size={25} /> : t('general:ready') }
      </Button>
    </Grid>
  );

  const Header = () => (
    <Grid style={{ width: '90%', margin: '0px auto' }}>
      <Typography variant="h5" align="center">
        { t('general:admin_cost_products') }
      </Typography>
      <Grid className={classes.containerSearch}>
        <InputSearch
          setSearchValue={HandleSetSearch}
          placeholder={t('general:search')}
        />
        <div style={{ flexGrow: 1, paddingLeft: 15 }}>
          <span className={classes.barcode} />
        </div>
      </Grid>

      <Grid>
        { titles }
      </Grid>
    </Grid>
  );

  return (
    <>
      {/* <Grid item xs={12}>
                <ContentHeader
                title={t("general:pointSell")}
                section="products"
                icon={true}
                />
            </Grid> */}
      <Grid style={{ width: '100%', maxWidth: 1000, margin: '30px auto' }}>
        <Action />
        <Header />
        <ProductsView />
      </Grid>
    </>
  );
};
