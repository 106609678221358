import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { rules as validationRules } from '../../../../../../../util/Validations';

const useBasicInfoForm = (defaultValues = { name: undefined, description: undefined }) => {
  const { t } = useTranslation(['general']);

  const schema = Yup.object({
    name: Yup.string()
      .max(validationRules.maxLength)
      .required(
        t('translation:required', {
          input: t('general:name'),
        }),
      ),
    description: Yup.string().required(
      t('translation:required', {
        input: t('general:description'),
      }),
    ),
  });

  const { register, handleSubmit, formState: { errors } /* , control */ } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues,
  });

  return {
    register,
    handleSubmit,
    errors,
  };
};

export default useBasicInfoForm;
