import Axios from 'axios';
import { useCallback, useState } from 'react';
import { BASE_URL } from '../constants/api/ApiConstants';

const useCategories = (companyId: string) => {
  const [categories, setCAtegories] = useState([]);
  const [loadingCat, setLoad] = useState(false);
  const categoriesPath = `companies/${companyId}/categories`;

  const getCategories = useCallback(() => {
    setLoad(true);
    Axios.get(`${BASE_URL}/api/v1/${categoriesPath}`)
      .then((res) => {
        setCAtegories(res.data.data);
      })
      .finally(() => setLoad(false));
  }, [categoriesPath]);

  return {
    getCategories,
    categories,
    loadingCat,
  };
};

export default useCategories;
