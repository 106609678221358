/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getResources, getUsers, resetUsers } from '../../../../../../actions/users';
import { SCROLL_PER_PAGE } from '../../../../../../constants/common';

import UsersListView from './UsersListView';

export default () => {
  const dispatch = useDispatch();
  const state = useSelector((states: any) => states.users);
  const { refresh } = state;
  const company = useSelector((states: any) => states.session.company);

  const _getUsers = useCallback(
    async (params: any = {}) => {
      if (company) {
        params.per_page = SCROLL_PER_PAGE;
        await dispatch(getUsers(company.id, params));
      }
    },
    [dispatch, company],
  );

  const _getResources = useCallback(
    async () => {
      await dispatch(getResources());
    },
    [dispatch],
  );

  useEffect(() => {
    _getResources();
  }, [_getResources]);

  useEffect(() => {
    if (refresh) {
      dispatch(resetUsers());
      _getUsers();
    }
  }, [refresh, _getUsers, dispatch]);

  const _resetUsers = useCallback(() => {
    dispatch(resetUsers());
  }, [dispatch]);

  return (
    <UsersListView
      state={state}
      getUsers={params => _getUsers(params)}
      resetUsers={_resetUsers}
    />
  );
};
