/* eslint-disable react/prop-types */
import React from 'react';
import { makeStyles } from '@mui/styles';
import { getFormattedDate, getFormattedToday, getFormattedHour } from '../../../../../../util/dates';

const useStyle = makeStyles(theme => ({
  date: {
    textAlign: 'center',
  },
  hour: {
    fontSize: 16,
    fontWeight: 'bold',
    color: theme.colors.black,
  },
  day: {
    fontSize: 9,
    color: theme.colors.black,
  },

}));

export default (props) => {
  const classes = useStyle();
  const { date } = props;
  const dayObtained = getFormattedDate(date);
  const today = getFormattedToday();
  const hourObtained = getFormattedHour(date);

  return (
    <div className={classes.date}>
      <p className={classes.hour}>{ hourObtained }</p>
      <p className={classes.day}>{ dayObtained === today ? 'HOY' : dayObtained }</p>
    </div>
  );
};
