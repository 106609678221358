/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/prop-types */
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useCampaignListContext } from './context/CampaignsListContext';
import AsyncPaginatedTable from '../../../../../../components/ui/AsyncPaginatedTable';
import FormattedCurrency from '../../../../../../components/ui/FormattedCurrency';
import CompletedPercentage from './CompletedPercentage';
import StatusCheck from './StatusCheck';
import ListDate from '../../../../../../components/ui/ListDate';
import StatusFilter from './StatusFilter';
import { CampaignStatus } from '../../../../../../constants/common';
import FormattedNumber from '../../../../../../components/ui/FormattedNumber';

const CampaignsList = () => {
  const { t } = useTranslation(['general']);
  const {
    state,
    getCampaigns,
    refreshList,
    setParams,
    resetFilters,
  } = useCampaignListContext();
  const { processing, response, params } = state;
  const { data } = response;

  const _onResetFilters = useCallback(() => {
    resetFilters();
  }, [resetFilters]);

  const _getCampaigns = useCallback(
    async (_params = {}) => {
      const updatedParams = { ...params, ..._params };
      // console.log("updatedParams", updatedParams)
      setParams(updatedParams);

      await getCampaigns(updatedParams);
    },
    [params, getCampaigns, setParams],
  );

  const _refreshList = useCallback(() => {
    refreshList();
  }, [refreshList]);

  const columns = [
    {
      name: 'id',
      label: '',

      options: {
        display: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'min_percentage',
      label: '',

      options: {
        display: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'finished',
      label: '',

      options: {
        display: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'status',
      label: t('general:status'),

      options: {
        filter: true,
        sort: false,
        filterType: 'custom',
        filterOptions: {
          display: (filterList, onChange, index, column) => (

            <StatusFilter
              defaultValue={params.filters.status}
              onChange={(value) => {
                try {
                  filterList[index][0] = value;
                  onChange(filterList[index], index, column);
                } catch (e) { console.log('error status filter', e.message); }
              }}
            />
          ),
        },
        customBodyRender: (value, tableMeta) => {
          const row = tableMeta.rowData;

          const id = row[0];
          const finished = row[2];
          return <StatusCheck defaultStatus={value} id={id} finished={finished} />;
        },
      },
    },

    {
      name: 'name',
      label: t('general:name'),
      filterName: 'created_at',
      options: {
        filter: false,
        sort: false,
      },
    },

    {
      name: 'sales',
      label: t('general:shared_campaigns:sales'),

      options: {
        filter: false,
        sort: false,
        customBodyRender: value => <FormattedNumber value={value || 0} />,
      },
    },

    {
      name: 'sold_amount',
      label: t('general:shared_campaigns:sold'),

      options: {
        filter: false,
        sort: false,
        customBodyRender: value => <FormattedCurrency value={value} />,
      },
    },

    {
      name: 'goal_amount',
      label: t('general:shared_campaigns:goal'),

      options: {
        filter: false,
        sort: false,
        customBodyRender: value => <FormattedCurrency value={value} />,
      },
    },

    {
      name: 'completed_percentage',
      label: t('general:shared_campaigns:completed_percentage'),

      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const row = tableMeta.rowData;

          const min_percentage = row[1];
          return <CompletedPercentage value={value} minPercentage={min_percentage} />;
        },
      },
    },

    {
      name: 'end_date',
      label: t('general:shared_campaigns:ends_at'),

      options: {
        filter: false,
        sort: false,
        customBodyRender: value => <ListDate value={value} />,
      },
    },

    {
      name: 'delivery_date',
      label: t('general:shared_campaigns:deliveries'),

      options: {
        filter: false,
        sort: false,
        customBodyRender: value => <ListDate value={value} format="YYYY-MM-DD" />,
      },
    },
  ];

  // console.log("params", params)

  return (
    <AsyncPaginatedTable
      hasNextPage={response.page < response.last_page}
      currentPage={response.page}
      perPage={response.per_page}
      data={data}
      columns={columns}
      loadData={params => _getCampaigns(params)}
      resetData={_refreshList}
      processing={processing}
      total={response.total}
      options={{ filter: true, search: true }}
      onResetFilters={_onResetFilters}
      searchField="name"
      defaultFilters={{ status: CampaignStatus.Active }}
    />
  );
};

export default CampaignsList;
