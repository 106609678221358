/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import FormInput from '../../../../../components/ui/FormInput';
import Grid from '../../../../../components/ui/Layout/Grid';

export default ({
  register, errors,
}) => {
  const { t } = useTranslation(['customers']);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormInput
          register={register('business_name')}
          error={errors.business_name}
          label={t('customers:business_name')}
        />
      </Grid>

      <Grid item xs={6}>
        <FormInput
          register={register('rfc')}
          error={errors.rfc}
          label={t('customers:rfc')}
        />
      </Grid>

      <Grid item xs={6}>
        <FormInput
          register={register('curp')}
          error={errors.curp}
          label={t('customers:curp')}
        />
      </Grid>

      <Grid item xs={12}>
        <FormInput
          register={register('bill_address')}
          error={errors.bill_address}
          label={t('customers:bill_address')}
        />
      </Grid>

      <Grid item xs={6}>
        <FormInput
          register={register('no_ext')}
          error={errors.no_ext}
          label={t('customers:no_ext')}
        />
      </Grid>

      <Grid item xs={6}>
        <FormInput
          register={register('no_int')}
          error={errors.no_int}
          label={t('customers:no_int')}
        />
      </Grid>

      <Grid item xs={12}>
        <FormInput
          register={register('bill_email')}
          error={errors.bill_email}
          label={t('customers:email')}
        />
      </Grid>

      <Grid item xs={12}>
        <FormInput
          register={register('bill_phone')}
          error={errors.bill_phone}
          label={t('customers:phone')}
        />
      </Grid>
    </Grid>
  );
};
