import React from 'react';
import useSalesPaymentMethodsState from '../hooks/useSalesPaymentMethodsState';

interface IContextProps {
  state: any;
  getPaymentMethods: Function;
  setParams: Function;
  resetFilters: Function;
}

export const SalesPaymentMethodsContext = React.createContext({} as IContextProps);

interface Props {
  children: React.ReactNode;
}

export const SalesPaymentMethodsProvider = ({ children }: Props) => {
  const initialState = useSalesPaymentMethodsState();
  return (
    <SalesPaymentMethodsContext.Provider value={initialState}>
      {children}
    </SalesPaymentMethodsContext.Provider>
  );
};

export const useSalesPaymentMethodsContext = () => {
  const context = React.useContext(SalesPaymentMethodsContext);
  if (context === undefined) {
    throw new Error(
      'SalesPaymentMethodsState must be used within a SalesPaymentMethodsProvider',
    );
  }
  return context;
};
