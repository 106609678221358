/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TextField, FormControl } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  root: {
    '& .MuiInputBase-root': {
      '& .MuiIconButton-root': {
        padding: 0,
      },
    },
  },
});

const DateInput = ({
  error, label, onChange, defaultValue,
}) => {
  const classes = useStyles();

  const [date, setDate] = useState<Date | null>(
    defaultValue || null,
  );
  const _onChange = (date: Date | null) => {
    // console.log("event", date);
    setDate(date);
    onChange(date);
  };
  return (
    <FormControl variant="standard" error={!!error} fullWidth>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          className={classes.root}
          showToolbar={false}
          disablePast
          // variant="inline"
          inputFormat="yyyy/MM/dd"
          // autoOk
          value={date}
          onChange={_onChange}
          // KeyboardButtonProps={{
          //   'aria-label': 'change date',
          // }}
          // inputVariant="outlined"
          // style={{ width: '100%' }}
          InputAdornmentProps={{ position: 'start' }}
          renderInput={params => (
            <TextField {...params} label={label} helperText={error && error.message} />
          )}
        />
      </LocalizationProvider>
    </FormControl>
  );
};

export default DateInput;
