import { Box } from '@mui/material';
import Alert from '@mui/lab/Alert';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default () => {
  const { t } = useTranslation();
  return (
    <Box>
      <Alert severity="error">
        {t('unauthorized_msg')}
      </Alert>
      {' '}
    </Box>
  );
};
