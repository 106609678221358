/* eslint-disable prefer-regex-literals */
export const rules = {
  // password: new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"),
  password: new RegExp('(?=.{8,})'),
  email: new RegExp('^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}$', 'i'),
  maxLength: 255,
  minPasswordLength: 8,
  companyCode: new RegExp('^[A-Z0-9]*$', 'i'),
  ladaKey: 12,
};

export const cleanKey = (value: string) => value.trim().toLocaleLowerCase();
