/* eslint-disable react/prop-types */
import {
  Box, Button, Grid, TextField, Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { makeStyles, withStyles } from '@mui/styles';
import { addItemToCart, removeProduct, setTemporalPrice } from '../../../../../../actions/cart';
import AddRemove from './AddRemove';

const styles = makeStyles(theme => ({
  container: {
    width: '100%',
    height: '80px',
    marginTop: '5px',
    display: 'grid',
    gridTemplateColumns: '1fr 3fr 1fr 1fr 1fr 1fr',
    gridTemplateRows: '1fr',
    gridTemplateAreas: '"img desc subtotal quantity price trash"',
    alignContent: 'center',
    border: '1px solid grey',
    backgroundColor: 'white',
    boxSizing: 'border-box',
    [theme.breakpoints.down('md')]: {
      height: '130px',
      gridTemplateColumns: 'repeat(4,1fr)',
      gridTemplateRows: '1fr 1fr',
      gridTemplateAreas: `"img desc desc trash"
                                "img price quantity subtotal"`,
    },
  },
  imgSize: {
    width: '100%',
    height: '100%',
    margin: 'auto 0px',
    borderRight: '1px solid grey',
    boxSizing: 'border-box',
  },
  Image: {
    height: '100%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  price: {
    fontWeight: 'bold',
    fontSize: '1rem',
    '&:before': {
      content: "'$'",
    },
  },
  content: {
    [theme.breakpoints.down('md')]: {
      whiteSpace: 'normal',
      marginLeft: 5,
    },
  },
  tittleName: {
    fontSize: '1.2em',
    fontWeight: 550,
  },
  subtitle: {
    display: 'block',
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
    },
  },
  description: {
    display: '-webkit-box',
    WebkitLineClamp: 2,
    lineHeight: 1,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
    },
  },
  trash: {
    height: 22,
    width: 22,
    background: `url(${theme.icons.trash}) no-repeat center`,
    backgroundSize: 'contain',
  },
}));

const CTextField = withStyles(() => ({
  root: {
    '& .MuiOutlinedInput-input': {
      padding: 5,
      marginLeft: 15,
    },
    '& .MuiInputdefaultValue-formControl': {
      position: 'absolute',
    },
    '& .MuiOutlinedInput-root': {
      width: '60%',
      margin: '0px auto',
      position: 'relative',
      '&::before': {
        content: "'$'",
        position: 'absolute',
        left: 7,

      },
    },
  },
}))(TextField);

export default ({ item }) => {
  const classes = styles();
  const dispatch = useDispatch();
  const { t } = useTranslation(['general']);
  const [temporal, setTemporal] = useState<any>(item.temp_price ? item.temp_price : item.subtotal);

  const handleChange = (e) => {
    setTemporal(e.target.value);
  };

  useEffect(() => {
    dispatch(setTemporalPrice(item.id, parseInt(temporal, 10) ? parseInt(temporal, 10) : 0));
  }, [temporal, item.id, dispatch]);

  const deleteItem = (product: any) => {
    const newItem = {
      ...product,
      quantity: 0,
    };
    dispatch(removeProduct(newItem));
  };

  const removeOneProduct = (product: any) => {
    dispatch(removeProduct(product));
  };

  const addOneProduct = (product: any) => {
    dispatch(addItemToCart(product));
  };

  const setValueToItem = (value: number, product: any) => {
    const newItem = {
      ...product,
      quantity: value,
    };
    dispatch(addItemToCart(newItem));
  };

  return (
    <Grid className={classes.container}>
      <Box className={classes.imgSize} style={{ gridArea: 'img' }}>
        <div style={{ backgroundImage: `url(${item.variants[0].image_url})` }} className={classes.Image} />
      </Box>

      <Grid className={classes.content} style={{ gridArea: 'desc', padding: 5 }}>
        <Typography align="inherit" className={classes.tittleName}>
          {item.name}
        </Typography>
        <Typography variant="caption" align="inherit" className={classes.description}>
          {item.selected_modifiers.map(modifier => modifier.products.map(({ product }) => ` + ${product.name}`))}
        </Typography>
      </Grid>

      <Grid className={classes.content} style={{ gridArea: 'subtotal' }}>
        <Typography variant="subtitle1" align="center">
          {t('general:point_of_sell:edit_price')}
        </Typography>
        <CTextField
          id="outlined-basic"
          variant="outlined"
          style={{ alignSelf: 'center' }}
          value={temporal}
          onChange={handleChange}
        />
      </Grid>

      <Grid className={classes.content} style={{ gridArea: 'quantity' }}>
        <Typography variant="subtitle1" align="center" className={classes.subtitle}>
          {t('general:point_of_sell:quantity')}
        </Typography>
        <AddRemove
          value={item.quantity}
          max={item.inventoried ? item.variants[0].stock : 999}
          add={() => addOneProduct(item)}
          remove={() => removeOneProduct(item)}
          onValueChange={(value: number) => setValueToItem(value, item)}
          fontSize={12}
        />
        <Typography variant="caption" align="center" className={classes.subtitle}>
          { item.variants[0].unitofmeasure?.code || 'Unidades'}
        </Typography>
      </Grid>

      <Grid className={classes.content} style={{ gridArea: 'price' }}>
        <Typography variant="subtitle1" align="center" className={classes.subtitle}>
          {t('general:point_of_sell:price')}
        </Typography>
        <Typography variant="body2" align="center" className={classes.price}>
          {(item.temp_price ? item.temp_price : item.subtotal) * item.quantity}
        </Typography>
      </Grid>

      <Grid className={classes.content} style={{ display: 'grid', placeItems: 'center', gridArea: 'trash' }}>
        <Button onClick={() => deleteItem(item)}>
          <span className={classes.trash} />
        </Button>
      </Grid>

    </Grid>
  );
};
