import { types } from '../../actions/product/types';

export const initialState = {
  addedBrand: null,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case types.SAVE_BRAND_SUCCESS: {
      const brand = {
        id: action.response.data.brand.id,
        name: action.response.data.brand.name,
        description: action.response.data.brand.description,
      };
      return {
        ...state,
        addedBrand: brand,
      };
    }

    case types.CLEAR_ADDED_BRAND:
      return {
        ...state,
        addedBrand: null,
      };

    default:
      return state;
  }
};
