/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { makeStyles } from '@mui/styles';
import Icon from '../Icon';
import Text from '../../Text/Text';

const useStyle = makeStyles(() => ({
  tittleContainer: {
    display: 'flex',
    gap: '1rem',
    alignItems: 'center',
    margin: '0.5rem 0',
    width: '100%',
  },
}));

export default (props) => {
  const classes = useStyle();
  const sections = {
    inventory: {
      icon: 'inventory',
      label: 'Inventario',
    },
    finance: {
      icon: 'finance',
      label: 'Finanzas',
    },
    store: {
      icon: 'store',
      label: 'Tienda online',
    },
  };

  return (
    <aside className={classes.tittleContainer}>
      <Icon
        section={sections[props.section].icon}
        size={40}
      />
      <Text>
        {sections[props.section].label}
      </Text>
    </aside>
  );
};
