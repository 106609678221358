/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import Box from '../../../../../components/ui/Layout/Box';
import BranchSelector from '../../../../../components/ui/BranchSelector';
import SuccessButton from '../../../../../components/ui/Buttons/SuccessButton';
import {
  clearCount,
  getCountBranchLocations,
  setBranch,
  setLocation,
  setProductsSearch,
  showMovementsSummary,
} from '../../../../../actions/count';
import LocationSelector from '../../../../../components/ui/LocationSelector';
import InputSearch from '../../../../../components/ui/InputSearch';
import RecountProductsList from './RecountProductsList';
import SwitchLocations from './SwitchLocations';
import MovementsSummary from './MovementsSummary';

const styles = makeStyles(theme => ({
  searchBox: {
    marginTop: '10px',
    [theme.breakpoints.down('lg')]: {
      width: '50%',
    },
  },
  buttonBox: {
    [theme.breakpoints.down('lg')]: {
      width: '50%',
    },
  },
}));

interface RecountProps {
  type: number;
}
export default ({ type }: RecountProps) => {
  const classes = styles();
  const { t } = useTranslation(['recounts', 'general']);
  const dispatch = useDispatch();
  const count = useSelector((state: any) => state.count);
  const { branch_id, location_id } = count.filters;
  const company = useSelector((state: any) => state.session.company);

  const _setBranch = (value) => {
    dispatch(setBranch(value));
  };

  const _setLocation = (location) => {
    dispatch(setLocation(location));
  };
  const _setProductsSearch = (value) => {
    dispatch(setProductsSearch(value));
  };

  const handleSummaryClick = () => {
    dispatch(showMovementsSummary());
  };

  useEffect(() => {
    if (company && branch_id) {
      dispatch(
        getCountBranchLocations(company.id, {
          filters: { branch_id, per_page: 1000 },
        }),
      );
    }
  }, [dispatch, company, branch_id]);

  useEffect(() => () => {
    dispatch(clearCount());
  }, [dispatch]);

  return (
    <>
      <Box display="flex" flexDirection="row" flexWrap="wrap" mb={2}>
        <Box flexGrow={1} className={classes.searchBox}>
          <InputSearch setSearchValue={_setProductsSearch} />
        </Box>
        <Box className={classes.buttonBox}>
          <SuccessButton
            text={t('general:apply_changes')}
            onClick={handleSummaryClick}
          />
        </Box>
      </Box>

      <Box display="flex" flexDirection="row" flexWrap="wrap" mb={2}>
        <Box display="flex" flexDirection="row" flexGrow={1}>
          <Box width="150px">
            <BranchSelector
              company_id={company ? company.id : null}
              branch_id={branch_id}
              setBranch={value => _setBranch(value)}
            />
          </Box>
          <Box width="150px" ml={2}>
            <LocationSelector
              company_id={company ? company.id : null}
              branch_id={branch_id}
              location_id={null}
              setLocation={value => _setLocation(value)}
            />
          </Box>
        </Box>
        {!location_id && (
          <Box>
            <SwitchLocations />
          </Box>
        )}
      </Box>

      <Box>
        <RecountProductsList type={type} />
      </Box>
      <MovementsSummary type={type} />
    </>
  );
};
