import React from 'react';
import {
  StyledEngineProvider, ThemeProvider as MuiThemeProvider, createTheme, Theme,
} from '@mui/material/styles';
import clients from '../../assets/images/Clients.svg';
import configuration from '../../assets/images/Configuration.svg';
import finance from '../../assets/images/Finance.svg';
import home from '../../assets/images/Home.svg';
import inventory from '../../assets/images/Inventory.svg';
import store from '../../assets/images/Store.svg';
import key from '../../assets/images/Key.svg';
import pointSale from '../../assets/images/PointSale.svg';
import questionMark from '../../assets/images/QuestionMark.svg';
import reports from '../../assets/images/Reports.svg';
import selection from '../../assets/images/Selection.svg';
import Logo from '../../assets/images/Logo.svg';
import arrowLeft from '../../assets/images/ArrowLeft.svg';
import circle from '../../assets/images/Circle.svg';

import income from '../../assets/images/Income.svg';
import outcome from '../../assets/images/Outcome.svg';
import adjustment from '../../assets/images/Adjustment.svg';

import blank_count from '../../assets/images/blank_count.svg';
import compared_count from '../../assets/images/compared_count.svg';

import products from '../../assets/images/productsServicesInv.svg';
import brands from '../../assets/images/brandsInv.svg';
import categories from '../../assets/images/categoriesInv.svg';
import cost from '../../assets/images/costEarnInv.svg';
import providers from '../../assets/images/providersInv.svg';
import units from '../../assets/images/unitsInv.svg';
import people from '../../assets/images/people.svg';

import movements from '../../assets/images/movements.svg';
import recounts from '../../assets/images/recounts.svg';
import locations from '../../assets/images/locations.svg';
import branches from '../../assets/images/branches.svg';

import warning from '../../assets/images/advertir.svg';
import money from '../../assets/images/money.svg';
import circleArrow from '../../assets/images/circleArrow.svg';
import inventoryReport from '../../assets/images/inventoryReport.svg';
import financesStore from '../../assets/images/financesStore.svg';

import deliveryMan from '../../assets/images/deliveryMan.svg';
import ecommerceBag from '../../assets/images/ecommerceBag.svg';
import opinions from '../../assets/images/opinions.svg';
import paymentMethod from '../../assets/images/paymentMethod.svg';
import shoppingOnline from '../../assets/images/shoppingOnline.svg';
import modifiers_groups from '../../assets/images/modifiers_groups.svg';
import sharedCampaigns from '../../assets/images/shared_campaigns.svg';
import trash from '../../assets/images/trash.svg';

import visa from '../../assets/images/visa.png';
import masterCard from '../../assets/images/mastercard.png';
import americanExpress from '../../assets/images/american_express.png';

import productsIcon from '../../assets/images/Products.svg';

import moventsReport from '../../assets/images/moventsReport.svg';
import salesReports from '../../assets/images/salesReports.svg';
import stocksReport from '../../assets/images/stocksReport.svg';

import campingIcon from '../../assets/images/storeCampings.svg';

import pickerZones from '../../assets/images/pickerZones.svg';

import mailIcon from '../../assets/images/Mail.svg';
import smsIcon from '../../assets/images/Sms.svg';
import smsWRadius from '../../assets/images/SmsWRadius.svg';

import routesMain from '../../assets/images/routes-main.svg';
import routesMap from '../../assets/images/routes-map.svg';
import routesOrders from '../../assets/images/routes-orders.svg';
import routesTatuses from '../../assets/images/routes-tatuses.svg';
import routesVehicles from '../../assets/images/routes-vehicles.svg';

declare module '@mui/styles' {
  interface DefaultTheme extends Theme {}
}

declare module '@mui/material/styles' {
  interface Theme {
    colors?: {
      home: {
        main?: string;
        light?: string;
      };
      pointSale: {
        main?: string;
        light?: string;
      };
      reports: {
        main?: string;
        light?: string;
      };
      clients: {
        main?: string;
        light?: string;
      };
      store: {
        main?: string;
        light?: string;
      };
      inventory: {
        main?: string;
        light?: string;
      };
      finance: {
        main?: string;
        light?: string;
      };
      cardPurple: {
        main?: string;
        light?: string;
      };
      grey?: string;
      black?: string;
      white?: string;
      trafficLight?: {
        low?: {
          main: string;
          light: string;
        };
        alert?: {
          main: string;
          light: string;
        };
        adequate?: {
          main: string;
          light: string;
        };
      };
      orange:{
        main: string;
        light: string;
      };
      orders?: {
        pending?: string;
        preparing?: string;
        shipped?: string;
        complete?: string;
        sent?: string;
        accepted?: string;
        rejected?: string;
        cancelled?: string;
        returned?: string;
      };
    };
    icons?: {
      moventsReport?: string,
      salesReports?: string,
      stocksReport?: string,

      Logo?: string;
      clients?: string;
      configuration?: string;
      finance?: string;
      home?: string;
      inventory?: string;
      store?: string;
      key?: string;
      pointSale?: string;
      questionMark?: string;
      reports?: string;
      selection?: string;
      arrowLeft: string;

      income: string;
      outcome: string;
      adjustment: string;
      blank_count: string;
      compared_count: string;
      circle: string;

      products: string;
      brands: string;
      categories: string;
      cost: string;
      providers: string;
      units: string;
      people: string;

      movements: string;
      recounts: string;
      locations: string;
      branches: string;

      visa: string;
      masterCard: string;
      americanExpress: string;

      warning: string;
      money: string;
      circleArrow: string;
      inventoryReport: string;
      deliveryMan: string;
      ecommerceBag: string;
      opinions: string;
      paymentMethod: string;
      shoppingOnline: string;
      modifiers_groups: string;
      sharedCampaigns: string;
      trash: string;

      productsIcon: string
      financesStore: string

      campingIcon: string
      pickerZones: string

      mailIcon: string;
      smsWRadius: string;
      smsIcon: string;
      routesMain: string;
      routesMap: string;
      routesOrders: string;
      routesTatuses: string;
      routesVehicles: string;
    };
    shadow?: {
      main: string;
    };
  }
  interface ThemeOptions {
    colors?: {
      home: {
        main?: string;
        light?: string;
      };
      pointSale: {
        main?: string;
        light?: string;
      };
      reports: {
        main?: string;
        light?: string;
      };
      clients: {
        main?: string;
        light?: string;
      };
      store: {
        main?: string;
        light?: string;
      };
      inventory: {
        main?: string;
        light?: string;
      };
      finance: {
        main?: string;
        light?: string;
      };
      cardPurple: {
        main?: string;
        light?: string;
      };
      grey?: string;
      black?: string;
      white?: string;
      trafficLight?: {
        low?: {
          main: string;
          light: string;
        };
        alert?: {
          main: string;
          light: string;
        };
        adequate?: {
          main: string;
          light: string;
        };
      };
      orders?: {
        pending?: string;
        preparing?: string;
        shipped?: string;
        complete?: string;
        sent?: string;
        accepted?: string;
        rejected?: string;
        cancelled?: string;
        returned?: string;
      };
      orange?: {
        main: string;
        light: string;
      }
    };
    icons?: {
      moventsReport?: string,
      salesReports?: string,
      stocksReport?: string,

      Logo?: string;
      clients?: string;
      configuration?: string;
      finance?: string;
      home?: string;
      inventory?: string;
      store?: string;
      key?: string;
      pointSale?: string;
      questionMark?: string;
      reports?: string;
      selection?: string;
      arrowLeft: string;
      income: string;
      outcome: string;
      adjustment: string;
      blank_count: string;
      compared_count: string;
      circle: string;

      products: string;
      brands: string;
      categories: string;
      cost: string;
      providers: string;
      units: string;
      people: string;

      movements: string;
      recounts: string;
      locations: string;
      branches: string;

      warning: string;
      money: string;
      circleArrow: string;
      inventoryReport: string;

      visa: string;
      masterCard: string;
      americanExpress: string;

      deliveryMan: string;
      ecommerceBag: string;
      opinions: string;
      paymentMethod: string;
      shoppingOnline: string;
      modifiers_groups: string;
      sharedCampaigns: string;
      trash: string;

      productsIcon: string;
      financesStore: string;

      campingIcon: string;
      pickerZones: string;

      mailIcon: string;
      smsWRadius: string;
      smsIcon: string;

      routesMain: string;
      routesMap: string;
      routesOrders: string;
      routesTatuses: string;
      routesVehicles: string;
    };
    shadow?: {
      main: string;
    };
  }

  interface Palette {
    white: Palette['primary'];
  }

  interface PaletteOptions {
    white?: PaletteOptions['primary'];
  }
}

declare module '@mui/material/AppBar' {
  interface AppBarPropsColorOverrides {
    white: true;
  }
}

const theme = createTheme(
  {
    typography: {
      fontFamily: ['Roboto'].join(','),
      button: {
        textTransform: 'none',
        fontWeight: 'normal',
      },
    },

    palette: {
      primary: {
        main: '#5d78ff',
      },
      secondary: {
        main: '#737373',
        contrastText: '#ffffff',
      },
      error: {
        main: '#fd397a',
      },
      white: {
        main: '#ffffff',
        contrastText: '#272727',
      },
    },
    components: {
      MuiButton: {
        defaultProps: {
          disableRipple: true,
        },
      },
      MuiPopover: {
        defaultProps: {
          elevation: 1,
        },
        styleOverrides: {
          root: {
            '& .MuiPopover-paper': {
              maxWidth: '320px',
            }
            ,
          },
        },
      },
    },
    colors: {
      home: {
        main: '#7A4CE2',
        light: '#D9CDF6',
      },
      pointSale: {
        main: '#00A0F3',
        light: '#D5F0FF',
      },
      reports: {
        main: '#A174CB',
        light: '#F4E8FC',
      },
      clients: {
        main: '#DA5347',
        light: '#FFE7E5',
      },
      inventory: {
        main: '#FFB901',
        light: '#FFF5E3',
      },
      store: {
        main: '#00A0F3',
        light: '#94D7F9',
      },
      finance: {
        main: '#8BB432',
        light: '#E7F4EA',
      },
      cardPurple: {
        main: '#7A4CE2',
        light: '#E3DAF9',
      },
      grey: '#E8EAEE',
      black: '#000000',
      white: '#FFFFFF',
      trafficLight: {
        low: {
          main: '#FF0000',
          light: '#FFD0D0',
        },
        alert: {
          main: '#FFFF00',
          light: '#FFF5E3',
        },
        adequate: {
          main: '#19BC04',
          light: '#E7F4EA',
        },
      },
      orders: {
        pending: '#FFF5E3',
        preparing: '#EDFCFA',
        complete: '#BBEDCA',
        shipped: '#E7F4EA',
        sent: '#EDF3FC',
        accepted: '#F4E8FC',
        rejected: '#E0E0E0',
        cancelled: '#FFC0BA',
        returned: '#FFE7E5',
      },
      orange: {
        main: '#DE9042',
        light: '#FFDEC6',
      },
    },

    icons: {
      moventsReport,
      salesReports,
      stocksReport,
      Logo,
      clients,
      configuration,
      finance,
      home,
      inventory,
      store,
      key,
      pointSale,
      questionMark,
      reports,
      selection,
      arrowLeft,
      income,
      outcome,
      adjustment,
      blank_count,
      compared_count,
      circle,
      products,
      brands,
      categories,
      cost,
      providers,
      units,
      people,
      movements,
      recounts,
      locations,
      branches,
      warning,
      money,
      circleArrow,
      inventoryReport,

      deliveryMan,
      ecommerceBag,
      opinions,
      paymentMethod,
      shoppingOnline,
      modifiers_groups,
      sharedCampaigns,
      trash,

      visa,
      masterCard,
      americanExpress,
      productsIcon,
      financesStore,

      campingIcon,
      pickerZones,

      mailIcon,
      smsWRadius,
      smsIcon,

      routesMain,
      routesMap,
      routesOrders,
      routesTatuses,
      routesVehicles,
    },
    shadow: {
      main: '0px 3px 6px #00000016',
    },
  },
);

interface Props {
  children: React.ReactNode;
}

const ThemeProvider = ({ children }: Props) => (
  <StyledEngineProvider injectFirst>
    <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
  </StyledEngineProvider>
);

export default ThemeProvider;
