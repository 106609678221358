import AuthorizedApiClient from '../../common/api/AuthorizedApiClient';
import UserService from '../../contracts/services/UserService';

export default class ApiUserService extends AuthorizedApiClient implements UserService {
  async getUser() {
    return this.get();
  }

  getPath(): string {
    return 'user?embed=pos-permissions,ec_roles';
  }
}
