/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/prop-types */
import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import MUIDataTable from 'mui-datatables';

import {
  Paper, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import ModifierListInput from './ModifierListInput';
import Box from '../../../../../components/ui/Layout/Box';
import ModifierListPriceInput from './ModifierListPriceInput';

const useStyle = makeStyles(theme => ({
  root: {
    position: 'relative',
  },
  '@global': {
    '.MuiTableCell-head': {
      color: theme.palette.secondary.dark,
    },
    '.MuiTableCell-body': {
      color: theme.palette.secondary.dark,
      fontWeight: 'normal',
    },
    '.MuiTablePagination-actions': {
      marginLeft: '0',
    },
    '.MuiChip-root': {
      display: 'none',
    },
    '.MuiTableCell-root': {
      padding: '5px 5px',
    },
  },
  deleteIcon: {
    cursor: 'pointer',
  },
}));

const defaultOptions = {
  filter: false,
  search: false,
  print: false,
  download: false,
  viewColumns: false,
  filterType: 'dropdown',
  fixedHeader: true,
  selectableRows: 'none',
  pagination: false,
  onRowClick() {
  },
  serverSide: false,

  textLabels: {
    body: {
      noMatch: '',
    },
    pagination: {},
  },
  responsive: 'standard',
};

const ModifiersList = ({
  modifiers,
  handleDeleteProduct,
  handleModifierQuantity,
}) => {
  const { t } = useTranslation(['general']);
  const classes = useStyle();

  const _deleteProduct = (id) => {
    if (handleDeleteProduct) {
      handleDeleteProduct(id);
    }
  };

  defaultOptions.textLabels.pagination = {
    next: t('general:pagination:next'),
    previous: t('general:pagination:previous'),
    rowsPerPage: t('general:pagination:per_page'),
    displayRows: t('general:pagination:of'),
  };

  const options = { ...defaultOptions };

  const _handleModifierQuantity = (id, quantity) => {
    handleModifierQuantity(id, quantity);
  };

  const idIndex = 4;

  const columns = [
    {
      name: 'name',
      label: t('general:modifiers_groups:modifier_name'),
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'price',
      label: t('general:modifiers_groups:price'),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const index = tableMeta.rowIndex;
          let visiblePrice = 0;
          if (modifiers[index] && modifiers[index].variants[0]) {
            visiblePrice = modifiers[index].variants[0].price;
          }
          return (
            <ModifierListPriceInput defaultValue={visiblePrice.toString()} />
          );
        },
      },
    },
    {
      name: 'max_permitted',
      label: t('general:modifiers_groups:max_permitted'),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const row = tableMeta.rowData;
          const id = row[idIndex];
          const index = tableMeta.rowIndex;
          return (
            <ModifierListInput
              onChange={(event) => {
                _handleModifierQuantity(id, event.target.value);
              }}
              defaultValue={
                modifiers[index] ? modifiers[index].max_permitted : null
              }
            />
          );
        },
      },
    },

    {
      name: '',
      label: '',
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({ style: { maxWidth: '30px' } }),
        customBodyRender: (value, tableMeta) => {
          const row = tableMeta.rowData;
          const id = row[idIndex];
          return (
            <Box display="flex" justifyContent="flex-end" width="30px">
              <DeleteIcon onClick={() => _deleteProduct(id)} className={classes.deleteIcon} />
            </Box>
          );
        },
      },
    },
    {
      name: 'id',
      label: '',

      options: {
        display: false,
        filter: false,
        sort: false,
      },
    },
  ];

  return (
    <>
      {modifiers.length > 0 ? (
        <Paper className={classes.root}>
          <MUIDataTable
            data={modifiers}
            columns={columns}
            options={options}
          />
        </Paper>
      ) : (
        <Typography variant="body2">
          {t('general:modifiers_groups:no_associated_modifiers')}
        </Typography>
      )}
    </>
  );
};

export default ModifiersList;
