import React from 'react';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import Box from '../../../../../components/ui/Layout/Box';
import { BLANK_RECOUNT } from '../../../../../constants/common';

const styles = makeStyles(theme => ({
  container: {
    position: 'relative',
    cursor: 'pointer',
    border: '0.5px solid #9A9494',
    borderRadius: '3px',
    boxShadow: '0px 3px 6px #00000029',
    minHeight: '340px',
  },
  image: {
    height: '220px',
    width: '100%',
  },
  blankCount: {
    background: `url(${theme.icons.blank_count}) no-repeat center`,
    backgroundPositionY: '90%',
  },
  comparedCount: {
    background: `url(${theme.icons.compared_count}) no-repeat`,
    backgroundPositionX: '80%',
  },
  margin: {
    marginBottom: theme.spacing(1.5),
  },
  description: {
    color: theme.palette.text.primary,
  },
}));

type RecountCardProps = {
  type: number;
};

export default ({ type }: RecountCardProps) => {
  const classes = styles();
  const { t } = useTranslation('general');
  const title = type === BLANK_RECOUNT ? t('general:blank_count') : t('general:compared_count');
  const description = type === BLANK_RECOUNT
    ? t('general:blank_count_desc')
    : t('general:compared_count_desc');

  const customClass = type === 1 ? classes.blankCount : classes.comparedCount;
  const link = type === BLANK_RECOUNT ? '/inventory/recounts/blank' : '/inventory/recounts/compared';
  return (
    <Box className={classes.container} p={2}>
      <Link to={link}>
        <Typography
          variant="h6"
          color="textPrimary"
          align="center"
          className={classes.margin}
        >
          {title}
        </Typography>
        <Typography variant="body2" className={`${classes.margin} ${classes.description}`}>
          {description}
        </Typography>

        <Box className={`${classes.image} ${customClass}`} mt={1} />
      </Link>
    </Box>
  );
};
