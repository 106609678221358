/* eslint-disable @typescript-eslint/naming-convention */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '../../../../../components/ui/Layout/Grid';
import SalesCosts from './SalesCosts';
import TotalSales from './TotalSales';
import SalesProfit from './SalesProfit';
import SalesRevenue from './SalesRevenue';
import { useKPIDashboardContext } from './context/KPIDashboardContext';
import LabelledOutline from '../../../../../hoc/LabelledOutline';
import { useDashboardContext } from '../context/DashboardContext';
import { dateToUtc } from '../../../../../util/dates';

const KPIDashboard = () => {
  const { t } = useTranslation(['general']);
  const { getSales, state } = useKPIDashboardContext();
  const { params } = state;

  const { state: dashboradState } = useDashboardContext();
  const { start_date, end_date } = dashboradState;

  useEffect(() => {
    getSales({
      filters: {
        updated_at: {
          $gte: dateToUtc(start_date),
          $lte: dateToUtc(end_date),
        },
      },
    });
  }, [params, start_date, end_date, getSales]);

  return (
    <LabelledOutline label={t('general:admin_dashboard:kpi_title')}>
      <Grid container spacing={1}>
        <Grid item xs={6} sm={3}>
          <TotalSales />
        </Grid>
        <Grid item xs={6} sm={3}>
          <SalesRevenue />
        </Grid>
        <Grid item xs={6} sm={3}>
          <SalesProfit />
        </Grid>
        <Grid item xs={6} sm={3}>
          <SalesCosts />
        </Grid>
      </Grid>
    </LabelledOutline>
  );
};

export default KPIDashboard;
