export const types = {
  EDIT_SUPPLIER: 'EDIT_SUPPLIER',
  CLOSE_SUPPLIER_MODAL: 'CLOSE_SUPPLIER_MODAL',
  SAVING_SUPPLIER: 'SAVING_SUPPLIER',
  SAVE_SUPPLIER_SUCCESS: 'SAVE_SUPPLIER_SUCCESS',
  SAVE_SUPPLIER_FAILURE: 'SAVE_SUPPLIER_FAILURE',
  DELETING_SUPPLIER: 'DELETING_SUPPLIER',
  DELETE_SUPPLIER_SUCCESS: 'DELETE_SUPPLIER_SUCCESS',
  DELETE_SUPPLIER_FAILURE: 'DELETE_SUPPLIER_FAILURE',
  CLEAR_REFRESH: 'CLEAR_REFRESH',
};
