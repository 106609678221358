export const types = {
  EDIT_LOCATION: 'EDIT_LOCATION',
  CLOSE_LOCATION_MODAL: 'CLOSE_LOCATION_MODAL',
  SAVING_LOCATION: 'SAVING_LOCATION',
  SAVE_LOCATION_SUCCESS: 'SAVE_LOCATION_SUCCESS',
  SAVE_LOCATION_FAILURE: 'SAVE_LOCATION_FAILURE',
  DELETING_LOCATION: 'DELETING_LOCATION',
  DELETE_LOCATION_SUCCESS: 'DELETE_LOCATION_SUCCESS',
  DELETE_LOCATION_FAILURE: 'DELETE_LOCATION_FAILURE',
  CLEAR_REFRESH: 'CLEAR_REFRESH',

  SET_BRANCHES_LOCATION_SUCCESS: 'SET_BRANCHES_LOCATION_SUCCESS',
  SET_BRANCHES_LOCATION_FAILURE: 'SET_BRANCHES_LOCATION_FAILURE',
};
