import { types } from '../../actions/product/types';

export const initialState = {
  addedUnit: null,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case types.SAVE_UNITOFMEASURE_SUCCESS: {
      const unitofmeasure = {
        id: action.response.data.unitofmeasure.id,
        code: action.response.data.unitofmeasure.code,
        description: action.response.data.unitofmeasure.description,
      };

      return {
        ...state,
        addedUnit: unitofmeasure,
      };
    }

    case types.CLEAR_ADDED_UNITOFMEASURE:
      return {
        ...state,
        addedUnit: null,
      };

    default:
      return state;
  }
};
