import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
// export const middlewares = [ReduxThunk];
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import reducers from './reducers';
// export const createStoreWithMiddleware = applyMiddleware(...middlewares)(createStore)
const persistConfig = {
  key: 'ecubi',
  storage,
  whitelist: ['theme', 'cart'],
};

const persistedReducer = persistReducer(persistConfig, reducers);
export const store = createStore(persistedReducer, applyMiddleware(thunk));

export const persistor = persistStore(store);

export default store;
