export const formatBranchData = (data, image = null) => {
  if (data.lat && data.lng) {
    data.coordinates = {
      lat: data.lat,
      lng: data.lng,
    };
  }

  delete data.lng;
  delete data.lat;

  if (image && data.coordinates) {
    data.coordinates = JSON.stringify(data.coordinates);
  }

  return data;
};
