import AuthorizedApiClient from '../../core/common/api/AuthorizedApiClient';
import { ApiParams } from '../../types/api';
import { types } from './types';

export const resetSearchProducts = () => ({
  type: types.RESET_SEARCH_PRODUCTS,
});

export const setSearchName = name => ({
  type: types.SET_SEARCH_NAME,
  name,
});
export const setSearchCategory = category_id => ({
  type: types.SET_SEARCH_CATEGORY,
  category_id,
});

export const searching = () => ({
  type: types.SEARCHING,
});

export const getSearchProducts = (companyId, params: ApiParams = {}) => async (
  dispatch: any,
) => {
  const searchParams = params;
  const sg = new AuthorizedApiClient();
  const path = `companies/${companyId}/products`;
  dispatch(searching());

  if (!!searchParams.filters?.category_id && searchParams.filters.category_id.length < 1) {
    delete searchParams.filters.category_id;
  }

  try {
    const response = await sg.get(searchParams, path);
    if (response != null) {
      dispatch({ type: types.SEARCH_SUCCESS, response: response.data });
    }
  } catch (error) {
    dispatch({ type: types.SEARCH_ERROR, erro: error });
  }
};
