/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '../../../../../components/ui/Layout/Box';
import Table from '../../../../../components/ui/Table';
import FormattedNumber from '../../../../../components/ui/FormattedNumber';
import MovementTypeIcon from '../../../../../components/ui/MovementTypeIcon';
import Adjustment from './MovementsTypes/Adjustment';
import Income from './MovementsTypes/Income';
import Outcome from './MovementsTypes/Outcome';
import DateBar from './DateBar';
import MovementDate from './MovementDate';
import Comment from './Comment';

export default ({ state, getMovements, resetMovements }) => {
  const { t } = useTranslation(['movements', 'general']);

  const columns = [

    {
      name: 'created_at',
      label: t('movements:hour'),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          if (value) {
            return <MovementDate value={value} />;
          }
          return '';
        },
      },
      customRenderer: value => <MovementDate value={value} />,
    },

    {
      name: 'product',
      label: t('movements:product'),

      options: {
        filter: false,
        sort: false,
        customBodyRender: (product) => {
          if (product) {
            return product.name;
          }
          return '';
        },
      },
    },
    {
      name: 'location',
      label: t('movements:location'),

      options: {
        filter: false,
        sort: false,
        customBodyRender: (location) => {
          if (location) {
            return location.name;
          }
          return '';
        },
      },
    },
    {
      name: 'current_quantity',
      label: t('movements:current_quantity'),

      options: {
        filter: false,
        sort: false,
        customBodyRender: value => <FormattedNumber value={value} />,
      },
    },
    {
      name: 'type',
      label: t('movements:type'),

      options: {
        filter: false,
        sort: false,
        customBodyRender: value => <MovementTypeIcon value={value} />,
      },
    },
    {
      name: 'quantity',
      label: t('movements:quantity'),

      options: {
        filter: false,
        sort: false,
        customBodyRender: value => <FormattedNumber value={value} />,
      },
    },
    {
      name: 'new_quantity',
      label: t('movements:new_quantity'),

      options: {
        filter: false,
        sort: false,
        customBodyRender: value => <FormattedNumber value={value} />,
      },
    },
    {
      name: 'user',
      label: t('movements:who'),

      options: {
        filter: false,
        sort: false,
        customBodyRender: (user) => {
          if (user) {
            return `${user.name} ${user.last_name}`;
          }
          return '';
        },
      },
    },
    {
      name: 'comments',
      label: t('movements:comments'),

      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const row = tableMeta.rowData;
          // console.log("row", row);

          const id = row[10];
          const _row = {
            parent_type: row[9],
            parent_number: row[11],
          };
          // console.log("_row", _row);
          return <Comment value={value} row={_row} id={id} />;
        },
      },
    },
    {
      name: 'parent_type',
      label: '',

      options: {
        display: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'parent',
      label: '',

      options: {
        display: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'parent_number',
      label: '',

      options: {
        display: false,
        filter: false,
        sort: false,
      },
    },
  ];

  const handleMovementTypeClick = async () => {
    // resetMovements();
    // await getMovements({ page: 1, filters: { type: type } });
  };

  return (
    <Box>
      <Box mb={3} display="flex" flexWrap="wrap">
        <Box>
          <Adjustment onClick={handleMovementTypeClick} />
        </Box>
        <Box ml={2}>
          <Income onClick={handleMovementTypeClick} />
        </Box>
        <Box ml={2}>
          <Outcome onClick={handleMovementTypeClick} />
        </Box>
      </Box>

      <Box mb={3}>
        <DateBar />
      </Box>

      <Box>
        <Table
          hasNextPage={state.response.page < state.response.last_page}
          currentPage={state.response.page}
          data={state.data}
          columns={columns}
          loadData={params => getMovements(params)}
          resetData={resetMovements}
          processing={state.processing}
          options={{ tableBodyHeight: window.innerHeight * 0.5 }}
        />
      </Box>
    </Box>
  );
};
