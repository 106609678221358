import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Box from '../../../../../components/ui/Layout/Box';
import PaginatedTable from '../../../../../components/ui/PaginatedTable';
import { Tabs } from '../../../../../components/ui/Tabs';
import Alert from '../../../../../components/ui/notifications/alert';

export default () => {
  const { t } = useTranslation(['general']);
  const { failed, inserted } = useSelector(
    (state: any) => state.products_excel,
  );
  const columns = [
    {
      name: 'name',
      label: t('general:products_excel:name'),
      options: {
        filter: false,
        sort: false,
      },
    },

    {
      name: 'description',
      label: t('general:products_excel:description'),

      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'barcode',
      label: t('general:products_excel:barcode'),

      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'cost',
      label: t('general:products_excel:cost'),

      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'price',
      label: t('general:products_excel:price'),

      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'published',
      label: t('general:products_excel:published'),

      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          if (value) {
            return 'Si';
          }
          return 'No';
        },
      },
    },
  ];

  const failedColumns = [
    ...columns,
    {
      name: 'error',
      label: t('general:products_excel:cause'),

      options: {
        filter: false,
        sort: false,
      },
    },
  ];

  const formattedInserted = inserted.map((i) => {
    const defaultVariant = i.variants && i.variants[0] ? i.variants[0] : null;
    return {
      name: i.name,
      description: i.description,
      barcode: defaultVariant ? defaultVariant.barcode : '',
      cost: defaultVariant ? defaultVariant.cost : '',
      price: defaultVariant ? defaultVariant.price : '',
      published: i.published,
    };
  });

  const validTab = inserted.length > 0 ? (
    () => <PaginatedTable data={formattedInserted} columns={columns} />
  ) : (
    () => <>{t('general:products_excel:no_data')}</>
  );
  const failedTab = failed.length > 0 ? (
    () => <PaginatedTable data={failed} columns={failedColumns} />
  ) : (
    () => <>{t('general:products_excel:no_data')}</>
  );
  const tabs = [

    {
      index: 0,
      title: t('general:products_excel:inserted_products', {
        total: formattedInserted.length,
      }),
      content: validTab,
    },
    {
      index: 1,
      title: t('general:products_excel:failed_products', {
        total: failed.length,
      }),
      content: failedTab,
    },
  ];

  return (
    <Box>
      <Box>
        <Alert
          severity="success"
          message={t('general:products_excel:success_msg')}
        />
      </Box>
      <Tabs tabs={tabs} />
    </Box>
  );
};
