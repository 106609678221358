/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/prop-types */
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  clearCount,
  closeMovementsSummary,
  saveCountMovements,
  savingCountMovements,
} from '../../../../../../actions/count';
import MovementsSummaryView from './MovementsSummaryView';
import {
  MOVEMENT_PARENT_TYPE,
  MOVEMENT_TYPE,
} from '../../../../../../constants/common';

export default ({ type }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['recounts']);
  const company = useSelector((state: any) => state.session.company);

  const {
    counted, showSummary, error, processing,
  } = useSelector(
    (state: any) => state.count_movements,
  );

  const handleClose = () => {
    dispatch(closeMovementsSummary());
  };

  const handleSubmit = useCallback(() => {
    if (company) {
      const number = new Date().valueOf();
      // console.log("number", number)
      const _counted = counted.filter(c => c.discrepancy);
      const movements = _counted.map(item => ({
        variant_id: item.variant_id,
        location_id: item.location_id,
        quantity: item.discrepancy,
        type: MOVEMENT_TYPE.ADJUSTMENT,
        parent_type: MOVEMENT_PARENT_TYPE.Count,
        parent_number: number.toString(),
      }));

      // console.log("movements", movements)
      if (movements.length) {
        dispatch(saveCountMovements(company.id, movements));
      } else { // simalate saving movements
        // console.log("simalutating")
        dispatch(savingCountMovements());
        setTimeout(() => {
          dispatch(clearCount());
        }, 2000);
      }
    }
  }, [dispatch, company, counted]);

  return (
    <MovementsSummaryView
      error={error}
      handleClose={handleClose}
      showModal={showSummary}
      title={t('recounts:summary')}
      type={type}
      counted={counted}
      processing={processing}
      handleSubmit={handleSubmit}
    />
  );
};
