import ApiAuthService from '../../services/api/ApiAuthService';
import LoginGatewayContract from '../../contracts/gateways/LoginGatewayContract';
import Log from '../../../util/Log';

export default class ApiLoginGateway implements LoginGatewayContract {
  private static instance: ApiLoginGateway = null;

  private service: ApiAuthService = null;

  private constructor() {
    this.service = new ApiAuthService();
  }

  public static getInstance(): ApiLoginGateway {
    if (ApiLoginGateway.instance === null) {
      ApiLoginGateway.instance = new ApiLoginGateway();
    }
    return ApiLoginGateway.instance;
  }

  async getToken(username: string, password: string) {
    try {
      const data = await this.service.login(username, password);
      Log.debug('response gateway', data);
      if (data !== undefined) {
        return data;
      }
      return null;
    } catch (error) {
      Log.debug('error gateway', error);
      throw new Error(error);
    }
  }
}
