import {
  Box, Button,
} from '@mui/material';
import Axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import PlansForm from '../../../../../components/Forms/PlansForm';
import Text from '../../../../../components/Text/Text';
import { MemberCard } from '../../../../../components/ui/MemberCard';
import PaymentMethodCard from '../../../../../components/ui/PaymentMethodCard';
import { PaymentMethodSelect } from '../../../../../components/ui/PaymentMethodSelect';
import { SubsHistoryCard } from '../../../../../components/ui/SubsHistoryCard';
import { BASE_URL } from '../../../../../constants/api/ApiConstants';
import useCompany from '../../../../../hooks/useCompany';
import images from '../../../../../theme/ECTheme/assets/images';

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },

  container: {
    borderRadius: 5,
    border: `1px solid ${theme.colors.grey}`,
    padding: '.5em 1em',
    minWidth: '15rem',
    display: 'flex',
    flexDirection: 'column',
  },
  containerCards: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  colorText: {
    color: 'black',
    marginTop: 10,
  },
  paymentMethChange: {
    color: 'blue',
    fontSize: 'small',
  },
  email: {
    color: 'black',
    padding: '.3rem .5rem',
  },
  membership: {
    color: 'red',
    fontSize: 'small',
    height: '35px',
  },
  flexButton: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  plansInfo: {
    marginBottom: 15,
  },
  nextButton: {
    justifyContent: 'center',
    fontSize: 18,
    color: '#FFF',
    marginInline: 70,
    backgroundColor: '#8821EA',
    width: 250,
    '&:hover': {
      backgroundColor: '#6A1AB8',
    },
  },
  selectPlan: {
    marginBottom: 70,
  },

}));

export const PaymentsAndSubsPage = () => {
  const classes = useStyles();
  const { companyId } = useSelector((state: any) => state.session);
  const [paymentData, setPaymentData] = useState([]);
  const [load, setLoad] = useState(true);
  const { user } = useCompany();
  const [planSub, setPlanSub] = useState<any>({});
  const [selectMenu, setSelectMenu] = useState(false);

  const [open, setOpen] = useState(false);
  const handleOpenModal = () => {
    setOpen(true);
  };

  const [subHistory, setSubHistory] = useState([]);

  const [cardSelected, setCardSelected] = useState('');
  const handleChangeCard = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCardSelected(event.target.value);
  };

  const MenuSelector = () => {
    setSelectMenu(!selectMenu);
  };

  const getSuscription = useCallback(
    async () => {
      Axios.get(`${BASE_URL}/api/v1/companies/${companyId}/subscriptions/?embed=plan,payment_method`)
        .then((res) => {
          if (res.data.data.data.length > 0) {
            setPlanSub(res.data.data.data[0].plan);
          }
        })
        .catch(console.error);
    },
    [companyId],
  );

  useEffect(() => {
    getSuscription();
  }, [getSuscription]);

  const getPaymentMeth = useCallback(
    async () => {
      Axios.get(`${BASE_URL}/api/v1/user/Payment_methods`)
        .then((data) => {
          // console.log("💲",data.data.data)
          setPaymentData(data.data.data);
        })
        .finally(() => {
          setLoad(false);
        });
    },
    [],
  );

  useEffect(() => {
    getPaymentMeth();
  }, [getPaymentMeth]);

  const getSubHistory = useCallback(
    () => {
      Axios.get(`${BASE_URL}/api/v1/companies/${companyId}/subscriptions/payments?limit=10`)
        .then((res) => {
          // console.log("✔💲 subscription", res.data[0].lines)
          setSubHistory(res.data);
        })
        .catch((err) => {
          console.log('err', err);
        });
    },
    [companyId],
  );

  useEffect(() => {
    getSubHistory();
  }, [getSubHistory]);

  return (
    <Box>
      <div className={classes.selectPlan}>
        <div className={classes.plansInfo}>
          <PlansForm open={open} setOpen={setOpen} plan={null} step="2" />
        </div>

        {
          planSub.code === 'free' && (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              onClick={handleOpenModal}
              className={classes.nextButton}
            >
              Ver planes
            </Button>
          </div>
          )
        }

      </div>

      <Text variant="h5" className={classes.colorText}>
        {' '}
        <b>Membresías y Facturación</b>
      </Text>
      <div className={classes.colorText}>
        Configura el metodo de pago de tu servicio
        {' '}
        <b>Ecubi mi negocio</b>
      </div>
      <Text variant="h6" className={classes.email}>
        {' '}
        <b>{user.email}</b>
      </Text>
      <div style={{ textAlign: 'right' }}>
        <Link to="/change_payment_method">
          <Button className={classes.paymentMethChange}>
            Administrar información de pago
          </Button>
        </Link>
      </div>
      <div className={classes.container}>

        <div className={classes.containerCards}>

          <div>
            <img src={images.Icon_EcubiMiNegocio} alt="" />
          </div>
          <div>
            <MemberCard planSubCode={planSub} open={handleOpenModal} />
          </div>
          <div>
            <Text>Método de pago</Text>
            { !load && paymentData.length > 0
              ? (
                <div>
                  <PaymentMethodCard cardData={planSub.code === 'free' ? {} : paymentData[0].data} />
                  {
                      planSub.code !== 'free'
                        ? (
                          <Link to="/change_payment_method">
                            <Button className={classes.paymentMethChange}>
                              cambiar metodo de pago
                            </Button>
                          </Link>
                        )
                        : null
                  }
                </div>
              )
              : null}
            <Button onClick={MenuSelector} className={classes.paymentMethChange}>
              Metodo de pago para suscripción
            </Button>
            {
              selectMenu
                ? (
                  <div>
                    {
                      paymentData.map(({ data }) => (
                        <PaymentMethodSelect
                          cardData={data}
                          key={data.id}
                          checked={cardSelected === data.id}
                          onChange={handleChangeCard}
                          value={data.id}
                        />
                      ))
                  }
                  </div>
                )
                : null
            }

          </div>
        </div>
      </div>

      <Text style={{ marginTop: '20px' }} variant="h5" className={classes.colorText}>
        {' '}
        <b>Pagos</b>
      </Text>
      <SubsHistoryCard
        subHistory={subHistory}
      />
    </Box>
  );
};
