import { types } from './types';
import AuthorizedApiClient from '../../core/common/api/AuthorizedApiClient';

export const resetModifiersGroups = () => ({
  type: types.RESET_MODIFIERS_GROUPS,
});

export const getModifiersGroups = (companyId, params = {}) => async (
  dispatch: any,
) => {
  const sg = new AuthorizedApiClient();
  const path = `companies/${companyId}/modifiers_groups`;
  dispatch({ type: types.SET_MODIFIERS_GROUPS_PROCESSING, payload: true });
  try {
    const response = await sg.get(params, path);

    if (response != null) {
      dispatch({ type: types.SET_MODIFIERS_GROUPS_SUCCESS, response: response.data });
    }
  } catch (error) {
    dispatch({ type: types.SET_MODIFIERS_GROUPS_FAILURE, error });
  }
};
