import AuthorizedApiClient from '../../core/common/api/AuthorizedApiClient';
import { types } from './types';

export const showMovementsSummary = () => ({
  type: types.SHOW_COUNT_MOVEMENTS_SUMMARY,
});

export const closeMovementsSummary = () => ({
  type: types.CLOSE_COUNT_MOVEMENTS_SUMMARY,
});

export const setBranch = branch_id => ({
  type: types.SET_COUNT_BRANCH,
  branch_id,
});

export const setShowAllLocations = showAllLocations => ({
  type: types.SET_SHOW_ALL_LOCATIONS,
  showAllLocations,
});

export const setLocation = location => ({
  type: types.SET_COUNT_LOCATION,
  location,
});

export const setProcessingProducts = () => ({
  type: types.SET_COUNT_PRODUCTS_PROCESSING,
  payload: true,
});

export const setProductsSearch = search => ({
  type: types.SET_COUNT_PRODUCTS_SEARCH,
  search,
});

export const resertCountProducts = () => ({
  type: types.RESET_COUNT_PRODUCTS,
});

export const clearCount = () => ({
  type: types.CLEAR_COUNT,
});

export const clearCountLocation = () => ({
  type: types.CLEAR_COUNT_LOCATIONS,
});

export const addCountItem = item => ({
  type: types.ADD_COUNT_ITEM,
  item,
});

export const removeCountItem = item => ({
  type: types.REMOVE_COUNT_ITEM,
  item,
});

export const savingCountMovements = () => ({
  type: types.SAVING_COUNT_MOVEMENTS,
});

export const saveCountMovements = (companyID, movements) => async (dispatch: any) => {
  dispatch(savingCountMovements());

  const sg = new AuthorizedApiClient();
  const path = `companies/${companyID}/movements/batch`;
  try {
    const response = await sg.post({ data: movements }, path);
    if (response != null) {
      dispatch({ type: types.SET_COUNT_MOVEMENTS_SUCCESS, response: response.data });
    }
  } catch (error) {
    dispatch({ type: types.SET_COUNT_MOVEMENTS_FAILURE, error: error.response.data });
  }
};

export const getCountProducts = (companyId, params = {}) => async (
  dispatch: any,
) => {
  const sg = new AuthorizedApiClient();
  const path = `companies/${companyId}/products`;
  dispatch(setProcessingProducts());
  try {
    const response = await sg.get(params, path);
    if (response != null) {
      dispatch({ type: types.SET_COUNT_PRODUCTS_SUCCESS, response: response.data });
    }
  } catch (error) {
    dispatch({ type: types.SET_COUNT_PRODUCTS_FAILURE, erro: error });
  }
};

export const getCountBranchLocations = (companyId, params = {}) => async (
  dispatch: any,
) => {
  const sg = new AuthorizedApiClient();
  const path = `companies/${companyId}/locations`;
  dispatch({ type: types.SET_COUNT_LOCATIONS_PROCESSING, payload: true });
  try {
    const response = await sg.get(params, path);

    if (response != null) {
      dispatch({ type: types.SET_COUNT_LOCATIONS_SUCCESS, response: response.data });
    }
  } catch (error) {
    dispatch({ type: types.SET_COUNT_LOCATIONS_FAILURE, erro: error });
  }
};
