import React from 'react';
import { Toaster } from 'react-hot-toast';

export default () => (
  <Toaster toastOptions={{
    className: '',
    position: 'bottom-center',

    style: {
      border: '1px solid #713200',
      padding: '16px',
      color: '#FFF',
      backgroundColor: '#333',
    },
  }}
  />
);
