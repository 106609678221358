/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/no-unused-prop-types */
import React, { HtmlHTMLAttributes } from 'react';
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { COMPARED_RECOUNT } from '../../../../../../../constants/common';
import Box from '../../../../../../../components/ui/Layout/Box';
import InputQty from './InputQty';
import DisabledQuantity from './DisabledQuantity';
import CellContent from './CellContent';

interface ProductLocationRowProps extends HtmlHTMLAttributes<any> {
  product: any;
  stock?: number | null;
  location: any;
  variant_id: string;
  handleCounted: Function;
  type: number;
}

export default ({
  location,
  variant_id,
  stock,
  handleCounted,
  type,
}: ProductLocationRowProps) => {
  const { counted } = useSelector((state: any) => state.count_movements);
  const countedItem = counted
    .find(c => c.variant_id === variant_id && c.location_id === location.id);

  const handleCountedChange = (value) => {
    let _dis;
    if (!value && value !== 0) {
      _dis = null;
    } else {
      _dis = value - stock;
    }

    handleCounted(variant_id, location, stock, value, _dis);
  };

  const showQuantities = type === COMPARED_RECOUNT;
  // console.log("here");

  return (
    <Box pt={1}>
      <Grid container>
        <Grid item xs={12} md={showQuantities ? 4 : 8} />
        <Grid item xs={showQuantities ? 3 : 7} md={2}>
          <Box width={1} display="flex" justifyContent="center">

            <CellContent content={location ? location.name : ''} />
          </Box>
        </Grid>

        {showQuantities && (
          <Grid item xs={3} md={2}>
            <Box width={1} display="flex" justifyContent="center">
              <DisabledQuantity quantity={stock} />
            </Box>
          </Grid>
        )}
        <Grid item xs={showQuantities ? 3 : 5} md={2}>
          <Box width={1} display="flex" justifyContent="center">
            <InputQty
              value={countedItem ? countedItem.counted_quantity : null}
              handleOnChange={handleCountedChange}
            />
          </Box>
        </Grid>
        {showQuantities && (
          <Grid item xs={3} md={1}>
            <Box width={1} display="flex" justifyContent="center">
              <DisabledQuantity quantity={countedItem ? countedItem.discrepancy : null} />
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
