/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Grid, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AvailableProducts from './AvailableProducts';
import DataContainer from '../../../../../../hoc/DataContainer';
import ModalBanner from '../../../../../../components/ui/ModalBanner';
import Tag from '../../../../../../components/ui/Tags';
import AddTag from '../../../../../../components/ui/Buttons/AddTag';
import InventoryLights from '../../../../../../components/ui/InventoryLights';
import MovementsHistory from '../../../../../../components/ui/Buttons/MovementsHistory';
import { AutoCompleteOption } from '../../../../../../components/ui/FormInput/AutoComplete';
import { PERMISSION, RESOURCES } from '../../../../../../constants/common';
import { useSession } from '../../../../../../util/session';
import SupplierSelector from '../SupplierSelector';
import InventoriedSwitch from '../InventoriedSwitch';
import AvailableSwitch from '../AvailableSwitch';
import MovementForm from './MovementForm';
import AddButton from '../../../../../../components/ui/Buttons/BlueButton/AddButton';

export default ({
  control,
  formState,
  register,
  setFormData,
  errors,
  inventories,
  setInventories,
}) => {
  const { can } = useSession();
  const [openSuppliersSelect, setOpenSuppliersSelect] = useState(false);
  const [movementsFrom, setMovementsFrom] = useState(false);

  const handleOpenMovementsFrom = () => {
    setMovementsFrom(true);
  };

  const handleSupplierChanged = (event) => {
    if (event.target.value) {
      const currentSupplier = formState.selectedSuppliers.find(
        s => s.value === event.target.value.value,
      );

      if (!currentSupplier) {
        setFormData('selectedSuppliers', [
          ...formState.selectedSuppliers,
          event.target.value,
        ]);
      }
    }
    setOpenSuppliersSelect(false);
  };

  const { t } = useTranslation(['general', 'products']);

  const isNew = !formState.id;

  let suppliersList = [];

  if (formState.selectedSuppliers && formState.selectedSuppliers.length > 0) {
    suppliersList = formState.selectedSuppliers.map((s: AutoCompleteOption) => <Tag key={s.value} label={s.label} />);
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ModalBanner section="inventory" />
      </Grid>

      <Grid item xs={12}>
        <InventoriedSwitch
          control={control}
        />
      </Grid>

      {!formState.inventoried && (
      <Grid item xs={12}>
        <AvailableSwitch
          control={control}
        />
      </Grid>
      )}

      {!isNew && can(RESOURCES.stock) && formState.inventoried && (
        can(RESOURCES.movements, PERMISSION.create) ? (
          <Grid item xs={12}>
            <AddButton onClick={handleOpenMovementsFrom}>
              {t('new_movement')}
            </AddButton>
            <MovementForm
              productId={formState.variant_id}
              productName={formState.name}
              isOpen={movementsFrom}
              handleClose={() => setMovementsFrom(false)}
            />
          </Grid>
        )
          : (
            <Grid item xs={12}>
              <AddButton disabled>
                {t('new_movement')}
              </AddButton>
            </Grid>
          )
      )}

      {!isNew && can(RESOURCES.stock) && can(RESOURCES.movements, PERMISSION.create) && formState.inventoried && (
        <Grid item xs={12}>
          <AvailableProducts
            inventories={inventories}
            setInventories={setInventories}
          />
        </Grid>
      )}

      <Grid item xs={12}>
        <DataContainer tittle="Proveedores (Opcional)">
          <Box display="flex" flexWrap="wrap" p={0}>
            {suppliersList}
          </Box>
          <Box
            onClick={() => {
              setOpenSuppliersSelect(true);
            }}
          >
            <AddTag tittle={t('general:add_supplier')} />
          </Box>

          {openSuppliersSelect && (
            <Box>
              <SupplierSelector handleOnChange={handleSupplierChanged} />
            </Box>
          )}
        </DataContainer>
      </Grid>
      {can(RESOURCES.stock, PERMISSION.edit) && formState.inventoried && (
        <Grid item xs={12}>
          <DataContainer tittle={t('products:traffic_light_inventory')}>
            <InventoryLights
              register={register('adequate_stock')}
              alert="green"
              title={t('products:adequate_quantity')}
              text={t('products:when_it_has_more_than')}
              error={errors.adequate_stock}
              defaultValue={
                formState.adequate_stock ? formState.adequate_stock : 0
              }
            />
            <InventoryLights
              register={register('stock_alert')}
              alert="yellow"
              title={t('products:request_sale')}
              text={t('products:when_it_has_less_than')}
              error={errors.stock_alert}
              defaultValue={formState.stock_alert ? formState.stock_alert : 0}
            />
            <InventoryLights
              register={register('low_stock')}
              alert="red"
              title={t('products:low_stock')}
              text={t('products:when_it_has_less_than')}
              error={errors.low_stock}
              defaultValue={formState.low_stock ? formState.low_stock : 0}
            />
          </DataContainer>
        </Grid>
      )}

      <Grid item xs={12}>
        {!isNew && formState.inventoried && <MovementsHistory />}
      </Grid>
    </Grid>
  );
};
