import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box, Tabs,
} from '@mui/material';
import { styled } from '@mui/styles';
import Grid from '../../../../components/ui/Layout/Grid';
import ContentHeader from '../../../../components/ui/ContentHeader';
// import { MenuTabs } from '../../../../components/ui/MenuTabs';
import { useSession } from '../../../../util/session';
import { PERMISSION, RESOURCES } from '../../../../constants/common';
import AllProductsPage from '../AllProductsPage';
import CostEarningsPage from '../CostEarningsPage';
import CategoriesPage from '../CategoriesPage';
import BrandsPage from '../BrandsPage';
import SuppliersPage from '../SuppliersPage';
import UnitsPage from '../UnitsPage';
import ModifiersGroupsPage from '../ModifiersGroupsPage';
import { NavTabs } from '../../../../components/navigation/NavTabs/NavTabs';

const CustomeTabs = styled(Tabs)(() => ({
  '& .MuiTabs-indicator': {
    backgroundColor: '#FFB901',
    color: '#FFB901',
    top: 0,
  },
  '& .Mui-selected': {
    color: '#FFB901',
  },
  '& .MuiTab-wrapper': {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  '& .MuiTabs-flexContainer': {
    display: 'flex',
    alignItems: 'center',
  },
}));

export const ProductsPage = () => {
  const { t } = useTranslation('general');
  const { can } = useSession();

  const tabs = [];

  if (can(RESOURCES.products, PERMISSION.read)) {
    tabs.push({
      title: t('general:product_page:products_services'),
      component: AllProductsPage,
      icon: 'productos',
      id: 0,
      to: 'all',
    });
  }

  if (can(RESOURCES.products_reports, PERMISSION.read)) {
    tabs.push({
      title: t('general:product_page:cost'),
      icon: 'cost',
      component: CostEarningsPage,
      id: 1,
      to: 'costs',
    });
  }

  if (can(RESOURCES.categories, PERMISSION.read)) {
    tabs.push({
      title: t('general:product_page:categories'),
      icon: 'categories',
      component: CategoriesPage,
      id: 2,
      to: 'categories',
    });
  }

  if (can(RESOURCES.brands, PERMISSION.read)) {
    tabs.push({
      title: t('general:product_page:brands'),
      icon: 'brands',
      component: BrandsPage,
      id: 3,
      to: 'brands',
    });
  }

  if (can(RESOURCES.suppliers, PERMISSION.read)) {
    tabs.push({
      title: t('general:product_page:suppliers'),
      icon: 'suppliers',
      component: SuppliersPage,
      id: 4,
      to: 'suppliers',
    });
  }

  if (can(RESOURCES.units, PERMISSION.read)) {
    tabs.push({
      title: t('general:units'),
      icon: 'units',
      component: UnitsPage,
      id: 5,
      to: 'units',
    });
  }

  if (can(RESOURCES.products, PERMISSION.read)) {
    tabs.push({
      title: t('general:product_page:modifiers_groups'),
      icon: 'modifiers_groups',
      component: ModifiersGroupsPage,
      id: 6,
      to: 'modifiers-groups',
    });
  }

  return (
    <main>
      <Grid item xs={12}>
        <ContentHeader
          title={t('general:products')}
          section="products"
          back
        />
      </Grid>
      <Box mt={3} p={0} pb={12} mx="auto" width="90%" maxWidth={1100}>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12}>
            <NavTabs
              tabs={tabs}
              tabsProps={{
                variant: 'scrollable',
                scrollButtons: 'auto',
                'aria-label': 'scrollable auto tabs example',
              }}
              containerTabsProps={{
                elevation: 0,
                style: {
                  borderTop: '1px solid #FFB901',
                },
              }}
              themeColor="#FFB901"
              customTabs={CustomeTabs}
            />
          </Grid>
        </Grid>
      </Box>
    </main>
  );
};
