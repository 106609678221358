/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Box from '../../../../../components/ui/Layout/Box';
import ConfirmDialog from '../../../../../components/ui/ConfirmDialog';
import Grid from '../../../../../components/ui/Layout/Grid';
import Alert from '../../../../../components/ui/notifications/alert';
import ImageUploader from '../../../../../components/ui/ImageUploader';
import FormInput from '../../../../../components/ui/FormInput';
import { rules as validationRules } from '../../../../../util/Validations';
import CategoryColor from '../CategoryColor';
import { DEFAULT_CATEGORY_COLOR } from '../../../../../constants/common';
import Actions from '../../../../../components/ui/Modals/Actions/Actions';
import Modal from '../../../../../components/ui/Modals/Modal';
import useSignUploadUrl from '../../../../../components/ui/ImageUploader/useSignUploadUrl';

export default ({
  category,
  attemptSaveCategory,
  attemptDeleteCategory,
  processing,
  deletingCategory,
  error,
  handleClose,
  showModal,
}) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const [image, setImage] = useState(null);
  const [imageKey, setImageKey] = useState(null);
  const { t } = useTranslation(['categories', 'translation', 'general']);
  const [color, setColor] = useState(
    category.color ? category.color : DEFAULT_CATEGORY_COLOR,
  );

  const schema = Yup.object({
    name: Yup.string()
      .max(validationRules.maxLength)
      .required(
        t('translation:required', {
          input: t('categories:category_name'),
        }),
      ),
    description: Yup.string().max(validationRules.maxLength),
  });
  const { register, handleSubmit, formState: { errors } } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    data.id = category.id ? category.id : null;
    if (data.id) {
      data.image = image === '' ? '' : category.image;
    }

    data.color = color;

    if (imageKey) {
      data.asset = {
        type: 'image',
        key: imageKey,
      };
    }

    attemptSaveCategory(data);
  };

  const actions = (
    <Actions
      handleClose={handleClose}
      handleSubmit={handleSubmit(onSubmit)}
      handleDelete={() => setShowConfirm(true)}
      isNew={category.id === null}
      processing={processing}
      deleting={deletingCategory}
    />
  );

  const { id } = category;
  useEffect(() => {
    setImage(null);
  }, [id]);

  const imgUrl = category.image_url ? category.image_url : null;

  const title = category.id == null
    ? t('categories:create_category')
    : t('categories:edit_category', { name: category.name });

  // TODO: dynamic url.
  const { signUrl } = useSignUploadUrl('categories');

  return (
    <Modal
      open={showModal}
      onClose={handleClose}
      title={title}
      actions={actions}
    >
      <Box>
        <ConfirmDialog
          open={showConfirm}
          handleCancel={() => {
            setShowConfirm(false);
          }}
          handleConfirm={() => {
            setShowConfirm(false);
            attemptDeleteCategory(category);
          }}
          title={t('categories:delete_category')}
          content={t('categories:delete_confirmation_msg')}
        />
        {error && (
          <Grid container>
            <Alert
              severity="error"
              message={
                error === 'category has related products.'
                  ? t('cat_related_product')
                  : error
              }
            />
          </Grid>
        )}
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <Grid container spacing={2}>
            <Grid
              container
              item
              xs={12}
              sm={6}
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <Box height="190px" width="190px">
                <ImageUploader
                  imgUrl={imgUrl}
                  setImage={image => setImage(image)}
                  text={t('products:add_image')}
                  signUrl={signUrl}
                  setImageKey={setImageKey}
                />
              </Box>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Grid container>
                <Grid item xs={12}>
                  <Box mt={2}>
                    <FormInput
                      register={register('name')}
                      error={errors.name}
                      label={t('categories:category_name')}
                      defaultValue={category.name ? category.name : ''}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <Box mt={3}>
                    <FormInput
                      register={register('description')}
                      error={errors.description}
                      type="textarea"
                      label={t('categories:description')}
                      defaultValue={
                        category.description ? category.description : ''
                      }
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <CategoryColor setColor={(color: string) => setColor(color)} propcolor={category.color ? category.color : color} />
          </Grid>

        </form>
      </Box>
    </Modal>
  );
};
