import {
  Box, Button, Grid, Paper, Typography,
} from '@mui/material';
import { DirectionsRenderer, DirectionsService, GoogleMap } from '@react-google-maps/api';
import React, { useState } from 'react';

const containerStyle = {
  width: '100%',
  height: '450px',
  borderRadius: '8px',
};

const initialCenter = {
  lat: 20.991913026470257,
  lng: -89.5652719334658,
};

enum TravelMode {
  BICYCLING = 'BICYCLING',
  DRIVING = 'DRIVING',
  TRANSIT = 'TRANSIT',
  WALKING = 'WALKING',
}

const ROUTES_RESPONSE = [
  {
    direction: 'Plaza Bon Ami, Av. Andrés García Lavín 336A, San Antonio Cucul, 97116 Mérida, Yuc.',
    origin: {
      lat: 20.969422504535395,
      lng: -89.59035184347442,
    },
    destination: {
      lat: 20.990608100831842,
      lng: -89.60207678932049,
    },
  },
  {
    direction: 'Plaza Bon Ami, Av. Andrés García Lavín 336A, San Antonio Cucul, 97116 Mérida, Yuc.',
    origin: {
      lat: 20.991913026470257,
      lng: -89.5652719334658,
    },
    destination: {
      lat: 20.9421908,
      lng: -89.6016754,
    },
  },
  {
    direction: 'Plaza Bon Ami, Av. Andrés García Lavín 336A, San Antonio Cucul, 97116 Mérida, Yuc.',
    origin: {
      lat: 21.003714056666972,
      lng: -89.61051542946166,
    },
    destination: {
      lat: 21.039279038385885,
      lng: -89.62875094409047,
    },
  },
  {
    direction: 'Plaza Bon Ami, Av. Andrés García Lavín 336A, San Antonio Cucul, 97116 Mérida, Yuc.',
    origin: {
      lat: 21.0380710542821,
      lng: -89.61929514213622,
    },
    destination: {
      lat: 21.018171885646705,
      lng: -89.58118815767213,
    },
  },
  {
    direction: 'Plaza Bon Ami, Av. Andrés García Lavín 336A, San Antonio Cucul, 97116 Mérida, Yuc.',
    origin: {
      lat: 21.003714056666972,
      lng: -89.61051542946166,
    },
    destination: {
      lat: 21.039279038385885,
      lng: -89.62875094409047,
    },
  },
];

interface RoutesMapProps {
  route: any,
  directionsCallback: any
}

const RoutesMap = React.memo(({ route, directionsCallback }: RoutesMapProps) => {
  console.log('map');
  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      // onLoad={onLoadMap}
      center={initialCenter}
      zoom={13}
      options={{
        clickableIcons: false,
        disableDefaultUI: true,
        // styles: styleMap,
        zoomControl: true,
      }}
    >
      <DirectionsService
        // required
        options={{
          travelMode: route.travelMode,
          origin: route.origin,
          destination: route.destination,
          waypoints: [
            {
              location: {
                lat: 21.018171885646705,
                lng: -89.58118815767213,
              } as any,
              stopover: true,
            },
          ],
        }}
        // required
        callback={directionsCallback}
        // optional
        onLoad={(directionsService) => {
          console.log('DirectionsService onLoad directionsService: ', directionsService);
        }}
        // optional
        onUnmount={(directionsService) => {
          console.log('DirectionsService onUnmount directionsService: ', directionsService);
        }}
      />
      {
        route.response !== null && (
          <DirectionsRenderer
            // required
            options={{
              directions: route.response,
            }}
            // optional
            onLoad={(directionsRenderer) => {
              console.log('DirectionsRenderer onLoad directionsRenderer: ', directionsRenderer);
            }}
            // optional
            onUnmount={(directionsRenderer) => {
              console.log('DirectionsRenderer onUnmount directionsRenderer: ', directionsRenderer);
            }}
          />
        )
      }
    </GoogleMap>
  );
});

const MapsPage = () => {
  const [route, setRoute] = useState({
    response: null,
    travelMode: TravelMode.DRIVING,
    origin: {
      lat: 20.991913026470257,
      lng: -89.5652719334658,
    },
    destination: {
      lat: 20.9421908,
      lng: -89.6016754,
    },
  });

  const directionsCallback = (response) => {
    console.log('responseeee', response);

    if (response !== null) {
      if (response.status === 'OK') {
        setRoute({
          ...route,
          response,
        });
      } else {
        console.log('response: ', response);
      }
    }
  };

  const onClickRoute = (routeSelected) => {
    setRoute({
      ...route,
      destination: routeSelected.destination,
    });
  };

  return (
    <Grid>
      <Box display="flex" alignItems="strech" flexWrap="nowrap" gap="8px" margin="6px 0px" overflow="auto" p={1}>
        {
          ROUTES_RESPONSE.map(r => (
            <Paper elevation={1} style={{ flex: '1 1 0px', minWidth: 250 }}>
              <Button onClick={() => onClickRoute(r)} fullWidth style={{ textAlign: 'start', color: 'darkslategray', height: '100%' }}>
                <Box padding={2}>
                  <Typography variant="button">
                    {r.direction}
                  </Typography>
                  {/* <Typography variant="caption" component="p">
                    origin:
                    {' '}
                    {`${r.origin.lat}, ${r.origin.lng}`}
                  </Typography> */}
                  <Typography variant="caption" component="p">
                    destination:
                    {' '}
                    {`${r.destination.lat}, ${r.destination.lng}`}
                  </Typography>
                </Box>
              </Button>
            </Paper>
          ))
        }
      </Box>
      <RoutesMap
        route={route}
        directionsCallback={directionsCallback}
      />
    </Grid>
  );
};

export default MapsPage;
