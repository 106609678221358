/* eslint-disable prefer-const */
import { types } from './types';
import Log from '../../util/Log';
import AuthorizedApiClient from '../../core/common/api/AuthorizedApiClient';

export const editUnitOfMeasure = (unitOfMeasure = null) => ({
  type: types.EDIT_UNITOFMEASURE,
  payload: unitOfMeasure,
});

export const savingUnitOfMeasure = () => ({
  type: types.SAVING_UNITOFMEASURE,
});

export const saveUnitOfMeasureSucceed = response => ({
  type: types.SAVE_UNITOFMEASURE_SUCCESS,
  response,
});

export const saveUnitOfMeasureFailed = error => ({
  type: types.SAVE_UNITOFMEASURE_FAILURE,
  error,
});

export const deletingUnitOfMeasure = () => ({
  type: types.DELETING_UNITOFMEASURE,
});

export const deleteUnitOfMeasureSucceed = response => ({
  type: types.DELETE_UNITOFMEASURE_SUCCESS,
  response,
});

export const deleteUnitOfMeasureFailed = error => ({
  type: types.DELETE_UNITOFMEASURE_FAILURE,
  error,
});

export const closeModalUnitOfMeasure = () => ({
  type: types.CLOSE_UNIT_MODAL,
});

export const saveUnitOfMeasure = (_data, companyId) => async (dispatch: any) => {
  dispatch(savingUnitOfMeasure());
  const sg = new AuthorizedApiClient();
  let path = `companies/${companyId}/unitofmeasures`;

  try {
    let response;
    let data;
    const { id } = _data;

    data = _data;

    if (id != null) {
      path = `${path}/${id}`;
      delete data.id;
      response = await sg.put(data, path);
    } else {
      delete data.id;
      response = await sg.post(data, path);
    }

    if (response != null) {
      Log.debug('saved unitofmeasure', response);
      dispatch(saveUnitOfMeasureSucceed(response));
      dispatch(closeModalUnitOfMeasure());
    }
  } catch (error) {
    Log.error(error.message);
    dispatch(saveUnitOfMeasureFailed(error.message));
  }
};

export const deleteUnitOfMeasure = (unitOfMeasure, companyId) => async (dispatch: any) => {
  dispatch(deletingUnitOfMeasure());
  const sg = new AuthorizedApiClient();
  let path = `companies/${companyId}/unitofmeasures`;
  try {
    if (!unitOfMeasure.id) {
      throw new Error('The id is required');
    }

    path = `${path}/${unitOfMeasure.id}`;
    const response = await sg.delete(path);

    if (response != null) {
      Log.debug('deleted unitOfMeasure', response);
      dispatch(deleteUnitOfMeasureSucceed(response));
      dispatch(closeModalUnitOfMeasure());
    }
  } catch (error) {
    Log.error(error.message);
    dispatch(deleteUnitOfMeasureFailed(error.message));
  }
};
