/* eslint-disable react/prop-types */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Modal from '../../../../../components/ui/Modals/Modal';
import SupplierFormView from './SupplierFormView';
import { rules as validationRules } from '../../../../../util/Validations';

import { closeModalSupplier, deleteSupplier, saveSupplier } from '../../../../../actions/supplier';
import { getSuppliers, resetSuppliers } from '../../../../../actions/suppliers';
import { SCROLL_PER_PAGE } from '../../../../../constants/common';
import { types } from '../../../../../actions/supplier/types';
import Box from '../../../../../components/ui/Layout/Box';
import Actions from '../../../../../components/ui/Modals/Actions/Actions';

export default ({ source = 1 }) => {
  const { showModal, supplier } = useSelector((state: any) => state.supplier);

  const [data, setData] = useState({});

  const [showConfirm, setShowConfirm] = useState(false);

  useEffect(() => {
    setData(supplier);
  }, [supplier, setData]);

  const dispatch = useDispatch();
  const { t } = useTranslation(['suppliers']);

  const schema = Yup.object({
    name: Yup.string()
      .max(validationRules.maxLength)
      .required(
        t('translation:required', {
          input: t('suppliers:supplier_name'),
        }),
      ),
    alias: Yup.string().max(validationRules.maxLength),
    manager: Yup.string().max(validationRules.maxLength),
    phone: Yup.string().max(validationRules.maxLength),
    cellphone: Yup.string().max(validationRules.maxLength),
    address: Yup.string().max(validationRules.maxLength),
    comments: Yup.string().max(validationRules.maxLength),
    business_name: Yup.string().max(validationRules.maxLength),
    rfc: Yup.string().max(validationRules.maxLength),
    curp: Yup.string().max(validationRules.maxLength),
    billing_address: Yup.string().max(validationRules.maxLength),
    no_ext: Yup.string().max(validationRules.maxLength),
    no_int: Yup.string().max(validationRules.maxLength),
    billing_phone: Yup.string().max(validationRules.maxLength),
    email: Yup.string()
      .email(t('validation:invalid_email'))
      .max(validationRules.maxLength),
    billing_email: Yup.string()
      .email(t('validation:invalid_email'))
      .max(validationRules.maxLength),
  });

  const { company } = useSelector((state: any) => state.session);
  const { error } = useSelector((state: any) => state.supplier);
  const { processing } = useSelector((state: any) => state.supplier);
  const { refresh } = useSelector((state: any) => state.supplier);
  const { deletingSupplier } = useSelector((state: any) => state.supplier);
  const [image, setImage] = useState(null);
  const [imageKey, setImageKey] = useState(null);

  const _getSuppliers = useCallback(() => {
    dispatch(resetSuppliers());
    dispatch(getSuppliers(company.id, { page: 1, per_page: SCROLL_PER_PAGE }));
    dispatch({
      type: types.CLEAR_REFRESH,
    });
  }, [dispatch, company]);

  const attemptSaveSupplier = async (dataSave) => {
    await dispatch(saveSupplier(dataSave, company.id));
  };

  const attemptDeleteSupplier = async (supplierDelete) => {
    await dispatch(deleteSupplier(supplierDelete, company.id));
  };

  useEffect(() => {
    if (refresh && source === 1) {
      _getSuppliers();
    }
  }, [refresh, _getSuppliers, source]);

  const handleClose = () => {
    setData({});
    dispatch(closeModalSupplier());
  };
  const title = supplier.id == null
    ? t('suppliers:create_supplier')
    : t('suppliers:edit_supplier', { name: supplier.name });

  const methods = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const { handleSubmit } = methods;

  const onSubmit = (dataSubmit) => {
    dataSubmit.id = supplier.id ? supplier.id : null;
    if (dataSubmit.id) {
      dataSubmit.image = image === '' ? '' : supplier.image;
    }

    if (imageKey) {
      dataSubmit.asset = {
        type: 'image',
        key: imageKey,
      };
    }

    attemptSaveSupplier(dataSubmit);
  };

  const actions = (
    <Actions
      handleClose={handleClose}
      handleSubmit={handleSubmit(onSubmit)}
      handleDelete={() => setShowConfirm(true)}
      isNew={supplier.id === null}
      processing={processing}
      deleting={deletingSupplier}
    />
  );

  const { id } = supplier;
  useEffect(() => {
    setImage(null);
  }, [id]);

  return (
    <Modal
      open={showModal}
      onClose={handleClose}
      title={title}
      actions={actions}

    >
      <Box maxWidth="480px">
        <FormProvider {...methods}>
          <SupplierFormView
            setImageKey={setImageKey}
            supplier={data}
            setData={setData}
            showConfirm={showConfirm}
            setShowConfirm={setShowConfirm}
            attemptDeleteSupplier={supplierDelete => attemptDeleteSupplier(supplierDelete)}
            error={error}
            setImage={setImage}
          />
        </FormProvider>
      </Box>
    </Modal>
  );
};
