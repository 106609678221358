export const types = {
  SET_MOVEMENTS_PROCESSING: 'SET_MOVEMENTS_PROCESSING',
  SET_MOVEMENTS_SUCCESS: 'SET_MOVEMENTS_SUCCESS',
  SET_MOVEMENTS_FAILURE: 'SET_MOVEMENTS_FAILURE',
  RESET_MOVEMENTS: 'RESET_MOVEMENTS',
  SET_MOVEMENTS_PARAMS: 'SET_MOVEMENTS_PARAMS',

  SAVE_MOVEMENT_SUCCESS: 'SAVE_MOVEMENT_SUCCESS',
  CLEAR_REFRESH: 'CLEAR_REFRESH',

};
