/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { makeStyles } from '@mui/styles';
import Drawer from '@mui/material/Drawer';
import NavBar from '../ListNavigator';

const drawerWidth = 300;

const styles = makeStyles(theme => ({
  drawerPaper: {
    width: drawerWidth,
    zIndex: 100,
  },
  toolbar: theme.mixins.toolbar,

}));

const Navigator = (props) => {
  const classes = styles();

  return (
    <Drawer
      classes={{ paper: classes.drawerPaper }}
      anchor="left"
      variant={props.variant}
      open={props.open}
      onClose={props.onClose ? props.onClose : null}
    >
      <div className={classes.toolbar} />
      <NavBar />
    </Drawer>
  );
};

export default Navigator;
