/* eslint-disable react/no-unstable-nested-components */
import { Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from '../../../../../components/Dropdown/Dropdown';
import PaginatedTable from '../../../../../components/ui/PaginatedTable';

const STATUSES = [
  {
    id: '123e4523',
    title: 'Pedido en camino',
    description: 'El pedido está en el vehiculo',
    notifications_channels: '[sms]',
    parent_state: 'waiting_for_route',
  },
  {
    id: '123e4582',
    title: 'Pedido en camino',
    description: 'El pedido está en el vehiculo',
    notifications_channels: '',
    parent_state: 'waiting_for_route',
  },
  {
    id: '1263egf7',
    title: 'Pedido en camino',
    description: 'El pedido está en el vehiculo',
    notifications_channels: '[sms]',
    parent_state: 'waiting_for_route',
  },
];

interface DropProps {
  value: string;
}

const NotificationDropdown = ({ value }: DropProps) => {
  const { t } = useTranslation('general');
  const [status, setStatus] = useState(value);
  const onChange = selected => setStatus(selected.identifier);

  return (
    <Dropdown
      fullWidth
      value={status}
      onChange={onChange}
      options={[
        { identifier: '[sms]', label: t('yes'), color: '#e3fde3' },
        { identifier: '', label: t('no'), color: '#e5b2dd' },
      ]}
    />
  );
};

export const OrderStatusesTable = () => {
  const { t } = useTranslation('general');

  const columns = [
    {
      name: 'id',
      label: t('routes.order_status.num_status'),
      options: {
        filter: false,
        sort: false,
        customBodyRender: idStatus => (
          <Typography variant="body2">
            {idStatus}
          </Typography>
        ),
      },
    },
    {
      name: 'title',
      label: t('routes.order_status.status_titule'),
      options: {
        filter: false,
        sort: false,
        customBodyRender: title => (
          <Typography variant="body2">
            {title}
          </Typography>
        ),
      },
    },
    {
      name: 'description',
      label: t('routes.order_status.description_status'),
      options: {
        filter: false,
        sort: false,
        customBodyRender: description => (
          <Typography variant="body2">
            {description}
          </Typography>
        ),
      },
    },
    {
      name: 'notifications_channels',
      label: t('routes.order_status.notifications_channels'),
      options: {
        filter: false,
        sort: false,
        customBodyRender: notChanel => (
          <NotificationDropdown value={notChanel} />
        ),
      },
    },
    {
      name: 'parent_state',
      label: t('routes.order_status.parent_state'),
      options: {
        filter: false,
        sort: false,
        customBodyRender: parentStatus => (
          <Typography variant="body2">
            {parentStatus}
          </Typography>
        ),
      },
    },
  ];

  return (
    <PaginatedTable
      columns={columns}
      title="Lista de vehiculos"
      options={{
        responsive: 'standard',
        pagination: false,
        elevation: 0,
        filter: true,
        sort: true,
        // onRowClick: (_, y) => onRowClick(vehiclesData[y.dataIndex]),
      }}
      data={STATUSES}
    />
  );
};
