import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem, Select,
  InputAdornment,
  FormHelperText,
} from '@mui/material';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import useStoreBranches from '../../../hooks/useBranches';
import Text from '../../Text/Text';

interface Props {
  onChange: ({ id, name }: { id: string, name: string }) => void;
  filterIds?: string[];
  error?: boolean;
  helperText?: string;
  defaultValue?: string;
}

const useStyles = makeStyles(() => ({
  formControl: {
    minWidth: 220,
    width: '100%',
  },
}));

const BranchSelect = ({
  onChange, error, helperText, filterIds = [], defaultValue,
}: Props) => {
  const classes = useStyles();
  const [value, setValue] = useState('');
  const { data, getBranches, loading } = useStoreBranches({ initialLoad: true });

  const handleChange = (event) => {
    const { id, name } = JSON.parse(event.target.value);
    setValue(event.target.value);
    onChange({ id, name });
  };

  useEffect(() => {
    if (defaultValue && !loading) {
      const found = data.find(({ id }) => id === defaultValue);
      setValue(JSON.stringify({ id: found.id, name: found.name }));
    }
  }, [loading, defaultValue]);

  useEffect(() => {
    getBranches();
  }, [getBranches]);

  if (loading) {
    return (
      <FormControl variant="outlined" className={classes.formControl}>
        <CircularProgress size={22} />
      </FormControl>
    );
  }

  const Branchs = filterIds.length > 0 ? data.filter(({ id }) => !filterIds.includes(id)) : data;

  return Branchs.length === 0
    ? <Text htmlColor="gray">No hay sucursales para asignar</Text>
    : (
      <FormControl variant="outlined" className={classes.formControl} error={error}>
        <InputLabel id="Branch-store-select-outlined-label">Sucursal</InputLabel>
        <Select
          variant="standard"
          error={error}
          startAdornment={(
            <InputAdornment position="start">
              <RoomOutlinedIcon htmlColor="gray" />
            </InputAdornment>
          )}
          labelId="Branch-store-select-outlined-label"
          id="Branch-store-select-outlined"
          value={value}
          onChange={handleChange}
          label="Sucursal"
        >
          {
            Branchs.map(({ id, name }) => (
              <MenuItem key={id} value={JSON.stringify({ id, name })}>{name}</MenuItem>
            ))
          }
        </Select>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    );
};

export default BranchSelect;
