/* eslint-disable no-restricted-syntax */
import { types } from './types';
import Log from '../../util/Log';
import AuthorizedApiClient from '../../core/common/api/AuthorizedApiClient';

export const editSupplier = (supplier = null) => ({
  type: types.EDIT_SUPPLIER,
  payload: supplier,
});

export const savingSupplier = () => ({
  type: types.SAVING_SUPPLIER,
});

export const saveSupplierSucceed = response => ({
  type: types.SAVE_SUPPLIER_SUCCESS,
  response,
});

export const saveSupplierFailed = error => ({
  type: types.SAVE_SUPPLIER_FAILURE,
  error,
});

export const deletingSupplier = () => ({
  type: types.DELETING_SUPPLIER,
});

export const deleteSupplierSucceed = response => ({
  type: types.DELETE_SUPPLIER_SUCCESS,
  response,
});

export const deleteSupplierFailed = error => ({
  type: types.DELETE_SUPPLIER_FAILURE,
  error,
});

export const closeModalSupplier = () => ({
  type: types.CLOSE_SUPPLIER_MODAL,
});

export const saveSupplier = (_data, companyId) => async (dispatch: any) => {
  dispatch(savingSupplier());
  const sg = new AuthorizedApiClient();
  let path = `companies/${companyId}/suppliers`;

  try {
    let response;
    const { id } = _data;
    const data = _data;

    if (id != null) {
      path = `${path}/${id}`;
      delete data.id;
      response = await sg.put(data, path);
    } else {
      delete data.id;
      response = await sg.post(data, path);
    }

    if (response != null) {
      Log.debug('saved supplier', response);
      dispatch(saveSupplierSucceed(response));
      dispatch(closeModalSupplier());
    }
  } catch (error) {
    Log.error(error.message);
    dispatch(saveSupplierFailed(error.message));
  }
};

export const deleteSupplier = (supplier, companyId) => async (dispatch: any) => {
  dispatch(deletingSupplier());
  const sg = new AuthorizedApiClient();
  let path = `companies/${companyId}/suppliers`;
  try {
    if (!supplier.id) {
      throw new Error('The id is required');
    }

    path = `${path}/${supplier.id}`;
    const response = await sg.delete(path);

    if (response != null) {
      Log.debug('deleted supplier', response);
      dispatch(deleteSupplierSucceed(response));
      dispatch(closeModalSupplier());
    }
  } catch (error) {
    Log.error(error.message);
    dispatch(deleteSupplierFailed(error.message));
  }
};
