/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/prop-types */
import {
  Box, Button, CircularProgress, Grid, Typography,
} from '@mui/material';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { updateProducts } from '../../../../../actions/productsCost';
import { getSearchProducts, resetSearchProducts, setSearchName } from '../../../../../actions/search';
import ActivityIndicator from '../../../../../components/ui/ActivityIndicator';
import InputSearch from '../../../../../components/ui/InputSearch';
import Modal from '../../../../../components/ui/Modals/Modal';
import { SCROLL_PER_PAGE } from '../../../../../constants/common';
import PaginatedScroll from '../../../../../hoc/PaginatedScroll';
import images from '../../../../../theme/ECTheme/assets/images';
import AdminCostItem from './AdminCostItem';

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: '42%',
    maxWidth: 630,
    minWidth: 500,
    height: 600,
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 5,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: 'auto',
    [theme.breakpoints.down('lg')]: {
      width: '75%',
      minWidth: 270,
      height: 550,
    },
  },

  containerSearch: {
    display: 'flex',
    alignItems: 'center',
    margin: '15px 5px',
  },
  barcode: {
    display: 'block',
    width: 25,
    height: 25,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundImage: `url(${images.Icon_Code})`,
  },

  itemsTitle: {
    display: 'grid',
    gridTemplateColumns: '1fr 2fr 1fr 1fr 1fr 1fr',
    alignContent: 'center',
    [theme.breakpoints.down('lg')]: {
      '& span': {
        fontSize: 10,
      },
    },
  },
}));

export default ({ open, setOpen }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { company } = useSelector((state: any) => state.session);
  const productsCost = useSelector((state: any) => state.productsCost);
  const search = useSelector((state: any) => state.search);

  const handleClose = () => {
    setOpen(false);
  };

  const updateData = async () => {
    const dataupdate = {
      data: productsCost.data,
    };
    await dispatch(updateProducts(company.id, dataupdate));
    await handleClose();
  };

  const HandleSetSearch = (value) => {
    dispatch(setSearchName(value));
  };

  const _getProducts = useCallback(async (params: any = {}) => {
    params.per_page = SCROLL_PER_PAGE;
    params.embed = 'category,brand,suppliers';
    await dispatch(getSearchProducts(company.id, params));
  }, [company.id, dispatch]);

  const _resetProducts = useCallback(() => {
    dispatch(resetSearchProducts());
  }, [dispatch]);

  const titles = (
    <Grid className={classes.itemsTitle}>
      <Grid />
      <Grid />
      <Typography variant="caption" align="center" style={{ alignSelf: 'center', lineHeight: 1 }}>
        { t('general:cost') }
      </Typography>
      <Typography variant="caption" align="center" style={{ alignSelf: 'center', lineHeight: 1 }}>
        { t('general:earnings') }
      </Typography>
      <Typography variant="caption" align="center" style={{ alignSelf: 'center', lineHeight: 1 }}>
        { t('general:price_public') }
      </Typography>
      <Typography variant="caption" align="center" style={{ alignSelf: 'center', lineHeight: 1 }}>
        { t('general:utility') }
      </Typography>
    </Grid>
  );

  const action = (
    <Grid style={{ width: '100%', margin: '15px 0px', textAlign: 'end' }}>
      <Button onClick={updateData} variant="contained" color={search.loading || productsCost.load ? 'inherit' : 'primary'} style={{ width: 140, height: 30 }}>
        {productsCost.load ? <CircularProgress size={25} /> : t('general:ready') }
      </Button>
    </Grid>
  );

  const header = (
    <Grid>
      <Typography variant="h5" align="center">
        { t('general:admin_cost_products') }
      </Typography>
      <Grid className={classes.containerSearch}>
        <InputSearch
          setSearchValue={HandleSetSearch}
          placeholder={t('general:search')}
        />
        <div style={{ flexGrow: 1, paddingLeft: 15 }}>
          <span className={classes.barcode} />
        </div>
      </Grid>

      <Grid>
        { titles }
      </Grid>
    </Grid>
  );

  const body = (
    <Box>
      <Grid style={{
        height: '58%', width: '100%', maxHeight: 500, padding: '5px 0px 5px 0px',
      }}
      >

        <PaginatedScroll
          hasNextPage={search.response.page < search.response.last_page}
          loadData={_getProducts}
          currentPage={search.response.page}
          resetData={_resetProducts}
          processing={search.loading}
          filters={search.filters}
        >

          {
            productsCost.load ? <ActivityIndicator /> : search.data.map(product => (
              <AdminCostItem data={product} key={product.id} />
            ))
          }

        </PaginatedScroll>

      </Grid>
    </Box>
  );

  return (
    <Modal
      open={open}
      onClose={handleClose}
      disableEnforceFocus
      disableAutoFocus
      title={header}
      actions={action}
    >
      { body }
    </Modal>
  );
};
