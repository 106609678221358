/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/prop-types */
import React, { useCallback, useState } from 'react';
import RoomIcon from '@mui/icons-material/Room';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import TextField from '../../../../../components/ui/TextField';
import Box from '../../../../../components/ui/Layout/Box';
import Modal from '../../../../../components/ui/Modals/Modal';
import LocationPicker from './LocationPicker';
import Actions from './Actions';
import { DEFAULT_COORDINATES } from '../../../../../constants/common';

const useStyles = makeStyles(() => ({
  marker: {
    cursor: 'pointer',
    color: 'black !important',
  },
}));

const AddressInput = ({
  register,
  maxlength = 255,
  label = '',
  defaultValue = '',
  defaultCoordinates = null,
  error,
  setValueForm,
}) => {
  const { t } = useTranslation(['general']);
  const classes = useStyles();
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState({
    address: defaultValue,
    lat: defaultCoordinates ? defaultCoordinates[1] : DEFAULT_COORDINATES.lat,
    lng: defaultCoordinates ? defaultCoordinates[0] : DEFAULT_COORDINATES.lng,
  });
  // const [address, setAddress] = useState(defaultValue);
  const [lat, setLat] = useState(data.lat);
  const [lng, setLng] = useState(data.lng);
  const handleMarkerClick = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleConfirm = () => {
    setValueForm('address', data.address, {
      shouldValidate: true,
      shouldDirty: true,
    });
    setLng(data.lng);
    setLat(data.lat);
    setShowModal(false);
  };

  const actions = <Actions handleSubmit={handleConfirm} />;
  const handleLocationChanged = useCallback((data) => {
    setData(data);
  }, []);

  return (
    <Box m={0} t={0}>
      <TextField
        onClick={handleMarkerClick}
        register={register}
        maxLength={maxlength}
        defaultValue={label}
        // label={label}
        error={Boolean(error)}
        helperText={error !== undefined ? error.message : null}
        InputProps={{
          shrink: true,
          endAdornment: (
            <Box onClick={handleMarkerClick} className={classes.marker}>
              <RoomIcon />
            </Box>
          ),
        }}
      />
      <Modal
        open={showModal}
        onClose={handleClose}
        title={t('general:location')}
        actions={actions}
      >
        <Box minWidth="320px">
          <LocationPicker
            onLocationChanged={handleLocationChanged}
            defaultAddress={defaultValue}
            defaultCoordinates={{
              lat,
              lng,
            }}
          />
        </Box>
      </Modal>

      <input type="hidden" value={lat || ''} {...register} />
      <input type="hidden" value={lng || ''} {...register} />
    </Box>
  );
};

export default AddressInput;
