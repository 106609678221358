/* eslint-disable prefer-const */
import { types } from './types';
import Log from '../../util/Log';
import AuthorizedApiClient from '../../core/common/api/AuthorizedApiClient';

export const editUser = (user = null) => ({
  type: types.EDIT_USER,
  payload: user,
});

export const savingUser = () => ({
  type: types.SAVING_USER,
});

export const saveUserSucceed = response => ({
  type: types.SAVE_USER_SUCCESS,
  response,
});

export const saveUserFailed = error => ({
  type: types.SAVE_USER_FAILURE,
  error,
});

export const closeModalUser = () => ({
  type: types.CLOSE_USER_MODAL,
});

export const saveUser = (_data, companyId) => async (dispatch: any) => {
  dispatch(savingUser());
  const sg = new AuthorizedApiClient();
  let path = `companies/${companyId}/users`;

  try {
    let response;
    let data;
    const { id } = _data;

    data = _data;

    if (id != null) {
      path = `${path}/${id}`;
      delete data.id;
      response = await sg.put(data, path);
    } else {
      delete data.id;
      response = await sg.post(data, path);
    }

    if (response != null) {
      Log.debug('saved user', response);
      dispatch(saveUserSucceed(response));
      dispatch(closeModalUser());
    }
  } catch (error) {
    Log.error(error.message);
    dispatch(saveUserFailed(error.response.data));
  }
};
