import { Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import Box from '../ui/Layout/Box';
import { ProductionVolume } from '../../constants/common';
import CheckOption from './CheckOption';
import { setProductionVolume, setWizardStep } from '../../actions/wizard';
import { WizardSteps } from './steps';
import SuccessButton from '../ui/Buttons/SuccessButton';

const useStyle = makeStyles(() => ({
  questionsContainer: {
    width: '70%',
    marginLeft: 'auto',
    marginRight: 'auto',
    minWidth: '220px',
  },
}));

const ProductionVolumeSection = () => {
  const classes = useStyle();
  const { t } = useTranslation(['general']);
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(null);

  const handleSelected = (value) => {
    setSelected(value);
  };

  const handleSubmit = () => {
    dispatch(setProductionVolume(selected));
    dispatch(setWizardStep(WizardSteps.CUSTOMER_TYPES));
  };

  const options = Object.values(ProductionVolume).map(key => (
    <Box key={key} mb={1}>
      <CheckOption
        label={t(`general:production_volume:name:${key}`)}
        handleOnCLick={() => handleSelected(key)}
        selected={selected === key}
        complement={t(`general:production_volume:desc:${key}`)}
      />
    </Box>
  ));

  return (
    <>

      <Box>
        <Typography variant="h6" align="center" color="textSecondary">
          {t('general:wizard_setup:production_volume_question')}
        </Typography>
      </Box>

      <Box mt={2} className={classes.questionsContainer} pt={5}>
        {options}
      </Box>

      <Box mt={2} display="flex" justifyContent="center">
        <SuccessButton
          text={t('general:next')}
          onClick={handleSubmit}
          disabled={selected === null}
        />
      </Box>
    </>
  );
};

export default ProductionVolumeSection;
