/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModifierGroupFormView from './ModifierGroupFormView';
import {
  saveModifierGroup,
  deleteModifierGroup,
  closeModalModifierGroup,
  setModifiers,
  setAssociatedProducts,
  getModifierGroup,
} from '../../../../../actions/modifier_group';
import { useSession } from '../../../../../util/session';

export default ({ refreshList }) => {
  const dispatch = useDispatch();
  const { company } = useSession();
  const [associateError, setAssociateError] = useState({
    modifiers: false,
    variants: false,
  });
  const {
    modifierGroup,
    error,
    processing,
    refresh,
    deletingModifierGroup,
    modifiers,
    associated_products,
  } = useSelector((state: any) => state.modifier_group);

  const { id } = modifierGroup;

  const attemptSaveModifierGroup = async (data) => {
    data.modifiers = modifiers.map(m => ({
      variant_id: m.variants[0].id,

      price: null,
      max_permitted: m.max_permitted ? parseFloat(m.max_permitted) : null,
    }));
    data.variants = associated_products.map(p => p.variants[0].id);

    if (data.modifiers.length < 1 || data.variants.length < 1) {
      setAssociateError({
        modifiers: (data.modifiers.length < 1),
        variants: (data.variants.length < 1),
      });
    } else {
      await dispatch(saveModifierGroup(data, company.id));
    }
  };

  const attemptDeleteModifierGroup = async (modifieGroup) => {
    await dispatch(deleteModifierGroup(modifieGroup, company.id));
  };

  const handleModifiers = useCallback(
    (modifiers) => {
      dispatch(setModifiers(modifiers));
    },
    [dispatch],
  );

  const handleAssociatedProducts = useCallback(
    (products) => {
      dispatch(setAssociatedProducts(products));
    },
    [dispatch],
  );

  const _getModifierGroup = useCallback((id) => {
    dispatch(getModifierGroup(id, company.id));
    // dispatch({
    //   type: types.CLEAR_REFRESH,
    // });
    // setTab(1);
  }, [dispatch, company]);

  useEffect(() => {
    if (refresh && refreshList) {
      refreshList();
    }
  }, [refresh, refreshList]);

  useEffect(() => {
    if (id) {
      _getModifierGroup(id);
    }
  }, [id, _getModifierGroup]);

  useEffect(() => {
    if (associateError.modifiers || associateError.variants) {
      setAssociateError({
        modifiers: (modifiers.length < 1),
        variants: (associated_products.length < 1),
      });
    }
  }, [associated_products, modifiers]);

  return (
    <ModifierGroupFormView
      modifierGroup={modifierGroup}
      attemptSaveModifierGroup={data => attemptSaveModifierGroup(data)}
      attemptDeleteModifierGroup={modifier_group => attemptDeleteModifierGroup(modifier_group)}
      processing={processing}
      deletingModifierGroup={deletingModifierGroup}
      error={error}
      closeModalModifierGroup={closeModalModifierGroup}
      handleModifiers={handleModifiers}
      handleAssociatedProducts={handleAssociatedProducts}
      associateError={associateError}
    />
  );
};
