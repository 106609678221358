/* eslint-disable react/prop-types */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useCallback, useEffect, useState } from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';
import Geocode from 'react-geocode';
import _ from 'lodash';
import TextField from '../../../../../components/ui/TextField';
import Box from '../../../../../components/ui/Layout/Box';
import {
  DEFAULT_CITY,
  DEFAULT_COUNTRY,
  GEO_API_KEY,
} from '../../../../../constants/api/ApiConstants';
import { DEFAULT_COORDINATES } from '../../../../../constants/common';

const containerStyle = {
  width: '100%',
  height: '400px',
};

Geocode.setApiKey(GEO_API_KEY);

const LocationPicker = ({
  onLocationChanged,
  country = DEFAULT_COUNTRY,
  city = DEFAULT_CITY,
  zoom = 15,
  defaultAddress = '',
  defaultCoordinates = DEFAULT_COORDINATES,
}) => {
  const [address, setAddres] = useState(defaultAddress);
  const [coordinates, setCoordinates] = useState(defaultCoordinates);

  const getLocationFromAddress = useCallback(async (addressLocation) => {
    if (!addressLocation) return null;
    try {
      const response = await Geocode.fromAddress(
        `${addressLocation}, ${city}, ${country}`,
      );
      return response.results[0].geometry.location;
    } catch (error) {
      console.error(error);
      return null;
    }
  }, [city, country]);

  const getAddress = useCallback(async (latitude, longitude) => {
    if (!latitude || !longitude) return null;
    try {
      const response = await Geocode.fromLatLng(latitude, longitude);
      return response.results[0].formatted_address;
    } catch (error) {
      console.error(error);
      return null;
    }
  }, []);

  const handleAddressChange = useCallback(
    async (addressLocation) => {
      const location = await getLocationFromAddress(addressLocation);

      if (location) {
        setCoordinates(location);
      }
    },
    [getLocationFromAddress],
  );

  const delayedSetState = useCallback(
    _.debounce(q => handleAddressChange(q), 500),
    [],
  );

  const handleAddresChanche = (e) => {
    setAddres(e.target.value);
    delayedSetState(e.target.value ? e.target.value : '');
  };

  const onMarkerChanged = async (e) => {
    const fullAddress = await getAddress(e.latLng.lat(), e.latLng.lng());
    if (fullAddress) {
      setCoordinates({ lat: e.latLng.lat(), lng: e.latLng.lng() });
      setAddres(fullAddress);
    }
  };

  useEffect(() => {
    onLocationChanged({
      address,
      ...coordinates,
    });
  }, [coordinates, address, onLocationChanged]);

  return (
    <Box>
      <Box mb={3}>
        <TextField
          maxLength={255}
          label="Dirección"
          value={address || ''}
          onChange={handleAddresChanche}
        />
      </Box>

      <Box>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={coordinates}
          zoom={zoom}
          options={{
            clickableIcons: false,
            disableDefaultUI: true,
          }}
        >
          <Marker
            position={coordinates}
            draggable
            onDragEnd={onMarkerChanged}
          />
        </GoogleMap>
      </Box>
    </Box>
  );
};

export default React.memo(LocationPicker);
