import { SET_DASHBOARD_START_DATE, SET_DASHBOARD_END_DATE } from '../constants';

const salesListReducer = (state: any, action: any) => {
  switch (action.type) {
    case SET_DASHBOARD_START_DATE:
      return { ...state, start_date: action.payload };

    case SET_DASHBOARD_END_DATE:
      return { ...state, end_date: action.payload };

    default:
      return state;
  }
};

export default salesListReducer;
