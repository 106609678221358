/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/button-has-type */
import { Box, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import CheckIcon from '@mui/icons-material/Check';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { green } from '@mui/material/colors';
import Axios from 'axios';
import { useTranslation } from 'react-i18next';

import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import PlanSubsContext from '../context/PlanSubsContext';
import { BASE_URL } from '../../../../constants/api/ApiConstants';
import images from '../../../../theme/ECTheme/assets/images';
import { AnnualPlan } from '../../../AnnualPlan';

const useStyle = makeStyles(() => ({
  icon: {
    color: green[500],
    margin: 3,
  },
  feature: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 5,
  },
  characteristic: {
    fontWeight: 'bold',
    fontSize: 14,
    margin: 5,
    minWidth: 220,
  },
  planSelector: {
    borderStyle: 'solid',
    borderColor: 'white',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    width: 120,
    height: 350,
    alignSelf: 'flex-end',
    backgroundColor: '#FFF',
    borderRadius: 5,
    borderWidth: 5,
    margin: 5,
    '&:hover': {
      cursor: 'pointer',
      borderColor: '#D7B5FF',
    },
    '&:focus': {
      borderColor: '#D7B5FF',
    },
  },
  table: {
    minWidth: 650,
  },
  cell: {
    borderColor: '#fff',
  },
  descriptionPlan: {
    minWidth: 100,
    height: 70,
    paddingTop: 10,
    color: '#FFF',
    fontWeight: 'bold',
    fontSize: 18,
  },
  btDisabled: {
    backgroundColor: '#ebdcfc',
    borderRadius: 20,
    '&:hover': {
      cursor: 'pointer',
      borderColor: '#D7B5FF',
    },
  },
  btPurple: {
    backgroundColor: '#8821EA',
    transitionDelay: '80ms',
    color: 'white !important',
    borderRadius: 20,
    '&:hover': {
      cursor: 'pointer',
      borderColor: '#D7B5FF',
    },
  },
}));

// TODO: move it
const MONTHLY_INTERVAL = 'month';
const getDefaultMonthlyPrice = plan => (
  plan.prices.find(price => price.interval === MONTHLY_INTERVAL && price.default === true)
);

const getConvertedDefaultPriceAmount = (plan) => {
  const defaultMonthlyPrice = getDefaultMonthlyPrice(plan);
  // TODO: validate not null;
  return defaultMonthlyPrice.amount / 100;
};

const getDefaultPriceId = (plan) => {
  const defaultMonthlyPrice = getDefaultMonthlyPrice(plan);

  // TODO: validate not null;
  return defaultMonthlyPrice.id;
};

export const PlansInfo = () => {
  const classes = useStyle();
  const [planData, setPlanData] = useState([]);
  const { t } = useTranslation(['general']);
  const { setPricePlan, setPlanId } = useContext(PlanSubsContext);
  const [selectMenu, setSelectMenu] = useState(false);

  const MenuSelector = () => {
    setSelectMenu(!selectMenu);
  };

  const getSubsInfo = useCallback(() => {
    Axios.get(`${BASE_URL}/api/v1/plans`)
      .then((res) => {
        setPlanData(res.data.data);
      });
  }, []);

  useEffect(() => {
    getSubsInfo();
  }, [getSubsInfo]);

  return (
    <>
      <h2 style={{ color: 'black' }}>Selecciona el plan ideal para ti</h2>
      <Box style={{
        display: 'flex', flexDirection: 'column', marginBottom: 20, minWidth: 500,
      }}
      >
        <Box className={classes.feature}>
          <CheckIcon className={classes.icon} />
          <span>Digitaliza tu negocio de manera rapida y sencilla.</span>
        </Box>
        <Box className={classes.feature}>
          <CheckIcon className={classes.icon} />
          <span>Cuenta con tu tienda online y compartela a tus clientes.</span>
        </Box>
        <Box className={classes.feature}>
          <CheckIcon className={classes.icon} />
          <span>Puedes cambiar de plan o cancelar cuando quieras.</span>
        </Box>
      </Box>
      <div>
        <div style={{ display: 'flex', flexDirection: 'row-reverse', alignItems: 'center' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div>
              <span style={{ color: 'red', fontSize: 12, alignItems: 'center' }}>
                <LocalOfferIcon style={{ fontSize: 14 }} />
                50% de desucneto
              </span>

            </div>
            <div style={{ display: 'flex', backgroundColor: '#ebdcfc', borderRadius: 20 }}>
              <Button
                style={{ marginRight: 8 }}
                disabled={!!selectMenu}
                className={selectMenu ? classes.btPurple : classes.btDisabled}
                onClick={MenuSelector}
              >
                {' '}
                Plan Anual
              </Button>
              <Button
                disabled={selectMenu === false}
                className={selectMenu ? classes.btDisabled : classes.btPurple}
                onClick={MenuSelector}
              >
                Plan Mensual
              </Button>
            </div>

          </div>

        </div>

        {
          selectMenu
            ? <AnnualPlan planData={planData} />
            : (
              <TableContainer>
                <Table
                  className={classes.table}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ justifyContent: 'center' }} className={classes.cell}>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                          <img
                            src={images.Icon_EcubiMiNegocio}
                            alt="ecubi_logo"
                            style={{ width: 75, marginBottom: 15 }}
                          />
                        </div>
                        <div className={classes.characteristic}>
                          Tienda online personalizada
                        </div>
                        <div className={classes.characteristic}>
                          Comision por venta
                        </div>
                        <div className={classes.characteristic}>
                          Aparecer como tienda &quot;Verificada&quot;
                        </div>
                        <div className={classes.characteristic}>
                          Subdominio personalizado
                        </div>
                        <div className={classes.characteristic}>
                          Agregar más de un usuario
                        </div>
                        <div className={classes.characteristic}>
                          Pago con tarjeta
                        </div>
                        <div className={classes.characteristic}>
                          Pago en efectivo
                        </div>
                      </TableCell>

                      {planData.map(info => (
                        <TableCell className={classes.cell}>
                          <button
                            onClick={() => {
                              // setPlanId(planData[index].id);
                              // setPricePlan(planData[index].prices[0].id);
                              setPlanId(info.id);
                              setPricePlan(getDefaultPriceId(info));
                            }}
                            className={classes.planSelector}
                          >
                            {info.code === 'free'
                              ? (
                                <div
                                  className={classes.descriptionPlan}
                                  style={{
                                    backgroundColor: '#E5D7FB',
                                    color: '#000',
                                  }}
                                >
                                  {t('general:basic')}
                                  <div>
                                    {info.prices[0].amount === 0
                                  && (
                                    <p
                                      style={{
                                        fontWeight: 'normal',
                                        fontSize: 14,
                                        marginTop: 10,

                                      }}
                                    >
                                      {' '}
                                      Gratuito
                                    </p>
                                  )}
                                  </div>
                                </div>
                              )
                              : info.code === 'standard' ? (
                                <div
                                  className={classes.descriptionPlan}
                                  style={{ backgroundColor: '#8821EA' }}
                                >
                                  {t('general:standard')}
                                  <div>
                                    <p
                                      style={{
                                        fontWeight: 'normal',
                                        fontSize: 14,
                                        marginTop: 10,
                                      }}
                                    >
                                      $
                                      {/* {info.prices[0].amount / 100} */}
                                      {getConvertedDefaultPriceAmount(info)}
                                    </p>
                                  </div>
                                </div>

                              ) : info.code === 'pro' ? (
                                <div
                                  className={classes.descriptionPlan}
                                  style={{ backgroundColor: '#8821EA' }}
                                >
                                  <span>PRO</span>
                                  <div>
                                    {/* {info.prices[1].amount === 7900 && ( */}
                                    <div>
                                      <p
                                        style={{
                                          fontWeight: 'lighter',
                                          fontSize: 12,
                                          margin: 5,
                                          textDecoration: 'line-through',
                                        }}
                                      >
                                        $130
                                      </p>
                                      <p
                                        style={{
                                          fontWeight: 'bold',
                                          fontSize: 14,
                                          margin: 5,
                                        }}
                                      >
                                        $
                                        {/* {info.prices[1].amount / 100} */}
                                        {getConvertedDefaultPriceAmount(info)}
                                      </p>
                                    </div>
                                    {/* )} */}
                                  </div>
                                </div>
                              )

                                : null}

                            {/* <----Tienda online personalizada----> */}

                            <CheckIcon className={classes.icon} style={{ marginTop: 30 }} />

                            {/* <----Comision por venta----> */}
                            <p style={{ fontWeight: 'bold', margin: 8 }}>
                              {' '}
                              {info.features.orders_fee * 100}
                              %
                            </p>
                            {/* </Box> */}

                            {/* <----Aparecer como tienda verificada----> */}
                            <span>
                              {info.code === 'free' ? (
                                <CloseRoundedIcon
                                  color="error"
                                  style={{ margin: 3 }}
                                />
                              ) : (
                                <CheckIcon className={classes.icon} />
                              )}
                            </span>

                            {/* <----Subdominio Personalizado----> */}
                            <span>
                              {info.features.custom_subdomain === true ? (
                                <span>
                                  <CheckIcon className={classes.icon} />
                                </span>
                              ) : (
                                <span>
                                  <CloseRoundedIcon
                                    color="error"
                                    style={{ margin: 3 }}
                                  />
                                </span>
                              )}
                            </span>

                            {/* <----Tienda online personalizada----> */}

                            {/* <----Métodos de pago con tarjeta----> */}
                            <span>
                              {info.features.payment_methods[0] === 'card' ? (
                                <span>
                                  <CheckIcon className={classes.icon} />
                                </span>
                              ) : (
                                <span>
                                  <CloseRoundedIcon
                                    color="error"
                                    style={{ margin: 3 }}
                                  />
                                </span>
                              )}
                            </span>

                            {/* <----Métodos de pago con efectivo----> */}
                            <span>
                              {info.features.payment_methods[1] ? (
                                <span>
                                  <CheckIcon className={classes.icon} />
                                </span>
                              ) : (
                                <span>
                                  <CloseRoundedIcon
                                    color="error"
                                    style={{ margin: 3 }}
                                  />
                                </span>
                              )}
                            </span>
                          </button>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                </Table>
              </TableContainer>
            )
        }
      </div>

    </>
  );
};
