/* eslint-disable react/prop-types */
import React from 'react';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { makeStyles } from '@mui/styles';
import Box from '../../../../../../../../components/ui/Layout/Box';
import ImagePreview from './ImagePreview';
import VideoPreview from './VideoPreview';
import { AssetType } from '../constants';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    border: '1px solid #A174CB',
  },
  delete: {
    position: 'absolute',
    right: '-11px',
    top: '-11px',
    cursor: 'pointer',
    color: '#A174CB',
    zIndex: 99999,
    // backgroundColor:"white"
  },
}));

const Asset = ({ asset, handleDelete }) => {
  const classes = useStyles();

  return (
    <Box
      height={asset.resource.toString() === AssetType.VIDEO ? '30vh' : '15vh'}
      display="flex"
      alignItems="center"
      justifyContent="center"
      className={classes.root}

    >
      {asset.success && (
        <Box>
          <HighlightOffIcon
            className={classes.delete}
            onClick={() => handleDelete(asset)}
          />
        </Box>
      )}
      {asset.resource.toString() === AssetType.IMAGE.toString() ? (
        <ImagePreview asset={asset} />
      ) : (
        <VideoPreview asset={asset} />
      )}
    </Box>
  );
};

export default Asset;
