import { types } from '../../actions/location/types';

export const initialState = {
  location: { id: null, name: '', description: '' },
  error: null,
  showModal: false,
  processing: false,
  deletingLocation: false,
  refresh: false,
  branches: [],
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case types.EDIT_LOCATION:
      return {
        ...state,
        showModal: true,
        location:
          action.payload !== null ? action.payload : initialState.location,
      };
    case types.CLOSE_LOCATION_MODAL:
      return initialState;
    case types.SAVE_LOCATION_SUCCESS:
      return {
        ...state,
        response: action.response,
        error: null,
        processing: false,
        refresh: true,
      };
    case types.SAVE_LOCATION_FAILURE:
      return {
        ...state,
        error: action.error,
        processing: false,
      };
    case types.SAVING_LOCATION:
      return {
        ...state,
        error: null,
        processing: true,
      };

    case types.DELETING_LOCATION:
      return {
        ...state,
        error: null,
        deletingLocation: true,
      };

    case types.DELETE_LOCATION_SUCCESS:
      return {
        ...state,
        error: null,
        deletingLocation: false,
        refresh: true,
      };
    case types.DELETE_LOCATION_FAILURE:
      return {
        ...state,
        error: action.error,
        deletingLocation: false,
      };
    case types.CLEAR_REFRESH:
      return {
        ...state,
        refresh: false,
      };

    case types.SET_BRANCHES_LOCATION_SUCCESS:
      return {
        ...state,
        branches: action.response.data,
      };
    case types.SET_BRANCHES_LOCATION_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
};
