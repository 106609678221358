/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import { FormHelperText, OutlinedInput } from '@mui/material';
import styles from './PhoneField.module.css';

const handleChange = (event) => {
  const keyCode = event.keyCode || event.which;
  const valid = [8, 9, 46, 37, 39];
  if (!((keyCode >= 48 && keyCode <= 57) || valid.includes(keyCode))) {
    event.preventDefault();
  }
};
const PhoneField = props => (
  <FormControl
    fullWidth
    variant="filled"
    error={!!props.error}
  >
    <InputLabel htmlFor="filled-adornment-amount">{props.label}</InputLabel>
    <OutlinedInput
      {...props}
      {...props.register}
      startAdornment={<InputAdornment position="start">52</InputAdornment>}
      className={styles.PhoneField}
      inputProps={{ maxLength: 10 }}
      onKeyDown={e => handleChange(e)}
    />
    {props.error && <FormHelperText>{props.error.message}</FormHelperText>}
  </FormControl>
);

export default PhoneField;
