/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  Box, Button, Grid, Hidden, Modal, Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import DiscountTicket from '../../../theme/ECTheme/assets/images/DiscountTicket.svg';
import Add from '../../../theme/ECTheme/assets/images/Add.svg';
import Collaborative from '../../../theme/ECTheme/assets/images/Collaborative.svg';
import multiple_users from '../../../theme/ECTheme/assets/images/multiple_users.svg';
import custom_domain from '../../../theme/ECTheme/assets/images/custom_domain.svg';
import Transaction from '../../../theme/ECTheme/assets/images/Transaction.svg';
import ubications from '../../../theme/ECTheme/assets/images/ubications.svg';
import inventoryDark from '../../../theme/ECTheme/assets/images/inventoryDark.svg';
import { useSession } from '../../../util/session';
import ModalProPayment from '../../ModalProPayment';
import { closeTrialModal, openTrialModal } from '../../../actions/notification';

const useStyles = makeStyles(theme => ({

  paper: {
    position: 'absolute',
    width: '100%',
    maxWidth: 520,
    height: 690,
    // maxHeight: 690,
    // minHeight: '15rem',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #878787',
    borderRadius: 5,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  centeredItems: {
    margin: 10,
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  mainText: {
    color: 'black',
    padding: 11,
  },
  icon: {
    width: 35,
    marginBlock: 10,
  },
  root: {
    width: '100%',
  },
  selectButton: {
    backgroundColor: '#8821EA',
    width: '33%',
    color: '#FFF',
    margin: 5,
    // padding: 6,
    fontSize: 15,
    border: 'none',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: 2,
  },
}));

export const PremiumTrial = () => {
  const dispatch = useDispatch();
  const { trialModal } = useSelector((state: any) => state.notification);
  const classes = useStyles();
  const { planning } = useSession();
  const [openPayment, setOpenPayment] = useState(false);
  const [modalStyle] = React.useState(getModalStyle);
  const plan = planning?.subscriptions[0]?.plan_id || '';

  useEffect(() => {
    const trialWasOpen = Boolean(localStorage.getItem('premiumTrialOpen'));

    if (
      !trialWasOpen
      && planning
      && planning.trial_redeemed === false
      && planning.offer_free_pro === false
      && plan !== '614a50847893331348600551'
    ) {
      dispatch(openTrialModal());

      localStorage.setItem('premiumTrialOpen', JSON.stringify(true));
    }
  }, []);

  function getModalStyle() {
    return {
      top: `${50}%`,
      left: `${50}%`,
      transform: `translate(-${50}%, -${50}%)`,
    };
  }

  const handleClose = () => {
    dispatch(closeTrialModal());
  };

  const body = (
    <Box style={modalStyle} className={classes.paper}>
      <Grid className={classes.root}>
        <Typography variant="h6" className={classes.centeredItems} style={{ color: 'black', fontWeight: 'bold', marginTop: 20 }}>
          Plan Ecubi Pro 14 días de prueba
        </Typography>
        <Typography className={classes.centeredItems} style={{ color: 'black', fontWeight: 'bold', marginTop: -12 }}>
          Después, solo por $ 130.00 al mes
        </Typography>
        <Hidden mdDown>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img src={DiscountTicket} alt="discount" style={{ width: 60 }} />
          </div>
        </Hidden>

        <div style={{ display: 'flex', marginBlock: 40 }}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <img src={Transaction} alt="transaction" className={classes.icon} style={{ width: 30 }} />
            <img src={custom_domain} alt="custom-domain" className={classes.icon} style={{ width: 50 }} />
            <img src={ubications} alt="ubications" className={classes.icon} />
            <img src={Add} alt="add" className={classes.icon} />
            <img src={Collaborative} alt="collaborative" className={classes.icon} style={{ marginTop: 12 }} />
            <img src={inventoryDark} alt="inventoryDark" className={classes.icon} style={{ width: 30 }} />
            <img src={multiple_users} alt="multiple-users" className={classes.icon} style={{ width: 40 }} />
          </div>

          <div>
            <Typography variant="subtitle1" className={classes.mainText}>
              0% de Comisión por venta.
            </Typography>
            <Typography variant="subtitle1" className={classes.mainText}>
              Personaliza el subdominio de tu negocio.
            </Typography>
            <Typography variant="subtitle1" className={classes.mainText}>
              Gestiona tus zonas y costos de entrega.
            </Typography>
            <Typography variant="subtitle1" className={classes.mainText}>
              Dale opciones a tus clientes con grupos modificadores.
            </Typography>
            <Typography variant="subtitle1" style={{ lineHeight: 1, marginTop: 5 }} className={classes.mainText}>
              Compras colaborativas: Publica cupones, descuentos o un lote
              de tus productos y venderlas a un grupo de clientes.
            </Typography>
            <Typography variant="subtitle1" style={{ lineHeight: 1 }} className={classes.mainText}>
              Controla tu inventario de productos y configura que te notifique
              cuando requieres pedir más a tu proveedor.
            </Typography>
            <Typography variant="subtitle1" style={{ lineHeight: 1 }} className={classes.mainText}>
              Crea múltiples usuarios, gestiona sus roles y monitorea tu negocio.
            </Typography>
          </div>
        </div>
        <div style={{
          display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: -13,
        }}
        >
          <Button className={classes.selectButton} onClick={() => setOpenPayment(!openPayment)}>
            Prueba 14 días gratis
          </Button>
          <Button color="primary" onClick={handleClose}>Ahora no</Button>
          <ModalProPayment open={openPayment} setOpen={setOpenPayment} />
        </div>
      </Grid>
    </Box>
  );

  return (
    <Modal
      open={trialModal}
      onClose={handleClose}
      disableEnforceFocus
      disableAutoFocus
    >
      {body}
    </Modal>
  );
};
