/* eslint-disable react/no-unstable-nested-components */
import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { Button, Divider, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { format } from 'date-fns';
import toast from 'react-hot-toast';
import Box from '../../../../../components/ui/Layout/Box';
import Modal from '../../../../../components/ui/Modals/Modal';
import { OrdersFileExcel } from './OrdersFileExcel';
import PaginatedTable from '../../../../../components/ui/PaginatedTable';
import useRequest from '../../../../../hooks/useRequest';
import useCompany from '../../../../../hooks/useCompany';

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up('md')]: {
      width: '750px',
      maxWidth: '100%',
    },
  },
}));

interface Props {
  isOpen: Boolean;
  handleClose(): void;
}

const formatCurrency = new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' });

export const OrdersExcelModal = ({ isOpen, handleClose }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation('general');
  const { company } = useCompany();
  const orderRequest = useRequest({ auth: true, apiVerison: 'v1.1' });
  const [orders, setOrders] = useState(null);

  const onClose = () => {
    handleClose();
    setOrders(null);
  };

  const handleSubmit = () => {
    const loadingToast = toast.loading('Waiting...');
    orderRequest.post(`businesses/${company.id}/orders/upload`, { orders })
      .then((response) => {
        if (response.status === 200) {
          toast.success('Successfully created!', {
            id: loadingToast,
          });
          onClose();
        }
      })
      .catch(() => {
        toast.error('This is an error!', {
          id: loadingToast,
        });
      })
      .finally(() => toast.dismiss(loadingToast));
  };

  const actions = (
    <Box style={{
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
    }}
    >
      <Button color="secondary" variant="contained" onClick={onClose}>
        <Typography variant="button">
          {t(orders?.length > 0 ? 'cancel' : 'close')}
        </Typography>
      </Button>
      <LoadingButton loading={orderRequest.processing} variant="contained" color="primary" onClick={handleSubmit}>
        <Typography variant="button">
          {t('send')}
        </Typography>
      </LoadingButton>
    </Box>
  );

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      title={t('general:orders_excel:title')}
      actions={actions}
      maxWidth="md"
    >
      <Box p={2} minHeight="300px" className={classes.root}>
        {orders === null && (
          <OrdersFileExcel setOrders={setOrders} />
        )}
        {orders?.length > 0 && (
          <PaginatedTable
            title="Previsualización de las ordenes"
            elevation={0}
            options={{ responsive: 'standard', elevation: 0 }}
            data={orders}
            columns={[
              {
                name: 'id',
                label: t('id'),
                options: {
                  customBodyRender: id => (
                    <Typography variant="caption" component="p">
                      {id}
                    </Typography>
                  ),
                },
              },
              {
                name: 'items',
                label: t('items'),
                options: {
                  customBodyRender: items => items.map((item, i) => (
                    <Box>
                      <Typography key={item.name + item.quantity} variant="caption" component="p">
                        {item.quantity}
                        x
                        {' '}
                        {item.name}
                      </Typography>
                      {i !== items.length - 1 && <Divider />}
                    </Box>
                  )),
                },
              },
              {
                name: 'total',
                label: t('total'),
                options: {
                  customBodyRender: total => (
                    <Typography variant="caption" component="p">
                      {formatCurrency.format(Number(total))}
                    </Typography>
                  ),
                },
              },
              {
                name: 'address',
                label: t('address'),
                options: {
                  customBodyRender: address => (
                    <Typography variant="caption" component="p">
                      {address.address}
                    </Typography>
                  ),
                },
              },
              {
                name: 'order_date',
                label: t('order_date'),
                options: {
                  customBodyRender: date => (
                    <Typography variant="caption" component="p">
                      {format(new Date(date), 'dd/MM/yyyy')}
                    </Typography>
                  ),
                },
              },
              {
                name: 'delivery_min_date',
                label: t('delivery_min_date'),
                options: {
                  customBodyRender: date => (
                    <Typography variant="caption" component="p">
                      {format(new Date(date), 'dd/MM/yyyy')}
                    </Typography>
                  ),
                },
              },
              {
                name: 'delivery_max_date',
                label: t('delivery_max_date'),
                options: {
                  customBodyRender: date => (
                    <Typography variant="caption" component="p">
                      {format(new Date(date), 'dd/MM/yyyy')}
                    </Typography>
                  ),
                },
              },
              {
                name: 'customer',
                label: t('customer_name'),
                options: {
                  customBodyRender: customer => (
                    <Typography variant="caption" component="p">
                      {customer.name}
                    </Typography>
                  ),
                },
              },
              {
                name: 'customer',
                label: t('customer_email'),
                options: {
                  customBodyRender: customer => (
                    <Typography variant="caption" component="p">
                      {customer.email}
                    </Typography>
                  ),
                },
              },
              {
                name: 'customer',
                label: t('customer_phone'),
                options: {
                  customBodyRender: customer => (
                    <Typography variant="caption" component="p">
                      {customer.phone}
                    </Typography>
                  ),
                },
              },
            ]}
          />
        )}
        {/* {
            step === 3
            ? <Results />
            : step === 2
            ? <Preview />
            : <Files />
          } */}
      </Box>
    </Modal>
  );
};
