/* eslint-disable prefer-destructuring */
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/naming-convention */
import React, {
  ChangeEvent, useCallback, useState,
} from 'react';
import {
  Badge,
  Box,
  Button, Grid, TextField, Typography,
} from '@mui/material';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import { makeStyles } from '@mui/styles';
import images from '../../../theme/ECTheme/assets/images';
import ProfilePicture from '../ProfilePicture';
import { useTimeAgo } from '../../../hooks/useTimeAgo';
import { firstUpperCase } from '../../../util/format';
import useRequest from '../../../hooks/useRequest';
import { CommentResponse } from '../../../helpers/interfaces/comment';

const styles = makeStyles(theme => ({
  container: {
    margin: '20px 0px',
    padding: '0px 10px',
  },
  responses: {
    backgroundColor: '#E8EAEF',
    borderRadius: 10,
    padding: '5px 10px',
    width: '95%',
    marginLeft: 'auto',
    marginTop: 10,
  },
  margin: {
    margin: theme.spacing(1),
  },
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
}));

const OpinionItem = ({
  user,
  created_at,
  content,
  replies,
  company,
  id,
  getComments,
  likes_count,
}) => {
  const classes = styles();
  let name = ''; let last_name = ''; let
    image_url = '';

  if (user) {
    name = user.name;
    last_name = user.last_name;
    image_url = user.image_url;
  }
  let timeAgo;
  if (created_at) {
    timeAgo = useTimeAgo(new Date(created_at).getTime())[0];
  }

  const { post, processing, response } = useRequest<CommentResponse>({});

  const [comment, setComment] = useState('');
  const [selectMenu, setSelectMenu] = useState(false);
  const [likes, setLikes] = useState(likes_count);
  const [likerender, setLikeRender] = useState(likes_count);

  const MenuSelector = () => {
    setSelectMenu(!selectMenu);
  };

  const postComments = useCallback(async (event) => {
    event.preventDefault();
    await post(`/companies/${company.id}/comments/${id}/replies`, {
      content: comment,
    })
      .then(() => {
        getComments(); // la respuesta no tiene info suficiente
      })
      .finally(() => {
        setSelectMenu(false);
      });
  }, [post, company, comment]);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setComment(e.target.value);
  };

  const postLikes = useCallback(async (event) => {
    event.preventDefault();
    // handleAdd();

    await post(`/companies/${company.id}/comments/${id}/likes`, {
      likes_count: setLikes(1),
    })
      .then(() => {
        // getComments();
        setLikeRender(likes);
      });
  }, [post, company]);

  return (
    <Grid container direction="row" wrap="nowrap" className={classes.container}>
      <Grid
        container
        justifyContent="center"
        alignItems="flex-start"
        style={{ margin: 10, maxWidth: 70 }}
      >
        <ProfilePicture
          image={image_url || images.DefaultPerfile}
          width={70}
          height={70}
        />
      </Grid>
      <Grid style={{ flex: 1 }}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid>
            <Typography style={{ color: 'black', fontWeight: 'bold' }}>
              {`${firstUpperCase(name)}`}

              {`${firstUpperCase(last_name)}`}
            </Typography>
            <Typography>{timeAgo}</Typography>
          </Grid>
          <Grid>
            <Button onClick={postLikes}>
              <Badge badgeContent={likerender} color="primary">
                <ThumbUpIcon />
              </Badge>
            </Button>
            <Button onClick={MenuSelector}>
              <ChatBubbleIcon />
            </Button>
          </Grid>
        </Grid>
        <Grid>
          <Typography style={{ fontSize: '0.9em' }}>{content}</Typography>
        </Grid>
        <div style={{ display: 'flex' }}>
          {
            selectMenu
              ? (
                <form className={classes.root} onSubmit={postComments} noValidate autoComplete="off">
                  <TextField
                    variant="standard"
                    multiline
                    className={classes.margin}
                    label="Comentario"
                    fullWidth
                    onChange={onChange}
                    id="standard-basic"
                  />
                  <Box display="flex" justifyContent="flex-end" padding="5px">
                    <Button
                      size="small"
                      disabled={processing}
                      type="submit"
                      variant="contained"
                      color="primary"
                    >
                      guardar
                    </Button>
                  </Box>
                </form>
              )
              : null
          }
        </div>

        {replies
          && replies.map(rep => (
            <Grid className={classes.responses} key={rep.id}>
              <Typography
                style={{
                  fontSize: '0.9em',
                  fontWeight: 'bold',
                  color: 'black',
                }}
              >
                {rep.user.name}
                &nbsp;
                {rep.user.last_name}

              </Typography>
              <Typography style={{ fontSize: '0.9em' }}>
                {rep.content}
              </Typography>
            </Grid>
          ))}

      </Grid>
    </Grid>
  );
};

export default OpinionItem;
