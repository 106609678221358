/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/prop-types */
import React from 'react';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const useStyle = makeStyles(theme => ({
  container: {
    padding: '7px 0px',
    minHeight: 80,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  tittle: {
    fontSize: 14,
    color: theme.colors.black,
    fontWeight: 'bold',
  },
  price: {
    '&:before': {
      content: "'$'",
    },
  },
  details: {
    color: theme.colors.pointSale.main,
    fontSize: 14,
    cursor: 'pointer',
  },
  limittext: {
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

}));

export default ({ event, data, responsible }) => {
  const classes = useStyle();
  const { t } = useTranslation(['general']);

  const section = event.split(':')[0];
  const type = event.split(':')[1];

  const sectionName = {
    ticket: 'ticket',
    product: 'producto',
    products: 'productos',
    movement: 'movimiento',
    company: 'perfil',
    user: 'usuario',
    subscription: 'suscripción',
    brand: 'marca',
    branch: 'branch',
    location: 'ubicación',
    category: 'categoría',
    variant: 'variante',
    variants: 'variantes',
    inventory: 'inventario',
    movements: 'movimientos',
    unitofmeasure: 'unidades de medida',
    supplier: 'proveedor',
    sale: 'sale',
    customer: 'cliente',
  };
  const typeName = {
    created: 'creó',
    updated: 'actualizó',
    registered: 'registró',
    deleted: 'eliminó',
    added: 'agregó',
  };

  let dataType;
  switch (section) {
    case 'sale':
      dataType = (
        <>
          <p style={{ fontSize: '0.9em' }} className={classes.tittle}>
            {t('general:ticket')}
            {' '}
            #
            { data.number }
          </p>
          <p style={{ fontSize: '0.9em' }} className={classes.price}>{ data.subtotal }</p>
        </>
      );
      break;

    case 'product':
      dataType = (
        <>
          <p style={{ fontSize: '0.9em' }} className={classes.tittle}>
            Se
            { typeName[type] }
            {' '}
            un
            { sectionName[section] }
          </p>
          <p style={{ fontSize: '0.9em' }}>{ data.name }</p>
        </>
      );
      break;

    case 'company':
      dataType = (
        <>
          <p style={{ fontSize: '0.9em' }} className={classes.tittle}>
            Se
            { typeName[type] }
            {' '}
            el
            { sectionName[section] }
          </p>
          <p style={{ fontSize: '0.9em' }}>{ data.name }</p>
        </>
      );
      break;

    case 'ticket':
      dataType = (
        <>
          <p style={{ fontSize: '0.9em' }} className={classes.tittle}>
            Se
            { typeName[type] }
            {' '}
            un
            { sectionName[section] }
          </p>
          <p style={{ fontSize: '0.9em' }}>{ data.name }</p>
        </>
      );
      break;

    case 'movement':
      dataType = (
        <>
          <p style={{ fontSize: '0.9em' }} className={classes.tittle}>
            Se
            { typeName[type] }
            {' '}
            un
            { sectionName[section] }
          </p>
          <p style={{ fontSize: '0.9em' }}>{ `${data.quantity} unidades` }</p>
        </>
      );
      break;

    case 'products':
      dataType = (
        <>
          <p style={{ fontSize: '0.9em' }} className={classes.tittle}>
            Se
            { typeName[type] }
            {' '}
            {data.products.length}
            {' '}
            { sectionName[section] }
          </p>
          {
                            data.products.map((product: any, index: any) => {
                              if (index > 1) {
                                return <></>;
                              }
                              return <p style={{ fontSize: '0.9em' }} key={product.id}>{product.name}</p>;
                            })
                        }
        </>
      );
      break;

    default:
      dataType = (
        <>
          <p style={{ fontSize: '0.9em' }} className={classes.tittle}>
            Se
            { typeName[type] }
            {' '}
            el
            { sectionName[section] }
          </p>
          <p style={{ fontSize: '0.9em' }}>{ data.name }</p>
        </>
      );
      break;
  }

  return (
    <div className={classes.container}>
      {
                dataType
            }
      {
                responsible
                && (
                <p className={classes.limittext} style={{ fontSize: '0.9em' }}>
                  {section === 'sale' ? t('general:seller') : 'Creador'}
                  { `: ${responsible.name} ${responsible.last_name}` }
                </p>
                )
            }
      <Link to="">
        <p className={classes.details}>
          {t('general:see_details')}
        </p>
      </Link>
    </div>
  );
};
