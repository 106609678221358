/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PayType from './PayType';
// import TagPay from "./TagPay";
import { PAYMENT_TYPE } from '../../../../../../constants/common';
import { addPaymentType } from '../../../../../../actions/cart';
import images from '../../../../../../theme/ECTheme/assets/images';

const styles = makeStyles(() => ({
  container: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  contentOpen: {
    visivility: 'visible',
    height: '80%',
  },
  contentClose: {
    height: 0,
    visibility: 'hidden',
  },
  price: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    fontSize: 35,
  },
  priceClose: {
    height: '100%',
  },
  priceOpen: {
    height: '15%',
  },

  tags: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '10px 0px',
  },

  payType: {
    width: '100%',
    height: 'calc(100% - 60px)',
    display: 'grid',
    gridTemplateColumns: 'repeat(3,1fr)',
    gridTemplateRows: 'repeat(2,1fr)',
    justifyContent: 'center',
    alignContent: 'center',
    whiteSpace: 'normal',
  },
}));

export default ({ drawerOpen, total, setClientAlert }) => {
  const classes = styles();
  const dispatch = useDispatch();
  const { t } = useTranslation(['general']);
  const cart = useSelector((state: any) => state.cart);

  const radioChecked = (value) => {
    if (value === PAYMENT_TYPE.CREDIT && !cart.customer) {
      return setClientAlert(true);
    }
    dispatch(addPaymentType(value));
  };

  return (
    <Grid className={classes.container}>
      <div className={`${classes.price} ${drawerOpen ? classes.priceOpen : classes.priceClose}`}>
        $
        {total}
      </div>
      <Grid className={drawerOpen ? classes.contentOpen : classes.contentClose}>
        {/* <Grid className={classes.tags}>
                <TagPay title={t("general:point_of_sell:reserve")}/>
                <TagPay title={t("general:point_of_sell:quotation")}/>
            </Grid> */}
        <Grid className={classes.payType}>
          <PayType image={images.Icon_CASH} title={t('general:payment_type:CASH')} value={PAYMENT_TYPE.CASH} change={() => radioChecked(PAYMENT_TYPE.CASH)} checkvalue={cart.payment_type} />
          <PayType image={images.Icon_CREDIT_CARD} title={t('general:payment_type:CREDIT_CARD')} value={PAYMENT_TYPE.CREDIT_CARD} change={() => radioChecked(PAYMENT_TYPE.CREDIT_CARD)} checkvalue={cart.payment_type} />
          <PayType image={images.Icon_CODI} title={t('general:payment_type:CODI')} value={PAYMENT_TYPE.CODI} change={() => radioChecked(PAYMENT_TYPE.CODI)} checkvalue={cart.payment_type} />
          <PayType image={images.Icon_VOUCHER} title={t('general:payment_type:VOUCHER')} value={PAYMENT_TYPE.VOUCHER} change={() => radioChecked(PAYMENT_TYPE.VOUCHER)} checkvalue={cart.payment_type} />
          <PayType image={images.Icon_TRANSFE} title={t('general:payment_type:TRANSFE')} value={PAYMENT_TYPE.TRANSFE} change={() => radioChecked(PAYMENT_TYPE.TRANSFE)} checkvalue={cart.payment_type} />
          <PayType image={images.Icon_CREDIT} title={t('general:payment_type:CREDIT')} value={PAYMENT_TYPE.CREDIT} change={() => radioChecked(PAYMENT_TYPE.CREDIT)} checkvalue={cart.payment_type} />
        </Grid>
      </Grid>
    </Grid>
  );
};
