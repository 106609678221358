/* eslint-disable react/destructuring-assignment */
import React from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { useTranslation } from 'react-i18next';

export interface ConfirmationDialogRawProps {
  keepMounted?: boolean;
  open: boolean;
  handleCancel: () => void;
  handleConfirm: () => void;
  title?: string;
  content?: any;
}

export default (props: ConfirmationDialogRawProps) => {
  const {
    handleCancel, handleConfirm, open, content, title, ...other
  } = props;
  const { t } = useTranslation(['general']);
  return (
    <Dialog
      disableEscapeKeyDown
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={props.open}
      {...other}
      TransitionProps={{
        onEntering: () => {
        },
      }}
    >
      <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
      <DialogContent dividers>{content}</DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel} color="primary">
          {t('general:cancel')}
        </Button>
        <Button onClick={handleConfirm} color="primary">
          {t('general:ok')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
