/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '../../../../../components/ui/Layout/Grid';
import ColumnCard from '../../../../../components/ui/ColumnCard';

export default () => {
  const { t } = useTranslation(['general']);

  const _cards = [
    { name: t('orders:create_order'), section: 'orders' },
    { name: t('orders:receipt_order'), link: 'inventory/categories' },
    { name: t('orders:refunds_vendors') },
    { name: t('orders:active_orders') },
    { name: t('orders:shopping_lists') },
    { name: t('orders:orders_reports'), topIcon: 'reports' },
  ];

  const cards = _cards.map(card => (
    <ColumnCard key={card.name} card={card} />
  ));

  return (
    <Grid container spacing={3}>
      {cards}
    </Grid>
  );
};
