import { useCallback, useReducer } from 'react';
import AuthorizedApiClient from '../../../../../../core/common/api/AuthorizedApiClient';
import useCompany from '../../../../../../hooks/useCompany';
import { DEFAULT_START_DATE, DEFAULT_END_DATE } from '../../constants';

import {
  SET_SALES_LIST_ERROR,
  SET_SALES_LIST_PROCESSING,
  SET_SALES_LIST_SUCCESS,
  SET_SALES_LIST_PARAMS,
  SET_SALES_RESET_FILTERS,
} from '../constants';
import productsListReducer from '../reducers/sales';

const initialState = {
  processing: false,
  response: {
    data: [],
    total: 0,
    per_page: 12,
    last_page: 1,
    page: 1,
  },
  params: {
    page: 1,
    per_page: 12,
    sort: { created_at: 'desc' },

    filters: {
      company: '5fce8633e710e40020713d21',
      updated_at: {
        $gte: DEFAULT_START_DATE,
        $lte: DEFAULT_END_DATE,
      },
    },
  },
};

const useSalesListState = () => {
  const { company } = useCompany();
  const [state, dispatch] = useReducer(productsListReducer, initialState);

  const setProcessing = useCallback((processing) => {
    dispatch({ type: SET_SALES_LIST_PROCESSING, payload: processing });
  }, [dispatch]);

  const setSalesFailure = useCallback((error) => {
    dispatch({ type: SET_SALES_LIST_ERROR, payload: error });
  }, [dispatch]);

  const setSalesResponse = useCallback((response) => {
    dispatch({ type: SET_SALES_LIST_SUCCESS, payload: response });
  }, [dispatch]);

  const getSales = useCallback(async (params = {}) => {
    // const sg = ApiSalesPerMonthGateway.getInstance();
    const path = `companies/${company.id}/sales/reports/sales_per_month`;
    const sg = new AuthorizedApiClient();

    setProcessing(true);
    try {
      const response = await sg.get(params, path);
      // const response = await sg.get(params);

      if (response != null) {
        setSalesResponse(response.data);
      }
    } catch (error) {
      setSalesFailure(error);
    }
  }, [setProcessing, setSalesResponse, setSalesFailure]);

  const refreshList = () => {
    getSales(initialState.params);
  };

  const setParams = (params) => {
    dispatch({ type: SET_SALES_LIST_PARAMS, payload: params });
  };

  const resetFilters = () => {
    dispatch({
      type: SET_SALES_RESET_FILTERS,
      payload: initialState.params.filters,
    });
  };

  return {
    state,
    getSales,
    refreshList,
    setParams,
    resetFilters,
  };
};

export default useSalesListState;
