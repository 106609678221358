export enum CampaignWizardStep {
  DATES = 1,
  GOALS = 2,
  PRODUCTS = 3,
  ZONES = 4,
  GALLERY = 5,
  BASIC_INFO = 6,
}

export const FIRST_STEP = CampaignWizardStep.DATES;
export const LAST_STEP = CampaignWizardStep.BASIC_INFO;

export const SAVE_CAMPAIGN_SUCCESS = 'SAVE_CAMPAIGN_SUCCESS';
export const SAVE_CAMPAIGN_ERROR = 'SAVE_CAMPAIGN_ERROR';
export const SET_CAMPAIGN_PROCESSING = 'SET_CAMPAIGN_PROCESSING';
export const SET_CAMPAIGN_STEP = 'SET_CAMPAIGN_STEP';
export const CLEAR_CAMPAIGN_FORM = 'CLEAR_CAMPAIGN_FORM';
export const SET_CAMPAIGN_STEP_TO_SAVE = 'SET_CAMPAIGN_STEP_TO_SAVE';
export const SET_CAMPAIGN_BASIC_INFO = 'SET_CAMPAIGN_BASIC_INFO';
export const SET_CAMPAIGN_DATES = 'SET_CAMPAIGN_DATES';
export const SET_CAMPAIGN_SELECTED_PRODUCTS = 'SET_CAMPAIGN_SELECTED_PRODUCTS';

export type CampaignZone = {
  lat: number;
  lng: number;
  address?: string;
};
