/* eslint-disable react/prop-types */
import React from 'react';
import ExcelButton from '../Buttons/ExcelButton';

export default ({ fileHandler }) => {
  const id = `upload-excel${Math.floor(Math.random() * 1000)}${1}`;
  return (
    <label htmlFor={id}>
      <ExcelButton text="Selecciona archivo" component="span" />
      <input
        type="file"
        id={id}
        onChange={fileHandler}
        style={{ display: 'none' }}
        accept=".xls,.xlsx"
      />
    </label>
  );
};
