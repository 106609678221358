/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyle = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    margin: 10,
  },
  button: {
    height: 30,
    width: 30,
    borderRadius: '50%',
    border: '2px solid #CCC',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  maskButton: {
    backgroundColor: '#757575',
    display: 'block',
    width: '80%',
    height: 3,
    borderRadius: 2,
    '&::after': {
      content: "''",
      backgroundColor: '#757575',
      display: 'block',
      width: '80%',
      height: 3,
      borderRadius: 2,
      transform: 'rotate(90deg)',
      position: 'absolute',
    },
  },
  text: {
    color: '#757575',
    fontSize: 18,
    marginLeft: 8,
  },
}));

export default (props) => {
  const classes = useStyle();
  return (
    <div className={classes.container}>
      <div className={classes.button}>
        <span className={classes.maskButton} />
      </div>
      <div className={classes.text}>{props.tittle}</div>
    </div>
  );
};
