/* eslint-disable react/prop-types */
import React from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import Box from '../../../../../components/ui/Layout/Box';
import { generateRamdomNumber } from '../../../../../util/keys';

const styles = makeStyles(() => ({
  container: {
    position: 'relative',
    cursor: 'pointer',
    border: '0.5px solid #9A9494',
    borderRadius: '3px',
    boxShadow: '0px 3px 6px #00000029',
  },
  image: {
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',
    height: '140px',
    border: '0.5px solid #9A9494',
    width: '100%',
  },
  field: {
    marginLeft: '5%',
  },
}));

export default ({ branch }) => {
  const classes = styles();
  const { t } = useTranslation(['general']);

  return (
    <Box
      display="flex"
      flexDirection="column"
      className={classes.container}
      p={1}
    >
      <Box display="flex" alignItems="center">
        <Typography color="secondary" variant="subtitle1">
          {t('branches:name')}
          :
        </Typography>

        <Typography color="secondary" className={classes.field}>
          {branch.name}
        </Typography>
      </Box>

      <Box display="flex" alignItems="center">
        <Typography color="secondary" variant="subtitle1">
          {t('branches:address')}
          :
        </Typography>

        <Typography color="secondary" className={classes.field}>
          {branch.address}
        </Typography>
      </Box>

      <Box display="flex" alignItems="center">
        <Typography color="secondary" variant="subtitle1">
          {t('branches:phone')}
          :
        </Typography>

        <Typography color="secondary" className={classes.field}>
          {branch.phone}
        </Typography>
      </Box>

      <Box display="flex" alignItems="center">
        <Typography color="secondary" variant="subtitle1">
          {t('branches:email')}
          :
        </Typography>

        <Typography color="secondary" className={classes.field}>
          {branch.email}
        </Typography>
      </Box>

      <Box display="flex" alignSelf="center" width="80%">
        <Box
          alignSelf="center"
          className={classes.image}
          style={{ backgroundImage: `url(${branch.image_url}?v=${generateRamdomNumber()})` }}
        />
      </Box>
    </Box>
  );
};
