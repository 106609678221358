import { Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import Box from '../ui/Layout/Box';
import Grid from '../ui/Layout/Grid';
import BoxAnswer from './BoxAnswer';
import { setBusinessType, setFood, setWizardStep } from '../../actions/wizard';
import { WizardSteps } from './steps';
import { BusinessType } from '../../constants/common';

const useStyle = makeStyles(() => ({
  questionsContainer: {
    width: '70%',
    marginLeft: 'auto',
    marginRight: 'auto',
    minWidth: '220px',
  },
}));

const FoodSection = () => {
  const classes = useStyle();
  const { t } = useTranslation(['general']);
  const dispatch = useDispatch();
  const { food } = useSelector((state: any) => state.wizard);
  const handleFoodChange = (value) => {
    dispatch(setFood(value));
    if (value) {
      dispatch(setWizardStep(WizardSteps.BUSINESS_TYPE));
    } else {
      dispatch(setBusinessType(BusinessType.OTHER));
      dispatch(setWizardStep(WizardSteps.CATEGORIES));
    }
  };

  const handleYesClick = () => {
    handleFoodChange(true);
  };

  const handleNoClick = () => {
    handleFoodChange(false);
  };
  return (
    <>
      <Box>
        <Typography variant="h6" align="center" color="textSecondary">
          {t('general:wizard_setup:food_question')}
        </Typography>
      </Box>

      <Box m={5} className={classes.questionsContainer} pt={5}>
        <Grid container spacing={3}>
          <Grid item xs={6} container justifyContent="flex-start">
            <BoxAnswer label={t('general:yes')} handleOnCLick={handleYesClick} selected={food === true} />
          </Grid>
          <Grid item xs={6} container justifyContent="flex-end">
            <BoxAnswer label={t('general:no')} handleOnCLick={handleNoClick} selected={food === false} />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default FoodSection;
