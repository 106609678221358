/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  ShopName: {
    fontSize: 23,
    color: theme.colors.black,
  },
  TypeAccount: {
    fontSize: 17,
  },
  Tittle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 15,
    marginTop: 15,
  },
}));

const ShopSuscription = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.Tittle}>
      <div className={classes.ShopName}>{props.shopName}</div>
      <div className={classes.TypeAccount}>{props.typeAccount}</div>
    </div>
  );
};

export default ShopSuscription;
