import {
  Box, Typography,
} from '@mui/material';
import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    minHeight: '100px',
    border: '1px dashed #e8eaee',
    cursor: 'pointer',
  },
  text: {
    color: theme.palette.primary.main,
  },
}));

type AddPaymentMethodButtonProps = {
  handleOnClik;
};

const AddPaymentMethodButton = ({
  handleOnClik,
}: AddPaymentMethodButtonProps) => {
  const classes = useStyles();
  const { t } = useTranslation(['general']);
  return (
    <Box
      className={classes.root}
      display="flex"
      alignItems="center"
      alignContent="center"
      textAlign="center"
      justifyContent="center"
      onClick={handleOnClik}
    >
      <Box display="flex" flexDirection="row">
        <AddIcon color="primary" />
        <Typography className={classes.text} color="textSecondary">
          {t('general:payment_methods:add_payment_method')}
        </Typography>
      </Box>
    </Box>
  );
};

export default AddPaymentMethodButton;
