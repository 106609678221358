/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/prop-types */
import {
  MenuItem, Select,
} from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@mui/styles';
import { SelectOption } from '../../../../../../../../components/ui/FormInput/Select';
import {
  PERMISSION,
  RESOURCES,
} from '../../../../../../../../constants/common';
import { generateKey } from '../../../../../../../../util/keys';

const CSelect = withStyles(theme => ({
  select: {
    padding: '4px 4px',
    fontSize: '0.8rem',
    color: theme.palette.text.secondary,
  },
}))(Select);

const useBuildOptions = (resource) => {
  const { t } = useTranslation(['general']);
  let options: Array<SelectOption>;
  switch (resource.code) {
    case RESOURCES.pos:
      options = [
        { value: 'none', label: t('general:settings:invisible') },
        { value: 'action', label: t('general:settings:see_and_edit') },
      ];
      break;

    default:
      options = [
        { value: 'none', label: t('general:settings:invisible') },
        { value: 'read', label: t('general:settings:see') },
        { value: 'action', label: t('general:settings:see_and_edit') },
      ];
  }

  return options;
};

export default ({ resource, setPermission, currentPermissions }) => {
  const options = useBuildOptions(resource);
  let defaultValue = 'none';
  if (currentPermissions && currentPermissions.length) {
    defaultValue = currentPermissions.includes('create') ? 'action' : 'read';
  }

  const [value, setValue] = useState(defaultValue);

  const items = options.map(i => (
    <MenuItem key={generateKey(i.value)} value={i.value}>
      {i.label}
    </MenuItem>
  ));

  const handleChange = (event) => {
    const { value } = event.target;

    let permissions: string[] | null = null;
    switch (value) {
      case 'read':
        permissions = [PERMISSION.read];
        break;
      case 'action':
        permissions = [
          PERMISSION.read,
          PERMISSION.create,
          PERMISSION.edit,
          PERMISSION.delete,
        ];
        break;
      default:
        permissions = null;
    }
    setPermission(resource.code, permissions);

    setValue(event.target.value);
  };

  return (
    <CSelect
      variant="outlined"
      style={{ width: '100%' }}
      value={value}
      onChange={handleChange}
    >
      {items}
    </CSelect>
  );
};
