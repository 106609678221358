/* eslint-disable react/prop-types */
import {
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { orderStatusesArr } from '../../../../../../constants/common';

const OrderStatusFilter = ({ onChange, value = '' }) => {
  const { t } = useTranslation(['general']);

  return (
    <FormControl variant="standard" fullWidth>
      <InputLabel>{t('general:status')}</InputLabel>
      <Select
        variant="standard"
        defaultValue=""
        onChange={(event) => {
          onChange(event.target.value);
        }}
        value={value}
      >
        {/* <MenuItem value={""}></MenuItem> */}
        {orderStatusesArr.map(item => (
          <MenuItem key={item} value={item}>
            <ListItemText primary={t(`general:orders_page:${item}`)} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default OrderStatusFilter;
