/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Alert from '../../../../../../components/ui/notifications/alert';
import { rules as validationRules } from '../../../../../../util/Validations';
import Modal from '../../../../../../components/ui/Modals/Modal';
import Box from '../../../../../../components/ui/Layout/Box';
import Grid from '../../../../../../components/ui/Layout/Grid';
import FormInput from '../../../../../../components/ui/FormInput';
import Actions from './Actions';

export default ({
  attemptSavePassword,
  processing,
  error,
  handleClose,
  showModal,
}) => {
  const { t } = useTranslation(['general']);

  const schema = Yup.object({
    current_password: Yup.string()
      .max(validationRules.maxLength)
      .required(
        t('general:validation:required', {
          input: t('general:settings:current_password'),
        }),
      ),
    new_password: Yup.string()
      .min(
        validationRules.minPasswordLength,
        t('general:validation:invalid_password_format'),
      )
      .max(validationRules.maxLength)
      .matches(
        validationRules.password,
        t('general:validation:invalid_password_format'),
      ),

    new_password_confirm: Yup.string().oneOf(
      [Yup.ref('new_password'), null],
      t('general:validation:passwords_mismatch'),
    ),
  });
  const { register, handleSubmit, formState: { errors } } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    // console.log("data", data)
    const _data = {
      new_password: data.new_password,
      password: data.current_password,
    };
    attemptSavePassword(_data);
  };

  const actions = (
    <Actions
      handleClose={handleClose}
      handleSubmit={handleSubmit(onSubmit)}
      processing={processing}
    />
  );

  const title = t('general:settings:change_password');

  return (
    <Modal
      open={showModal}
      onClose={handleClose}
      title={title}
      actions={actions}
      maxWidth="xs"
    >
      <Box>
        {error && (
          <Grid container>
            <Alert
              severity="error"
              message={t(`general:settings:${error}`, error)}
            />
          </Grid>
        )}
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormInput
                register={register('current_password')}
                error={errors.current_password}
                label={t('general:settings:current_password')}
                type="password"
              />
            </Grid>

            <Grid item xs={12}>
              <FormInput
                register={register('new_password')}
                error={errors.new_password}
                label={t('general:settings:new_password')}
                type="password"
              />
            </Grid>

            <Grid item xs={12}>
              <FormInput
                register={register('new_password_confirm')}
                error={errors.new_password_confirm}
                label={t('general:settings:new_password_confirmation')}
                type="password"
              />
            </Grid>
          </Grid>
        </form>
      </Box>
    </Modal>
  );
};
