import React from 'react';
import useCampaginListState from '../hooks/useCampaginListState';

interface IContextProps {
  state: any;
  getCampaigns: Function;
  refreshList: Function;
  setParams: Function;
  resetFilters: Function;
}

export const CampaignListStateContext = React.createContext(
  {} as IContextProps,
);

interface Props {
  children: React.ReactNode;
}

export const CampaignListProvider = ({ children }: Props) => {
  const initialState = useCampaginListState();
  return (
    <CampaignListStateContext.Provider value={initialState}>
      {children}
    </CampaignListStateContext.Provider>
  );
};

export const useCampaignListContext = () => {
  const context = React.useContext(CampaignListStateContext);
  if (context === undefined) {
    throw new Error(
      'useCampaignListState must be used within a CampaignListProvider',
    );
  }
  return context;
};
