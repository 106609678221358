/* eslint-disable react/no-unstable-nested-components */
import React, { useState } from 'react';
import {
  Box,
  Grid,
  TextField,
  Paper,
  Typography,
  InputAdornment,
  InputLabel,
  FormControl,
  OutlinedInput,
  Button,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IMaskInput } from 'react-imask';
import { Controller, useForm } from 'react-hook-form';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { format, getHours, getMinutes } from 'date-fns';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import toast from 'react-hot-toast';
import { VehicleTable } from '../components/VehicleTable';
import { Dropdown } from '../../../../../components/Dropdown/Dropdown';
import useRequest from '../../../../../hooks/useRequest';
import useCompany from '../../../../../hooks/useCompany';
// const useStyles = makeStyles(theme => ({
//   cellTable: { padding: '.5rem', textAlign: 'center' },
//   typography: {
//     padding: theme.spacing(2),
//   },
// }));

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const TextMaskCustom = React.forwardRef<any, CustomProps>(
  (props, ref) => {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask={Number}
        radix="."
        thousandsSeparator=","
        inputRef={ref}
        onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  },
);

export const VehiclesPage = () => {
  // const classes = useStyles();
  const { t } = useTranslation();
  const { company } = useCompany();
  const [status, setStatus] = useState('available');
  const [fistCirculationHour, setFistCirculationHour] = useState(format(new Date(), 'MM/dd/yyyy'));
  const [lastCirculationHour, setLastCirculationHour] = useState(format(new Date(), 'MM/dd/yyyy'));
  const request = useRequest({ auth: true, apiVerison: 'v1.1' });
  // const requestVehicles = useRequest({ auth: true });
  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
  } = useForm();

  const onSubmit = (data) => {
    const loadingToast = toast.loading('Waiting...');
    request.post(
      `businesses/${company.id}/vehicles`,
      {
        plate: data.plate,
        model: data.model,
        max_weight: data.burden,
        max_volume: data.volume,
        status: 'available',
      },
    )
      .then(() => {
        toast.success('Successfully created!', {
          id: loadingToast,
        });
        reset();
      })
      .catch(() => {
        toast.error('This is an error!', {
          id: loadingToast,
        });
      })
      .finally(() => toast.dismiss(loadingToast));
  };

  const onTimeConfirm = (date, field) => {
    const formatHour = `${getHours(date)}:${getMinutes(date)}`;
    const fieldToUpdate = field;

    const loadingToast = toast.loading('Waiting...');
    request.post(
      `businesses/${company.id}/vehicles/set_time_windows`,
      {
        [fieldToUpdate]: formatHour,
      },
    )
      .then(() => {
        toast.success('Successfully created!', {
          id: loadingToast,
        });
        reset();
      })
      .catch(() => {
        toast.error('This is an error!', {
          id: loadingToast,
        });
      })
      .finally(() => toast.dismiss(loadingToast));
  };

  const onChangeStatus = (selected) => {
    setStatus(selected.identifier);
    const action = selected.identifier === 'available' ? 'enable' : 'disable';

    const loadingToast = toast.loading('Waiting...');
    request.put(
      `businesses/${company.id}/vehicles/{id}/${action}`,
    )
      .then(() => {
        toast.success('Successfully created!', {
          id: loadingToast,
        });
        reset();
      })
      .catch(() => {
        toast.error('This is an error!', {
          id: loadingToast,
        });
      })
      .finally(() => toast.dismiss(loadingToast));
  };

  const clearForm = () => {
    reset({
      plate: '',
      model: '',
      max_volume: '',
      max_weight: '',
    });
  };
  // useEffect(() => {
  //   requestVehicles.get('businesses/{businessId}/vehicles');
  // }, [request]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={7} gap={2}>
        <Box mb={2} p={1} borderRadius={1} display="flex" alignItems="center" gap={1} style={{ backgroundColor: '#3b0ee3' }}>
          <TimerOutlinedIcon fontSize="large" htmlColor="white" />
          <Box>
            <Typography variant="h6" color="#fff">
              {t('schedule_title')}
            </Typography>
            <Typography variant="body2" color="#fff">
              {t('schedule_desc')}
            </Typography>
          </Box>
          <Box display="flex" gap={1}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <TimePicker
                onAccept={date => onTimeConfirm(date, 'start_hour')}
                value={fistCirculationHour}
                onChange={hour => setFistCirculationHour(hour)}
                renderInput={params => <TextField style={{ backgroundColor: 'white', borderRadius: '4px' }} variant="outlined" size="small" {...params} />}
              />
              <Typography variant="h6" color="#fff">
                a
              </Typography>
              <TimePicker
                onAccept={date => onTimeConfirm(date, 'end_hour')}
                value={lastCirculationHour}
                onChange={hour => setLastCirculationHour(hour)}
                renderInput={params => <TextField style={{ backgroundColor: 'white', borderRadius: '4px' }} variant="outlined" size="small" {...params} />}
              />
            </LocalizationProvider>
          </Box>
        </Box>
        <VehicleTable
          onRowClick={({
            id, max_weight, max_volume, plate, model,
          }) => {
            reset({
              id,
              plate,
              model,
              max_volume: max_volume.toString(),
              max_weight: max_weight.toString(),
            });
          }}
        />
      </Grid>
      <Grid item xs={12} md={5}>
        <Box mb={2}>
          <Button onClick={clearForm} variant="contained">
            {t('add_vehicle')}
          </Button>
        </Box>
        <Paper style={{ padding: '1rem' }}>
          <Typography variant="h5" color="gray">
            {t('vehicle')}
          </Typography>
          <Box display="flex" gap={1}>
            <Box flexBasis="50%">
              <img style={{ borderRadius: 5 }} width="100%" alt="vehicle" src="https://d1eip2zddc2vdv.cloudfront.net/wor/cover_images/thumbnails/888/1200x800_888.jpg?timestamp=1641214260" />
            </Box>
            <Box flexBasis="40%">
              <Dropdown
                label="Estado"
                fullWidth
                value={status}
                options={[
                  { identifier: 'available', label: 'Disponible', color: '#e3fde3' },
                  { identifier: 'unavailable', label: 'No disponible', color: '#e5b2dd' },
                ]}
                onChange={onChangeStatus}
              />
            </Box>
          </Box>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box mt={2} mb={2} gap={2} display="flex" flexDirection="column" flex={1} alignItems="streach" justifyContent="space-around">
              <TextField {...register('model')} InputLabelProps={{ shrink: watch('model') }} label="Modelo" variant="outlined" size="small" />
              <TextField {...register('plate')} InputLabelProps={{ shrink: watch('plate') }} label="Placa" variant="outlined" size="small" />
              <Controller
                name="max_weight"
                control={control}
                render={({ field }) => (
                  <FormControl size="small" variant="outlined">
                    <InputLabel>{t('weight')}</InputLabel>
                    <OutlinedInput
                      {...field}
                      size="small"
                      inputComponent={TextMaskCustom as any}
                      endAdornment={<InputAdornment position="end">Tons</InputAdornment>}
                      label="Carga"
                    />
                  </FormControl>
                )}
              />
              <Controller
                name="max_volume"
                control={control}
                render={({ field }) => (
                  <FormControl size="small" variant="outlined">
                    <InputLabel>{t('volume')}</InputLabel>
                    <OutlinedInput
                      {...field}
                      size="small"
                      inputComponent={TextMaskCustom as any}
                      endAdornment={<InputAdornment position="end">L</InputAdornment>}
                      label="Volumen"
                    />
                  </FormControl>
                )}
              />
              <Button variant="contained" type="submit">Guardar</Button>
            </Box>
          </form>

          {/* <Grid container style={{ padding: '2rem 0rem' }}>
            <Grid item xs={6}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Revisón de vehículos</FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox color="primary" checked onChange={() => {}} name="t34r3" />}
                    label="Llantas"
                  />
                  <FormControlLabel
                    control={(
                      <Checkbox
                        color="primary"
                        checked={false}
                        onChange={() => {}}
                        name="j75ht"
                      />
                    )}
                    label="Frenos"
                  />
                  <FormControlLabel
                    control={<Checkbox color="primary" checked onChange={() => {}} name="sw1e" />}
                    label="Espejos"
                  />
                  <FormControlLabel
                    control={<Checkbox color="primary" checked onChange={() => {}} name="l80pth" />}
                    label="Aceite"
                  />
                  <FormControlLabel
                    control={(
                      <Checkbox
                        color="primary"
                        checked={false}
                        onChange={() => {}}
                        name="g45tg3"
                      />
                    )}
                    label="Verificación"
                  />
                  <FormControlLabel
                    control={<Checkbox color="primary" checked onChange={() => {}} name="h4g5g" />}
                    label="Tenencia"
                  />
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Días que no circula</FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox color="primary" checked onChange={() => {}} name="r2d" />}
                    label="Lunes"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox color="primary" checked={false} onChange={() => {}} name="r32r" />
                    }
                    label="Martes"
                  />
                  <FormControlLabel
                    control={<Checkbox color="primary" checked onChange={() => {}} name="r32r" />}
                    label="Miercoles"
                  />
                  <FormControlLabel
                    control={<Checkbox color="primary" checked onChange={() => {}} name="t54t" />}
                    label="Jueves"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox color="primary" checked={false} onChange={() => {}} name="y65h5" />
                    }
                    label="Viernes"
                  />
                  <FormControlLabel
                    control={<Checkbox color="primary" checked onChange={() => {}} name="j86j" />}
                    label="Sabado"
                  />
                  <FormControlLabel
                    control={<Checkbox color="primary" checked onChange={() => {}} name="i67jt" />}
                    label="Domingo"
                  />
                </FormGroup>
              </FormControl>
            </Grid>
          </Grid> */}

          {/* <Grid container direction="column">
            <Typography>
              Alertas/Recordatorios
            </Typography>
            <Box padding={2} display="box">
              <Typography>
                Próxima verificación:
                {' '}
                {new Date().toLocaleDateString('es')}
              </Typography>
              <Typography>
                Próxima verificación:
                {' '}
                {new Date().toLocaleDateString('es')}
              </Typography>
            </Box>
          </Grid>

          <PaginatedTable
            title="Lista de vehiculos"
            elevation={0}
            options={{
              responsive: 'standard',
              pagination: false,
              elevation: 0,
              filter: true,
              sort: true,
            }}
            columns={[
              {
                name: 'date',
                label: t('general:date'),
                options: {
                  filter: false,
                  sort: false,
                  setCellProps: () => ({
                    className: classes.cellTable,
                  }),
                  setCellHeaderProps: () => ({
                    className: classes.cellTable,
                  }),
                },
              },
              {
                name: 'hour',
                label: t('general:hour'),
                options: {
                  filter: false,
                  sort: false,
                  setCellProps: () => ({
                    className: classes.cellTable,
                  }),
                  setCellHeaderProps: () => ({
                    className: classes.cellTable,
                  }),
                },
              },
              {
                name: 'driver',
                label: t('general:driver'),
                options: {
                  filter: false,
                  sort: false,
                  setCellProps: () => ({
                    className: classes.cellTable,
                  }),
                  setCellHeaderProps: () => ({
                    className: classes.cellTable,
                  }),
                  customBodyRender: value => (
                    <Link to={value}>
                      {value}
                    </Link>
                  ),
                },
              },
              {
                name: 'status',
                label: t('general:status'),
                options: {
                  filter: false,
                  sort: false,
                  setCellProps: () => ({
                    className: classes.cellTable,
                  }),
                  setCellHeaderProps: () => ({
                    className: classes.cellTable,
                  }),
                  customBodyRender: (value) => {
                    const status = JSON.parse(value);
                    return (
                      <StatusIndicator color={status.color} label={status.label} />
                    );
                  },
                },
              },
              {
                name: 'comments',
                label: t('general:comments'),
                options: {
                  filter: false,
                  sort: false,
                  setCellProps: () => ({
                    className: classes.cellTable,
                  }),
                  setCellHeaderProps: () => ({
                    className: classes.cellTable,
                  }),
                  customBodyRender: value => (
                    <Link to={value}>
                      Ver más
                    </Link>
                  ),
                },
              },
            ]}
            data={[
              {
                date: new Date().toLocaleDateString('es'),
                hour: '14:00hrs',
                driver: 'Juan Roncha',
                status: JSON.stringify({ color: '#eba39b', label: 'important' }),
                comments: 'id o algo',
              },
              {
                date: new Date().toLocaleDateString('es'),
                hour: '14:00hrs',
                driver: 'Juan Roncha',
                status: JSON.stringify({ color: '#eba39b', label: 'important' }),
                comments: 'id o algo',
              },
              {
                date: new Date().toLocaleDateString('es'),
                hour: '14:00hrs',
                driver: 'Juan Roncha',
                status: JSON.stringify({ color: '#eba39b', label: 'important' }),
                comments: 'id o algo',
              },
            ]}
          /> */}
        </Paper>
      </Grid>
    </Grid>
  );
};
