/* eslint-disable react/prop-types */
import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyle = makeStyles(theme => ({
  icon: {
    width: '26px',
    height: '26px',
    display: 'inline-block',
  },
  income: {
    background: `url(${theme.icons.income}) no-repeat center`,
  },
  outcome: {
    background: `url(${theme.icons.outcome}) no-repeat center`,
  },
  adjustement: {
    background: `url(${theme.icons.adjustment}) no-repeat center`,
  },
}));

export default ({ value }) => {
  const classes = useStyle();
  let customClass;

  switch (value) {
    case 3:
      customClass = classes.adjustement;
      break;
    case 2:
      customClass = classes.outcome;
      break;

    case 1:
      customClass = classes.income;
      break;
    default:
      customClass = classes.income;
  }
  return <span className={`${classes.icon} ${customClass}`} />;
};
