/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { editSupplier } from '../../../../../actions/supplier';
import AutoComplete, {
  AutoCompleteOption,
} from '../../../../../components/ui/FormInput/AutoComplete';
import AuthorizedApiClient from '../../../../../core/common/api/AuthorizedApiClient';
import { useSession } from '../../../../../util/session';
import { PERMISSION, RESOURCES } from '../../../../../constants/common';
import { clearAddedSupplier } from '../../../../../actions/product';
import SupplierForm from '../../SuppliersPage/SupplierForm';

export default ({ handleOnChange }) => {
  const { company } = useSession();
  const [loading, setLoading] = useState(false);
  const [suppliers, setSuppliers] = useState([]);
  const dispatch = useDispatch();
  const { t } = useTranslation(['products', 'suppliers']);
  const { can } = useSession();
  const { addedSupplier } = useSelector((state: any) => state.product_supplier);

  const _getSuppliers = useCallback(async (companyId, _params: any = {}) => {
    const sg = new AuthorizedApiClient();
    const path = `companies/${companyId}/suppliers`;
    const params = {
      per_page: 20,
    };

    if (_params.filter) {
      params['filters'] = { name: _params.filter };
    }
    return sg.get(params, path);
  }, []);

  const _loadSuppliers = useCallback(
    async (_params = {}) => {
      if (company) {
        setLoading(true);
        try {
          const response = await _getSuppliers(company.id, _params);

          if (response != null) {
            const suppliers = response.data.data.map(c => ({
              value: c.id,
              label: c.name,
            } as AutoCompleteOption));

            setSuppliers(suppliers);

            setLoading(false);
          }
        } catch (error) {
          setSuppliers([]);
          setLoading(false);
        }
      }
    },
    [company, _getSuppliers],
  );
  const _handleCreateSupplier = () => {
    dispatch(editSupplier());
  };

  const _handleOnChange = useCallback(
    (value) => {
      handleOnChange({
        target: {
          name: 'supplier_id',
          value: value || null,
          customName: 'selectedSupplier',
        },
      });
    },
    [handleOnChange],
  );

  useEffect(() => {
    const a = async () => {
      if (addedSupplier !== null && !loading) {
        await _loadSuppliers({ filter: addedSupplier.name });
        _handleOnChange({ value: addedSupplier.id, label: addedSupplier.name });

        dispatch(clearAddedSupplier());
      }
    };
    a();
  }, [
    company,
    suppliers,
    addedSupplier,
    _loadSuppliers,
    loading,
    dispatch,
    _handleOnChange,
  ]);

  return (
    <>
      <AutoComplete
        getData={_loadSuppliers}
        handleOnChange={_handleOnChange}
        options={suppliers}
        loading={loading}
        label={t('suppliers:supplier')}
        creatable={can(RESOURCES.suppliers, PERMISSION.create)}
        handleCreate={_handleCreateSupplier}
        createNewLabel={t('products:create_supplier')}
      />

      {can(RESOURCES.suppliers, PERMISSION.create) && (
        <SupplierForm source={2} />
      )}
    </>
  );
};
