import { Typography } from '@mui/material';
import React from 'react';

interface Props {
  title: string;
}

const SectionTitle = ({ title }: Props) => (
  <Typography variant="h6" align="center" color="textSecondary">
    {title}
  </Typography>
);

export default SectionTitle;
