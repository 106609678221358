/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyle = makeStyles(theme => ({
  container: {
    margin: '20px 5px',
    border: '2px solid #CCC',
    borderRadius: 15,
    padding: '20px 33px',
    position: 'relative',
  },
  tittle: {
    color: '#757575',
    position: 'absolute',
    fontSize: 19,
    top: -14,
    backgroundColor: theme.colors.white,
  },
  tittleCol1: {
    color: theme.colors.grey,
    position: 'absolute',
    fontSize: 13,
    top: -9,
    left: '13%',
    backgroundColor: theme.colors.white,
  },
  tittleCol2: {
    color: theme.colors.grey,
    position: 'absolute',
    fontSize: 13,
    top: -9,
    left: '45%',
    backgroundColor: theme.colors.white,
  },
}));

export default (props) => {
  const classes = useStyle();

  if (props.type === 'columns') {
    return (
      <div className={classes.container}>
        <div className={classes.tittleCol1}>{props.tittle}</div>
        <div className={classes.tittleCol2}>{props.tittle2}</div>
        <div>{props.children}</div>
      </div>
    );
  }
  return (
    <div className={classes.container}>
      <div className={classes.tittle}>{props.tittle}</div>
      <div>{props.children}</div>
    </div>
  );
};
