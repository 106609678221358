import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../components/ui/Button';
import SuccessButton from '../../../../../components/ui/Buttons/SuccessButton';
import Grid from '../../../../../components/ui/Layout/Grid';

interface ActionsProps {
  handleClose: Function;
  handleSubmit: Function;
  resetProcess: Function;
  processing: boolean;
  enabled: boolean;
  step?: number;
}

export default ({
  handleSubmit,
  processing,
  enabled,
  step = 1,
  resetProcess,
  handleClose,
}: ActionsProps) => {
  const { t } = useTranslation();
  return (
    <Grid container>
      <Grid container item xs={6}>
        {(step < 3 && !processing) && (
          <Button onClick={handleClose} text={t('general:cancel')} />
        )}
        {(step === 3 && !processing) && (
          <Button onClick={handleClose} text={t('general:close')} />
        )}
      </Grid>

      <Grid container item xs={6} justifyContent="flex-end">
        {step === 2 && enabled && (
          <SuccessButton
            processing={processing ? 1 : 0}
            onClick={handleSubmit}
            color="primary"
            text={t('general:products_excel:send_products')}
          />
        )}
        {step === 3 && (
          <SuccessButton
            processing={processing ? 1 : 0}
            onClick={resetProcess}
            color="primary"
            text={t('general:products_excel:upload_another_file')}
          />
        )}
      </Grid>
    </Grid>
  );
};
