/* eslint-disable prefer-const */
import { types } from './types';
import Log from '../../util/Log';
import AuthorizedApiClient from '../../core/common/api/AuthorizedApiClient';

export const editPassword = () => ({
  type: types.EDIT_PASSWORD,
});

export const closeModalPassword = () => ({
  type: types.CLOSE_PASSWORD_MODAL,
});

export const savingPassword = () => ({
  type: types.SAVING_PASSWORD,
});

export const savePasswordSucceed = response => ({
  type: types.SAVE_PASSWORD_SUCCESS,
  response,
});

export const savePasswordFailed = error => ({
  type: types.SAVE_PASSWORD_FAILURE,
  error,
});

export const savePassword = data => async (dispatch: any) => {
  dispatch(savingPassword());
  const sg = new AuthorizedApiClient();
  const path = 'user/password';
  try {
    let response;
    response = await sg.post(data, path);

    if (response != null) {
      Log.debug('saved password', response);
      dispatch(savePasswordSucceed(response));
    }
  } catch (error) {
    Log.error(error.message);
    dispatch(savePasswordFailed(error.response.data));
  }
};
