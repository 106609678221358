/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/destructuring-assignment */
import React, {
  useCallback, useEffect, useState,
} from 'react';
import MUIDataTable from 'mui-datatables';
import { Paper } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Waypoint } from 'react-waypoint';
import { makeStyles } from '@mui/styles';
import Loader from './Loader';

const useStyle = makeStyles(theme => ({
  root: {
    position: 'relative',
  },
  '@global': {
    '.MuiTableCell-head': {
      color: theme.palette.secondary.dark,
    },
    '.MuiTableCell-body': {
      color: theme.palette.secondary.dark,
      fontWeight: 'normal',
    },
    '.MuiTablePagination-actions': {
      marginLeft: '0',
    },
    '.MuiChip-root': {
      display: 'none',
    },
  },
}));

const defaultOptions = {
  filter: false,
  search: false,
  print: false,
  download: false,
  viewColumns: false,
  filterType: 'dropdown',
  fixedHeader: true,
  selectableRows: 'none',
  pagination: false,
  onRowClick() {
  },
  serverSide: true,

  textLabels: {
    body: {
      noMatch: '',
    },
  },
};

interface TableProps {
  loadData: Function;
  resetData: Function;
  data: Array<any>;
  columns: Array<any>;
  processing: boolean;
  options?: object;
  hasNextPage: boolean;
  currentPage: number;
}

export default (props: TableProps) => {
  const [initial, setInitial] = useState(true);
  const companyId = useSelector((state: any) => state.session.companyId);
  const classes = useStyle();

  const {
    columns,
    data,
    loadData,
    resetData,
    hasNextPage,
    currentPage,
    processing,
  } = props;

  const options = { ...defaultOptions, ...props.options };
  const dispatch = useDispatch();

  const { customRenderer } = columns[0];

  columns[0].options = {
    ...columns[0].options,
    customBodyRender: (value, tableMeta) => {
      const { rowIndex } = tableMeta;

      if (rowIndex === data.length - 1) {
        return (
          <Waypoint onEnter={loadMoreData}>
            <div>{customRenderer ? customRenderer(value) : value}</div>
          </Waypoint>
        );
      }
      return customRenderer ? customRenderer(value) : value;
    },
  };

  const loadMoreData = useCallback(async () => {
    if (hasNextPage && !processing) {
      await loadData({ page: currentPage + 1 });
    }
  }, [currentPage, hasNextPage, loadData, processing]);

  useEffect(() => {
    const a = async () => {
      if (initial && companyId != null) {
        setInitial(false);

        await loadData({ page: 1 });
      }
    };

    a();
  }, [companyId, loadData, initial]);

  useEffect(() => {
    if (resetData) {
      const a = async () => {
        await dispatch(resetData());
      };
      a();
      return () => {
        dispatch(resetData());
      };
    }
  }, [dispatch, resetData]);

  return (
    <Paper className={classes.root}>
      <MUIDataTable data={data} columns={columns} options={options} />
      {processing && <Loader />}
    </Paper>
  );
};
