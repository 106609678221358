import { types } from '../../actions/brand/types';

export const initialState = {
  brand: {
    id: null, name: '', description: '', image: '',
  },
  error: null,
  showModal: false,
  processing: false,
  deletingBrand: false,
  refresh: false,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case types.EDIT_BRAND:
      return {
        ...state,
        showModal: true,
        brand:
          action.payload !== null ? action.payload : initialState.brand,
      };
    case types.CLOSE_BRAND_MODAL:
      return initialState;
    case types.SAVE_BRAND_SUCCESS:
      return {
        ...state,
        response: action.response,
        error: null,
        processing: false,
        refresh: true,
      };
    case types.SAVE_BRAND_FAILURE:
      return {
        ...state,
        error: action.error,
        processing: false,
      };
    case types.SAVING_BRAND:
      return {
        ...state,
        error: null,
        processing: true,
      };

    case types.DELETING_BRAND:
      return {
        ...state,
        error: null,
        deletingBrand: true,
      };

    case types.DELETE_BRAND_SUCCESS:
      return {
        ...state,
        error: null,
        deletingBrand: false,
        refresh: true,
      };
    case types.DELETE_BRAND_FAILURE:
      return {
        ...state,
        error: action.error,
        deletingBrand: false,
      };
    case types.CLEAR_REFRESH:
      return {
        ...state,
        refresh: false,
      };
    default:
      return state;
  }
};
