/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  clearAddedCustomer,
  getCustomersForCart,
  setCustomer,
} from '../../../../../actions/cart';
import { AutoCompleteOption } from '../../../../../components/ui/FormInput/AutoComplete';
import Box from '../../../../../components/ui/Layout/Box';
import AutoCompletePlus from '../../../../../components/ui/FormInput/AutoCompletePlus';
import { editCustomer } from '../../../../../actions/customer';

export default () => {
  const { t } = useTranslation(['general']);
  const dispatch = useDispatch();
  const company = useSelector((state: any) => state.session.company);
  const {
    customer, customers, loadingCustomers, addedCustomer,
  } = useSelector(
    (state: any) => state.cart,
  );

  const [customersOptions, setCustomersOptions] = useState([]);

  const _getCustomers = useCallback(
    (_params) => {
      if (company) {
        const params = { filters: {} };
        if (_params.filter) {
          params.filters = { name: _params.filter };
        }
        dispatch(getCustomersForCart(company.id, params));
      }
    },
    [dispatch, company],
  );

  useEffect(() => {
    let _cs = [];
    if (customers.length > 0) {
      _cs = customers.map(c => ({
        value: c.id,
        label: `${c.name} ${c.last_name}`,
      } as AutoCompleteOption));
    }

    setCustomersOptions(_cs);

    if (customers.length && addedCustomer != null) {
      dispatch(
        setCustomer({
          value: addedCustomer.id,
          label: `${addedCustomer.name} ${addedCustomer.last_name}`,
        }),
      );
      dispatch(clearAddedCustomer());
    }
  }, [dispatch, customers, addedCustomer]);

  const handleCustomerChange = (value) => {
    dispatch(setCustomer(value));
  };

  const handleCreate = (value) => {
    // console.log("value", value)
    dispatch(editCustomer({ name: value }));
  };

  useEffect(() => {
    if (addedCustomer !== null && !loadingCustomers) {
      _getCustomers({ filter: addedCustomer.name });
    }
  }, [addedCustomer, _getCustomers, loadingCustomers]);

  return (
    <Box width="150px">
      <AutoCompletePlus
        loading={loadingCustomers}
        getData={_getCustomers}
        defaultValue={
          customer != null && customer.value ? customer.value : null
        }
        label={t('general:point_of_sell:client')}
        handleOnChange={handleCustomerChange}
        options={customersOptions}
        value={customer}
        handleCreate={handleCreate}
        addStartLabel={t('general:create')}
      />
    </Box>
  );
};
