import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { editUser } from '../../../../../actions/user';
import AddButton from '../../../../../components/ui/Buttons/BlueButton/AddButton';
import Box from '../../../../../components/ui/Layout/Box';
import UserForm from './UserForm';
import UsersList from './UsersList';

import { useSession } from '../../../../../util/session';
import { FEATURES_TYPE, PERMISSION, RESOURCES } from '../../../../../constants/common';
import { ModalGetPremium } from '../../../../../components/ModalGetPremium';

export default () => {
  const { t } = useTranslation(['general']);
  const dispatch = useDispatch();
  const openNewUser = () => {
    dispatch(editUser());
  };

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const { can } = useSession();

  const canGetMoreUsers = can(
    RESOURCES.pos_users,
    PERMISSION.access,
    FEATURES_TYPE.limits,
  );

  return (
    <>
      <Box mt={3}>
        { canGetMoreUsers
          ? (
            <AddButton onClick={openNewUser}>
              {t('general:settings:create_user')}
            </AddButton>
          )
          : (
            <AddButton onClick={handleOpen}>
              {t('general:settings:create_user')}
            </AddButton>
          )}
      </Box>

      <ModalGetPremium open={open} setOpen={setOpen} />

      <Box mt={2}>
        {' '}
        <UsersList />
      </Box>
      <UserForm />
    </>
  );
};
