import React from 'react';
import { useTranslation } from 'react-i18next';
import MovementsList from './MovementsList';
import SectionListContainer from '../../../../hoc/SectionListContainer';
import Grid from '../../../../components/ui/Layout/Grid';
import PageContainer from '../../../../hoc/PageContainer';
import AddButton from '../../../../components/ui/Buttons/BlueButton/AddButton';
import Box from '../../../../components/ui/Layout/Box';
import MovementForm from '../../../../components/MovementForm';
import { useSession } from '../../../../util/session';
import { PERMISSION, RESOURCES } from '../../../../constants/common';

type MovementProps = {
  openNewMovement: any;
};

export default ({ openNewMovement }: MovementProps) => {
  const { t } = useTranslation('general');
  const { can } = useSession();
  return (
    <>
      <MovementForm />
      <Grid container>
        <PageContainer>
          {can(RESOURCES.movements, PERMISSION.create) && (
            <Box mt={3}>
              <AddButton onClick={openNewMovement}>
                {t('general:create_movement')}
              </AddButton>
            </Box>
          )}
          <SectionListContainer>
            <MovementsList />
          </SectionListContainer>
        </PageContainer>
      </Grid>
    </>
  );
};
