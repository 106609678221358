import React from 'react';
import Box from '../../../../components/ui/Layout/Box';
import Grid from '../../../../components/ui/Layout/Grid';
import PageContainer from '../../../../hoc/PageContainer';
import SectionListContainer from '../../../../hoc/SectionListContainer';
import BestSeller from './BestSeller';
import { BestSellerProvider } from './BestSeller/context/BestSellerContext';
import KPI from './KPI';
import { KPIDashboardProvider } from './KPI/context/KPIDashboardContext';
import SalesSpeedoMeter from './SalesSpeedoMeter';
import SalesPerMonth from './SalesPerMonth';
import { SalesPerMonthProvider } from './SalesPerMonth/context/SalesPerMonthContext';
import SalesPaymentMethods from './SalesPaymentMethods';
import { SalesPaymentMethodsProvider } from './SalesPaymentMethods/context/SalesPaymentMethodsContext';
import TitleBar from './TitleBar';
import { DashboardProvider } from './context/DashboardContext';
import { SalesPerMetric } from './SalesPerMetric';

export const EcubiDashboardPage = () => (
  <PageContainer>
    <SectionListContainer>
      <DashboardProvider>
        <KPIDashboardProvider>
          <Box mb={2}>
            <TitleBar />
          </Box>
          <Box mt={4}>
            <KPI />
          </Box>

          <Box mt={4}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12}>
                <SalesPerMonthProvider>
                  <SalesPerMonth />
                </SalesPerMonthProvider>
              </Grid>
            </Grid>
            <Grid container alignItems="center" direction="column" spacing={1} style={{ margin: '2.5rem 0px' }}>
              <SalesPerMetric />
            </Grid>
          </Box>
          <Box mt={3}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <SalesSpeedoMeter />
              </Grid>

              <Grid item xs={12} sm={6}>
                <SalesPaymentMethodsProvider>
                  <SalesPaymentMethods />
                </SalesPaymentMethodsProvider>
              </Grid>
            </Grid>
          </Box>
          <Box mt={3}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12}>
                <BestSellerProvider>
                  <BestSeller />
                </BestSellerProvider>
              </Grid>
            </Grid>
          </Box>
        </KPIDashboardProvider>
      </DashboardProvider>
    </SectionListContainer>
  </PageContainer>
);
