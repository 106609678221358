/* eslint-disable @typescript-eslint/naming-convention */
import { Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import Box from '../ui/Layout/Box';
import Grid from '../ui/Layout/Grid';
import BusinessTypeSection from './BusinessTypeSection';
import CategoriesSection from './CategoriesSection';
import CustomerTypeSection from './CostumerTypeSection';
import Alert from '../ui/notifications/alert';
import FoodSection from './FoodSection';
import InvoicesSection from './InvoicesSection';
import KeywordsSection from './KeywordsSection';
import LogisticSection from './LogisticSection';
import ProductionVolumeSection from './ProductionVolumeSection';
import { WizardSteps } from './steps';
import BranchSection from './BranchSection';

const useStyle = makeStyles(theme => ({
  container: {
    background: '#ffffff 0% 0% no-repeat padding-box',
    boxShadow: theme.shadow.main,
    border: '1px solid #C0C3CA',
  },
}));

const WizardView = () => {
  const classes = useStyle();
  const { t } = useTranslation(['general']);

  const { step, error, business_type } = useSelector(
    (state: any) => state.wizard,
  );

  return (
    <Grid
      container
      justifyContent="center"
      direction="row"
      alignItems="center"
      style={{ minHeight: '100vh' }}
    >
      <Grid item xs={10} md={6}>
        {business_type && (
          <Box mb={2}>
            <Typography variant="h5" align="center">
              {t(`general:business_type:${business_type}`)}
            </Typography>
          </Box>
        )}
        <Box
          p={0}
          pt={4}
          pb={4}
          pl={2}
          pr={2}
          minHeight="300px"
          maxWidth="480px"
          className={classes.container}
          marginLeft="auto"
          marginRight="auto"
        >
          {error && (
            <Box widt={1}>
              <Alert
                severity="error"
                message={t(`wizard_setup:${error}`, error)}
              />
            </Box>
          )}
          {step === WizardSteps.FOOD && <FoodSection />}
          {step === WizardSteps.BUSINESS_TYPE && <BusinessTypeSection />}
          {step === WizardSteps.CATEGORIES && <CategoriesSection />}
          {step === WizardSteps.PRODUCTION_VOLUME && (
            <ProductionVolumeSection />
          )}
          {step === WizardSteps.CUSTOMER_TYPES && <CustomerTypeSection />}
          {step === WizardSteps.LOGISTIC && <LogisticSection />}
          {step === WizardSteps.INVOICES && <InvoicesSection />}
          {(step === WizardSteps.KEYWORDS) && (
            <KeywordsSection />
          )}
          {step === WizardSteps.BRANCH && <BranchSection />}
        </Box>
      </Grid>
    </Grid>
  );
};

export default WizardView;
