const downloadFileInBrowser = (response, fileName) => {
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName); // or any other extension
  document.body.appendChild(link);
  link.click();
  document.removeChild(link);
};

const fileDownloader = {
  downloadFileInBrowser,
};

export default fileDownloader;
