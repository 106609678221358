import React from 'react';

import { makeStyles } from '@mui/styles';

import AppBar from '../components/AppBar';
import Drawer from '../components/Drawer';

const drawerWidth = 300;

const styles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  toolbar: theme.mixins.toolbar,
  content: {
    backgroundColor: '#FFF5E3',
    padding: 35,
  },
  layout: {
    [theme.breakpoints.up('sm')]: { width: `calc(100% - ${drawerWidth}px)`, marginLeft: drawerWidth },
  },
}));

const NewMenu = () => {
  const classes = styles();

  const [stateOpen, setOpen] = React.useState(false);
  const [sizeContent, setSizeContent] = React.useState(null);

  function MenuToogle() {
    setOpen(!stateOpen);
    if (stateOpen === false) {
      setSizeContent(classes.layout);
    } else {
      setSizeContent(classes.content);
    }
  }

  return (
    <div className={classes.root}>
      <AppBar
        toogleClick={MenuToogle}
      />
      <Drawer
        variant="persistent"// permanent default
        open={stateOpen}
        onClose={MenuToogle}
      />
      <div className={[`${classes.content} ${sizeContent}`].join(' ')}>
        <div className={classes.toolbar} />
        <h1>
          Content
        </h1>
      </div>
    </div>
  );
};

export default NewMenu;
