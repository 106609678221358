/* eslint-disable react/prop-types */
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useDispatch } from 'react-redux';
import { setSearchName } from '../../../../../actions/search';
import SwitchList from '../../../../../components/inputs/SwitchList';
import InputSearch from '../../../../../components/ui/InputSearch';
import images from '../../../../../theme/ECTheme/assets/images';

const styles = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    margin: '15px 5px',
  },
  barcode: {
    display: 'block',
    width: 25,
    height: 25,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundImage: `url(${images.Icon_Code})`,
  },
}));

export default ({ switchViewClick, switchView }) => {
  const classes = styles();
  const dispatch = useDispatch();

  const HandleSetSearch = (value) => {
    dispatch(setSearchName(value));
  };

  return (
    <Grid className={classes.container}>
      <InputSearch
        setSearchValue={HandleSetSearch}
        placeholder="Buscar..."
      />
      <div style={{ flexGrow: 1, paddingLeft: 15 }}>
        <span className={classes.barcode} />
      </div>
      <SwitchList
        onChange={switchViewClick}
        value={switchView}
      />
    </Grid>
  );
};
