/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable max-len */
/* eslint-disable no-multiple-empty-lines */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/prop-types */
import {
  Button, Modal, Switch, TextField, Typography,
} from '@mui/material';
import Axios from 'axios';
import React, { useState } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import { makeStyles } from '@mui/styles';
import { BASE_URL } from '../../constants/api/ApiConstants';
import Box from '../ui/Layout/Box';

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: 500,
    height: 650,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #878787',
    borderRadius: 5,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  centeredItems: {
    margin: 20,
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  mainText: {
    color: 'black',
  },
  icon: {
    width: 35,
    marginBlock: 10,
  },
  root: {
    width: '100%',
    display: 'felx',
    flexDirection: 'column',
    justifyContent: 'spaceBetween',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  containerCard: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gap: 10,
    marginBottom: 25,
  },
}));

export const ModalBusinessHours = ({ open, setOpen, company }) => {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [scheduleServices, setScheduleServices] = useState(
    company.service_schedule?.map(day => ({
      ...day,
      status: true,
    })) || [],
  );

  const handleChangeEventStartDay = (event: React.ChangeEvent<{ value: string }>, weekDay: number) => {
    const [scheduleDay] = scheduleServices.filter(day => day.day === weekDay);
    const rest = scheduleServices.filter(day => day.day !== weekDay);
    scheduleDay.schedule[0].start_hour = event.target.value.replace(':', '');
    // const daysTrue = dateState.filtre(day => day === true )
    setScheduleServices([
      ...rest,
      scheduleDay,
    ]);
  };

  const handleChangeschedule = (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    const service_schedule = scheduleServices
      .filter(day => day.status)
      .map(({ day, schedule }) => ({ day, schedule }));

    Axios.put(`${BASE_URL}/api/v1/companies/${company.id}`, { service_schedule })
      .catch(err => console.error(err))
      .finally(() => {
        setOpen(false);
      });
  };

  const handleChangeEventEndDay = (event: React.ChangeEvent<{ value: string }>, weekDay: number) => {
    const [scheduleDay] = scheduleServices.filter(day => day.day === weekDay);
    const rest = scheduleServices.filter(day => day.day !== weekDay);
    scheduleDay.schedule[0].end_hour = event.target.value.replace(':', '');

    setScheduleServices([
      ...rest,
      scheduleDay,
    ]);
  };

  function getModalStyle() {
    return {
      top: `${50}%`,
      left: `${50}%`,
      transform: `translate(-${50}%, -${50}%)`,
    };
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, weekDay: number) => {
    let [scheduleDay] = scheduleServices.filter(day => day.day === weekDay);
    const rest = scheduleServices.filter(day => day.day !== weekDay);
    if (!scheduleDay) {
      scheduleDay = {
        day: weekDay,
        schedule: [{
          end_hour: '00:00',
          start_hour: '00:00',
        }],
      };
    }
    scheduleDay.status = event.target.checked;
    setScheduleServices([...rest, scheduleDay]);
  };

  const body = (

    <div className={classes.root}>
      <div className={classes.containerCard}>
        <FormControlLabel
          style={{ display: 'flex', justifyContent: 'space-around' }}
          control={(
            <Switch
              checked={scheduleServices.filter(day => day.day === 1)[0]?.status}
              onChange={e => handleChange(e, 1)}
              name="monday"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
              color="primary"
            />
                      )}
          label="Lunes"
          labelPlacement="start"
        />
        <TextField
          variant="standard"
          disabled={!scheduleServices.filter(day => day.day === 1)[0]?.status}
          id="start_hour"
          label="abre a la(S)"
          type="time"
          defaultValue={scheduleServices.filter(day => day.day === 1)[0]?.schedule[0].start_hour.replace(/(\d{2})/, '$1:')}
          className={classes.textField}
          onChange={e => handleChangeEventStartDay(e, 1)}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            step: 60,
          }}
        />
        <TextField
          variant="standard"
          disabled={!scheduleServices.filter(day => day.day === 1)[0]?.status}
          id="end_hour"
          label="cierra a la(S)"
          type="time"
          defaultValue={scheduleServices.filter(day => day.day === 1)[0]?.schedule[0].end_hour.replace(/(\d{2})/, '$1:')}
          onChange={e => handleChangeEventEndDay(e, 1)}
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            step: 60,
          }}
        />
      </div>
      <div className={classes.containerCard}>
        <FormControlLabel
          style={{ display: 'flex', justifyContent: 'space-around' }}
          control={(
            <Switch
              checked={scheduleServices.filter(day => day.day === 2)[0]?.status}
              onChange={e => handleChange(e, 2)}
              name="tuesday"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
              color="primary"
            />
                      )}
          label="Martes"
          labelPlacement="start"
        />
        <TextField
          variant="standard"
          disabled={!scheduleServices.filter(day => day.day === 2)[0]?.status}
          id="start_hour"
          label="abre a la(S)"
          type="time"
          defaultValue={scheduleServices.filter(day => day.day === 2)[0]?.schedule[0].start_hour.replace(/(\d{2})/, '$1:')}
          onChange={e => handleChangeEventStartDay(e, 2)}
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            step: 60,
          }}
        />
        <TextField
          variant="standard"
          disabled={!scheduleServices.filter(day => day.day === 2)[0]?.status}
          id="end_hour"
          label="cierra a la(S)"
          type="time"
          defaultValue={scheduleServices.filter(day => day.day === 2)[0]?.schedule[0].end_hour.replace(/(\d{2})/, '$1:')}
          onChange={e => handleChangeEventEndDay(e, 2)}
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            step: 60,
          }}
        />
      </div>

      <div className={classes.containerCard}>
        <FormControlLabel
          style={{ display: 'flex', justifyContent: 'space-around' }}
          control={(
            <Switch
              checked={scheduleServices.filter(day => day.day === 3)[0]?.status}
              onChange={e => handleChange(e, 3)}
              name="wednesday"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
              color="primary"
            />
                      )}
          label="Miercoles"
          labelPlacement="start"
        />
        <TextField
          variant="standard"
          disabled={!scheduleServices.filter(day => day.day === 3)[0]?.status}
          id="start_hour"
          label="abre a la(S)"
          type="time"
          defaultValue={scheduleServices.filter(day => day.day === 3)[0]?.schedule[0].start_hour.replace(/(\d{2})/, '$1:')}
          onChange={e => handleChangeEventStartDay(e, 3)}
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            step: 60,
          }}
        />
        <TextField
          variant="standard"
          disabled={!scheduleServices.filter(day => day.day === 3)[0]?.status}
          id="end_hour"
          label="cierra a la(S)"
          type="time"
          defaultValue={scheduleServices.filter(day => day.day === 3)[0]?.schedule[0].end_hour.replace(/(\d{2})/, '$1:')}
          onChange={e => handleChangeEventEndDay(e, 3)}
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            step: 60,
          }}
        />
      </div>
      <div className={classes.containerCard}>
        <FormControlLabel
          style={{ display: 'flex', justifyContent: 'space-around' }}
          control={(
            <Switch
              checked={scheduleServices.filter(day => day.day === 4)[0]?.status}
              onChange={e => handleChange(e, 4)}
              name="thursday"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
              color="primary"
            />
                      )}
          label="Jueves"
          labelPlacement="start"
        />
        <TextField
          variant="standard"
          disabled={!scheduleServices.filter(day => day.day === 4)[0]?.status}
          id="start_hour"
          label="abre a la(S)"
          type="time"
          defaultValue={scheduleServices.filter(day => day.day === 4)[0]?.schedule[0].start_hour.replace(/(\d{2})/, '$1:')}
          onChange={e => handleChangeEventStartDay(e, 4)}
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            step: 60,
          }}
        />
        <TextField
          variant="standard"
          disabled={!scheduleServices.filter(day => day.day === 4)[0]?.status}
          id="end_hour"
          label="cierra a la(S)"
          type="time"
          defaultValue={scheduleServices.filter(day => day.day === 4)[0]?.schedule[0].end_hour.replace(/(\d{2})/, '$1:')}
          onChange={e => handleChangeEventEndDay(e, 4)}
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            step: 60,
          }}
        />
      </div>
      <div className={classes.containerCard}>
        <FormControlLabel
          style={{ display: 'flex', justifyContent: 'space-around' }}
          control={(
            <Switch
              checked={scheduleServices.filter(day => day.day === 5)[0]?.status}
              onChange={e => handleChange(e, 5)}
              name="friday"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
              color="primary"
            />
                      )}
          label="Viernes"
          labelPlacement="start"
        />
        <TextField
          variant="standard"
          disabled={!scheduleServices.filter(day => day.day === 5)[0]?.status}
          id="start_hour"
          label="abre a la(S)"
          type="time"
          defaultValue={scheduleServices.filter(day => day.day === 5)[0]?.schedule[0].start_hour.replace(/(\d{2})/, '$1:')}
          onChange={e => handleChangeEventStartDay(e, 5)}
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            step: 60,
          }}
        />
        <TextField
          variant="standard"
          disabled={!scheduleServices.filter(day => day.day === 5)[0]?.status}
          id="end_hour"
          label="cierra a la(S)"
          type="time"
          defaultValue={scheduleServices.filter(day => day.day === 5)[0]?.schedule[0].end_hour.replace(/(\d{2})/, '$1:')}
          onChange={e => handleChangeEventEndDay(e, 5)}
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            step: 60,
          }}
        />
      </div>
      <div className={classes.containerCard}>
        <FormControlLabel
          style={{ display: 'flex', justifyContent: 'space-around' }}
          control={(
            <Switch
              checked={scheduleServices.filter(day => day.day === 6)[0]?.status}
              onChange={e => handleChange(e, 6)}
              name="saturday"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
              color="primary"
            />
                      )}
          label="Sábado"
          labelPlacement="start"
        />
        <TextField
          variant="standard"
          disabled={!scheduleServices.filter(day => day.day === 6)[0]?.status}
          id="start_hour"
          label="abre a la(S)"
          type="time"
          defaultValue={scheduleServices.filter(day => day.day === 6)[0]?.schedule[0].start_hour.replace(/(\d{2})/, '$1:')}
          onChange={e => handleChangeEventStartDay(e, 6)}
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            step: 60,
          }}
        />
        <TextField
          variant="standard"
          disabled={!scheduleServices.filter(day => day.day === 6)[0]?.status}
          id="end_hour"
          label="cierra a la(S)"
          type="time"
          defaultValue={scheduleServices.filter(day => day.day === 6)[0]?.schedule[0].end_hour.replace(/(\d{2})/, '$1:')}
          onChange={e => handleChangeEventEndDay(e, 6)}
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            step: 60,
          }}
        />
      </div>
      <div className={classes.containerCard}>
        <FormControlLabel
          style={{ display: 'flex', justifyContent: 'space-around' }}
          control={(
            <Switch
              checked={scheduleServices.filter(day => day.day === 0)[0]?.status}
              onChange={e => handleChange(e, 0)}
              name="sunday"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
              color="primary"
            />
                      )}
          label="Domingo"
          labelPlacement="start"
        />
        <TextField
          variant="standard"
          disabled={!scheduleServices.filter(day => day.day === 0)[0]?.status}
          id="start_hour"
          label="abre a la(S)"
          type="time"
          defaultValue={scheduleServices.filter(day => day.day === 0)[0]?.schedule[0].start_hour.replace(/(\d{2})/, '$1:')}
          onChange={e => handleChangeEventStartDay(e, 0)}
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            step: 60,
          }}
        />
        <TextField
          variant="standard"
          disabled={!scheduleServices.filter(day => day.day === 0)[0]?.status}
          id="end_hour"
          label="cierra a la(S)"
          type="time"
          defaultValue={scheduleServices.filter(day => day.day === 0)[0]?.schedule[0].end_hour.replace(/(\d{2})/, '$1:')}
          onChange={e => handleChangeEventEndDay(e, 0)}
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            step: 60,
          }}
        />
      </div>
    </div>
  );

  return (
    <Modal
      open={open}
      onClose={handleClose}
      disableEnforceFocus
      disableAutoFocus
    >
      <Box
        className={classes.paper}
        style={modalStyle}
      >
        <form onSubmit={handleChangeschedule}>
          {body}
          <Box
            fullWidth
            display="flex"
            justifyContent="flex-end"
            padding="5px"
          >
            <Button
              type="submit"
              variant="contained"
              color="primary"
            >
              Guardar
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};
