/* eslint-disable consistent-return */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import AsyncPaginatedTable from '../../../../../../components/ui/AsyncPaginatedTable';
import FormattedNumber from '../../../../../../components/ui/FormattedNumber';
import Box from '../../../../../../components/ui/Layout/Box';
import Grid from '../../../../../../components/ui/Layout/Grid';
import ProductSelector from '../../../../../../components/ui/ProductSelector';
import { useSession } from '../../../../../../util/session';

export default ({
  state, getProducts, resetProducts, downloadProductsExcel,
}) => {
  const { t } = useTranslation(['general']);
  // const [productFilter, setProductFilter] = useState("");
  const { data } = state.response;
  const { company } = useSession();
  // const onResetFilters = () => {
  //   setProductFilter("");
  // };

  // const _setProduct = (product) => {
  //   console.log("product", product);
  // };

  const columns = [
    {
      name: 'variant',
      label: t('general:product'),
      options: {
        filter: true,
        filterType: 'custom',
        filterOptions: {
          display: (filterList, onChange, index, column, filterData) => (

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ProductSelector
                  company_id={company ? company.id : ''}
                  setProduct={(product) => {
                    if (product) {
                      filterList[index][0] = product ? product.value : '';
                      onChange(filterList[index], index, column, filterData);
                    }
                  }}
                />
              </Grid>
            </Grid>

          ),
        },
        sort: false,
        customFilterListOptions: {
          render: (v) => {
            if (Array.isArray(v)) {
              const row = data.find(inventory => inventory.variant.product.id === v[0]);
              if (row) {
                return [row.variant.product.name];
              }
            } else {
              return [];
            }
          },
        },

        customBodyRender: (variant) => {
          if (variant && variant.product) {
            return variant.product.name;
          }
          return 'Hola';
        },
      },
    },

    {
      name: 'location',
      label: t('general:branch'),

      options: {
        filter: false,
        sort: false,
        customBodyRender: (location) => {
          if (location && location.branch) {
            return location.branch.name;
          }
          return '';
        },
      },
    },
    {
      name: 'location',
      label: t('general:location'),

      options: {
        filter: false,
        sort: false,
        customBodyRender: (location) => {
          if (location) {
            return location.name;
          }
          return '';
        },
      },
    },
    {
      name: 'stock',
      label: t('general:quantity'),

      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          if (value) {
            return <FormattedNumber value={value} />;
          }
          return <FormattedNumber value={0} />;
        },
      },
    },
  ];

  // console.log("data",state.response.data )

  return (
    <Box>
      <Box mt={3}>
        <AsyncPaginatedTable
          hasNextPage={state.response.page < state.response.last_page}
          currentPage={state.response.page}
          perPage={state.response.per_page}
          data={data}
          columns={columns}
          loadData={params => getProducts(params)}
          resetData={resetProducts}
          processing={state.processing}
          total={state.response.total}
          options={{ filter: true, downloadExcel: downloadProductsExcel }}
          filterFields={[{ field: 'product' }]}
          // onResetFilters={onResetFilters}
        />
      </Box>
    </Box>
  );
};
