import React from 'react';
import { useDispatch } from 'react-redux';
import { setProductMovement, showModalMovement } from '../../../../actions/movement';
import MovementsPageView from './MovementsPageView';

export default () => {
  const dispatch = useDispatch();
  const openNewMovement = () => {
    dispatch(setProductMovement(null));
    dispatch(showModalMovement());
  };

  return <MovementsPageView openNewMovement={openNewMovement} />;
};
