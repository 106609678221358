/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/prop-types */
import {
  Box, Grid, Typography,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import OrderStatus from '../OrderStatus';
import images from '../../../theme/ECTheme/assets/images';

const styles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '.5em 1em',
    margin: '.5em',
    border: `1px solid ${theme.colors.grey}`,
    borderRadius: 8,
  },
  itemsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  payment_method: {
    border: `1px solid ${theme.colors.grey}`,
    borderRadius: 8,
    padding: 5,
  },
  card: {
    width: '3.6em',
    height: '2em',
    margin: '.4em',
    border: `1px solid ${theme.colors.grey}`,
    borderRadius: 8,
    padding: 6,
    backgroundSize: '75%',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    boxSizing: 'border-box',
  },
  orderInformation: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4,1fr)',
    justifyItems: 'center',
    padding: '.5em',
    margin: '.5em 0px',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(2,1fr)',
      justifyItems: 'center',
      padding: '0em',
      textAlign: 'center',
      rowGap: '1.2em',
    },
  },
  visa: {
    backgroundImage: `url(${theme.icons.visa})`,
  },
  mastercard: {
    backgroundImage: `url(${theme.icons.masterCard})`,
  },
  americanexpress: {
    backgroundImage: `url(${theme.icons.americanExpress})`,
  },
  review: {
    fontSize: 14,
    cursor: 'pointer',
    padding: '.4em 4em',
    color: 'white',
    height: '100%',
    borderRadius: 20,
    backgroundColor: theme.colors.cardPurple.main,
    [theme.breakpoints.down('md')]: {
      padding: '.4em .2em',
      textAlign: 'center',
    },
  },
}));

const OrderDetails = ({
  orderStatusComponent,
  action,
  number,
  shipping_address,
  shipping_method,
  status,
  payment_method,
  payment_type,
  company,
  source,
  company_number,
  user,
}) => {
  const classes = styles();
  const { t } = useTranslation();

  return (
    <Box className={classes.container}>
      <Typography variant="h6">
        {t('general:orders:number_order')}
        {number}
      </Typography>
      {company_number && (
        <Typography variant="body2">
          <strong>
            {t('general:internal_number')}
            {' '}
            #
          </strong>
          {' '}
          {company_number}
        </Typography>
      )}
      <Box className={classes.orderInformation}>
        <Grid className={classes.itemsContainer}>
          {shipping_method === 'delivery' && shipping_address ? (
            <>
              <Typography variant="body2" style={{ fontWeight: 'bold' }}>
                {t('general:shipping_address')}
              </Typography>
              <Typography variant="body2">
                {shipping_address?.address}
              </Typography>
              {shipping_address.complement && (
                <Typography variant="body2">
                  {shipping_address?.complement}
                </Typography>
              )}
              <Typography variant="body2">{shipping_address.city}</Typography>
              {shipping_address.postal_code && (
                <Typography variant="body2">
                  {shipping_address.postal_code}
                </Typography>
              )}

              {shipping_address.phone && (
                <Typography variant="body2">
                  {t('general:phone')}
                  {': '}
                  {shipping_address.phone}
                </Typography>
              )}
            </>
          ) : (
            <Typography>
              {t(`general:shipping_methods_list:${shipping_method}`)}
            </Typography>
          )}

          {user && (
            <>
              <Typography variant="body2" style={{ fontWeight: 'bold' }}>
                {t('general:orders:order_for')}
              </Typography>
              <Typography variant="body2">
                {`${user.name} ${user.last_name}`}
              </Typography>
            </>
          )}

          {user && user.phone && (
            <Typography variant="body2">
              {t('general:phone')}
              {': '}
              {user.phone}
            </Typography>
          )}
        </Grid>
        <Grid className={classes.itemsContainer}>
          <Typography variant="body2" style={{ fontWeight: 'bold' }}>
            {t('general:orders:order_seller')}
          </Typography>
          <Typography variant="body2">{company?.name || source}</Typography>
        </Grid>
        <Grid className={classes.itemsContainer}>
          <Typography
            align="center"
            variant="body2"
            style={{ fontWeight: 'bold' }}
          >
            {t('general:orders:status')}
          </Typography>
          <Box textAlign="center" fontWeight="200" color="black">
            {orderStatusComponent || (
              <OrderStatus status={status} />
            )}
          </Box>
        </Grid>
        <Grid>
          <Grid className={classes.payment_method}>
            <Typography variant="body2" style={{ fontWeight: 'bold' }}>
              {t('general:orders:payment_method')}
            </Typography>
            {}
            {payment_type === 'card' ? (
              <Box display="flex" alignItems="center" justifyContent="center">
                <Grid
                  className={`${classes.card} ${
                    payment_method && payment_method.data && payment_method.data.brand ? classes[
                      payment_method.data.brand.replace('_', '').toLowerCase()
                    ] : ''
                  }`}
                />
                <Typography variant="body1">
                  &bull;&bull;&bull;
                  {payment_method && payment_method.data && payment_method.data.last4 ? payment_method.data.last4 : 'visa'}
                </Typography>
              </Box>
            ) : (
              <Box>
                <img
                  alt={t(`general:orders:${payment_type}`)}
                  src={images[`Icon_${payment_type.toUpperCase()}`]}
                />
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default OrderDetails;
