/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable prefer-destructuring */
/* eslint-disable default-case */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable react/no-unused-prop-types */
import React, { useEffect, useState } from 'react';
import MUIDataTable from 'mui-datatables';
import {
  Paper,
  Box,
  useTheme,
  useMediaQuery,
  Button,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import ActivityIndicator from '../ActivityIndicator';
import { getUTCDayEnd, getUTCDayStart, isValidDate } from '../../../util/dates';
import DownloadExcelButton from '../Buttons/DownloadExcelButton';

export const usePaginatedTable = () => {
  const handleViewColumnsChange = (
    changedColumn: string,
    action: string,
    visibleColumns,
    setVisibleColumns,
  ) => {
    if (action === 'add') {
      setVisibleColumns([...visibleColumns, changedColumn]);
    } else {
      setVisibleColumns(
        visibleColumns.filter(c => c.toString() !== changedColumn),
      );
    }
  };

  const isVisibleColumn = (visibleColumns, column) => visibleColumns.includes(column);

  return {
    handleViewColumnsChange,
    isVisibleColumn,
  };
};

const useStyle = makeStyles(theme => ({
  root: {
    position: 'relative',
  },
  '@global': {
    '.MuiTable-root': {
      borderCollapse: 'separate',
    },
    '.MuiTableCell-head': {
      color: theme.palette.secondary.dark,
    },
    '.MuiTableCell-body': {
      color: theme.palette.secondary.dark,
      fontWeight: 'normal',
      padding: '5px 5px',
    },
    '.MuiTablePagination-actions': {
      marginLeft: '0',
    },
    '.MuiChip-root': {
      display: 'none',
    },
  },
}));

const defaultOptions = {
  filter: false,
  search: false,
  print: false,
  download: false,
  viewColumns: false,
  filterType: 'dropdown',
  fixedHeader: false,
  selectableRows: 'none',
  pagination: true,
  onRowClick() {
  },
  serverSide: true,
  rowsPerPageOptions: [10, 20, 50],
  textLabels: {
    body: {},
    pagination: {},
    selectedRows: {},
    filter: {},
    toolbar: {},
  },
};

interface TableProps {
  columns: Array<any>;
  currentPage: number;
  data: Array<any>;
  defaultFilters?: any | {};
  filterFields?: any[];
  hasNextPage: boolean;
  loadData: Function;
  onResetFilters?: Function;
  options?: object;
  perPage: number;
  processing: boolean;
  redirectButton?: boolean;
  resetData: Function;
  searchField?: string | null;
  title?: string | '';
  total: number;
  withDispatch?: boolean | true;
}

export const useTableCellStyles = () => {
  const theme = useTheme();
  const centeredColumnStyle = {
    align: useMediaQuery(theme.breakpoints.down('lg')) ? 'left ' : 'center',
    justify: useMediaQuery(theme.breakpoints.down('lg')) ? 'left ' : 'center',
    justifyContent: useMediaQuery(theme.breakpoints.down('lg'))
      ? 'left '
      : 'center',
    alignItems: useMediaQuery(theme.breakpoints.down('lg'))
      ? 'left '
      : 'center',
    textAlign: useMediaQuery(theme.breakpoints.down('lg')) ? 'left ' : 'center',
  };

  return {
    centeredColumnStyle,
  };
};

export default (props: TableProps) => {
  const [initial, setInitial] = useState(true);
  const companyId = useSelector((state: any) => state.session.companyId);
  const { t } = useTranslation(['general']);
  const classes = useStyle();

  const {
    columns,
    data,
    loadData,
    total,
    processing,
    currentPage,
    perPage,
    filterFields = [],
    title = '',
    searchField,
    defaultFilters = {},
    redirectButton = false,
  } = props;

  defaultOptions.textLabels.pagination = {
    next: t('general:pagination:next'),
    previous: t('general:pagination:previous'),
    rowsPerPage: t('general:pagination:per_page'),
    displayRows: t('general:pagination:of'),
  };

  defaultOptions.textLabels.selectedRows = {
    text: 'fila(s) seleccionadas',
    delete: 'Borrar',
    deleteAria: 'Borrar filas seleccionadas',
  };

  defaultOptions.textLabels.filter = {
    all: t('general:filters:all'),
    title: t('general:filters:filters'),
    reset: t('general:filters:reset'),
  };

  defaultOptions.textLabels.toolbar = {
    search: t('general:tables:toolbar:search'),
    downloadCsv: t('general:tables:toolbar:downloadCsv'),
    print: t('general:tables:toolbar:print'),
    viewColumns: t('general:tables:toolbar:viewColumns'),
    filterTable: t('general:tables:toolbar:filterTable'),
  };

  const options = { ...defaultOptions, ...props.options };
  options['count'] = total;
  options['rowsPerPage'] = perPage;
  options['page'] = currentPage - 1;
  if (options['page'] < 0) {
    options['page'] = 0;
  }

  if (redirectButton) {
    options['customToolbar'] = () => (
      <Link to="/store/orders">
        <Button
          variant="contained"
          color="primary"
          style={{
            height: 20,
            borderRadius: 10,
          }}
        >
          Ver más
        </Button>
      </Link>
    );
  }

  options['onTableChange'] = (action, tableState) => {
    let filters = {};
    tableState.filterList.forEach((filter, index) => {
      if (filter[0]) {
        if (filterFields[index]) {
          const column = filterFields[index].field;
          if (filterFields[index].type === 'date_range') {
            const dates = filter[0].split('|');

            if (dates.length === 2) {
              const date1 = new Date(`${dates[0]} 00:00:00`);
              const date2 = new Date(`${dates[1]} 11:59:59`);

              if (isValidDate(date1) && isValidDate(date2)) {
                filters[column] = {
                  $gte: getUTCDayStart(date1),
                  $lte: getUTCDayEnd(date2),
                };
              }
            }
          } else if (Array.isArray(filter)) {
            filters[column] = filter[0];
          }
        } else {
          const column = tableState.columns[index].name;
          filters[column] = filter[0];
        }
      }
    });

    let page = currentPage;
    let per_page = perPage;
    let fetchData = false;
    if (tableState.searchText && searchField) {
      filters[searchField] = tableState.searchText;
    }

    switch (action) {
      case 'changePage':
        page = tableState.page + 1;
        fetchData = true;
        break;
      case 'changeRowsPerPage':
        per_page = tableState.rowsPerPage;
        page = 1;
        fetchData = true;
        break;
      case 'filterChange':
        page = 1;
        fetchData = true;
        break;
      case 'resetFilters':
        filters = defaultFilters;
        fetchData = true;
        break;
      case 'search':
        if (searchField) {
          per_page = tableState.rowsPerPage;
          page = 1;
          fetchData = true;
        }

        break;
    }
    if (fetchData) {
      loadData({ page, per_page, filters });
    }
  };

  options.textLabels.body = {
    noMatch: processing ? (
      <Box
        minHeight="30vh"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <ActivityIndicator />
      </Box>
    ) : (
      t('general:no_results')
    ),
  };

  useEffect(() => {
    const a = async () => {
      if (initial && companyId != null) {
        setInitial(false);

        await loadData({ page: 1 });
      }
    };

    a();
  }, [companyId, loadData, initial]);

  if (options['downloadExcel']) {
    const HeaderElements = () => (
      <Button style={{ order: -1, borderRadius: '50%', minWidth: '48px' }}>
        <DownloadExcelButton downloadExcel={options['downloadExcel']} />
      </Button>
    );
    options['customToolbar'] = () => <HeaderElements />;
  }

  return (
    <Paper className={classes.root}>
      <MUIDataTable
        data={processing ? [] : data}
        columns={columns}
        options={options}
        title={title}
      />
    </Paper>
  );
};
