/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BranchFormView from './BranchFormView';
import { getBranches, resetBranches } from '../../../../../actions/branches';
import {
  saveBranch,
  deleteBranch,
  closeModalBranch,
} from '../../../../../actions/branch';
import { SCROLL_PER_PAGE } from '../../../../../constants/common';
import { types } from '../../../../../actions/branch/types';
import { formatBranchData } from '../../../../../util/branches';

export default () => {
  const dispatch = useDispatch();
  const { company } = useSelector((state: any) => state.session);
  const {
    branch, error, processing, refresh, deletingBranch,
  } = useSelector(
    (state: any) => state.branch,
  );

  const _getBranches = useCallback(() => {
    dispatch(resetBranches());
    dispatch(getBranches(company.id, { page: 1, per_page: SCROLL_PER_PAGE }));
    dispatch({
      type: types.CLEAR_REFRESH,
    });
  }, [dispatch, company]);

  const attemptSaveBranch = async (data, image = null) => {
    //  if(data.lat && data.lng){
    //    data.coordinates = {
    //     lat: data.lat,
    //     lng: data.lng
    //    }
    //  }

    //  delete data.lng
    //  delete data.lat

    data = formatBranchData(data, image);

    // if (image && data.coordinates) {
    //   data.coordinates = JSON.stringify(data.coordinates);
    // }

    await dispatch(saveBranch(data, image, company.id));
  };

  const attemptDeleteBranch = async (category) => {
    await dispatch(deleteBranch(category, company.id));
  };

  useEffect(() => {
    if (refresh) {
      _getBranches();
    }
  }, [refresh, _getBranches]);

  return (
    <BranchFormView
      branch={branch}
      attemptSaveBranch={(data, image) => attemptSaveBranch(data, image)}
      attemptDeleteBranch={branch => attemptDeleteBranch(branch)}
      processing={processing}
      deletingBranch={deletingBranch}
      error={error}
      closeModalBranch={closeModalBranch}
    />
  );
};
