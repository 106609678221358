import { Alert, Link, Typography } from '@mui/material';
import { formatRFC3339 } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ActivityIndicator from '../../../../../components/ui/ActivityIndicator';
import DownoloadButton from '../../../../../components/ui/Buttons/DownoloadButton';
import ExcelUploader from '../../../../../components/ui/ExcelUploader';
import Box from '../../../../../components/ui/Layout/Box';
import { EXCEl_LIMIT_ROWS } from '../../../../../constants/common';
import { useExcelReader } from '../../../../../util/excel';
import { generateRamdomNumber } from '../../../../../util/keys';

const formatStringToRFC3339 = (stringDate) => {
  const splitDate = stringDate.split('/');
  return formatRFC3339(new Date(Number(`20${splitDate[2]}`), splitDate[1], splitDate[0]), { fractionDigits: 3 });
};

interface Props {
  setOrders(order: any[]): void;
}

export const OrdersFileExcel = ({ setOrders }: Props) => {
  const { t } = useTranslation('general');
  const { readExcel } = useExcelReader();
  const orders = [1];

  const fileHandler = async (e) => {
    try {
      const fileObj = e.target.files[0];
      const ordersFromExcel = await readExcel(fileObj);

      if (ordersFromExcel.length > EXCEl_LIMIT_ROWS) {
        throw Error(t('products_excel:error_limit_reached', {
          limit: EXCEl_LIMIT_ROWS,
        }));
      }

      const ordersFormated = ordersFromExcel.reduce((acumulator, current) => {
        const orderFoundIndex = acumulator.findIndex(o => o.id === current[0].toString());
        if (orderFoundIndex !== -1) {
          acumulator[orderFoundIndex].items = [
            ...acumulator[orderFoundIndex].items,
            {
              name: current[4],
              quantity: current[5],
              volume_per_item: current[24],
              weight_per_item: current[14],
              // price: current[13],
            },
          ];
          acumulator[orderFoundIndex].total += current[13] * current[5];

          return acumulator;
        }

        const buildingOrder = {
          id: current[0].toString(),
          address: {
            address: current[3],
            lat: 19.3906797, // calculate
            lng: -99.2840405,
          },
          items: [
            {
              name: current[4],
              quantity: current[5],
              volume_per_item: current[24],
              weight_per_item: current[14],
              // price: current[13],
            },
          ],
          order_date: formatStringToRFC3339(current[23]),
          delivery_min_date: formatStringToRFC3339(current[7]),
          delivery_max_date: formatStringToRFC3339(current[8]),
          total: current[13] * current[5],
          customer: {
            id: current[18].toString() || '',
            name: current[19] || '',
            email: current[21] || '',
            phone: current[20] || '',
          },
        };

        return [
          ...acumulator,
          buildingOrder,
        ];
      }, []);

      setOrders(ordersFormated);
    } catch (err) {
      console.log({ err });
    }
  };

  return (
    <Box>
      {orders === null && <ActivityIndicator />}
      {orders.length === 0 ? (
        <Alert severity="error">
          {t('general:orders_excel:msg_no_units', {
            limit: EXCEl_LIMIT_ROWS,
          })}
        </Alert>
      ) : (
        <>
          <Box>
            <Box width={1}>
              <Typography>
                {t('general:orders_excel:msg_select_file')}
                :
              </Typography>
            </Box>
            <Box display="flex" justifyContent="center" width={1}>
              <ExcelUploader fileHandler={fileHandler} />
            </Box>
          </Box>

          <Box mt={4}>
            <Box width={1}>
              <Typography>
                {t('general:orders_excel:msg_download_template')}
                :
              </Typography>
            </Box>
            <Box display="flex" justifyContent="center" width={1}>
              <Link
                href={
                  `${process.env.PUBLIC_URL
                  }/media/excel/ec-orders-template.xlsx?v?=${generateRamdomNumber()}`
                }
                underline="hover"
              >
                <DownoloadButton
                  component="span"
                  text={t('general:orders_excel:download_template')}
                />
              </Link>
            </Box>
          </Box>

          <Box justifyContent="center" mt={5}>
            <Alert severity="warning">
              {t('general:orders_excel:msg_limit_excels', {
                limit: EXCEl_LIMIT_ROWS,
              })}
            </Alert>
          </Box>
        </>
      )}
    </Box>
  );
};
