import { Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import LinkButton from '../../../ui/Buttons/LinkButton';
import SuccessButton from '../../../ui/Buttons/SuccessButton';

interface ActionsProps {
  handleClose;
  handleSubmit: Function;

  processing: boolean;
}

const Actions = ({ handleClose, handleSubmit, processing }: ActionsProps) => {
  const { t } = useTranslation();
  return (
    <Grid container>
      <Grid container item xs={12} justifyContent="flex-end">
        <LinkButton onClick={handleClose}>{t('general:cancel')}</LinkButton>
        <SuccessButton
          processing={processing ? 1 : 0}
          onClick={handleSubmit}
          color="primary"
          text={t('general:save')}
        />
      </Grid>
    </Grid>
  );
};

export default Actions;
