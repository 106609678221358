/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect } from 'react';
// import { LayoutSplashScreen } from "../../../../theme/DefaultTheme";
import { useDispatch } from 'react-redux';
import { logout } from '../../../../actions/auth';

const Logout = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const logoutSession = async () => {
      await dispatch(logout());
      props.history.push('/login');
    };
    logoutSession();
  }, [props.history, dispatch]);

  // return  <LayoutSplashScreen />
  return <div />;
};

export default Logout;
