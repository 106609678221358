/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/prop-types */
import {
  Menu,
  MenuItem,
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '../../../../../../components/ui/Layout/Box';
import {
  allowedOrderStatuses,
  OrderStatuses,
} from '../../../../../../constants/common';
import { generateKey, generateRamdomNumber } from '../../../../../../util/keys';
import AuthorizedApiClient from '../../../../../../core/common/api/AuthorizedApiClient';
import Button from '../../../../../../components/ui/Button';
import { errorParser } from '../../../../../../helpers/errorParser';
import ChangeOrderStatusConfirm from '../ChangeOrderStatusConfirm';

const ECButton = withStyles(theme => ({
  root: {
    borderRadius: theme.spacing(2),
    backgroundColor: theme.colors.orders.pending,
    '&:hover': {
      backgroundColor: theme.colors.orders.pending,
      boxShadow: 'none',
    },
    '&:disabled': {
      backgroundColor: theme.colors.orders.pending,
      boxShadow: 'none',
    },
    margin: 0,
    padding: '5px 10px',
    boxShadow: 'none',
    minWidth: '100px',
  },
}))(Button);

const useStyles = makeStyles(theme => ({
  complete: {
    backgroundColor: theme.colors.orders.complete,
    '&:hover': {
      backgroundColor: theme.colors.orders.complete,
    },
    '&:disabled': {
      backgroundColor: theme.colors.orders.complete,
    },
  },
  shipped: {
    backgroundColor: theme.colors.orders.shipped,
    '&:hover': {
      backgroundColor: theme.colors.orders.shipped,
    },
    '&:disabled': {
      backgroundColor: theme.colors.orders.shipped,
    },
  },
  preparing: {
    backgroundColor: theme.colors.orders.preparing,
    '&:hover': {
      backgroundColor: theme.colors.orders.preparing,
    },
    '&:disabled': {
      backgroundColor: theme.colors.orders.preparing,
    },
  },
  sent: {
    backgroundColor: theme.colors.orders.sent,
    '&:hover': {
      backgroundColor: theme.colors.orders.sent,
    },
    '&:disabled': {
      backgroundColor: theme.colors.orders.sent,
    },
  },
  accepted: {
    backgroundColor: theme.colors.orders.accepted,
    '&:hover': {
      backgroundColor: theme.colors.orders.accepted,
    },
    '&:disabled': {
      backgroundColor: theme.colors.orders.accepted,
    },
  },
  rejected: {
    backgroundColor: theme.colors.orders.rejected,
    '&:hover': {
      backgroundColor: theme.colors.orders.rejected,
    },
    '&:disabled': {
      backgroundColor: theme.colors.orders.rejected,
    },
  },
  cancelled: {
    backgroundColor: theme.colors.orders.cancelled,
    '&:hover': {
      backgroundColor: theme.colors.orders.cancelled,
    },
    '&:disabled': {
      backgroundColor: theme.colors.orders.cancelled,
    },
  },
  returned: {
    backgroundColor: theme.colors.orders.returned,
    '&:hover': {
      backgroundColor: theme.colors.orders.returned,
    },
    '&:disabled': {
      backgroundColor: theme.colors.orders.returned,
    },
  },
  cancelItem: {
    color: 'red',
  },
}));

const OrderStatus = ({
  order,
  setError = null,
  onChangedStatus = null,
  onStartToChangeStatus = null,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [status, setStatus] = useState(null);
  const [label, setLabel] = useState('');
  const [newStatus, setNewStatus] = useState(null);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation(['general']);

  const handleMenuItemClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = (status) => {
    setNewStatus(status);
    setAnchorEl(null);
  };
  const handleCancel = () => {
    setShowConfirm(false);
    setNewStatus(null);
  };

  const handleConfirm = () => {
    setShowConfirm(false);
    attemptChangeStatus(newStatus);
  };

  const attemptChangeStatus = useCallback(
    async (st) => {
      if (!order.company_id) {
        return;
      }
      const sg = new AuthorizedApiClient();
      const path = `companies/${order.company_id}/orders/${order.id}`;
      setLoading(true);
      if (setError) {
        setError(null);
      }

      if (onStartToChangeStatus) {
        onStartToChangeStatus(order);
      }

      try {
        await sg.put({ status: st }, path);

        setStatus(st);
        if (onChangedStatus) {
          onChangedStatus(order, st);
        }
      } catch (error) {
        if (setError) {
          setError(errorParser(error, true));
        }
      } finally {
        setNewStatus(null);
        setLoading(false);
      }
    },
    [order, setError, onChangedStatus, onStartToChangeStatus],
  );

  useEffect(() => {
    if (newStatus) {
      setShowConfirm(true);
    } else {
      setShowConfirm(false);
    }
  }, [newStatus]);

  useEffect(() => {
    if (order) {
      setStatus(order.status);
    }
  }, [order]);

  useEffect(() => {
    if (status) {
      setLabel(t(`general:orders_page:${status}`, status));
    }
  }, [status, t]);

  const id = `menu-${generateRamdomNumber()}`;

  let list = [];

  if (allowedOrderStatuses[status] !== undefined) {
    list = allowedOrderStatuses[status].map(st => (
      <MenuItem
        key={generateKey(st)}
        onClick={() => handleCloseMenu(st)}
        className={st === OrderStatuses.Cancelled ? classes.cancelItem : null}
      >
        {t(`general:orders_page:${st}`, st)}
      </MenuItem>
    ));
  }

  const disabled = !(list.length > 0);

  return (
    <Box minWidth="100px">
      {status && (
        <ECButton
          aria-controls={id}
          aria-haspopup="true"
          onClick={handleMenuItemClick}
          className={`${classes[status]}`}
          disabled={disabled}
          endIcon={
            !disabled ? <ExpandMoreIcon style={{ cursor: 'pointer' }} /> : null
          }
          text={label}
          processing={loading ? 1 : 0}
        />
      )}

      {!disabled && (
        <Menu
          id={id}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => handleCloseMenu(null)}
        >
          {list}
        </Menu>
      )}

      <ChangeOrderStatusConfirm
        open={showConfirm}
        handleCancel={handleCancel}
        order={order}
        newStatus={newStatus}
        handleConfirm={handleConfirm}
      />
    </Box>
  );
};

export default OrderStatus;
