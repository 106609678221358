/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button, CircularProgress, Collapse, IconButton,
} from '@mui/material';
import Axios from 'axios';
import CreateIcon from '@mui/icons-material/Create';
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';
import Grid from '../../../../../components/ui/Layout/Grid';
import images from '../../../../../theme/ECTheme/assets/images';
import Box from '../../../../../components/ui/Layout/Box';
import PaymentCheck from './PaymentCheck';
import { BASE_URL } from '../../../../../constants/api/ApiConstants';

const useStyles = makeStyles(() => ({
  nameNSwitchSpace: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    marginBottom: 15,
  },
}));

const ShippingMethodsSection = ({
  company, deliveryZone, visibleAddress, setCanshipping,
}) => {
  const classes = useStyles();
  const { t } = useTranslation(['general']);
  const [shippingMethods, setShippingMethods] = useState(company.shipping_methods || []);
  const [load, setLoad] = useState(false);
  const [selectMenu, setSelectMenu] = useState(false);
  const [open, setOpen] = useState(true);
  const [open2, setOpen2] = useState(true);
  const [open3, setOpen3] = useState(true);

  const MenuSelector = () => {
    setSelectMenu(!selectMenu);
  };

  const handleChangeShipping = () => {
    setLoad(true);
    const shippingStatus = {
      shipping_methods: shippingMethods,
    };
    Axios.put(`${BASE_URL}/api/v1/companies/${company.id}`, shippingStatus)
      .catch(err => console.error(err))
      .finally(() => {
        setLoad(false);
        setSelectMenu(false);
      });
  };

  const handleDeliveryChange = (checked, method) => {
    if (checked) {
      setShippingMethods([...shippingMethods, method]);
      setCanshipping([...shippingMethods, method]);
    } else {
      const newArray = shippingMethods.filter(sM => sM !== method);
      setShippingMethods(newArray);
      setCanshipping(newArray);
    }
  };

  return (
    <Grid justifyContent="center" alignItems="center" className={classes.nameNSwitchSpace} spacing={5}>
      { deliveryZone === 0
        ? (
          <Grid
            item
            xs={6}
            md={3}
            container
            justifyContent="center"
            alignItems="center"
          >
            <Collapse in={open}>
              <Alert
                severity="warning"
                action={(
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                  )}
              >
                Configurar zona de reparto primero
              </Alert>
            </Collapse>
            <PaymentCheck
              selectMenu={selectMenu}
              label="Configura una zona de reparto antes"
              image={images.Icon_Delivery}
              onChange={checked => handleDeliveryChange(checked, 'delivery')}
              checked={deliveryZone === 0 ? false : shippingMethods.includes('delivery')}
            />
          </Grid>
        )
        : (
          <Grid
            item
            xs={6}
            md={3}
            container
            justifyContent="center"
            alignItems="center"
          >
            <PaymentCheck
              selectMenu={selectMenu}
              label={deliveryZone === 0 ? 'Configura una zona de reparto antes' : t('general:shipping_methods:delivery')}
              image={images.Icon_Delivery}
              onChange={checked => handleDeliveryChange(checked, 'delivery')}
              checked={deliveryZone === 0 ? false : shippingMethods.includes('delivery')}
            />
          </Grid>
        )}
      {
        visibleAddress
          ? (
            <Grid
              item
              xs={6}
              md={3}
              container
              justifyContent="center"
              alignItems="center"
            >
              <PaymentCheck
                selectMenu={selectMenu}
                label={visibleAddress ? t('general:shipping_methods:buy_in_shop') : 'Debes tener activada la ubicación de tu tienda'}
                image={images.Icon_FoodStore}
                onChange={checked => handleDeliveryChange(checked, 'buy_in_shop')}
                checked={visibleAddress ? shippingMethods.includes('buy_in_shop') : false}
              />
            </Grid>
          )

          : (

            <Grid
              item
              xs={6}
              md={3}
              container
              justifyContent="center"
              alignItems="center"
            >
              <Collapse in={open2}>
                <Alert
                  severity="warning"
                  action={(
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setOpen2(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  )}
                >
                  Debes tener activada la ubicación de tu tienda
                </Alert>
              </Collapse>
              <PaymentCheck
                selectMenu={selectMenu}
                label={visibleAddress ? t('general:shipping_methods:buy_in_shop') : 'Debes tener activada la ubicación de tu tienda'}
                image={images.Icon_FoodStore}
                onChange={checked => handleDeliveryChange(checked, 'buy_in_shop')}
                checked={visibleAddress ? shippingMethods.includes('buy_in_shop') : false}
              />
            </Grid>
          )

      }
      {
        visibleAddress
          ? (
            <Grid
              item
              xs={6}
              md={3}
              container
              justifyContent="center"
              alignItems="center"
            >
              <PaymentCheck
                selectMenu={selectMenu}
                label={visibleAddress ? 'Recoger en tienda' : 'Debes tener activada la ubicación de tu tienda'}
                image={images.Icon_Marker}
                onChange={checked => handleDeliveryChange(checked, 'shop_pickup')}
                checked={visibleAddress ? shippingMethods.includes('shop_pickup') : false}
              />
            </Grid>
          )
          : (
            <Grid
              item
              xs={6}
              md={3}
              container
              justifyContent="center"
              alignItems="center"
            >
              <Collapse in={open3}>
                <Alert
                  severity="warning"
                  action={(
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setOpen3(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  )}
                >
                  Debes tener activada la ubicación de tu tienda
                </Alert>
              </Collapse>
              <PaymentCheck
                selectMenu={selectMenu}
                label={visibleAddress ? 'Recoger en tienda' : 'Debes tener activada la ubicación de tu tienda'}
                image={images.Icon_Marker}
                onChange={checked => handleDeliveryChange(checked, 'shop_pickup')}
                checked={visibleAddress ? shippingMethods.includes('shop_pickup') : false}
              />
            </Grid>
          )

      }
      <Box fullWidth display="flex" justifyContent="flex-end" padding="5px">
        <Button onClick={MenuSelector}><CreateIcon fontSize="small" /></Button>
        {
              selectMenu
                ? (
                  <Button onClick={handleChangeShipping} variant="contained" color="primary">
                    {
                  load
                    ? <CircularProgress color="secondary" size={25} />
                    : 'Guardar'
                }
                  </Button>
                )
                : null
            }
      </Box>
    </Grid>
  );
};

export default ShippingMethodsSection;
