/* eslint-disable react/prop-types */
import React from 'react';
import { useFormContext } from 'react-hook-form';
import Grid from '../../../../../components/ui/Layout/Grid';
import Basic from './Basic';
import Billing from './Billing';

export default ({ tab }) => {
  const {
    register, formState: { errors }, setValue, watch,
  } = useFormContext();
  const cellphone = watch('cellphone');

  // const handleOnChange = (event) => {
  //   if (event.target.customName != null) {
  //     setFormData({
  //       ...formData,
  //       [event.target.customName]: event.target.value,
  //     });
  //   }
  //   setFormData({ ...formData, [event.target.name]: event.target.value });
  // };

  return (
    <>
      <Grid
        container
        spacing={2}
        style={{ display: tab === 0 ? 'block' : 'none' }}
      >
        <Basic
          register={register}
          errors={errors}
          setValue={setValue}
          cellphone={cellphone}
        />
      </Grid>

      <Grid
        container
        spacing={2}
        style={{ display: tab === 1 ? 'block' : 'none' }}
      >
        <Billing
          register={register}
          errors={errors}
        />
      </Grid>

    </>
  );
};
