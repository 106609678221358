/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { makeStyles } from '@mui/styles';
import { ListItem, ListItemIcon, Grow } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import { setOpen } from '../../../../actions/theme';

import MenuIcon from '../MenuIcon';

const minWidth = 960;

const useStyles = makeStyles(theme => ({
  itemDefault: {
    borderTopRightRadius: 25,
    borderBottomRightRadius: 25,
    width: '98%',
  },
  item: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(4),
  },
  itemSelected: {
    backgroundColor: theme.colors.home.light,
    height: 41,
    position: 'absolute',
  },
  tittle: {
    paddingLeft: theme.spacing(1),
    fontWeight: 300,
    color: theme.colors.black,
  },
  tittleSelected: {
    paddingLeft: theme.spacing(1),
    fontWeight: 600,
    color: theme.colors.home.main,
  },
}));

const MenuItem = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [selected, setSelected] = React.useState(false);
  const location = useLocation().pathname.split('/')[1];

  React.useEffect(() => {
    if (location === props.link.split('/')[1]) {
      setSelected(true);
    } else {
      setSelected(false);
    }
  }, [location, props.link]);

  const handleClick = () => {
    if (window.innerWidth <= minWidth) {
      dispatch(setOpen(false));
    }
  };

  return (
    <Link to={props.link} onClick={handleClick}>
      <Grow in={selected} timeout={500}>
        <div className={`${classes.itemDefault} ${selected ? classes.itemSelected : null}`} />
      </Grow>
      <ListItem button className={`${classes.itemDefault} ${classes.item}`}>
        <ListItemIcon>
          <MenuIcon
            section={props.section}
            shadow={props.shadow}
            background={props.background}
          />
        </ListItemIcon>
        <div className={selected ? classes.tittleSelected : classes.tittle}>{props.tittle}</div>
      </ListItem>
    </Link>
  );
};

export default MenuItem;
