/* eslint-disable prefer-destructuring */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import AsyncPaginatedTable from '../../../../../../components/ui/AsyncPaginatedTable';
import FormattedNumber from '../../../../../../components/ui/FormattedNumber';
import Box from '../../../../../../components/ui/Layout/Box';
import MovementTypeIcon from '../../../../../../components/ui/MovementTypeIcon';
import MovementDate from './MovementDate';
import Comment from '../../../MovementsPage/MovementsList/Comment';
import Grid from '../../../../../../components/ui/Layout/Grid';
import DateFilter from '../../../../../../components/ui/DateFilter';
import { getFormattedDate } from '../../../../../../util/dates';

export default ({
  state, getMovements, resetMovements, downloadMovements,
}) => {
  const { t } = useTranslation(['movements', 'general']);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const onResetFilters = () => {
    setStartDate(null);
    setEndDate(null);
  };

  const columns = [
    {
      name: 'created_at',
      label: t('general:date'),
      options: {
        filter: true,
        filterType: 'custom',
        filterOptions: {
          display: (filterList, onChange, index, column) => (
            <Box mt={2}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <DateFilter
                    label={t('general:filters:from_date')}
                    value={startDate}
                    maxDate={endDate || new Date()}
                    onChange={(date) => {
                      setStartDate(date);
                      const dates = [];
                      if (date) {
                        dates[0] = getFormattedDate(date);
                      } else {
                        dates[0] = '';
                      }
                      if (endDate) {
                        dates[1] = getFormattedDate(endDate);
                      } else {
                        dates[1] = dates[0];
                        setEndDate(date);
                      }
                      // console.log("dates 0", dates);
                      // filterList[index][0] = date ? dates.join("|") : "";
                      // onChange(filterList[index], index, column);
                      try {
                        filterList[index][0] = date ? dates.join('|') : '';
                        onChange(filterList[index], index, column);
                      } catch (e) { console.log('error date filter', e.message); }
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <DateFilter
                    label={t('general:filters:from_to')}
                    value={endDate}
                    minDate={startDate || null}
                    maxDate={new Date()}
                    onChange={(date) => {
                      setEndDate(date);

                      const dates = [];
                      if (date) {
                        dates[1] = getFormattedDate(date);
                      } else {
                        dates[1] = '';
                      }
                      if (startDate) {
                        dates[0] = getFormattedDate(startDate);
                      } else {
                        dates[0] = dates[1];
                        setStartDate(date);
                      }
                      // console.log("dates 1", dates);
                      try {
                        filterList[index][0] = date ? dates.join('|') : '';
                        onChange(filterList[index], index, column);
                      } catch (e) { console.log('error date filter', e.message); }
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          ),
        },
        customFilterListOptions: {
          render: (v) => {
            if (Array.isArray(v)) {
              const dates = v[0].split('|');
              if (dates[0] === dates[1]) {
                return dates[0];
              }
              return (
                `${dates[0]} ${t('general:filters:from_to')} ${dates[1]}`
              );
            }
            return [];
          },
        },
        sort: false,
        customBodyRender: (value) => {
          if (value) {
            return <MovementDate value={value} />;
          }
          return '';
        },
      },
      customRenderer: value => <MovementDate value={value} />,
    },

    {
      name: 'product',
      label: t('movements:product'),

      options: {
        filter: false,
        sort: false,
        customBodyRender: (product) => {
          if (product) {
            return product.name;
          }
          return '';
        },
      },
    },
    {
      name: 'location',
      label: t('movements:location'),

      options: {
        filter: false,
        sort: false,
        customBodyRender: (location) => {
          if (location) {
            return location.name;
          }
          return '';
        },
      },
    },
    {
      name: 'current_quantity',
      label: t('movements:current_quantity'),

      options: {
        filter: false,
        sort: false,
        customBodyRender: value => <FormattedNumber value={value} />,
      },
    },
    {
      name: 'type',
      label: t('movements:type'),

      options: {
        filter: false,
        sort: false,
        customBodyRender: value => <MovementTypeIcon value={value} />,
      },
    },
    {
      name: 'quantity',
      label: t('movements:quantity'),

      options: {
        filter: false,
        sort: false,
        customBodyRender: value => <FormattedNumber value={value} />,
      },
    },
    {
      name: 'new_quantity',
      label: t('movements:new_quantity'),

      options: {
        filter: false,
        sort: false,
        customBodyRender: value => <FormattedNumber value={value} />,
      },
    },
    {
      name: 'user',
      label: t('movements:who'),

      options: {
        filter: false,
        sort: false,
        customBodyRender: (user) => {
          if (user) {
            return `${user.name} ${user.last_name}`;
          }
          return '';
        },
      },
    },
    {
      name: 'comments',
      label: t('movements:comments'),

      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const row = state.response.data[tableMeta.rowIndex];
          const saleId = row.parent;
          return <Comment value={value} row={row} id={saleId} />;
        },
      },
    },
  ];

  return (
    <Box>
      <Box mt={3}>
        <AsyncPaginatedTable
          hasNextPage={state.response.page < state.response.last_page}
          currentPage={state.response.page}
          perPage={state.response.per_page}
          data={state.response.data}
          columns={columns}
          loadData={params => getMovements(params)}
          resetData={resetMovements}
          processing={state.processing}
          total={state.response.total}
          options={{ filter: true, downloadExcel: downloadMovements }}
          filterFields={[{ field: 'updated_at', type: 'date_range' }]}
          onResetFilters={onResetFilters}
        />
      </Box>
    </Box>
  );
};
