/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable import/no-extraneous-dependencies */
import { TextField } from '@mui/material';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';
import _ from 'lodash';
import { withStyles } from '@mui/styles';

const ECTextField = withStyles(() => ({
  root: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '30px',
    },
    '& .MuiOutlinedInput-input': {
      paddingTop: 7,
      paddingBottom: 7,
    },
  },
}))(TextField);

interface InputSearchProps {
  setSearchValue: Function;
  placeholder?: string;
}

export default ({ setSearchValue, placeholder }: InputSearchProps) => {
  const { t } = useTranslation(['general']);
  const _placeholder = placeholder || t('general:search');

  const delayedSetState = useCallback(_.debounce(q => setSearchValue(q), 500), []);
  const stateSearch = (e: any) => {
    delayedSetState(e.target.value);
  };

  return (
    <ECTextField
      variant="outlined"
      onChange={stateSearch}
      placeholder={_placeholder}
      InputProps={{
        endAdornment: (
          <SearchIcon />
        ),
      }}
    />
  );
};
