/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/prop-types */
import {
  Modal, TextField, Button,
} from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Axios from 'axios';
import { makeStyles } from '@mui/styles';
import Box from '../ui/Layout/Box';
import Map from '../../containers/pages/admin/StorePage/SharedCampaignsPage/CampaignWizard/ZonesSection/Map';
import { DEFAULT_COORDINATES } from '../../constants/common';
import { BASE_URL } from '../../constants/api/ApiConstants';

const useStyles = makeStyles(theme => ({

  paper: {
    position: 'absolute',
    width: 500,
    height: 'auto',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #878787',
    borderRadius: 5,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  centeredItems: {
    margin: 20,
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  mainText: {
    color: 'black',
    padding: 11,
  },
  icon: {
    width: 35,
    marginBlock: 10,
  },
  root: {
    width: '100%',
    display: 'felx',
    flexDirection: 'column',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  containerCard: {
    display: 'flex',
    marginTop: 25,
    marginBottom: 25,
  },
}));

export const ModalMapsChange = ({
  open, setOpen, location, onChangeAddress,
}) => {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [storeAddress, setStoreAddress] = useState('');
  const { companyId, company } = useSelector((state: any) => state.session);
  const [coordinates, setCoordinates] = useState({
    lat: location?.coordinates[1] || DEFAULT_COORDINATES.lat,
    lng: location?.coordinates[0] || DEFAULT_COORDINATES.lng,
  });

  function getModalStyle() {
    return {
      top: `${50}%`,
      left: `${50}%`,
      transform: `translate(-${50}%, -${50}%)`,
    };
  }

  const handleClose = () => {
    setOpen(false);
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setStoreAddress(value);
  };

  const handleSubmit = (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    const addressStatus = {
      address: storeAddress,
      coordinates,
    };

    Axios.put(`${BASE_URL}/api/v1/companies/${companyId}/branches/${company.branches[0]?.id}`, addressStatus)
      .then(() => {
        onChangeAddress(addressStatus);
      })
      .finally(() => {
        setOpen(false);
      });
  };

  const body = (
    <div className={classes.root}>
      <div className={classes.containerCard}>
        <TextField
          variant="standard"
          disabled
          fullWidth
          label="Ubicación"
          value={storeAddress}
          onChange={onChange}
          InputProps={{
            style: {
              textAlign: 'center',
              margin: '10px 0',
            },
          }}
        />
        <Map
          zoom={16}
          disableDefaultUI
          clickableMarker
          draggableMarker
          draggableMap
          clickableIcons
          onLocationChanged={(fullAddress, lat, lng) => {
            setStoreAddress(fullAddress);
            setCoordinates({ lat, lng });
          }}
          onLoadLocation={(fullAddress) => { setStoreAddress(fullAddress); }}
          zone={coordinates}
        />
      </div>
    </div>
  );

  return (
    <Modal
      open={open}
      onClose={handleClose}
      disableEnforceFocus
      disableAutoFocus
    >
      <Box
        className={classes.paper}
        style={modalStyle}
      >
        <form onSubmit={handleSubmit}>
          {body}
          <Box
            fullWidth
            display="flex"
            justifyContent="flex-end"
            padding="5px"
          >
            <Button
              type="submit"
              variant="contained"
              color="primary"
            >
              Guardar
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};
