import { types } from '../../actions/modifier_group/types';

export const initialState = {
  modifierGroup: {
    id: null, title: '', description: '', products: [],
  },
  error: null,
  showModal: false,
  processing: false,
  deletingLocation: false,
  modifiers: [],
  associated_products: [],
  refresh: false,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case types.EDIT_MODIFIER_GROUP:
      return {
        ...state,
        showModal: true,
        modifierGroup:
          action.payload !== null ? action.payload : initialState.modifierGroup,
      };
    case types.CLOSE_MODIFIER_GROUP_MODAL:
      return initialState;
    case types.SAVE_MODIFIER_GROUP_SUCCESS:
      return {
        ...state,
        response: action.response,
        error: null,
        processing: false,
        refresh: true,
      };
    case types.SAVE_MODIFIER_GROUP_FAILURE:
      return {
        ...state,
        error: action.error,
        processing: false,
      };
    case types.SAVING_MODIFIER_GROUP:
      return {
        ...state,
        error: null,
        processing: true,
      };

    case types.DELETING_MODIFIER_GROUP:
      return {
        ...state,
        error: null,
        deletingLocation: true,
      };

    case types.DELETE_MODIFIER_GROUP_SUCCESS:
      return {
        ...state,
        error: null,
        deletingLocation: false,
        refresh: true,
      };
    case types.DELETE_MODIFIER_GROUP_FAILURE:
      return {
        ...state,
        error: action.error,
        deletingLocation: false,
      };
    case types.CLEAR_REFRESH:
      return {
        ...state,
        refresh: false,
      };

    case types.SET_MODIFIERS:
      return {
        ...state,
        modifiers: action.modifiers,
      };

    case types.SET_ASSOCIATED_PRODUCTS:
      return {
        ...state,
        associated_products: action.associated_products,
      };

    case types.SET_MODIFIER_GROUP_SUCCESS:
      return {
        ...state,
        modifierGroup: action.modifierGroup,
      };
    case types.SET_MODIFIER_GROUP_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    default:
      return state;
  }
};
