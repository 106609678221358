/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ProfileView from './ProfileView';
import ActivityIndicator from '../../../../../components/ui/ActivityIndicator';
import { saveProfile, clearSaved } from '../../../../../actions/profile';
import { editPassword } from '../../../../../actions/password';

export default () => {
  const dispatch = useDispatch();
  const session = useSelector((state: any) => state.session);
  const { user } = session;

  const {
    error,
    processing,
    saved,
  } = useSelector((state: any) => state.profile);

  const attemptSaveProfile = async (data, image) => {
    await dispatch(saveProfile(data, image));
  };

  const _clearSaved = useCallback(() => {
    if (saved) {
      dispatch(clearSaved());
    }
  }, [saved, dispatch]);

  useEffect(() => () => {
    _clearSaved();
  }, [_clearSaved]);

  const _handleChangePasswordClick = useCallback(() => {
    dispatch(editPassword());
  }, [dispatch]);

  // console.log("user", user);
  return (
    <>
      {user ? (
        <ProfileView
          user={user}
          attemptSaveProfile={attemptSaveProfile}
          error={error}
          processing={processing}
          saved={saved}
          clearSaved={_clearSaved}
          handleChangePasswordClick={_handleChangePasswordClick}
        />
      ) : (
        <ActivityIndicator />
      )}
    </>
  );
};
