import React from 'react';
import {
  Route, Switch, useHistory, withRouter,
} from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';
import ReactGa from 'react-ga';
import PrivateRoute from '../PrivateRoute';
import Register from '../containers/pages/auth/Register';
import Login from '../containers/pages/auth/Login';
import * as routerHelpers from './RouterHelpers';
import NotFound from '../containers/pages/errors/NotFound';
import Logout from '../containers/pages/auth/Logout';
import ReportsPage from '../containers/pages/admin/ReportsPage';
import FinancesPage from '../containers/pages/admin/FinancesPage';
import InventoryPage from '../containers/pages/admin/InventoryPage';
import SettingsPage from '../containers/pages/admin/SettingsPage';
import NewMenu from '../theme/ECTheme/pages/MainMenu';
import PosPage from '../containers/pages/admin/PosPage';
import BlankRecountPage from '../containers/pages/admin/RecountsPage/BlankRecountPage';
import ComparedRecountPage from '../containers/pages/admin/RecountsPage/ComparedRecountPage';
import {
  PERMISSION, RESOURCES, SECTIONS, FEATURES_TYPE,
} from '../constants/common';
import CustomersPage from '../containers/pages/admin/CustomersPage';

import StorePage from '../containers/pages/admin/StorePage';

import ProductsReportsPage from '../containers/pages/admin/ReportsPage/ProductsReports';
import SalesReportsPage from '../containers/pages/admin/ReportsPage/SalesReports';
import MovementsReportsPage from '../containers/pages/admin/ReportsPage/MovementsReports';
import SaleDetailsPage from '../containers/pages/admin/ReportsPage/SalesReports/SaleDetailsPage';

import OrderDetailsPage from '../containers/pages/admin/StorePage/OrdersPage/OrderDetailsPage';
import EcubiOrdersPage from '../containers/pages/ecubi_admin/OrdersPage';
import { ReturnPaymentAcount } from '../containers/pages/admin/ReturnPaymentAcount';
import { ReauthPaymentAcount } from '../containers/pages/admin/ReauthPaymentAcount';
import { ProductsPage } from '../containers/pages/admin/ProductsPage';
import PaymentMethodPage from '../containers/pages/admin/PaymentMethodPage';
import { useSession } from '../util/session';
import PermissionAccount from '../components/Alerts/PermissionAccount';
import LogisticPage from '../containers/pages/admin/LogisticPage';
import { HomePage } from '../containers/pages/admin/HomePage';
import { DashboardPage } from '../containers/pages/admin/DashboardPage';
import { EcubiDashboardPage } from '../containers/pages/ecubi_admin/EcubiDashboardPage';
import { AnalyticsPage } from '../containers/pages/admin/AnalyticsPage';
// import { Subscriptions } from "../containers/pages/admin/Subscriptions";

const Routes = withRouter(() => {
  const lastLocation = useLastLocation();
  routerHelpers.saveLastLocation(lastLocation);

  const historyT = useHistory();
  historyT.listen(location => ReactGa.pageview(location.pathname + location.search));

  const { can, user } = useSession();

  const canLocations = can(
    RESOURCES.locations,
    PERMISSION.access,
    FEATURES_TYPE.pos_resources,
  );
  const canCounts = can(
    RESOURCES.counts,
    PERMISSION.access,
    FEATURES_TYPE.pos_resources,
  );
  const canMovements = can(
    RESOURCES.movements,
    PERMISSION.access,
    FEATURES_TYPE.pos_resources,
  );
  const canStockReports = can(
    RESOURCES.stock_reports,
    PERMISSION.access,
    FEATURES_TYPE.pos_resources,
  );
  const canMovementsReports = can(
    RESOURCES.movements_reports,
    PERMISSION.access,
    FEATURES_TYPE.pos_resources,
  );

  return (
    <>
      <PermissionAccount open={user && user.status && user.status !== 'active'} />
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/logout" component={Logout} />
        <Route exact path="/register" component={Register} />
        <Route exact path="/newmenu" component={NewMenu} />

        <PrivateRoute exact path="/" component={HomePage} />
        <PrivateRoute exact path="/pos" component={PosPage} section={RESOURCES.pos} />
        <PrivateRoute exact path="/reports" component={ReportsPage} />
        <PrivateRoute exact planAuth={canStockReports} path="/reports/products_report" component={ProductsReportsPage} section={RESOURCES.products_reports} />
        <PrivateRoute exact path="/reports/sales_report" component={SalesReportsPage} section={RESOURCES.sales} />
        <PrivateRoute exact planAuth={canMovementsReports} path="/reports/movements_report" component={MovementsReportsPage} section={RESOURCES.movements} />
        <PrivateRoute exact path="/sales/:id" component={SaleDetailsPage} section={RESOURCES.sales} />

        <PrivateRoute exact path="/finances" component={FinancesPage} />

        <PrivateRoute exact path="/logistic" component={LogisticPage} />

        <PrivateRoute path="/products" component={ProductsPage} section={SECTIONS.inventory.name} />

        <PrivateRoute path="/store" component={StorePage} section={SECTIONS.store} />

        <PrivateRoute path="/analytics" component={AnalyticsPage} />

        <PrivateRoute exact path="/customers" component={CustomersPage} />

        {/* TODO: check subrouter */}
        <PrivateRoute planAuth={canLocations || canMovements || canCounts} path="/inventory" component={InventoryPage} section={SECTIONS.inventory.name} />
        <PrivateRoute exact planAuth={canCounts} path="/inventory/recounts/blank" component={BlankRecountPage} section={RESOURCES.blank_count} />
        <PrivateRoute exact planAuth={canCounts} path="/inventory/recounts/compared" component={ComparedRecountPage} section={RESOURCES.compared_count} />

        <PrivateRoute path="/settings" component={SettingsPage} />
        <PrivateRoute exact path="/return" component={ReturnPaymentAcount} section={RESOURCES.aprove_acount} />
        <PrivateRoute exact path="/reauth" component={ReauthPaymentAcount} section={RESOURCES.pending_acount} />
        <PrivateRoute exact path="/change_payment_method" component={PaymentMethodPage} />

        {/* TODO: Check access */}
        <PrivateRoute exact path="/dashboard" component={DashboardPage} section={RESOURCES.dashboard} />
        <PrivateRoute exact path="/global-dashboard" component={EcubiDashboardPage} section={RESOURCES.ecubi_dashboard} />

        <PrivateRoute exact path="/orders" component={EcubiOrdersPage} section={RESOURCES.ecubi_orders} />
        <PrivateRoute exact path="/orders/:id" component={OrderDetailsPage} section={RESOURCES.sales} />

        <Route path="*" exact component={NotFound} />
      </Switch>
    </>
  );
});

export default Routes;
