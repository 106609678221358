/* eslint-disable react/prop-types */
import React from 'react';
import {
  Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow,
} from '@mui/material';
import Text from '../../../../../components/Text/Text';
import { ProductTableRow } from './ProductTableRow';
import { RowTableSkeleton } from '../../../../../components/skeleton/RowTableSkeleton';

export const ListOfProducts = ({
  onClickProduct,
  loadingProducts,
  products,
  setPage,
  setPerPage,
}) => {
  if (loadingProducts) {
    return <RowTableSkeleton />;
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table style={{ borderCollapse: 'separate', padding: 10 }}>
          <TableHead>
            <TableRow>
              <TableCell className="products-table-col" style={{ border: 'none', padding: 4 }}>
                <Text htmlColor="gray" variant="caption">
                  Producto
                </Text>
              </TableCell>
              <TableCell className="unit-table-col" style={{ border: 'none', padding: 4 }} align="center">
                <Text htmlColor="gray" variant="caption">
                  Unidad
                </Text>
              </TableCell>
              <TableCell className="quantity-table-col" style={{ border: 'none', padding: 4 }} align="center">
                <Text htmlColor="gray" variant="caption">
                  Cantidad
                </Text>
              </TableCell>
              <TableCell className="available-table-col" style={{ border: 'none', padding: 4 }} align="center">
                <Text htmlColor="gray" variant="caption">
                  Disponible
                </Text>
              </TableCell>
              <TableCell className="category-table-col" style={{ border: 'none', padding: 4 }} align="center">
                <Text htmlColor="gray" variant="caption">
                  Categoría
                </Text>
              </TableCell>
              <TableCell className="cost-table-col" style={{ border: 'none', padding: 4 }} align="center">
                <Text htmlColor="gray" variant="caption">
                  Costo
                </Text>
              </TableCell>
              <TableCell className="price-table-col" style={{ border: 'none', padding: 4 }} align="center">
                <Text htmlColor="gray" variant="caption">
                  Precio
                </Text>
              </TableCell>
              <TableCell className="published-table-col" style={{ border: 'none', padding: 4 }} align="center">
                <Text htmlColor="gray" variant="caption">
                  Publicado
                </Text>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {
            products.data.map(rowData => (
              <ProductTableRow
                onClick={() => onClickProduct(rowData)}
                key={rowData.id}
                productData={rowData}
              />
            ))
          }
          </TableBody>

        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        rowsPerPageOptions={[5, 10, 20]}
        onRowsPerPageChange={e => setPerPage(parseInt(e.target.value, 10))}
        count={products.total || 0}
        rowsPerPage={products.per_page || 5}
        page={products.page - 1 || 0}
        onPageChange={(_, next) => setPage(next + 1)}
      />
    </>
  );
};
