/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-unstable-nested-components */
import {
  Button, Collapse, IconButton, Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import PaginatedTable from '../../../../../../components/ui/PaginatedTable';
import { useBestSellerContext } from '../context/BestSellerContext';
import ActivityIndicator from '../../../../../../components/ui/ActivityIndicator';
import { getDefaultVariant } from '../../../../../../util/products';
import FormattedCurrency from '../../../../../../components/ui/FormattedCurrency';
import FormattedNumber from '../../../../../../components/ui/FormattedNumber';
import { useDashboardContext } from '../../context/DashboardContext';
import { dateToUtc } from '../../../../../../util/dates';
import Box from '../../../../../../components/ui/Layout/Box';

const CustomTableHead = ({ label }: { label: string }) => {
  const [open, setOpen] = React.useState(true);

  return (
    <div style={{ display: 'flex' }}>
      <Collapse style={{ flex: 1 }} in={open}>
        <Alert
          severity="info"
          action={(
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          )}
        >
          {label}
        </Alert>
      </Collapse>
      <Button
        disabled={open}
        onClick={() => {
          setOpen(true);
        }}
        style={{ borderRadius: 20, minWidth: 'auto' }}
      >
        <InfoIcon />
      </Button>
    </div>
  );
};

const customCellStyle = {
  padding: '5px',
};

const centeredCustomCellStyle = {
  padding: '5px',
  align: 'center',
  textAlign: 'center',
};

const BestSellerList = () => {
  const { getProducts, state } = useBestSellerContext();
  const { params, response, processing } = state;
  const { data } = response;
  const { state: dashboardState } = useDashboardContext();
  const { start_date, end_date } = dashboardState;
  const { t } = useTranslation(['general']);
  const [sortBy, setSortBy] = useState('total_profit');
  const [sortFU, setSortFU] = useState('desc');

  const tableData = data
    .filter(p => p != null)
    .map((p) => {
      const defaultVariant = getDefaultVariant(p);
      const unit = defaultVariant && defaultVariant && defaultVariant.unitofmeasure
        ? defaultVariant.unitofmeasure
        : null;

      return {
        name: p.name,
        price:
          defaultVariant && defaultVariant.price ? defaultVariant.price : 0,
        total_sales: p.total_sales || 0,
        total_profit: p.total_profit || 0,
        total_cost: p.total_cost || 0,
        total_revenue: p.total_revenue || 0,
        unit: unit && unit.code ? unit.code : '',
      };
    });

  const columns = [
    {
      name: 'name',
      label: t('general:product'),
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: customCellStyle,
        }),
        setCellHeaderProps: () => ({
          style: customCellStyle,
        }),
      },
    },

    {
      name: 'total_sales',
      label: t('general:quantity'),
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: centeredCustomCellStyle,
        }),
        setCellHeaderProps: () => ({
          style: centeredCustomCellStyle,
        }),
        customBodyRender: value => <FormattedNumber value={value || 0} />,
      },
    },

    {
      name: 'unit',
      label: t('general:unit'),
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: centeredCustomCellStyle,
        }),
        setCellHeaderProps: () => ({
          style: centeredCustomCellStyle,
        }),
      },
    },

    {
      name: 'price',
      label: t('general:price'),
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: centeredCustomCellStyle,
        }),
        setCellHeaderProps: () => ({
          style: centeredCustomCellStyle,
        }),
        customBodyRender: value => <FormattedCurrency value={value} />,
      },
    },

    {
      name: 'total_cost',
      label: t('general:total_cost'),
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: centeredCustomCellStyle,
        }),
        setCellHeaderProps: () => ({
          style: centeredCustomCellStyle,
        }),
        customBodyRender: value => <FormattedCurrency value={value || 0} />,
      },
    },

    {
      name: 'total_profit',
      label: t('general:total_profit'),
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: centeredCustomCellStyle,
        }),
        setCellHeaderProps: () => ({
          style: centeredCustomCellStyle,
        }),
        customBodyRender: value => <FormattedCurrency value={value || 0} />,
      },
    },

    {
      name: 'total_revenue',
      label: t('general:total_revenue'),
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: centeredCustomCellStyle,
        }),
        setCellHeaderProps: () => ({
          style: centeredCustomCellStyle,
        }),
        customBodyRender: value => <FormattedCurrency value={value || 0} />,
      },
    },
  ];

  const changeSortBy = (sort: string) => {
    setSortBy(sort);
  };

  const changeSortFu = (sort: string) => {
    setSortFU(sort);
  };

  useEffect(() => {
    const sort = {
      [sortBy]: sortFU,
    };
    getProducts({
      ...params,
      sort,
      filters: {
        ...params.filters,
        updated_at: {
          $gte: dateToUtc(start_date),
          $lte: dateToUtc(end_date),
        },
      },
    });
  }, [params, start_date, end_date, getProducts, sortBy, sortFU]);

  const infoLabel = {
    total_sales: `${t('total_sales')}: ${t('sales_table_info')}`,
    total_cost: `${t('total_cost')}: ${t('cost_table_info')}`,
    total_profit: `${t('total_profit')}: ${t('profit_table_info')}`,
    total_revenue: `${t('total_revenue')}: ${t('revenue_table_info')}`,
  };

  return (
    <>
      {processing ? (
        <ActivityIndicator />
      ) : (
        <>
          {data.length > 0 ? (
            <PaginatedTable
              data={tableData}
              columns={columns}
              title={<CustomTableHead label={infoLabel[sortBy]} />}
              options={{
                responsive: 'standard',
                pagination: false,
                elevation: 0,
                filter: true,
                sort: true,
                customFilterDialogFooter: () => (
                  <div style={{ marginTop: '40px', display: 'flex', flexDirection: 'column' }}>
                    <Box display="flex">
                      <Button
                        style={{ backgroundColor: sortFU === 'desc' ? '#f0f0f0' : 'transparent' }}
                        onClick={() => changeSortFu('desc')}
                      >
                        <span style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
                          {t('desc_sort')}
                          <ArrowDownwardIcon fontSize="small" />
                        </span>
                      </Button>
                      <Button
                        style={{ backgroundColor: sortFU === 'asc' ? '#f0f0f0' : 'transparent' }}
                        onClick={() => changeSortFu('asc')}
                      >
                        <span style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
                          {t('asc_sort')}
                          <ArrowUpwardIcon fontSize="small" />
                        </span>
                      </Button>
                    </Box>
                    <Button
                      style={{ backgroundColor: sortBy === 'total_sales' ? '#f0f0f0' : 'transparent' }}
                      onClick={() => changeSortBy('total_sales')}
                    >
                      {t('total_sales')}
                    </Button>
                    <Button
                      style={{ backgroundColor: sortBy === 'total_cost' ? '#f0f0f0' : 'transparent' }}
                      onClick={() => changeSortBy('total_cost')}
                    >
                      {t('total_cost')}
                    </Button>
                    <Button
                      style={{ backgroundColor: sortBy === 'total_profit' ? '#f0f0f0' : 'transparent' }}
                      onClick={() => changeSortBy('total_profit')}
                    >
                      {t('total_profit')}
                    </Button>
                    <Button
                      style={{ backgroundColor: sortBy === 'total_revenue' ? '#f0f0f0' : 'transparent' }}
                      onClick={() => changeSortBy('total_revenue')}
                    >
                      {t('total_revenue')}
                    </Button>
                  </div>
                ),
              }}
            />
          ) : (
            <Typography variant="body2">{t('general:no_data')}</Typography>
          )}
        </>
      )}
    </>
  );
};

export default BestSellerList;
