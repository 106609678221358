import { types } from './types';
import AuthorizedApiClient from '../../core/common/api/AuthorizedApiClient';
import { getProducts, resetProducts } from '../products';
import { SCROLL_PER_PAGE } from '../../constants/common';
import { ApiParams } from '../../types/api';

export const addCostValue = data => ({
  type: types.ADD_PRODUCT_VALUE,
  data,
});

export const updateProducts = (companyId, data) => async (
  dispatch: any,
) => {
  const sg = new AuthorizedApiClient();
  const path = `companies/${companyId}/products/batch`;
  dispatch({ type: types.LOAD_MASIVE_PRODUCTS });
  try {
    const response = await sg.put(data, path);

    if (response) {
      const params: ApiParams = {};
      params.per_page = SCROLL_PER_PAGE;

      await dispatch(resetProducts());

      await dispatch(getProducts(companyId, params));

      await dispatch({ type: types.LOAD_MASIVE_SUCCES });

      await dispatch({ type: types.RESET_MASIVE_STATE });
    }
  } catch (error) {
    dispatch({ type: types.LOAD_MASIVE_FAILED, erro: error });
  }
};
