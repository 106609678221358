/* eslint-disable react/destructuring-assignment */
/* eslint-disable consistent-return */
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useSelector } from 'react-redux';
import { Waypoint } from 'react-waypoint';
import { makeStyles } from '@mui/styles';
import Box from '../../components/ui/Layout/Box';
import ActivityIndicator from '../../components/ui/ActivityIndicator';

const styles = makeStyles(() => ({
  containerSpinner: {
    marginTop: '5%',
  },
}));

type PaginatedScrollProps = {
  children: any;
  loadData: Function;
  resetData?: Function;
  hasNextPage: boolean;
  currentPage: number;
  processing?: boolean;
  filters?: object;
};

function usePreviousFilters(filters) {
  const ref = useRef();
  useEffect(() => {
    ref.current = filters;
  });
  return ref.current;
}

export default (props: PaginatedScrollProps) => {
  const {
    children, hasNextPage, currentPage, filters,
  } = props;
  const classes = styles();
  const [initial, setInitial] = useState(true);

  const { loadData, resetData, processing } = props;
  const companyId = useSelector((state: any) => state.session.companyId);

  const loadMoreData = useCallback(async () => {
    if (hasNextPage && !processing) {
      await loadData({ page: currentPage + 1, filters });
    }
  }, [currentPage, hasNextPage, loadData, processing, filters]);

  useEffect(() => {
    if (resetData) {
      const a = async () => {
        await resetData();
      };
      a();
      return () => {
        resetData();
      };
    }
  }, [resetData]);

  const prevFilters = usePreviousFilters(filters);

  useEffect(() => {
    const a = async () => {
      if (initial && companyId != null) {
        setInitial(false);
        await loadData({ page: 1, filters });
      } else if (!initial && prevFilters !== filters && !processing) {
        // console.log("params", filters)
        resetData();
        await loadData({ page: 1, filters });
      }
    };

    a();
  }, [
    companyId,
    prevFilters,
    loadData,
    initial,
    filters,
    processing,
    resetData,
  ]);

  return (
    <Box>
      {children}
      {processing && (
        <Box mt={5}>
          <ActivityIndicator />
        </Box>
      )}
      {props.hasNextPage && (
        <Waypoint onEnter={loadMoreData}>
          <div className={classes.containerSpinner}>
          &nbsp;
            {/* <ActivityIndicator /> */}
          </div>
        </Waypoint>
      )}
    </Box>
  );
};
