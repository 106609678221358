import { types } from '../../actions/customers/types';
import { PAGINATED_TABLE_PER_PAGE } from '../../constants/common';

export const initialState = {
  response: {
    data: [],
    total: 0,
    per_page: PAGINATED_TABLE_PER_PAGE,
    last_page: 0,
    page: 0,
  },
  processing: true,
  data: [],
  error: null,
  params: {
    page: 1,
    per_page: PAGINATED_TABLE_PER_PAGE,
    sort: { updated_at: 'desc' },
    filters: {},
  },
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case types.SET_CUSTOMERS_PROCESSING:
      return {
        ...state,
        processing: action.payload,
      };

    case types.SET_CUSTOMERS_SUCCESS:
      return {
        ...state,
        response: action.response,
        data: action.response.data,
        error: null,
        processing: false,
      };
    case types.SET_CUSTOMERS_FAILURE:
      return {
        ...state,
        error: action.error,
        processing: false,
      };
    case types.RESET_CUSTOMERS:
      return initialState;

    case types.SAVE_CUSTOMER_SUCCESS:
      return {
        ...state,
        refresh: action.refresh,
      };

    case types.DELETE_CUSTOMER_SUCCESS:
      return {
        ...state,
        refresh: true,
      };

    case types.SET_CUSTOMERS_PARAMS:
      return {
        ...state,
        params: action.params,
      };

    default:
      return state;
  }
};
