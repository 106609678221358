/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/prop-types */
import React from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import {
  Box,
  Grid,
  Typography,
} from '@mui/material';
import Alert from '@mui/material/Alert';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from '@stripe/react-stripe-js';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import CardHolderInput from './CardHolderInput';
import Actions from './Actions';
import Modal from '../../ui/Modals/Modal';

const useStyles = makeStyles(() => ({
  inputBoxDate: {
    width: '100px',
    paddingTop: '5px',
  },
  inputBoxCVC: {
    width: '100px',
    display: 'flex',
  },
  cvcContainer: {
    width: '40px',
    paddingTop: '5px',
  },
  fontSmall: {
    fontSize: 'x-small',
  },
}));

const AddCardFormView = ({
  visible,
  handleClose,
  attemptSaveCard,
  processing,
  error,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const schema = Yup.object({
    card_holder: Yup.string().required(
      t('general:payment_methods:card_holder_required'),
    ),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    attemptSaveCard(data);
  };

  const title = t('general:payment_methods:add_payment_method');
  const actions = (
    <Actions
      handleClose={handleClose}
      handleSubmit={handleSubmit(onSubmit)}
      processing={processing}
    />
  );

  return (
    <Modal open={visible} onClose={handleClose} title={title} actions={actions}>
      <Box p={4}>
        {error && <Alert severity="error">{error.card_holder}</Alert>}
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box mt={2}>
                <CardHolderInput
                  register={register('card_holder')}
                  error={errors.card_holder}
                  label={t('general:payment_methods:card_holder')}
                  defaultValue=""
                />
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box mt={2}>
                <CardNumberElement
                  options={{
                    style: {
                      base: {
                        iconColor: '#000',
                        fontSize: '14px',
                        color: '#000',
                        '::placeholder': {
                          color: '#727272',
                        },
                      },
                      invalid: { color: '#9e2146' },

                    },

                    showIcon: true,
                  }}
                />
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box mt={2} display="flex" flexDirection="row">
                <Box className={classes.inputBoxDate}>
                  <CardExpiryElement />
                </Box>

                <Box className={classes.inputBoxCVC} ml={1}>
                  <Box className={classes.cvcContainer}>
                    <CardCvcElement />
                  </Box>
                  <Box>
                    <CreditCardIcon />
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </form>
        <Box mt={3}>
          <Typography variant="body2" color="textSecondary" className={classes.fontSmall}>

            <Link to="#"><p>{t('general:payment_methods:privacy_message')}</p></Link>
            {' '}
            <Link to="#">
              <p>{t('general:payment_methods:service_conditions')}</p>
            </Link>
            {/* {t("general:payment_methods:conditions1")}{" "}
            {t("general:payment_methods:conditions2")}{" "}
            {t("general:payment_methods:conditions3")} */}

          </Typography>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddCardFormView;
