/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/prop-types */
import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getProducts, resetProducts } from '../../../../../actions/products';
import { SCROLL_PER_PAGE } from '../../../../../constants/common';
import PaginatedScroll from '../../../../../hoc/PaginatedScroll';
import Grid from '../../../../../components/ui/Layout/Grid';
import ProductListItem from '../ProductListItem';

export default ({ onClickProduct }) => {
  const dispatch = useDispatch();
  const state = useSelector((states: any) => states.products);
  const { refresh } = state;
  const company = useSelector((states: any) => states.session.company);

  const _getProducts = useCallback(
    async (params: any = {}) => {
      if (company) {
        params.per_page = SCROLL_PER_PAGE;

        params.embed = 'category,brand,suppliers';
        await dispatch(getProducts(company.id, params));
      }
    },
    [dispatch, company],
  );

  useEffect(() => {
    dispatch(resetProducts());
  }, [dispatch]);

  useEffect(() => {
    if (refresh) {
      dispatch(resetProducts());
      _getProducts();
    }
  }, [refresh, _getProducts, dispatch]);

  const _resetProducts = useCallback(() => {
    dispatch(resetProducts());
  }, [dispatch]);

  const productsList = state.data.map(product => (
    <Grid key={product.id} onClick={() => onClickProduct(product)} item xs={6} sm={3}>
      <ProductListItem product={product} />
    </Grid>
  ));

  return (
    <PaginatedScroll
      hasNextPage={state.response.page < state.response.last_page}
      loadData={params => _getProducts(params)}
      currentPage={state.response.page}
      resetData={_resetProducts}
      processing={state.processing}
    >
      <Grid container spacing={3}>
        {productsList}
      </Grid>
    </PaginatedScroll>
  );
};
