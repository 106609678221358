import { useCallback, useReducer } from 'react';
import AuthorizedApiClient from '../../core/common/api/AuthorizedApiClient';
import { useSession } from '../../util/session';
import { initialState, reducer } from './reducer';
import { types } from './types';

export default function useStore() {
  const { company } = useSession();
  const [state, dispatch] = useReducer(reducer, initialState);
  const { params } = state;

  const getProducts = useCallback(
    async (_params = {}) => {
      if (company) {
        const updatedParams = { ...params, ..._params };
        dispatch({
          type: types.SET_PRODUCTS_LIST_COMPONENT_PARAMS,
          params: updatedParams,
        });

        const sg = new AuthorizedApiClient();
        const path = `companies/${company.id}/products`;

        dispatch({
          type: types.SET_PRODUCTS_LIST_COMPONENT_PROCESSING,
          payload: true,
        });

        try {
          const response = await sg.get(updatedParams, path);

          if (response != null) {
            dispatch({
              type: types.SET_PRODUCTS_LIST_COMPONENT_SUCCESS,
              response: response.data,
            });
          }
        } catch (error) {
          dispatch({
            type: types.SET_PRODUCTS_LIST_COMPONENT_FAILURE,
            erro: error,
          });
        }
      }
    },
    [dispatch, company, params],
  );

  const clearRefresh = useCallback(() => {
    dispatch({
      type: types.CLEAR_PRODUCTS_LIST_COMPONENT_REFRESH,
    });
  }, [dispatch]);

  const addSelected = useCallback(
    (products) => {
      dispatch({
        type: types.PRODUCTS_LIST_COMPONENT_ADD_SELECTED_PRODUCTS,
        products,
      });
    },
    [dispatch],
  );

  const removeSelected = useCallback(
    (products) => {
      dispatch({
        type: types.PRODUCTS_LIST_COMPONENT_REMOVE_SELECTED_PRODUCTS,
        products,
      });
    },
    [dispatch],
  );

  const resetProducts = useCallback(() => {
    dispatch({
      type: types.RESET_PRODUCTS_LIST_COMPONENT,
    });
  }, [dispatch]);

  const handleAddNewClick = useCallback(() => {
    dispatch({
      type: types.PRODUCTS_LIST_COMPONENT_ADD_NEW,
    });
  }, [dispatch]);

  const closeModalModifier = useCallback(() => {
    dispatch({
      type: types.PRODUCTS_LIST_COMPONENT_CLOSE_MODALMODIFER,
    });
  }, [dispatch]);

  return {
    state,
    getProducts,
    resetProducts,
    removeSelected,
    clearRefresh,
    addSelected,
    handleAddNewClick,
    closeModalModifier,
  };
}
