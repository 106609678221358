/* eslint-disable react/prop-types */
import { Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '../../../../../../../../components/ui/Layout/Grid';
import PermissionSelect from './PermissionSelect';

export default ({ user, resource, setPermission }) => {
  const { t } = useTranslation(['general']);

  // console.log("resource", resource);
  return (
    <Grid container spacing={2}>
      <Grid item xs={7}>
        <Typography variant="body2">
          {t(`general:${resource.code}`, resource.name)}
        </Typography>
      </Grid>
      <Grid item xs={5}>
        <PermissionSelect
          setPermission={setPermission}
          resource={resource}
          currentPermissions={user.permissions[resource.code]}
        />
      </Grid>
    </Grid>
  );
};
