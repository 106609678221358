/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import OrdersListView from './OrdersListView';
import {
  clearRefresh,
  getOrders,
  resetOrders,
} from '../../../../../../actions/orders';

interface Props {
  redirectButton?: boolean,
  defaultFilters?: any,
}

export default ({ redirectButton, defaultFilters }: Props) => {
  const dispatch = useDispatch();
  const state = useSelector((states: any) => states.orders);
  const { refresh } = state;
  const company = useSelector((states: any) => states.session.company);
  const { params } = state;
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);

  const _getOrders = useCallback(
    async (_params = {}) => {
      if (company) {
        const updatedParams = { ...params, ..._params };
        // console.log("updatedParams", updatedParams)
        if (updatedParams.filters && updatedParams.filters.campaigns) {
          updatedParams.filters.campaign_id = updatedParams.filters.campaigns;
          delete updatedParams.filters.campaigns;
        }

        if (defaultFilters) {
          updatedParams.filters = { ...updatedParams.filters, ...defaultFilters };
        }
        // dispatch(setParams(updatedParams));

        await dispatch(getOrders(company.id, updatedParams));
      }
    },
    [dispatch, company, params, defaultFilters],
  );

  useEffect(() => {
    if (refresh && company) {
      dispatch(resetOrders());
      dispatch(clearRefresh());
      _getOrders();
    }
  }, [refresh, company, dispatch, _getOrders]);

  return (
    <OrdersListView
      redirectButton={redirectButton}
      state={state}
      getOrders={_getOrders}
      resetOrders={resetOrders || refresh}
      error={error}
      setError={setError}
      message={message}
      setMessage={setMessage}
    />
  );
};
