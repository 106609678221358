export const types = {
  SHOW_PRODUCTS_EXCEl_MODAL: 'SHOW_PRODUCTS_EXCEl_MODAL',
  CLOSE_PRODUCTS_EXCEl_MODAL: 'CLOSE_PRODUCTS_EXCEl_MODAL',
  SET_PEXCEL_UNITS_PROCESSING: 'SET_PEXCEL_UNITS_PROCESSING',
  SET_PEXCEL_UNITS_SUCCESS: 'SET_PEXCEL_UNITS_SUCCESS',
  SET_PEXCEL_UNITS_FAILURE: 'SET_PEXCEL_UNITS_FAILURE',
  SAVING_PRODUCTS_EXCEL: 'SAVING_PRODUCTS_EXCEL',
  SAVE_PRODUCTS_EXCEL_SUCCEED: 'SAVE_PRODUCTS_EXCEL_SUCCEED',
  SAVE_PRODUCTS_EXCEL_FAILED: 'SAVE_PRODUCTS_EXCEL_FAILED',
  SET_PEXCEL_STEP: 'SET_PEXCEL_STEP',
  RESET_PEXCEL: 'RESET_PEXCEL',
  SET_VALID_ROWS: 'SET_VALID_ROWS',
  SET_INVALID_ROWS: 'SET_INVALID_ROWS',
  SET_PEXCEL_ERROR: 'SET_PEXCEL_ERROR',
  SET_PEXCEL_FILE: 'SET_PEXCEL_FILE',
  SET_PEXCEL_READING_EXCEL: 'SET_PEXCEL_READING_EXCEL',
};
