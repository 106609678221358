import { types } from '../../actions/pos/types';
import { getUTCTodayEnd, getUTCTodayStart } from '../../util/dates';

export const initialState = {
  branches: [],
  branch_id: '',
  warning_message: false,
  stock_warning: false,
  sales: {
    load: false,
    data: [],
    failure: '',
    params: {
      filters: {
        updated_at: {
          $gte: getUTCTodayStart(),
          $lte: getUTCTodayEnd(),
        },
      },
    },
  },
};

export default (state = initialState, action: any) => {
  // console.log(state);
  switch (action.type) {
    // case types.SET_BRANCHES_POS_SUCCESS:
    //   return {
    //     ...state,
    //     error: null,
    //     branches: action.response.data,
    //     branch_id: state.branch_id
    // ?  state.branch_id
    // : (action.response.data.length > 0 ? action.response.data[0].id : "")
    //   };

    // case types.SET_BRANCHES_POS_FAILURE:
    //   return {
    //     ...state,
    //     error: action.error,
    //   };
    case types.SET_SALES_PROCESSING:
      return {
        ...state,
        sales: {
          ...state.sales,
          load: action.payload,
        },
      };

    case types.SET_SALES_SUCCESS:
      return {
        ...state,
        sales: {
          ...state.sales,
          load: false,
          data: action.response.data,
          failure: '',
        },
      };
    case types.SET_SALES_FAILURE:
      return {
        ...state,
        sales: {
          ...state.sales,
          load: false,
          data: [],
          failure: action.error,
        },
      };
    case types.RESET_SALES:
      return {
        ...state,
        sales: state.sales,
      };

    case types.SET_BRANCH:
      return {
        ...state,
        branch_id: action.branch_id,
      };

    case types.OPEN_WARNING_MESSAGE:
      return {
        ...state,
        warning_message: action.warning_message,
      };

    default:
      return state;
  }
};
