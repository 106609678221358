/* eslint-disable react/prop-types */
import React from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TextField } from '@mui/material';

const DateFilter = ({
  date = null,
  label = '',
  handleChange,
  // max = new Date(),
  // min = null,
}) => (
  <LocalizationProvider dateAdapter={AdapterDateFns}>
    <DatePicker
      showToolbar={false}
      // variant="inline"
      inputFormat="yyyy/MM/dd"
      // autoOk
      // label={label}
      value={date}
      onChange={handleChange}
      // KeyboardButtonProps={{
      //   'aria-label': 'change date',
      // }}
      disableFuture
      // style={{ width: '95%', padding: 0 }}
      renderInput={params => (
        <TextField {...params} label={label} />
      )}
    />
  </LocalizationProvider>
);

export default DateFilter;
