import { types } from './types';
import AuthorizedApiClient from '../../core/common/api/AuthorizedApiClient';

export const resetUnitOfMeasures = () => ({
  type: types.RESET_UNITOFMEASURES,
});

export const getUnitOfMeasures = (companyId, params = {}) => async (
  dispatch: any,
) => {
  const sg = new AuthorizedApiClient();
  const path = `companies/${companyId}/unitofmeasures`;
  dispatch({ type: types.SET_UNITOFMEASURES_PROCESSING, payload: true });
  try {
    const response = await sg.get(params, path);

    if (response != null) {
      dispatch({ type: types.SET_UNITOFMEASURES_SUCCESS, response: response.data });
    }
  } catch (error) {
    dispatch({ type: types.SET_UNITOFMEASURES_FAILURE, error });
  }
};
