import React, { useState } from 'react';
import { ColorPalette, ColorPicker, createColor } from 'mui-color';
import { useTranslation } from 'react-i18next';
import PaletteOutlinedIcon from '@mui/icons-material/PaletteOutlined';
import { makeStyles } from '@mui/styles';

const Styles = makeStyles(() => ({
  container: {
  },
  colorPickerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  label: {
    marginBottom: '10px',
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  error: {
    color: 'red',
  },
}));
interface CategoryColorProps {
  onChange(color: string): void
  label?: string;
  error?: string;
  defaultValue?: string;
}

const ColorSelect = ({
  onChange, label, error, defaultValue,
}: CategoryColorProps) => {
  const classes = Styles();
  const { t } = useTranslation();
  const [color, setColor] = useState(createColor(defaultValue || '#'));

  const handleChange = (value) => {
    setColor(value);
    onChange(value.hex ? `#${value.hex}` : value);
  };

  const palette: any = {
    [t('color')]: '#EB4A4F',
    [t('color2')]: '#F6A758',
    [t('color3')]: '#F6D763',
    [t('color4')]: '#4BB958',
    [t('color5')]: '#1C83E2',
    [t('color6')]: '#254BBA',
    [t('color7')]: '#A736A0',
    [t('color8')]: '#000000',
    [t('colo9')]: '#48BA96',
    [t('color10')]: '#5AD257',
    [t('color11')]: '#F5889B',
    [t('color12')]: '#e7a355',
    [t('color13')]: '#7cbc00',
  };

  return (
    <section className={classes.container}>
      <p className={classes.label}>
        <PaletteOutlinedIcon fontSize="small" />
        {label}
      </p>
      {
        error && (
          <p className={classes.error}>
            <small>
              {error}
            </small>
          </p>
        )
      }
      <div className={classes.colorPickerContainer}>
        <ColorPicker onChange={handleChange} value={color} defaultValue="transparent" />
        <ColorPalette
          onSelect={colorName => handleChange(createColor(palette[colorName]))}
          palette={palette}
        />
      </div>
    </section>
  );
};

export default ColorSelect;
