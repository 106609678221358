export const types = {
  EDIT_MODIFIER_GROUP: 'EDIT_MODIFIER_GROUP',
  CLOSE_MODIFIER_GROUP_MODAL: 'CLOSE_MODIFIER_GROUP_MODAL',
  SAVING_MODIFIER_GROUP: 'SAVING_MODIFIER_GROUP',
  SAVE_MODIFIER_GROUP_SUCCESS: 'SAVE_MODIFIER_GROUP_SUCCESS',
  SAVE_MODIFIER_GROUP_FAILURE: 'SAVE_MODIFIER_GROUP_FAILURE',
  DELETING_MODIFIER_GROUP: 'DELETING_MODIFIER_GROUP',
  DELETE_MODIFIER_GROUP_SUCCESS: 'DELETE_MODIFIER_GROUP_SUCCESS',
  DELETE_MODIFIER_GROUP_FAILURE: 'DELETE_MODIFIER_GROUP_FAILURE',
  CLEAR_REFRESH: 'CLEAR_REFRESH',
  SET_MODIFIERS: 'SET_MODIFIERS',
  SET_ASSOCIATED_PRODUCTS: 'SET_ASSOCIATED_PRODUCTS',
  SET_MODIFIER_GROUP_SUCCESS: 'SET_MODIFIER_GROUP_SUCCESS',
  SET_MODIFIER_GROUP_FAILURE: 'SET_MODIFIER_GROUP_FAILURE',
};
