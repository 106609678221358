/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-len */
import { BusinessType } from '../../constants/common';

export enum WizardSteps {
  FOOD = 1,
  BUSINESS_TYPE = 2,
  CATEGORIES = 3,
  LOGISTIC = 4,
  DELIVERY_DISTANCE = 5,
  INVOICES = 6,
  PRODUCTION_VOLUME = 7,
  CUSTOMER_TYPES = 8,
  KEYWORDS = 9,
  BRANCH = 10,
  FINAL = 11,
}

export const requireProductionVolume = bt => bt === BusinessType.PROCESOR || bt === BusinessType.FARMER;

export const requireCustomerType = bt => true;
