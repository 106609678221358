import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { MenuTabs } from '../../../../components/ui/MenuTabs';
import { PERMISSION, RESOURCES } from '../../../../constants/common';
import PageContainer from '../../../../hoc/PageContainer';
import { useSession } from '../../../../util/session';

import ProductsList from './ProductsReports/ProductsList';
import MovementsList from './MovementsReports/MovementsList';
import SalesList from './SalesReports/SalesList';
import ContentHeader from '../../../../components/ui/ContentHeader';

export default () => {
  const { t } = useTranslation(['general']);
  const { can } = useSession();

  const tabs = [];

  if (can(RESOURCES.products_reports, PERMISSION.read)) {
    tabs.push({
      title: t('general:products_reports'),
      icon: 'existenceReports',
      render: <ProductsList />,
      index: 0,
    });
  }
  if (can(RESOURCES.movements, PERMISSION.read)) {
    tabs.push({
      title: t('general:movements_reports'),
      icon: 'movimentReports',
      render: <MovementsList />,
      index: 1,
    });
  }

  if (can(RESOURCES.sales, PERMISSION.read)) {
    tabs.push({
      title: t('general:sales_reports'),
      icon: 'salesReports',
      render: <SalesList />,
      index: 2,
    });
  }

  return (
    <main>
      <Grid item xs={12}>
        <ContentHeader title={t('general:reports')} section="reports" back />
      </Grid>
      <PageContainer>
        <div>
          <MenuTabs
            data={tabs}
            selectColor="#A174CB"
            colorBar="#A174CB"
          />
        </div>
      </PageContainer>
      {/* <ReportsPageView /> */}
    </main>

  );
};
