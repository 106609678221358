import { types } from '../../actions/products_excel/types';

export const initialState = {
  showModal: false,
  units: null,
  processing: false,
  readingExcel: false,
  savedProducts: false,
  inserted: [],
  failed: [],
  validRows: [],
  invalidRows: [],
  error: null,
  enable: false,
  file: null,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case types.SHOW_PRODUCTS_EXCEl_MODAL:
      return {
        ...state,
        showModal: true,
      };

    case types.CLOSE_PRODUCTS_EXCEl_MODAL:
      return initialState;

    case types.SET_PEXCEL_UNITS_PROCESSING:
      return {
        ...state,
        loadingUnitsOfMeasures: action.payload,
      };

    case types.SET_PEXCEL_READING_EXCEL:
      return {
        ...state,
        readingExcel: action.reading,
      };

    case types.SET_PEXCEL_UNITS_SUCCESS:
      return {
        ...state,
        loadingUnitsOfMeasures: false,
        units: action.response.data,
      };
    case types.SET_PEXCEL_UNITS_FAILURE:
      return {
        ...state,
        loadingUnitsOfMeasures: false,
        error: action.error,
      };

    case types.SAVE_PRODUCTS_EXCEL_SUCCEED:
      return {
        ...state,
        failed: action.response.data.failed,
        inserted: action.response.data.inserted,
        savedProducts: true,
        error: null,
        processing: false,
      };
    case types.SAVE_PRODUCTS_EXCEL_FAILED:
      return {
        ...state,
        error: action.error,
        processing: false,
      };
    case types.SET_PEXCEL_ERROR:
      return {
        ...state,
        error: action.error,
      };

    case types.SET_PEXCEL_FILE:
      return {
        ...state,
        file: action.file,
      };

    case types.SET_VALID_ROWS:
      return {
        ...state,
        validRows: action.validRows,
      };

    case types.SET_INVALID_ROWS:
      return {
        ...state,
        invalidRows: action.invalidRows,
      };

    case types.SAVING_PRODUCTS_EXCEL:
      return {
        ...state,
        processing: true,
      };

    case types.RESET_PEXCEL:
      return {
        ...initialState,
        showModal: state.showModal,
        units: state.units,
      };

    default:
      return state;
  }
};
