/* eslint-disable @typescript-eslint/return-await */
import KeyGatewayContract from '../../contracts/gateways/KeyGatewayContract';
import LocalKeyService from '../../services/local/LocalKeyService';

export default class LocalKeyGateway implements KeyGatewayContract {
  private static instance: LocalKeyGateway = null;

  private service;

  private constructor() {
    this.service = new LocalKeyService();
  }

  public static getInstance() {
    if (LocalKeyGateway.instance === null) {
      LocalKeyGateway.instance = new LocalKeyGateway();
    }

    return LocalKeyGateway.instance;
  }

  saveToken(data: any) {
    return this.service.create(data);
  }

  getToken() {
    return this.service.get();
  }

  async clear() {
    return await this.service.clear();
  }
}
