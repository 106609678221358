/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import Box from '../../../../../components/ui/Layout/Box';
import ConfirmDialog from '../../../../../components/ui/ConfirmDialog';
import Grid from '../../../../../components/ui/Layout/Grid';
import Alert from '../../../../../components/ui/notifications/alert';
import FormInput from '../../../../../components/ui/FormInput';
import { rules as validationRules } from '../../../../../util/Validations';
import Modal from '../../../../../components/ui/Modals/Modal';
import Actions from '../../../../../components/ui/Modals/Actions/Actions';
import Select from '../../../../../components/ui/FormInput/Select';

export default ({
  location,
  attemptSaveLocation,
  attemptDeleteLocation,
  processing,
  deletingLocation,
  error,
  closeModalLocation,
  branchesOptions,
}) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const { showModal } = useSelector((state: any) => state.location);
  const { t } = useTranslation(['locations', 'translation', 'general']);

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(closeModalLocation());
  };

  const title = location.id == null
    ? t('locations:create_location')
    : t('locations:edit_location', { name: location.name });

  const schema = Yup.object({
    branch_id: Yup.string().required(
      t('translation:required', {
        input: t('locations:branch'),
      }),
    ),
    name: Yup.string()
      .max(validationRules.maxLength)
      .required(
        t('translation:required', {
          input: t('locations:name_location'),
        }),
      ),
    description: Yup.string().max(validationRules.maxLength),
  });

  const {
    register, handleSubmit, formState: { errors }, control,
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    data.id = location.id ? location.id : null;

    attemptSaveLocation(data);
  };

  const actions = (
    <Actions
      handleClose={handleClose}
      handleSubmit={handleSubmit(onSubmit)}
      handleDelete={() => setShowConfirm(true)}
      isNew={location.id === null}
      processing={processing}
      deleting={deletingLocation}
    />
  );

  return (
    <Modal
      open={showModal}
      onClose={handleClose}
      title={title}
      actions={actions}

    >
      <Box>
        <ConfirmDialog
          open={showConfirm}
          handleCancel={() => {
            setShowConfirm(false);
          }}
          handleConfirm={() => {
            setShowConfirm(false);
            attemptDeleteLocation(location);
          }}
          title={t('location:delete_location')}
          content={t('locations:delete_confirmation_msg')}
        />
        {error && (
          <Grid container>
            <Alert severity="error" message={error} />
          </Grid>
        )}
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Select
                control={control}
                defaultValue={location.branch_id ? location.branch_id : ''}
                name="branch_id"
                options={branchesOptions}
                error={errors.branch_id}
                label={t('locations:branch')}
              />
            </Grid>

            <Grid item xs={12}>
              <FormInput
                register={register('name')}
                error={errors.name}
                label={t('locations:name_location')}
                defaultValue={location.name ? location.name : ''}
              />
            </Grid>

            <Grid item xs={12}>
              <FormInput
                register={register('description')}
                error={errors.description}
                label={t('locations:description')}
                defaultValue={location.description ? location.description : ''}
              />
            </Grid>
          </Grid>
        </form>
      </Box>
    </Modal>
  );
};
