/* eslint-disable prefer-const */
import { types } from './types';
import Log from '../../util/Log';
import AuthorizedApiClient from '../../core/common/api/AuthorizedApiClient';

export const savingMovement = () => ({
  type: types.SAVING_MOVEMENT,
});

export const setProductMovement = product => ({
  type: types.SET_PRODUCT_MOVEMENT,
  product,
});

export const saveMovementSucceed = response => ({
  type: types.SAVE_MOVEMENT_SUCCESS,
  response,
});

export const saveMovementFailed = error => ({
  type: types.SAVE_MOVEMENT_FAILURE,
  error,
});

export const closeModalMovement = () => ({
  type: types.CLOSE_MOVEMENT_MODAL,
});

export const showModalMovement = () => ({
  type: types.SHOW_MOVEMENT_MODAL,
});

export const saveMovement = (data, companyId) => async (dispatch: any) => {
  dispatch(savingMovement());
  const sg = new AuthorizedApiClient();
  const path = `companies/${companyId}/movements`;

  try {
    let response;

    response = await sg.post(data, path);

    if (response != null) {
      Log.debug('saved movement', response);
      dispatch(saveMovementSucceed(response));
      dispatch(closeModalMovement());
    }
  } catch (error) {
    Log.error(error.response.data);
    dispatch(saveMovementFailed(error.response.data));
  }
};

export const getProductsForMovement = (companyId, params = {}) => async (
  dispatch: any,
) => {
  const sg = new AuthorizedApiClient();
  const path = `companies/${companyId}/products`;
  dispatch({ type: types.SET_PRODUCTS_MOVEMENT_PROCESSING, payload: true });
  try {
    const response = await sg.get(params, path);

    if (response != null) {
      dispatch({ type: types.SET_PRODUCTS_MOVEMENT_SUCCESS, response: response.data });
    }
  } catch (error) {
    dispatch({ type: types.SET_PRODUCTS_MOVEMENT_FAILURE, erro: error });
  }
};

export const getLocationsForMovement = (companyId, params = {}) => async (
  dispatch: any,
) => {
  const sg = new AuthorizedApiClient();
  const path = `companies/${companyId}/locations`;
  dispatch({ type: types.SET_LOCATIONS_MOVEMENT_PROCESSING, payload: true });
  try {
    const response = await sg.get(params, path);

    if (response != null) {
      dispatch({ type: types.SET_LOCATIONS_MOVEMENT_SUCCESS, response: response.data });
    }
  } catch (error) {
    dispatch({ type: types.SET_LOCATIONS_MOVEMENT_FAILURE, erro: error });
  }
};
