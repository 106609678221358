import { combineReducers } from 'redux';
import session from './session';
import theme from './theme';
import auth from './auth';
import branches from './branches';
import branch from './branch';
import locations from './locations';
import location from './location';
import register from './register';
import categories from './categories';
import category from './category';

import brands from './brands';
import brand from './brand';

import unitofmeasure from './unitofmeasure';
import unitofmeasures from './unitofmeasures';

import suppliers from './suppliers';
import supplier from './supplier';

import products from './products';
import product from './product';
import product_category from './product_category';
import product_brand from './product_brand';
import product_unit from './product_unit';
import product_supplier from './product_supplier';
import products_excel from './products_excel';
import products_report from './products_report';

import modifier_group from './modifier_group';
import modifiers_groups from './modifiers_groups';

import movements from './movements';
import movements_report from './movements_report';
import movement from './movement';

import cart from './cart';
import pos from './pos';

import customers from './customers';
import customer from './customer';

import search from './search';

import count from './count';
import count_movements from './count_movements';

import users from './users';
import user from './user';
import profile from './profile';
import password from './password';
import productsCost from './productsCost';

import resources from './resources';
import activitis from './activitis';

import store_profile from './store_profile';

import sales from './sales';
import sale from './sale';

import orders from './orders';
import order from './order';

import wizard from './wizard';

import notification from './notification';

const rootReducer = combineReducers({
  notification,
  session,
  theme,
  auth,
  branches,
  branch,
  locations,
  location,
  register,
  categories,
  category,
  products,
  product,
  suppliers,
  supplier,
  brands,
  brand,
  unitofmeasures,
  unitofmeasure,
  movements,
  movements_report,
  movement,
  cart,
  pos,
  customers,
  customer,
  search,
  count,
  count_movements,
  users,
  user,
  profile,
  password,
  productsCost,
  resources,
  product_category,
  product_brand,
  product_unit,
  product_supplier,
  products_excel,
  products_report,
  activitis,
  store_profile,
  sales,
  sale,
  orders,
  order,
  wizard,
  modifier_group,
  modifiers_groups,
});
export default rootReducer;
