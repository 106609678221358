/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-case-declarations */
import { types } from '../../actions/count/types';

export const initialState = {
  counted: [],
  showSummary: false,
  error: null,
  processing: false,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case types.ADD_COUNT_ITEM:
      let _counted;
      if (
        state.counted.find(_c => (
          _c.variant_id === action.item.variant_id
            && _c.location_id === action.item.location_id
        ))
      ) {
        _counted = state.counted.map((_c) => {
          if (
            _c.variant_id === action.item.variant_id
            && _c.location_id === action.item.location_id
          ) {
            return {
              ..._c,
              counted_quantity: action.item.counted_quantity,
              discrepancy: action.item.discrepancy,
            };
          }

          return _c;
        });
      } else {
        _counted = [...state.counted, action.item];
      }
      return {
        ...state,
        counted: _counted,
      };

    case types.REMOVE_COUNT_ITEM:
      return {
        ...state,
        counted: state.counted.filter(
          _c => !(
            _c.variant_id === action.item.variant_id
              && _c.location_id === action.item.location_id
          ),
        ),
      };

    case types.SHOW_COUNT_MOVEMENTS_SUMMARY:
      return {
        ...state,
        showSummary: true,
      };

    case types.CLOSE_COUNT_MOVEMENTS_SUMMARY:
      return {
        ...state,
        showSummary: false,
      };

    case types.SAVING_COUNT_MOVEMENTS:
      return {
        ...state,
        processing: true,
      };

    case types.SET_COUNT_MOVEMENTS_FAILURE:
      return {
        ...state,
        processing: false,
        error: action.error,
      };

    case types.SET_COUNT_MOVEMENTS_SUCCESS:
      return initialState;

    case types.CLEAR_COUNT:
      return initialState;

    default:
      return state;
  }
};
