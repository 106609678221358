import React from 'react';
import EcubiTitle from '../../../ui/EcubiTitle';
import styles from './TopBar.module.css';
import Box from '../../../ui/Layout/Box';

export default () => (
  <Box className={`text-center ${styles.TopBar}`}>
    <EcubiTitle />
  </Box>
);
