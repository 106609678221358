import { Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import Box from '../ui/Layout/Box';
import Grid from '../ui/Layout/Grid';
import BoxAnswer from './BoxAnswer';
import { setInvoice, setWizardStep } from '../../actions/wizard';
import { WizardSteps } from './steps';
import SuccessButton from '../ui/Buttons/SuccessButton';

const useStyle = makeStyles(() => ({
  questionsContainer: {
    width: '70%',
    marginLeft: 'auto',
    marginRight: 'auto',
    minWidth: '220px',
  },
}));

const InvoicesSection = () => {
  const classes = useStyle();
  const { t } = useTranslation(['general']);
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(null);

  const handleInvoiceChange = (value) => {
    setSelected(value);
  };

  const handleSubmit = () => {
    dispatch(setInvoice(selected));
    dispatch(setWizardStep(WizardSteps.KEYWORDS));
  };

  const handleYesClick = () => {
    handleInvoiceChange(true);
  };

  const handleNoClick = () => {
    handleInvoiceChange(false);
  };
  return (
    <>
      <Box>
        <Typography variant="h6" align="center" color="textSecondary">
          {t('general:wizard_setup:invoices_question')}
        </Typography>
      </Box>

      <Box m={5} className={classes.questionsContainer} pt={5}>
        <Grid container spacing={3}>
          <Grid item xs={6} container justifyContent="flex-start">
            <BoxAnswer
              label={t('general:yes')}
              handleOnCLick={handleYesClick}
              selected={selected === true}
            />
          </Grid>
          <Grid item xs={6} container justifyContent="flex-end">
            <BoxAnswer
              label={t('general:no')}
              handleOnCLick={handleNoClick}
              selected={selected === false}
            />
          </Grid>
        </Grid>
      </Box>

      <Box mt={2} display="flex" justifyContent="center">
        <SuccessButton
          text={t('general:next')}
          onClick={handleSubmit}
          disabled={selected === null}
        />
      </Box>
    </>
  );
};

export default InvoicesSection;
