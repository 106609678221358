import { types } from './types';
import AuthorizedApiClient from '../../core/common/api/AuthorizedApiClient';
import fileDownloader from '../../helpers/file-downloader';

const sg = new AuthorizedApiClient();

const EXCEL_FILE_NAME = 'reporte_existencias.xlsx';

export const resetProducts = () => ({
  type: types.RESET_PRODUCTS_REPORT,
});

export const clearRefresh = () => ({
  type: types.CLEAR_PRODUCTS_REPORT_REFRESH,
});

export const setParams = params => ({
  type: types.SET_PRODUCTS_REPORT_PARAMS,
  params,
});

const getPath = (companyId: string) => `companies/${companyId}/products/stock`;

export const downloadProducts = (companyId, params = {}) => async (
  dispatch: any,
) => {
  const path = getPath(companyId);

  try {
    const fileName = EXCEL_FILE_NAME;

    const response = await sg.downloadFile(params, path);

    fileDownloader.downloadFileInBrowser(response, fileName);
  } catch (error) {
    dispatch({ type: types.SET_PRODUCTS_REPORT_FAILURE, erro: error });
  }
};

export const getProducts = (companyId, params = {}) => async (
  dispatch: any,
) => {
  const path = getPath(companyId);

  dispatch({ type: types.SET_PRODUCTS_REPORT_PROCESSING, payload: true });
  try {
    const response = await sg.get(params, path);

    if (response != null) {
      dispatch({ type: types.SET_PRODUCTS_REPORT_SUCCESS, response: response.data });
    }
  } catch (error) {
    dispatch({ type: types.SET_PRODUCTS_REPORT_FAILURE, erro: error });
  }
};
