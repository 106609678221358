/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import ConfirmDialog from '../../../../../../components/ui/ConfirmDialog';

const ChangeOrderStatusConfirm = ({
  open,
  handleCancel,
  handleConfirm,
  newStatus,
  order,
}) => {
  const { t } = useTranslation(['general']);
  const content = (
    <Typography>
      {t('general:orders_page:change_status_msg_1')}
      <strong>{order.number}</strong>
      {t('general:orders_page:change_status_msg_2')}
      <strong>
        {t(`general:orders_page:${newStatus}`, newStatus)}
        .
      </strong>
    </Typography>
  );
  return (
    <ConfirmDialog
      open={open}
      handleCancel={handleCancel}
      handleConfirm={handleConfirm}
      title={t('general:orders_page:change_status_title')}
      content={content}
    />
  );
};

export default ChangeOrderStatusConfirm;
