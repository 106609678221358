/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BranchesListView from './BranchesListView';
import {
  getBranches,
  resetBranches,
} from '../../../../../actions/branches';
import { SCROLL_PER_PAGE } from '../../../../../constants/common';

export default () => {
  const dispatch = useDispatch();
  const state = useSelector((states: any) => states.branches);
  const company = useSelector((states: any) => states.session.company);

  const _getBranches = useCallback(
    async (params: any = {}) => {
      if (company) {
        params.per_page = SCROLL_PER_PAGE;
        await dispatch(getBranches(company.id, params));
      }
    },
    [dispatch, company],
  );

  const _resetBranches = useCallback(() => {
    dispatch(resetBranches());
  }, [dispatch]);

  return (
    <BranchesListView
      state={state}
      getBranches={params => _getBranches(params)}
      resetBranches={_resetBranches}
    />
  );
};
