/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useCallback, useEffect } from 'react';
import { CircularProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import HistoryDetails from './HistoryDetails';
import Hour from './Hour';
import HistoryPointTracket from './HistoryPointTracket';
import { getActivitis, resetActivitis } from '../../../../../actions/activitis';

const useStyle = makeStyles(theme => ({
  container: {
    boxSizing: 'border-box',
    margin: '10px auto 10px auto',
    padding: 13,
    width: '96%',
    border: '1px solid #CCC',
    borderRadius: 15,
    boxShadow: theme.shadow.main,
    overflow: 'hidden',
  },
  content: {
    display: 'grid',
    gridTemplateColumns: '16% 23% 61%',
    alignItems: 'center',
  },
  processing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
export default () => {
  const classes = useStyle();

  const dispatch = useDispatch();
  const state = useSelector((states: any) => states.activitis);
  const company = useSelector((states: any) => states.session.company);
  const { params, refresh } = state;

  const _getActivitis = useCallback(
    async () => {
      const _params = {
        ...params,
      };
      if (company) {
        await dispatch(getActivitis(company.id, _params));
      }
    },
    [dispatch, company, params],
  );

  useEffect(() => {
    if (company) {
      dispatch(resetActivitis());
      _getActivitis();
    }
  }, [company, _getActivitis, refresh, dispatch]);

  // state.processing? console.log("cargando...") : console.log(state.data)

  const activitis = state.data.map((activity, index) => (
    <div className={classes.content} key={activity.id}>
      <Hour
        date={activity.created_at}
      />
      <HistoryPointTracket
        section={activity.event.split(':')[0]}
        line={index < 4}
      />
      <HistoryDetails
        {...activity}
      />
    </div>
  ));
  return (
    <div className={`${classes.container} ${state.processing && classes.processing}`}>
      {state.processing ? <CircularProgress /> : activitis}
    </div>
  );
};
