/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox } from '@mui/material';
import { Controller } from 'react-hook-form';
import Grid from '../../../../../components/ui/Layout/Grid';
import Text from '../../../../../components/Text/Text';

const AvailableSwitch = ({ control }) => {
  const { t } = useTranslation('general');

  return (
    <Controller
      control={control}
      defaultValue={false}
      name="available"
      render={({
        field: {
          onChange, onBlur, value, ref,
        },
      }) => (
        <Grid container alignItems="center" style={{ gap: '1rem' }}>
          <Checkbox
            onBlur={onBlur}
            onChange={onChange}
            checked={value}
            inputRef={ref}
          />
          <Text>
            {t('general:available')}
          </Text>
        </Grid>
      )}
    />
  );
};

export default AvailableSwitch;
