/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';

const useStyle = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: 10,
    width: 166,
    [theme.breakpoints.down('md')]: {
      margin: '15px 10px',
    },

  },
  card: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: theme.shadow.main,
    borderRadius: 15,
    width: 166,
    height: 166,
    [theme.breakpoints.down('md')]: {
      width: 105,
      height: 105,
    },
  },
  tittle: {
    marginTop: 5,
    fontSize: 20,
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
    },
  },
  products: {
    backgroundColor: theme.colors.inventory.main,
    mask: `url(${theme.icons.products})`,
  },
  categories: {
    backgroundColor: theme.colors.inventory.main,
    mask: `url(${theme.icons.categories})`,
  },
  cost: {
    backgroundColor: theme.colors.inventory.main,
    mask: `url(${theme.icons.cost})`,
  },
  brands: {
    backgroundColor: theme.colors.inventory.main,
    mask: `url(${theme.icons.brands})`,
  },
  suppliers: {
    backgroundColor: theme.colors.inventory.main,
    mask: `url(${theme.icons.providers})`,
  },
  units: {
    backgroundColor: theme.colors.inventory.main,
    mask: `url(${theme.icons.units})`,
  },
  movements: {
    backgroundColor: theme.colors.inventory.main,
    mask: `url(${theme.icons.movements})`,
  },
  recounts: {
    backgroundColor: theme.colors.orange.main,
    mask: `url(${theme.icons.recounts})`,
  },
  locations: {
    backgroundColor: theme.colors.inventory.main,
    mask: `url(${theme.icons.locations})`,
  },
  branches: {
    backgroundColor: theme.colors.inventory.main,
    mask: `url(${theme.icons.branches})`,
  },
  clients: {
    backgroundColor: theme.colors.clients.main,
    mask: `url(${theme.icons.people})`,
  },
  span: {
    display: 'block',
    width: '80%',
    height: '80%',
    maskSize: 'auto',
    maskRepeat: 'no-repeat',
    maskOrigin: 'border-box',
    maskPosition: 'center',
  },
}));

export default (props) => {
  const classes = useStyle();
  return (
    <div className={classes.container}>
      <Link to={props.link}>
        <div className={classes.card}>
          <span className={`${classes[props.section]} ${classes.span}`} />
        </div>
      </Link>
      <p className={classes.tittle}>
        {props.title}
      </p>
    </div>
  );
};
