export const generateKey = pre => `${pre}_${new Date().getTime()}_${Math.random()}`;

export const generateRamdomNumber = () => Math.floor(Math.random() * 1000) + 1;

export function generatePasswordRand(length, type = 'characters') {
  let characters;
  switch (type) {
    case 'num':
      characters = '0123456789';
      break;
    case 'alf':
      characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
      break;
    default:
      characters = `abcdefghijklmnopqrstuvwxyz${new Date().getTime()}`;
      break;
  }
  let pass = '';
  for (let i = 0; i < length; i += 1) {
    pass += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return pass;
}
