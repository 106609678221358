export const types = {
  EDIT_CATEGORY: 'EDIT_CATEGORY',
  CLOSE_CATEGORY_MODAL: 'CLOSE_CATEGORY_MODAL',
  SAVING_CATEGORY: 'SAVING_CATEGORY',
  SAVE_CATEGORY_SUCCESS: 'SAVE_CATEGORY_SUCCESS',
  SAVE_CATEGORY_FAILURE: 'SAVE_CATEGORY_FAILURE',
  DELETING_CATEGORY: 'DELETING_CATEGORY',
  DELETE_CATEGORY_SUCCESS: 'DELETE_CATEGORY_SUCCESS',
  DELETE_CATEGORY_FAILURE: 'DELETE_CATEGORY_FAILURE',
  CLEAR_REFRESH: 'CLEAR_REFRESH',
};
