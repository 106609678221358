/* eslint-disable react/destructuring-assignment */
import React from 'react';
import MUIDataTable from 'mui-datatables';
import { Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';

const useStyle = makeStyles(theme => ({
  root: {
    position: 'relative',
  },
  '@global': {
    '.MuiTableCell-head': {
      color: theme.palette.secondary.dark,
    },
    '.MuiTableCell-body': {
      color: theme.palette.secondary.dark,
      fontWeight: 'normal',
    },
    '.MuiTablePagination-actions': {
      marginLeft: '0',
    },
    '.MuiChip-root': {
      display: 'none',
    },
  },
}));

const defaultOptions = {
  filter: false,
  search: false,
  print: false,
  download: false,
  viewColumns: false,
  filterType: 'dropdown',
  fixedHeader: true,
  selectableRows: 'none',
  pagination: true,
  onRowClick() {
  },
  serverSide: false,

  textLabels: {
    body: {
      noMatch: '',
    },
    pagination: {},
  },
};

interface PaginatedTableProps {
  data: Array<any>;
  columns: Array<any>;
  options?: object;
  components?: any;
  title?: any;
  elevation?: number;
}

export default ({
  columns, data, elevation, ...props
}: PaginatedTableProps) => {
  const classes = useStyle();
  const { t } = useTranslation(['general']);

  defaultOptions.textLabels.pagination = {
    next: t('general:pagination:next'),
    previous: t('general:pagination:previous'),
    rowsPerPage: t('general:pagination:per_page'),
    displayRows: t('general:pagination:of'),
  };

  const options = { ...defaultOptions, ...props.options };

  return (
    <Paper elevation={elevation ?? 1} className={classes.root}>
      <MUIDataTable
        title={props.title}
        components={props.components}
        data={data}
        columns={columns}
        options={options}
      />
    </Paper>
  );
};
