import React, { useCallback, useState } from 'react';
import Modal from '../../../components/ui/Modals/Modal';
import Wizard from '../../../components/Wizard';
import { useSession } from '../../../util/session';

// eslint-disable-next-line react/prop-types
const AuthenticatedLayout = () => {
  const { company } = useSession();
  const [showWizard, setShowWizard] = useState(!company.setup);

  const handleCloseWizard = useCallback(() => {
    setShowWizard(false);
  }, []);
  // const { id } = company;
  // useEffect(() => {
  //   if (company && !company.setup) {
  //     setShowWizard(true);
  //   } else {
  //     setShowWizard(false);
  //   }
  // }, [company, id]);
  return (
    <Modal
      open={showWizard}
      onClose={handleCloseWizard}
      title=""
      fullScreen
    >
      <Wizard onFinish={handleCloseWizard} />
    </Modal>
  );
};

export default AuthenticatedLayout;
