/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetProducts, getProducts } from '../../../../../actions/products';
import { closeProductsExcelModal } from '../../../../../actions/products_excel';
import { SCROLL_PER_PAGE } from '../../../../../constants/common';
import { useSession } from '../../../../../util/session';
import ProductsExcelView from './ProductsExcelView';

export default () => {
  const { showModal } = useSelector((state: any) => state.products_excel);
  const dispatch = useDispatch();

  const { company } = useSession();

  const _getProducts = useCallback(() => {
    dispatch(resetProducts());
    dispatch(getProducts(company.id, { page: 1, per_page: SCROLL_PER_PAGE }));
  }, [dispatch, company]);

  const handleClose = () => {
    _getProducts();
    dispatch(closeProductsExcelModal());
  };
  return <ProductsExcelView handleClose={handleClose} showModal={showModal} />;
};
