/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import { CircularProgress, TextField } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Autocomplete from '@mui/material/Autocomplete';
import { SelectOption } from '../FormInput/Select';
import AuthorizedApiClient from '../../../core/common/api/AuthorizedApiClient';
import { AutoCompleteOption } from '../FormInput/AutoComplete';

interface ProductsSelectorProps {
  company_id: string;

  setProduct: Function;
}

export default ({
  company_id,

  setProduct,
}: ProductsSelectorProps) => {
  const { t } = useTranslation(['general']);

  const [productsOptions, setProductsOptions] = useState<SelectOption[]>([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState('');
  const [open, setOpen] = React.useState(false);

  const _handleOnChange = (event, value) => {
    handleOnChange(value);
  };

  const _getProducts = useCallback(async (companyId, filter) => {
    const sg = new AuthorizedApiClient();
    const path = `companies/${companyId}/products`;
    setLoading(true);
    try {
      const params = { filters: {} };

      if (filter) {
        params.filters['name'] = filter;
      }

      const response = await sg.get(params, path);

      if (response != null) {
        const products = response.data.data;
        const _products = products.map(b => ({
          value: b.id,
          label: b.name,
        } as AutoCompleteOption));

        setProductsOptions(_products);

        setLoading(false);
      }
    } catch (error) {
      setProductsOptions([]);
      setLoading(false);
    }
  }, []);

  const handleOnChange = (event) => {
    setProduct(event || null);
  };

  useEffect(() => {
    if (open && company_id) {
      _getProducts(company_id, filter);
    }
  }, [_getProducts, company_id, filter, open]);

  return (
    <Autocomplete
      onChange={_handleOnChange}
      inputValue={filter !== '-1' ? filter : null}
      onInputChange={(event, newInputValue) => {
        setFilter(newInputValue);
      }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) => option.label === value.label}
      getOptionLabel={option => option.label}
      options={productsOptions}
      loading={loading}
      forcePopupIcon={false}
      fullWidth
      defaultValue={null}
      renderInput={params => (
        <TextField
          style={{ marginTop: '5px' }}
          {...params}
          placeholder={t('general:product')}
          variant="outlined"
          fullWidth
          InputProps={{
            ...params.InputProps,

            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};
