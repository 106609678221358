/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';

const styles = makeStyles(() => ({
  productName: {
    fontSize: '.7rem',
  },
}));

interface ProductName {
  name: string
}
export default ({ name } : ProductName) => {
  const classes = styles();
  const limit: number = 26;
  const _name = (name.length > limit) ? `${name.substring(0, limit)}...` : name;
  return (
    <Typography align="center" className={classes.productName}>
      {_name}
    </Typography>
  );
};
