/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { CircularProgress } from '@mui/material';
import PaginatedTable from '../../../../../components/ui/PaginatedTable';
import { StatusIndicator } from '../../../../../components/StatusIndicator/StatusIndicator';
import useRequest from '../../../../../hooks/useRequest';
import useCompany from '../../../../../hooks/useCompany';

const useStyles = makeStyles(() => ({
  cellTable: { padding: '.5rem', textAlign: 'center' },
}));

const formatCurrency = new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' });

export const OrdersTable = () => {
  const classes = useStyles();
  const { t } = useTranslation('general');
  const { company } = useCompany();
  const ordersRequest = useRequest({ processingInit: true, auth: true, apiVerison: 'v1.1' });

  useEffect(() => {
    ordersRequest.get(`businesses/${company.id}/orders`);
  }, []);

  const columns = [
    {
      name: 'id',
      label: t('routes.delivery_table.id_delivery'),
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          className: classes.cellTable,
        }),
        setCellHeaderProps: () => ({
          className: classes.cellTable,
        }),
      },
    },
    {
      name: 'customer',
      label: t('routes.delivery_table.client'),
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          className: classes.cellTable,
        }),
        setCellHeaderProps: () => ({
          className: classes.cellTable,
        }),
        customBodyRender: customer => customer.name,
      },
    },
    {
      name: 'status',
      label: t('routes.delivery_table.status'),
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          className: classes.cellTable,
        }),
        setCellHeaderProps: () => ({
          className: classes.cellTable,
        }),
        customBodyRender: status => (
          <StatusIndicator color="#82ca9d" label={status.title} />
        ),
      },
    },
    {
      name: 'delivery_min_date',
      label: t('routes.delivery_table.delivery_min_date'),
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          className: classes.cellTable,
        }),
        setCellHeaderProps: () => ({
          className: classes.cellTable,
        }),
      },
      customBodyRender: date => format(new Date(date), 'dd/MM/yyyy'),
    },
    {
      name: 'delivery_max_date',
      label: t('routes.delivery_table.delivery_max_date'),
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          className: classes.cellTable,
        }),
        setCellHeaderProps: () => ({
          className: classes.cellTable,
        }),
        customBodyRender: date => format(new Date(date), 'dd/MM/yyyy'),
      },
    },
    {
      name: 'order_date',
      label: t('routes.delivery_table.order_date'),
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          className: classes.cellTable,
        }),
        setCellHeaderProps: () => ({
          className: classes.cellTable,
        }),
        customBodyRender: date => format(new Date(date), 'dd/MM/yyyy'),
      },
    },
    {
      name: 'total',
      label: t('routes.delivery_table.total'),
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          className: classes.cellTable,
        }),
        setCellHeaderProps: () => ({
          className: classes.cellTable,
        }),
        customBodyRender: total => formatCurrency.format(Number(total)),
      },
    },
    // {
    //   name: 'perfile',
    //   label: t('routes.delivery_table.perfile'),
    //   options: {
    //     filter: false,
    //     sort: false,
    //     setCellProps: () => ({
    //       className: classes.cellTable,
    //     }),
    //     setCellHeaderProps: () => ({
    //       className: classes.cellTable,
    //     }),
    //     customBodyRender: (value) => {
    //       const perfile = JSON.parse(value);
    //       return (
    //         <Chip
    //           label={perfile.label}
    //           style={{ backgroundColor: perfile.color, display: 'inline-flex' }}
    //         />
    //       );
    //     },
    //   },
    // },
    {
      name: 'interests',
      label: t('routes.delivery_table.interests'),
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          className: classes.cellTable,
        }),
        setCellHeaderProps: () => ({
          className: classes.cellTable,
        }),
        customBodyRender: () => <Link to="jejes">Ver más</Link>,
      },
    },
    {
      name: 'location',
      label: t('routes.delivery_table.location'),
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          className: classes.cellTable,
        }),
        setCellHeaderProps: () => ({
          className: classes.cellTable,
        }),
        customBodyRender: () => <Link to="jejes">Ver más</Link>,
      },
    },
  ];

  if (ordersRequest.processing) {
    return (
      <CircularProgress />
    );
  }

  return (
    <PaginatedTable
      columns={columns}
      title={t('routes.delivery_table.title')}
      options={{
        responsive: 'standard',
        pagination: false,
        elevation: 0,
        filter: true,
        sort: true,
      }}
      data={(ordersRequest.response?.data as any)?.data}
    />
  );
};
