/* eslint-disable react/prop-types */
import {
  Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
} from '@mui/material';
import React from 'react';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { makeStyles } from '@mui/styles';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import UpdateIcon from '@mui/icons-material/Update';
import { useTranslation } from 'react-i18next';
import images from '../../../theme/ECTheme/assets/images';
import Text from '../../Text/Text';

const useStyles = makeStyles(() => ({
  paid: {
    color: '#7CBB01',
    backgroundColor: '#E4F6EA',
    borderRadius: 5,
  },
  open: {
    color: '#FED44C',
    backgroundColor: '#FFF6E2',
    borderRadius: 5,
  },
  void: {
    color: '#7C8185',
    backgroundColor: '#DCDFE7',
    borderRadius: 5,
  },

  formatItems: {
    display: 'flex',
  },
  container: {
    padding: '.5em 1em',
    minWidth: 650,
  },
}));

const iconImage = {
  paid: <CheckIcon />,
  open: <UpdateIcon />,
  void: <ClearIcon />,
};
const statusPayment = {
  paid: 'Pagado',
  open: 'Pendiente',
  void: 'Cancelada',
};
// const planDescription = {
//     "1 × Ecubi Freemium (at $0.00 / month)": "Ecubi Fremium",
//     "1 × Ecubi Standard (at $79.00 / month)": "Ecubi Standar",
//     "1 × Ecubi Pro (at $130.00 / month)": "Ecubi Pro",
// }

export const SubsHistoryCard = ({ subHistory }) => {
  const classes = useStyles();
  const { t } = useTranslation(['general']);

  const getTicket = (link) => {
    window.open(link, '_blank');
  };

  return (
    <div>
      <TableContainer className={classes.container}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><b>FECHA</b></TableCell>
              <TableCell align="left"><b>DESCRIPCIÓN</b></TableCell>
              <TableCell align="left"><b>ESTATUS</b></TableCell>
              <TableCell align="left"><b>IMPORTE</b></TableCell>
              <TableCell align="left"><b>RECIBO</b></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>

            {subHistory.map(row => (
              <TableRow key={row.id}>
                <TableCell>
                  {' '}
                  {format(new Date(row.formatted_created), 'eeee, d MMM, yyyy', { locale: es })}
                  {' '}
                </TableCell>

                <TableCell>
                  {row.lines.map(ticketInfo => (
                    ticketInfo.proration === false
                      ? <Text key={ticketInfo.id}>{t(`general:${ticketInfo.description}`)}</Text>
                      : null
                  ))}
                </TableCell>

                <TableCell align="left">
                  <div
                    style={{
                      display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap',
                    }}
                    className={classes[row.status]}
                  >
                    <div style={{ marginLeft: '2px' }}>
                      {statusPayment[row.status]}
                    </div>
                    <div style={{ marginRight: '2px' }}>
                      {iconImage[row.status]}

                    </div>
                  </div>
                </TableCell>

                <TableCell align="left">
                  {' '}
                  <b>
                    <Text>
                      MXN
                      {' '}
                      {row.formatted_total}
                    </Text>
                  </b>
                </TableCell>
                <TableCell align="left">
                  <Button
                    disabled={row.invoice_pdf === null}
                    onClick={() => getTicket(row.invoice_pdf)}
                  >
                    <img src={images.Icon_FileDowload} alt="Icon_FileDowload" />
                  </Button>

                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>

  );
};
