/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/prop-types */
import { Box, Switch } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { withStyles } from '@mui/styles';
import ActivityIndicator from '../../../../../../components/ui/ActivityIndicator';
import { CampaignStatus } from '../../../../../../constants/common';
import AuthorizedApiClient from '../../../../../../core/common/api/AuthorizedApiClient';
import Log from '../../../../../../util/Log';
import { useSession } from '../../../../../../util/session';

const CSwitch = withStyles({
  switchBase: {
    '&$checked': {
      color: '#009688',
    },
  },
  checked: {},
})(Switch);

const StatusCheck = ({
  id, defaultStatus, handleError = null, finished = false,
}) => {
  const [checked, setChecked] = useState(
    defaultStatus === CampaignStatus.Active && !finished,
  );
  const { company } = useSession();
  const [processing, setProcessing] = useState(false);

  const updateCampaign = useCallback(
    async (id, checked) => {
      if (!company) {
        return;
      }
      setProcessing(true);
      const sg = new AuthorizedApiClient();
      let path = `companies/${company.id}/campaigns`;
      const data = {
        status: checked ? CampaignStatus.Active : CampaignStatus.Inactive,
      };
      try {
        path = `${path}/${id}`;

        const response = await sg.put(data, path);

        if (response != null) {
          Log.debug('updated campaign status', response);
          setChecked(checked);
          setProcessing(false);
        }
      } catch (error) {
        Log.error(error.response.data);
        if (handleError) {
          handleError(error.response.data);
        }
      } finally {
        setProcessing(false);
      }
    },
    [company, handleError],
  );

  const _handleChange = useCallback(
    async (event) => {
      // console.log("aca", event.checked);

      updateCampaign(id, event.target.checked);
    },
    [id, updateCampaign],
  );

  return (
    <>
      {processing ? (
        <Box width="50px">
          <ActivityIndicator size={20} />
        </Box>
      ) : (
        <CSwitch checked={checked} onChange={_handleChange} disabled={finished} />
      )}
    </>
  );
};

export default StatusCheck;
