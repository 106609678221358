/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getProductsForMovement,
  getLocationsForMovement,
  closeModalMovement,
  saveMovement,
} from '../../actions/movement';
import { AUTOCOMPLETE_PER_PAGE } from '../../constants/common';
import { AutoCompleteOption } from '../ui/FormInput/AutoComplete';
import MovementFormView from './MovementFormView';

export default () => {
  const [locationsOptions, setLocationsOptions] = useState<
  AutoCompleteOption[]
  >([]);

  const [productsOptions, setProductsOptions] = useState<AutoCompleteOption[]>(
    [],
  );

  const dispatch = useDispatch();

  const open = useSelector((state: any) => state.movement.showModal);

  const { company } = useSelector((state: any) => state.session);

  const {
    product,
    products,
    locations,
    processing,
    loadingLocations,
    loadingProducts,
    error,
  } = useSelector((state: any) => state.movement);

  const _getLocations = useCallback(
    async (_params: any = {}) => {
      if (company) {
        const params = {
          per_page: AUTOCOMPLETE_PER_PAGE,
        };

        if (_params.filter) {
          params['filters'] = { name: _params.filter };
        }

        await dispatch(getLocationsForMovement(company.id, params));
      }
    },
    [dispatch, company],
  );

  const _getProducts = useCallback(
    async (_params: any = {}) => {
      if (company) {
        const params = {
          per_page: AUTOCOMPLETE_PER_PAGE,

        };

        if (_params.filter) {
          params['filters'] = { name: _params.filter, inventoried: true };
        } else {
          params['filters'] = { inventoried: true };
        }

        await dispatch(getProductsForMovement(company.id, params));
      }
    },
    [dispatch, company],
  );

  useEffect(() => {
    if (locations) {
      const _locations = locations.map(b => ({
        value: b.id,
        label: b.name,
      } as AutoCompleteOption));

      setLocationsOptions(_locations);
    } else {
      setLocationsOptions([]);
    }
  }, [locations]);

  useEffect(() => {
    if (products) {
      const _products = products.map(b => ({
        value: b.id,
        label: b.name,
      } as AutoCompleteOption));

      setProductsOptions(_products);
    } else {
      setProductsOptions([]);
    }
  }, [products]);

  const attemptSaveMovement = async (data) => {
    await dispatch(saveMovement(data, company.id));
  };

  const handleClose = () => {
    dispatch(closeModalMovement());
  };

  return (
    <MovementFormView
      open={open}
      handleClose={handleClose}
      processing={processing}
      loadingLocations={loadingLocations}
      locationsOptions={locationsOptions}
      getLocations={_getLocations}
      attemptSaveMovement={attemptSaveMovement}
      loadingProducts={loadingProducts}
      productsOptions={productsOptions}
      products={products}
      getProducts={_getProducts}
      error={error}
      product={product}
    />
  );
};
