import { types } from '../../actions/product/types';

export const initialState = {
  product: {
    id: null,
    name: '',
    description: '',
    image: '',
    brand_id: '',
    category_id: '',
    unitofmeasure_id: '',
    suppliers: [],
    cost: 0,
    price: 0,
    low_stock: 0,
    stock_alert: 0,
    adequate_stock: 0,
    category: null,
    published: false,
    inventoried: false,
    available: false,
  },
  error: null,
  showModal: false,
  processing: false,
  deletingProduct: false,
  refresh: false,
  suppliers: [],
  loadingSuppliers: false,
  tab: 0,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case types.EDIT_PRODUCT:
      return {
        ...state,
        showModal: true,
        product:
          action.payload !== null ? action.payload : initialState.product,
      };
    case types.CLOSE_PRODUCT_MODAL:
      return initialState;
    case types.SAVE_PRODUCT_SUCCESS:
      return {
        ...state,
        response: action.response,
        error: null,
        processing: false,
      };
    case types.SAVE_PRODUCT_FAILURE:
      return {
        ...state,
        error: action.error,
        processing: false,
      };
    case types.SAVING_PRODUCT:
      return {
        ...state,
        error: null,
        processing: true,
      };

    case types.DELETING_PRODUCT:
      return {
        ...state,
        error: null,
        deletingProduct: true,
      };

    case types.DELETE_PRODUCT_SUCCESS:
      return {
        ...state,
        error: null,
        deletingProduct: false,
      };
    case types.DELETE_PRODUCT_FAILURE:
      return {
        ...state,
        error: action.error,
        deletingProduct: false,
      };

    case types.CLEAR_REFRESH:
      return {
        ...state,
        refresh: false,
      };

    case types.SET_SUPPLIERS_PRODUCT_PROCESSING:
      return {
        ...state,
        loadingSuppliers: action.payload,
      };

    case types.SET_SUPPLIERS_PRODUCT_SUCCESS:
      return {
        ...state,
        loadingSuppliers: false,
        suppliers: action.response.data,
      };
    case types.SET_SUPPLIERS_PRODUCT_FAILURE:
      return {
        ...state,
        loadingSuppliers: false,
        error: action.error,
      };

    case types.SET_UNITOFMEASURES_PRODUCT_PROCESSING:
      return {
        ...state,
        loadingUnitsOfMeasures: action.payload,
      };

    case types.SET_UNITOFMEASURES_PRODUCT_SUCCESS:
      return {
        ...state,
        loadingUnitsOfMeasures: false,
        unitsOfMeasures: action.response.data,
      };
    case types.SET_UNITOFMEASURES_PRODUCT_FAILURE:
      return {
        ...state,
        loadingUnitsOfMeasures: false,
        error: action.error,
      };

    case types.SET_BRANDS_PRODUCT_PROCESSING:
      return {
        ...state,
        loadingBrands: action.payload,
      };

    case types.SET_BRANDS_PRODUCT_SUCCESS:
      return {
        ...state,
        loadingBrands: false,
        brands: action.response.data,
      };
    case types.SET_BRANDS_PRODUCT_FAILURE:
      return {
        ...state,
        loadingBrands: false,
        error: action.error,
      };

    case types.SAVE_MOVEMENT_SUCCESS:
      return {
        ...state,
        refresh: true,
      };
    case types.SET_PRODUCT_SUCCESS:
      return {
        ...state,
        product: action.product,
      };
    case types.SET_PRODUCT_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case types.SET_PRODUCT_TAB:
      return {
        ...state,
        tab: action.tab,
      };

    default:
      return state;
  }
};
