/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import LoginForm from './LoginForm';
import { login } from '../../../../actions/auth';
import Log from '../../../../util/Log';

const Login = () => {
  const dispatch = useDispatch();
  // useSessionCheck (props.history);
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState(null);
  const { t } = useTranslation();
  const { loggedIn } = useSelector((state: any) => state.session);

  async function loginSession(data) {
    try {
      setError(null);
      setProcessing(true);
      const response = await dispatch(login(data.email, data.password));
      setProcessing(false);

      if (response != null) {
        // props.history.push("/");
      } else {
        setError(t('errors.invalid_credentials'));
      }
    } catch (err) {
      setError(t('errors.invalid_credentials'));
      setProcessing(false);
      Log.debug('error _login', err);
    }
  }

  return (
    <>
      {loggedIn ? (
        <Redirect to="/" />
      ) : (
        <LoginForm
          onLogin={data => loginSession(data)}
          processing={processing}
          error={error}
        />
      )}
    </>
  );
};

export default Login;
