import { types } from './types';
import AuthorizedApiClient from '../../core/common/api/AuthorizedApiClient';

export const resetOrders = () => ({
  type: types.RESET_ORDERS,
});

export const clearRefresh = () => ({
  type: types.CLEAR_ORDERS_REFRESH,
});

export const setParams = params => ({
  type: types.SET_ORDERS_PARAMS,
  params,
});

export const getOrders = (companyId, params = {}) => async (
  dispatch: any,
) => {
  const sg = new AuthorizedApiClient();
  const path = `companies/${companyId}/orders`;
  dispatch({ type: types.SET_ORDERS_PROCESSING, payload: true });
  try {
    const response = await sg.get(params, path);

    if (response != null) {
      dispatch({ type: types.SET_ORDERS_SUCCESS, response: response.data });
    }
  } catch (error) {
    dispatch({ type: types.SET_ORDERS_FAILURE, error });
  }
};
