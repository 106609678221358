import { types } from './types';
import AuthorizedApiClient from '../../core/common/api/AuthorizedApiClient';

export const resetUsers = () => ({
  type: types.RESET_USERS,
});

export const getUsers = (companyId, params = {}) => async (
  dispatch: any,
) => {
  const sg = new AuthorizedApiClient();
  const path = `companies/${companyId}/users`;
  dispatch({ type: types.SET_USERS_PROCESSING, payload: true });
  try {
    const response = await sg.get(params, path);

    if (response != null) {
      dispatch({ type: types.SET_USERS_SUCCESS, response: response.data });
    }
  } catch (error) {
    dispatch({ type: types.SET_USERS_FAILURE, erro: error });
  }
};

export const getResources = () => async (
  dispatch: any,
) => {
  const sg = new AuthorizedApiClient();
  const path = 'pos-resources';
  dispatch({ type: types.SET_RESOURCES_PROCESSING, payload: true });
  try {
    const response = await sg.get({}, path);

    if (response != null) {
      dispatch({ type: types.SET_RESOURCES_SUCCESS, response: response.data });
    }
  } catch (error) {
    dispatch({ type: types.SET_RESOURCES_FAILURE, erro: error.response.data });
  }
};
