import { ShepherdOptionsWithType } from 'react-shepherd';
import { ECUBI_MARKET_URL } from '../common';

export const productsSteps: ShepherdOptionsWithType[] = [
  {
    id: 'products-tour',
    attachTo: { element: '.products-table-col', on: 'top' },
    modalOverlayOpeningRadius: 4,
    buttons: [
      {
        text: 'Siguiente',
        type: 'next',
      },
    ],
    title: 'Producto',
    text: ['<p>Puedes dar click a un producto sobre la primer columna para mirar su informacion.</p>'],
    canClickTarget: false,
  },
  {
    id: 'products-tour-2',
    attachTo: { element: '.unit-table-col', on: 'top' },
    modalOverlayOpeningRadius: 4,
    buttons: [
      {
        classes: 'shepherd-button-secondary',
        text: 'Atras',
        type: 'back',
      },
      {
        text: 'Siguiente',
        type: 'next',
      },
    ],
    title: 'Unidades',
    text: ['<p>Puedes cambiar de forma rapida el tipo de unidad asignada al producto</p>'],
    canClickTarget: false,
  },
  {
    id: 'products-tour-3',
    attachTo: { element: '.quantity-table-col', on: 'top' },
    modalOverlayOpeningRadius: 4,
    buttons: [
      {
        classes: 'shepherd-button-secondary',
        text: 'Atras',
        type: 'back',
      },
      {
        text: 'Siguiente',
        type: 'next',
      },
    ],
    title: 'Cantidad',
    text: [
      '<p>Dale un vistazo a la cantidad disponible del producto</p><br/><p>Si la columna contiene el codigo "N/A" el producto no requiere inventario. Puedes cambiar esta opción al administrar el producto</p>',
    ],
    canClickTarget: false,
  },
  {
    id: 'products-tour-4',
    attachTo: { element: '.available-table-col', on: 'top' },
    modalOverlayOpeningRadius: 4,
    buttons: [
      {
        classes: 'shepherd-button-secondary',
        text: 'Atras',
        type: 'back',
      },
      {
        text: 'Siguiente',
        type: 'next',
      },
    ],
    title: 'Categoría',
    text: ['<p>Puedes cambiar la disponibilidad de productos que no tienen inventario, es decir, o está disponible o no.</p><br/><p>La leyenda NA quiere decir que el producto cuenta con inventario por lo que estará disponible siempre y cuando haya piezas en existencia.</p>'],
    canClickTarget: false,
  },
  {
    id: 'products-tour-5',
    attachTo: { element: '.category-table-col', on: 'top' },
    modalOverlayOpeningRadius: 4,
    buttons: [
      {
        classes: 'shepherd-button-secondary',
        text: 'Atras',
        type: 'back',
      },
      {
        text: 'Siguiente',
        type: 'next',
      },
    ],
    title: 'Categoría',
    text: ['<p>Puedes cambiar la categoría asignada al producto</p>'],
    canClickTarget: false,
  },
  {
    id: 'products-tour-6',
    attachTo: { element: '.cost-table-col', on: 'top' },
    modalOverlayOpeningRadius: 4,
    buttons: [
      {
        classes: 'shepherd-button-secondary',
        text: 'Atras',
        type: 'back',
      },
      {
        text: 'Siguiente',
        type: 'next',
      },
    ],
    title: 'Costo',
    text: ['<p>El costo es lo que te cuesta tener un producto para su venta.</p>'],
    canClickTarget: false,
  },
  {
    id: 'products-tour-7',
    attachTo: { element: '.price-table-col', on: 'top' },
    modalOverlayOpeningRadius: 4,
    buttons: [
      {
        classes: 'shepherd-button-secondary',
        text: 'Atras',
        type: 'back',
      },
      {
        text: 'Siguiente',
        type: 'next',
      },
    ],
    title: 'Precio',
    text: ['<p>El precio es la cantidad por la cual vendes el producto.</p>'],
    canClickTarget: false,
  },
  {
    id: 'products-tour-8',
    attachTo: { element: '.published-table-col', on: 'top' },
    modalOverlayOpeningRadius: 4,
    buttons: [
      {
        classes: 'shepherd-button-secondary',
        text: 'Atras',
        type: 'back',
      },
      {
        text: 'Siguiente',
        type: 'next',
      },
    ],
    title: 'Publicado',
    text: [`<p>La columna de publicado indica si el producto es visible a traves de tu tienda online en <a target="_blank" href="${ECUBI_MARKET_URL}">Ecubi Marketplace</a>.</p>`],
    canClickTarget: false,
  },
  {
    id: 'products-tour-9',
    attachTo: { element: '.excel-table-download', on: 'top' },
    modalOverlayOpeningRadius: 4,
    buttons: [
      {
        classes: 'shepherd-button-secondary',
        text: 'Atras',
        type: 'back',
      },
      {
        text: 'Siguiente',
        type: 'next',
      },
    ],
    title: 'Descarga la lista de tus productos',
    text: ['<p>Puedes descargar rapidamente una lista con tus productos en un archivo Excel(xlsx)</p>'],
    canClickTarget: false,
  },
  {
    id: 'products-tour-10',
    attachTo: { element: '.excel-table-upload', on: 'top' },
    modalOverlayOpeningRadius: 4,
    buttons: [
      {
        classes: 'shepherd-button-secondary',
        text: 'Atras',
        type: 'back',
      },
      {
        text: 'Siguiente',
        type: 'next',
      },
    ],
    title: 'Sube la lista de tus productos',
    text: ['<p>Puedes subir rapidamente una lista con tus productos en un archivo Excel(xlsx) para registrarlos en Ecubi.</p>'],
    canClickTarget: false,
  },
  {
    id: 'products-tour-last-step',
    attachTo: { element: '.create-first-product', on: 'top' },
    modalOverlayOpeningRadius: 4,
    title: 'Crea tu primer producto',
    text: ['<p>Crea tu primer producto y empieza a utilizar estas herramientas.</p>'],
  },
];
