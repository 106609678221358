import React from 'react';
import {
  ButtonProps as MuiButtonProps, Button,
} from '@mui/material';
import { withStyles } from '@mui/styles';

const RedButton = withStyles(() => ({
  root: {
    color: 'red',
    '&:hover': {
      color: '#FF1F1F',
    },
  },
}))(Button);

export default (props:MuiButtonProps) => <RedButton {...props} variant="text" />;
