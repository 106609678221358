/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '../../../../../../components/ui/Layout/Box';
import DataContainer from '../../../../../../hoc/DataContainer';
import { useSession } from '../../../../../../util/session';
import { PERMISSION, RESOURCES } from '../../../../../../constants/common';
import AddTag from '../../../../../../components/ui/Buttons/AddTag';
import LocationSelect from '../../../../../../components/inputs/LocationSelect/LocationSelect';
import Text from '../../../../../../components/Text/Text';
import AddRemove from '../../../../../../components/ui/CartItem/AddRemove';

export default ({ inventories, setInventories }) => {
  const { can } = useSession();
  const [switchLocation, setSwitchLocation] = useState(false);
  const { t } = useTranslation('general');

  const switchLocationHandler = () => {
    setSwitchLocation(!switchLocation);
  };

  const handleAddLocation = ({ id, name }) => {
    const newInventorie = {
      id,
      stock: 0,
      quantity: 0,
      location_id: id,
      variant_id: null,
      name,
    };
    setInventories([...inventories, newInventorie]);
    setSwitchLocation(false);
  };

  const handleAddOneToLocation = (location) => {
    const newLocations = inventories.map((l) => {
      if (l.id === location.id) {
        return { ...l, quantity: l.quantity + 1 || 1 };
      }
      return l;
    });
    setInventories(newLocations);
  };

  const handleRemoveOneFromLocation = (location) => {
    const newLocations = inventories.map((l) => {
      if (l.id === location.id) {
        return { ...l, quantity: l.quantity - 1 || 0 };
      }
      return l;
    });
    setInventories(newLocations);
  };

  const handleSetValueToLocation = (value, location) => {
    const newLocations = inventories.map((l) => {
      if (l.id === location.id) {
        return { ...l, quantity: value };
      }
      return l;
    });
    setInventories(newLocations);
  };

  return (
    <Box p={0} m={0}>
      <DataContainer
        tittle={t('general:available')}
      >
        {
          inventories.map(location => (
            <Box key={location.id} display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" margin="1rem .5rem">
              <Text>
                {location.name}
              </Text>
              <Box width={110}>
                <AddRemove
                  value={location.quantity}
                  max={999}
                  min={0}
                  add={() => handleAddOneToLocation(location)}
                  remove={() => handleRemoveOneFromLocation(location)}
                  onValueChange={(value: number) => handleSetValueToLocation(value, location)}
                  fontSize={12}
                />
              </Box>
            </Box>
          ))
        }

        {
           can(RESOURCES.stock, PERMISSION.edit)
            && can(RESOURCES.movements, PERMISSION.create) && (
             switchLocation ? (
               <Box>
                 <LocationSelect filterIds={inventories.map(inv => inv.location_id)} onChange={handleAddLocation} />
               </Box>
             ) : (
               <Box onClick={switchLocationHandler}>
                 <AddTag tittle={t('general:add_location')} />
               </Box>
             )
           )
        }

      </DataContainer>

    </Box>
  );
};
