import React from 'react';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import Grid from '../Layout/Grid';
import Box from '../Layout/Box';
import HeaderIcon from './HeaderIcon';
import BackButton from '../BackButton';

type TitleProps = {
  title: string;
  section?: string;
  back?: boolean;
  shadow?: boolean
  icon?: boolean
};

const styles = makeStyles(theme => ({
  header: {
    height: '60px',
  },

  Title: {
    fontSize: '24px',
    margin: 0,
    marginLeft: 20,
    opacity: '1',
  },
  products: {
    backgroundColor: theme.colors.inventory.light,
  },
  reports: {
    backgroundColor: theme.colors.reports.light,
  },
  finance: {
    backgroundColor: theme.colors.finance.light,
  },
  inventory: {
    backgroundColor: theme.colors.orange.light,
  },
  store: {
    backgroundColor: 'rgba(148, 215, 249, 0.5)',
  },
  client: {
    backgroundColor: theme.colors.clients.light,
  },
  pointSell: {
    backgroundColor: theme.colors.pointSale.light,
  },
  backContainer: {
    position: 'absolute',
    left: '2%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
  },
  container: {
    position: 'relative',
  },
  containerShadow: {
    boxShadow: theme.shadow.main,
  },
}));

export default ({
  title, back = false, section, shadow, icon = true,
}: TitleProps) => {
  const classes = styles();
  return (
    <Box mx="auto" className={`${classes.container} ${shadow ? classes.containerShadow : null}`}>
      {back && (
        <div className={classes.backContainer}>
          <BackButton />
        </div>
      )}
      <Grid
        container
        className={`${classes.header} ${classes[section]}`}
        justifyContent="center"
        alignItems="center"
      >
        {icon ? <HeaderIcon section={section} /> : null}
        <Typography variant="h5" className={classes.Title}>
          {title}
        </Typography>
      </Grid>
    </Box>
  );
};
