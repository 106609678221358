/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/prop-types */
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearCount,
  getCountProducts,
  resertCountProducts,
} from '../../../../../../actions/count';
import Box from '../../../../../../components/ui/Layout/Box';
import { SCROLL_PER_PAGE } from '../../../../../../constants/common';
import PaginatedScroll from '../../../../../../hoc/PaginatedScroll';
import { generateKey } from '../../../../../../util/keys';
import ProductRow from './ProductRow';

export default ({ type }) => {
  const {
    filters, products, loadingProducts, selectedLocation, completed, branchLocations,
  } = useSelector(
    (state: any) => state.count,
  );

  const company = useSelector((state: any) => state.session.company);
  const dispatch = useDispatch();

  const _getProducts = useCallback(
    async (params: any = {}) => {
      if (company && filters.branch_id) {
        const _filters = {
          name: undefined,
        };
        if (params.filters && params.filters.name) {
          // ignore branch_id and location_id
          _filters.name = params.filters.name;
        }
        params.filters = _filters;
        params.filters.inventoried = true;
        params.per_page = SCROLL_PER_PAGE;
        dispatch(getCountProducts(company.id, params));
      }
    },
    [dispatch, company, filters],
  );
  let list = [];
  if (branchLocations.length) {
    list = products.data.map(product => (
      <ProductRow
        key={generateKey(product.id)}
        product={product}
        type={type}
        selectedLocation={selectedLocation}
      />
    ));
  }

  const _resetProducts = useCallback(() => {
    dispatch(resertCountProducts());
  }, [dispatch]);

  const _resetCount = useCallback(() => {
    dispatch(clearCount());
  }, [dispatch]);

  useEffect(() => {
    if (completed) {
      _resetCount();
      _getProducts();
    }
  }, [_resetCount, _getProducts, completed]);

  return (
    <PaginatedScroll
      hasNextPage={products.response.page < products.response.last_page}
      loadData={(params: any) => _getProducts(params)}
      currentPage={products.response.page}
      resetData={_resetProducts}
      processing={loadingProducts}
      filters={filters}
    >
      <Box width={1}>{list}</Box>
    </PaginatedScroll>
  );
};
