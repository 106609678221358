/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import Box from '../../../../../components/ui/Layout/Box';
import ConfirmDialog from '../../../../../components/ui/ConfirmDialog';
import Grid from '../../../../../components/ui/Layout/Grid';
import Alert from '../../../../../components/ui/notifications/alert';
import { rules as validationRules } from '../../../../../util/Validations';
import Modal from '../../../../../components/ui/Modals/Modal';
import Actions from '../../../../../components/ui/Modals/Actions/Actions';
import RawBranchForm from './RawBranchForm';

export default ({
  branch,
  attemptSaveBranch,
  attemptDeleteBranch,
  processing,
  deletingBranch,
  error,
  closeModalBranch,
}) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const [image, setImage] = useState(null);
  const { showModal } = useSelector((state: any) => state.branch);
  const { t } = useTranslation([
    'branches',
    'translation',
    'general',
    'validation',
  ]);

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(closeModalBranch());
  };

  const title = branch.id == null
    ? t('branches:create_branch')
    : t('branches:edit_branch', { name: branch.name });

  const schema = Yup.object({
    name: Yup.string()
      .max(validationRules.maxLength)
      .required(
        t('translation:required', {
          input: t('branches:branch_name'),
        }),
      ),
    address: Yup.string()
      .max(validationRules.maxLength)
      .required(
        t('translation:required', {
          input: t('branches:address'),
        }),
      ),

    phone: Yup.string()
      .max(validationRules.maxLength)
      .required(
        t('translation:required', {
          input: t('branches:phone'),
        }),
      ),

    email: Yup.string()
      .email(t('validation:invalid_email'))
      .max(validationRules.maxLength),

    lat: Yup.string(),
    lng: Yup.string(),
  });
  const {
    register, handleSubmit, formState: { errors }, setValue,
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    data.id = branch.id ? branch.id : null;
    if (data.id && image === '') {
      data.image = '';
    }

    attemptSaveBranch(data, image !== '' ? image : null);
  };

  const actions = (
    <Actions
      handleClose={handleClose}
      handleSubmit={handleSubmit(onSubmit)}
      handleDelete={() => setShowConfirm(true)}
      isNew={branch.id === null}
      processing={processing}
      deleting={deletingBranch}
    />
  );

  const { id } = branch;
  useEffect(() => {
    setImage(null);
  }, [id]);

  return (
    <Modal
      open={showModal}
      onClose={handleClose}
      title={title}
      actions={actions}
    >
      <Box>
        <ConfirmDialog
          open={showConfirm}
          handleCancel={() => {
            setShowConfirm(false);
          }}
          handleConfirm={() => {
            setShowConfirm(false);
            attemptDeleteBranch(branch);
          }}
          title={t('branches:delete_branch')}
          content={t('branches:delete_confirmation_msg')}
        />
        {error && (
          <Grid container>
            <Alert severity="error" message={t(`branches:${error}`, error)} />
          </Grid>
        )}
        <RawBranchForm
          branch={branch}
          register={register}
          setImage={setImage}
          errors={errors}
          setValueForm={setValue}
        />
      </Box>
    </Modal>
  );
};
