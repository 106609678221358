/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import styles from './UniverseImg.module.css';

const UniverseImg: React.ElementType = (
  props: React.HTMLAttributes<HTMLElement>,
) => {
  const url = `${process.env.PUBLIC_URL}/images/universe.png`;
  return (
    <div className={`${props.className} ${styles.UniverseImg}`}>
      <img src={url} alt="" />
    </div>
  );
};

export default UniverseImg;
