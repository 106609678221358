export const types = {
  SET_PRODUCTS_LIST_COMPONENT_PROCESSING: 'SET_PRODUCTS_LIST_COMPONENT_PROCESSING',
  SET_PRODUCTS_LIST_COMPONENT_SUCCESS: 'SET_PRODUCTS_LIST_COMPONENT_SUCCESS',
  SET_PRODUCTS_LIST_COMPONENT_FAILURE: 'SET_PRODUCTS_LIST_COMPONENT_FAILURE',
  RESET_PRODUCTS_LIST_COMPONENT: 'RESET_PRODUCTS_LIST_COMPONENT',
  CLEAR_PRODUCTS_LIST_COMPONENT_REFRESH: 'CLEAR_PRODUCTS_LIST_COMPONENT_REFRESH',
  SET_PRODUCTS_LIST_COMPONENT_PARAMS: 'SET_PRODUCTS_LIST_COMPONENT_PARAMS',
  PRODUCTS_LIST_COMPONENT_ADD_SELECTED_PRODUCTS: 'PRODUCTS_LIST_COMPONENT_ADD_SELECTED_PRODUCTS',
  PRODUCTS_LIST_COMPONENT_REMOVE_SELECTED_PRODUCTS: 'PRODUCTS_LIST_COMPONENT_REMOVE_SELECTED_PRODUCTS',
  PRODUCTS_LIST_COMPONENT_ADD_NEW: 'PRODUCTS_LIST_COMPONENT_ADD_NEW',
  PRODUCTS_LIST_COMPONENT_CLOSE_MODALMODIFER: 'PRODUCTS_LIST_COMPONENT_CLOSE_MODALMODIFER',
};

export enum ListType {
  PRODUCTS = 1,
  MODIFIERS = 2,
}
