/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/prop-types */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SaleDetailsView from './SaleDetailsView';
import { getSale } from '../../../../../../actions/sale';

export default ({ id }) => {
  const dispatch = useDispatch();

  const company = useSelector((state: any) => state.session.company);

  const _getSale = useCallback(
    async (id) => {
      if (company) {
        await dispatch(getSale(id, company.id));
      }
    },
    [dispatch, company],
  );

  useEffect(() => {
    if (company) {
      _getSale(id);
    }
  }, [id, company, _getSale]);

  return <SaleDetailsView />;
};
