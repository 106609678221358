import { CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Axios from 'axios';
import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { BASE_URL } from '../../../../constants/api/ApiConstants';

const useStyles = makeStyles(() => ({
  container: {
    display: 'grid',
    placeContent: 'center',
    height: '80vh',
  },
}));

export const ReauthPaymentAcount = () => {
  const classes = useStyles();
  const { companyId } = useSelector((state: any) => state.session);
  const history = useHistory();

  const redirectToAcount = useCallback(
    async () => {
      Axios.post(`${BASE_URL}/api/v1/companies/${companyId}/payment_accounts`)
        .then(({ data }) => {
          //    console.log(data)
          window.location.href = data.account_link.url;
        })
        .catch((error) => {
          console.log('este es el error', error);
          history.push('/store/finances');
        });
    },

    [companyId, history],
  );

  useEffect(() => {
    redirectToAcount();
  }, [redirectToAcount]);

  return (
    <div className={classes.container}>
      <CircularProgress />
    </div>
  );
};
