/* eslint-disable react/no-unstable-nested-components */
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from '../../../../../components/Dropdown/Dropdown';
import PaginatedTable from '../../../../../components/ui/PaginatedTable';

const vehiclesData = [
  {
    id: '21232',
    model: '2021',
    plate: 'SAF-943',
    max_weight: 2.5,
    max_volume: 100,
    status: 'available',
  },
  {
    id: '24676',
    model: '2010',
    plate: 'AXW-325',
    max_weight: 1.7,
    max_volume: 85,
    status: 'available',
  },
  {
    id: '27647',
    model: '2023',
    plate: 'JGF-102',
    max_weight: 3.4,
    max_volume: 200,
    status: 'unavailable',
  },
];

const useStyles = makeStyles(() => ({
  cellTable: { padding: '.5rem', textAlign: 'center' },
}));

interface DropProps {
  value: string;
}

const StatusDropdown = ({ value }: DropProps) => {
  const [status, setStatus] = useState(value);
  const onChange = selected => setStatus(selected.identifier);

  return (
    <Dropdown
      fullWidth
      value={status}
      options={[
        { identifier: 'available', label: 'Disponible', color: '#e3fde3' },
        { identifier: 'unavailable', label: 'No disponible', color: '#e5b2dd' },
      ]}
      onChange={onChange}
    />
  );
};

interface Props {
  onRowClick?(data: any): void;
}

export const VehicleTable = ({ onRowClick }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const columns = [
    {
      name: 'plate',
      label: t('general:plate'),
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          className: classes.cellTable,
        }),
        setCellHeaderProps: () => ({
          className: classes.cellTable,
        }),
      },
    },
    {
      name: 'model',
      label: t('general:model'),
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          className: classes.cellTable,
        }),
        setCellHeaderProps: () => ({
          className: classes.cellTable,
        }),
      },
    },
    {
      name: 'max_weight',
      label: t('general:burden'),
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          className: classes.cellTable,
        }),
        setCellHeaderProps: () => ({
          className: classes.cellTable,
        }),
        customBodyRender: value => (
          <Typography variant="body2">
            {`${value} Tons`}
          </Typography>
        ),
      },
    },
    {
      name: 'max_volume',
      label: t('general:volume'),
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          className: classes.cellTable,
        }),
        setCellHeaderProps: () => ({
          className: classes.cellTable,
        }),
        customBodyRender: value => (
          <Typography variant="body2">
            {`${value} L`}
          </Typography>
        ),
      },
    },
    {
      name: 'status',
      label: t('general:status'),
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          className: classes.cellTable,
        }),
        setCellHeaderProps: () => ({
          className: classes.cellTable,
        }),
        customBodyRender: value => <StatusDropdown value={value} />,
      },
    },
  ];

  return (
    <PaginatedTable
      columns={columns}
      title="Lista de vehiculos"
      options={{
        responsive: 'standard',
        pagination: false,
        elevation: 0,
        filter: true,
        sort: true,
        onRowClick: (_, y) => onRowClick(vehiclesData[y.dataIndex]),
      }}
      data={vehiclesData}
    />
  );
};
