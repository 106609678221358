import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { rules as validationRules } from '../../../../../util/Validations';

export default function useGroupFormFields() {
  const { t } = useTranslation(['general']);

  const schema = Yup.object({
    title: Yup.string()
      .max(validationRules.maxLength)
      .required(
        t('translation:required', {
          input: t('general:name'),
        }),
      ),
    required: Yup.boolean(),
    allow_several: Yup.boolean(),
  });
  const { register, handleSubmit, formState: { errors } /* , control */ } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  return {
    register,
    handleSubmit,
    errors,
  };
}
