import React from 'react';
import { useDispatch } from 'react-redux';
import CategoriesPageView from './CategoriesPageView';
import { editCategory } from '../../../../actions/category';

export default () => {
  const dispatch = useDispatch();
  const openNewCategory = () => {
    dispatch(editCategory());
  };

  return <CategoriesPageView openNewCategory={openNewCategory} />;
};
