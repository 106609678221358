/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import { useTranslation } from 'react-i18next';

const OrderListDeliveryMethod = ({ value }) => {
  const { t } = useTranslation(['general']);

  return (
    <>
      {value ? t(`general:shipping_methods_list:${value}`) : ''}
    </>
  );
};

export default OrderListDeliveryMethod;
