/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-return-assign */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/prop-types */
import { makeStyles } from '@mui/styles';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSales, resetSales } from '../../../../../actions/pos';
import { getProducts, resetProducts } from '../../../../../actions/products';
import DataCard from '../DataCard';

const useStyle = makeStyles(theme => ({

  containerSection: {
    margin: '25px 0px',
    width: '93%',
    marginRight: 'auto',
    marginLeft: 'auto',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    columnGap: '10px',
    maxWidth: 642,
    [theme.breakpoints.down('md')]: {
      width: '85%',
    },
  },
}));

const DayInformation = ({ handleOpenWarning }) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const state = useSelector((states: any) => states.pos);
  const products = useSelector((states: any) => states.products);
  const company = useSelector((states: any) => states.session.company);

  const [earnings, setEarnings] = useState(0);
  const [revenue, setRevenue] = useState(0);
  // const [ costWarning, setCostWarning ] = useState( false )

  // ---- LOAD SALES ----
  const _getSales = useCallback(
    async () => {
      const _params = {
        ...state.sales.params,
      };
      if (company) {
        await dispatch(getSales(company.id, _params));
      }
    },
    [dispatch, company, state.sales.params],
  );

  useEffect(() => {
    if (company) {
      dispatch(resetSales());
      _getSales();
    }
  }, [company, _getSales, dispatch]);
  // state.sales.load? console.log("cargando...") : console.log(state.sales.data)
  // ---- LOAD SALES ----

  // ---- LOAD PRODUCTS ----
  const _getProducts = useCallback(
    async () => {
      const _params = {
        filters: {
          no_financial: true,
        },
      };
      if (company) {
        await dispatch(getProducts(company.id, _params));
      }
    },
    [dispatch, company],
  );
  useEffect(() => {
    if (company) {
      dispatch(resetProducts());
      _getProducts();
    }
  }, [company, _getProducts, dispatch]);
  // products.processing? console.log("cargando...") : console.log("productos",products.data)
  // ---- LOAD PRODUCTS ----

  useMemo(() => {
    let i = 0;
    state.sales.data.map((sale: any) => i = sale.subtotal + i);
    return setEarnings(i);
  }, [state.sales.data]);

  useMemo(() => {
    let i = 0;
    state.sales.data.map((sale: any) => {
      let j = 0;
      sale.items.map((item: any) =>
      // hay que remplzar 0 por item.cost
        j = ((item.price * item.quantity) - (item.variant.cost * item.quantity)) + j);
      return i = j + i;
    });
    return setRevenue(i);
  }, [state.sales.data]);

  return (
    <div className={classes.containerSection}>
      <DataCard
        data={state.sales.data ? state.sales.data.length : 0}
        load={state.sales.load}
        tittle="Venta del día de hoy"
      />
      <DataCard
        data={earnings}
        load={state.sales.load}
        tittle="Ingresos"
        type="money"
      />
      <DataCard
        data={revenue}
        load={state.sales.load}
        tittle="Ganancias"
        type="money"
        alert={products.data.length > 0}
        warningClick={handleOpenWarning}
      />
    </div>
  );
};

export default DayInformation;
