/* eslint-disable react/prop-types */
import React from 'react';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import SuccessButton from '../SuccessButton';

export default (props) => {
  const { text, variant, ...rest } = props;
  return (
    <SuccessButton
      {...rest}
      variant="contained"
      color="primary"
      text={text}
      startIcon={<CloudDownloadIcon />}
    />
  );
};
