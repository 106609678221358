import React from 'react';
import useBestSellerState from '../hooks/useBestSellerState';

interface IContextProps {
  state: any;
  getProducts: Function;
  setParams: Function;
  resetFilters: Function;
}

export const BestSellerContext = React.createContext({} as IContextProps);

interface Props {
  children: React.ReactNode;
}

export const BestSellerProvider = ({ children }: Props) => {
  const initialState = useBestSellerState();
  return (
    <BestSellerContext.Provider value={initialState}>
      {children}
    </BestSellerContext.Provider>
  );
};

export const useBestSellerContext = () => {
  const context = React.useContext(BestSellerContext);
  if (context === undefined) {
    throw new Error(
      'BestSellerState must be used within a BestSellerProvider',
    );
  }
  return context;
};
