import {
  Button,
  Checkbox,
  Fade, FormControlLabel, InputAdornment, Modal, TextField,
} from '@mui/material';
import React, {
  useCallback,
  useEffect, useRef, useState,
} from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import PersonPinCircleOutlinedIcon from '@mui/icons-material/PersonPinCircleOutlined';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import CloseIcon from '@mui/icons-material/Close';
import Axios from 'axios';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import { makeStyles, withStyles } from '@mui/styles';
import DeliveryZoneCard from '../../../../components/Cards/DeliveryZoneCard';
import { DelimitationAreaMap } from '../../../../components/ui/DelimitationAreaMap';
import useDeliveryZones from '../../../../hooks/useDeliveryZones';
import { useSession } from '../../../../util/session';
import Select from '../../../../components/ui/FormInput/Select';
import ColorSelect from '../../../../components/inputs/ColorSelect';
import BranchSelect from '../../../../components/inputs/BranchSelect';
import { BASE_URL } from '../../../../constants/api/ApiConstants';
import useAppBarHeight from '../../../../hooks/useAppBarHeight';
import images from '../../../../theme/ECTheme/assets/images';

const styles = makeStyles(theme => ({
  shake: {
    animation: '$shake 0.82s cubic-bezier(.36,.07,.19,.97) both;',
    animationDelay: '0.35s',
    transform: 'translate3d(0, 0, 0)',
    backfaceVisibility: 'hidden',
    perspective: '1000px',
  },
  '@keyframes shake': {
    '10%, 90% ': {
      transform: 'translate3d(-1px, 0, 0)',
    },
    '20%, 80%': {
      transform: 'translate3d(2px, 0, 0)',
    },
    '30%, 50%, 70%': {
      transform: 'translate3d(-4px, 0, 0)',
    },
    '40%, 60%': {
      transform: 'translate3d(4px, 0, 0)',
    },
  },
  containerStyle: {
    width: '100%',
    position: 'fixed',
    height: '100%',
  },
  asidebar: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '30%',
    height: '100%',
    minWidth: 420,
    zIndex: 11,
    backgroundColor: '#fff',
    transition: 'transform 0.3s ease-in-out',
    [theme.breakpoints.down('md')]: {
      minWidth: 290,
    },
    '& > button': {
      position: 'absolute',
      cursor: 'pointer',
      top: 0,
      bottom: 0,
      right: '-25px',
      margin: 'auto',
      width: 25,
      height: 120,
      border: 'none',
      borderBottomRightRadius: 8,
      borderTopRightRadius: 8,
      borderBottomLeftRadius: 0,
      borderTopLeftRadius: 0,
      padding: 0,
      textAlign: 'center',
      boxShadow: '4px 0px 4px #00000025',
      minWidth: 25,
    },
    '& > ul > li': {
      listStyle: 'none',
      padding: '4px',
      margin: 0,
      borderBottom: '1px solid #ccc',
    },
    '& > ul': {
      padding: 0,
      margin: 0,
      width: '100%',
      overflowY: 'scroll',
      height: '100%',
    },
  },
  hidden: {
    transform: 'translate(-100%)',
  },
  mapcontainer: {
    width: '100%',
    height: '100%',
  },
  modal: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
  },

  paper: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.background.paper,
    border: 'none',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'scroll',
    position: 'relative',
  },
  formZone: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: 10,
    boxSizing: 'border-box',
    gap: 16,
    flex: 1,
  },
  timePicker: {
    display: 'flex',
    alignItems: 'center',
  },
  timePickerRange: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    gap: 8,
    flexWrap: 'wrap',
    [theme.breakpoints.down(1200)]: {
      flexDirection: 'column',
    },
  },

  logisticInput: {
    display: 'flex',
    alignItems: 'center',
  },

  footeBottons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '10px',
    width: '100%',
    gap: 10,
  },

  closeButton: {
    position: 'absolute',
    top: 10,
    right: 10,
    backgroundColor: '#d5d5d5',
    cursor: 'pointer',
    width: 25,
    height: 25,
    borderRadius: '50%',
    border: 'none',
    padding: 0,
    textAlign: 'center',
    boxShadow: '2px 0px 6px #2B2B2B15',
  },

  error: {
    color: 'red',
    display: 'block',
  },
}));

const CTextField = withStyles(theme => ({
  root: {
    '& .MuiInputBase-root': {
      margin: 0,
    },
    '& .MuiOutlinedInput-input': {
      padding: '4px',
      marginLeft: '10px',
      width: '80px',
      [theme.breakpoints.down('lg')]: {
        width: '50px',
      },
    },
    '& .MuiInputdefaultValue-formControl': {
      position: 'absolute',
      padding: '400px',
    },
    '& .MuiOutlinedInput-root': {
      position: 'relative',
      '&::before': {
        content: "'$'",
        position: 'relative',
        left: 7,
        fontSize: 14,
      },
    },
  },
}))(TextField);

const TimeInput = withStyles({
  root: {
    '& .MuiFormControl-root': {
      width: '52px',
    },
    '& .MuiInputBase-input': {
      padding: '14px 5px',
    },
    '& .MuiInputBase-root': {
      width: '52px',
    },
    '& .MuiOutlinedInput-root': {
      padding: '0px',
    },
    '& .MuiFormHelperText-root': {
      padding: '0px',
      margin: '0px',
      textAlign: 'center',
    },
  },
})(TextField);

interface TimerPickerProps {
  defaultValue: number;
  onChange: (value: number) => void;
  disabled?: boolean;
  error?: string;
}
const TimePicker = ({
  onChange, disabled, error = null, defaultValue = null,
}: TimerPickerProps) => {
  const classes = styles();
  // const { t } = useTranslation('general');
  const {
    register,
    formState: { errors },
    watch,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      days: defaultValue ? (Math.trunc(defaultValue / 1440)) : 0,
      hours: defaultValue ? (Math.trunc((defaultValue % 1440) / 60)) : 0,
      minutes: defaultValue ? (defaultValue % 60) : 0,
    },
  });

  useEffect(() => {
    const subscription = watch((timeState) => {
      const { days, hours, minutes } = timeState;
      const timeInMinutes = days * 1440 + hours * 60 + minutes * 1;
      onChange(timeInMinutes);
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  const validateNumber = (e, min = 0, max = 99) => {
    // 0 is valid
    if (e.target.value === '0') {
      e.target.value = '0';
    } else if (e.target.value.startsWith('0')) {
      e.target.value = e.target.value.substring(1);
    } else if (e.target.value >= max) {
      e.target.value = max;
    } else if (e.target.value <= min) {
      e.target.value = min;
    } else if (e.target.value === '') {
      e.target.value = min;
    } else if (Number.isNaN(e.target.value)) {
      e.target.value = min;
    }
  };

  return (
    <div>
      {
        error && (
          <p className={classes.error}>
            <small>
              {error}
            </small>
          </p>
        )
      }
      <div className={classes.timePicker}>
        <TimeInput
          {...register('days', {
            required: true,
            min: 0,
            max: 90,
            onChange: e => validateNumber(e, 0, 90),
          })}
          variant="outlined"
          helperText="Días"
          error={!!errors.days || Boolean(error)}
          type="number"
          inputProps={{
            min: 0,
            max: 90,
          }}
          disabled={disabled}
        />
        <TimeInput
          {...register('hours', {
            required: true,
            min: 0,
            max: 23,
            onChange: e => validateNumber(e, 0, 23),
          })}
          variant="outlined"
          helperText="Horas"
          error={!!errors.hours || Boolean(error)}
          type="number"
          inputProps={{
            min: 0,
            max: 23,
          }}
          disabled={disabled}
        />
        <TimeInput
          {...register('minutes', {
            required: true,
            min: 0,
            max: 59,
            onChange: e => validateNumber(e, 0, 59),
          })}
          variant="outlined"
          helperText="Minutos"
          error={!!errors.minutes || Boolean(error)}
          type="number"
          inputProps={{
            min: 0,
            max: 59,
          }}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

const deliveryOptions = [
  { value: 'local', label: 'Local' },
  { value: 'national', label: 'Nacional' },
];

const providersOptions = [
  { value: 'self_managed', label: 'Logistica propia' },
];

const scheme = yup.object({
  name: yup
    .string()
    .max(100)
    .required('validation.required'),
  branch_id: yup
    .string()
    .required('validation:required'),
  delivery_type: yup
    .string()
    .required('validation:required'),
  color: yup
    .string()
    .max(7)
    .required('validation:required'),
  courier_providers: yup
    .string()
    .required('validation:required'),
  // equals_to_company_time: yup
  //   .boolean(),
  min_time: yup
    .number()
    .lessThan(
      yup.ref('max_time'),
      'validation:minThan',
    )
    .required('validation:required'),
  // .when('equals_to_company_time', {
  //   is: false,
  //   then: yup
  //     .number()
  //     .required('validation:required'),
  // }),
  max_time: yup
    .number()
    .moreThan(
      yup.ref('min_time'),
      'validation:moreThan',
    )
    .required('validation:required'),
  // .when('equals_to_company_time', {
  //   is: false,
  //   then: yup
  //     .number()
  //     .required('validation:required'),
  // }),
  regular_cost: yup
    .number()
    .required('validation:required'),
  discount_settings: yup
    .boolean(),
  free_deliveries_from: yup
    .number()
    .when('discount_settings', {
      is: true,
      then: yup
        .number()
        .required('validation:required'),
    }),
  available_for_campaigns: yup
    .boolean(),
});

const defaultVals = {
  id: undefined,
  name: '',
  branch_id: '',
  delivery_type: '',
  color: '',
  courier_providers: '',
  // equals_to_company_time: false,
  min_time: 0,
  max_time: 0,
  regular_cost: 0,
  discount_settings: false,
  free_deliveries_from: 0,
  available_for_campaigns: false,
};

interface Props {
  initialValues?: any;
  figure?: any;
  onColorChange?(color: string): void;
  container?: Element | (() => Element);
  isOpen?: boolean;
  onSaveDone?(): void;
  onClose?(): void;
}

const DeliveryZonesModal = ({
  onClose,
  onSaveDone,
  isOpen,
  container,
  onColorChange,
  figure,
  initialValues = null,
}: Props) => {
  const classes = styles();
  const { t } = useTranslation('general');
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
    watch,
    trigger,
    reset,
    getValues,
  } = useForm({
    resolver: yupResolver(scheme),
    defaultValues: defaultVals,
  });
  const { company } = useSession();
  const { loadingZones, createZone, updateZone } = useDeliveryZones(company.id);

  useEffect(() => {
    if (initialValues && initialValues.id !== getValues('id')) {
      reset(initialValues);
    }
  }, [initialValues, reset]);

  const onsubmit = (data) => {
    let figureToSet: any;

    if (figure.type === 'circle') {
      figureToSet = {
        type: 'sphere',
        sphere: {
          radius: figure.data.radius,
          location: {
            coordinates: [
              figure.data.center.lng,
              figure.data.center.lat,
            ],
          },
        },
      };
    } else if (figure.type === 'polygon') {
      const coordinates = figure.data.path.map(coord => [
        coord.lng,
        coord.lat,
      ]);

      figureToSet = {
        type: 'polygon',
        polygon: {
          coordinates: [[
            ...coordinates,
            coordinates[0],
          ]],
        },
      };
    }

    const finalData = data.discount_settings
      ? {
        name: data.name,
        branch_id: data.branch_id,
        available_for_campaigns: data.available_for_campaigns,
        color: data.color,
        delivery_type: data.delivery_type,
        courier_providers: [{
          delivery_cost_settings: {
            regular_cost: data.regular_cost,
          },
          delivery_time_settings: {
            equals_to_company_time: false,
            min_time: data.min_time,
            max_time: data.max_time,
          },
          type: data.courier_providers,
        }],
        discount_settings: {
          free_deliveries_from: data.free_deliveries_from,
        },
        geo_information: figureToSet,
      }
      : {
        name: data.name,
        branch_id: data.branch_id,
        available_for_campaigns: data.available_for_campaigns,
        color: data.color,
        delivery_type: data.delivery_type,
        courier_providers: [{
          delivery_cost_settings: {
            regular_cost: data.regular_cost,
          },
          delivery_time_settings: {
            equals_to_company_time: false,
            min_time: data.min_time,
            max_time: data.max_time,
          },
          type: data.courier_providers,
        }],
        geo_information: figureToSet,
      };

    if (data.id) {
      updateZone(data.id, finalData)
        .then(() => {
          onClose();
          reset(defaultVals);
          if (onSaveDone) {
            onSaveDone();
          }
        });
    } else {
      createZone(finalData)
        .then(() => {
          onClose();
          reset(defaultVals);
          if (onSaveDone) {
            onSaveDone();
          }
        });
    }
  };

  const handleClose = () => {
    if (getValues('id')) {
      reset(defaultVals);
    }
    if (onClose) {
      onClose();
    }
  };

  return (
    <Modal
      hideBackdrop
      disableScrollLock
      className={classes.modal}
      container={container}
      style={{ position: 'absolute', padding: 0 }}
      open={isOpen}
    >
      <Fade in={isOpen}>
        <main className={classes.paper}>
          <button
            type="button"
            onClick={handleClose}
            className={classes.closeButton}
          >
            <CloseIcon htmlColor="#2B2B2B" />
          </button>
          <h2 style={{ textAlign: 'center' }}>
            {
              initialValues
                ? t('edit_zone')
                : t('add_zone')
            }
          </h2>
          <form className={classes.formZone} onSubmit={handleSubmit(onsubmit)}>
            <TextField
              {...register('name')}
              fullWidth
              error={!!errors.name}
              helperText={t(errors.name?.message, { input: t('name') })}
              label={t('name')}
              variant="outlined"
            />
            <BranchSelect
              defaultValue={initialValues?.branch_id}
              error={!!errors.branch_id}
              helperText={t(errors.branch_id?.message, { input: t('branch') })}
              onChange={({ id }) => setValue('branch_id', id, { shouldValidate: true })}
            />
            <Select
              error={errors.delivery_type || null}
              label={t('register:Tipo de envío')}
              name="delivery_type"
              control={control}
              options={deliveryOptions}
              startAdornment={(
                <InputAdornment position="start">
                  <LocalShippingOutlinedIcon htmlColor="gray" />
                </InputAdornment>
              )}
            />
            <ColorSelect
              defaultValue={initialValues?.color}
              onChange={(color) => {
                setValue('color', color, { shouldValidate: true });
                if (onColorChange) {
                  onColorChange(color);
                }
              }}
              label={t('select_zone_color')}
              error={errors.color?.message || null}
            />
            <Select
              error={errors.courier_providers || null}
              label="Responsable del envío"
              name="courier_providers"
              control={control}
              options={providersOptions}
              startAdornment={(
                <InputAdornment position="start">
                  <PersonPinCircleOutlinedIcon htmlColor="gray" />
                </InputAdornment>
              )}
            />
            <div style={{ width: '100%' }}>
              <p style={{
                width: '100%', display: 'flex', alignItems: 'center', gap: 10,
              }}
              >
                <AccessTimeIcon htmlColor="gray" />
                Tu orden se entrega entre:
              </p>
              {/* <FormControlLabel
                style={{ margin: 0, width: '100%' }}
                control={(
                  <Checkbox
                    {...register('equals_to_company_time')}
                    checked={watch('equals_to_company_time')}
                    icon={<CheckBoxOutlineBlankOutlinedIcon />}
                    checkedIcon={<CheckBoxOutlinedIcon />}
                  />
                )}
                label={<small>El tiempo configurado en tienda.</small>}
              /> */}
              <div className={classes.timePickerRange}>
                <TimePicker
                  defaultValue={initialValues?.min_time}
                  error={errors.min_time?.message || null}
                  onChange={(time) => {
                    setValue('min_time', time, { shouldValidate: true });
                    trigger('max_time');
                  }}
                  // disabled={watch('equals_to_company_time')}
                />
                <strong><p>Y</p></strong>
                <TimePicker
                  defaultValue={initialValues?.max_time}
                  error={errors.max_time?.message || null}
                  onChange={(time) => {
                    setValue('max_time', time, { shouldValidate: true });
                    trigger('min_time');
                  }}
                  // disabled={watch('equals_to_company_time')}
                />
              </div>
            </div>
            <p style={{
              width: '100%', display: 'flex', alignItems: 'center', gap: 10,
            }}
            >
              <AccessTimeIcon htmlColor="gray" />
              Costo de envío por:
            </p>
            <TextField
              {...register('regular_cost')}
              fullWidth
              error={!!errors.regular_cost}
              helperText={t(errors.regular_cost?.message, { input: t('regular_cost') })}
              label={t('regular_cost')}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AttachMoneyOutlinedIcon htmlColor="gray" />
                  </InputAdornment>
                ),
              }}
            />
            <div className={classes.logisticInput}>
              {/* <MoneyOffOutlinedIcon htmlColor="gray" /> */}
              <FormControlLabel
                control={(
                  <Checkbox
                    {...register('discount_settings')}
                    checked={watch('discount_settings')}
                    icon={<CheckBoxOutlineBlankOutlinedIcon />}
                    checkedIcon={<CheckBoxOutlinedIcon />}
                  />
                )}
                label={<small>Envío gratis en la compras mayores a:</small>}
              />
              <CTextField
                {...register('free_deliveries_from')}
                error={!!errors.free_deliveries_from}
                helperText={t(errors.free_deliveries_from?.message, { input: t('regular_cost') })}
                id="outlined-basic"
                variant="outlined"
                style={{ alignSelf: 'center' }}
                defaultValue="0"
                disabled={!watch('discount_settings')}
              />
            </div>
            <FormControlLabel
              style={{ margin: 0, width: '100%' }}
              control={(
                <Checkbox
                  {...register('available_for_campaigns')}
                  checked={watch('available_for_campaigns')}
                  icon={<CheckBoxOutlineBlankOutlinedIcon />}
                  checkedIcon={<CheckBoxOutlinedIcon />}
                />
              )}
              label="Zona exclusiva para compras colaborativas"
            />
            <footer className={classes.footeBottons}>
              <Button variant="contained" onClick={handleClose}>
                Cancelar
              </Button>
              <Button disabled={loadingZones} variant="contained" color="primary" type="submit" onClick={handleSubmit(onsubmit)}>
                Guardar
              </Button>
            </footer>
          </form>
        </main>
      </Fade>
    </Modal>
  );
};

const LogisticPage = () => {
  const { t } = useTranslation('general');
  const appBarHeight = useAppBarHeight();
  const [suggestionOpen, setSuggestionOpen] = useState(true);
  const [isVisible, setIsVisible] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [zoneSelect, setZoneSelect] = useState(null);
  const [mapMode, setMapMode] = useState<'view' | 'edit'>('view');
  const [figureColor, setFigureColor] = useState(null);
  const [figure, setFigure] = useState(null);
  const [initialFigure, setInitialFigure] = useState(null);
  const listZoneRef = useRef([]);
  const asideRef = useRef(null);
  const classes = styles();
  const { company } = useSession();
  const { getDeliveryZones, deliveryZones } = useDeliveryZones(company.id);

  useEffect(() => {
    getDeliveryZones();
  }, [getDeliveryZones]);

  const toggleDrawer = () => {
    setIsVisible(!isVisible);
  };

  const toggleModal = () => {
    setMapMode(!showModal ? 'edit' : 'view');
    setShowModal(!showModal);
  };

  const closeModal = () => {
    setMapMode('view');
    setShowModal(false);
    if (zoneSelect) {
      setZoneSelect(null);
    }
    if (initialFigure) {
      setInitialFigure(null);
    }
    if (figureColor) {
      setFigureColor(null);
    }
  };

  const handleFocusZone = (id) => {
    if (id === null) return setZoneSelect(null);
    listZoneRef.current[id].scrollIntoView({ block: 'center', behavior: 'smooth' });
    return setZoneSelect(id);
  };

  const handleEdit = (id) => {
    const foundZone = deliveryZones.find(zone => zone.id === id);

    if (foundZone.geo_information.type === 'sphere') {
      setInitialFigure({
        type: 'circle',
        center: {
          lng: foundZone.geo_information.sphere.location.coordinates[0],
          lat: foundZone.geo_information.sphere.location.coordinates[1],
        },
        radius: foundZone.geo_information.sphere.radius,
      });
    } else {
      setInitialFigure({
        type: 'polygon',
        path: foundZone.geo_information.polygon.coordinates[0]
          .map(coord => ({
            lng: coord[0],
            lat: coord[1],
          }))
          .slice(0, -1),
      });
    }
    setFigureColor(foundZone.color);
    setMapMode('edit');
    setShowModal(true);
    setZoneSelect(id);
  };

  const handleDelete = (id) => {
    Axios
      .delete(`${BASE_URL}/api/v1/companies/${company.id}/deliveryzones/${id}`)
      .then(() => getDeliveryZones());
  };

  const handleSetDafaulValues = useCallback(() => {
    const zone = deliveryZones.find(z => z.id === zoneSelect);
    if (zone) {
      return {
        id: zone.id,
        name: zone.name,
        branch_id: zone.branch_id,
        delivery_type: zone.delivery_type,
        color: zone.color,
        courier_providers: zone.courier_providers[0].type,
        // equals_to_company_time: zone
        //   .courier_providers[0].delivery_time_settings.equals_to_company_time,
        min_time: zone.courier_providers[0].delivery_time_settings.min_time ?? 0,
        max_time: zone.courier_providers[0].delivery_time_settings.max_time ?? 0,
        regular_cost: zone.courier_providers[0].delivery_cost_settings.regular_cost,
        discount_settings: Boolean(zone.discount_settings),
        free_deliveries_from: zone.discount_settings?.free_deliveries_from ?? 0,
        available_for_campaigns: zone.available_for_campaigns,
      };
    }
    return null;
  }, [zoneSelect, deliveryZones]);

  return (
    <div className={classes.containerStyle}>
      <Collapse in={suggestionOpen}>
        <Alert
          severity="success"
          icon={<img style={{ width: 50, aspectRatio: '2/1' }} src={images.Icon_zone_delimiter} alt="delivery-zone" />}
          action={(
            <IconButton
              aria-label="close"
              color="inherit"
              onClick={() => {
                setSuggestionOpen(false);
              }}
              size="large"
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          )}
        >
          <h4 style={{ fontWeight: 400 }}>{t('zones_suggestion')}</h4>
        </Alert>
      </Collapse>
      <main style={{ height: `calc(100% - ${appBarHeight}px)`, position: 'relative' }}>
        <aside ref={asideRef} className={`${classes.asidebar} ${isVisible && classes.hidden}`}>
          <DeliveryZonesModal
            onClose={closeModal}
            onSaveDone={getDeliveryZones}
            isOpen={showModal}
            container={asideRef.current}
            onColorChange={setFigureColor}
            figure={figure}
            initialValues={handleSetDafaulValues()}
          />
          <Button variant="contained" color="primary" onClick={toggleDrawer}>
            {
              isVisible
                ? <ArrowForwardIosIcon fontSize="small" />
                : <ArrowForwardIosIcon style={{ transform: 'rotate(180deg)' }} fontSize="small" />
            }
          </Button>
          <ul>
            <div style={{ padding: '16px 8px' }}>
              <Button fullWidth variant="contained" color="primary" onClick={zoneSelect ? () => handleEdit(zoneSelect) : toggleModal}>
                {
                  zoneSelect
                    ? t('edit_zone')
                    : t('add_zone')
                }
              </Button>
            </div>
            {deliveryZones.map(zone => (
              <li
                className={zoneSelect === zone.id ? classes.shake : null}
                key={zone.id}
                ref={(element) => {
                  listZoneRef.current[zone.id] = element;
                }}
              >
                <DeliveryZoneCard zone={zone} handleEdit={handleEdit} handleDelete={handleDelete} />
              </li>
            ))}
          </ul>
        </aside>
        <main className={classes.mapcontainer}>
          <DelimitationAreaMap
            initialPoint={company.branches[0]?.location}
            initialFigure={initialFigure}
            onFigureChange={setFigure}
            colorEditingFigure={figureColor}
            typeMode={mapMode}
            onSelectZone={handleFocusZone}
            zones={
              deliveryZones
                .map(({
                  geo_information,
                  id,
                  color,
                }) => ({
                  ...geo_information,
                  id,
                  color,
                }))
            }
          />
        </main>
      </main>
    </div>
  );
};

export default LogisticPage;
