import { types } from './types';
import AuthorizedApiClient from '../../core/common/api/AuthorizedApiClient';

export const resetLocations = () => ({
  type: types.RESET_LOCATIONS,
});

export const getBranchesForLocations = (companyId, params = {}) => async (
  dispatch: any,
) => {
  const sg = new AuthorizedApiClient();
  const path = `companies/${companyId}/branches`;

  try {
    const response = await sg.get(params, path);

    if (response != null) {
      dispatch({
        type: types.SET_BRANCHES_LOCATIONS_SUCCESS,
        response: response.data,
      });
    }
  } catch (error) {
    dispatch({ type: types.SET_BRANCHES_LOCATIONS_FAILURE, error });
  }
};

export const getLocations = (companyId, params = {}) => async (
  dispatch: any,
) => {
  const sg = new AuthorizedApiClient();
  const path = `companies/${companyId}/locations`;
  dispatch({ type: types.SET_LOCATIONS_PROCESSING, payload: true });
  try {
    const response = await sg.get(params, path);

    if (response != null) {
      dispatch({ type: types.SET_LOCATIONS_SUCCESS, response: response.data });
    }
  } catch (error) {
    dispatch({ type: types.SET_LOCATIONS_FAILURE, error });
  }
};
