/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-tabs */
import React, { ChangeEvent, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import { CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';
import { uploadTmpImage } from '../../../services/images';

const useStyles = makeStyles(theme => ({
  img: {
    maxWidth: 120,
    maxHeight: 120,
    objectFit: 'cover',
    borderRadius: 5,
    margin: 5,
    minHeight: 120,
    minWidth: 120,
  },
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: 'none',
  },
  imgContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  deleteIcon: {
    position: 'absolute',
    top: 0,
    right: 0,
    backgroundColor: '#3D4042',
    borderRadius: '50%',
    padding: 4,
    color: 'white',
    '&:hover': {
      backgroundColor: '#2c2f30',
    },
  },
}));

interface MultiImgUploaderProps {
  setTempImages: any,
  signUrl: any,
  tempImages: any,
  imgUrl: any,

}
export const MultiImgUploader = ({
  setTempImages,
  signUrl,
  imgUrl,
  tempImages,
}: MultiImgUploaderProps) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [imgStock, setImgStock] = useState(imgUrl);

  const imageHandleChange = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      setLoading(true);
      const input = e.target;
      const rawImagesFiles = Array.from(input.files);
      const signedImages = await Promise.all(
        rawImagesFiles.map(file => uploadTmpImage(file, signUrl)),
      );
      const tempImagesToSet = rawImagesFiles.map((file, i) => ({
        type: 'image',
        key: signedImages[i],
        preview: URL.createObjectURL(file),
        raw: file,
      }));
      const tempImgsNExistingImgs = [...imgUrl, ...tempImagesToSet];
      setTempImages(tempImgsNExistingImgs);
    } catch (err) {
      console.log('error', err);
    } finally {
      setLoading(false);
    }
  };

  const deleteImage = (imageData) => {
    const isSelectedImage = tempImages.some(i => i.key === imageData.key);
    const imagesFilter = isSelectedImage
      ? tempImages.filter(img => img.key !== imageData.key)
      : [...tempImages, imageData];
    setTempImages(imagesFilter);
  };
  const deleteImageStock = (imageData) => {
    const isSelectedImage = imgUrl.some(i => i.key === imageData.key);
    const imagesFilter = isSelectedImage
      ? imgUrl.filter(img => img.key !== imageData.key)
      : [...imgUrl, imageData];
    setImgStock(imagesFilter);
    setTempImages(imagesFilter);
  };

  return (
    <div>
      {
				loading
				  ? <CircularProgress color="inherit" size={20} />
				  : (
  <>
    <input
      accept=".jpeg,.jpg,.png"
      className={classes.input}
      onChange={imageHandleChange}
      id="contained-button-file"
      multiple
      type="file"
    />
    <label htmlFor="contained-button-file">
      <IconButton color="primary" aria-label="upload picture" component="span" size="large">
        <AddAPhotoIcon />
      </IconButton>
      Agregar foto del producto
    </label>
  </>
				  )
			}
      <div className={classes.imgContainer}>
        {
          tempImages.length === 0

            ? (imgStock.map(image => (
              <div style={{ position: 'relative' }} key={image.key}>
                <IconButton
                  className={classes.deleteIcon}
                  color="default"
                  onClick={() => deleteImageStock(image)}
                  aria-label="upload picture"
                  component="span"
                  size="large"
                >
                  <CloseIcon />
                </IconButton>
                <img className={classes.img} src={image.url} alt="Upload" />
              </div>

            )))
            : tempImages.map(i => (
              <div style={{ position: 'relative' }} key={i.key}>
                <IconButton
                  className={classes.deleteIcon}
                  color="default"
                  onClick={() => deleteImage(i)}
                  aria-label="upload picture"
                  component="span"
                  size="large"
                >
                  <CloseIcon />
                </IconButton>
                <img className={classes.img} src={i.preview} alt="Upload" />
              </div>
            ))
        }
      </div>

    </div>
  );
};
