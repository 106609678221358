/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import {
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { makeStyles } from '@mui/styles';
import Alert from '../../../../../components/ui/notifications/alert';
import { rules as validationRules } from '../../../../../util/Validations';
import Box from '../../../../../components/ui/Layout/Box';
import Grid from '../../../../../components/ui/Layout/Grid';
import FormInput from '../../../../../components/ui/FormInput';
import SuccessButton from '../../../../../components/ui/Buttons/SuccessButton';
import Select, {
  SelectOption,
} from '../../../../../components/ui/FormInput/Select';
import { GENRES } from '../../../../../constants/common';
import AvatatUploader from '../../../../../components/ui/AvatatUploader';
import { getFormattedDate } from '../../../../../util/dates';
import ControlledDateField from '../../../../../components/ui/ControlledDateField';
import LinkButton from '../../../../../components/ui/Buttons/LinkButton';
import PasswordForm from './PasswordForm';

const useStyles = makeStyles(() => ({
  card: {
    border: '0.5px solid #DBDADA',
    borderRadius: '8px',
  },
  avatarContainer: {
    width: '150px',
    height: '150px',
  },
  lastUpdate: {
    fontSize: '0.8rem',
  },
}));

export default ({
  user,
  attemptSaveProfile,
  processing,
  error,
  saved,
  clearSaved,
  handleChangePasswordClick,
}) => {
  const { t } = useTranslation(['general']);
  const classes = useStyles();

  const [image, setImage] = useState(null);

  const schema = Yup.object({
    name: Yup.string()
      .max(validationRules.maxLength)
      .required(
        t('translation:required', {
          input: t('settings:name'),
        }),
      ),
    last_name: Yup.string()
      .max(validationRules.maxLength)
      .required(
        t('translation:required', {
          input: t('register:last_name'),
        }),
      ),
    phone: Yup.string().max(validationRules.maxLength),
    birthdate: Yup.date()
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr)),
  });
  const {
    register, control, handleSubmit, formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const genresOptions: Array<SelectOption> = [
    { value: GENRES.MALE, label: t('general:settings:male') },
    { value: GENRES.FEMALE, label: t('general:settings:female') },
    { value: GENRES.OTHER, label: t('general:settings:other_genre') },
  ];

  const onSubmit = (data) => {
    data.id = user.id ? user.id : null;
    if (data.id) {
      data.image = image === '' ? '' : user.image;
    }
    if (!data.birthdate) {
      data.birthdate = '';
    } else {
      data.birthdate = getFormattedDate(data.birthdate);
    }
    // if (!data.genre) {
    //   delete data.genre;
    // }
    // console.log("data", data)
    attemptSaveProfile(data, image !== '' ? image : null);
  };

  const imgUrl = user && user.image_url ? user.image_url : '';
  // console.log("user =>", user.birthdate)
  return (
    <Box>
      {error && (
        <Box width={1}>
          <Alert
            severity="error"
            message={t(`general:settings:${error}`, error)}
          />
        </Box>
      )}

      {saved && (
        <Box width={1}>
          <Alert
            severity="success"
            message={t('general:settings:user_saved')}
            clearAlert={clearSaved}
          />
        </Box>
      )}

      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Box mb={3}>
          <Box display="flex" justifyContent="center" mb={2}>
            <Box className={classes.avatarContainer}>
              <AvatatUploader imgUrl={imgUrl} setImage={setImage} />
            </Box>
          </Box>

          <Typography variant="h5" color="textPrimary" align="center">
            {t('general:settings:welcome_user', {
              user: `${user.name} ${user.last_name}`,
            })}
          </Typography>

          <Typography variant="body2" align="center">
            {t('general:settings:privacy_msg')}
            .
            {' '}
            <Link to="/settings">{t('general:settings:more_info')}</Link>
            .
          </Typography>
        </Box>
        <Box className={classes.card} p={2}>
          <Typography variant="h5" color="textPrimary">
            {t('general:settings:profile')}
          </Typography>

          <Typography variant="body2">
            {t('general:settings:profile_msg')}
          </Typography>

          <Box mt={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormInput
                  register={register('name')}
                  error={errors.name}
                  label={t('general:settings:name')}
                  defaultValue={user.name ? user.name : ''}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormInput
                  register={register('last_name')}
                  error={errors.last_name}
                  label={t('general:settings:last_name')}
                  defaultValue={user.last_name ? user.last_name : ''}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <ControlledDateField
                  control={control}
                  name="birthdate"
                  errors={errors}
                  label={t('general:settings:birthdate')}
                  defaultValue={user.birthdate ? user.birthdate : null}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Select
                  variant="outlined"
                  control={control}
                  name="genre"
                  error={errors.genre}
                  label={t('general:settings:genre')}
                  defaultValue={user.genre ? user.genre : ''}
                  options={genresOptions}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box className={classes.card} p={2} mt={3}>
          <Typography variant="h5" color="textPrimary">
            {t('general:settings:contact_info')}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body1">
                {t('general:settings:email')}
                :
                {user.email}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormInput
                register={register('phone')}
                error={errors.phone}
                label={t('general:settings:phone')}
                defaultValue={user.phone ? user.phone : ''}
              />
            </Grid>
          </Grid>
        </Box>

        <Box display="flex" justifyContent="flex-end" mt={2}>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={6}>
              <LinkButton onClick={handleChangePasswordClick}>{t('general:settings:change_password')}</LinkButton>
            </Grid>
            <Grid container item xs={6} sm={6} justifyContent="flex-end">
              <SuccessButton
                processing={processing ? 1 : 0}
                onClick={handleSubmit(onSubmit)}
                color="primary"
                text={t('general:save')}
              />
            </Grid>
          </Grid>
        </Box>
      </form>
      <PasswordForm />
    </Box>
  );
};
