import React from 'react';
import { useDispatch } from 'react-redux';
import { editUnitOfMeasure } from '../../../../actions/unitofmeasure';
import UnitsPageView from './UnitsPageView';

export default () => {
  const dispatch = useDispatch();
  const openNewUnitOfMeasure = () => {
    dispatch(editUnitOfMeasure());
  };

  return <UnitsPageView openNewUnitOfMeasure={openNewUnitOfMeasure} />;
};
