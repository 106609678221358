import { types } from '../../actions/auth/types';

export default (state = false, action : any) => {
  switch (action.type) {
    case types.SET_LOGIN_PROCESSING:
      return action.payload;

    default:
      return state;
  }
};
