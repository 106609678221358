/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable import/no-extraneous-dependencies */
import { TextField } from '@mui/material';
import React, { useCallback } from 'react';
import _ from 'lodash';
import { withStyles } from '@mui/styles';
import { EMPTY_QUANTITY } from '../../../../../../../constants/common';

const CTextField = withStyles(theme => ({
  root: {
    '& .MuiOutlinedInput-root': {
      padding: 0,
      borderRadius: 2,
      width: '60px',
      [theme.breakpoints.down('md')]: {
        width: '50px',
        fontSize: '0.875rem',
      },
    },
    '& .MuiOutlinedInput-input': {
      padding: '5px 0',
      textAlign: 'center',
    },
    '& .Mui-disabled': {
      backgroundColor: '#E5E6E5',
    },

  },
}))(TextField);

interface IProps {
  value?: number | null;
  disabled?: boolean;
  handleOnChange?: Function;
}

export default ({ value, disabled, handleOnChange }: IProps) => {
  const delayedOnChange = useCallback(_.debounce(q => handleOnChange(q), 500), []);
  const _handleOnChange = (e: any) => {
    if (handleOnChange) {
      delayedOnChange(e.target.value);
    // handleOnChange(e.target.value)
    }
  };

  return (
    <CTextField
      defaultValue={value || ''}
      // value={value ? value : ""}
      placeholder={EMPTY_QUANTITY}
      variant="outlined"
      disabled={disabled}
      type="number"
      onChange={_handleOnChange}
    />
  );
};
