import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles(() => ({
  statusIndicator: { width: 8, height: 8, borderRadius: 3 },
}));

interface Props {
  color?: string;
  label: string;
}

export const StatusIndicator = ({ color = '#ccc', label }: Props) => {
  const classes = useStyles();

  return (
    <Box display="flex" alignItems="center" gap={1}>
      <div className={classes.statusIndicator} style={{ backgroundColor: color }} />
      <Typography>
        {label}
      </Typography>
    </Box>
  );
};
