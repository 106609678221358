import { makeStyles } from '@mui/styles';
import React from 'react';
import Box from '../../../../../../../components/ui/Layout/Box';

const useStyles = makeStyles(theme => ({
  circle: {
    width: '30px',
    height: '30px',
    backgroundColor: '#627AF7',
    WebkitMask: `url(${theme.icons.circle}) no-repeat center`,
    mask: `url(${theme.icons.circle}) no-repeat center`,
  },
  adminCircle: {
    backgroundColor: '#ED417B',
  },
}));

interface CircleProps {
  isAdmin: boolean
}

export default ({ isAdmin }: CircleProps) => {
  const classes = useStyles();
  const adminClass = isAdmin ? classes.adminCircle : '';
  return (
    <Box className={`${classes.circle} ${adminClass}`} />
  );
};
