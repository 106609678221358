/* eslint-disable no-restricted-syntax */
import FormData from 'form-data';
import { types } from './types';
import Log from '../../util/Log';
import AuthorizedApiClient from '../../core/common/api/AuthorizedApiClient';

export const editBranch = (branch = null) => ({
  type: types.EDIT_BRANCH,
  payload: branch,
});

export const savingBranch = () => ({
  type: types.SAVING_BRANCH,
});

export const saveBranchSucceed = response => ({
  type: types.SAVE_BRANCH_SUCCESS,
  response,
});

export const saveBranchFailed = error => ({
  type: types.SAVE_BRANCH_FAILURE,
  error,
});

export const deletingBranch = () => ({
  type: types.DELETING_BRANCH,
});

export const deleteBranchSucceed = response => ({
  type: types.DELETE_BRANCH_SUCCESS,
  response,
});

export const deleteBranchFailed = error => ({
  type: types.DELETE_BRANCH_FAILURE,
  error,
});

export const closeModalBranch = () => ({
  type: types.CLOSE_BRANCH_MODAL,
});

export const saveBranch = (_data, file, companyId) => async (dispatch: any) => {
  dispatch(savingBranch());
  const sg = new AuthorizedApiClient();
  let path = `companies/${companyId}/branches`;

  try {
    let response;
    let data;
    const { id } = _data;

    if (file !== null) {
      data = new FormData();
      for (const key in _data) {
        if (key !== 'id') {
          data.append(key, _data[key]);
        }
      }
      data.append('image', file);
    } else {
      data = _data;
    }

    if (id != null) {
      path = `${path}/${id}`;
      delete data.id;
      response = await sg.put(data, path);
    } else {
      delete data.id;
      response = await sg.post(data, path, {
        'Content-Type': 'multipart/form-data',
      });
    }

    if (response != null) {
      Log.debug('saved Branch', response);
      dispatch(saveBranchSucceed(response));
      dispatch(closeModalBranch());
    }
  } catch (error) {
    Log.error(error.message);
    dispatch(saveBranchFailed(error.message));
  }
};

export const deleteBranch = (branch, companyId) => async (dispatch: any) => {
  dispatch(deletingBranch());
  const sg = new AuthorizedApiClient();
  let path = `companies/${companyId}/branches`;
  try {
    if (!branch.id) {
      throw new Error('The id is required');
    }

    path = `${path}/${branch.id}`;
    const response = await sg.delete(path);

    if (response != null) {
      Log.debug('deleted branc', response);
      dispatch(deleteBranchSucceed(response));
      dispatch(closeModalBranch());
    }
  } catch (error) {
    Log.error(error.message);
    dispatch(deleteBranchFailed(error.response.data));
  }
};
