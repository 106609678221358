/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/prop-types */
import {
  Box, Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import {
  clearCount,
  getCountBranchLocations,
  setBranch, setLocation, setProductsSearch, showMovementsSummary,
} from '../../../../../actions/count';
import BranchSelector from '../../../../../components/ui/BranchSelector';
import SuccessButton from '../../../../../components/ui/Buttons/SuccessButton';
import InputSearch from '../../../../../components/ui/InputSearch';
import LocationSelector from '../../../../../components/ui/LocationSelector';
import Modal from '../../../../../components/ui/Modals/Modal';
import { PERMISSION, RESOURCES } from '../../../../../constants/common';
import { useSession } from '../../../../../util/session';
import MovementsSummary from '../../RecountsPage/Recount/MovementsSummary';
import RecountProductsList from '../../RecountsPage/Recount/RecountProductsList';
import SwitchLocations from '../../RecountsPage/Recount/SwitchLocations';

const styles = makeStyles(theme => ({
  searchBox: {
    marginTop: '10px',
    [theme.breakpoints.down('lg')]: {
      width: '50%',
    },
  },
  buttonBox: {
    [theme.breakpoints.down('lg')]: {
      width: '50%',
    },
  },
}));

const QuantityForm = ({ handleClose, open }) => {
  const dispatch = useDispatch();
  const classes = styles();
  const { t } = useTranslation();
  const count = useSelector((state: any) => state.count);
  const { branch_id, location_id } = count.filters;
  const company = useSelector((state: any) => state.session.company);
  const { can } = useSession();

  let typeView = 1;
  if (can(RESOURCES.compared_count, PERMISSION.create) === true) {
    typeView = 2;
  }

  const _setBranch = (value) => {
    dispatch(setBranch(value));
  };

  const _setLocation = (location) => {
    dispatch(setLocation(location));
  };
  const _setProductsSearch = (value) => {
    dispatch(setProductsSearch(value));
  };

  const handleSummaryClick = () => {
    dispatch(showMovementsSummary());
  };

  useEffect(() => {
    if (company && branch_id) {
      dispatch(
        getCountBranchLocations(company.id, {
          filters: { branch_id, per_page: 1000 },
        }),
      );
    }
  }, [dispatch, company, branch_id]);

  useEffect(() => () => {
    dispatch(clearCount());
  }, [dispatch]);

  const header = (
    <>
      <Typography style={{ textAlign: 'center' }} variant="h5">Administrar cantidades</Typography>

      <Box display="flex" flexDirection="row" flexWrap="wrap" mb={2}>
        <Box display="flex" flexDirection="row" flexGrow={1}>
          <Box width="110px">
            <BranchSelector
              company_id={company ? company.id : null}
              branch_id={branch_id}
              setBranch={value => _setBranch(value)}
            />
          </Box>
          <Box width="110px" ml={2}>
            <LocationSelector
              company_id={company ? company.id : null}
              branch_id={branch_id}
              location_id={null}
              setLocation={value => _setLocation(value)}
            />
          </Box>
        </Box>
        {!location_id && (
        <Box>
          <SwitchLocations />
        </Box>
        )}
      </Box>
      <Box display="flex" flexDirection="row" flexWrap="wrap" mb={2}>
        <Box flexGrow={1} className={classes.searchBox}>
          <InputSearch setSearchValue={_setProductsSearch} />
        </Box>
      </Box>
    </>
  );

  const body = (
    <>
      <Box style={{ minHeight: 330 }}>
        <RecountProductsList type={typeView} />
      </Box>
      <MovementsSummary type={typeView} />
    </>
  );

  const action = (
    <Box className={classes.buttonBox}>
      <SuccessButton
        text={t('general:apply_changes')}
        onClick={handleSummaryClick}
      />
    </Box>
  );

  return (
    <Modal
      open={open}
      onClose={handleClose}
      disableEnforceFocus
      disableAutoFocus
      title={header}
      actions={action}
    >
      { body }
    </Modal>
  );
};

export default QuantityForm;
