/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import ReactSpeedometer from 'react-d3-speedometer';
import LabelledOutline from '../../../../../hoc/LabelledOutline';
import Box from '../../../../../components/ui/Layout/Box';
import { useKPIDashboardContext } from '../KPI/context/KPIDashboardContext';

const SalesSpeedoMeter = () => {
  const { state } = useKPIDashboardContext();
  const { total_revenue } = state;
  const MAX_REVENUE = 5000;
  return (
    <LabelledOutline label="Ingresos por ventas" customWidth={170}>
      <Box
        display="flex"
        heighth="100%"
        alignItems="center"
        justifyContent="center"
      >
        <ReactSpeedometer
          value={total_revenue}
          minValue={0}
          maxValue={MAX_REVENUE}
          height={250}
        />
      </Box>
    </LabelledOutline>
  );
};

export default SalesSpeedoMeter;
