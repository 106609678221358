/* eslint-disable @typescript-eslint/no-shadow */
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

export default function useGoalsForm(defaultValues = {
  goal_amount: undefined,
  min_percentage: undefined,
  min_amount: undefined,
  min_sale: undefined,
}) {
  const { t } = useTranslation(['general']);

  const schema = Yup.object({
    goal_amount: Yup.number()
      .required(t('general:shared_campaigns:goal_amount_error'))
      .typeError(t('general:shared_campaigns:goal_amount_error'))
      .min(0),

    min_percentage: Yup.number()
      .required(t('general:shared_campaigns:min_percentage_error'))
      .typeError(t('general:shared_campaigns:min_percentage_error'))
      .min(0)
      .max(100, 'El porcentage no puede ser mayor a 100'),

    min_amount: Yup.number()
      .required(t('general:shared_campaigns:min_amount_error'))
      .typeError(t('general:shared_campaigns:min_amount_error'))
      .min(0)
      .when('goal_amount', (goal_amount, schema) => schema.test({
        test: min_amount => !!goal_amount && min_amount <= goal_amount,
        message: t('general:shared_campaigns:min_amount_max_error'),
      })),

    min_sale: Yup.number()
      .required(t('general:shared_campaigns:min_sale_error'))
      .typeError(t('general:shared_campaigns:min_sale_error'))
      .min(0)
      .when('goal_amount', (goal_amount, schema) => schema.test({
        test: min_sale => !!goal_amount && min_sale <= goal_amount,
        message: t('general:shared_campaigns:min_sale_max_error'),
      })),
  });
  const {
    register, handleSubmit, formState: { errors }, getValues, setValue, clearErrors: clearError,
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues,
  });

  return {
    register,
    handleSubmit,
    errors,
    getValues,
    setValue,
    clearError,
  };
}
