import Axios from 'axios';
import { useCallback, useState } from 'react';
import { BASE_URL } from '../constants/api/ApiConstants';

const useUnits = (companyId: string) => {
  const [units, setUnits] = useState([]);
  const [loadingUnits, setLoad] = useState(false);
  const unitsPath = `companies/${companyId}/unitofmeasures`;

  const getUnits = useCallback(() => {
    setLoad(true);
    Axios.get(`${BASE_URL}/api/v1/${unitsPath}`)
      .then((res) => {
        setUnits(res.data.data);
      })
      .finally(() => setLoad(false));
  }, [unitsPath]);

  return {
    getUnits,
    units,
    loadingUnits,
  };
};

export default useUnits;
