import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './RegisterBanner.module.css';
import UniverseImg from '../../../ui/UniverseImg';
import Box from '../../../ui/Layout/Box';
import Grid from '../../../ui/Layout/Grid';

export default () => {
  const { t } = useTranslation(['register']);
  return (
    <Box className="mx-auto" maxWidth="80%" width={580}>
      <Grid item>
        <Grid
          container
          className={`${styles.RegisterBanner}`}
          style={{ position: 'relative' }}
        >
          <Grid item md={2} />
          <Grid item className="text-center" xs={12} md={8}>
            {t('register:msg_banner')}
          </Grid>
          <Grid item md={2} />
          <UniverseImg className="universe" />
        </Grid>
      </Grid>
    </Box>
  );
};
