import { useCallback, useReducer } from 'react';
import ApiOrdersGateway from '../../../../../core/gateways/api/ApiOrdersGateway';

import {
  SET_ORDERS_LIST_ERROR,
  SET_ORDERS_LIST_PROCESSING,
  SET_ORDERS_LIST_SUCCESS,
  SET_ORDERS_LIST_PARAMS,
  SET_ORDERS_RESET_FILTERS,
  SET_ORDERS_RESET_ORDERS,
} from '../constants';
import ordersListReducer, { initialState } from '../reducers/orders';

const useOrdersListState = () => {
  const [state, dispatch] = useReducer(ordersListReducer, initialState);

  const setProcessing = useCallback(
    (processing) => {
      dispatch({ type: SET_ORDERS_LIST_PROCESSING, payload: processing });
    },
    [dispatch],
  );

  const setOrdersFailure = useCallback(
    (error) => {
      dispatch({ type: SET_ORDERS_LIST_ERROR, payload: error });
    },
    [dispatch],
  );

  const setOrdersResponse = useCallback(
    (response) => {
      dispatch({ type: SET_ORDERS_LIST_SUCCESS, payload: response });
    },
    [dispatch],
  );

  const getOrders = useCallback(
    async (params = {}) => {
      const sg = ApiOrdersGateway.getInstance();

      setProcessing(true);
      try {
        const response = await sg.get(params);

        if (response != null) {
          setOrdersResponse(response);
        }
      } catch (error) {
        setOrdersFailure(error);
      }
    },
    [setProcessing, setOrdersResponse, setOrdersFailure],
  );

  const refreshList = () => {
    getOrders(initialState.params);
  };

  const setParams = useCallback(
    (params) => {
      dispatch({ type: SET_ORDERS_LIST_PARAMS, payload: params });
    },
    [dispatch],
  );

  const resetFilters = () => {
    dispatch({
      type: SET_ORDERS_RESET_FILTERS,
      payload: initialState.params.filters,
    });
  };

  const resetOrders = useCallback(() => {
    dispatch({
      type: SET_ORDERS_RESET_ORDERS,

    });
  }, [dispatch]);

  return {
    state,
    getOrders,
    refreshList,
    setParams,
    resetFilters,
    resetOrders,
  };
};

export default useOrdersListState;
