/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Tab, Paper, Tabs as MTabs } from '@mui/material';
import Box from '../Layout/Box';

export const TabPanel = (props) => {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0} mt={5} width={1}>
          {children}
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export const a11yProps = index => ({
  id: `nav-tab-${index}`,
  'aria-controls': `nav-tabpanel-${index}`,
});

export const LinkTab = props => (
  <Tab
    component="a"
    onClick={(event) => {
      event.preventDefault();
    }}
    {...props}
    style={{ textTransform: 'capitalize' }}
  />
);

interface TabsProps {
  tabs: Array<any>;
  onTabChanged?: Function;
}

export const Tabs = ({ tabs, onTabChanged }: TabsProps) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    if (onTabChanged) {
      onTabChanged(event, newValue);
    }
    setValue(newValue);
  };

  const _tabs = tabs.map(tab => (
    <LinkTab key={tab.index} label={tab.title} />
  ));

  const tabPanels = tabs.map(tab => (
    <TabPanel key={tab.index} value={value} index={tab.index}>
      {tab.content}
    </TabPanel>
  ));
  return (
    <>
      <Paper>
        <MTabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          {_tabs}
        </MTabs>
      </Paper>
      {tabPanels}
    </>
  );
};
