/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';

const Styles = makeStyles(theme => ({
  container: {
    width: '100%',
    height: 80,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  circleContainer: {
    width: '70%',
    height: 50,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  circle: {
    display: 'inline-block',
    width: 20,
    height: 20,
    borderRadius: '50%',
    cursor: 'pointer',
    transition: 'border 0.1s, box-shadow 0.1s',
  },
  selected: {
    border: '5px solid #CCCCCC',
    boxShadow: theme.shadow.main,
  },
}));
interface CategoryColorProps {
  setColor: Function
  propcolor: string
}

export default ({ setColor, propcolor }: CategoryColorProps) => {
  const [C, setC] = useState('');
  const classes = Styles();
  const colors = [
    '#EB4A4F',
    '#F6A758',
    '#F6D763',
    '#4BB958',
    '#1C83E2',
    '#254BBA',
    '#A736A0',
    '#000000',
    '#48BA96',
    '#5AD257',
    '#F5889B',
  ];
  useEffect(() => {
    setC(propcolor);
  }, [propcolor]);
  const printColors = colors.map(color => (
    <span
      key={color}
      className={`${classes.circle} ${C === color ? classes.selected : ''}`}
      onClick={() => { setColor(color); setC(color); }}
      style={{ backgroundColor: color }}
    />
  ));

  return (
    <div className={classes.container}>
      <p>Escoge un color para identificar esta categoría:</p>
      <div className={classes.circleContainer}>
        {printColors}
      </div>
    </div>
  );
};
