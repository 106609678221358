/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TextField, FormControl } from '@mui/material';
import { makeStyles } from '@mui/styles';
// import { useTranslation } from 'react-i18next';
// import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';

const useStyles = makeStyles({
  root: {
    '& .MuiInputBase-root': {
      margin: 0,
      '& .MuiButtonBase-root': {
        padding: 0,
      },
      '& .MuiInputBase-input': {
        margin: 0,
        paddingLeft: 0,
      },
      '& .MuiSvgIcon-root': {
        padding: 0,
      },
    },
  },
});
export default ({
  error, label = '', onChange, defaultValue,
}) => {
  const classes = useStyles();
  // const { t } = useTranslation(['general']);

  const [date, setDate] = useState<Date | null>(
    defaultValue || null,
  );
  const _onChange = (date: Date | null) => {
    setDate(date);
    onChange(date);
  };

  return (
    <FormControl variant="standard" error={!!error}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          className={classes.root}
          label={label}
          value={date}
          onChange={_onChange}
          // inputProps={{ readOnly: true }}
          // KeyboardButtonProps={{
          //   'aria-label': 'change time',
          // }}
          // InputLabelProps={{
          //   shrink: true,
          // }}
          // minutesStep={1}
          // initialFocusedDate={date}
          // okLabel={t('general:ok')}
          // cancelLabel={t('general:cancel')}
          // inputVariant="outlined"
          InputAdornmentProps={{ position: 'start' }}
          // keyboardIcon={<QueryBuilderIcon fontSize="small" />}
          // ampm
          renderInput={params => (
            <TextField disabled {...params} label={label} helperText={error && error.message} />
          )}
        />
      </LocalizationProvider>
    </FormControl>
  );
};
