import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '../../../../../components/ui/Layout/Box';
import BestSellerList from './BestSellerList';
import LabelledOutline from '../../../../../hoc/LabelledOutline';

const BestSeller = () => {
  const { t } = useTranslation(['general']);

  return (
    <LabelledOutline label={t('general:admin_dashboard:bestseller_title')}>
      <Box mt={1} mb={1}>
        <BestSellerList />
      </Box>
    </LabelledOutline>
  );
};

export default BestSeller;
