/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/prop-types */
import {
  Button,
  FormControl,
  Input,
  InputLabel,
  Modal,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import MaskedInput from 'react-text-mask';
import { makeStyles } from '@mui/styles';
import Box from '../ui/Layout/Box';
import { MARKETPLACE_URL } from '../../constants/api/ApiConstants';

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: 500,
    height: 'auto',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #878787',
    borderRadius: 5,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  centeredItems: {
    margin: 20,
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  root: {
    width: '100%',
    display: 'felx',
    flexDirection: 'column',
  },
  containerCard: {
    display: 'flex',
    marginTop: 25,
    marginBottom: 25,
  },
}));

export const ModalInputs = ({
  open, setOpen, defaultValue, type, label, onSubmit, ...props
}) => {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [inputState, setInputState] = useState(defaultValue);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target; // EDG
    setInputState(value);
  };

  function getModalStyle() {
    return {
      top: `${50}%`,
      left: `${50}%`,
      transform: `translate(-${50}%, -${50}%)`,
    };
  }

  const handleClose = () => {
    setOpen(false);
  };

  const input = () => {
    switch (type) {
      case 'text': {
        return (
          <TextField
            variant="standard"
            fullWidth
            {...props}
            type={type}
            label={label}
            value={inputState}
            onChange={onChange}
            InputProps={{
              style: {
                textAlign: 'center',
                margin: '10px 0',
              },
            }}
          />
        );
      }

      case 'direccion': {
        return (
          <TextField
            variant="standard"
            fullWidth
            {...props}
            type={type}
            label={label}
            value={inputState}
            onChange={onChange}
            InputProps={{
              style: {
                textAlign: 'center',
                margin: '10px 0',
              },
              endAdornment: (
                <Typography
                  variant="body2"
                  style={{ fontStyle: 'italic', color: '#CCC' }}
                >
                  {/* {value ? "." : ""} */}
                  {MARKETPLACE_URL}
                </Typography>
              ),
            }}
          />
        );
      }

      case 'phoneNumber': {
        return (
          <FormControl variant="standard">
            <InputLabel htmlFor="formatted-text-mask-input">Número de teléfono</InputLabel>
            <Input
              value={inputState}
              onChange={onChange}
              name="textmask"
              id="formatted-text-mask-input"
              inputComponent={TextMaskCustom as any}
            />
          </FormControl>
        );
      }

      default: {
        return (
          <TextField
            variant="standard"
            fullWidth
            {...props}
            type={type}
            label={label}
            value={inputState}
            onChange={onChange}
            InputProps={{
              style: {
                textAlign: 'center',
                margin: '10px 0',
              },
            }}
          />
        );
      }
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      disableEnforceFocus
      disableAutoFocus
    >
      <Box style={modalStyle} className={classes.paper}>
        <form onSubmit={e => onSubmit(e, inputState)}>
          {input()}
          <Box fullWidth display="flex" justifyContent="flex-end" padding="5px">
            <Button type="submit" variant="contained" color="primary"> guardar </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

const TextMaskCustom = (props: any) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
};
