import React from 'react';

import { CampaignListProvider } from '../CampaignsList/context/CampaignsListContext';
import SharedCampaignsPageView from '../SharedCampaignsPageView';

export default () => (

  <CampaignListProvider>
    <SharedCampaignsPageView />
  </CampaignListProvider>

);
