import {
  START_IMAGE_UPLOAD,
  SET_IMAGE_UPLOAD_ERROR,
  SET_IMAGE_UPLOAD_SUCCESS,
  DELETE_IMAGE,
  SET_VIDEO_UPLOAD_SUCCESS,
  DELETE_VIDEO,
  SET_VIDEO_UPLOAD_ERROR,
  START_VIDEO_UPLOAD,
  SET_ERROR,
} from './constants';

export const initialState = {
  images: [],
  video: null,
  error: null,
};

export function reducer(state, action) {
  switch (action.type) {
    case START_IMAGE_UPLOAD:
      return {
        ...state,
        error: null,
        images: [...state.images, action.payload],
      };

    case SET_IMAGE_UPLOAD_ERROR:
      return {
        ...state,
        error: action.payload.error,
        images: state.images.filter(image => image.uid !== action.payload.uid),
      };

    case SET_IMAGE_UPLOAD_SUCCESS:
      return {
        ...state,
        images: state.images.map((image) => {
          if (image.uid === action.payload.uid) {
            return action.payload;
          }
          return image;
        }),
      };

    case DELETE_IMAGE:
      return {
        ...state,
        images: state.images.filter(image => image.uid !== action.payload.uid),
      };

    case START_VIDEO_UPLOAD:
      return {
        ...state,
        error: null,
        video: action.payload,
      };

    case SET_VIDEO_UPLOAD_ERROR:
      return {
        ...state,
        error: action.payload.error,
        video: null,
      };

    case SET_VIDEO_UPLOAD_SUCCESS:
      return {
        ...state,
        video: action.payload,
      };

    case DELETE_VIDEO:
      return {
        ...state,
        video: null,
      };

    case SET_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
}
