/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable prefer-const */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/prop-types */
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import DangerButton from '../../../../../../../../components/ui/Buttons/DangerButton';
import SuccessButton from '../../../../../../../../components/ui/Buttons/SuccessButton';
import ConfirmDialog from '../../../../../../../../components/ui/ConfirmDialog';
import Box from '../../../../../../../../components/ui/Layout/Box';
import Alert from '../../../../../../../../components/ui/notifications/alert';
import AuthorizedApiClient from '../../../../../../../../core/common/api/AuthorizedApiClient';
import Log from '../../../../../../../../util/Log';
import Sections from './Sections';

export default ({ user, resetUsers }) => {
  const { t } = useTranslation(['general']);
  const [showConfirm, setShowConfirm] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);
  const [saved, setSaved] = useState(false);
  const resources = useSelector((state: any) => state.resources.data);
  if (!user.permissions) {
    user.permissions = {};
  }
  const handleDeleteClick = () => {
    setShowConfirm(true);
  };

  const company = useSelector((state: any) => state.session.company);

  const deleteUser = useCallback(async () => {
    if (company && user) {
      const sg = new AuthorizedApiClient();
      const path = `companies/${company.id}/users/${user.id}`;
      setDeleting(true);
      setError(null);
      try {
        const response = await sg.delete(path);

        if (response != null) {
          setDeleting(false);
          resetUsers();
        }
      } catch (err) {
        setError(err.response.data);
        setDeleting(false);
      }
    }
  }, [company, user, resetUsers]);

  const ss = {};
  for (const r of resources) {
    const { section } = r;
    if (ss[section] === undefined) {
      ss[section] = [];
    }

    const subsection = r.subsection ? r.subsection : section;

    if (ss[section][subsection] === undefined) {
      ss[section][subsection] = [];
    }

    ss[section][subsection].push(r);
  }

  const setPermission = (resource, ps) => {
    if (ps === null) {
      delete user.permissions[resource];
    } else {
      user.permissions[resource] = ps;
    }
  };

  const saveUser = useCallback(async () => {
    if (company) {
      const sg = new AuthorizedApiClient();
      setError(null);
      setSaved(false);
      setSaving(true);

      try {
        let response;
        let data;
        const path = `companies/${company.id}/users/${user.id}`;
        data = {
          permissions: user.permissions,
        };
        delete data.id;
        response = await sg.put(data, path);

        if (response != null) {
          setSaving(false);
          setSaved(true);
          Log.debug('saved user', response);
        }
      } catch (error) {
        Log.error(error.message);
        setSaving(false);
        setSaved(false);
        setError(error.response.data);
      }
    }
  }, [company, user]);

  const handleSaveClick = () => {
    saveUser();
  };

  return (
    <Box>
      <ConfirmDialog
        open={showConfirm}
        handleCancel={() => {
          setShowConfirm(false);
        }}
        handleConfirm={() => {
          setShowConfirm(false);
          deleteUser();
        }}
        title={t('general:settings:delete_user')}
        content={t('general:settings:delete_user_confirmation_msg')}
      />
      <Box p={1}>
        {error && (
          <Box width={1}>
            <Alert
              severity="error"
              message={t(`general:settings:${error}`, error)}
            />
          </Box>
        )}

        {saved && (
          <Box width={1}>
            <Alert
              severity="success"
              message={t('general:settings:saved_user')}
            />
          </Box>
        )}

        <Sections user={user} sections={ss} setPermission={setPermission} />
      </Box>

      <Box display="flex" mt={2} justifyContent="flex-end">
        <DangerButton
          text={t('general:settings:delete_user')}
          onClick={handleDeleteClick}
          processing={deleting ? 1 : 0}
        />
        <SuccessButton
          text={t('general:save')}
          onClick={handleSaveClick}
          processing={saving ? 1 : 0}
        />
      </Box>
    </Box>
  );
};
