/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/quotes */
import { CircularProgress } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '../../../../../components/ui/Layout/Box';
import Grid from '../../../../../components/ui/Layout/Grid';
import OpinionItem from '../../../../../components/ui/OpinionItem';
import { CommentInterface, CommentResponse } from '../../../../../helpers/interfaces/comment';
import PageContainer from '../../../../../hoc/PageContainer';
import useRequest from '../../../../../hooks/useRequest';
import { useSession } from '../../../../../util/session';

export default () => {
  const { company } = useSession();
  const { t } = useTranslation();
  const { get, processing, response } = useRequest<CommentResponse>({});

  const getComments = useCallback(async () => {
    const params = {
      sort: {
        updated_at: "desc",
      },
      embed: "user,replies",
    };
    await get(`/companies/${company.id}/comments`, params);
  }, [get, company]);

  useEffect(() => {
    getComments();
  }, [getComments]);

  return (
    <Grid container>
      <PageContainer>
        <Box mt={3}>
          {processing ? (
            <Box width="100%" justifyContent="center" display="flex">
              <CircularProgress />
            </Box>
          ) : response && !!response.data && response.data.data.length > 0 ? (
            response.data.data.map((comment: CommentInterface) => (
              <OpinionItem
                key={comment.id}
                {...comment}
                company={company}
                getComments={getComments}
              />
            ))
          ) : (
            t("general:reviewClients:no_comments")
          )}
        </Box>
      </PageContainer>
    </Grid>
  );
};
