/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';

const styles = makeStyles(() => ({
  tag: {
    padding: '3px 8px',
    margin: 5,
    borderRadius: 12,
  },
}));

const OrderStatus = ({ status }) => {
  const classes = styles();
  const { t } = useTranslation();

  const ordersStates = {
    complete: '#BBEDCA',
    shipped: '#E7F4EA',
    sent: '#EDF3FC',
    preparing: '#EDFCFA',
    accepted: '#F4E8FC',
    rejected: '#E0E0E0',
    pending: '#FFF5E3',
    cancelled: '#FFC0BA',
    returned: 'FFE7E5',
  };

  return (
    <b className={classes.tag} style={{ backgroundColor: ordersStates[status] }}>{ t(`general:orders:${status}`) }</b>
  );
};

export default OrderStatus;
