import React from 'react';
import { useTranslation } from 'react-i18next';
import BreadCrumbs from '../../../../../components/ui/BreadCrumbs';
import ContentHeader from '../../../../../components/ui/ContentHeader';
import Grid from '../../../../../components/ui/Layout/Grid';
import PageContainer from '../../../../../hoc/PageContainer';
import SectionListContainer from '../../../../../hoc/SectionListContainer';
import RecountMaker from './RecountMaker';

interface RecountProps {
  type: number;
}
export default ({ type }: RecountProps) => {
  const { t } = useTranslation(['general', 'recounts']);
  return (
    <Grid container>
      <Grid item xs={12}>
        <ContentHeader
          title={t('general:inventory')}
          section="inventory"
          back
        />
      </Grid>

      <PageContainer>
        <BreadCrumbs />

        <SectionListContainer><RecountMaker type={type} /></SectionListContainer>
      </PageContainer>
    </Grid>
  );
};
