/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
import { Switch } from '@mui/material';
import { styled } from '@mui/styles';
import React from 'react';

const ColorSwitch = styled(Switch)(({ theme, activeHtmlColor }: any) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: activeHtmlColor,
    '&:hover': {
      backgroundColor: `${activeHtmlColor}40`,
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: activeHtmlColor,
  },
}));

export const Switcher = ({
  activeHtmlColor, checked, onChange, ...props
}) => (
  <ColorSwitch
    activeHtmlColor={activeHtmlColor}
    checked={checked}
    onChange={onChange}
    {...props}
  />
);
