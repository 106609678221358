/* eslint-disable react/prop-types */
import { Button } from '@mui/material';
import React from 'react';
import { makeStyles } from '@mui/styles';
import images from '../../../theme/ECTheme/assets/images';
import Text from '../../Text/Text';

const useStyles = makeStyles(theme => ({
  container: {
    boxShadow: theme.shadow.main,
    borderRadius: 5,
    margin: '20px auto 0px auto',
    width: '85%',
    padding: '1.5rem 1.5rem',
  },
  h5color: {
    color: 'black',
    marginBottom: '1rem',
  },
  botonConfig: {
    padding: '.5rem 3rem',
    marginTop: '.5em',
  },
  alignRight: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  leftImg: {
    display: 'flex',
  },
  imgSpace: {
    paddingRight: '2rem',
  },
}));

export const WithdrawalsCard = ({ onClick }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Text variant="h5" className={classes.h5color}>
        {' '}
        <b> Cuenta para retiro de dinero</b>
      </Text>

      <div className={classes.leftImg}>
        <img className={classes.imgSpace} src={images.Icon_Withdrawals} alt="" />
        <Text>
          Recibe todos los pagos de tus ventas en tu tienda online y la app de
          {' '}
          <b>Ecubi Market</b>
          {' '}
        </Text>
      </div>

      <div className={classes.alignRight}>
        <Button onClick={onClick} className={classes.botonConfig} variant="contained" color="primary">Configurar</Button>
      </div>
    </div>
  );
};
