/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react';

import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Box from '../ui/Layout/Box';
import Grid from '../ui/Layout/Grid';
import Modal from '../ui/Modals/Modal';
import { rules as validationRules } from '../../util/Validations';
import Actions from './Actions';
import Select, { SelectOption } from '../ui/FormInput/Select';
import FormInput from '../ui/FormInput';
import ControlledAutoComplete from '../ui/FormInput/ControlledAutoComplete';
import Alert from '../ui/notifications/alert';
import ProductSelect from '../inputs/ProductSelect';

export default ({
  open,
  handleClose,
  processing,
  locationsOptions,
  getLocations,
  loadingLocations,
  products,
  productsOptions,
  getProducts,
  loadingProducts,
  attemptSaveMovement,
  error,
  product,
}) => {
  const { t } = useTranslation(['movements', 'create_a_movement']);

  const typeOptions = [
    { value: '1', label: t('movements:income') } as SelectOption,
    { value: '2', label: t('movements:outcome') } as SelectOption,
  ];

  const schema = Yup.object({
    type: Yup.number()
      .required(
        t('translation:required', {
          input: t('movements:type'),
        }),
      )
      .typeError(
        t('translation:required', {
          input: t('movements:type'),
        }),
      ),

    product_id: Yup.string()
      .nullable()
      .max(validationRules.maxLength)
      .required(
        t('translation:required', {
          input: t('movements:product'),
        }),
      ),
    location_id: Yup.string()
      .nullable()
      .max(validationRules.maxLength)
      .required(
        t('translation:required', {
          input: t('movements:location'),
        }),
      ),
    quantity: Yup.number()
      .typeError(
        t('translation:required', {
          input: t('movements:quantity'),
        }),
      )
      .moreThan(0, t('movements:error_msg_quantity'))
      .required(
        t('translation:required', {
          input: t('movements:quantity'),
        }),
      ),
    comments: Yup.string().max(validationRules.maxLength),
  });
  const {
    handleSubmit, control, register, formState: { errors }, setValue,
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    data.variant_id = data.product_id;

    if (data.type === 2) {
      data.quantity *= -1;
    }
    delete data.product_id;
    attemptSaveMovement(data);
  };

  const actions = (
    <Actions
      handleClose={handleClose}
      handleSubmit={handleSubmit(onSubmit)}
      processing={processing}
    />
  );

  return (
    <Modal
      open={open}
      onClose={handleClose}
      title={t('movements:create_a_movement')}
      maxWidth="xs"
      actions={actions}
    >
      <Box p={2}>
        {error && (
          <Grid container>
            <Alert severity="error" message={t(`movements:${error}`, error)} />
          </Grid>
        )}
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <Box id="scroll-dialog-description">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Select
                  control={control}
                  defaultValue=""
                  name="type"
                  handleOnChange={(event) => {}}
                  options={typeOptions}
                  error={errors.type}
                  label={t('movements:type')}
                />
              </Grid>

              <Grid item xs={12}>
                <ProductSelect
                  onChange={(value) => { setValue('product_id', value.variantId); }}
                  getProductsParams={{
                    filters: {
                      inventoried: true,
                    },
                  }}
                />
                {/* <ControlledAutoComplete
                  getData={getProducts}
                  name="product_id"
                  customName="selectedProduct"
                  control={control}
                  error={errors.product_id}
                  options={productsOptions}
                  loading={loadingProducts}
                  label={t('movements:product')}
                /> */}
              </Grid>

              <Grid item xs={12}>
                <FormInput
                  register={register('quantity')}
                  error={errors.quantity}
                  label={t('movements:quantity')}
                  type="number"
                />
              </Grid>

              <Grid item xs={12}>
                <ControlledAutoComplete
                  getData={getLocations}
                  name="location_id"
                  customName="selectedLocation"
                  control={control}
                  error={errors.location_id}
                  options={locationsOptions}
                  loading={loadingLocations}
                  label={t('movements:location')}
                />
              </Grid>

              <Grid item xs={12}>
                <FormInput
                  register={register('comments')}
                  error={errors.comments}
                  label={t('movements:comments')}
                  type="textarea"
                />
              </Grid>
            </Grid>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};
