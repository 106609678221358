/* eslint-disable no-return-assign */
/* eslint-disable no-else-return */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import { types } from '../../actions/cart/types';
import { PAYMENT_TYPE, SALE_SOURCE, SALE_STATUS } from '../../constants/common';
import { getFormattedToday, getTicketExpirationTime } from '../../util/dates';

export const initialState = {
  items: [],
  ticket: null,
  processingTicket: false,
  ticketExpiresAt: null,
  processing: false,

  totalItems: 0,
  subtotal: 0,
  total: 0,
  tax: 0,
  branch_id: '',
  date: getFormattedToday(),
  source: SALE_SOURCE.POS,
  payment_type: PAYMENT_TYPE.CASH,
  status: SALE_STATUS.Complete,
  completed: false,
  step: 1,
  displayed: false,
  branches: [],

  temp_price: null,
  quantity: 0,
  loadingCustomers: false,
  addedCustomer: null,
  customer: null,
  customers: [],
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case types.add_item_to_cart: {
      if (state.items.some((item: any) => item.line_id === action.product.line_id)) {
        state.items.map((item) => {
          if (item.line_id === action.product.line_id) {
            item.quantity = item.quantity !== action.product.quantity
              ? action.product.quantity : item.quantity + 1;
            return item;
          }
          return item;
        });

        let _subtotal = 0;
        let _quantityAllProducts = 0;

        state.items.forEach((item: any) => {
          let _modifiersCost = 0;
          let itemSubtotal = 0;
          item.selected_modifiers.forEach((modifier:any) => {
            modifier.products.forEach((product:any) => {
              _modifiersCost += product.price;
            });
          });
          itemSubtotal = item.temp_price
            ? item.temp_price
            : _modifiersCost + item.variants[0].price;

          item.subtotal = itemSubtotal;
          _quantityAllProducts += item.quantity;
          _subtotal += itemSubtotal * item.quantity;
        });

        const new_items = state.items.filter(item => item.quantity !== 0);

        return {
          ...state,
          quantity: _quantityAllProducts,
          subtotal: _subtotal,
          items: new_items,
        };
      } else {
        let _subtotal = 0;
        let _quantityAllProducts = 0;

        const newItemsState = [
          ...state.items,
          action.product,
        ];

        newItemsState.forEach((item: any) => {
          let _modifiersCost = 0;
          let itemSubtotal = 0;
          item.selected_modifiers.forEach((modifier:any) => {
            modifier.products.forEach((product:any) => {
              _modifiersCost += product.price;
            });
          });
          itemSubtotal = item.temp_price
            ? item.temp_price
            : _modifiersCost + item.variants[0].price;
          item.subtotal = itemSubtotal;
          _quantityAllProducts += item.quantity;
          _subtotal += itemSubtotal * item.quantity;
        });

        return {
          ...state,
          quantity: _quantityAllProducts,
          subtotal: _subtotal,
          items: newItemsState,
        };
      }
    }

    case types.remove_one_in_product: {
      state.items.map((item) => {
        if (item.line_id === action.product.line_id) {
          item.quantity = item.quantity !== action.product.quantity ? 0 : item.quantity - 1;
          return item;
        }
        return item;
      });

      let _subtotal = 0;
      let _quantityAllProducts = 0;

      state.items.forEach((item: any) => {
        let _modifiersCost = 0;
        let itemSubtotal = 0;
        item.selected_modifiers.forEach((modifier:any) => {
          modifier.products.forEach((product:any) => {
            _modifiersCost += product.price;
          });
        });

        itemSubtotal = item.temp_price
          ? item.temp_price
          : _modifiersCost + item.variants[0].price;

        item.subtotal = itemSubtotal;
        _quantityAllProducts += item.quantity;
        _subtotal += itemSubtotal * item.quantity;
      });

      const new_items = state.items.filter(item => item.quantity !== 0);

      return {
        ...state,
        quantity: _quantityAllProducts,
        subtotal: _subtotal,
        items: new_items,
      };
    }

    case types.EDIT_PRICE_ITEM: {
      state.items.map((item) => {
        if (item.id === action.item_id) {
          item.temp_price = action.newPrice;
          return item;
        }
        return item;
      });

      let __subtotal = 0;
      let __quantityAllProducts = 0;

      state.items.forEach((item: any) => {
        let _modifiersCost = 0;
        let itemSubtotal = 0;
        item.selected_modifiers.forEach((modifier:any) => {
          modifier.products.forEach((product:any) => {
            _modifiersCost += product.price;
          });
        });
        itemSubtotal = item.temp_price
          ? item.temp_price
          : _modifiersCost + item.variants[0].price;

        item.subtotal = itemSubtotal;
        __quantityAllProducts += item.quantity;
        __subtotal += itemSubtotal * item.quantity;
      });

      return {
        ...state,
        quantity: __quantityAllProducts,
        subtotal: __subtotal,
      };
    }

    case types.ADD_CART_ITEM: {
      if (state.items.find(item => item.id === action.item.id)) {
        action.item.quantity += action.item.quantity;
        return {
          ...state,
          items: [...state.items],
        };
      }
      action.item.quantity = 1;
      return {
        ...state,
        items: [...state.items, action.item],
      };
    }
    case types.OVER_STOCK_TRUE: {
      action.item.stock_warning = true;
      return {
        ...state,
        items: [...state.items],
      };
    }
    case types.OVER_STOCK_FALSE: {
      action.item.stock_warning = false;
      return {
        ...state,
        items: [...state.items],
      };
    }

    case types.REMOVE_ONLY_ONE: {
      action.item.quantity -= action.item.quantity;
      if (action.item.quantity <= 0) {
        return {
          ...state,
          items: state.items.filter(i => i.id !== action.item.id),
        };
      }
      return {
        ...state,
        items: [...state.items],
      };
    }

    case types.DELETE_PRODUCT_FROM_CART: {
      state.items.map((item) => {
        if (item.id === action.item.id) {
          return (item.quantity = 0);
        }
        return item;
      });

      return {
        ...state,
        items: state.items.filter(i => i.id !== action.item.id),
      };
    }

    case types.REMOVE_CART:
      return initialState;

    case types.ADD_PAYMENT_TYPE:
      return {
        ...state,
        payment_type: action.paymentType,
      };

    case types.GET_TICKET:
      return {
        ...state,
        requestingTicket: action.payload,
      };

    case types.GET_TICKET_SUCCESS:
      return {
        ...state,
        ticket: action.response.ticket,
        error: null,
        requestingTicket: false,
        ticketExpiresAt: getTicketExpirationTime(),
      };

    case types.GET_TICKET_FAILURE:
      return {
        ...state,
        error: action.error,
        requestingTicket: false,
      };

    case types.CLEAR_SESSION:
      return initialState;

    case types.CLEAR_CART:
      return initialState;

    case types.SAVE_CART_SUCCESS:
      return {
        ...state,
        error: null,
        processing: false,
        displayed: true,
        completed: true,
      };

    case types.SAVE_CART_FAILURE:
      return {
        ...state,
        error: action.error,
        processing: false,
      };

    case types.SET_STEP:
      return {
        ...state,
        step: action.step,
      };

    case types.SAVING_CART:
      return {
        ...state,
        processing: true,
      };

    case types.SET_SELL_DRAWER:
      return {
        ...state,
        displayed: !state.displayed,
      };

    case types.REQUESTING_CUSTOMERS:
      return {
        ...state,
        loadingCustomers: true,
      };

    case types.SET_CART_CUSTOMERS_SUCCESS:
      return {
        ...state,
        customers: action.response.data,
        error: null,
        loadingCustomers: false,
      };

    case types.SET_CART_CUSTOMERS_FAILURE:
      return {
        ...state,
        error: action.error,
        loadingCustomers: false,
      };

    case types.SET_CUSTOMER:
      return {
        ...state,
        customer: action.customer,
      };

    case types.SAVE_CUSTOMER_SUCCESS: {
      const _customer = {
        id: action.response.data.customer.id,
        name: action.response.data.customer.name,
        last_name: action.response.data.customer.last_name,
      };
      return {
        ...state,
        addedCustomer: _customer,
      };
    }

    case types.CLEAR_ADDED_CUSTOMER:
      return {
        ...state,
        addedCustomer: null,
      };

    case types.CLEAR_CART_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};
