/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import {
  Box, CircularProgress, Typography,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import UpdateCardForm from '../../../../components/Cards/AddCardForm/UpdateCardForm';
import PaymentMethodItem from './PaymentMethodItem';
import AddPaymentMethodButton from './AddPaymentMethodButton';
import AddCardForm from '../../../../components/Cards/AddCardForm';
import useRequest from '../../../../hooks/useRequest';

interface PaymentMethodResponse {
  data: Array<any>;
  status: number;
  statusText: string;
}
const PAYMENT_METHODS_URL = '/user/payment_methods';

const styles = makeStyles(theme => ({
  boxContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: '1em',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr',
    },
    // minWidth: "10em",
    maxWidth: '50em',
  },
  ColorButton: {
    color: 'blue',
  },
}));

const PaymentMethodPageCopy = () => {
  const classes = styles();
  const { t } = useTranslation(['general']);
  const { processing, get, response } = useRequest<PaymentMethodResponse>({ processingInit: true });
  const [showModalCard, setShowModalCard] = useState<boolean>(false);
  const [modalUpCard, setModalUpCard] = useState<boolean>(false);
  const [dataUpCard, setDataUpCard] = useState<boolean>(false);

  const handleCloseUpdate = () => {
    setModalUpCard(false);
  };

  const handleAddClick = () => {
    setShowModalCard(true);
  };

  const handleCloseModalCard = () => {
    setShowModalCard(false);
  };

  const _getPaymentMethods = useCallback(async () => {
    await get(PAYMENT_METHODS_URL);
  }, [get]);

  const handleSavedCard = () => {
    // console.log("saved card", data);
    _getPaymentMethods();
  };

  useEffect(() => {
    _getPaymentMethods();
  }, [_getPaymentMethods]);

  // console.log('response',response)

  return (

    <Box className={classes.boxContainer}>
      <AddCardForm
        visible={showModalCard}
        handleClose={handleCloseModalCard}
        onSavedCard={handleSavedCard}
      />
      <UpdateCardForm
        onSavedCard={handleSavedCard}
        dataUpCard={dataUpCard}
        visible={modalUpCard}
        handleClose={handleCloseUpdate}
      />

      <Box display="flex" margin="1em .5em">
        <CreditCardIcon fontSize="large" htmlColor="black" />
        <Typography variant="h6">{t('general:payment_methods:payment_methods')}</Typography>
      </Box>
      {
        processing
          ? <CircularProgress />
          : (
            <Box className={classes.container}>
              {
            response.data.data.map(paymentMethod => (
              <PaymentMethodItem
                key={paymentMethod.id}
                pm={paymentMethod}
                getPaymentMethods={_getPaymentMethods}
                setModalUpCard={setModalUpCard}
                setDataUpCard={setDataUpCard}
              />
            ))
          }
              <AddPaymentMethodButton handleOnClik={handleAddClick} />
            </Box>
          )
      }
    </Box>
  );
};

export default PaymentMethodPageCopy;
