import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RegisterFormView from './RegisterFormView';
import {
  register,
  // clearRegister
} from '../../../../../actions/register';

const RegisterForm = () => {
  const dispatch = useDispatch();
  const { processing } = useSelector((state: any) => state.register);
  const { error } = useSelector((state: any) => state.register);

  const registerForm = async (data) => {
    await dispatch(register(data));
    // dispatch(clearRegister());
    // props.history.push("/");
  };
  return (
    <RegisterFormView
      onRegister={data => registerForm(data)}
      processing={processing}
      error={error}
    />
  );
};

export default RegisterForm;
