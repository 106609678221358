import React from 'react';
import useCampaginWizardState from '../hooks/useCampaginWizardState';

interface IContextProps {
  state: any;
  clearCampaignForm: Function;
  setStep: Function;
  setStepToSave: Function;
  setDates: Function;
  setSelectedProducts: Function;
  setBasicInfo: Function;
  setGoals: Function;
  saveCampaign: Function;
  setSelectedZone: Function;
  setGallery: Function;
  removeZone: Function;
}

export const CampaignWizardStateContext = React.createContext(
  {} as IContextProps,
);

interface Props {
  children: React.ReactNode;
}

export const CampaignWizardProvider = ({ children }: Props) => {
  const initialState = useCampaginWizardState();
  return (
    <CampaignWizardStateContext.Provider value={initialState}>
      {children}
    </CampaignWizardStateContext.Provider>
  );
};

export const useCampaignWizardContext = () => {
  const context = React.useContext(CampaignWizardStateContext);
  if (context === undefined) {
    throw new Error(
      'useCampaignWizardState must be used within a CampaignWizardProvider',
    );
  }
  return context;
};
