/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  TagLabel: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '2px solid #CCC',
    borderRadius: 20,
    height: 35,
    padding: '0px 5px',
    margin: '5px 10px',
    minWidth: 70,
  },
  tagText: {
    color: theme.colors.grey,
    fontSize: 18,
  },

}));
export default (props) => {
  const classes = useStyles();
  return (
    <div className={classes.TagLabel}>
      <div className={classes.tagText}>{props.label}</div>
    </div>
  );
};
