/* eslint-disable react/prop-types */
import {
  Box, Button,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import Text from '../../Text/Text';

const useStyles = makeStyles(() => ({
  planId: {
    border: ['4px', 'solid', '#8821EA'].join(' '),
    borderRadius: 5,
    backgroundColor: '#fff',
    color: '#8821EA',
    textAlign: 'center',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    letterSpacing: 2,
    padding: '.5em 1em',
  },
  changePlan: {
    color: 'blue',
    fontSize: 'small',
  },

}));

export const MemberCard = ({ planSubCode, open }) => {
  const classes = useStyles();

  return (
    <Box>
      <div>
        <Text>Información del plan</Text>
        <div className={classes.planId}>
          {planSubCode.code}
        </div>
        <Button onClick={open} className={classes.changePlan}>cambiar plan</Button>
      </div>
    </Box>

  );
};
