/* eslint-disable no-nested-ternary */
import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import AuthenticatedLayout from './theme/ECTheme/layout/AuthenticatedLayout';
import Layout from './theme/ECTheme/layout';
import { useAuthorization } from './util/authorization';
import UnauthorizedLayout from './theme/ECTheme/layout/UnauthorizedLayout';
import LoadingLayout from './theme/ECTheme/layout/LoadingLayout';

type Props = {
  section?: any;
  planAuth?: boolean;
} & RouteProps;

const PrivateRoute = ({
  section = null,
  planAuth = true,
  component: Component,
  ...rest
}: Props) => {
  const { loggedIn, company } = useSelector((state: any) => state.session);
  const { authorized } = useAuthorization(section);

  if (!loggedIn) return <Redirect to="/login" />;
  if (!authorized || !company) return <LoadingLayout />;
  if (loggedIn && !authorized) return <Layout><UnauthorizedLayout /></Layout>;
  if (loggedIn && !planAuth) return <Redirect to="/" />;
  if (!company.setup) return <AuthenticatedLayout />;

  return (
    <Route
      {...rest}
      render={routeProps => (
        <Layout>
          <Component {...routeProps} />
        </Layout>
      )}
    />
  );
};

export default PrivateRoute;
