/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import { useSession } from '../../../../../util/session';
import StoreProfilePageView from './StoreProfilePageView';
import ActivityIndicator from '../../../../../components/ui/ActivityIndicator';

export default () => {
  const { company } = useSession();

  return (
    <>
      {company ? (
        <StoreProfilePageView
          company={company}
        />
      ) : (
        <ActivityIndicator />
      )}
    </>
  );
};
