import {
  GoogleMap, Circle, Polygon, Marker,
} from '@react-google-maps/api';
import { Button, Popover } from '@mui/material';
import React, {
  memo, useCallback, useRef, useState,
} from 'react';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import DetailsIcon from '@mui/icons-material/Details';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';

interface DelimitationAreaMapProps {
  onFigureChange(figure: any): void;
  onSelectZone?(id: string): void;
  typeMode: 'edit' | 'view';
  colorEditingFigure: string;
  zones:any;
  initialFigure?: any;
  initialPoint?: any;
}

// const initialCenter = {
//   lat: 19.4321811,
//   lng: -99.132429,
// };

const styleMap = [
  {
    featureType: 'all',
    elementType: 'geometry.fill',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'administrative',
    elementType: 'all',
    stylers: [
      {
        color: '#f2f2f2',
      },
    ],
  },
  {
    featureType: 'administrative',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#686868',
      },
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'landscape',
    elementType: 'all',
    stylers: [
      {
        color: '#f2f2f2',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'all',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'all',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'all',
    stylers: [
      {
        saturation: -100,
      },
      {
        lightness: 45,
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'all',
    stylers: [
      {
        visibility: 'simplified',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.fill',
    stylers: [
      {
        lightness: '-22',
      },
      {
        visibility: 'on',
      },
      {
        color: '#b4b4b4',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.stroke',
    stylers: [
      {
        saturation: '-51',
      },
      {
        lightness: '11',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.text',
    stylers: [
      {
        saturation: '3',
      },
      {
        lightness: '-56',
      },
      {
        visibility: 'simplified',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.text.fill',
    stylers: [
      {
        lightness: '-52',
      },
      {
        color: '#9094a0',
      },
      {
        visibility: 'simplified',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.text.stroke',
    stylers: [
      {
        weight: '6.13',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.icon',
    stylers: [
      {
        weight: '1.24',
      },
      {
        saturation: '-100',
      },
      {
        lightness: '-10',
      },
      {
        gamma: '0.94',
      },
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road.highway.controlled_access',
    elementType: 'geometry.fill',
    stylers: [
      {
        visibility: 'on',
      },
      {
        color: '#b4b4b4',
      },
      {
        weight: '5.40',
      },
      {
        lightness: '7',
      },
    ],
  },
  {
    featureType: 'road.highway.controlled_access',
    elementType: 'labels.text',
    stylers: [
      {
        visibility: 'simplified',
      },
      {
        color: '#231f1f',
      },
    ],
  },
  {
    featureType: 'road.highway.controlled_access',
    elementType: 'labels.text.fill',
    stylers: [
      {
        visibility: 'simplified',
      },
      {
        color: '#595151',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'geometry',
    stylers: [
      {
        lightness: '-16',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'geometry.fill',
    stylers: [
      {
        visibility: 'on',
      },
      {
        color: '#d7d7d7',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'labels.text',
    stylers: [
      {
        color: '#282626',
      },
      {
        visibility: 'simplified',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'labels.text.fill',
    stylers: [
      {
        saturation: '-41',
      },
      {
        lightness: '-41',
      },
      {
        color: '#2a4592',
      },
      {
        visibility: 'simplified',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'labels.text.stroke',
    stylers: [
      {
        weight: '1.10',
      },
      {
        color: '#ffffff',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'geometry.fill',
    stylers: [
      {
        lightness: '-16',
      },
      {
        weight: '0.72',
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'labels.text.fill',
    stylers: [
      {
        lightness: '-37',
      },
      {
        color: '#2a4592',
      },
    ],
  },
  {
    featureType: 'transit',
    elementType: 'all',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'transit.line',
    elementType: 'geometry.fill',
    stylers: [
      {
        visibility: 'off',
      },
      {
        color: '#eeed6a',
      },
    ],
  },
  {
    featureType: 'transit.line',
    elementType: 'geometry.stroke',
    stylers: [
      {
        visibility: 'off',
      },
      {
        color: '#0a0808',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'all',
    stylers: [
      {
        color: '#b7e4f4',
      },
      {
        visibility: 'on',
      },
    ],
  },
];

const initialRadius = 1000;

const initialPath = [
  {
    lat: 19.440358717874247,
    lng: -99.13235162590333,
  },
  {
    lat: 19.426191170676173,
    lng: -99.14187328203126,
  },
  {
    lat: 19.426531921093048,
    lng: -99.11872823442383,
  },
];

const useStyles = makeStyles({
  containerStyle: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  containerMap: {
    flex: 1,
  },
  containerButtons: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    height: 'fit-content',
    padding: '10px',
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 1,
  },
});

enum Delimitations {
  CIRCLE = 'circle',
  POLYGON = 'polygon',
}

export const DelimitationAreaMap = memo(({
  onFigureChange,
  onSelectZone,
  typeMode = 'view',
  colorEditingFigure,
  zones,
  initialFigure,
  initialPoint,
}: DelimitationAreaMapProps) => {
  const classes = useStyles();
  const mapRef = useRef<google.maps.Map | null>(null);
  const polygonRef = useRef<google.maps.Polygon | null>(null);
  const circleRef = useRef<google.maps.Circle | null>(null);
  const [delimiter, setDelimiter] = useState<Delimitations>(Delimitations.CIRCLE);
  const [selectedZone, setSelectedZone] = useState<string | null>(null);
  const [vertex, setVertex] = useState({
    open: false,
    element: null,
    index: null,
    enable: false,
  });
  const initialCenter = {
    lat: initialPoint?.coordinates[1] || 19.4321811,
    lng: initialPoint?.coordinates[0] || -99.132429,
  };

  const onLoadMap = useCallback((map: google.maps.Map) => {
    mapRef.current = map;
  }, []);

  const onEditCircle = useCallback(() => {
    if (circleRef.current) {
      const nextCenter = circleRef.current.getCenter();
      const nextRadius = circleRef.current.getRadius();
      const nextData = {
        center: {
          lat: nextCenter.lat(),
          lng: nextCenter.lng(),
        },
        radius: nextRadius,
      };
      // setCircle(nextData);

      onFigureChange({ data: { ...nextData }, type: 'circle' });
    }
  }, []);

  const onLoadCircle = useCallback((circle: google.maps.Circle) => {
    if (initialFigure && initialFigure.type === 'polygon') {
      setDelimiter(Delimitations.POLYGON);
    }
    circleRef.current = circle;

    const circleBounds = circle.getBounds();
    mapRef.current.panToBounds(circleBounds, { left: 230, top: 230 });
    mapRef.current.setZoom(13.9);

    if (!initialFigure || initialFigure.type === 'circle') {
      onEditCircle();
    }
  }, [initialFigure]);

  const onUnmountCircle = useCallback(() => {
    circleRef.current = null;
  }, []);

  // polygon handler

  const onEditPolygon = useCallback(() => {
    if (polygonRef.current) {
      const nextPath = polygonRef.current
        .getPath()
        .getArray()
        .map(latLng => ({ lat: latLng.lat(), lng: latLng.lng() }));
      // setPath(nextPath);

      onFigureChange({ data: { path: nextPath }, type: 'polygon' });
    }
  }, []);

  const changeVertex = (event) => {
    if (event.vertex !== undefined && event.domEvent.target.isConnected) {
      const isEnable = polygonRef.current.getPath().getArray().length > 3;
      setVertex({
        open: true,
        element: event.domEvent.target,
        index: event.vertex,
        enable: isEnable,
      });
    }
  };

  const cleanVertextState = () => setVertex({
    open: false, element: null, index: null, enable: false,
  });

  const deleteVertex = () => {
    const polygonsPath = polygonRef.current.getPath();
    if (polygonsPath.getArray().length > 3) {
      polygonRef.current.getPath().removeAt(vertex.index);
      cleanVertextState();
      onEditPolygon();
    }
  };

  const onLoadPolygon = useCallback((polygon: google.maps.Polygon) => {
    if (initialFigure && initialFigure.type === 'circle') {
      setDelimiter(Delimitations.CIRCLE);
    }
    polygonRef.current = polygon;

    const debouncedEditPolygon = _.debounce(onEditPolygon, 500, { maxWait: 1000 });
    google.maps.event.addListener(polygonRef.current.getPath(), 'insert_at', onEditPolygon); // TODO: remove lsitener?
    google.maps.event.addListener(polygonRef.current.getPath(), 'set_at', debouncedEditPolygon); // TODO: remove lsitener?

    const bounds = new google.maps.LatLngBounds();
    polygonRef.current.getPaths().forEach((p) => {
      p.forEach((latlng) => {
        bounds.extend(latlng);
        mapRef.current.fitBounds(bounds);
      });
    });
    mapRef.current.setZoom(13.9);

    if (!initialFigure || initialFigure.type === 'polygon') {
      onEditPolygon();
    }
  }, [initialFigure]);

  const onUnmountPolygon = useCallback(() => {
    polygonRef.current = null;
  }, []);

  const fixToCenter = () => {
    if (mapRef.current) {
      mapRef.current.setZoom(14);
      mapRef.current.panTo(new google.maps.LatLng(initialCenter.lat, initialCenter.lng));
    }
  };

  const toggleDelimiter = () => {
    setDelimiter(delimiter === Delimitations.CIRCLE ? Delimitations.POLYGON : Delimitations.CIRCLE);
    if (delimiter === Delimitations.CIRCLE) {
      onEditPolygon();
    } else {
      onEditCircle();
    }
  };

  const handleClickFigure = (e, id) => {
    setSelectedZone((prev) => {
      const next = prev === id ? null : id;
      if (onSelectZone) {
        onSelectZone(next);
      }
      return next;
    });
  };

  return (
    <section className={classes.containerStyle}>
      <div className={classes.containerButtons}>
        <Button disabled={typeMode === 'view'} variant="contained" color="primary" onClick={toggleDelimiter}>
          {
            delimiter === Delimitations.CIRCLE ? <DetailsIcon /> : <RadioButtonUncheckedIcon />
          }
        </Button>
        <Button variant="contained" color="primary" onClick={fixToCenter}>
          <GpsFixedIcon />
        </Button>
      </div>
      <GoogleMap
        onLoad={onLoadMap}
        mapContainerClassName={classes.containerMap}
        center={initialCenter}
        zoom={13}
        options={{
          clickableIcons: false,
          disableDefaultUI: true,
          styles: styleMap,
          zoomControl: true,
        }}
      >
        <Popover
          id="simple-popover"
          open={vertex.open}
          anchorEl={vertex.element}
          onClose={cleanVertextState}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <p>Remove</p>
          <Button
            disabled={!vertex.enable}
            onClick={deleteVertex}
          >
            <HighlightOffIcon htmlColor={!vertex.enable ? 'gray' : 'red'} />
          </Button>
        </Popover>
        {(typeMode === 'edit') && (
          <>
            <Circle
              radius={initialFigure?.type === 'circle' ? initialFigure.radius : initialRadius}
              center={initialFigure?.type === 'circle' ? initialFigure.center : initialCenter}
              onLoad={onLoadCircle}
              onUnmount={onUnmountCircle}
              options={{
                strokeColor: colorEditingFigure,
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: colorEditingFigure,
                fillOpacity: 0.35,
                clickable: true,
                draggable: true,
                editable: true,
                visible: delimiter === Delimitations.CIRCLE,
                zIndex: 1,
              }}
              onDragEnd={onEditCircle}
              onRadiusChanged={onEditCircle}
            />
            <Polygon
              paths={initialFigure?.type === 'polygon' ? initialFigure.path : initialPath}
              options={{
                fillColor: colorEditingFigure,
                fillOpacity: 0.4,
                strokeColor: colorEditingFigure,
                strokeOpacity: 1,
                strokeWeight: 2,
                draggable: true,
                editable: true,
                geodesic: true,
                clickable: true,
                visible: delimiter === Delimitations.POLYGON,
                zIndex: 1,
              }}
              onLoad={onLoadPolygon}
              onUnmount={onUnmountPolygon}
              onDragEnd={onEditPolygon}
              onMouseUp={changeVertex}
            />
          </>
        )}
        {
          typeMode === 'view' && zones.map((zone) => {
            const thereIsSelected = selectedZone !== null;
            const isSelected = zone.id === selectedZone;
            const strokeOpacity = !thereIsSelected || isSelected ? 0.8 : 0.18;
            const fillOpacity = !thereIsSelected || isSelected ? 0.25 : 0.12;
            const color = !thereIsSelected || isSelected ? zone.color : '#232327';

            if (zone.type === 'sphere') {
              const { sphere } = zone;
              return (
                <Circle
                  key={zone.id}
                  radius={sphere.radius}
                  center={{
                    lat: sphere.location.coordinates[1],
                    lng: sphere.location.coordinates[0],
                  }}
                  options={{
                    strokeColor: color,
                    strokeOpacity,
                    strokeWeight: 2,
                    fillColor: color,
                    fillOpacity,
                    zIndex: isSelected ? 111 : 0,
                    visible: true,
                  }}
                  onClick={e => handleClickFigure(e, zone.id)}
                />
              );
            }
            const { polygon } = zone;
            const polygonPath = polygon.coordinates[0].map(coordinate => ({
              lat: coordinate[1],
              lng: coordinate[0],
            }));
            polygonPath.pop();
            return (
              <Polygon
                key={zone.id}
                paths={polygonPath}
                options={{
                  strokeColor: color,
                  strokeOpacity,
                  strokeWeight: 2,
                  fillColor: color,
                  fillOpacity,
                  zIndex: isSelected ? 111 : 0,
                  visible: true,
                }}
                onClick={e => handleClickFigure(e, zone.id)}
              />
            );
          })
        }
        <Marker
          position={initialCenter}
        />
      </GoogleMap>
    </section>
  );
});
