/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LocationFormView from './LocationFormView';

import { getLocations, resetLocations } from '../../../../../actions/locations';
import {
  saveLocation,
  deleteLocation,
  closeModalLocation,
  getBranchesForLocation,
} from '../../../../../actions/location';
import { SCROLL_PER_PAGE } from '../../../../../constants/common';
import { types } from '../../../../../actions/location/types';
import { SelectOption } from '../../../../../components/ui/FormInput/Select';

export default () => {
  const dispatch = useDispatch();
  const { company } = useSelector((state: any) => state.session);
  const {
    location,
    error,
    processing,
    refresh,
    deletingLocation,
    branches,
  } = useSelector((state: any) => state.location);
  const [branchesOptions, setBranchesOptions] = useState<SelectOption[]>([]);

  const _getLocations = useCallback(() => {
    dispatch(resetLocations());
    dispatch(getLocations(company.id, { page: 1, per_page: SCROLL_PER_PAGE }));
    dispatch({
      type: types.CLEAR_REFRESH,
    });
  }, [dispatch, company]);

  const _getBranches = useCallback(() => {
    dispatch(getBranchesForLocation(company.id, { page: 1, per_page: 1000 }));
  }, [dispatch, company]);

  const attemptSaveBrand = async (data) => {
    await dispatch(saveLocation(data, company.id));
  };

  const attemptDeleteLocation = async (location) => {
    await dispatch(deleteLocation(location, company.id));
  };

  useEffect(() => {
    if (refresh) {
      _getLocations();
    }
  }, [refresh, _getLocations]);

  useEffect(() => {
    if (company) _getBranches();
  }, [company, _getBranches]);

  useEffect(() => {
    const _branches = branches.map(b => ({
      value: b.id,
      label: b.name,
    } as SelectOption));

    setBranchesOptions(_branches);
  }, [branches]);

  return (
    <LocationFormView
      location={location}
      attemptSaveLocation={data => attemptSaveBrand(data)}
      attemptDeleteLocation={attemptDeleteLocation}
      processing={processing}
      deletingLocation={deletingLocation}
      error={error}
      closeModalLocation={closeModalLocation}
      branchesOptions={branchesOptions}
    />
  );
};
