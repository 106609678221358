import React from 'react';
import useSalesPerMonthState from '../hooks/useSalesPerMonthState';

interface IContextProps {
  state: any;
  getSales: Function;
  setParams: Function;
  resetFilters: Function;
}

export const SalesPerMonthContext = React.createContext({} as IContextProps);

interface Props {
  children: React.ReactNode;
}

export const SalesPerMonthProvider = ({ children }: Props) => {
  const initialState = useSalesPerMonthState();
  return (
    <SalesPerMonthContext.Provider value={initialState}>
      {children}
    </SalesPerMonthContext.Provider>
  );
};

export const useSalesPerMonthContext = () => {
  const context = React.useContext(SalesPerMonthContext);
  if (context === undefined) {
    throw new Error(
      'SalesPerMonthState must be used within a SalesPerMonthProvider',
    );
  }
  return context;
};
