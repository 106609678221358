import React from 'react';
import Grid from '../Layout/Grid';
import SectionLink from '../SectionLink';

interface ColumnProps {
  card: any;
  xs?: number;
  sm?: number;
}
export default ({ card, xs = 4, sm = 3 }: ColumnProps) => (
  <Grid item xs={xs} sm={sm}>
    <SectionLink {...card} />
  </Grid>
);
