/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { AxiosResponse } from 'axios';
import { useCallback, useState } from 'react';
import ApiClient from '../core/common/api/ApiClient';
import AuthorizedApiClient from '../core/common/api/AuthorizedApiClient';
import PublicApiClient from '../core/common/api/PublicApiClient';

interface UseRequestExport<T = any> {
  response: T;
  processing: boolean;
  error: Error | any;
  post: Function;
  get: Function;
  put: Function;
  destroy: Function;
  resetStates: Function;
}

interface UseRequestProps {
  auth?: boolean;
  processingInit?: boolean;
  apiVerison?: 'v1' | 'v1.1'
}

const useRequest = <T extends any>(
  { auth = true, processingInit = false, apiVerison }: UseRequestProps,
): UseRequestExport<AxiosResponse<T>> => {
  const [response, setResponse] = useState<AxiosResponse<T>>(undefined);
  const [processing, setProcessing] = useState<boolean>(() => processingInit);
  const [error, setError] = useState<Error | any>('');

  const getApiClientInstance = useCallback(() => {
    let apiClient: any;

    if (auth) {
      apiClient = new AuthorizedApiClient({
        apiVerison: apiVerison || 'v1',
      });
    } else {
      apiClient = new PublicApiClient();
    }

    return apiClient;
  }, [auth]);

  const get = useCallback(
    async (url: string, params = {}): Promise<void> => {
      try {
        setProcessing(true);
        const apiClient: ApiClient = getApiClientInstance();
        const axiosResponse: AxiosResponse<T> = await apiClient.get(params, url);
        setResponse(axiosResponse);
      } catch (err) {
        setError(err.response);
      } finally {
        setProcessing(false);
      }
    },
    [getApiClientInstance],
  );

  const post = useCallback(
    async (url: string, data: T, file: HTMLInputElement = null): Promise<any> => {
      setProcessing(true);
      setError(null);
      const apiClient: ApiClient = getApiClientInstance();
      try {
        const apiResponse = await apiClient.post(data, url);
        setResponse(apiResponse);
        return apiResponse;
      } catch (err) {
        setError(err.response || err);
      } finally {
        setProcessing(false);
      }
    },
    [getApiClientInstance],
  );

  const put = useCallback(
    async (url: string, data: T, file: HTMLInputElement = null): Promise<void> => {
      setProcessing(true);
      setError(null);
      const apiClient: ApiClient = getApiClientInstance();
      try {
        const apiResponse = await apiClient.put(data, url);
        setResponse(apiResponse);
      } catch (err) {
        setError(err.response);
      } finally {
        setProcessing(false);
      }
    },
    [getApiClientInstance],
  );

  const destroy = useCallback(
    async (url:string, file: HTMLInputElement = null) => {
      setProcessing(true);
      setError(null);
      const apiClient: ApiClient = getApiClientInstance();
      try {
        const apiResponse = await apiClient.delete(url);
        setResponse(apiResponse);

        return apiResponse;
      } catch (err) {
        setError(err.response);
      } finally {
        setProcessing(false);
      }
    },
    [getApiClientInstance],
  );

  const resetStates = () => {
    setResponse(null);
    setError(null);
  };

  return {
    response,
    processing,
    error,
    post,
    get,
    put,
    destroy,
    resetStates,
  };
};

export default useRequest;
