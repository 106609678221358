/* eslint-disable prefer-const */
import { types } from './types';
import Log from '../../util/Log';
import AuthorizedApiClient from '../../core/common/api/AuthorizedApiClient';

export const editLocation = (location = null) => ({
  type: types.EDIT_LOCATION,
  payload: location,
});

export const savingLocation = () => ({
  type: types.SAVING_LOCATION,
});

export const saveLocationSucceed = response => ({
  type: types.SAVE_LOCATION_SUCCESS,
  response,
});

export const saveLocationFailed = error => ({
  type: types.SAVE_LOCATION_FAILURE,
  error,
});

export const deletingLocation = () => ({
  type: types.DELETING_LOCATION,
});

export const deleteLocationSucceed = response => ({
  type: types.DELETE_LOCATION_SUCCESS,
  response,
});

export const deleteLocationFailed = error => ({
  type: types.DELETE_LOCATION_FAILURE,
  error,
});

export const closeModalLocation = () => ({
  type: types.CLOSE_LOCATION_MODAL,
});

export const saveLocation = (_data, companyId) => async (dispatch: any) => {
  dispatch(savingLocation());
  const sg = new AuthorizedApiClient();
  let path = `companies/${companyId}/locations`;

  try {
    let response;
    let data;
    const { id } = _data;

    data = _data;

    if (id != null) {
      path = `${path}/${id}`;
      delete data.id;
      response = await sg.put(data, path);
    } else {
      delete data.id;
      response = await sg.post(data, path);
    }

    if (response != null) {
      Log.debug('saved location', response);
      dispatch(saveLocationSucceed(response));
      dispatch(closeModalLocation());
    }
  } catch (error) {
    Log.error(error.message);
    dispatch(saveLocationFailed(error.message));
  }
};

export const deleteLocation = (location, companyId) => async (
  dispatch: any,
) => {
  dispatch(deletingLocation());
  const sg = new AuthorizedApiClient();
  let path = `companies/${companyId}/locations`;
  try {
    if (!location.id) {
      throw new Error('The id is required');
    }

    path = `${path}/${location.id}`;
    const response = await sg.delete(path);

    if (response != null) {
      Log.debug('deleted location', response);
      dispatch(deleteLocationSucceed(response));
      dispatch(closeModalLocation());
    }
  } catch (error) {
    Log.error(error.message);
    dispatch(deleteLocationFailed(error.message));
  }
};

export const getBranchesForLocation = (companyId, params = {}) => async (
  dispatch: any,
) => {
  const sg = new AuthorizedApiClient();
  const path = `companies/${companyId}/branches`;

  try {
    const response = await sg.get(params, path);

    if (response != null) {
      dispatch({
        type: types.SET_BRANCHES_LOCATION_SUCCESS,
        response: response.data,
      });
    }
  } catch (error) {
    dispatch({ type: types.SET_BRANCHES_LOCATION_FAILURE, error });
  }
};
