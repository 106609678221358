/* eslint-disable react/destructuring-assignment */
import {
  Box,
  CircularProgress,
  CircularProgressProps,
  Typography,
} from '@mui/material';
import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  success: {
    color: 'green',
  },
});

const CompletedPercentage = (
  props: CircularProgressProps & { value: number, minPercentage: number },
) => {
  const classes = useStyles();
  const isSuccessfull = props.value >= props.minPercentage;
  const { minPercentage, ...rest } = props;
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" {...rest} className={isSuccessfull ? classes.success : ''} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="caption"
          component="div"
          color="textSecondary"
        >
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
};

export default CompletedPercentage;
