import React, { createContext } from 'react';
import Pusher from 'pusher-js';
import { PUSHER_KEY } from '../constants/api/ApiConstants';

const socket = new Pusher(PUSHER_KEY, {
  cluster: 'us2',
  forceTLS: true,
});

const SocketValue = { socket };

export const SocketContext = createContext({ socket });

interface Props {
  children: React.ReactNode;
}

const SocketsProvider = ({ children }: Props) => (
  <SocketContext.Provider value={SocketValue}>
    {children}
  </SocketContext.Provider>
);

export default SocketsProvider;
