import { types } from '../../actions/product/types';

export const initialState = {
  addedCategory: null,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case types.SAVE_CATEGORY_SUCCESS: {
      const category = {
        id: action.response.data.category.id,
        name: action.response.data.category.name,
        description: action.response.data.category.description,
      };
      return {
        ...state,
        addedCategory: category,
      };
    }

    case types.CLEAR_ADDED_CATEGORY:
      return {
        ...state,
        addedCategory: null,
      };

    default:
      return state;
  }
};
