import {
  Box, TextField,
} from '@mui/material';
import React, { ChangeEvent } from 'react';
import { withStyles } from '@mui/styles';

const CTextField = withStyles(() => ({
  root: {
    '& .MuiOutlinedInput-input:-webkit-inner-spin-button': {
      webkitAppearance: 'none',
      mozAppearance: 'textfield',
    },
    '& .MuiOutlinedInput-input:-webkit-outer-spin-button': {
      webkitAppearance: 'none',
      mozAppearance: 'textfield',
    },
  },
}))(TextField);

const CardMonthInput = ({
  label,
  register,
  error,
  defaultValue = '',
  onChange,
  ...rest
}:any) => (
  <Box width="100%">
    <CTextField
      {...rest}
      {...register}
      label={label}
      InputLabelProps={{
        shrink: true,
      }}
      onInput={(e: ChangeEvent<HTMLInputElement>) => {
        e.target.value = e.target.value.replace(/[^0-9]/g, '');
      }}
      inputProps={{ maxLength: 2 }}
      defaultValue={defaultValue}
      error={!!error}
      helperText={error !== undefined ? error.message : null}
      onChange={onChange}
    />
  </Box>
);

export default CardMonthInput;
