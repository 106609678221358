/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/prop-types */
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
} from '@mui/material';
import React from 'react';
import { makeStyles } from '@mui/styles';
import Box from '../ui/Layout/Box';

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: 500,
    height: 'auto',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #878787',
    borderRadius: 5,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  centeredItems: {
    margin: 20,
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  root: {
    width: '100%',
    display: 'felx',
    flexDirection: 'column',
  },
  containerCard: {
    display: 'flex',
    marginTop: 25,
    marginBottom: 25,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
}));

export const ModalCanBill = ({
  open, setOpen, company, onSubmit, value, onChange,
}) => {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);

  function getModalStyle() {
    return {
      top: `${50}%`,
      left: `${50}%`,
      transform: `translate(-${50}%, -${50}%)`,
    };
  }

  const handleClose = () => {
    setOpen(false);
  };

  const body = (
    <div>
      <FormControl variant="standard" fullWidth className={classes.formControl}>
        <InputLabel id="demo-simple-select-label">¿Tu comercio puede emitir facturas?</InputLabel>
        <Select
          variant="standard"
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value}
          onChange={onChange}
        >
          <MenuItem value="true">Sí</MenuItem>
          <MenuItem value="false">No</MenuItem>
        </Select>
      </FormControl>
    </div>
  );

  return (
    <Modal
      open={open}
      onClose={handleClose}
      disableEnforceFocus
      disableAutoFocus
    >
      <Box style={modalStyle} className={classes.paper}>
        <form
          onSubmit={onSubmit}
        >
          {body}
          <Box fullWidth display="flex" justifyContent="flex-end" padding="5px">
            <Button
              type="submit"
              variant="contained"
              color="primary"
            >
              Guardar
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};
