import { types } from '../../actions/count/types';

export const initialState = {
  filters: {
    branch_id: '',
    location_id: '',
    name: '',
  },
  selectedLocation: null,
  showAllLocations: false,
  processing: false,
  loadingProducts: false,

  products: {
    response: {
      data: [], total: 0, per_page: 0, last_page: 0, page: 0,
    },
    data: [],
  },
  loadingLocations: false,
  branchLocations: [],
  completed: false,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case types.SET_COUNT_BRANCH:
      return {
        ...state,
        filters: { ...state.filters, branch_id: action.branch_id },
      };
    case types.SET_COUNT_LOCATION:
      return {
        ...state,
        filters: {
          ...state.filters,
          location_id: action.location ? action.location.value : '',
        },
        selectedLocation: action.location,
      };

    case types.SET_COUNT_PRODUCTS_SEARCH:
      return {
        ...state,
        filters: { ...state.filters, name: action.search },
      };
    case types.SET_SHOW_ALL_LOCATIONS:
      return {
        ...state,
        showAllLocations: action.showAllLocations,
      };

    case types.SET_COUNT_PRODUCTS_PROCESSING:
      return {
        ...state,
        loadingProducts: action.payload,
      };

    case types.SET_COUNT_PRODUCTS_SUCCESS:
      return {
        ...state,
        loadingProducts: false,
        products: {
          response: action.response,
          data: [...state.products.data, ...action.response.data],
        },
      };
    case types.SET_COUNT_PRODUCTS_FAILURE:
      return {
        ...state,
        loadingProducts: false,
        error: action.error,
      };

    case types.SET_COUNT_LOCATIONS_PROCESSING:
      return {
        ...state,
        loadindLocations: true,
      };

    case types.SET_COUNT_LOCATIONS_SUCCESS:
      return {
        ...state,
        loadingLocations: false,
        branchLocations: action.response.data,
      };
    case types.SET_COUNT_LOCATIONS_FAILURE:
      return {
        ...state,
        loadingLocations: false,
        error: action.error,
      };

    case types.CLEAR_COUNT:
      return {
        ...initialState,
        filters: {
          ...initialState.filters,
          branch_id: state.filters.branch_id,
        },
        branchLocations: state.branchLocations,
      };

    case types.CLEAR_COUNT_LOCATIONS:
      return {
        ...state,
        branchLocations: initialState.branchLocations,
      };

    case types.RESET_COUNT_PRODUCTS:
      return {
        ...state,
        loadingProducts: false,
        products: initialState.products,
      };

    case types.SET_COUNT_MOVEMENTS_SUCCESS:
      return {
        ...state,
        completed: true,
      };

    default:
      return state;
  }
};
