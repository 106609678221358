import React from 'react';
import {
  Cell, Pie, PieChart, ResponsiveContainer, Tooltip,
} from 'recharts';

interface Props {
  data: any[],
  dataKey: string;
}

export const CustomPieChart = ({ data, dataKey }: Props) => (
  <ResponsiveContainer width="100%" height={240}>
    <PieChart width={240} height={220}>
      <Pie
        dataKey={dataKey}
        data={data}
        cx="50%"
        cy="50%"
        outerRadius={80}
        isAnimationActive
        label
      >
        {data?.map(entry => <Cell key={`cell-${entry.name}`} fill={entry.color || '#ccc'} />)}
      </Pie>
      <Tooltip />
    </PieChart>
  </ResponsiveContainer>
);
