import React, { useMemo } from 'react';
import { makeStyles } from '@mui/styles';
import images from '../../assets/images';
import Box from '../../../../components/ui/Layout/Box';

const useStyle = makeStyles(() => ({
  perfileImg: {
    width: 50,
    height: 50,
    borderRadius: '50%',
    border: '1px solid #7A4CE2',
    cursor: 'pointer',

    '&:hover': {
      opacity: 0.5,
    },
  },
}));

interface ButtonProps {
  user: any;
  width?: number;
  height?: number;
  click?: any;
}

const NavBar = ({
  user, width, height, click,
}: ButtonProps) => {
  const classes = useStyle();

  return (
    <Box className={classes.perfileImg} style={{ width, height }} onClick={click}>
      {useMemo(() => {
        const imageUrl = user && user.image_url
          ? `${user.image_url}?v=${Math.random()}`
          : images.DefaultPerfile;
        return (
          <span
            style={{
              backgroundImage: `url(${imageUrl})`,
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              backgroundSize: 'cover',
              display: 'inline-block',
            }}

          />
        );
      }, [user])}
    </Box>
  );
};

export default NavBar;
