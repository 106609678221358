/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import { editCustomer } from '../../../../../actions/customer';
import Box from '../../../../../components/ui/Layout/Box';
import AsyncPaginatedTable from '../../../../../components/ui/AsyncPaginatedTable';
import Grid from '../../../../../components/ui/Layout/Grid';

import { useSession } from '../../../../../util/session';
import CustomerSelector from '../../../../../components/ui/CustomerSelector';

interface CustomersListProps {
  state: any;
  getCustomers: any;
  resetCustomers: any;
  downloadCustomers
}

export default ({
  state,
  getCustomers,
  resetCustomers,
  downloadCustomers,
}: CustomersListProps) => {
  const { t } = useTranslation(['customers']);

  const { company } = useSession();

  const dispatch = useDispatch();
  const _editCustomer = (customer) => {
    const {
      created_at, updated_at, version, ...data
    } = customer;
    dispatch(editCustomer(data));
  };

  const columns = [
    {
      name: 'name',
      label: t('customers:name'),
      options: {
        filter: true,
        filterType: 'custom',
        filterOptions: {
          display: (filterList, onChange, index, column, filterData) => (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CustomerSelector
                  company_id={company ? company.id : ''}
                  setCustomer={(customer: { value: any; }) => {
                    if (customer) {
                      filterList[index][0] = customer ? customer.value : '';
                      onChange(filterList[index], index, column, filterData);
                    }
                  }}
                />
              </Grid>
            </Grid>
          ),
        },
        sort: false,
      },
    },
    {
      name: 'cellphone',
      label: t('customers:cellphone'),
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'email',
      label: t('customers:email'),
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'billing_data',
      label: t('customers:billing_data'),
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'comments',
      label: t('customers:comments'),
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'edit',
      label: 'Editar',
      options: {
        filter: false,
        sort: false,
      },
    },
  ];

  const customerData = state.data.map((customer) => {
    const hasBillingInfo = !!(customer.rfc && customer.business_name);
    const billingCheck = hasBillingInfo ? <CheckIcon style={{ color: 'green' }} /> : null;
    const customerEdit = <EditIcon style={{ cursor: 'pointer' }} onClick={() => _editCustomer(customer)} />;
    return {
      key: customer.id,
      name: `${customer.name}${' '}${customer.last_name}`,
      cellphone: customer.cellphone,
      email: customer.email,
      billing_data: billingCheck,
      comments: customer.comments,
      edit: customerEdit,
    };
  });

  return (

    <Box mt={1}>
      <AsyncPaginatedTable
        title="Lista de Clientes"
        hasNextPage={state.response.page < state.response.last_page}
        currentPage={state.response.page}
        perPage={state.response.per_page}
        data={customerData}
        columns={columns}
        loadData={params => getCustomers(params)}
        resetData={resetCustomers}
        processing={state.processing}
        total={state.response.total}
        options={{ filter: true, downloadExcel: downloadCustomers }}
      />
    </Box>

  );
};
