export const types = {

  // SET_BRANCHES_POS_SUCCESS: "SET_BRANCHES_POS_SUCCESS",
  // SET_BRANCHES_POS_FAILURE: "SET_BRANCHES_POS_FAILURE",
  OPEN_WARNING_MESSAGE: 'OPEN_WARNING_MESSAGE',

  SET_BRANCH: 'SET_BRANCH',
  CLEAR_SESSION: 'CLEAR_SESSION',
  SET_SALES_PROCESSING: 'SET_SALES_PROCESSING',
  SET_SALES_SUCCESS: 'SET_SALES_SUCCESS',
  SET_SALES_FAILURE: 'SET_SALES_FAILURE',
  RESET_SALES: 'RESET_SALES',
};
