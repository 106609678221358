/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import { Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { makeStyles } from '@mui/styles';
import Box from '../ui/Layout/Box';
import { setFinish, setWizardBranch } from '../../actions/wizard';
import SuccessButton from '../ui/Buttons/SuccessButton';
import RawBranchForm from '../../containers/pages/admin/BranchesPage/BranchForm/RawBranchForm';
import { rules as validationRules } from '../../util/Validations';
import AuthorizedApiClient from '../../core/common/api/AuthorizedApiClient';
import ActivityIndicator from '../ui/ActivityIndicator';
import { useSession } from '../../util/session';
import { formatBranchData } from '../../util/branches';

const useStyle = makeStyles(() => ({
  questionsContainer: {
    width: '70%',
    marginLeft: 'auto',
    marginRight: 'auto',
    minWidth: '220px',
  },
}));

const BranchSection = () => {
  const classes = useStyle();
  const { t } = useTranslation(['general']);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [branch, setBranch] = useState(null);
  const { company } = useSession();

  const { saving } = useSelector((state: any) => state.wizard);

  const _getBranch = useCallback(async (companyId) => {
    const sg = new AuthorizedApiClient();
    const path = `companies/${companyId}/branches`;
    setLoading(true);
    try {
      const params = { per_page: 1, filters: { primary: true } };
      const response = await sg.get(params, path);

      if (response != null) {
        setBranch(response.data.data[0]);
      }
    } catch (error) {
      setBranch(null);
    } finally {
      setLoading(false);
    }
  }, []);

  const schema = Yup.object({
    name: Yup.string()
      .max(validationRules.maxLength)
      .required(
        t('translation:required', {
          input: t('branches:branch_name'),
        }),
      ),
    address: Yup.string()
      .max(validationRules.maxLength)
      .required(
        t('translation:required', {
          input: t('branches:address'),
        }),
      ),

    phone: Yup.string()
      .max(validationRules.maxLength)
      .required(
        t('translation:required', {
          input: t('branches:phone'),
        }),
      ),

    email: Yup.string()
      .email(t('validation:invalid_email'))
      .max(validationRules.maxLength),

    lat: Yup.string(),
    lng: Yup.string(),
  });
  const {
    register, handleSubmit, formState: { errors }, setValue,
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const attempFinish = (branch) => {
    dispatch(setWizardBranch(branch));
    dispatch(setFinish(true));
  };

  const onSubmit = (data) => {
    data.id = branch.id ? branch.id : null;

    data = formatBranchData(data, image);

    if (data.id && image === '') {
      data.image = '';
    }

    data.file = image !== '' ? image : null;

    attempFinish(data);
  };

  useEffect(() => {
    if (company) {
      _getBranch(company.id);
    }
  }, [company, _getBranch]);

  // "branch", branch)

  return (
    <>
      {branch && !loading ? (
        <>
          <Box>
            <Typography variant="h6" align="center" color="textSecondary">
              {t('general:wizard_setup:branch_question')}
            </Typography>
          </Box>

          <Box mt={2} className={classes.questionsContainer} pt={5}>
            <RawBranchForm
              branch={branch}
              register={register}
              setImage={setImage}
              errors={errors}
              setValueForm={setValue}
            />
          </Box>

          <Box mt={2} display="flex" justifyContent="center">
            <SuccessButton
              text={t('general:finish')}
              onClick={handleSubmit(onSubmit)}
              // disabled={errors.length > 0 ? false : true}
              processing={saving ? 1 : 0}
            />
          </Box>
        </>
      ) : (
        <Box>
          <ActivityIndicator />
        </Box>
      )}
    </>
  );
};

export default BranchSection;
