import {
  CircularProgress, FormControl, MenuItem, Select, Box, InputLabel,
} from '@mui/material';
import React, { ChangeEvent, useEffect, useState } from 'react';
import useCompany from '../../../hooks/useCompany';
import useProducts, { ProductParams } from '../../../hooks/useProducts';

interface SelectValues {
  id: string;
  name: string;
  variantId: string;
}

interface Props {
  defaultValue?: string;
  onChange?: (value: SelectValues) => void;
  getProductsParams?: ProductParams;
}

const ProductSelect = ({ defaultValue, onChange, getProductsParams = {} }: Props) => {
  const { company } = useCompany();
  const [option, setOption] = useState(defaultValue || '');
  const { getProducts, loadingProducts, products } = useProducts(company.id, getProductsParams);

  useEffect(() => {
    getProducts();
  }, [getProducts]);

  const onChangeSelect = (e: ChangeEvent<HTMLInputElement>) => {
    setOption(e.target.value);
    if (onChange) onChange(JSON.parse(e.target.value));
  };

  if (loadingProducts) return <Box width={100}><CircularProgress size={16} /></Box>;

  return (
    <FormControl
      fullWidth
      variant="outlined"
    >
      <InputLabel id="product-select">Producto</InputLabel>
      <Select
        variant="standard"
        labelId="product-select"
        id="product-select-outlined"
        placeholder="Selecciona un producto"
        label="Producto"
        maxRows={5}
        value={products.data.length < 1 ? '' : option}
        onChange={onChangeSelect}
      >
        {
          products.data.map(p => (
            <MenuItem
              key={p.id}
              value={JSON.stringify({ id: p.id, variantId: p.variants[0].id, name: p.name })}
            >
              {p.name}
            </MenuItem>
          ))
        }
      </Select>
    </FormControl>
  );
};

export default ProductSelect;
