import { Box, Button } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ExcelButton from '../../../../../components/ui/Buttons/ExcelButton';
import { OrdersExcelModal } from '../components/OrdersExcelModal';
import { OrdersTable } from '../components/OrdersTable';

export const OrdersPage = () => {
  const { t } = useTranslation('general');
  const [openExcelModal, setOpenExcelModal] = useState(false);

  const handleOpenExcelModal = () => {
    setOpenExcelModal(!openExcelModal);
  };

  return (
    <>
      <OrdersExcelModal
        isOpen={openExcelModal}
        handleClose={() => setOpenExcelModal(false)}
      />
      <Box mb={2}>
        <Button variant="contained">
          Subir nuevos pedidos
        </Button>
        <ExcelButton
          text={t('general:products_excel:upload_excel')}
          onClick={handleOpenExcelModal}
        />
      </Box>
      <OrdersTable />
    </>
  );
};
