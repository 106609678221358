/* eslint-disable react/prop-types */
import React from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import Grid from '../../../../../components/ui/Layout/Grid';
import Box from '../../../../../components/ui/Layout/Box';

const styles = makeStyles(theme => ({
  container: {
    height: 80,
    cursor: 'pointer',
    border: '0.5px solid #9A9494',
    borderRadius: '3px',
    boxShadow: '0px 3px 6px #00000029',
  },

  tittle: {
    color: theme.colors.black,
    fontsize: 15,
  },

  description: {
    alignSelf: 'center',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
  },
}));

export default ({ location }) => {
  const { t } = useTranslation(['locations']);
  const classes = styles();
  return (
    <Box className={classes.container} p={2}>
      <Grid container spacing={2}>
        <Grid container item xs={5} justifyContent="center">
          <Typography className={classes.tittle}>
            {t('locations:location')}
          </Typography>
        </Grid>
        <Grid container item xs={7}>
          <Typography className={classes.tittle}>
            {t('locations:description')}
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid container item xs={5} justifyContent="center">
          <Typography className={classes.description}>
            {location.name}
          </Typography>
        </Grid>
        <Grid item xs={7}>
          <Typography className={classes.description}>
            {location.description}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
