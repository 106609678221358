import { types } from '../../actions/users/types';

export const initialState = {
  processing: true,
  data: [],
  error: null,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case types.SET_RESOURCES_PROCESSING:
      return {
        ...state,
        processing: action.payload,
      };

    case types.SET_RESOURCES_SUCCESS:
      return {
        ...state,
        data: action.response.data,
        error: null,
        processing: false,
      };
    case types.SET_RESOURCES_FAILURE:
      return {
        ...state,
        error: action.error,
        processing: false,
      };

    default:
      return state;
  }
};
