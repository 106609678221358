/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import SectionListContainer from '../../../../hoc/SectionListContainer';
import Grid from '../../../../components/ui/Layout/Grid';
import PageContainer from '../../../../hoc/PageContainer';
import Box from '../../../../components/ui/Layout/Box';
import AddButton from '../../../../components/ui/Buttons/BlueButton/AddButton';
import LocationsList from './LocationsList';
import LocationForm from './LocationForm';
import { getBranchesForLocations } from '../../../../actions/locations';
import { PERMISSION, RESOURCES } from '../../../../constants/common';
import { useSession } from '../../../../util/session';

type LocationProps = {
  openNewLocation: any;
  refresh: boolean;
};
export default ({ openNewLocation, refresh }: LocationProps) => {
  const { t } = useTranslation('general');
  const { can } = useSession();
  const { company } = useSelector((state: any) => state.session);
  const { branches } = useSelector((state: any) => state.locations);
  const dispatch = useDispatch();

  const _getBranches = useCallback(
    async (params: any = {}) => {
      if (company) {
        params.per_page = 1000; // TODO: check it.
        await dispatch(getBranchesForLocations(company.id, params));
      }
    },
    [dispatch, company],
  );
  // console.log("refresh", refresh);
  useEffect(() => {
    if (company || (company && refresh)) {
      _getBranches();
    }
  }, [company, refresh, _getBranches]);

  return (
    <>
      {(can(RESOURCES.locations, PERMISSION.create)
        || can(RESOURCES.locations, PERMISSION.create)) && <LocationForm />}

      <Grid container>
        {/* <Grid item xs={12}>
          <ContentHeader
            title={t("general:inventory")}
            section="inventory"
            back
          />
        </Grid> */}

        <PageContainer>
          {/* <BreadCrumbs /> */}
          {can(RESOURCES.locations, PERMISSION.create) && (
            <Box mt={3}>
              <AddButton onClick={openNewLocation}>
                {t('general:create_location')}
              </AddButton>
            </Box>
          )}

          <SectionListContainer>
            <LocationsList branches={branches} />
          </SectionListContainer>
        </PageContainer>
      </Grid>
    </>
  );
};
