import React from 'react';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import ListItemTitle from '../../../../../components/ui/ListItemTitle';
import CategoryImage from '../CategoryImage';
import Box from '../../../../../components/ui/Layout/Box';
import ListItemDescription from '../../../../../components/ui/ListItemDescription';
import CategoryButton from './CategoryButton';

type CategoryListItemViewProps = {
  category: any;
  showDetails?: Function;
};

const styles = makeStyles(theme => ({
  CategoryContainer: {
    position: 'relative',
    cursor: 'pointer',
    boxShadow: theme.shadow.main,
    border: '0.5px solid #9A9494',
    borderRadius: '3px',
    height: 70,
  },
  description: {
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    padding: '0px 10px',
  },
}));

export default ({ category, showDetails }: CategoryListItemViewProps) => {
  const { t } = useTranslation(['categories']);
  const classes = styles();
  return (
    <Box
      onClick={() => showDetails(category)}
      className={classes.CategoryContainer}
      p={1}
      display="flex"
      flexDirection="row"
    >
      <Box alignSelf="flex-start" width="30%">
        <ListItemTitle text={t('categories:category')} />
        <Box mt={1}>
          <CategoryButton category={category} click={() => console.log('click')} />
        </Box>
      </Box>

      <Box p={0} m={0} alignSelf="flex-center" width="60%">
        <ListItemTitle text={t('categories:description')} />
        <Box display="flex" justifyContent="center" mt={1} className={classes.description}>
          <ListItemDescription text={category.description} />
        </Box>
      </Box>
      <Box p={0} m={0} alignSelf="flex-end" width="30%" height="100%">
        <CategoryImage url={category.image_url} />
      </Box>
    </Box>
  );
};
