import { types } from '../../actions/category/types';

export const initialState = {
  category: {
    id: null, name: '', description: '', color: '', image: '',
  },
  error: null,
  showModal: false,
  processing: false,
  deletingCategory: false,
  refresh: false,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case types.EDIT_CATEGORY:
      return {
        ...state,
        showModal: true,
        category:
          action.payload !== null ? action.payload : initialState.category,
      };
    case types.CLOSE_CATEGORY_MODAL:
      return initialState;
    case types.SAVE_CATEGORY_SUCCESS:
      return {
        ...state,
        response: action.response,
        error: null,
        processing: false,
        refresh: true,
      };
    case types.SAVE_CATEGORY_FAILURE:
      return {
        ...state,
        error: action.error,
        processing: false,
      };
    case types.SAVING_CATEGORY:
      return {
        ...state,
        error: null,
        processing: true,
      };

    case types.DELETING_CATEGORY:
      return {
        ...state,
        error: null,
        deletingCategory: true,
      };

    case types.DELETE_CATEGORY_SUCCESS:
      return {
        ...state,
        error: null,
        deletingCategory: false,
        refresh: true,
      };
    case types.DELETE_CATEGORY_FAILURE:
      return {
        ...state,
        error: action.error,
        deletingCategory: false,
      };
    case types.CLEAR_REFRESH:
      return {
        ...state,
        refresh: false,
      };
    default:
      return state;
  }
};
