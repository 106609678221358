/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '../../../../../components/ui/Layout/Grid';
import ColumnCard from '../../../../../components/ui/ColumnCard';

const ProductsFinancePage = () => {
  const { t } = useTranslation(['general']);

  const _cards = [
    { name: t('general:products'), section: 'products', link: 'inventory/products' },
    { name: t('general:categories'), link: 'inventory/categories' },
    { name: t('general:brands') },
    { name: t('general:suppliers') },
    { name: t('general:unit_of_measures') },
    { name: t('general:products_reports'), topIcon: 'reports' },
  ];

  const cards = _cards.map(card => (
    <ColumnCard key={card.name} card={card} />
  ));

  return (
    <Grid container spacing={3}>
      {cards}
    </Grid>
  );
};
export default ProductsFinancePage;
