/* eslint-disable react/prop-types */
import { blue } from '@mui/material/colors';
import React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Switcher } from '../../../../../components/inputs/Switcher';
import Text from '../../../../../components/Text/Text';
import Grid from '../../../../../components/ui/Layout/Grid';
import ModalBanner from '../../../../../components/ui/ModalBanner';

const OnlineStore = ({ control, formData }) => {
  const { t } = useTranslation('general');
  return (
    <aside>
      <Grid item xs={12}>
        <ModalBanner section="store" />
      </Grid>
      <Controller
        control={control}
        name="published"
        defaultValue={formData.published}
        render={({
          field: {
            onChange, onBlur, value, ref,
          },
        }) => (
          <Grid container alignItems="center" style={{ gap: '1rem' }}>
            <Switcher
              activeHtmlColor={blue[600]}
              onBlur={onBlur}
              onChange={onChange}
              checked={value}
              inputRef={ref}
            />
            <Text>
              {t('general:publish_online')}
            </Text>
          </Grid>
        )}
      />
    </aside>
  );
};

export default OnlineStore;
