/* eslint-disable react/prop-types */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import { FormControl, FormHelperText } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AuthorizedApiClient from '../../../../../core/common/api/AuthorizedApiClient';
import { useSession } from '../../../../../util/session';

const UnitSelector = ({ register, error }) => {
  const { t } = useTranslation(['general']);
  const { company } = useSession();
  const [unit, setUnit] = useState(null);

  const _getUnits = useCallback(async (companyId) => {
    const sg = new AuthorizedApiClient();
    const path = `companies/${companyId}/unitofmeasures`;
    const params = {
      per_page: 1,
    };
    try {
      const response = await sg.get(params, path);

      return response.data.data;
    } catch (e) {
      return [];
    }
  }, []);

  const loadUnit = useCallback(
    async (companyId) => {
      const units = await _getUnits(companyId);
      //   console.log("units", units);
      if (units && units.length) {
        setUnit(units[0]);
      }
    },
    [_getUnits],
  );

  useEffect(() => {
    if (company) {
      loadUnit(company.id);
    }
  }, [company, loadUnit]);

  return (
    <>
      <input
        {...register('unitofmeasure_id')}
        type="hidden"
        value={unit ? unit.id : ''}
      />
      {error && (
      <FormControl variant="standard" error={!!error}>
        <FormHelperText>{t('general:unit_no_set')}</FormHelperText>
      </FormControl>
      )}
    </>
  );
};

export default UnitSelector;
