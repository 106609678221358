/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/button-has-type */
import { makeStyles } from '@mui/styles';
import React, { useContext } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import { green } from '@mui/material/colors';

import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import images from '../../theme/ECTheme/assets/images';
import PlanSubsContext from '../Forms/PlansForm/context/PlanSubsContext';

const useStyle = makeStyles(() => ({
  icon: {
    color: green[500],
    margin: 3,
  },
  feature: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 5,
  },
  characteristic: {
    fontWeight: 'bold',
    fontSize: 14,
    margin: 5,
    minWidth: 220,
  },
  planSelector: {
    borderStyle: 'solid',
    borderColor: 'white',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    width: 120,
    height: 350,
    alignSelf: 'flex-end',
    backgroundColor: '#FFF',
    borderRadius: 5,
    borderWidth: 5,
    margin: 5,
    '&:hover': {
      cursor: 'pointer',
      borderColor: '#D7B5FF',
    },
    '&:focus': {
      borderColor: '#D7B5FF',
    },
  },
  infoAnnualPlan: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    alignSelf: 'flex-end',
    width: 156,
    height: 350,
    margin: 5,
  },
  table: {
    minWidth: 650,
  },
  cell: {
    borderColor: '#fff',
  },
  descriptionPlan: {
    minWidth: 100,
    height: 70,
    paddingTop: 10,
    color: '#FFF',
    fontWeight: 'bold',
    fontSize: 18,
  },
}));

const MONTHS_IN_YEAR = 12;
const PRO_CODE = 'pro';
const YEAR_INTERVAL = 'yearly';

// TODO: move it to utils or helpers
const convertAmount = amount => amount / 100;

// TODO: move it to context
const getProPlan = plans => plans.find(plan => plan.code === PRO_CODE);

const getYearlyPrice = plan => (
  plan.prices.find(price => price.interval === YEAR_INTERVAL && price.default === true)
);

export const AnnualPlan = ({ planData }) => {
  const classes = useStyle();
  const { setPlanId, setPricePlan } = useContext(PlanSubsContext);

  const regularConvertedMonthlyAmount = 130;
  const regularConvertedAnnualAmount = regularConvertedMonthlyAmount * MONTHS_IN_YEAR;

  const proPlan = getProPlan(planData);

  const price = getYearlyPrice(proPlan);
  const planId = proPlan.id;

  const convertedAnnualPriceAmount = convertAmount(price.amount);

  const convertedMonthlyAmount = convertedAnnualPriceAmount / MONTHS_IN_YEAR;

  const convertedSavedAmount = regularConvertedAnnualAmount - convertedAnnualPriceAmount;

  return (
    <TableContainer>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.cell}>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <img
                  src={images.Icon_EcubiMiNegocio}
                  alt="ecubi_logo"
                  style={{ width: 75, marginBottom: 15 }}
                />
              </div>
              <div className={classes.characteristic}>
                Tienda online personalizada
              </div>
              <div className={classes.characteristic}>Comision por venta</div>
              <div className={classes.characteristic}>
                Aparecer como tienda &quot;Verificada&quot;
              </div>
              <div className={classes.characteristic}>
                Subdominio personalizado
              </div>
              <div className={classes.characteristic}>
                Agregar más de un usuario
              </div>
              <div className={classes.characteristic}>Pago con tarjeta</div>
              <div className={classes.characteristic}>Pago en efectivo</div>
            </TableCell>

            <TableCell className={classes.cell}>
              <div style={{ display: 'flex' }}>
                <div>
                  <button
                    onClick={() => {
                      // setPricePlan(planData[2].prices[2].id);
                      // setPlanId(planData[2].id);
                      setPricePlan(price.id);
                      setPlanId(planId);
                    }}
                    className={classes.planSelector}
                  >
                    <div
                      className={classes.descriptionPlan}
                      style={{ backgroundColor: '#8821EA' }}
                    >
                      <span>PRO</span>
                      <div>
                        {/* <span>$828</span> */}
                        <span>
                          $
                          {convertedAnnualPriceAmount}
                        </span>

                        <span
                          style={{
                            fontWeight: 'bold',
                            fontSize: 14,
                            margin: 5,
                          }}
                        >
                          año
                        </span>
                      </div>
                    </div>

                    <CheckIcon
                      className={classes.icon}
                      style={{ marginTop: 30 }}
                    />

                    <p style={{ fontWeight: 'bold', margin: 8 }}> 0%</p>

                    <span>
                      <CheckIcon className={classes.icon} />
                    </span>

                    <span>
                      <CheckIcon className={classes.icon} />
                    </span>

                    <span>
                      <CheckIcon className={classes.icon} />
                    </span>

                    <span>
                      <CheckIcon className={classes.icon} />
                    </span>
                  </button>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    margin: 15,
                  }}
                >
                  <div style={{ marginBottom: 15, alignItems: 'center' }}>
                    <p
                      style={{
                        fontSize: 20,
                        margin: 5,
                        textDecoration: 'line-through',
                        fontWeight: 'bold',
                      }}
                    >
                      {/* $130 mensual */}
                      $
                      {regularConvertedMonthlyAmount}
                      {' '}
                      mensual
                    </p>
                    <p
                      style={{
                        fontSize: 25,
                        margin: 5,
                        fontWeight: 'bold',
                        color: 'red',
                      }}
                    >
                      $
                      {convertedMonthlyAmount}
                      {' '}
                      mensual
                    </p>
                    <p
                      style={{
                        fontSize: 12,
                        margin: 5,
                        fontWeight: 'bold',
                        color: 'red',
                      }}
                    >
                      {/* Ahorra $852 al año */}
                      Ahorra $
                      {convertedSavedAmount}
                      {' '}
                      al año
                    </p>
                  </div>

                  <div>
                    <div style={{ marginBottom: 10 }}>
                      Otros beneficios del plan anual:
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div>
                        <img src={images.Icon_comunity} alt="" />
                      </div>
                      <div>
                        Comunidad exclusiva en FB.
                        <p>&quot;Comunidad semila&quot;</p>
                      </div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div>
                        <img src={images.Icon_tips} alt="" />
                      </div>
                      <div>Tips para mejorar y crecer tu negocio</div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div>
                        <img src={images.Icon_hands} alt="" />
                      </div>
                      <div>
                        Queremos escuchar tus solicitudes a nuevas
                        características
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TableCell>
          </TableRow>
        </TableHead>
      </Table>
    </TableContainer>
  );
};
