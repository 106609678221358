/* eslint-disable react/prop-types */
import React from 'react';
import CategoryListItem from '../CategoryListItem';
import Grid from '../../../../../components/ui/Layout/Grid';
import PaginatedScroll from '../../../../../hoc/PaginatedScroll';

export default ({ state, getCategories, resetCategories }) => {
  const categoriesList = state.data.map(category => (
    <Grid item xs={12} sm={6} key={category.id}>
      <CategoryListItem category={category} />
    </Grid>

  ));

  return (
    <PaginatedScroll
      hasNextPage={state.response.page < state.response.last_page}
      loadData={params => getCategories(params)}
      currentPage={state.response.page}
      resetData={resetCategories}
      processing={state.processing}
    >
      <Grid container spacing={3}>
        {categoriesList}
      </Grid>
    </PaginatedScroll>
  );
};
