/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import { MOVEMENT_PARENT_TYPE } from '../../../../../constants/common';

const styles = makeStyles(theme => ({
  Button: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
}));

const SaleComment = ({ number, id = null }) => {
  const { t } = useTranslation(['movements']);
  const classes = styles();
  return (
    <>
      {t('movements:sale')}
      {' '}
      <Link to={`/sales/${id}`}>
        {' '}
        #
        <span className={classes.Button}>{number}</span>
      </Link>
    </>
  );
};

const OrderComment = ({ number, id = null }) => {
  const { t } = useTranslation(['movements']);
  const classes = styles();
  return (
    <>
      {t('movements:order')}
      {' '}
      <Link to={`/orders/${id}`}>
        #
        <span className={classes.Button}>{number}</span>
      </Link>
    </>
  );
};

const CountComment = ({ number }) => {
  const { t } = useTranslation(['movements']);

  return (
    <>
      {t('movements:recount')}
      {' '}
      #
      <span>{number}</span>
    </>
  );
};

export default ({ value, row, id = null }) => {
  switch (row.parent_type) {
    case MOVEMENT_PARENT_TYPE.Sale:
      return <SaleComment number={row.parent_number} id={id} />;

    case MOVEMENT_PARENT_TYPE.Order:
      return <OrderComment number={row.parent_number} id={id} />;

    case MOVEMENT_PARENT_TYPE.Count:
      return <CountComment number={row.parent_number} />;
    default:
      return <>{value}</>;
  }
};
