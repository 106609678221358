/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Grid,
  Typography,
} from '@mui/material';
import Alert from '@mui/material/Alert';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Modal from '../../ui/Modals/Modal';
import Actions from './Actions';
import CardMonthInput from './CardMonthInput';
import CardYearInput from './CardYearInput';
import CardHolderInput from './CardHolderInput';
import useRequest from '../../../hooks/useRequest';

const useStyles = makeStyles(() => ({
  inputBoxDate: {
    width: '30px',
  },
  inputBoxCVC: {
    width: '70px',
  },
  fontSmall: {
    fontSize: 'x-small',
  },
}));

interface Props {
  visible: boolean;
  handleClose: any;
  dataUpCard: any;
  onSavedCard: any
}

const AddCardFormView = ({
  visible,
  handleClose,
  dataUpCard,
  onSavedCard,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const date = new Date();
  const fullYear = date.getFullYear();
  const currentMonth = date.getMonth() + 1;
  const currentYear = fullYear % 100;
  const [, setYear] = useState(currentYear);
  const [, setMonth] = useState(currentMonth);
  const handleYearChange = (event) => {
    setYear(event.target.value);
  };

  const handleMonthChange = (event) => {
    setMonth(event.target.value);
  };

  const schema = Yup.object({
    card_holder: Yup.string().required(
      t('general:payment_methods:card_holder_required'),
    ),
    card_month: Yup.string()
      .required('')
      .test('card_month', '', () => {}),
    card_year: Yup.string()
      .required('')
      .test('card_year', '', () => {}),
  });

  const { register, handleSubmit, formState: { errors } } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const { put, processing, error } = useRequest({});

  const onSubmit = async (data) => {
    try {
      const paymentData = {
        name: data.card_holder,
        exp_month: data.card_month,
        exp_year: data.card_year,
      };
      await put(`/user/payment_methods/${dataUpCard.id}`, { data: paymentData });
      await onSavedCard();
      await handleClose();
    } catch (err) {
      console.error(err);
    }
  };

  const title = t('general:payment_methods:update_payment_method');
  const actions = (
    <Actions
      handleClose={handleClose}
      handleSubmit={handleSubmit(onSubmit)}
      processing={processing}
    />
  );

  return (
    <Modal open={visible} onClose={handleClose} title={title} actions={actions}>
      <Box p={4}>
        {error && <Alert severity="error">{error}</Alert>}
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box mt={2}>
                <CardHolderInput
                  {...register('card_holder')}
                  error={errors.card_holder}
                  label={t('general:payment_methods:card_holder')}
                  defaultValue={dataUpCard.data?.name}
                />
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box mt={2} display="flex" flexDirection="row">
                <Box className={classes.inputBoxDate}>
                  <CardMonthInput
                    register={register('card_month')}
                    error={errors.card_month}
                    label={t('general:payment_methods:card_month')}
                    defaultValue={dataUpCard.data?.exp_month}
                    onChange={handleMonthChange}
                  />
                </Box>
                <Box width="20px" display="flex" flexDirection="column" alignItems="flex-end" justifyContent="center">
                  <Typography color="textSecondary" align="center">
                    /
                  </Typography>
                </Box>
                <Box className={classes.inputBoxDate}>
                  <CardYearInput
                    register={register('card_year')}
                    error={errors.card_year}
                    label={t('general:payment_methods:card_year')}
                    defaultValue={dataUpCard.data?.exp_year}
                    min={currentYear}
                    onChange={handleYearChange}
                  />
                </Box>

              </Box>
            </Grid>
          </Grid>
        </form>
        <Box mt={3}>
          <Typography className={classes.fontSmall} variant="body2" color="textSecondary">
            <Link to="#">
              <p>{t('general:payment_methods:service_conditions')}</p>
            </Link>
            <Link to="#">{t('general:payment_methods:privacy_message')}</Link>
            {' '}
          </Typography>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddCardFormView;
