import AuthorizedApiClient from '../../core/common/api/AuthorizedApiClient';
import Log from '../../util/Log';
import { types } from './types';

export const resetProcess = () => ({
  type: types.RESET_PEXCEL,
});

export const setReadingExcel = reading => ({
  type: types.SET_PEXCEL_READING_EXCEL,
  reading,
});

export const setValidRows = validRows => ({
  type: types.SET_VALID_ROWS,
  validRows,
});

export const setInvalidRows = invalidRows => ({
  type: types.SET_INVALID_ROWS,
  invalidRows,
});

export const showProductsExcelModal = () => ({
  type: types.SHOW_PRODUCTS_EXCEl_MODAL,
});

export const setError = error => ({
  type: types.SET_PEXCEL_ERROR,
  error,
});

export const savingProductsExcel = () => ({
  type: types.SAVING_PRODUCTS_EXCEL,
});

export const saveProductsExcelFailed = error => ({
  type: types.SAVE_PRODUCTS_EXCEL_FAILED,
  error,
});

export const setFile = file => ({
  type: types.SET_PEXCEL_FILE,
  file,
});

export const saveProductsExcelSucceed = response => ({
  type: types.SAVE_PRODUCTS_EXCEL_SUCCEED,
  response,
});

export const saveProductsExcel = (data, companyId) => async (dispatch: any) => {
  dispatch(savingProductsExcel());
  const sg = new AuthorizedApiClient();
  const path = `companies/${companyId}/products/batch`;

  try {
    const response = await sg.post({ data }, path);

    if (response != null) {
      Log.debug('saved products excel', response);
      dispatch(saveProductsExcelSucceed(response));
    }
  } catch (error) {
    Log.error(error.message);
    dispatch(saveProductsExcelFailed(error.response.data));
  }
};

export const closeProductsExcelModal = () => ({
  type: types.CLOSE_PRODUCTS_EXCEl_MODAL,
});

export const getUnitsForProductExcel = (companyId, params = {}) => async (
  dispatch: any,
) => {
  const sg = new AuthorizedApiClient();
  const path = `companies/${companyId}/unitofmeasures`;
  dispatch({ type: types.SET_PEXCEL_UNITS_PROCESSING, payload: true });
  try {
    const response = await sg.get(params, path);

    if (response != null) {
      dispatch({ type: types.SET_PEXCEL_UNITS_SUCCESS, response: response.data });
    }
  } catch (error) {
    dispatch({ type: types.SET_PEXCEL_UNITS_FAILURE, error });
  }
};
