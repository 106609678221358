/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/button-has-type */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  Modal,
  Typography,
} from '@mui/material';
import axios from 'axios';
import { useSelector } from 'react-redux';
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';
import ArrowLeft from '../../../../theme/ECTheme/assets/images/ArrowLeft.svg';
import PaymentMethodPlan from '../../../../containers/pages/admin/PaymentMethodPlan';
import PlanSubsContext from '../context/PlanSubsContext';
import { PlansInfo } from '../PlansInfo';
import { BASE_URL } from '../../../../constants/api/ApiConstants';
import { PaymentInfo } from '../PaymentInfo';
import { useSession } from '../../../../util/session';

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    maxWidth: 850,
    maxHeight: 620,
    padding: '40px 50px',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #878787',
    boxShadow: theme.shadows[5],
    borderRadius: 5,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: 'auto',
    overflowY: 'auto',
  },
  warning: {
    display: 'block',
    minWidth: 40,
    minHeight: 40,
    backgroundImage: `url(${theme.icons.warning})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    cursor: 'pointer',
    margin: 10,
    [theme.breakpoints.down('md')]: {
      minWidth: 35,
      height: 35,
    },
  },
  linkSection: {
    width: 130,
    margin: '15px auto',
  },
  root: {
    width: '100%',
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),

  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    justifyItems: 'flex-end',
    marginTop: 15,
  },
  nextButton: {

    justifyContent: 'center',
    fontSize: 18,
    color: '#FFF',
    position: 'relative',
    bottom: 10,
    backgroundColor: '#8821EA',
    width: 250,
    '&:hover': {
      backgroundColor: '#6A1AB8',
    },
  },
  previousButton: {
    border: 'none',
    backgroundColor: '#fff',
    borderRadius: 50,
    '&:hover': {
      backgroundColor: '#DEDEDE',
    },
  },
}));

const PlansFormView = ({ open, setOpen, stepSelector }) => {
  const classes = useStyles();
  const steps = getSteps();
  const { companyId } = useSelector((state: any) => state.session);
  const [activeStep, setActiveStep] = React.useState(0);
  const [subscriptionId, setSubscriptionId] = useState('');
  const { value, planId, pricePlan } = React.useContext(PlanSubsContext);
  const [error, setError] = React.useState(null);
  const [processing, setProcessing] = useState(true);
  const [selectMenu, setSelectMenu] = useState(false);
  const { planning } = useSession();

  const handleClose = () => {
    setOpen(false);
  };
  const handlePrevious = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const getSuscription = useCallback(async () => {
    axios
      .get(`${BASE_URL}/api/v1/companies/${companyId}/subscriptions`)
      .then((res) => {
        if (res.data.data.data.length > 0) {
          setSubscriptionId(res.data.data.data[0].id);
        }
      });
  }, [companyId]);

  useEffect(() => {
    getSuscription();
  }, [getSuscription]);

  const updatePlan = useCallback(() => {
    setProcessing(true);
    setError(null);
    if (planning.subscriptions.length > 0) {
      axios
        .put(
          `${BASE_URL}/api/v1/companies/${companyId}/subscriptions/${subscriptionId}`,
          {
            plan_id: planId,
            default_payment_method_id: value,
            price_id: pricePlan,
          },
        )
        .then(res => console.log(res))
        .catch((err) => {
          console.log(err);
          setError(err.response.data);
        })
        .finally(() => { setProcessing(false); });
    } else {
      axios
        .post(
          `${BASE_URL}/api/v1/companies/${companyId}/subscriptions`,
          {
            plan_id: planId,
            default_payment_method_id: value,
            price_id: pricePlan,
          },
        )
        .then(res => console.log(res))
        .catch((err) => {
          setError(err.response.data);
        })
        .finally(() => { setProcessing(false); });
    }
  }, [companyId, planId, subscriptionId, value]);

  const openAlert = () => {
    setSelectMenu(true);
  };

  const closeAlert = () => {
    setSelectMenu(false);
  };

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleNextandUpdate = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
    updatePlan();
  };

  function getSteps() {
    return ['1', '2', '3'];
  }

  const stepper = (stepSelector, step) => {
    if (stepSelector) {
      return stepSelector;
    }
    return step;
  };
  function getStepContent(stepper) {
    switch (stepper) {
      case 0:
        return <PlansInfo />;
      case 1:
        return <PaymentMethodPlan />;
      case 2:
        return <PaymentInfo err={error} processing={processing} />;
      default:
        return 'Unknown step';
    }
  }

  const body = (
    <Box className={classes.paper}>
      <div>
        {
          selectMenu
            ? (
              <Alert
                action={(
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={closeAlert}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                )}
                severity="error"
              >
                Selecciona un Plan para seguir avanzando
              </Alert>
            )
            : null
        }
      </div>
      <div className={classes.root}>
        {
          activeStep === steps.length - 2
            && (
            <button className={classes.previousButton} onClick={handlePrevious}>
              <img src={ArrowLeft} alt="back-arrow-button" style={{ padding: 5 }} />
            </button>
            )
        }

        {
          activeStep <= steps.length
            && (
            <>
              <Typography className={classes.instructions}>
                {' '}
                {getStepContent(activeStep)}
                {' '}
              </Typography>
              <Box className={classes.buttons}>
                {
                  activeStep === steps.length - 1
                    ? (
                      <>
                        {
                          error !== null

                            ? (
                              <Button
                                onClick={() => { setActiveStep(1); }}
                                className={classes.nextButton}
                              >
                                Regresar al método de pago
                              </Button>
                            )
                            : (
                              <Button
                                className={classes.nextButton}
                                href="/settings"
                              >
                                Finalizar
                              </Button>
                            )
                        }
                      </>
                    )
                    : (
                      <div className={classes.buttons}>
                        {
                          activeStep === steps.length - 2
                          && (
                          <Button
                            onClick={value !== null ? handleNextandUpdate : openAlert}
                            className={classes.nextButton}
                          >
                            Confirmar
                          </Button>
                          )
                        }

                        {
                          activeStep === steps.length - 3
                          && (
                          <Button
                            onClick={planId !== null ? handleNext : openAlert}
                            className={classes.nextButton}
                          >
                            Pagar
                          </Button>
                          )
                        }
                      </div>
                    )
                }
              </Box>
            </>
            )
        }
      </div>

    </Box>
  );

  return (
    <Modal
      open={open}
      onClose={handleClose}
      disableEnforceFocus
      disableAutoFocus
    >
      {body}
    </Modal>
  );
};

export default PlansFormView;
