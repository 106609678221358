import { Typography } from '@mui/material';
import React from 'react';

interface Props {
  title: string;
}

const KPITitle = ({ title }: Props) => <Typography variant="subtitle2">{title}</Typography>;

export default KPITitle;
