/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/prop-types */
import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CategoryFormView from './CategoryFormView';

import {
  getCategories,
  resetCategories,
} from '../../../../../actions/categories';
import { saveCategory, deleteCategory, closeModalCategory } from '../../../../../actions/category';
import { SCROLL_PER_PAGE } from '../../../../../constants/common';
import { types } from '../../../../../actions/category/types';

export default ({ source = 1 }) => {
  const dispatch = useDispatch();
  const { company } = useSelector((state: any) => state.session);
  const {
    category, error, processing, deletingCategory, refresh, showModal,
  } = useSelector((state: any) => state.category);

  const handleClose = () => {
    dispatch(closeModalCategory());
  };

  const _getCategories = useCallback(() => {
    dispatch(resetCategories());
    dispatch(getCategories(company.id, { page: 1, per_page: SCROLL_PER_PAGE }));
    dispatch({
      type: types.CLEAR_REFRESH,
    });
  }, [dispatch, company]);

  const attemptSaveCategory = async (data) => {
    await dispatch(saveCategory(data, company.id));
  };

  const attemptDeleteCategory = async (category) => {
    await dispatch(deleteCategory(category, company.id));
  };

  useEffect(() => {
    if (refresh && source === 1) {
      _getCategories();
    }
  }, [refresh, _getCategories, source]);

  return (
    <CategoryFormView
      category={category}
      attemptSaveCategory={data => attemptSaveCategory(data)}
      attemptDeleteCategory={category => attemptDeleteCategory(category)}
      processing={processing}
      deletingCategory={deletingCategory}
      error={error}
      handleClose={handleClose}
      showModal={showModal}
    />
  );
};
