/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/prop-types */
import {
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import React, { useState } from 'react';
import {
  CustomerPaymentMethods,
  PaymentVendors,
  PAYMENT_METHODS_URL,
} from '../../../constants/common';
import useRequest from '../../../hooks/useRequest';
import AddCardFormView from './AddCardFormView';

const AddCardForm = ({ visible, handleClose, onSavedCard }) => {
  const [processingToken, setProcessingToken] = useState(false);
  const [tokenError, setTokenError] = useState(null);
  const { processing, post, error } = useRequest({});

  const successResponseHandler = async function (token) {
    const response = await post(PAYMENT_METHODS_URL, {
      type: CustomerPaymentMethods.CARD,
      vendor: PaymentVendors.STRIPE,
      token: token.id,
    });

    setProcessingToken(false);

    onSavedCard(response.data.payment_method);
    handleClose();
  };

  const errorResponseHandler = function (error) {
    setTokenError(error.message);
    setProcessingToken(false);
  };

  const stripe = useStripe();
  const elements = useElements();

  const attempSaveCard = async (data) => {
    setTokenError(null);
    setProcessingToken(true);

    const cardElement = elements.getElement(CardNumberElement);

    const { token, error: stripeError } = await stripe.createToken(
      cardElement,
      { name: data.card_holder },
    );

    if (stripeError) {
      errorResponseHandler(stripeError);
    } else {
      successResponseHandler(token);
    }
  };

  return (
    <AddCardFormView
      visible={visible}
      handleClose={handleClose}
      attemptSaveCard={attempSaveCard}
      processing={processingToken || processing}
      error={tokenError || error?.data}
    />
  );
};

export default AddCardForm;
