/* eslint-disable max-len */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  IconButton,
  Radio,
  Typography,
} from '@mui/material';
import React, {
  ChangeEvent, useEffect, useReducer, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { makeStyles, withStyles } from '@mui/styles';
import Modal from '../../ui/Modals/Modal';
import { addItemToCart } from '../../../actions/cart';
import AddRemove from '../../ui/CartItem/AddRemove';
import { ProductInterface, ProductWithQuantity, ProductWithModifiers } from '../../../helpers/interfaces/product';
import { generatePasswordRand } from '../../../util/keys';

const style = makeStyles(() => ({
  imageMedia: {
    display: 'grid',
    width: 'calc(100% + 32px)',
    minWidth: 320,
    margin: '-16px -16px 0px -16px',
    height: 'auto',
    minHeight: 220,
    maxHeight: 337,
    objectFit: 'cover',
    placeItems: 'center',
    backgroundColor: '#FFF5E3',
  },
  complementImageMedia: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: 5,
  },
  complementImageMediaContainer: {
    margin: 5,
    width: 35,
    height: 35,
  },
  modifierItem: {
    padding: '6px 10px',
    alignItems: 'center',
    gap: 8,
  },
  itemContainer: {
    position: 'relative',
  },
  overlayOutOfStock: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    backgroundColor: '#8c7f7f10',
    borderRadius: 6,
    display: 'grid',
    placeContent: 'center',
    fontWeight: 'bold',
    zIndex: 111,
  },
}));

const EAccordion = withStyles(() => ({
  root: {
    '&.MuiAccordion-rounded': {
      '&:first-child': {
        marginTop: 15,
      },
    },
    '&.MuiAccordion-root.Mui-expanded': {
      '&:last-child': {
        marginBottom: '15px',
      },
    },
  },
}))(Accordion);

interface ProductModalProps {
  handlerOpen: Function;
  productData: ProductInterface;
  isOpen: boolean;
}

type AddRemoveOptions = { type: 'add' | 'remove' | 'change' | 'reset', data?: any };

const addRemoveReducerInitialState = [];

function addRemoveReducer(
  stateAddRemove: Array<InputOptions>,
  action: AddRemoveOptions,
) {
  switch (action.type) {
    case 'add': {
      if (stateAddRemove
        .some(savedModifiers => savedModifiers.modifierId === action.data.modifierId)) {
        const restModifiersFromState = stateAddRemove
          .filter(savedModifier => savedModifier.modifierId !== action.data.modifierId);
        const modifierFoundFromState = stateAddRemove
          .find(savedModifier => savedModifier.modifierId === action.data.modifierId);
        if (modifierFoundFromState.products
          .some(productInModifierFound => productInModifierFound.id === action.data.variant.id)) {
          const restProductsFromState = modifierFoundFromState.products
            .filter(savedProduct => savedProduct.id !== action.data.variant.id);
          const productFoundFromState = modifierFoundFromState.products
            .find(savedProduct => savedProduct.id === action.data.variant.id);
          productFoundFromState.quantity += 1;
          const newListProducts = [
            ...restProductsFromState,
            productFoundFromState,
          ];
          const newModifier = {
            ...modifierFoundFromState,
            products: newListProducts,
          };
          return [
            ...restModifiersFromState,
            newModifier,
          ];
        }
        action.data.variant.quantity = 1;
        const newModifier = {
          modifierId: action.data.modifierId,
          products: [
            ...modifierFoundFromState.products,
            action.data.variant,
          ],
        };
        return [
          ...restModifiersFromState,
          newModifier,
        ];
      }
      action.data.variant.quantity = 1;
      const parsedInterface = {
        modifierId: action.data.modifierId,
        products: [
          action.data.variant,
        ],
      };
      return [
        ...stateAddRemove,
        parsedInterface,
      ];
    }
    case 'remove': {
      const restModifiersFromState = stateAddRemove
        .filter(savedModifier => savedModifier.modifierId !== action.data.modifierId);
      const modifierFoundFromState = stateAddRemove
        .find(savedModifier => savedModifier.modifierId === action.data.modifierId);

      const restProductsModifierFound = modifierFoundFromState.products
        .filter(savedProduct => savedProduct.id !== action.data.variant.id);
      const productFoundFromModifierFound = modifierFoundFromState.products
        .find(savedProduct => savedProduct.id === action.data.variant.id);

      productFoundFromModifierFound.quantity -= 1;

      const listProducts = [
        ...restProductsModifierFound,
        productFoundFromModifierFound,
      ];
      const newListProducts = listProducts.filter(products => products.quantity !== 0);
      const newModifier = {
        ...modifierFoundFromState,
        products: newListProducts,
      };
      if (newModifier.products.length < 1) {
        return restModifiersFromState;
      }
      return [
        ...restModifiersFromState,
        newModifier,
      ];
    }
    case 'change': {
      if (stateAddRemove
        .some(savedModifiers => savedModifiers.modifierId === action.data.modifier.modifierId)) {
        const restModifiersFromState = stateAddRemove
          .filter(savedModifier => savedModifier.modifierId !== action.data.modifier.modifierId);
        const modifierFoundFromState = stateAddRemove
          .find(savedModifier => savedModifier.modifierId === action.data.modifier.modifierId);
        if (modifierFoundFromState.products
          .some(productInModifier => productInModifier.id === action.data.modifier.variant.id)) {
          const restProductsFromState = modifierFoundFromState.products
            .filter(savedProduct => savedProduct.id !== action.data.modifier.variant.id);
          const productFoundFromState = modifierFoundFromState.products
            .find(savedProduct => savedProduct.id === action.data.modifier.variant.id);
          productFoundFromState.quantity = action.data.quantity;
          const listProducts = [
            ...restProductsFromState,
            productFoundFromState,
          ];
          const newListProducts = listProducts.filter(products => products.quantity !== 0);
          const newModifier = {
            ...modifierFoundFromState,
            products: newListProducts,
          };
          if (newModifier.products.length < 1) {
            return restModifiersFromState;
          }
          return [
            ...restModifiersFromState,
            newModifier,
          ];
        }
        action.data.modifier.variant.quantity = action.data.quantity;
        const listProducts = [
          ...modifierFoundFromState.products,
          action.data.modifier.variant,
        ];
        const newListProducts = listProducts.filter(products => products.quantity !== 0);
        const newModifier = {
          ...modifierFoundFromState,
          products: newListProducts,
        };
        if (newModifier.products.length < 1) {
          return restModifiersFromState;
        }
        return [
          ...restModifiersFromState,
          newModifier,
        ];
      }
      if (action.data.quantity === 0) {
        return stateAddRemove;
      }
      action.data.modifier.variant.quantity = action.data.quantity;
      const newModifier = {
        modifierId: action.data.modifier.modifierId,
        products: [
          action.data.modifier.variant,
        ],
      };
      return [
        ...stateAddRemove,
        newModifier,
      ];
    }
    case 'reset': {
      return addRemoveReducerInitialState;
    }

    default: {
      throw new Error();
    }
  }
}

interface InputOptions {
  modifierId: string,
  products: Array<ProductWithQuantity>
}

const ProductModal = ({ isOpen, handlerOpen, productData }: ProductModalProps) => {
  const { t } = useTranslation();
  const classes = style();
  const dispatch = useDispatch();
  const [addRemove, addRemoveDispatch] = useReducer(addRemoveReducer, addRemoveReducerInitialState);
  const [checked, setChecked] = useState<Array<InputOptions>>([]);
  const [radio, setRadio] = useState<Array<InputOptions>>([]);
  const [multiplyProduct, setMultiplyProduct] = useState<number>(1);
  const [productState, setProductState] = useState<ProductWithModifiers>(
    productData && productData,
  );
  const [requiredProducts, setRequiredProducts] = useState({
    isOpen: false,
    products: [],
  });

  useEffect(() => {
    setProductState({
      ...productData,
      quantity: multiplyProduct,
      selected_modifiers: [
        ...addRemove,
        ...checked,
        ...radio,
      ],
    });
  }, [addRemove, checked, radio, productData, multiplyProduct]);

  const addMultiply = () => {
    setMultiplyProduct(multiplyProduct + 1);
  };

  const removeMultiply = () => {
    setMultiplyProduct(multiplyProduct - 1);
  };

  const changeMultiply = (value: number) => {
    setMultiplyProduct(value);
  };

  const handleChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const parsedSentValues: { modifierId: string, variant: ProductWithQuantity } = JSON.parse(value);
    parsedSentValues.variant.quantity = 1;
    setRadio((prev) => {
      if (prev
        .some(savedModifiers => savedModifiers.modifierId === parsedSentValues.modifierId)) {
        const modifierFound = prev
          .find(savedModifiers => savedModifiers.modifierId === parsedSentValues.modifierId);
        if (parsedSentValues.variant.id === modifierFound.products[0].id) {
          return prev
            .filter(savedModifiers => savedModifiers.modifierId !== modifierFound.modifierId);
        }
        return prev.map((modifier) => {
          if (modifier.modifierId === parsedSentValues.modifierId) {
            return {
              ...modifier,
              products: [parsedSentValues.variant],
            };
          }
          return modifier;
        });
      }
      return [
        ...prev,
        {
          modifierId: parsedSentValues.modifierId,
          products: [parsedSentValues.variant],
        },
      ];
    });
  };

  const handleChangeCheck = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const parsedSentValues: { modifierId: string, variant: ProductWithQuantity } = JSON.parse(value);
    parsedSentValues.variant.quantity = 1;
    setChecked((prev) => {
      if (prev
        .some(savedModifiers => savedModifiers.modifierId === parsedSentValues.modifierId)) {
        const modifierFound = prev
          .find(savedModifiers => savedModifiers.modifierId === parsedSentValues.modifierId);
        if (modifierFound.products
          .some(productInModifier => productInModifier.id === parsedSentValues.variant.id)) {
          const productsArrayWithRemovedValue = modifierFound.products
            .filter(productModifier => productModifier.id !== parsedSentValues.variant.id);
          const newModifier = {
            ...modifierFound,
            products: productsArrayWithRemovedValue,
          };
          if (newModifier.products.length < 1) {
            return prev.filter(modifier => modifier.modifierId !== newModifier.modifierId);
          }
          return prev.map((modifier) => {
            if (modifier.modifierId === newModifier.modifierId) {
              return newModifier;
            }
            return modifier;
          });
        }
        const newModifier = {
          ...modifierFound,
          products: [
            ...modifierFound.products,
            parsedSentValues.variant,
          ],
        };
        return prev.map((modifier) => {
          if (modifier.modifierId === newModifier.modifierId) {
            return newModifier;
          }
          return modifier;
        });
      }
      return [
        ...prev,
        {
          modifierId: parsedSentValues.modifierId,
          products: [
            parsedSentValues.variant,
          ],
        },
      ];
    });
  };

  const handleClose = () => {
    setChecked([]);
    setRadio([]);
    setMultiplyProduct(1);
    setProductState(undefined);
    addRemoveDispatch({ type: 'reset' });
    handlerOpen((prev: object) => ({
      ...prev,
      isOpen: false,
    }));
    setRequiredProducts({
      isOpen: false,
      products: [],
    });
  };

  // eslint-disable-next-line consistent-return
  const handleAddItemToCart = () => {
    // para productos que se agregan desde campañas, no tienen grupos modificadores
    if (!productState.modifiers_groups) {
      dispatch(addItemToCart({
        line_id: generatePasswordRand(15),
        ...productState,
      }));
      return handleClose();
    }

    const modifierRequired = productState.modifiers_groups
      .filter(modifiers => modifiers.required);
    const listOfModifiersRequired = modifierRequired.map(modifier => ({
      id: modifier.id,
      name: modifier.title,
      isAdded: productState.selected_modifiers
        .some(selectedModifier => selectedModifier.modifierId === modifier.id),
    }));
    const modifierRequiredAndNotAdded = listOfModifiersRequired
      .filter(modifiers => modifiers.isAdded !== true);

    if (modifierRequiredAndNotAdded.length > 0) {
      return setRequiredProducts({
        isOpen: true,
        products: modifierRequiredAndNotAdded,
      });
    }

    dispatch(addItemToCart({
      line_id: generatePasswordRand(15),
      ...productState,
    }));
    handleClose();
  };

  const actions = (
    <>
      <Button color="secondary" onClick={() => handleClose()}>
        {t('general:cancel')}
      </Button>
      <Button color="primary" variant="contained" onClick={handleAddItemToCart}>
        {t('general:add')}
      </Button>
    </>
  );

  if (!productData) {
    return null;
  }

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      title="Agregar al carrito"
      actions={actions}
    >
      {
                requiredProducts.isOpen
                && (
                <Box position="absolute" top="80px" left="0" right="0" margin="auto" zIndex="1" width="80%">
                  <Alert
                    action={(
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setRequiredProducts(prev => ({ ...prev, isOpen: false }));
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                          )}
                    variant="filled"
                    severity="error"
                  >
                    {`Los campos ${requiredProducts.products.map(item => ` ${item.name}`)} son requeridos`}
                  </Alert>
                </Box>
                )
            }
      {
                Object.keys(productData).length !== 0
                  ? (
                    <>
                      <img
                        className={classes.imageMedia}
                        src={productData.variants[0].image_url}
                        alt="Product view"
                      />
                      <Box width="100%" display="flex" justifyContent="space-between">
                        {
                                // eslint-disable-next-line no-nested-ternary
                                productData.inventoried
                                  ? (
                                    <Typography variant="subtitle1" display="inline">
                                      { productData.variants[0].stock }
                                      {' '}
                                      { productData.variants[0].unitofmeasure?.code || 'Unidades*' }
                                      {' '}
                                      disponibles
                                    </Typography>
                                  )
                                  : productData.available
                                    ? 'Disponible'
                                    : 'Agotado'
                            }

                        <Typography variant="subtitle1" display="inline">
                          $
                          { productData.variants[0].price }
                          c/u
                        </Typography>
                      </Box>
                      <Typography variant="h5">
                        { productData.name }
                      </Typography>
                      <Typography variant="body2">
                        { productData.description }
                      </Typography>
                    </>
                  )
                  : null
            }

      {
                productData.modifiers_groups
                && (Object.keys(productData).length !== 0)
                && (productData.modifiers_groups.length !== 0)
                  ? (
                    <>
                      <Typography variant="h6">
                        Complementos
                      </Typography>
                      <Divider />
                      {
                            productData.modifiers_groups.map(modifier => (
                              <EAccordion key={modifier.id} defaultExpanded>
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                >
                                  <Typography variant="subtitle1">
                                    {
                                      requiredProducts.products
                                        .some(requiredProduct => requiredProduct.id === modifier.id)
                                      && <ErrorOutlineIcon fontSize="small" color="error" />
                                    }
                                    &nbsp;
                                    { modifier.title }
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails style={{ marginBottom: 'auto' }}>
                                  <Typography variant="caption">
                                    { modifier.description }
                                  </Typography>
                                  <Grid container>
                                    {
                                      modifier.modifiers.map((modifierItem) => {
                                        // console.log({ modifierItem }, modifierItem.variant.stock, modifierItem.variant.product.inventoried);
                                        const { stock } = modifierItem.variant;
                                        const isInventoried = modifierItem.variant.product.inventoried;
                                        const { available } = modifierItem.variant.product;
                                        const canBuy = (!isInventoried && available) || (isInventoried && stock > 0);
                                        const { variant } = modifierItem;
                                        const { id: modifierId } = modifier;
                                        const valueToSet = { modifierId, variant };
                                        let quantityItem: number = 0;
                                        let valueChecked: boolean = false;
                                        // busca si existe el producto en algun modificador
                                        // y le asgina valores a razon de eso. las condiciones
                                        // son las mismas que para el renderizado.
                                        if (modifier.allow_several) {
                                          if (modifierItem.max_permitted !== 1) {
                                            // is a add remove quantity
                                            if (addRemove.some(AddRemoveModifier => AddRemoveModifier.modifierId === modifier.id)) {
                                              const AddRemoveModifierFound = addRemove.find(AddRemoveModifier => AddRemoveModifier.modifierId === modifier.id);
                                              if (AddRemoveModifierFound.products.some(productModifierInAddRemove => productModifierInAddRemove.id === modifierItem.variant.id)) {
                                                const { quantity } = AddRemoveModifierFound.products.find(productModifierInAddRemove => productModifierInAddRemove.id === modifierItem.variant.id);
                                                quantityItem = quantity;
                                              }
                                            }
                                          } else {
                                            // is a checck quantity
                                            // eslint-disable-next-line no-lonely-if
                                            if (checked.some(radioModifier => radioModifier.modifierId === modifier.id)) {
                                              const checkedModifierGroupFound = checked.find(radioModifier => radioModifier.modifierId === modifier.id);
                                              valueChecked = checkedModifierGroupFound.products.some(productInChecked => productInChecked.id === modifierItem.variant.id);
                                            }
                                          }
                                        } else {
                                          // is radio quantiy
                                          // eslint-disable-next-line no-lonely-if
                                          if (radio.some(radioModifier => radioModifier.modifierId === modifier.id)) {
                                            const radioModifierGroupFound = radio.find(radioModifier => radioModifier.modifierId === modifier.id);
                                            valueChecked = radioModifierGroupFound.products[0].id === modifierItem.variant.id;
                                          } else {
                                            valueChecked = false;
                                          }
                                        }

                                        return (
                                          // eslint-disable-next-line no-underscore-dangle
                                          <Grid container key={modifierItem._id} className={classes.itemContainer}>
                                            {canBuy && (
                                              <span className={classes.overlayOutOfStock}>
                                                Agotado
                                              </span>
                                            )}
                                            <Grid container style={{ opacity: canBuy ? 0.35 : 1 }} className={classes.modifierItem}>
                                              {
                                                // eslint-disable-next-line no-nested-ternary
                                                modifier.allow_several
                                                  ? modifierItem.max_permitted !== 1
                                                    ? (
                                                      <Grid style={{ width: 80 }}>
                                                        <AddRemove
                                                          value={quantityItem}
                                                          add={() => addRemoveDispatch({ type: 'add', data: valueToSet })}
                                                          remove={() => addRemoveDispatch({ type: 'remove', data: valueToSet })}
                                                          onValueChange={(value: number) => addRemoveDispatch({ type: 'change', data: { modifier: valueToSet, quantity: value } })}
                                                          max={modifierItem.max_permitted || 999}
                                                          fontSize={14}
                                                        />
                                                      </Grid>
                                                    )
                                                    : (
                                                      <Checkbox
                                                        value={JSON.stringify(valueToSet)}
                                                        checked={valueChecked}
                                                        onChange={handleChangeCheck}
                                                        color="primary"
                                                      />
                                                    )
                                                  : (
                                                    <Radio
                                                      required
                                                      value={JSON.stringify(valueToSet)}
                                                      checked={valueChecked}
                                                      onChange={handleChangeRadio}
                                                      color="primary"
                                                      name="radio-button-demo"
                                                    />
                                                  )
                                                }
                                              <Typography variant="body1" style={{ height: 'max-content' }}>
                                                { modifierItem.variant.product.name }
                                              </Typography>
                                              <Grid style={{
                                                flex: 1, display: 'flex', justifyContent: 'flex-end', alignItems: 'center',
                                              }}
                                              >
                                                <Typography variant="caption">
                                                  $
                                                  {modifierItem.variant.price || 0}
                                                </Typography>
                                              </Grid>
                                              <Grid className={classes.complementImageMediaContainer}>
                                                {
                                                        modifierItem.variant.image_url
                                                        && (
                                                        <img
                                                          className={classes.complementImageMedia}
                                                          src={modifierItem.variant.image_url}
                                                          alt="Complement view"
                                                        />
                                                        )
                                                    }
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        );
                                      })
                                    }
                                  </Grid>
                                </AccordionDetails>
                              </EAccordion>
                            ))
                        }
                      <Grid container style={{ margin: '20px 0px' }}>
                        <AddRemove
                          value={multiplyProduct}
                          add={addMultiply}
                          remove={removeMultiply}
                          onValueChange={changeMultiply}
                          min={1}
                          max={productData.inventoried ? productData.variants[0].stock : 999}
                          fontSize={18}
                        />
                      </Grid>
                    </>
                  )
                  : null
            }
    </Modal>
  );
};

export default ProductModal;
