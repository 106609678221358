/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import {
  Box, Grid,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

const styles = makeStyles(() => ({
  container: {
    textAlign: 'center',
    border: '1px solid #E2E2E2',
    position: 'relative',
    width: 'auto',
  },
  input: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
    margin: 0,
    transition: 'background 0.3s',
    appearance: 'none',
    '&:checked': {
      background: '#F4E8FC40',
    },
    cursor: 'pointer',
  },
  content: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  imgSize: {
    height: '50%',
    width: '50%',
    marginBottom: 10,
  },
  Image: {
    backgroundRepeat: 'no-repeat',
    height: '100%',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
  },
  noCheked: {
    '&:after': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      margin: 0,
      zIndex: -1,
      transform: 'scale(0)',
      transition: 'background 0.25s, transform 0.25s',
    },
  },
  Checked: {
    '&:after': {
      transform: 'scale(1)',
      background: '#F4E8FC',
    },
  },
}));

export default (props) => {
  const classes = styles();

  return (
    <Grid className={classes.container}>
      <Box className={`${classes.content} ${classes.noCheked} ${props.checkvalue === props.value ? classes.Checked : ''}`} onClick={() => props.change()}>
        <Box className={`${classes.imgSize}`}>
          <div style={{ backgroundImage: `url(${props.image})` }} className={classes.Image} />
        </Box>
        <Grid>
          {props.title}
        </Grid>
      </Box>
    </Grid>
  );
};
