import axios from 'axios';
import { BASE_URL } from '../../../constants/api/ApiConstants';
import Response from './Response';

export default abstract class ApiClient {
  private url: string;

  private headers = {};

  constructor() {
    this.setUrl(BASE_URL);
  }

  public setUrl(url: string) {
    this.url = url;
  }

  abstract getPath(): string;

  getBaseUrl() {
    // TODO
    return this.url;
  }

  buildUrl(path = ''): string {
    return `${this.getBaseUrl()}/${path}`;
  }

  getBaseRequest() {
    this.interceptor();
    return axios;
  }

  async get(params = {}, _path = null) {
    const path = _path !== null ? _path : this.getPath();
    const url = this.buildUrl(path);
    return this.getBaseRequest().get(url, { params });
  }

  async post(data = {}, _path = null, headers = {}) {
    const path = _path !== null ? _path : this.getPath();
    const url = this.buildUrl(path);
    return this.getBaseRequest().post(url, data, { ...this.headers, ...headers });
  }

  async put(data = {}, _path = null) {
    const path = _path !== null ? _path : this.getPath();
    const url = this.buildUrl(path);
    return this.getBaseRequest().put(url, data, this.headers);
  }

  async delete(_path = null) {
    const path = _path !== null ? _path : this.getPath();
    const url = this.buildUrl(path);
    return this.getBaseRequest().delete(url, this.headers);
  }

  static parseError(response) {
    if (response === undefined) {
      return 'Unknow';
    }
    switch (response.status) {
      case Response.UNAUTHORIZED:
        return 'Unauthorized';
      case Response.UNPROCESABLE_ENTITY:

        return this.parseUnprocesableEntity(response);

      default:
        return 'Unknow';
    }
  }

  public static parseUnprocesableEntity(response) {
    if (response.data !== undefined) {
      return response.data;
    }

    return { unknow: 'Unknow' };
  }

  public interceptor() {
    axios.interceptors.request.use((config) => {
      config.headers.common.Accept = 'application/json';

      return config;
    });
  }

  async downloadFile(params, _path: string) {
    const path = _path !== null ? _path : this.getPath();
    const url = this.buildUrl(path);
    return this.getBaseRequest().get(url, {
      params,
      responseType: 'blob',
    });
  }
}
