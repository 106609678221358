import React from 'react';
import { useTranslation } from 'react-i18next';
import SectionListContainer from '../../../../hoc/SectionListContainer';
import Grid from '../../../../components/ui/Layout/Grid';
import PageContainer from '../../../../hoc/PageContainer';
import Box from '../../../../components/ui/Layout/Box';
import AddButton from '../../../../components/ui/Buttons/BlueButton/AddButton';
import ModifierGroupForm from './ModifierGroupForm';
import { PERMISSION, RESOURCES } from '../../../../constants/common';
import { useSession } from '../../../../util/session';
import ModifiersGroupsList from './ModifiersGroupsList';

type ModifiersGroupsProps = {
  openNewModifierGroup: any;
  refreshList: any;
};
export default ({ openNewModifierGroup, refreshList }: ModifiersGroupsProps) => {
  const { t } = useTranslation('general');
  const { can } = useSession();

  return (
    <>
      {(can(RESOURCES.products, PERMISSION.create)
        || can(RESOURCES.products, PERMISSION.create))
        && <ModifierGroupForm refreshList={refreshList} />}

      <Grid container>
        {/* <Grid item xs={12}>
          <ContentHeader
            title={t("general:inventory")}
            section="products"
            back
          />
        </Grid> */}

        <PageContainer>
          {/* <BreadCrumbs /> */}
          {can(RESOURCES.products, PERMISSION.create) && (
            <Box mt={3}>
              <AddButton onClick={openNewModifierGroup}>
                {t('general:modifiers_groups:create_modifier_group')}
              </AddButton>
            </Box>
          )}

          <SectionListContainer>
            <ModifiersGroupsList />
          </SectionListContainer>
        </PageContainer>
      </Grid>
    </>
  );
};
