/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import { useTranslation } from 'react-i18next';
import ActivityIndicator from '../../../../../../components/ui/ActivityIndicator';
import { useKPIDashboardContext } from '../context/KPIDashboardContext';
import KPIData, { KPIDataType } from '../KPIData';
import KPITitle from '../KPITitle';
import KPIWrapper from '../KPIWrapper';

const TotalSales = () => {
  const { t } = useTranslation(['general']);
  const { state } = useKPIDashboardContext();
  const { total_sales, processing } = state;

  return (
    <KPIWrapper>
      <KPITitle title={t('general:admin_dashboard:sales_number')} />
      {processing ? (
        <ActivityIndicator />
      ) : (
        <KPIData value={total_sales} type={KPIDataType.QUANTITY} />
      )}
    </KPIWrapper>
  );
};

export default TotalSales;
