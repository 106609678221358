/* eslint-disable react/destructuring-assignment */
import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useLocation } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { rules as validationRules } from '../../../../../util/Validations';

import FormInput from '../../../../../components/ui/FormInput';

import Grid from '../../../../../components/ui/Layout/Grid';
import SuccessButton from '../../../../../components/ui/Buttons/SuccessButton';
import Box from '../../../../../components/ui/Layout/Box';
import Alert from '../../../../../components/ui/notifications/alert';
import { REQUIRE_INVITATION_CODE } from '../../../../../constants/api/ApiConstants';

interface IRegisterForm {
  onRegister: Function;
  processing: boolean;
  error: string;
}

const useQuery = () => new URLSearchParams(useLocation().search);

const RegisterFormView: FC<IRegisterForm> = (props) => {
  const query = useQuery();
  const { t } = useTranslation([
    'register',
    'translation',
    'validation',
    'api_errors',
    'general',
  ]);

  const validationSchema = Yup.object({
    name: Yup.string()
      .max(validationRules.maxLength)
      .required(
        t('validation:required', {
          input: t('register:names'),
        }),
      ),
    last_name: Yup.string()
      .max(validationRules.maxLength)
      .required(
        t('validation:required', {
          input: t('register:last_name'),
        }),
      ),
    nickname: Yup.string().max(validationRules.maxLength),
    company_name: Yup.string()
      .max(validationRules.maxLength)
      .required(
        t('validation:required', {
          input: t('register:what_is_the_company_name'),
        }),
      ),
    phone: Yup.string()
      .required(
        t('validation:required', {
          input: t('register:phone'),
        }),
      )
      .max(validationRules.maxLength),
    email: Yup.string()
      .email(t('validation:invalid_email'))
      .max(validationRules.maxLength)
      .required(
        t('validation:required', {
          input: t('register:email'),
        }),
      ),
    password: Yup.string()
      .min(
        validationRules.minPasswordLength,
        t('validation:invalid_password_format'),
      )
      .max(validationRules.maxLength)
      // .required(
      //   t("validation:required", {
      //     input: t("register:password"),
      //   })
      // )
      .matches(
        validationRules.password,
        t('validation:invalid_password_format'),
      ),

    password1: Yup.string().oneOf(
      [Yup.ref('password'), null],
      t('validation:passwords_mismatch'),
    ),
    invitation_code: REQUIRE_INVITATION_CODE ? Yup.string()
      .required(
        t('validation:required', {
          input: t('register:invitation_code'),
        }),
      )
      .max(validationRules.maxLength)
      : Yup.string()

        .max(validationRules.maxLength),
    // business_types: Yup.string().required(t("register:business_type_e")),
  });

  const { register, handleSubmit, formState: { errors } } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data) => {
    if (!REQUIRE_INVITATION_CODE) {
      delete data.invitation_code;
    }
    props.onRegister(data);
  };

  const urlInvitationCode = query.get('v') ? query.get('v') : '';
  return (
    <Grid
      container
      justifyContent="center"
      direction="row"
      alignItems="center"
      spacing={1}
    >
      <Grid item xs={10} md={10}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off"
          className="w-100 mb-5 mt-5"
        >
          {props.error && (
            <div className="form-group ">
              <Alert
                severity="error"
                message={t(`register:${props.error}`, props.error)}
              />
            </div>
          )}

          <Grid container direction="column" spacing={4}>
            <Grid item>
              <FormInput
                register={register('name')}
                error={errors.name}
                label={t('register:names')}
                placeholder={t('register:placeholder_name')}
              />
            </Grid>
          </Grid>

          <Grid container direction="column" spacing={4}>
            <Grid item>
              <FormInput
                label={t('register:last_name')}
                register={register('last_name')}
                error={errors.last_name}
                placeholder={t('register:placeholder_last_name')}
              />
            </Grid>
          </Grid>

          <Grid container direction="column" spacing={4}>
            <Grid item>
              <FormInput
                label={t('register:what_is_nickname')}
                register={register('nickname')}
                error={errors.nickname}
                placeholder={t('register:placeholder_nickname')}
              />
            </Grid>
          </Grid>

          <Grid container direction="column" spacing={4}>
            <Grid item>
              <FormInput
                label={t('register:what_is_the_company_name')}
                register={register('company_name')}
                error={errors.company_name}
                placeholder={t('register:placeholder_company_name')}
              />
            </Grid>
          </Grid>

          <Grid container direction="column" spacing={4}>
            <Grid item>
              <FormInput
                register={register('phone')}
                error={errors.phone}
                placeholder={t('register:placeholder_phone')}
                type="phone"
                label={t('register:phone')}
              />
            </Grid>
          </Grid>

          <Grid container direction="column" spacing={4}>
            <Grid item>
              <FormInput
                label={t('register:email')}
                register={register('email')}
                error={errors.email}
                placeholder={t('register:placeholder_email')}
              />
            </Grid>
          </Grid>

          <Grid container direction="column" spacing={4}>
            <Grid item>
              <FormInput
                label={t('register:password')}
                register={register('password')}
                error={errors.password}
                placeholder={t('register:placeholder_password')}
                type="password"
                autoComplete="off"
              />
            </Grid>
          </Grid>

          <Grid container direction="column" spacing={4}>
            <Grid item>
              <FormInput
                label={t('register:retype_password')}
                register={register('password1')}
                error={errors.password1}
                placeholder={t('register:placeholder_password')}
                type="password"
                autoComplete="off"
                labelWidth={150}
              />
            </Grid>
          </Grid>

          {REQUIRE_INVITATION_CODE && (
            <Grid container direction="column" spacing={4} style={{ display: urlInvitationCode ? 'none' : 'flex' }}>
              <Grid item>
                <FormInput
                  label={t('register:invitation_code')}
                  register={register('invitation_code')}
                  error={errors.invitation_code}
                  placeholder={t('register:invitation_code')}
                  autoComplete="off"
                  defaultValue={urlInvitationCode}
                  maxLength={10}
                />
              </Grid>
            </Grid>
          )}

          <Grid container justifyContent="center" spacing={4} className="mt-5">
            <Box mt={3} mb={3}>
              <SuccessButton
                text={t('general:create_acount')}
                processing={props.processing ? 1 : 0}
                type="submit"
                size="large"
              />
            </Box>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default RegisterFormView;
