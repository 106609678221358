/* eslint-disable react/prop-types */
import {
  Button, Grid, Modal, Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: '40%',
    height: 90,
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 15,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: 'auto',
  },
}));

export default ({
  title, description, open, setOpen,
}) => {
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };

  const body = (
    <Grid className={classes.paper}>
      <Typography variant="h6">
        { title }
      </Typography>
      <Typography variant="subtitle2">
        { description }
      </Typography>
      <Grid style={{ width: '100%', textAlign: 'end' }}>
        <Button onClick={handleClose} variant="contained" color="primary" style={{ width: 140, height: 30 }}>
          Ok
        </Button>
      </Grid>
    </Grid>
  );

  return (
    <Modal
      open={open}
      onClose={handleClose}
      disableEnforceFocus
      disableAutoFocus
    >
      {body}
    </Modal>
  );
};
