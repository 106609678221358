import ApiClient from '../../common/api/ApiClient';
import Log from '../../../util/Log';
import { PaginatedResponse } from '../../contracts/data/PaginatedResponse';
import { Entity } from '../../contracts/data/Entity';
import { ServiceContract } from '../../contracts/services/ServiceContract';
import BaseGatewayContract from '../../contracts/gateways/BaseGatewayContract';

export default abstract class BaseGateway<T extends Entity>
implements BaseGatewayContract {
  protected service: ServiceContract = null;

  async get(params, companyId: string | null = null): Promise<PaginatedResponse<T>> {
    try {
      const response = await this.service.get(params, this.service.getPath(companyId));
      Log.debug('ApiCategoriesGateway ok getting rows', response);
      return this.parseResponse(response);
    } catch (error) {
      Log.debug('ApiCategoriesGateway error getting rows', error.response);
      throw new Error(ApiClient.parseError(error.response));
    }
  }

  protected parseResponse(response): PaginatedResponse<T> {
    return {
      ...response.data,

      data: response.data.data.map(r => this.parseEntity(r)),
    };
  }

  abstract parseEntity(row: any): T;
}
