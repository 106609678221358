import React from 'react';
import { useDispatch } from 'react-redux';
import { editBrand } from '../../../../actions/brand';
import BrandsPageView from './BrandsPageView';

export default () => {
  const dispatch = useDispatch();
  const openNewBrand = () => {
    dispatch(editBrand());
  };

  return <BrandsPageView openNewBrand={openNewBrand} />;
};
