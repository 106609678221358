import { types } from '../../actions/product/types';

export const initialState = {
  addedSupplier: null,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case types.SAVE_SUPPLIER_SUCCESS: {
      const supplier = {
        id: action.response.data.supplier.id,
        name: action.response.data.supplier.name,
        alias: action.response.data.supplier.alias,
      };
      return {
        ...state,
        addedSupplier: supplier,
      };
    }

    case types.CLEAR_ADDED_SUPPLIER:
      return {
        ...state,
        addedSupplier: null,
      };

    default:
      return state;
  }
};
