import { Typography } from '@mui/material';
import React from 'react';

interface Props {
  text: string;
  align?: 'left' | 'center' | 'right';
}

const SectionText = ({ text, align = null }: Props) => (
  <Typography variant="body2" color="textSecondary" align={align || 'left'}>
    {text}
  </Typography>
);

export default SectionText;
