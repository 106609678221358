/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/prop-types */
import {
  Box, Modal, Typography,
} from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Add from '../../theme/ECTheme/assets/images/Add.svg';
import Collaborative from '../../theme/ECTheme/assets/images/Collaborative.svg';
import multiple_users from '../../theme/ECTheme/assets/images/multiple_users.svg';
import custom_domain from '../../theme/ECTheme/assets/images/custom_domain.svg';
import Transaction from '../../theme/ECTheme/assets/images/Transaction.svg';
import ubications from '../../theme/ECTheme/assets/images/ubications.svg';
import inventoryDark from '../../theme/ECTheme/assets/images/inventoryDark.svg';

const useStyles = makeStyles(theme => ({

  paper: {
    position: 'absolute',
    width: 500,
    height: 650,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #878787',
    borderRadius: 5,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  centeredItems: {
    margin: 20,
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  mainText: {
    color: 'black',
    padding: 11,
  },
  icon: {
    width: 35,
    marginBlock: 10,
  },
  root: {
    width: '100%',
  },
  selectButton: {
    backgroundColor: '#176CFF',
    width: '60%',
    color: '#FFF',
    padding: 10,
    fontWeight: 'bold',
    fontSize: 18,
    border: 'none',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: 2,
  },
}));

export const ModalGetPremium = ({ open, setOpen }) => {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);

  function getModalStyle() {
    return {
      top: `${50}%`,
      left: `${50}%`,
      transform: `translate(-${50}%, -${50}%)`,
    };
  }

  const handleClose = () => {
    setOpen(false);
  };

  const body = (
    <Box style={modalStyle} className={classes.paper}>
      <div className={classes.root}>

        <Typography variant="h5" className={classes.centeredItems} style={{ color: 'black', fontSize: 20 }}>
          Conviértete en Ecubi Premium
        </Typography>
        <Typography variant="h6" className={classes.centeredItems} style={{ color: 'black', fontWeight: 'bold', marginTop: 20 }}>
          Adquiere el Plan Premium y ten acceso a todas las ventajas de Ecubi.
        </Typography>

        <div style={{ display: 'flex', marginBlock: 40 }}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <img src={Transaction} alt="transaction" className={classes.icon} style={{ width: 30 }} />
            <img src={custom_domain} alt="custom-domain" className={classes.icon} style={{ width: 50 }} />
            <img src={ubications} alt="ubications" className={classes.icon} />
            <img src={Add} alt="add" className={classes.icon} />
            <img src={Collaborative} alt="collaborative" className={classes.icon} style={{ marginTop: 12 }} />
            <img src={inventoryDark} alt="inventoryDark" className={classes.icon} style={{ width: 30 }} />
            <img src={multiple_users} alt="multiple-users" className={classes.icon} style={{ width: 40 }} />
          </div>

          <div>
            <Typography variant="subtitle1" className={classes.mainText}>
              Vende online con tu propia tienda.
            </Typography>
            <Typography variant="subtitle1" className={classes.mainText}>
              Personaliza el subdominio de tu negocio.
            </Typography>
            <Typography variant="subtitle1" className={classes.mainText}>
              Gestiona tus zonas y costos de entrega.
            </Typography>
            <Typography variant="subtitle1" className={classes.mainText}>
              Dale opciones a tus clientes con grupos modificadores.
            </Typography>
            <Typography variant="subtitle1" style={{ lineHeight: 1, marginTop: 5 }} className={classes.mainText}>
              Compras colaborativas: Publica cupones, descuentos o un lote
              de tus productos y venderlas a un grupo de clientes.
            </Typography>
            <Typography variant="subtitle1" style={{ lineHeight: 1 }} className={classes.mainText}>
              Controla tu inventario de productos y configura que te notifique
              cuando requieres pedir más a tu proveedor.
            </Typography>
            <Typography variant="subtitle1" style={{ lineHeight: 1 }} className={classes.mainText}>
              Crea múltiples usuarios, gestiona sus roles y monitorea tu negocio.
            </Typography>
          </div>

        </div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

          <Link to="/settings" className={classes.selectButton}>
            Seleccionar un plan
          </Link>
        </div>
      </div>

    </Box>
  );

  return (
    <Modal
      open={open}
      onClose={handleClose}
      disableEnforceFocus
      disableAutoFocus
    >
      {body}
    </Modal>

  );
};
