/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Box, Button, CircularProgress, Divider, Grid, Typography,
} from '@mui/material';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import PlanSubsContext from '../../../../components/Forms/PlansForm/context/PlanSubsContext';
import useRequest from '../../../../hooks/useRequest';

const useStyles = makeStyles(theme => ({
  container: {
    borderRadius: 5,
    border: `4px solid ${theme.colors.grey}`,
    padding: '.5em .8em',
    minWidth: '17.5rem',
    backgroundColor: 'white',
    maxWidth: '100%',
    boxSizing: 'border-box',
    '&:focus': {
      borderColor: '#D7B5FF',
    },
    '&:hover': {
      cursor: 'pointer',
      borderColor: '#D7B5FF',
    },

  },
  card: {
    maxWidth: '100%',
    height: '100%',
    display: 'block',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },

  cardContain: {
    justifySelf: 'center',
    width: '5.5em',
    height: '3.5em',
    border: '1px solid grey',
    borderRadius: 10,
    padding: '.5em',
    margin: '.7em .5em',
  },
  visa: {
    backgroundImage: `url(${theme.icons.visa})`,
  },
  mastercard: {
    backgroundImage: `url(${theme.icons.masterCard})`,
  },
  americanexpress: {
    backgroundImage: `url(${theme.icons.americanExpress})`,
  },
}));

const PaymentMethodItem = ({
  pm, getPaymentMethods, setModalUpCard, setDataUpCard,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { processing } = useRequest({});
  const { value, setValue } = useContext(PlanSubsContext);
  const [idCard, setIdCard] = useState('');
  const setId = useContext(PlanSubsContext);

  const handleSetValue = () => {
    // setIdCard(pm.id)
    setValue(pm.id);
  };

  return (

    <Button className={classes.container} onClick={() => setValue(pm.id)}>
      <Button />
      <Box display="grid" gridTemplateColumns="4fr 3fr" alignItems="center">
        <Grid className={classes.cardContain}>
          <span className={`${classes.card} ${classes[pm.data?.brand.replace('_', '').toLowerCase()]}`} />
        </Grid>
        <Box justifySelf="start">
          <Typography variant="body1">
            {pm.data?.brand.replace('_', ' ').toUpperCase()}
            {' '}
            &#8226;&#8226;&#8226;
            {pm.data.last4}
          </Typography>
          <Typography variant="caption">
            { t('general:expire_in')}
            {' '}
            {`${pm.data.exp_month}/${pm.data.exp_year}`}
          </Typography>
        </Box>
      </Box>

      <Divider />
      <Box>
        <Box>
          <Button onClick={() => setValue(pm.id)} color="primary">
            {processing ? <CircularProgress /> : null }
          </Button>
        </Box>

        {/* <Box>
          <Button onClick={ handleDelete } style={{color: "red"}}>
            {processing? <CircularProgress/> : "Eliminar"}
          </Button>
          <Button onClick={ handleUpdate } color="primary">
            { t("general:edit") }
          </Button>
        </Box> */}
      </Box>
    </Button>
  );
};

export default PaymentMethodItem;
