import { useTranslation } from 'react-i18next';
import { ExcelRenderer } from 'react-excel-renderer';

export const useExcelReader = () => {
  const { t } = useTranslation(['general']);

  const validateExtension = (fileObj) => {
    if (
      fileObj.type === 'application/vnd.ms-excel'
        || fileObj.type
          === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ) {
      return true;
    }

    return false;
  };

  const isExcelValidRow = row => row && row !== 'undefined' && row.length;

  // eslint-disable-next-line max-len
  const readExcel = async (fileObj): Promise<Array<any>> => new Promise((resolve, reject) => {
    if (!validateExtension(fileObj)) {
      reject(t('general:invalid_file'));
    } else {
      ExcelRenderer(fileObj, async (err, resp) => {
        if (err) {
          reject(t('general:error_reading'));
        } else {
          // delete header
          const allRows = resp.rows.slice(1);
          const validExcelRows = [];

          // eslint-disable-next-line no-restricted-syntax
          for await (const row of allRows) {
            if (isExcelValidRow(row)) {
              validExcelRows.push(row);
            }
          }

          resolve(validExcelRows);
        }
      });
    }
  });
  return {
    readExcel,
  };
};
