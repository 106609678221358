/* eslint-disable react/destructuring-assignment */
import React from 'react';
import styles from './ShapeRight.module.css';

interface ShapeProps extends React.HTMLAttributes<HTMLElement> {
  color?: string;
}

const ShapeRight: React.ElementType = (props: ShapeProps) => {
  const color = props.color ? props.color : '#E94436';
  return (
    <div className={`${props.className}`}>
      <div className={`${styles.Wrapper}`}>
        <div
          className={`${styles.ShapeRight}`}
          style={{ backgroundColor: color }}
        />
      </div>
    </div>
  );
};

export default ShapeRight;
