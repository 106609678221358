/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/prop-types */
import {
  Button,
  Checkbox,
  FormControl,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Modal,
  Select,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import Axios from 'axios';
import { makeStyles } from '@mui/styles';
import Box from '../ui/Layout/Box';

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: 500,
    height: 'auto',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #878787',
    borderRadius: 5,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  centeredItems: {
    margin: 20,
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  root: {
    width: '100%',
    display: 'felx',
    flexDirection: 'column',
  },
  containerCard: {
    display: 'flex',
    marginTop: 25,
    marginBottom: 25,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const ModalCategories = ({
  open, setOpen, company, onSubmit, defaultValue, onChange,
}) => {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [categories, setCategories] = useState([company.categories]);

  const getCategories = useCallback(
    () => {
      Axios.get('https://apiqa.ecubi.net/api/v1/categories', {
        params: {
          per_page: 51,
        },
      })
        .then((categories) => {
          setCategories(categories.data.data);
        });
    },
    [],
  );

  useEffect(() => {
    getCategories();
  }, [getCategories]);

  function getModalStyle() {
    return {
      top: `${50}%`,
      left: `${50}%`,
      transform: `translate(-${50}%, -${50}%)`,
    };
  }

  const handleClose = () => {
    setOpen(false);
  };

  const body = (
    <FormControl variant="standard" className={classes.formControl}>
      <InputLabel id="demo-mutiple-checkbox-label">subcategorías</InputLabel>
      <Select
        variant="standard"
        labelId="demo-mutiple-checkbox-label"
        id="demo-mutiple-checkbox"
        multiple
        value={defaultValue}
        renderValue={categories => (categories as any).map(cat => JSON.parse(cat).name).join(', ')}
        onChange={onChange}
        input={<Input />}
        MenuProps={MenuProps}
      >
        {categories.map((category) => {
          const value = JSON.stringify({ name: category.name, id: category.id });
          return (
            <MenuItem key={JSON.stringify({ name: category.name, id: category.id })} value={value}>
              <Checkbox checked={defaultValue.indexOf(value) > -1} />
              <ListItemText primary={category.name} />
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );

  return (
    <Modal
      open={open}
      onClose={handleClose}
      disableEnforceFocus
      disableAutoFocus
    >
      <Box
        className={classes.paper}
        style={modalStyle}
      >
        <form onSubmit={onSubmit}>
          {body}
          <Box
            fullWidth
            display="flex"
            justifyContent="flex-end"
            padding="5px"
          >
            <Button
              type="submit"
              variant="contained"
              color="primary"
            >
              Guardar
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};
