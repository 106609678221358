/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavTabs } from '../../../../components/navigation/NavTabs/NavTabs';
import Grid from '../../../../components/ui/Layout/Grid';
import { PERMISSION, RESOURCES } from '../../../../constants/common';
import PageContainer from '../../../../hoc/PageContainer';
import SectionListContainer from '../../../../hoc/SectionListContainer';
import { useSession } from '../../../../util/session';
import { PaymentsAndSubsPage } from './PaymentsAndSubsPage';
import Profile from './Profile';
import Users from './Users';

export default () => {
  const { t } = useTranslation(['general']);
  const { user, can } = useSession();
  const tabs = [
    {
      id: 0,
      title: t('general:settings:user'),
      component: Profile,
      to: 'perfile',
    },
  ];

  if (can(RESOURCES.users, PERMISSION.read)) {
    tabs.push({
      id: tabs.length,
      title: t('general:settings:users_and_permissions'),
      component: Users,
      to: 'users-permissions',
    });
  }

  if (can(RESOURCES.users, PERMISSION.read)) {
    tabs.push({
      id: tabs.length,
      title: t('Pagos y Suscripciones'),
      component: PaymentsAndSubsPage,
      to: 'payments-subs',
    });
  }

  return (
    <>
      {user && (
        <Grid container>
          <PageContainer>
            <SectionListContainer>
              <NavTabs
                tabs={tabs}
                tabsProps={{
                  centered: true,
                }}
              />
            </SectionListContainer>
          </PageContainer>
        </Grid>
      )}
    </>
  );
};
