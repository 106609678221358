/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
// import 'react-phone-number-input/style.css';
// import PhoneInput from 'react-phone-number-input';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import FormInput from '../../../../../components/ui/FormInput';
import Grid from '../../../../../components/ui/Layout/Grid';

export default ({
  register,
  errors,
  setValue,
  cellphone,
}) => {
  const { t } = useTranslation(['customers']);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormInput
          register={register('name')}
          error={errors.name}
          label={t('customers:name')}
        />
      </Grid>

      <Grid item xs={12}>
        <FormInput
          register={register('last_name')}
          error={errors.last_name}
          label={t('customers:last_name')}
        />
      </Grid>

      <Grid item xs={12}>
        <FormInput
          register={register('phone')}
          error={errors.phone}
          label={t('customers:phone')}
        />
      </Grid>

      <Grid item xs={12}>
        <PhoneInput
          inputStyle={{ width: '100%' }}
          country="mx"
          placeholder="Celular"
          defaultMask="..........."
          value={cellphone}
          enableSearch
          onChange={phone => setValue('cellphone', phone)}
        />
      </Grid>

      <Grid item xs={12}>
        <FormInput
          register={register('email')}
          error={errors.email}
          label={t('customers:email')}

        />
      </Grid>

      <Grid item xs={12}>
        <FormInput
          register={register('address')}
          error={errors.address}
          label={t('customers:address')}
        />
      </Grid>

      <Grid item xs={12}>
        <FormInput
          register={register('comments')}
          error={errors.comments}
          label={t('customers:comments')}
        />
      </Grid>
    </Grid>
  );
};
