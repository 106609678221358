/* eslint-disable import/no-cycle */
import React from 'react';
import FormInputControl from './FormInputControl';
import FormInputRegister from './FormInputRegister';

export interface FormInputProps {
  defaultValue?;
  error?;
  name?: string;
  label?: string;
  placeholder?: string;
  autoComplete?: string;
  maxLength?: number;
  labelWidth?: number;
  rows?: number;
  type?: string;
  onChange?: any;
  selectOptions?;
  labelId?: string;
  className?: string;
  min?: number
}

interface FormInputPropsG extends FormInputProps {
  control?;
  register?;
}

export default (props: FormInputPropsG) => {
  const { register, control, ...rest } = props;
  return register !== null ? (
    <FormInputRegister {...rest} register={register} />
  ) : (
    <FormInputControl {...rest} control={control} />
  );
};
