/* eslint-disable class-methods-use-this */
const KEY = 'key';
export default class LocalServiceKey {
  create(data: any) {
    return localStorage.setItem(KEY, JSON.stringify(data));
  }

  get() {
    const key = localStorage.getItem(KEY);
    if (key !== null) {
      return JSON.parse(key);
    }

    return null;
  }

  clear() {
    console.log('Key', KEY);
    return localStorage.removeItem(KEY);
  }
}
