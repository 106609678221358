import { types } from '../../actions/theme/types';

const initialState = {
  open: false,
  perfile: false,
};
export default (state = initialState, action: any) => {
  switch (action.type) {
    case types.SET_OPEN:
      return {
        ...state,
        open: action.payload,
      };

    case types.SET_PERFILE_OPEN:
      return {
        ...state,
        perfile: action.payload,
      };

    default:
      return state;
  }
};
