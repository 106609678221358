/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-redeclare */
/* eslint-disable @typescript-eslint/dot-notation */
import React from 'react';
import {
  Box, Tab, Tabs, TabsProps,
} from '@mui/material';
import { makeStyles, styled } from '@mui/styles';

interface CustomeTabsProps {
  selectColor: string
}

const CustomeTabs = styled(Tabs)(({ selectColor }: CustomeTabsProps) => ({
  '& .MuiTabs-indicator': {
    backgroundColor: selectColor,
    color: selectColor,
    top: 0,
  },
  '& .Mui-selected': {
    color: selectColor,
  },
  '& .MuiTab-wrapper': {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  '& .MuiTabs-flexContainer': {
    display: 'flex',
    alignItems: 'center',
  },
}));

const useStyle = makeStyles(theme => ({
  productos: {
    mask: `url(${theme.icons.products})`,
  },

  categories: {
    mask: `url(${theme.icons.categories})`,
  },
  cost: {
    mask: `url(${theme.icons.cost})`,
  },
  brands: {
    mask: `url(${theme.icons.brands})`,
  },
  suppliers: {
    mask: `url(${theme.icons.providers})`,
  },
  units: {
    mask: `url(${theme.icons.units})`,
  },
  movements: {
    mask: `url(${theme.icons.movements})`,
  },
  recounts: {
    mask: `url(${theme.icons.recounts})`,
  },
  locations: {
    mask: `url(${theme.icons.locations})`,
  },
  branches: {
    mask: `url(${theme.icons.branches})`,
  },
  clients: {
    mask: `url(${theme.icons.people})`,
  },
  modifiers_groups: {
    mask: `url(${theme.icons.modifiers_groups})`,
    maskSize: '60% auto !important',
  },
  existenceReports: {
    mask: `url(${theme.icons.stocksReport})`,
    maskSize: '66% auto !important',
  },
  movimentReports: {
    mask: `url(${theme.icons.moventsReport})`,
    maskSize: '66% auto !important',

  },
  salesReports: {
    mask: `url(${theme.icons.salesReports})`,
    maskSize: '66% auto !important',

  },
  marketInformation: {
    mask: `url(${theme.icons.shoppingOnline})`,
    maskSize: '60% auto !important',
  },
  marketOpinions: {
    mask: `url(${theme.icons.opinions})`,
    maskSize: '60% auto !important',
  },
  marketSales: {
    mask: `url(${theme.icons.ecommerceBag})`,
    maskSize: '60% auto !important',
  },
  sharedCampaigns: {
    mask: `url(${theme.icons.sharedCampaigns})`,
    maskSize: '60% auto !important',
  },
  client: {
    mask: `url(${theme.icons.clients})`,
    maskSize: '60% auto !important',
  },
  marketFinances: {
    mask: `url(${theme.icons.financesStore})`,
    maskSize: '60% auto !important',
  },
  pickerZones: {
    mask: `url(${theme.icons.pickerZones})`,
    maskSize: '50% auto !important',
  },
  span: {
    display: 'block',
    width: 50,
    height: 50,
    maskSize: 'auto',
    maskRepeat: 'no-repeat',
    maskOrigin: 'border-box',
    maskPosition: 'center',
  },
  appBarStyle: {
    boxShadow: 'none',
    borderTop: '1px solid',
    borderTopColor: props => props['colorBar'],
  },
}));

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const TabPanel = (props: TabPanelProps) => {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
};

interface Tab {
  color,
  title,
  render,
  index,
  icon,
}

interface Props {
  data: Tab[]
  selectColor?: string
  colorBar?: string
}

export const MenuTabs = ({ data, selectColor = '#000', colorBar = '#000' }: Props) => {
  const classes = useStyle({ colorBar });

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: any) => {
    setValue(newValue);
  };
  return (
    <div>
      <Box
        className={classes.appBarStyle}
        color="inherit"
      >

        <CustomeTabs
          value={value}
          onChange={handleChange as any}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
          selectColor={selectColor}
        >
          {
            data.map((tab) => {
              const isSelect = value === tab.index;
              return (
                <Tab
                  icon={(
                    <span
                      style={{ backgroundColor: isSelect ? selectColor : 'grey' }}
                      className={`${classes[tab.icon]} ${classes.span}`}
                    />
                    )}
                  key={tab.index}
                  label={tab.title}
                />
              );
            })
          }

        </CustomeTabs>
      </Box>
      {
        data.map(tab => (
          <TabPanel key={tab.index} value={value} index={tab.index}>
            {' '}
            {tab.render}
            {' '}
          </TabPanel>
        ))
        }
    </div>
  );
};
