/* eslint-disable react/prop-types */
import {
  Button, FormControlLabel, Checkbox,
} from '@mui/material';
import React, { useState } from 'react';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CreateIcon from '@mui/icons-material/Create';
import Axios from 'axios';
import { makeStyles } from '@mui/styles';
import Map from '../../../containers/pages/admin/StorePage/SharedCampaignsPage/CampaignWizard/ZonesSection/Map';
import Text from '../../Text/Text';
import Box from '../Layout/Box';
import { ModalMapsChange } from '../../ModalMapsChange';
import { DEFAULT_COORDINATES } from '../../../constants/common';
import { BASE_URL } from '../../../constants/api/ApiConstants';

const useStyles = makeStyles(() => ({
  containerSection: {
    minWidth: '15rem',
  },
  headerNIcon: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10,
  },
}));

export const UbicationStoreCard = ({ location, company, onChangeChecked }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [address, setAddress] = React.useState('');
  const [checked, setChecked] = useState(company.show_address);
  const [coordinates, setCoordinates] = React.useState({
    lat: location?.coordinates[1] || DEFAULT_COORDINATES.lat,
    lng: location?.coordinates[0] || DEFAULT_COORDINATES.lng,
  });

  const handleChangeAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
    const statusAddress = {
      show_address: !!event.target.checked,
    };
    setChecked(event.target.checked);

    Axios.put(`${BASE_URL}/api/v1/companies/${company.id}`, statusAddress)
      .then(() => {
        onChangeChecked(statusAddress);
      });
  };

  const handleOpen = () => setOpen(true);

  return (
    <Box mt={4}>
      <Box className={classes.containerSection}>
        <div className={classes.headerNIcon}>
          <div>
            <LocationOnIcon />
          </div>
          <div>
            <Text onClick={handleOpen}><b>Ubicación</b></Text>
          </div>
          <div>
            <Button onClick={handleOpen}><CreateIcon fontSize="small" /></Button>
          </div>
        </div>
        <div>
          <FormControlLabel
            control={(
              <Checkbox
                checked={checked}
                onChange={handleChangeAddress}
                name="checkedB"
                color="primary"
              />
                          )}
            label="Mostrar ubicación de mi tienda online"
          />
        </div>
        <label htmlFor="calle">{address}</label>
        <Map
          zoom={16}
          disableDefaultUI
          clickableMarker={false}
          draggableMarker={false}
          draggableMap={false}
          clickableIcons={false}
          onLocationChanged={(fullAddress) => { setAddress(fullAddress); }}
          onLoadLocation={(fullAddress) => { setAddress(fullAddress); }}
          zone={coordinates}
        />
      </Box>
      <ModalMapsChange
        location={location}
        open={open}
        setOpen={setOpen}
        onChangeAddress={(newAdress) => {
          setAddress(newAdress.address);
          setCoordinates(newAdress.coordinates);
        }}
      />
    </Box>

  );
};
