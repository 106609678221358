import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PasswordFormView from './PasswordFormView';

import {
  closeModalPassword,
  savePassword,
} from '../../../../../../actions/password';

export default () => {
  const dispatch = useDispatch();
  const { error, processing, showModal } = useSelector(
    (state: any) => state.password,
  );

  const handleClose = () => {
    dispatch(closeModalPassword());
  };

  const attemptSavePassword = async (data) => {
    await dispatch(savePassword(data));
  };

  return (
    <PasswordFormView
      attemptSavePassword={data => attemptSavePassword(data)}
      processing={processing}
      error={error}
      handleClose={handleClose}
      showModal={showModal}
    />
  );
};
