/* eslint-disable no-restricted-syntax */
// import FormData from 'form-data';
import { types } from './types';
import Log from '../../util/Log';
import AuthorizedApiClient from '../../core/common/api/AuthorizedApiClient';

export const editProduct = (product = null) => ({
  type: types.EDIT_PRODUCT,
  payload: product,
});

export const clearAddedCategory = () => ({
  type: types.CLEAR_ADDED_CATEGORY,
});

export const clearAddedBrand = () => ({
  type: types.CLEAR_ADDED_BRAND,
});

export const clearAddedUnit = () => ({
  type: types.CLEAR_ADDED_UNITOFMEASURE,
});

export const clearAddedSupplier = () => ({
  type: types.CLEAR_ADDED_SUPPLIER,
});

export const savingProduct = () => ({
  type: types.SAVING_PRODUCT,
});

export const saveProductSucceed = response => ({
  type: types.SAVE_PRODUCT_SUCCESS,
  response,
});

export const saveProductFailed = error => ({
  type: types.SAVE_PRODUCT_FAILURE,
  error,
});

export const deletingProduct = () => ({
  type: types.DELETING_PRODUCT,
});

export const deleteProductSucceed = response => ({
  type: types.DELETE_PRODUCT_SUCCESS,
  response,
});

export const deleteProductFailed = error => ({
  type: types.DELETE_PRODUCT_FAILURE,
  error,
});

export const closeModalProduct = () => ({
  type: types.CLOSE_PRODUCT_MODAL,
});

export const setProductTab = tab => ({
  type: types.SET_PRODUCT_TAB,
  tab,
});
export const getProduct = (id, companyId) => async (
  dispatch: any,
) => {
  const sg = new AuthorizedApiClient();
  const path = `companies/${companyId}/products/${id}`;

  try {
    const response = await sg.get({}, path);

    if (response != null) {
      dispatch({ type: types.SET_PRODUCT_SUCCESS, product: response.data.product });
    }
  } catch (error) {
    dispatch({ type: types.SET_PRODUCT_FAILURE, erro: error });
  }
};

export const saveProduct = (_data, companyId) => async (dispatch: any) => {
  dispatch(savingProduct());
  const sg = new AuthorizedApiClient();
  let path = `companies/${companyId}/products`;

  try {
    let response;
    // let data;
    const { id } = _data;
    const data = _data;

    // if (file !== null) {
    //   data = new FormData();
    //   for (const key in _data) {
    //     if (key !== 'id') {
    //       data.append(key, _data[key]);
    //     }
    //   }
    //   data.append('image', file);
    // } else {
    //   if (id && _data.image) {
    //     _data.image = '';
    //   } else {
    //     delete _data.image;
    //   }
    //   data = _data;
    // }

    if (id != null) {
      path = `${path}/${id}`;
      delete data.id;
      response = await sg.put(data, path);
    } else {
      delete data.id;
      response = await sg.post(data, path);
      //   {
      //   'Content-Type': 'multipart/form-data',
      // }
    }

    if (response != null) {
      Log.debug('saved product', response);
      dispatch(saveProductSucceed(response));
      dispatch(closeModalProduct());
    }
  } catch (error) {
    Log.error(error.message);
    dispatch(saveProductFailed(error.message));
  }
};

export const deleteProduct = (product, companyId) => async (dispatch: any) => {
  dispatch(deletingProduct());
  const sg = new AuthorizedApiClient();
  let path = `companies/${companyId}/products`;
  try {
    if (!product.id) {
      throw new Error('The id is required');
    }

    path = `${path}/${product.id}`;
    const response = await sg.delete(path);

    if (response != null) {
      Log.debug('deleted product', response);
      dispatch(deleteProductSucceed(response));
      dispatch(closeModalProduct());
    }
  } catch (error) {
    Log.error(error.message);
    dispatch(deleteProductFailed(error.message));
    throw error;
  }
};

export const getSuppliersForProduct = (companyId, params = {}) => async (
  dispatch: any,
) => {
  const sg = new AuthorizedApiClient();
  const path = `companies/${companyId}/suppliers`;
  dispatch({ type: types.SET_SUPPLIERS_PRODUCT_PROCESSING, payload: true });
  try {
    const response = await sg.get(params, path);

    if (response != null) {
      dispatch({ type: types.SET_SUPPLIERS_PRODUCT_SUCCESS, response: response.data });
    }
  } catch (error) {
    dispatch({ type: types.SET_SUPPLIERS_PRODUCT_FAILURE, error });
  }
};

export const getCategoriesForProduct = (companyId, params = {}) => async (
  dispatch: any,
) => {
  const sg = new AuthorizedApiClient();
  const path = `companies/${companyId}/categories`;
  dispatch({ type: types.SET_CATEGORIES_PRODUCT_PROCESSING, payload: true });
  try {
    const response = await sg.get(params, path);

    if (response != null) {
      dispatch({ type: types.SET_CATEGORIES_PRODUCT_SUCCESS, response: response.data });
    }
  } catch (error) {
    dispatch({ type: types.SET_CATEGORIES_PRODUCT_FAILURE, error });
  }
};

export const getBrandsForProduct = (companyId, params = {}) => async (
  dispatch: any,
) => {
  const sg = new AuthorizedApiClient();
  const path = `companies/${companyId}/brands`;
  dispatch({ type: types.SET_BRANDS_PRODUCT_PROCESSING, payload: true });
  try {
    const response = await sg.get(params, path);

    if (response != null) {
      dispatch({ type: types.SET_BRANDS_PRODUCT_SUCCESS, response: response.data });
    }
  } catch (error) {
    dispatch({ type: types.SET_BRANDS_PRODUCT_FAILURE, error });
  }
};

export const getUnitsForProduct = (companyId, params = {}) => async (
  dispatch: any,
) => {
  const sg = new AuthorizedApiClient();
  const path = `companies/${companyId}/unitofmeasures`;
  dispatch({ type: types.SET_UNITOFMEASURES_PRODUCT_PROCESSING, payload: true });
  try {
    const response = await sg.get(params, path);

    if (response != null) {
      dispatch({ type: types.SET_UNITOFMEASURES_PRODUCT_SUCCESS, response: response.data });
    }
  } catch (error) {
    dispatch({ type: types.SET_UNITOFMEASURES_PRODUCT_FAILURE, error });
  }
};
