import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import BreadCrumbs from '../../../../../../components/ui/BreadCrumbs';
import ContentHeader from '../../../../../../components/ui/ContentHeader';
import Box from '../../../../../../components/ui/Layout/Box';
import Grid from '../../../../../../components/ui/Layout/Grid';
import PageContainer from '../../../../../../hoc/PageContainer';
import SectionListContainer from '../../../../../../hoc/SectionListContainer';
import OrderDetails from '../OrderDetails';

export default () => {
  const { t } = useTranslation(['general']);
  const { id } = useParams<any>();
  const { order } = useSelector((state: any) => state.order);
  return (
    <Box>
      <Grid container>
        <Grid item xs={12}>
          <ContentHeader
            title={t('general:store_page:my_store')}
            section="store"
            back
          />
        </Grid>
        <PageContainer>
          <BreadCrumbs title={order ? order.number : ''} />

          <SectionListContainer>
            <OrderDetails id={id} />
          </SectionListContainer>
        </PageContainer>
      </Grid>
    </Box>
  );
};
