/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MovementsListView from './MovementsListView';
import {
  clearRefresh,
  getMovements,
  resetMovements,
  setParams,
} from '../../../../../actions/movements';

export default () => {
  const dispatch = useDispatch();
  const state = useSelector((states: any) => states.movements);
  const { refresh } = state;
  const company = useSelector((states: any) => states.session.company);
  const { params } = state;

  const _getMovements = useCallback(
    async (_params = {}) => {
      if (company) {
        const updatedParams = { ...params, ..._params };
        // console.log("updatedParams", updatedParams)
        dispatch(setParams(updatedParams));

        await dispatch(getMovements(company.id, updatedParams));
      }
    },
    [dispatch, company, params],
  );

  useEffect(() => {
    if (refresh && company) {
      dispatch(resetMovements());
      dispatch(clearRefresh());
      _getMovements();
    }
  }, [refresh, company, dispatch, _getMovements]);

  return (
    <MovementsListView
      state={state}
      getMovements={_getMovements}
      resetMovements={resetMovements || refresh}
    />
  );
};
