/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Button,
  CircularProgress,
  Collapse,
  IconButton,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import CreateIcon from '@mui/icons-material/Create';
import Axios from 'axios';
import PhoneIcon from '@mui/icons-material/Phone';
import PublicIcon from '@mui/icons-material/Public';
import SubjectSharpIcon from '@mui/icons-material/SubjectSharp';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import ScheduleIcon from '@mui/icons-material/Schedule';
import CloseIcon from '@mui/icons-material/Close';
import { Link } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import Box from '../../../../../components/ui/Layout/Box';
import Grid from '../../../../../components/ui/Layout/Grid';
import PageContainer from '../../../../../hoc/PageContainer';
import { rules as validationRules } from '../../../../../util/Validations';
import StoreImageUploader from '../../../../../components/ui/StoreImageUploader';
import { FEATURES_TYPE, PERMISSION, RESOURCES } from '../../../../../constants/common';
import ShippingMethodsSection from './ShippingMethodsSection';
import PublishedSwitch from './PublishedSwitch';
import { useSession } from '../../../../../util/session';
import { StoreOptionscard } from '../../../../../components/ui/StoreOptionscard';
import { DeliveryZoneConfig } from '../../../../../components/ui/DeliveryZoneConfig';
import { UbicationStoreCard } from '../../../../../components/ui/UbicationStoreCard';
import Text from '../../../../../components/Text/Text';
import { BASE_URL, MARKETPLACE_URL } from '../../../../../constants/api/ApiConstants';
import { ModalInputs } from '../../../../../components/ModalInputs';
import { ModalGetPremium } from '../../../../../components/ModalGetPremium';
import { ModalPaymentSelect } from '../../../../../components/ModalPaymentSelect';
import { ModalBusinessHours } from '../../../../../components/ModalBusinessHours';

const useStyles = makeStyles(theme => ({
  avatarContainer: {
    width: '170px',
    height: '170px',
  },
  Image: {
    width: '100px',
    height: '60px',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  containerSection: {
    borderRadius: 5,
    border: `1px solid ${theme.colors.grey}`,
    padding: '.5em 1em',
    display: 'flex',
    flexDirection: 'column',
  },
  paymentMethod: {
    display: 'flex',
    flexDirection: 'column',

  },
  containerCards: {
    display: 'flex',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
    marginBottom: 15,
  },
  nameNSwitchSpace: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    marginBottom: 15,
  },
}));

export default ({
  company,
}) => {
  const { t } = useTranslation('general');
  const [profileImage, setProfileImage] = useState(null);
  const classes = useStyles();
  const [openModalUrl, setOpenModalUrl] = React.useState(false);
  const [openModalName, setopenModalName] = React.useState(false);
  const [openModalDescription, setopenModalDescription] = React.useState(false);
  const [openModalPhone, setopenModalPhone] = React.useState(false);
  const [openModalPremium, setOpenModalPremium] = useState(false);

  const [urlName, setUrlName] = React.useState(company.code);
  const [nameStore, setNameStore] = useState(company.name);
  const [descriptionStore, setDescriptionStore] = useState(company.description);
  const [phoneStore, setPhoneStore] = useState(company.phone);
  const [OpenModalPayment, setOpenModalPayment] = useState(false);
  const [OpenModalSchedule, setOpenModalSchedule] = useState(false);

  const [selectMenu, setSelectMenu] = useState(false);
  const [load, setLoad] = useState(false);
  const [deliveryZone, setDeliveryZone] = useState();
  const [visibleAddress, setVisibleAddress] = useState(company.show_address);
  const [paymentState, setPaymentState] = useState(company.payment_methods.length);
  const [open, setOpen] = useState(true);
  const [published, setPublished] = useState(company.published);
  const [canShipping, setCanshipping] = useState(company.shipping_methods || []);

  const handleOpenPaymentMeth = () => setOpenModalPayment(true);

  const { can } = useSession();

  const canSubdomain = can(
    RESOURCES.store_profile,
    PERMISSION.access,
    FEATURES_TYPE.custom_subdomain,
  );

  const handleOpenModalPremium = () => setOpenModalPremium(true);

  const handleOpenModaSchedule = () => setOpenModalSchedule(true);

  const handleOpenModalUrl = () => setOpenModalUrl(true);

  const handleOpenModalName = () => setopenModalName(true);

  const handleOpenModalDescription = () => setopenModalDescription(true);

  const handleOpenModalPhone = () => setopenModalPhone(true);

  const handleChangeImage = () => {
    setLoad(true);
    const url = `${BASE_URL}/api/v1/companies/${company.id}`;
    const formData = new FormData();
    formData.append('image', profileImage);
    Axios.put(url, formData)
      .finally(() => {
        setLoad(false);
        setSelectMenu(false);
      });
  };

  const getDeliveryData = useCallback(
    async () => {
      Axios.get(`${BASE_URL}/api/v1/companies/${company.id}/deliveryzones`)
        .then((res) => {
          setDeliveryZone(res.data.data.data.length);
        });
    },
    [company.id],
  );

  useEffect(() => {
    getDeliveryData();
  }, [getDeliveryData]);

  const handleChangeUrlName = (event: React.ChangeEvent<HTMLFormElement>, code: string) => {
    event.preventDefault();
    Axios.put(`${BASE_URL}/api/v1/companies/${company.id}`, { code })
      .then(() => {
        setUrlName(code);
        setOpenModalUrl(false);
      })
      .catch(err => console.error(err));
  };

  const handleSubmitName = (event: React.ChangeEvent<HTMLFormElement>, name: string) => {
    event.preventDefault();
    Axios.put(`${BASE_URL}/api/v1/companies/${company.id}`, { name })
      .then(() => {
        setNameStore(name);
        setopenModalName(false);
      })
      .catch(err => console.error(err));
  };

  const handleChangeDescription = (
    event: React.ChangeEvent<HTMLFormElement>,
    description: string,
  ) => {
    event.preventDefault();
    Axios.put(`${BASE_URL}/api/v1/companies/${company.id}`, { description })
      .then(() => {
        setDescriptionStore(description);
        setopenModalDescription(false);
      })
      .catch(err => console.error(err));
  };

  const handleChangePhone = (event: React.ChangeEvent<HTMLFormElement>, phone: string) => {
    event.preventDefault();
    Axios.put(`${BASE_URL}/api/v1/companies/${company.id}`, { phone })
      .then(() => {
        setPhoneStore(phone);
        setopenModalPhone(false);
      })
      .catch(err => console.error(err));
  };

  const schema = Yup.object({
    name: Yup.string()
      .max(validationRules.maxLength)
      .required(
        t('translation:required', {
          input: t('settings:name'),
        }),
      ),
    description: Yup.string().max(validationRules.maxLength),
    code: Yup.string()
      // .max(validationRules.maxLength)
      .matches(
        validationRules.companyCode,
        t('general:store_page:invalid_company_code_format'),
      ),
    default_shop_theme: Yup.string().max(validationRules.maxLength),
    deliveries_duration: Yup.number().min(1, t('general:store_page:error_deliveries_duration')),
    published: Yup.boolean(),
    delivery_price: Yup.number().min(0, t('general:store_page:error_delivery_price')),
    delivery_comments: Yup.string().max(validationRules.maxLength),
    buy_in_shop_comments: Yup.string().max(validationRules.maxLength),
    shop_pickup_comments: Yup.string().max(validationRules.maxLength),
  });
  const { register, formState: { errors } } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),

  });

  const imgUrl = company && company.image_url ? company.image_url : '';

  return (
    <Grid container>
      <PageContainer>

        <div style={{ marginBottom: '5px' }}>
          {
            published
              ? null
              : (
                <Collapse in={open}>
                  <Alert
                    severity="warning"
                    action={(
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setOpen(false);
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                  )}
                  >
                    Recuerda que para poder publicar tu tienda,
                    tienes que tener, seleccionado un
                    {' '}
                    <b>metodo de envío</b>
                    , un
                    {' '}
                    <b>metodo de pago</b>
                    {' '}
                    y al menos un
                    {' '}
                    <b>producto publicado</b>
                  </Alert>
                </Collapse>
              )
          }

        </div>
        <div style={{
          display: 'flex', alignItems: 'center', flexDirection: 'row-reverse', marginBottom: '5px',
        }}
        >
          {
            visibleAddress && canShipping.length !== 0
              ? (
                <PublishedSwitch
                  setPublished={setPublished}
                  register={register}
                  company={company}
                  paymentState={paymentState}
                />
              )
              : <Text>Debes seleccionar un método de envío</Text>
          }

          <div style={{ marginRight: '10px' }}>
            <Link to={{ pathname: company.marketplace_url }} target="_blank">
              <Button
                variant="contained"
                color="primary"
              >
                {t('general:store_page:preview')}
              </Button>
            </Link>
          </div>
        </div>

        <Box className={classes.containerSection}>
          <div className={classes.nameNSwitchSpace} style={{ alignItems: 'center' }}>
            <Box className={classes.avatarContainer}>
              <StoreImageUploader
                selectMenu={selectMenu}
                imgUrl={imgUrl}
                setImage={setProfileImage}
              />
            </Box>
            <div style={{ display: 'flex', justifyContent: 'spaceAround' }}>
              <Button
                onClick={handleChangeImage}
                variant="contained"
                color="primary"
              >
                {
                    load
                      ? <CircularProgress color="secondary" size={25} />
                      : 'Guardar imagen'
                  }
              </Button>
            </div>
          </div>

          <div className={classes.nameNSwitchSpace}>
            <Typography component="span" variant="h5" color="textPrimary" align="center">
              {nameStore}
            </Typography>
            <Button onClick={handleOpenModalName}>
              {' '}
              <CreateIcon fontSize="small" />
            </Button>
          </div>

          <div className={classes.nameNSwitchSpace}>
            <div style={{ display: 'flex', alignItems: 'center', marginRight: 5 }}>
              <div style={{ marginRight: 5 }}>
                <PublicIcon />
              </div>
              {urlName}
              .
              {MARKETPLACE_URL}
            </div>
            {
              canSubdomain
                ? (
                  <Button
                    type="submit"
                    onClick={handleOpenModalUrl}
                  >
                    <CreateIcon fontSize="small" />
                  </Button>
                )
                : (
                  <Button
                    type="submit"
                    onClick={handleOpenModalPremium}
                  >
                    <CreateIcon fontSize="small" />
                  </Button>
                )
            }
          </div>

          <div className={classes.nameNSwitchSpace}>
            <div
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <div style={{ marginRight: 5 }}>
                <SubjectSharpIcon />
              </div>
              <Text> Descripcion de la tienda</Text>
            </div>
            <Button
              onClick={handleOpenModalDescription}
            >
              <CreateIcon fontSize="small" />
            </Button>
            {descriptionStore}
          </div>

          <div className={classes.nameNSwitchSpace}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ marginRight: 5 }}>
                <PhoneIcon />
              </div>
              <Text>
                {' '}
                Teléfono
                {' '}
                {phoneStore}
              </Text>
            </div>
            <Button
              onClick={handleOpenModalPhone}
            >
              <CreateIcon fontSize="small" />
            </Button>
          </div>

          <div className={classes.nameNSwitchSpace}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ marginRight: 5 }}>
                <CreditCardIcon />
              </div>
              <Text>Método de pago aceptado</Text>
            </div>
            <Button
              onClick={handleOpenPaymentMeth}
            >
              <CreateIcon fontSize="small" />
            </Button>
          </div>

          <div className={classes.nameNSwitchSpace}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ marginRight: 5 }}>
                <ScheduleIcon />
              </div>
              <Text>Horario de servicio</Text>
            </div>
            <Button
              onClick={handleOpenModaSchedule}
            >
              <CreateIcon fontSize="small" />
            </Button>
          </div>

          <div>
            <UbicationStoreCard
              company={company}
              location={company.branches[0]?.location}
              onChangeChecked={(showAddress) => {
                setVisibleAddress(showAddress.show_address);
              }}
            />
          </div>

          <Box mt={3}>
            <ShippingMethodsSection
              setCanshipping={setCanshipping}
              visibleAddress={visibleAddress}
              deliveryZone={deliveryZone}
              company={company}
            />
          </Box>

          <Box mt={3}>
            <DeliveryZoneConfig />
          </Box>

          <div>
            <StoreOptionscard company={company} />
          </div>

        </Box>
        <ModalInputs
          onSubmit={handleSubmitName}
          type="text"
          label="Nombre de la empresa"
          open={openModalName}
          setOpen={setopenModalName}
          defaultValue={nameStore}
        />
        <ModalInputs
          onSubmit={handleChangeUrlName}
          type="direccion"
          label="Subdominio personalizado"
          open={openModalUrl}
          setOpen={setOpenModalUrl}
          defaultValue={urlName}
        />
        <ModalInputs
          onSubmit={handleChangeDescription}
          multiline
          type="textarea"
          label="Describe tu empresa a los clientes"
          open={openModalDescription}
          setOpen={setopenModalDescription}
          defaultValue={descriptionStore}
        />
        <ModalInputs
          onSubmit={handleChangePhone}
          type="phoneNumber"
          label="Número de teléfono"
          open={openModalPhone}
          setOpen={setopenModalPhone}
          defaultValue={phoneStore}
        />
        <ModalGetPremium
          open={openModalPremium}
          setOpen={setOpenModalPremium}
        />

        <ModalPaymentSelect
          open={OpenModalPayment}
          setOpen={setOpenModalPayment}
          company={company}
          onChangePayment={(paymetMethodState) => {
            setPaymentState(paymetMethodState.payment_methods.length);
          }}
        />

        <ModalBusinessHours
          open={OpenModalSchedule}
          setOpen={setOpenModalSchedule}
          company={company}
        />
      </PageContainer>
    </Grid>
  );
};
