/* eslint-disable react/prop-types */
import React from 'react';

import { useTranslation } from 'react-i18next';
import ImageUploader from '../../../../../components/ui/ImageUploader';
import Box from '../../../../../components/ui/Layout/Box';
import Grid from '../../../../../components/ui/Layout/Grid';
import FormInput from '../../../../../components/ui/FormInput';
import AddressInput from './AddressInput';

const RawBranchForm = ({
  branch,
  errors,
  setImage,
  register,
  setValueForm,
//   onSubmit,
}) => {
  const { t } = useTranslation([
    'branches',
    'translation',
    'general',
    'validation',
  ]);

  const imgUrl = branch.image_url ? branch.image_url : null;

  return (
    <form autoComplete="off">
      <Grid container justifyContent="center" alignItems="center">
        <Box height="250px" width="250px" mb={3}>
          <ImageUploader
            imgUrl={imgUrl}
            setImage={setImage}
            text={t('branches:add_image')}
          />
        </Box>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormInput
            register={register('name')}
            error={errors.name}
            label={t('branches:branch_name')}
            defaultValue={branch.name ? branch.name : ''}
          />
        </Grid>

        <Grid item xs={12}>

          <AddressInput
            register={register('address')}
            setValueForm={setValueForm}
            error={errors.address}
            label={t('branches:address')}
            defaultCoordinates={
                branch.location ? branch.location.coordinates : null
              }
          />
        </Grid>

        <Grid item xs={12}>
          <FormInput
            register={register('phone')}
            error={errors.phone}
            label={t('branches:phone')}
            defaultValue={branch.phone ? branch.phone : ''}
          />
        </Grid>

        <Grid item xs={12}>
          <FormInput
            register={register('email')}
            error={errors.email}
            label={t('branches:email')}
            defaultValue={branch.email ? branch.email : ''}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default RawBranchForm;
