/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/prop-types */
import React from 'react';
import Box from '../../../../../../../../components/ui/Layout/Box';
import { generateKey } from '../../../../../../../../util/keys';
import Section from './Section';

export default ({ user, sections, setPermission }) => {
  const list = [];
  Object.entries(sections).forEach(([code]) => {
    list.push(
      <Box key={generateKey(code)} mb={2}>
        <Section
          subsections={sections[code]}
          code={code}
          user={user}
          setPermission={setPermission}
        />
      </Box>,
    );
  });
  return <>{list}</>;
};
