import React from 'react';
import { makeStyles } from '@mui/styles';
import Box from '../../../../../../components/ui/Layout/Box';

const useKPIStyles = makeStyles(theme => ({
  wrapper: {
    height: '100px',
    borderRadius: theme.spacing(1),
    backgroundColor: 'white',
  },
}));

interface Props {
  children: React.ReactNode;
}

const KPIWrapper = ({ children }: Props) => {
  const classes = useKPIStyles();
  return (
    <Box boxShadow={3} className={classes.wrapper} p={1}>
      {children}
    </Box>
  );
};

export default KPIWrapper;
