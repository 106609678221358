/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModifiersGroupsListView from './ModifiersGroupsListView';
import {
  getModifiersGroups,
  resetModifiersGroups,
} from '../../../../../actions/modifiers_groups';
import { SCROLL_PER_PAGE } from '../../../../../constants/common';
import { useSession } from '../../../../../util/session';

export default () => {
  const dispatch = useDispatch();
  const state = useSelector((states: any) => states.modifiers_groups);
  const { company } = useSession();

  const _getModifiersGroups = useCallback(
    async (params: any = {}) => {
      if (company) {
        params.per_page = SCROLL_PER_PAGE;
        await dispatch(getModifiersGroups(company.id, params));
      }
    },
    [dispatch, company],
  );

  const _resetModifierGroups = useCallback(() => {
    dispatch(resetModifiersGroups());
  }, [dispatch]);

  return (
    <ModifiersGroupsListView
      state={state}
      getModifierGroups={params => _getModifiersGroups(params)}
      resetModifierGroups={_resetModifierGroups}
    />
  );
};
