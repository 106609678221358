/* eslint-disable react/prop-types */
import React from 'react';
import { DatePicker as MUIDatepicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TextField } from '@mui/material';

interface Props {
  date?: Date;
  label?: String;
  handleChange?(date: Date, value?: string): void
}

export const DatePicker = ({
  date = null,
  label = '',
  handleChange,
}: Props) => (
  <LocalizationProvider dateAdapter={AdapterDateFns}>
    <MUIDatepicker
      showToolbar={false}
      inputFormat="yyyy/MM/dd"
      value={date}
      onChange={handleChange}
      disableFuture
      // style={{ width: '95%', padding: 0 }}
      renderInput={params => (
        <TextField {...params} variant="standard" label={label} />
      )}
    />
  </LocalizationProvider>
);
