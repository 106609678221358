/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { CircularProgress, TextField } from '@mui/material';
import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '../Layout/Box';
// import { useTranslation } from "react-i18next";

export default ({
  error = null,
  control = null,
  defaultValue = null,
  getData,
  name,
  customName = '',
  options = [],
  loading = false,
  label = '',
}) => {
  const [filter, setFilter] = useState('');
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    if (open) {
      const params = {};
      if (filter) {
        params['filter'] = filter;
      }
      getData(params);
    }
  }, [getData, filter, open]);

  return (
    <Box>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field: { onChange, ...rest } }) => (
          <Autocomplete
            onChange={(e, opt) => onChange({ target: { value: (opt as any).value } })}
            inputValue={filter}
            onInputChange={(event, newInputValue) => {
              setFilter(newInputValue);
            }}
            open={open}
            onOpen={() => {
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);
            }}
            isOptionEqualToValue={(option, value) => option.label === value.label}
            getOptionLabel={option => option.label}
            options={options}
            loading={loading}
            renderInput={params => (
              <TextField
                {...params}
                error={!!error}
                helperText={error ? error.message : null}
                label={label}
                variant="outlined"
                fullWidth
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        )}
      />

    </Box>
  );
};
