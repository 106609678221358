/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  downloadCustomers, getCustomers, resetCustomers, setParams,
} from '../../../../../actions/customers';
import { ViewFormats } from '../../../../../constants/common';
import CustomersListView from './CustomersListView';

export default () => {
  const dispatch = useDispatch();
  const state = useSelector((states: any) => states.customers);
  const { refresh, params } = state;
  const company = useSelector((states: any) => states.session.company);

  const _getCustomers = useCallback(
    async (_params = {}) => {
      if (company) {
        const updatedParams = { ...params, ..._params };
        dispatch(setParams(updatedParams));
        await dispatch(getCustomers(company.id, updatedParams));
      }
    },
    [dispatch, company, params],
  );

  const _downloadCustomers = useCallback(
    async () => {
      if (company) {
        const updatedParams = { ...params, format: ViewFormats.EXCEL };

        await dispatch(downloadCustomers(company.id, updatedParams));
      }
    },
    [dispatch, company, params],
  );

  useEffect(() => {
    if (refresh) {
      dispatch(resetCustomers());
      _getCustomers();
    }
  }, [refresh, _getCustomers, dispatch]);

  const _resetCustomers = useCallback(() => {
    dispatch(resetCustomers());
  }, [dispatch]);

  useEffect(() => () => {
    _resetCustomers();
  }, [_resetCustomers]);

  return (
    <CustomersListView
      state={state}
      getCustomers={_getCustomers}
      resetCustomers={_resetCustomers}
      downloadCustomers={_downloadCustomers}
    />
  );
};
