/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import FormInput from '../../../../../components/ui/FormInput';
import Grid from '../../../../../components/ui/Layout/Grid';
import TypeSwitch from './TypeSwitch';
import UnitSelector from './UnitSelector';

const RawModifierForm = ({ register, errors }) => {
  const { t } = useTranslation(['general']);
  return (
    <form autoComplete="off">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormInput
            register={register('name')}
            error={errors.name}
            label={t('general:name')}
          />
        </Grid>

        <Grid item xs={12}>
          <FormInput
            register={register('price')}
            error={errors.price}
            label={t('general:price')}
            type="number"
          />
        </Grid>

        <Grid item xs={12}>
          <TypeSwitch register={register} />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <UnitSelector register={register} error={errors.unitofmeasure_id} />
      </Grid>

    </form>
  );
};

export default RawModifierForm;
