/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import SuccessButton from '../../../../../../components/ui/Buttons/SuccessButton';
import Grid from '../../../../../../components/ui/Layout/Grid';

interface ActionsProps {
  handleClose: Function;
  handleSubmit: Function;
  processing: boolean;
}

export default ({ handleSubmit, processing }: ActionsProps) => {
  const { t } = useTranslation();
  return (
    <Grid container>
      <Grid container item xs={12} justifyContent="flex-end">
        <SuccessButton
          processing={processing ? 1 : 0}
          onClick={handleSubmit}
          color="primary"
          text={t('general:apply_changes')}
        />
      </Grid>
    </Grid>
  );
};
