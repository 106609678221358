/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import { Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import Box from '../ui/Layout/Box';
import { setWizardStep, setCategories } from '../../actions/wizard';
import AuthorizedApiClient from '../../core/common/api/AuthorizedApiClient';
import CheckOption from './CheckOption';
import { BusinessType } from '../../constants/common';
import SuccessButton from '../ui/Buttons/SuccessButton';
import ActivityIndicator from '../ui/ActivityIndicator';
import { requireCustomerType, requireProductionVolume, WizardSteps } from './steps';

const useStyle = makeStyles(() => ({
  questionsContainer: {
    width: '70%',
    marginLeft: 'auto',
    marginRight: 'auto',
    minWidth: '220px',
  },
}));

const CategoriesSection = () => {
  const classes = useStyle();
  const { t } = useTranslation(['general']);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [cats, setCats] = useState([]);
  const [selectedCats, setSelectedCats] = useState([]);

  const { food, business_type } = useSelector((state: any) => state.wizard);

  const handleCategorySelected = (value) => {
    const current = selectedCats.find(c => c.toString() === value.toString());

    if (current) {
      setSelectedCats(selectedCats.filter(c => c.toString() !== value));
    } else {
      setSelectedCats([...selectedCats, value]);
    }
  };

  const _getCategories = useCallback(
    async (food = null, business_type = null) => {
      const sg = new AuthorizedApiClient();
      const path = 'categories';
      setLoading(true);
      try {
        const params = { per_page: 100, filters: {} };

        if (business_type !== null && business_type !== BusinessType.OTHER) {
          params.filters['business_type'] = business_type === BusinessType.PROCESOR
            ? BusinessType.FARMER
            : business_type;
        }
        params.filters['food'] = !!food;
        const response = await sg.get(params, path);

        if (response != null) {
          setCats(response.data.data);
        }
      } catch (error) {
        setCats([]);
      } finally {
        setLoading(false);
      }
    },
    [],
  );

  useEffect(() => {
    _getCategories(food, business_type);
  }, [food, business_type, _getCategories]);

  const handleSubmit = () => {
    if (selectedCats.length) {
      dispatch(setCategories(selectedCats));
      if (requireProductionVolume(business_type)) {
        dispatch(setWizardStep(WizardSteps.PRODUCTION_VOLUME));
      } else if (requireCustomerType(business_type)) {
        dispatch(setWizardStep(WizardSteps.CUSTOMER_TYPES));
      } else {
        dispatch(setWizardStep(WizardSteps.INVOICES));
      }
    }
  };

  return (
    <>

      <Box>
        <Typography variant="h6" align="center" color="textSecondary">
          {t('general:wizard_setup:category_question')}
        </Typography>
      </Box>

      {loading ? (
        <ActivityIndicator />
      ) : (
        <Box mt={2} className={classes.questionsContainer} pt={2}>
          {cats.map(category => (
            <Box key={category.id} mb={1}>
              <CheckOption
                label={category.name}
                handleOnCLick={() => handleCategorySelected(category.id)}
                selected={selectedCats.includes(category.id)}
              />
            </Box>
          ))}
        </Box>
      )}

      <Box mt={2} display="flex" justifyContent="center">
        <SuccessButton
          text={t('general:next')}
          onClick={handleSubmit}
          disabled={!(selectedCats.length > 0)}
        />
      </Box>
    </>
  );
};

export default CategoriesSection;
