/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/prop-types */
import React from 'react';

import { useDispatch } from 'react-redux';
import CategoryListItemView from './CategoryListItemView';
import { editCategory } from '../../../../../actions/category';

const CategoryListItem = ({ category }) => {
  const dispatch = useDispatch();
  const showDetails = (category) => {
    dispatch(editCategory(category));
  };

  return (
    <CategoryListItemView
      category={category}
      showDetails={category => showDetails(category)}
    />
  );
};

export default CategoryListItem;
