import { useReducer } from 'react';
import {
  SET_DASHBOARD_START_DATE, SET_DASHBOARD_END_DATE, DEFAULT_END_DATE, DEFAULT_START_DATE,
} from '../constants';
import dashboardReducer from '../reducers/dashboard';

const initialState = {
  start_date: DEFAULT_START_DATE,
  end_date: DEFAULT_END_DATE,
};

const useDashboardState = () => {
  const [state, dispatch] = useReducer(dashboardReducer, initialState);

  const setStartDate = (date) => {
    dispatch({ type: SET_DASHBOARD_START_DATE, payload: date });
  };

  const setEndDate = (date) => {
    dispatch({ type: SET_DASHBOARD_END_DATE, payload: date });
  };
  return {
    state,
    setStartDate,
    setEndDate,
  };
};

export default useDashboardState;
