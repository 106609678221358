/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import Box from '../../../../../components/ui/Layout/Box';
import ConfirmDialog from '../../../../../components/ui/ConfirmDialog';
import Grid from '../../../../../components/ui/Layout/Grid';
import Alert from '../../../../../components/ui/notifications/alert';
import ImageUploader from '../../../../../components/ui/ImageUploader';
import FormInput from '../../../../../components/ui/FormInput';
import { rules as validationRules } from '../../../../../util/Validations';
import Modal from '../../../../../components/ui/Modals/Modal';
import Actions from '../../../../../components/ui/Modals/Actions/Actions';
import useSignUploadUrl from '../../../../../components/ui/ImageUploader/useSignUploadUrl';

export default ({
  brand,
  attemptSaveBrand,
  attemptDeleteBrand,
  processing,
  deletingBrand,
  error,
  closeModalBrand,
}) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const [image, setImage] = useState(null);
  const [imageKey, setImageKey] = useState(null);
  const { showModal } = useSelector((state: any) => state.brand);
  const { t } = useTranslation(['brands', 'translation', 'general']);

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(closeModalBrand());
  };

  const title = brand.id == null
    ? t('brands:create_brand')
    : t('brands:edit_brand', { name: brand.name });

  const schema = Yup.object({
    name: Yup.string()
      .max(validationRules.maxLength)
      .required(
        t('translation:required', {
          input: t('brands:brand_name'),
        }),
      ),
    description: Yup.string().max(validationRules.maxLength),
  });
  const { register, handleSubmit, formState: { errors } } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    data.id = brand.id ? brand.id : null;
    if (data.id) {
      data.image = image === '' ? '' : brand.image;
    }
    if (imageKey) {
      data.asset = {
        type: 'image',
        key: imageKey,
      };
    }
    attemptSaveBrand(data);
  };

  const imgUrl = brand.image_url ? brand.image_url : null;

  const { signUrl } = useSignUploadUrl('brands');

  const actions = (
    <Actions
      handleClose={handleClose}
      handleSubmit={handleSubmit(onSubmit)}
      handleDelete={() => setShowConfirm(true)}
      isNew={brand.id === null}
      processing={processing}
      deleting={deletingBrand}
    />
  );

  const { id } = brand;
  useEffect(() => {
    setImage(null);
  }, [id]);

  return (
    <Modal
      open={showModal}
      onClose={handleClose}
      title={title}
      actions={actions}
    >
      <Box>
        <ConfirmDialog
          open={showConfirm}
          handleCancel={() => {
            setShowConfirm(false);
          }}
          handleConfirm={() => {
            setShowConfirm(false);
            attemptDeleteBrand(brand);
          }}
          title={t('brands:delete_brand')}
          content={t('brands:delete_confirmation_msg')}
        />
        {error && (
          <Grid container>
            <Alert severity="error" message={error} />
          </Grid>
        )}
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <Grid container spacing={2}>
            <Grid
              container
              item
              xs={12}
              sm={6}
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <Box height="190px" width="190px">
                <ImageUploader
                  imgUrl={imgUrl}
                  setImage={setImage}
                  text={t('brands:add_image')}
                  signUrl={signUrl}
                  setImageKey={setImageKey}
                />
              </Box>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Grid container>
                <Grid item xs={12}>
                  <Box mt={2}>
                    <FormInput
                      register={register('name')}
                      error={errors.name}
                      label={t('brands:brand_name')}
                      defaultValue={brand.name ? brand.name : ''}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <Box mt={3}>
                    <FormInput
                      register={register('description')}
                      error={errors.description}
                      type="textarea"
                      label={t('brands:description')}
                      defaultValue={brand.description ? brand.description : ''}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Modal>
  );
};
