import { types } from './types';
import AuthorizedApiClient from '../../core/common/api/AuthorizedApiClient';

export const resetSuppliers = () => ({
  type: types.RESET_SUPPLIERS,
});

export const getSuppliers = (companyId, params = {}) => async (
  dispatch: any,
) => {
  const sg = new AuthorizedApiClient();
  const path = `companies/${companyId}/suppliers`;
  dispatch({ type: types.SET_SUPPLIERS_PROCESSING, payload: true });
  try {
    const response = await sg.get(params, path);

    if (response != null) {
      dispatch({ type: types.SET_SUPPLIERS_SUCCESS, response: response.data });
    }
  } catch (error) {
    dispatch({ type: types.SET_SUPPLIERS_FAILURE, error });
  }
};
