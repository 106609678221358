export const types = {
  CLOSE_MOVEMENT_MODAL: 'CLOSE_MOVEMENT_MODAL',
  SHOW_MOVEMENT_MODAL: 'SHOW_MOVEMENT_MODAL',
  SAVING_MOVEMENT: 'SAVING_MOVEMENT',
  SAVE_MOVEMENT_SUCCESS: 'SAVE_MOVEMENT_SUCCESS',
  SAVE_MOVEMENT_FAILURE: 'SAVE_MOVEMENT_FAILURE',

  SET_PRODUCTS_MOVEMENT_PROCESSING: 'SET_PRODUCTS_MOVEMENT_PROCESSING',
  SET_PRODUCTS_MOVEMENT_SUCCESS: 'SET_PRODUCTS_MOVEMENT_SUCCESS',
  SET_PRODUCTS_MOVEMENT_FAILURE: 'SET_PRODUCTS_MOVEMENT_FAILURE',

  SET_LOCATIONS_MOVEMENT_PROCESSING: 'SET_LOCATIONS_MOVEMENT_PROCESSING',
  SET_LOCATIONS_MOVEMENT_SUCCESS: 'SET_LOCATIONS_MOVEMENT_SUCCESS',
  SET_LOCATIONS_MOVEMENT_FAILURE: 'SET_LOCATIONS_MOVEMENT_FAILURE',

  SET_PRODUCT_MOVEMENT: 'SET_PRODUCT_MOVEMENT',

};
