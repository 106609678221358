/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BrandsListView from './BrandsListView';
import {
  getBrands,
  resetBrands,
} from '../../../../../actions/brands';
import { SCROLL_PER_PAGE } from '../../../../../constants/common';

export default () => {
  const dispatch = useDispatch();
  const state = useSelector((states: any) => states.brands);
  const company = useSelector((states: any) => states.session.company);

  const _getBrands = useCallback(
    async (params: any = {}) => {
      if (company) {
        params.per_page = SCROLL_PER_PAGE;
        await dispatch(getBrands(company.id, params));
      }
    },
    [dispatch, company],
  );

  const _resetBrands = useCallback(() => {
    dispatch(resetBrands());
  }, [dispatch]);

  return (
    <BrandsListView
      state={state}
      getBrands={params => _getBrands(params)}
      resetBrands={_resetBrands}
    />
  );
};
