/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import {
  Grid, Typography,
} from '@mui/material';
import React from 'react';
import { makeStyles } from '@mui/styles';

const styles = makeStyles(theme => ({
  x: {
    display: 'inline-table',
    position: 'relative',
    height: 26,
    width: 26,
    '&::after': {
      content: "''",
      display: 'block',
      backgroundColor: theme.colors.trafficLight.low.main,
      width: '85%',
      height: 3,
      position: 'absolute',
      top: 'calc(50% - 1.5px)',
      left: -1,
      transform: 'rotate(-45deg)',
    },
    '&::before': {
      content: "''",
      display: 'block',
      backgroundColor: theme.colors.trafficLight.low.main,
      width: '85%',
      height: 3,
      position: 'absolute',
      top: 'calc(50% - 1.5px)',
      left: -1,
      transform: 'rotate(45deg)',
    },
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    backgroundColor: theme.colors.trafficLight.low.light,
    border: `1px solid ${theme.colors.trafficLight.low.main}`,
    borderRadius: 15,
    padding: '0px 4px',
    overflow: 'hidden',
    width: 20,
    transition: 'width 0.3s',
    '&:hover': {
      width: 130,
    },
  },
  containerHidden: {
    display: 'none',
    visibility: 'hidden',
    width: 0,
  },
  containerVisible: {
    visibility: 'visible',
  },
}));

export default (props) => {
  const classes = styles();

  return (
    <Grid
      className={`${classes.container} ${
        props.displayed && props.step === 1
          ? classes.containerVisible
          : classes.containerHidden
      }`}
      onClick={() => props.click()}
    >
      <span
        className={`${classes.x}`}
      />
      <Typography variant="body2" style={{ whiteSpace: 'nowrap' }}>
        Descartar carrito
      </Typography>
    </Grid>
  );
};
