import {
  Box, Button, Grid, TextField, Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from '../../../../../components/Dropdown/Dropdown';
import Modal from '../../../../../components/ui/Modals/Modal';
import { OrderStatusesTable } from '../components/OrderStatusesTable';

export const StatusesPage = () => {
  const { t } = useTranslation('general');
  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => setIsOpen(!isOpen);

  return (
    <Grid>
      <Modal
        title={t('new_order_status')}
        actions={(
          <Button variant="contained" color="primary">
            <Typography variant="button">
              {t('send')}
            </Typography>
          </Button>
        )}
        onClose={toggleModal}
        open={isOpen}
      >
        <Box display="flex" flexDirection="column" gap="10px" padding={3}>
          <TextField
            // {...register('model')}
            // InputLabelProps={{ shrink: watch('model') }}
            label="title"
            variant="outlined"
            size="small"
          />
          <TextField
            // {...register('model')}
            // InputLabelProps={{ shrink: watch('model') }}
            label="description"
            variant="outlined"
            size="small"
          />
          <Dropdown
            fullWidth
            value=""
            onChange={() => {}}
            options={[
              { identifier: 'yes', label: 'yes', color: '#e3fde3' },
              { identifier: 'no', label: 'no', color: '#e5b2dd' },
            ]}
          />
          <Dropdown
            fullWidth
            value=""
            onChange={() => {}}
            options={[
              { identifier: 'waiting_for_route', label: 'waiting_for_route' },
              { identifier: 'in_trouble', label: 'in_trouble' },
              { identifier: 'in_progress', label: 'in_progress' },
              { identifier: 'shipped', label: 'shipped' },
            ]}
          />
        </Box>
      </Modal>
      <Box m="1rem 0px">
        <Button onClick={() => setIsOpen(true)} variant="contained" color="primary">
          <Typography variant="button">
            {t('routes.add_a_status')}
          </Typography>
        </Button>
      </Box>
      <OrderStatusesTable />
    </Grid>
  );
};
