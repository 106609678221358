/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/prop-types */
import React, { useCallback, useState } from 'react';
import ProductsListSelector from '../ProductsListSelector';
import Modal from '../ui/Modals/Modal';
import Actions from './Actions';

const ModalProducts = ({
  handleSelected,
  listType,
  show = true,
  title = 'Listado de productos',
}) => {
  const [showModal, setShowModal] = useState(show);
  const [selected, setSelected] = useState([]);
  const _handleSelected = useCallback((selected) => {
    setSelected(selected);
  }, []);
  const handleSubmit = () => {
    handleSelected(selected);
  };

  const actions = <Actions handleSubmit={handleSubmit} />;

  return (
    <Modal
      open={showModal}
      title={title}
      onClose={() => {
        setShowModal(false);
      }}
      actions={actions}
    >
      <ProductsListSelector handleSelected={_handleSelected} listType={listType} />
    </Modal>
  );
};

export default ModalProducts;
