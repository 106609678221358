/* eslint-disable react/prop-types */
import {
  Box, Grid, Typography,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  container: {
    borderRadius: 5,
    border: `1px solid ${theme.colors.grey}`,
    padding: '.5em 1em',
    minWidth: '15rem',
    backgroundColor: 'white',
    boxSizing: 'border-box',
    maxWidth: '16rem',
  },
  card: {
    maxWidth: '100%',
    height: '100%',
    display: 'block',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  cardContain: {
    justifySelf: 'center',
    width: '5.5em',
    height: '3em',
    border: '1px solid grey',
    borderRadius: 10,
    padding: '.5em',
    margin: '.7em .5em',
  },
  visa: {
    backgroundImage: `url(${theme.icons.visa})`,
  },
  mastercard: {
    backgroundImage: `url(${theme.icons.masterCard})`,
  },
  americanexpress: {
    backgroundImage: `url(${theme.icons.americanExpress})`,
  },
}));

const LayoutCard = ({ cardData }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box display="grid" gridTemplateColumns="4fr 3fr" alignItems="center">
      <Grid className={classes.cardContain}>
        <span className={`${classes.card} ${classes[cardData.brand.replace('_', '').toLowerCase()]}`} />
      </Grid>
      <Box justifySelf="start">
        <Typography variant="body1">
          {cardData.brand.replace('_', ' ').toUpperCase()}
          {' '}
          &#8226;&#8226;&#8226;
          {cardData.last4}
        </Typography>
        <Typography variant="caption">
          { t('general:expire_in')}
          {' '}
          {`${cardData.exp_month}/${cardData.exp_year}`}
        </Typography>
      </Box>
    </Box>
  );
};

const WithoutData = () => {
  const { t } = useTranslation(['general']);
  // TODO_T: No hay metodo de pago establecido
  return (
    <Box display="grid" style={{ placeItems: 'center' }}>
      <Typography variant="body1">
        {t('without_paymentmethod')}
      </Typography>
    </Box>
  );
};

const PaymentMethodCard = ({ cardData }) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      {
        Object.values(cardData).length > 0
          ? <LayoutCard cardData={cardData} />
          : <WithoutData />
      }
    </Box>
  );
};

export default PaymentMethodCard;
