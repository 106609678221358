import React from 'react';
import { useTranslation } from 'react-i18next';
import UnitsList from './UnitsList';
import SectionListContainer from '../../../../hoc/SectionListContainer';
import Grid from '../../../../components/ui/Layout/Grid';
import PageContainer from '../../../../hoc/PageContainer';
import Box from '../../../../components/ui/Layout/Box';
import AddButton from '../../../../components/ui/Buttons/BlueButton/AddButton';
import UnitForm from './UnitForm';
import { useSession } from '../../../../util/session';
import { PERMISSION, RESOURCES } from '../../../../constants/common';

type UnitOfMeasureProps = {
  openNewUnitOfMeasure: any;
};
export default ({ openNewUnitOfMeasure }: UnitOfMeasureProps) => {
  const { t } = useTranslation('general');
  const { can } = useSession();
  return (
    <>
      {(can(RESOURCES.units, PERMISSION.create)
        || can(RESOURCES.units, PERMISSION.create)) && <UnitForm />}

      <Grid container>
        <PageContainer>
          {/* <BreadCrumbs /> */}
          {can(RESOURCES.units, PERMISSION.create) && (
            <Box mt={3}>
              <AddButton onClick={openNewUnitOfMeasure}>
                {t('general:create_unit')}
              </AddButton>
            </Box>
          )}

          <SectionListContainer>
            <UnitsList />
          </SectionListContainer>
        </PageContainer>
      </Grid>
    </>
  );
};
