import { Button } from '@mui/material';
import React from 'react';
import { withStyles } from '@mui/styles';

const ECButton = withStyles(() => ({
  root: {
    width: '80px',
    height: '80px',
    color: '#A174CB',
    background: '#F4E8FC',
    '&:hover': {
      color: '#A174CB',
      background: '#F4E8FC',
    },
  },
}))(Button);
const GalleryButton = () => <ECButton>+</ECButton>;

export default GalleryButton;
