/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import {
  Button as BButton,
  CircularProgress,
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';

const CButton = withStyles(theme => ({
  root: {
    margin: '5px',
    [theme.breakpoints.down('md')]: {
      padding: '8px 18px',
    },
  },
}))(BButton);

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
    display: 'inline-block',
  },

  fabProgress: {
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1,
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const Button = (props) => {
  const variant = props.variant ? props.variant : 'contained';
  const classes = useStyles();
  const processing = props.processing === 1;
  return (
    <CButton
      {...props}
      variant={variant}
      disabled={!!(processing || props.disabled)}
    >
      <span>
        {' '}
        {props.text}
        {' '}
      </span>
      {processing && (
        <CircularProgress size={24} className={classes.buttonProgress} />
      )}
      {props.children && props.children}
    </CButton>
  );
};

export default Button;
