/* eslint-disable react/prop-types */
import React from 'react';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { withStyles } from '@mui/styles';
import Button from '../../Button';

const ECButton = withStyles(() => ({
  root: {
    backgroundColor: '#408c3c',
    '&:hover': {
      backgroundColor: '#408c3c',
    },
  },
}))(Button);

export default (props) => {
  const { text, variant, ...rest } = props;
  return (
    <ECButton
      {...rest}
      variant="contained"
      color="primary"
      text={text}
      startIcon={<CloudUploadIcon />}
    />
  );
};
